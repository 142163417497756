/**
 * Update date: 24-05-2023
 * Screen 2033.3
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import SpecialDealTable from './TableView';
import FormSearch from './FormSearch';
import useProfileInfo from 'hooks/useProfileInfo';
import { useProductType } from 'hooks/useProductType';
/**
 * create context
 */
const orderDetail = createContext({
  refetchData: () => {},
  loadingData: false,
  dealData: [],
  clientName: '',
  clientId: '',
});
orderDetail.displayName = 'orderDetail';
export const useOrderDetail = () => {
  const context = useContext(orderDetail);
  return context;
};

function ConsignmentDeliveryInquiry() {
  const profileInfo = useProfileInfo();
  const id = profileInfo.userId;
  const [orderData, setOrderData] = useState([]);
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  const [{ data: resData, loading: userLoading }] = useAxios(
    {
      url: `/user/user/${id}`,
      method: 'GET',
    },
    {
      manual: false,
    },
  );

  const [{ data, loading: loadingOrder }, refetchData] = useAxios(
    {
      url: '/warehouse/order-consignment',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const clientName = resData?.data?.clientName; //resData?.data?.clientName
  const clientId = resData?.data?.clientId; //resData?.data?.clientId

  const value: any = {
    refetchData,
    orderData,
    loadingOrder,
    clientName,
    clientId,
    productTpPermission,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setOrderData(data?.data);
    }
  }, [data]);

  const loadingAll = loadingOrder || userLoading || loadingProductTpPermission;

  return (
    <>
      <orderDetail.Provider value={value}>
        <LoadPanel visible={loadingAll} />
        <FormSearch />
        <SpecialDealTable />
      </orderDetail.Provider>
    </>
  );
}

export default ConsignmentDeliveryInquiry;
