/**
 * Update date: 2023-08-31
 * Screen 2054.E
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import { cloneDeep, uniqBy } from 'lodash';
import { useRef, useState, memo } from 'react';
import useFormatTime from 'hooks/useFormatTime';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectInventoryDueDiligence54B from 'app/components/PopupCommon/PopupSelectInventoryDueDiligence54B';
import useDictionary from 'hooks/useDictionary';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';
import { dateFormatStr } from 'utils/format';
import moment from 'moment';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
  },
  mediumCategory: {
    codeType: 'MC',
  },
  smallCategory: {
    codeType: 'SC',
  },
  subCategory: {
    codeType: 'UC',
  },
  productMaker: {
    codeType: 'MK',
  },
};

const getAdjustAll = '/warehouse/m2054_e/all';
const getAdjustByZone = '/warehouse/m2054_e/by-zone';
const getAdjustDetail = '/warehouse/m2054_e/detail';
const getAdjustDetail2 = '/warehouse/m2054_e/detail-2';

/**
 * Form search component
 *
 * @return {*}
 */
const FormSearch = ({
  selectedIndex,
  setDataTabAll,
  setDataTabZone,
  setDataDetail,
  setDataDetail2,
}) => {
  const { t }: any = useDictionary({ programId: '2054.E' });
  const [showPopupByType, setShowPopupByType] = useState('');
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      dueDiligence: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      product: [],
      productGroup: [],
      productMaker: [],
      vendor: [],
    },
    dueDiligence: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    productMaker: '',
    vendor: '',
  });

  const [{ loading }, fetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const res = await fetchData({
      url:
        selectedIndex === 0
          ? getAdjustAll
          : selectedIndex === 1
          ? getAdjustByZone
          : selectedIndex === 2
          ? getAdjustDetail
          : getAdjustDetail2,
      method: 'POST',
      data: {
        startDate: dateFormatStr(formSearchData?.fromDate),
        endDate: dateFormatStr(formSearchData?.endDate),
        planIds: formSearchData?.dataList?.dueDiligence.map(
          (o: any) => o.splanid,
        ),
        largeCategories: formSearchData?.dataList?.largeCategory.map(
          (o: any) => o?.code,
        ),
        mediumCategories: formSearchData?.dataList?.mediumCategory.map(
          (o: any) => o?.code,
        ),
        smallCategories: formSearchData?.dataList?.smallCategory.map(
          (o: any) => o?.code,
        ),
        subCategories: formSearchData?.dataList?.subCategory.map(
          (o: any) => o?.code,
        ),
        productMakers: formSearchData?.dataList?.productMaker.map(
          (o: any) => o?.code,
        ),
        productIds: formSearchData?.dataList?.product?.map(o => o?.productId),
        productGroupIds: formSearchData?.dataList?.productGroup?.map(
          o => o?.productGroupId,
        ),
        vendorIds: formSearchData?.dataList?.vendor.map(
          (o: any) => o?.vendorId,
        ),
      },
    });
    if (res?.data?.status === '200') {
      const dataMaping = (res?.data?.data || []).map(o => ({
        ...o,
        stock_taking_time: `${moment(o?.plan_start_dt).format(
          DateFormat.toUpperCase(),
        )} ~ ${moment(o?.plan_end_dt).format(DateFormat.toUpperCase())}`,
        adjust_time: o?.regist_adjust_dt,
      }));
      switch (selectedIndex) {
        case 0:
          setDataTabAll(dataMaping);
          break;
        case 1:
          setDataTabZone(dataMaping);
          break;
        case 2:
          setDataDetail(dataMaping);
          break;
        case 3:
          setDataDetail2(dataMaping);
          break;
        default:
          break;
      }
    } else {
      notification({ res });
    }
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormSearchData({
      ...formSearchData,
      dataList: dataList,
    });
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formSearchData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormSearchData(newData);
    setShowPopupByType('');
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formSearchData?.dataList?.[fieldName] || [];
    const buttons = dataList?.slice(0, numberShowMore)?.map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };
  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'dueDiligence':
        return (
          <PopupSelectInventoryDueDiligence54B
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('dueDiligence', o, 'splanid')}
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'productId')}
          />
        );
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data =>
              updateStore('productGroup', data, 'productGroupId')
            }
          />
        );
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
      case 'productMaker':
        return (
          <PopupSelectCommonCode
            visible={showPopupByType === popupType}
            text={formSearchData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Onchange date when choose date option
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };

  /**
   * Render search tab Content
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={formRef}
          formData={formSearchData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={2}
          items={[
            {
              itemType: 'group',
              colCount: 2,
              items: [
                {
                  label: { text: t('From Date') },
                  dataField: 'fromDate',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    displayFormat: DateFormat,
                  },
                },
                {
                  label: { text: t('End Date') },
                  dataField: 'endDate',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    displayFormat: DateFormat,
                  },
                },
              ],
            },
            generateDxTextBox(
              'Inventory Due Diligence',
              'dueDiligence',
              'splanno',
            ),
          ]}
        />
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <Form
        formData={formSearchData}
        showColonAfterLabel={false}
        labelLocation="top"
        colCount={3}
        items={[
          generateDxTextBox('Large Category', 'largeCategory', 'name'),
          generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
          generateDxTextBox('Product Group', 'productGroup', 'productGroupNm'),
          generateDxTextBox('Small Category', 'smallCategory', 'name'),
          generateDxTextBox('Sub Category', 'subCategory', 'name'),
          generateDxTextBox('Product', 'product', 'productNm'),
          generateDxTextBox('Maker', 'productMaker', 'name'),
          generateDxTextBox('Vendor', 'vendor', 'vendorName'),
        ]}
      />
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      <LoadPanel visible={loading} />
      {getPopupByType(showPopupByType)}
    </div>
  );
};
export default memo(FormSearch);
