/**
 * Update date: 07-06-2023
 * Screen 2081.2
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { API_URL_USER_MEMBER_LIST } from 'constant/apiUrl';

const MemberDetail = React.lazy(() => import('./MemberDetail'));
const ListMember = React.lazy(() => import('./ListMember'));

const defaultSearchParams = {
  keyword: '',
  approvals: [],
};
/**
 * create context
 */
export const MemberInquiryContext = createContext({
  setModeView: any => {},
  modeView: '',
  searchParams: {},
  listMembers: [],
  fetchMembers: any => {},
});

MemberInquiryContext.displayName = 'MemberInquiryContext';

export const useMemberInquiry = () => {
  const context = useContext(MemberInquiryContext);
  return context;
};

const MemberInquiry = () => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [listMembers, setListMembers] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ data: resMembers, loading }, fetchMembers] = useAxios(
    {
      url: API_URL_USER_MEMBER_LIST,
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    setModeView,
    modeView,
    searchParams,
    listMembers,
    fetchMembers,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListMember />;
      case 'detail':
        return <MemberDetail />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resMembers?.data) {
      setListMembers(resMembers?.data);
    }
  }, [resMembers]);

  return (
    <MemberInquiryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </MemberInquiryContext.Provider>
  );
};

export default MemberInquiry;
