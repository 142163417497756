/**
 * Update date: 24-05-2023
 * Screen 2041.4
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useAxios from 'axios-hooks';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { forwardRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useIncomingLoadreg } from '..';

function LoadingComplete(props, ref) {
  const { themePro }: any = useApp();
  const context: any = useIncomingLoadreg();
  const { storeComplete, modeView, setListComplete } = context;
  const selectedKeys = ref?.current?.instance?.getSelectedRowKeys?.() || [];
  const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState(false);
  const { AmountFormat } = useFormat();
  const { DateFormat } = useFormatTime();
  const [{ loading }, fetchPut] = useAxios(
    {
      url: 'ecommerce/load-rec-pda',
      method: 'PUT',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  const { t }: any = useDictionary({
    programId: '2041.4',
  });

  const columns: any = [
    {
      dataField: 'status',
      caption: t('Status'),
      alignment: 'left',
    },
    {
      dataField: 'locationToNm',
      caption: t('Location'),
      alignment: 'left',
    },
    {
      dataField: 'expirationEndDate',
      caption: t('Expiration End Date'),
      alignment: themePro ? 'left' : 'center',
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'receiveBill',
      caption: t('Bill ID'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'vendorNm',
      caption: t('Vendor'),
      alignment: 'left',
    },
    {
      dataField: 'receiveId',
      caption: t('ID'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'productNm',
      caption: t('Product Name'),
      alignment: 'left',
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
      alignment: 'left',
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      alignment: 'right',
    },
    {
      dataField: 'moveQuantity',
      caption: t('Qty'),
      alignment: 'right',
      format: AmountFormat,
    },
  ];

  /**
   * on save
   *
   * @return {*}
   */
  const onSave = async () => {
    const list = ref?.current?.instance?.getDataSource()?._store?._array || [];
    if (!list?.length) return;
    const res = await fetchPut({
      data: {
        zoneId: modeView?.id,
        data: list?.map(o => ({
          ...o,
          expirationEndDate: o?.expirationEndDate
            ? moment(o.expirationEndDate).format('YYYY-MM-DD')
            : null,
        })),
      },
    });
    if (res?.data?.status === '200') {
      storeComplete.clear();
      setListComplete([]);
      ref?.current?.instance?.refresh();
    }
    notification({ res });
  };

  /**
   * on delete
   *
   */
  const onDelete = () => {
    selectedKeys.forEach(o => {
      storeComplete
        .remove(o)
        .done(() => {})
        .fail(() => {});
    });
    ref?.current?.instance?.refresh();
    ref?.current?.instance?.clearSelection();
    setShowPopupConfirmDelete(false);
  };
  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loading} />
      <DataGridFull
        ref={ref}
        dataSource={storeComplete}
        columns={columns}
        options={{
          key: 'ID',
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                locateInMenu: 'auto',
                widget: 'dxButton',
                options: {
                  icon: 'save',
                  text: t('Save'),
                  onClick: () => onSave(),
                },
              },
              {
                location: 'after',
                locateInMenu: 'auto',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete Line'),
                  onClick: () => setShowPopupConfirmDelete(true),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
          summary: {
            totalItems: [
              {
                column: 'receiveQt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
            ],
          },
        }}
      />
      <PopupConfirmDelete
        visible={showPopupConfirmDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onDelete}
        onHiding={() => setShowPopupConfirmDelete(false)}
      />
    </React.Suspense>
  );
}

export default forwardRef(LoadingComplete);
