/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup } from 'devextreme-react';
import { usePopupOrder } from '.';
import TableSingle from './TableSingle';
import { useState, useEffect } from 'react';

interface IWayBill {
  onClose: () => void;
  waybill: string;
}

function WayBill({ onClose, waybill }: IWayBill) {
  const [dataWaybill, setDataWaybill] = useState<any>([]);
  const [{ loading, data }] = useAxios(
    {
      url: `/ecommerce/order-popup/waybill`,
      method: 'GET',
      params: {
        waybill: waybill,
      },
    },
    { useCache: false },
  );
  const { t }: any = usePopupOrder();
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loading && data) {
      setDataWaybill([
        {
          status: t(data?.data?.stock_allocation_status || ''),
          date: data?.data?.stock_allocation_date,
          operator: data?.data?.stock_allocation_user_nm,
        },
        {
          status: t(data?.data?.picking_print_status || ''),
          date: data?.data?.picking_print_date,
          operator: data?.data?.picking_print_user_nm,
        },
        {
          status: t(data?.data?.ecs_send_status || ''),
          date: data?.data?.ecs_send_date,
          operator: data?.data?.ecs_send_user_nm,
        },
        {
          status: t(data?.data?.ecs_send_receive_status || ''),
          date: data?.data?.ecs_send_receive_date,
          operator: data?.data?.ecs_send_receive_user_nm,
        },
        {
          status: t(data?.data?.product_check_status || ''),
          date: data?.data?.product_check_date,
          operator: data?.data?.product_check_user_nm,
        },
      ]);
    }
  }, [loading, data]);

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <>
        <LoadPanel visible={loading} />
        <div className="modal-popup-header">
          <span
            className="title-page"
            style={{ fontSize: 18, margin: '0 0 5px' }}
          >
            {t('Waybill')}
          </span>
          <div style={{ marginBottom: 10 }}>
            <Button stylingMode="contained" icon="close" onClick={onClose} />
          </div>
        </div>
        <TableSingle
          columns={[
            {
              label: t('Status'),
              dataField: 'status',
            },
            {
              label: t('Date Time'),
              dataField: 'date',
              type: 'datetime',
            },
            {
              label: t('Operator'),
              dataField: 'operator',
            },
          ]}
          dataSource={dataWaybill}
        />
      </>
    );
  };

  return (
    <Popup
      visible={true}
      contentRender={content}
      width="auto"
      height="auto"
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default WayBill;
