import React, { useState, useContext, createContext, useEffect } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import FormSignUp from './FormSignUp';
import { USER_REGISTRATION } from 'constant';
import { useHistory } from 'react-router-dom';
import tokenService from 'services/auth.service';
import Verification from './Verification';
import useDictionary from 'hooks/useDictionary';
import './styles.scss';
import { useApp } from 'app';
import useCenterInfo from 'hooks/useCenterInfo';

const SignUpContext = createContext({});

SignUpContext.displayName = 'SignUpContext';

export const useSignUp = () => {
  const context = useContext(SignUpContext);
  return context;
};

const SignUp = () => {
  const history = useHistory();
  const { themePro }: any = useApp();
  const { centerName } = useCenterInfo();
  const [modeView, setModeView] = useState({
    type: 'verification',
  });
  const { t }: any = useDictionary({});
  const value: any = { t, centerName };
  const renderContent = () => {
    switch (modeView?.type) {
      case 'signup':
        return <FormSignUp />;
      case 'verification':
        return <Verification />;
      default:
        return <NotFoundPage />;
    }
  };
  useEffect(() => {
    const timeNow = new Date().getTime();
    const userRegistration: string | null =
      localStorage.getItem(USER_REGISTRATION);
    const dataUser: any = userRegistration
      ? JSON.parse(userRegistration)
      : null;
    const waiting: boolean =
      parseInt(dataUser?.expired) > timeNow ? true : false;
    if (history.location.search === '') {
      tokenService.removeUserRegistration();
      setModeView({
        type: 'signup',
      });
    } else if (
      (history.location.search === '?verification-code' ||
        history.location.search === '?resend-code') &&
      waiting
    ) {
      setModeView({
        type: 'verification',
      });
    } else {
      history.push('/signup');
      tokenService.removeUserRegistration();
    }
  }, [history, history.location.search]);
  const loadingAll = false;

  return (
    <SignUpContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <div className="sign-up">
          <LoadPanel visible={loadingAll} />
          <div className="content">
            {themePro && <div className="smdc">{centerName}</div>}
            {renderContent()}
          </div>
        </div>
      </React.Suspense>
    </SignUpContext.Provider>
  );
};

export default SignUp;
