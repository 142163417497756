/**
 * Update date: 15-05-2023
 * Screen 1044.2
 */
import { DataGridFull } from 'app/components/DataGrid';
import { CheckBox } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import { memo, useRef } from 'react';

const rowDefault = {
  store_manager_id: '',
  store_manager_nm: '',
  email: '',
  division: '',
  position: '',
  mobile: '',
  main_contact_tf: false,
  isNewRow: true,
};

const columnEditStoreManager = [
  'store_manager_nm',
  'email',
  'division',
  'position',
  'mobile',
];

function StoreManager(props) {
  const { t }: any = useDictionary({ programId: '1044.2' });
  const { value, onMainContactChange } = props?.data?.editorOptions || {};
  const dataGridRef: any = useRef(null);

  /**
   * on row inserted
   *
   * @param {*} e
   */
  const onRowInserted = e => {
    const dataRow = e?.data;
    const newLocation = {
      ...dataRow,
      isNewRow: false,
    };
    value.remove(e?.key).done(() => {
      value?._array?.unshift(newLocation);
      dataGridRef?.current?.instance?.refresh();
    });
  };

  const onInitNewRow = e => {
    e.data = { ...e.data, ...rowDefault, ID: new Date().getTime() };
  };

  /**
   * render switch active
   *
   * @param {*} cell
   * @return {*}
   */
  const mainContactRender = record => {
    const { isNewRow, main_contact_tf, store_manager_nm, ID } =
      record?.data || {};
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CheckBox
          defaultValue={main_contact_tf}
          onValueChanged={e => {
            value?._array?.forEach(o => {
              if (o?.ID === ID) {
                o.main_contact_tf = e.value;
              } else {
                o.main_contact_tf = false;
              }
            });
            onMainContactChange?.(e.value ? store_manager_nm : '');
            dataGridRef?.current?.instance?.refresh?.();
          }}
          readOnly={isNewRow}
        />
      </div>
    );
  };

  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowSaved = e => {
    const findMain = (e?.changes || []).find(o => o?.key?.main_contact_tf);
    if (findMain) {
      if (findMain?.type === 'update') {
        onMainContactChange(findMain?.data?.store_manager_nm);
      } else if (findMain?.type === 'remove') {
        onMainContactChange('');
      }
    }
  };

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <DataGridFull
        ref={dataGridRef}
        dataSource={value}
        columns={[
          {
            dataField: 'selected',
            caption: t('Main Contact'),
            alignment: 'center',
            allowEditing: false,
            cellRender: mainContactRender,
            width: 160,
          },
          {
            dataField: 'store_manager_nm',
            caption: t('Name'),
            validationRules: [
              { type: 'required' },
              {
                type: 'stringLength',
                max: 100,
                message: t('The maximum allowed characters are 100'),
              },
            ],
          },
          {
            dataField: 'email',
            caption: t('Email'),
            validationRules: [
              {
                type: 'email',
                message: t('Email is valid'),
              },
              {
                type: 'stringLength',
                max: 100,
                message: t('The maximum allowed characters are 100'),
              },
            ],
          },
          {
            dataField: 'division',
            caption: t('Division'),
            validationRules: [
              {
                type: 'stringLength',
                max: 100,
                message: t('The maximum allowed characters are 100'),
              },
            ],
          },
          {
            dataField: 'position',
            caption: t('Position'),
            validationRules: [
              {
                type: 'stringLength',
                max: 100,
                message: t('The maximum allowed characters are 100'),
              },
            ],
          },
          {
            dataField: 'mobile',
            caption: t('Mobile Number'),
            validationRules: [
              {
                type: 'stringLength',
                max: 30,
                message: t('The maximum allowed characters are 30'),
              },
            ],
          },
        ]}
        options={{
          columnAutoWidth: true,
          reshapeOnPush: true,
          editing: {
            mode: 'row',
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: true,
            startEditAction: 'click',
            selectTextOnEditStart: true,
          },
          onInitNewRow: onInitNewRow,
          onRowInserted: onRowInserted,
          onSaved: onRowSaved,
          onEditorPreparing: function (e) {
            const indexOf = columnEditStoreManager.indexOf(e?.dataField);
            if (e?.parentType === 'dataRow' && indexOf !== -1) {
              e.editorOptions.onKeyDown = function (ekey) {
                if (ekey?.event?.keyCode == 13) {
                  const dataGrid = dataGridRef?.current?.instance;
                  const nextElement = dataGrid?.getCellElement(
                    e?.row?.rowIndex,
                    columnEditStoreManager[indexOf + 1],
                  );
                  if (nextElement) {
                    ekey?.event?.stopPropagation();
                    dataGrid?.focus(nextElement);
                    nextElement?.click();
                  }
                }
              };
            }
          },
          onToolbarPreparing: (e: any) => {
            const toolbarItems = e?.toolbarOptions?.items;
            toolbarItems?.forEach(item => {
              if (item.name === 'addRowButton') {
                item.showText = 'always';
              }
            });
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !value?._array?.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: `SMDC_1044.2 Customer Employee Information_${moment().format(
              'YYYYMMDD',
            )}`,
          },
        }}
      />
    </div>
  );
}

export default memo(StoreManager);
