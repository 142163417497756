/**
 * Update date: 2024-07-11
 * Screen 2024.3
 */
import { useApp } from 'app';
import LoadPanel from 'app/components/LoadPanel';
import FileUpload from 'app/components/Templates/FileUpload';
import useAxios from 'axios-hooks';
import { Button, Form, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';

interface IPopupConfirm {
  visible?: boolean;
  onSelectedFile?: (file: any) => void;
  onOk?: (data?: any) => void;
  onHiding?: () => void;
  accept?: string;
  mode?: 'single' | 'multiple';
  title?: string;
  data?: { vendorId: number; seqNo: number };
}
function PopupFileUploadFormData({
  visible,
  onSelectedFile,
  onOk,
  onHiding,
  accept = '.xlsx, .xls',
  mode = 'single',
  title = '',
  data,
}: IPopupConfirm) {
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({});
  const formRef = useRef<any>(null);
  const [formData, setFormData] = useState<any>({
    vendorId: null,
    seqNo: null,
  });
  const [settingFromData, setSettingFromData] = useState<any>([]);

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  useEffect(() => {
    if (!data?.vendorId) return;
    setFormData({ ...formData, ...data });
    const getSettingFromData = async () => {
      const res = await refetchData({
        url: `warehouse/consigned-delivery/setting-form/${data?.vendorId}`,
        method: 'GET',
      });
      if (res?.data?.status === '200') {
        setSettingFromData(res?.data?.data || {});
      } else {
        notification({ res });
      }
    };
    getSettingFromData();
  }, [data]);

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <>
        {themePro && (
          <div
            className="modal-popup-header"
            style={{
              width: '100%',
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: 18,
                paddingBottom: 20,
              }}
            >
              {title || t('Import File Excel')}
            </span>
          </div>
        )}
        <>
          <FileUpload
            allowedFileExtensions={[accept]}
            accept={accept}
            mode={mode}
            onSelectedFile={file => {
              onSelectedFile?.(file);
            }}
          />
          <br />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <div>
              <Form
                ref={formRef}
                formData={formData}
                showColonAfterLabel={false}
                style={{ display: 'flex', justifyContent: 'center' }}
                items={[
                  {
                    label: {
                      text: t('Setting Consignment Delivery Order Form'),
                    },
                    dataField: 'seqNo',
                    editorType: 'dxSelectBox',
                    isRequired: true,
                    editorOptions: {
                      displayExpr: 'orderFormNm',
                      valueExpr: 'seqNo',
                      defaultValue: settingFromData?.[0]?.seqNo,
                      items: settingFromData,
                    },
                  },
                ]}
                labelLocation="top"
                colCount={1}
              />
            </div>
          </div>

          <br />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <Button
              icon="check"
              text={t('Ok')}
              onClick={() => {
                const isValid = formRef?.current?.instance?.validate()?.isValid;
                if (!isValid) return;
                onOk?.(formData);
              }}
            />
            <Button icon="close" text={t('Cancel')} onClick={onHiding} />
          </div>
        </>
      </>
    );
  };

  return (
    <>
      <Popup
        visible={visible}
        onHiding={onHiding}
        title={title || t('Import File Excel')}
        contentRender={content}
        height="auto"
        width={isMobile ? '96%' : '40vw'}
        maxHeight="100vh"
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
      <LoadPanel visible={loading} />
    </>
  );
}

export default PopupFileUploadFormData;
