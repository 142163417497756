/**
 * Update date: 2024-10-30
 * Screen 2043.0
 */
import { Button, Form, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import PopupConfirmWithRef from '../../PopupConfirmWithRef/PopupConfirmWithRef';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';

interface IPopupEditOrderQty {
  onOk: (data?: any) => void;
}

const dataDefault = {
  productNm: '',
  orderId: 0,
  itemId: 0,
  orderQuantity: null,
  newOrderQuantity: 0,
  orderPrice: 0,
};

function PopupEditOrderQty({ onOk }: IPopupEditOrderQty, ref) {
  const { t } = useDictionary({ programId: '2043.0' });
  const formRef: any = useRef(null);
  const confirmRef: any = useRef(null);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [formData, setFormData] = useState({ ...dataDefault });

  const [{ loading }, refetchData] = useAxios(
    {},
    { useCache: false, manual: true },
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = (data: any) => {
    if (!data) return;
    setFormData({
      productNm: data.product_nm,
      orderId: data.order_id,
      itemId: data.item_id,
      orderQuantity: data.order_quantity,
      newOrderQuantity: 0,
      orderPrice: data.order_unit_price,
    });
    setShowPopupConfirm(true);
    setTimeout(function () {
      formRef?.current?.instance?.getEditor('newOrderQuantity')?.focus?.();
    }, 600);
  };

  const onClickYes = () => {
    onOk?.();
    setFormData({
      ...dataDefault,
    });
    setShowPopupConfirm(false);
  };

  const onClickNo = () => {
    setFormData({
      ...dataDefault,
    });
    setShowPopupConfirm(false);
  };

  const onSavePrice = async () => {
    const res: any = await refetchData({
      url: `/ecommerce/order-popup/update-order-price-qty`,
      method: 'POST',
      data: {
        order_id: formData?.orderId,
        item_id: formData?.itemId,
        quantity: formData?.newOrderQuantity,
        order_unit_price: formData?.orderPrice,
      },
    });
    notification({ res });
    if (res?.data?.status === '200') {
      onClickYes();
    }
  };

  const content = () => {
    return (
      <div>
        <div
          style={{
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: 500,
            marginBottom: 20,
          }}
        >
          {`${formData.productNm} - ${t('Change Order Quantity')}`}
        </div>
        <Form
          ref={formRef}
          labelLocation="top"
          showColonAfterLabel={false}
          formData={formData}
          items={[
            {
              dataField: 'orderQuantity',
              editorType: 'dxNumberBox',
              label: {
                text: t('Current Quantity'),
              },
              editorOptions: {
                disabled: true,
                format: '#,##0',
              },
            },
            {
              dataField: 'newOrderQuantity',
              editorType: 'dxNumberBox',
              label: {
                text: t('New Quantity'),
              },
              editorOptions: {
                min: 0,
                format: '#,##0',
                onEnterKey: () => confirmRef?.current?.onOpen?.(),
              },
            },
          ]}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
            marginTop: 30,
          }}
        >
          <Button
            text={t('Cancel')}
            type={'normal'}
            width={'100%'}
            icon={'close'}
            onClick={onClickNo}
          />
          <Button
            text={t('Save')}
            type={'default'}
            width={'100%'}
            icon={'check'}
            onClick={() => confirmRef?.current?.onOpen?.()}
          />
        </div>
        <PopupConfirmWithRef
          ref={confirmRef}
          content={'Do you want to Save?'}
          onOk={onSavePrice}
          isTranslate
        />
        <LoadPanel visible={loading} />
      </div>
    );
  };

  return (
    <Popup
      visible={showPopupConfirm}
      contentRender={content}
      height={'auto'}
      width={400}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}
export default memo(forwardRef(PopupEditOrderQty));
