/**
 * Update date: 26-05-2023
 * Screen 2042.5
 */
import Form from 'devextreme-react/form';
import { uniqBy, cloneDeep } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import DateOptions from 'app/components/DateOptions';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Collapse from 'app/components/Collapse';
import { useCustomContext } from '../store';
import useFormatTime from 'hooks/useFormatTime';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  vendor: {
    codeType: 'VD',
    title: 'Search Vendor',
  },
  product: {
    codeType: 'PD',
    title: 'Search Product',
  },
  productGroup: {
    codeType: 'PG',
    title: 'Search ProductGroup',
  },
  largeCategories: {
    codeType: 'LC',
    title: 'Search Large Category',
  },
  mediumCategories: {
    codeType: 'MC',
    title: 'Search Medium Category',
  },
  smallCategories: {
    codeType: 'SC',
    title: 'Search Small Category',
  },
  subCategories: {
    codeType: 'UC',
    title: 'Search Sub Category',
  },
  vendorType: {
    codeType: 'VT',
  },
};

const FormSearch = () => {
  const { t }: any = useDictionary({
    programId: '2042.5',
  });
  // Context
  const { refetchData } = useCustomContext();
  const formRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  //state params
  const [dataLocal, setDataLocal] = useLocalStorage('2042.5_dataList', {});
  const [params, setParams] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    largeCategories: '',
    mediumCategories: '',
    smallCategories: '',
    subCategories: '',
    product: '',
    productGroup: '',
    vendor: '',
    vendorType: '',
    status: '1',
    dataList: {
      largeCategories: [],
      mediumCategories: [],
      smallCategories: [],
      subCategories: [],
      product: [],
      productGroup: [],
      vendor: [],
      vendorType: [],
    },
  });
  const [showPopupByType, setShowPopupByType] = useState('');
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setParams(prev => ({
      ...prev,
      dataList: {
        ...prev.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  // Onsubmit form search
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const dataReq = {
      status: params.status,
      fromDate: params?.fromDate
        ? moment(params?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: params?.endDate
        ? moment(params?.endDate).format('YYYY-MM-DD')
        : null,
      vendorIds: params?.dataList?.vendor?.map(o => o.vendorId) || [],
      productIds: params?.dataList?.product?.map(o => o.productId) || [],
      productGroupIds:
        params?.dataList?.productGroup?.map(o => o.productGroupId) || [],
      largeCategories:
        params?.dataList?.largeCategories?.map(o => o.code) || [],
      mediumCategories:
        params?.dataList?.mediumCategories?.map(o => o.code) || [],
      smallCategories:
        params?.dataList?.smallCategories?.map(o => o.code) || [],
      subCategories: params?.dataList?.subCategories?.map(o => o.code) || [],
      vendorTypes: params?.dataList?.vendorType?.map(o => o?.code) || [],
    };
    refetchData({ data: { ...dataReq } });
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(params?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setParams({
      ...params,
      dataList: dataList,
    });
    if (storedType === 'vendorType') {
      setDataLocal(preState => ({
        ...preState,
        vendorType: dataList[storedType],
      }));
    }
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(params?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...params,
      dataList: dataList,
      [storedType]: '',
    };
    setParams(newData);
    setShowPopupByType('');
    if (storedType === 'vendorType') {
      setDataLocal(preState => ({
        ...preState,
        vendorType: dataList[storedType],
      }));
    }
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            mode="multiple"
            onSubmit={data => updateStore(popupType, data, 'productId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            mode="multiple"
            onSubmit={data => updateStore(popupType, data, 'productGroupId')}
          />
        );
      case 'largeCategories':
      case 'mediumCategories':
      case 'smallCategories':
      case 'subCategories':
      case 'vendorType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = params?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (params.endDate && e?.value && e?.value > params.endDate)
          setParams({
            ...params,
            endDate: null,
          });
        break;
      case 'endDate':
        if (params.fromDate && e?.value && e?.value < params.fromDate)
          setParams({
            ...params,
            fromDate: null,
          });
        break;
      default:
        break;
    }
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, endDateValue) => {
    if (!fromDateValue || !endDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', endDateValue);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, endDate) => onChangeDate(fromDate, endDate)}
        />
        <Form
          formData={params}
          showColonAfterLabel={false}
          ref={formRef}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !params.fromDate && params.endDate ? true : false,
            },
            {
              label: { text: t('End Date') },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !params.endDate && params.fromDate ? true : false,
            },
            {
              label: { text: t('Status') },
              dataField: 'status',
              editorType: 'dxSelectBox',
              colSpan: 2,
              editorOptions: {
                readOnly: true,
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  { label: t('All'), value: '' },
                  { label: t('Pending'), value: '0' },
                  { label: t('Fixed'), value: '1' },
                ],
              },
            },
            generateDxTextBox('Vendor', 'vendor', 'vendorName', { colSpan: 2 }),
            generateDxTextBox('Vendor Type', 'vendorType', 'name', {
              colSpan: 2,
            }),
          ]}
        ></Form>
      </>
    );
  };
  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          items={[
            generateDxTextBox('Large Category', 'largeCategories', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategories', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            generateDxTextBox('Small Category', 'smallCategories', 'name'),
            generateDxTextBox('Sub Category', 'subCategories', 'name'),
            generateDxTextBox('Product', 'product', 'productNm'),
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
