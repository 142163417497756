/**
 * Update date: 22-05-2023
 * Screen 2016.4
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const FormView = React.lazy(() => import('./FormView'));
const ListView = React.lazy(() => import('./ListView'));

const defaultSearchParams = {
  bundleName: '',
};
/**
 * create context
 */
export const ModuleContext = createContext({
  modeView: { type: '', bundleId: '' },
  setModeView: any => {},
  searchParams: {},
  setSearchParams: any => {},
  listBundles: [],
  apiList: any => {},
  printBarcode: any => {},
});

ModuleContext.displayName = 'BundlePacakgeInquiry';

export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  return context;
};

const BundlePackageInquiry = () => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [listBundles, setListBundles] = useState([]);
  const [modeView, setModeView] = useState<any>({
    type: 'list',
  });

  const [{ data: resApiList, loading: loadingApiList }, apiList] = useAxios(
    {
      url: 'warehouse/bundle',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ loading: loadingPrint }, printBarcode] = useAxios(
    {
      url: 'warehouse/bundle/print-barcode',
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const value: any = {
    modeView,
    setModeView,
    searchParams,
    setSearchParams,
    listBundles,
    apiList,
    printBarcode,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListView />;
      case 'edit':
      case 'create':
        return <FormView />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiList?.data) {
      const d = resApiList.data?.map((o: any) => {
        let obj = { ...o, print_count: '' };
        return obj;
      });
      setListBundles(d);
    }
  }, [resApiList]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiList} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default BundlePackageInquiry;
