/**
 * Update date: 24-05-2023
 * Screen 2041.3
 */
import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useMemo,
} from 'react';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import LoadPanel from 'app/components/LoadPanel';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import MenuSound from 'app/components/MenuSound';

const CenterOrder = React.lazy(() => import('./CenterOrder'));
const CenterOrderTable = React.lazy(() => import('./Table/CenterOrderTable'));
// const ReceiveDue = React.lazy(() => import('./ReceiveDue'));
// const ReceiveDueTable = React.lazy(() => import('./Table/ReceiveDueTable'));
const Receive = React.lazy(() => import('./Receive'));
const ReceiveTable = React.lazy(() => import('./Table/ReceiveTable'));
/**
 * create context
 */
const ReceivingRegistrationContext = createContext({});

ReceivingRegistrationContext.displayName = 'ReceivingRegistrationContext';

export const useReceivingRegistration = () => {
  const context = useContext(ReceivingRegistrationContext);
  return context;
};

export default function ReceivingRegistration() {
  const appContext: any = useApp();
  const { s, themePro } = appContext || {};
  const { t }: any = useDictionary({});
  const [tabPanelIndex, setTabPanelIndex] = useState<number>(0);
  const [centerOrderBillData, setCenterOrderBillData] = useState<any>([]);
  const [centerOrderData, setCenterOrderData] = useState<any>([]);
  const [receiveDueBillData, setReceiveDueBillData] = useState<any>([]);
  const [receiveDueData, setReceiveDueData] = useState<any>([]);
  const [receiveData, setReceiveData] = useState<any>([]);
  const [centerOrderZoneId, setCenterOrderZoneId] = useState<any>(null);
  const [receiveZoneId, setReceiveZoneId] = useState<any>(null);
  const [formSearchReceiveData, setFormSearchReceiveData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    status: '2',
    loading: '2',
    dataList: {
      vendor: [],
    },
    vendor: '',
  });
  const centerOrderTableRef: any = useRef(null);
  const receiveDueTableRef: any = useRef(null);
  const receiveTableRef: any = useRef(null);

  const centerOrderBillStore: any = new ArrayStore({
    data: centerOrderBillData,
    key: 'ctorderId',
  });

  const centerOrderStore: any = new ArrayStore({
    data: centerOrderData,
    key: 'lineId',
  });

  const receiveDueBillStore: any = new ArrayStore({
    data: receiveDueBillData,
    key: 'receiveDueId',
  });

  const receiveDueStore: any = new ArrayStore({
    data: receiveDueData,
    key: 'lineId',
  });

  const receiveStore: any = new ArrayStore({
    data: receiveData,
    key: ['receiveId', 'lineId'],
  });

  const [{ loading: dataLoading }, refetchData] = useAxios(
    {},
    {
      manual: true,
      autoCancel: true,
    },
  );

  /**
   * render table content
   *
   * @return {*}
   */
  const renderTableContent = () => {
    switch (tabPanelIndex) {
      case 0: // Center Order
        return <CenterOrderTable ref={centerOrderTableRef} />;
      // case 1: // Receive Due
      //   return <ReceiveDueTable ref={receiveDueTableRef} />;
      case 1: // Receive
        return <ReceiveTable ref={receiveTableRef} />;
      default:
        break;
    }
  };

  const value: any = useMemo(
    () => ({
      s,
      centerOrderBillStore,
      setCenterOrderBillData,
      receiveDueBillStore,
      setReceiveDueBillData,
      centerOrderStore,
      receiveDueStore,
      receiveStore,
      setCenterOrderData,
      setReceiveDueData,
      setReceiveData,
      centerOrderZoneId,
      receiveZoneId,
      setCenterOrderZoneId,
      setReceiveZoneId,
      centerOrderTableRef,
      receiveDueTableRef,
      formSearchReceiveData,
      setFormSearchReceiveData,
      refetchData,
    }),
    [
      s,
      centerOrderBillStore,
      setCenterOrderBillData,
      receiveDueBillStore,
      setReceiveDueBillData,
      centerOrderStore,
      receiveDueStore,
      receiveStore,
      setCenterOrderData,
      setReceiveDueData,
      setReceiveData,
      centerOrderZoneId,
      receiveZoneId,
      setCenterOrderZoneId,
      setReceiveZoneId,
      centerOrderTableRef,
      receiveDueTableRef,
      formSearchReceiveData,
      setFormSearchReceiveData,
      refetchData,
    ],
  );

  const loadingAll = dataLoading;

  return (
    <ReceivingRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <TabPanel
          swipeEnabled={false}
          defaultSelectedIndex={tabPanelIndex}
          selectedIndex={tabPanelIndex}
          onOptionChanged={(e: any) => {
            if (e?.name === 'selectedIndex') {
              setTabPanelIndex(e?.value);
            }
          }}
          className="bg-white"
        >
          <TabItem title={t('Center Order')}>
            <div style={themePro ? {} : { padding: '20px' }}>
              <CenterOrder />
            </div>
          </TabItem>
          {/* <TabItem title={t('Receive Due')}>
            <div style={themePro ? {} : { padding: '20px' }}>
              <ReceiveDue />
            </div>
          </TabItem> */}
          <TabItem title={t('Receive')}>
            <div style={themePro ? {} : { padding: '20px' }}>
              <Receive />
            </div>
          </TabItem>
        </TabPanel>
        <br />
        {renderTableContent()}
        <MenuSound />
      </React.Suspense>
    </ReceivingRegistrationContext.Provider>
  );
}
