/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { Button, Form, Popup, ScrollView } from 'devextreme-react';
import moment from 'moment';
import { useState, useRef, useMemo } from 'react';
import { usePopupOrder } from '..';
import PopupAddProduct from '../../PopupAddProduct';
import { toNumberAmount } from 'hooks/toNumberAmount';
import useDictionary from 'hooks/useDictionary';

interface IExchangeProduct {
  qty: number;
  currentPayUnitPrice: number;
  onOk: any;
  onClose: () => void;
}

/**
 * get amount
 *
 * @param {*} record
 * @return {*}
 */
const getAmount = record => {
  const { pay_unit_price = 0, shipping_qty = 0 } = record || {};
  return toNumberAmount(pay_unit_price * shipping_qty, true);
};

/**
 * get bottle
 *
 * @param {*} record
 * @return {*}
 */
const getBottle = record => {
  const {
    quantity_per_pack = 0,
    shipping_qty = 0,
    bottle_unit_price = 0,
  } = record || {};
  return toNumberAmount(bottle_unit_price * quantity_per_pack * shipping_qty);
};
/**
 * get container
 *
 * @param {*} record
 * @return {*}
 */
const getContainer = record => {
  const { container_unit_price = 0, shipping_qty = 0 } = record || {};
  return toNumberAmount(container_unit_price * shipping_qty);
};

/**
 * get add shipping amount
 *
 * @param {*} record
 * @return {*}
 */
const getAddShippingAmount = record => {
  const {
    add_shipping_price = 0,
    shipping_qty = 0,
    shipping_method,
  } = record || {};
  if (shipping_method == '2' || shipping_method == '3') {
    return toNumberAmount(add_shipping_price * shipping_qty);
  }
  return 0;
};

/**
 * get gtotal
 *
 * @param {*} record
 * @return {*}
 */
const getGTotal = (record, shippingFree) => {
  return toNumberAmount(
    getAmount(record) +
      getBottle(record) +
      getContainer(record) +
      getAddShippingAmount(record) +
      shippingFree,
  );
};

function ExchangeProduct({
  qty,
  currentPayUnitPrice,
  onOk,
  onClose,
}: IExchangeProduct) {
  const { t } = useDictionary({ programId: '2043.0' });
  const {
    formInformation,
    formData,
    DateTimeFormat,
    statusSelect,
    QtyFormat,
    AmountFormat,
    DoubleFormat,
    numberToCurrency,
  }: any = usePopupOrder();

  const [formProduct] = useState<any>({
    order_status: 'N10',
    statusName: statusSelect.find(o => o.code === 'N10')?.name || '',
    product: '',
    shipping_qty: qty || 0,
    shipping_fee: 0,
  });
  const [productData, setProductData] = useState<any>({});
  const [showPopupProduct, setShowPopupProduct] = useState(false);
  const formRef: any = useRef(null);
  const scrollRef: any = useRef(null);
  let top = 0;

  const onScroll = e => {
    top = scrollRef.current.instance.scrollTop();
  };

  const columns: any = useMemo(
    () => [
      {
        dataField: 'product_id',
        caption: t('Id'),
      },
      {
        dataField: 'product_tp_nm',
        caption: t('Product Type'),
        allowSearch: false,
      },
      {
        dataField: 'product_cd',
        caption: t('Product Code'),
        allowSearch: false,
      },
      {
        dataField: 'product_maker_nm',
        caption: t('Maker'),
      },
      {
        dataField: 'vendor_nm',
        caption: t('Vendor'),
      },
      {
        dataField: 'product_nm',
        caption: t('Product'),
      },
      {
        dataField: 'marketable_size',
        caption: t('Marketablesize'),
      },
      {
        dataField: 'option_cd',
        caption: t('Option Code'),
      },
      {
        dataField: 'option_nm',
        caption: t('Option'),
      },
      {
        dataField: 'quantity_per_pack',
        caption: t('Quantity Per Pack'),
      },
      {
        dataField: 'regist_dm',
        caption: t('Regist Date Time'),
        dataType: 'date',
        format: DateTimeFormat,
      },
    ],
    [t, DateTimeFormat],
  );

  const informationItem = (title, value, date?) => {
    return (
      <div
        style={{
          display: 'inline-block',
          padding: 10,
          backgroundColor: '#f2f4f7',
          borderRadius: 6,
          margin: '10px 10px 0 0',
        }}
      >
        <div style={{ color: '#667085' }}>{t(title)} </div>
        <span
          style={{
            color: '#101828',
            fontWeight: 600,
          }}
        >
          {date ? moment(value).format('MM/DD/YYYY HH:mm:ss') : value}
        </span>
      </div>
    );
  };
  const calculation = (data, shippingFree) => {
    const qty = formProduct?.shipping_qty || 0;
    data.shipping_qty = qty;
    const amount = getAmount(data);
    const bottle_amount = getBottle(data);
    const container_amount = getContainer(data);
    const add_shipping_amount = getAddShippingAmount(data);
    const total = getGTotal(data, shippingFree);
    return {
      amount,
      bottle_amount,
      container_amount,
      add_shipping_amount,
      total,
      shippingFree,
    };
  };
  /**
   * on add product
   *
   * @param {*} data
   */
  const onAddProduct = data => {
    if (data?.length) {
      const productSelected = data[0];
      productSelected.pay_unit_price = currentPayUnitPrice || 0;
      const dataMapping = calculation(
        productSelected,
        formProduct.shipping_fee,
      );
      setProductData({
        ...productSelected,
        ...dataMapping,
      });

      formRef?.current?.instance?.updateData('product', '');
      formRef?.current?.instance?.updateData(
        'product_cd',
        productSelected?.product_cd,
      );
    }
    setShowPopupProduct(false);
  };

  const clearProduct = () => {
    setProductData({});
    formRef?.current?.instance?.updateData('product_cd', '');
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView
        width="100%"
        height="100%"
        ref={scrollRef}
        onScroll={onScroll}
      >
        <div className="modal-popup-header">
          <span className="title-page">{t('Exchange Product')}</span>
          <div>
            <Button
              stylingMode="contained"
              type="default"
              text={t('Save')}
              icon="check"
              style={{ marginRight: 5 }}
              onClick={() => {
                onOk({ ...productData, ...formProduct });
              }}
              disabled={!Object.keys(productData)?.length}
            />
            <Button
              stylingMode="contained"
              text={t('Cancel')}
              icon="close"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="body-padding-white mb-10">
          <div className="dx-form-group-caption">{t('Order Information')}</div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            {informationItem('Order Number: ', formData.orderNumber)}
            {informationItem('Mall ID: ', formData.mallId)}
            {informationItem('Store: ', formData.store)}
            {informationItem('Order Date Time: ', formData.orderDateTime)}
          </div>
        </div>
        <div className="body-padding-white">
          <Form
            labelLocation="top"
            showColonAfterLabel={false}
            formData={formProduct}
            ref={formRef}
            items={[
              {
                caption: t('Product'),
                itemType: 'group',
                cssClass: 'group-caption-second',
                items: [
                  {
                    label: {
                      text: t('Product'),
                    },
                    dataField: 'product',
                    colSpan: 2,
                    editorOptions: {
                      onEnterKey: () => setShowPopupProduct(true),
                      buttons: [
                        {
                          name: 'productData',
                          location: 'before',
                          options: {
                            rtlEnabled: true,
                            text: productData?.product_nm || '',
                            visible: !!productData?.product_id,
                            icon: 'close',
                            onClick: clearProduct,
                          },
                        },
                        {
                          name: 'search',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            icon: 'search',
                            onClick: () => setShowPopupProduct(true),
                          },
                        },
                      ],
                    },
                  },
                  {
                    dataField: 'product_cd',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Product Code'),
                    },
                    editorOptions: {
                      disabled: true,
                    },
                  },
                  {
                    dataField: 'statusName',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Status'),
                    },
                    editorOptions: {
                      disabled: true,
                    },
                  },
                ],
              },
            ]}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {informationItem(
              'Order Quantity',
              numberToCurrency(formProduct?.shipping_qty, QtyFormat),
            )}
            <Form
              labelLocation="top"
              showColonAfterLabel={false}
              formData={productData}
              onFieldDataChanged={e => {
                if (e.dataField === 'pay_unit_price') {
                  const dataMaping = calculation(
                    productData,
                    productData?.shippingFree,
                  );
                  setProductData(preState => ({
                    ...preState,
                    ...dataMaping,
                  }));
                }
                scrollRef.current.instance.scrollTo(top);
              }}
              style={{
                marginTop: 10,
                backgroundColor: '#f2f4f7',
                borderRadius: 6,
                padding: 10,
              }}
              items={[
                {
                  itemType: 'group',
                  cssClass: 'group-caption-second',
                  colCount: 1,
                  items: [
                    {
                      label: { text: t('Supply Unit Price') },
                      dataField: 'pay_unit_price',
                      editorType: 'dxNumberBox',
                      editorOptions: {
                        format: DoubleFormat,
                        min: 0,
                      },
                    },
                  ],
                },
              ]}
            />
            {informationItem(
              'Amount',
              `${numberToCurrency(productData?.amount, AmountFormat)}`,
            )}
            {informationItem(
              'Marketable Size',
              productData?.marketable_size || '--',
            )}
            {informationItem(
              'Option / Quantity Per Pack',
              `${productData?.option_nm || '--'} / ${numberToCurrency(
                productData?.quantity_per_pack,
                QtyFormat,
              )}`,
            )}
            {informationItem(
              'Bottle / Container',
              `${numberToCurrency(
                productData?.bottle_amount,
                AmountFormat,
              )} / ${numberToCurrency(
                productData?.container_amount,
                AmountFormat,
              )}`,
            )}
            <Form
              labelLocation="top"
              showColonAfterLabel={false}
              formData={formProduct}
              onFieldDataChanged={e => {
                if (e.dataField === 'shipping_fee') {
                  const dataMaping = calculation(
                    productData,
                    parseInt(e.value),
                  );
                  setProductData(preState => ({
                    ...preState,
                    ...dataMaping,
                  }));
                }
                scrollRef.current.instance.scrollTo(top);
              }}
              style={{
                marginTop: 10,
                backgroundColor: '#f2f4f7',
                borderRadius: 6,
                padding: 10,
              }}
              items={[
                {
                  itemType: 'group',
                  cssClass: 'group-caption-second',
                  colCount: 1,
                  items: [
                    {
                      label: { text: t('Shipping Fee') },
                      dataField: 'shipping_fee',
                      editorType: 'dxNumberBox',
                      editorOptions: {
                        format: AmountFormat,
                        min: 0,
                      },
                    },
                  ],
                },
              ]}
            />
            {informationItem(
              'Add Shipping Amount',
              numberToCurrency(productData?.add_shipping_amount, AmountFormat),
            )}
            {informationItem(
              'Total',
              numberToCurrency(productData?.total, AmountFormat),
            )}
          </div>
        </div>
        {showPopupProduct && (
          <PopupAddProduct
            url="/ecommerce/order-popup/product"
            columns={columns}
            text={formProduct.product}
            dataBody={{
              keySearch: formProduct.product,
              orderId: formInformation.order_id,
            }}
            keyWord="keySearch"
            mode="single"
            visible={true}
            onHiding={() => setShowPopupProduct(false)}
            onSubmit={o => onAddProduct(o)}
          />
        )}
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup popup-order-custom-claim"
      visible={true}
      //   onHiding={onHiding}
      contentRender={content}
      height={'100vh'}
      width={500}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default ExchangeProduct;
