/**
 * Update date: 08-06-2023
 * My page component
 */
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useAxios from 'axios-hooks';
import { Button, Form, LoadPanel, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import IconEye from 'images/icon_eye.svg';
import React, { useRef, useState } from 'react';
import './style.scss';

interface IChangeEmail {
  visible?: boolean;
  onChangeEmail?: any;
  emailValue?: string;
  onHiding?: () => void;
}

const ChangeEmail = ({
  visible,
  onChangeEmail,
  emailValue,
  onHiding,
}: IChangeEmail) => {
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [formData, setFormData] = useState<any>({
    email: emailValue || '',
    oldPassword: '',
  });
  const formRef: any = useRef(null);

  const { t }: any = useDictionary({
    programId: '1031.7',
  });

  const [{ loading: changeEmailLoading }, changeEmail] = useAxios(
    {
      url: 'user/my-page/check-email',
      method: 'PUT',
    },
    {
      manual: true,
    },
  );

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    setEmailError('');
    setPasswordError('');
    const isValid = formRef?.current?.instance?.validate()?.isValid;

    if (isValid) {
      const res = await changeEmail({ data: { ...formData } });
      // notification({
      //   res,
      // });
      switch (res?.data?.status) {
        case '426':
          setEmailError(res?.data?.message);
          break;
        case '400':
          setPasswordError(res?.data?.message);
          break;
        default:
          break;
      }
      if (res?.data?.status == '200') {
        onChangeEmail(formData?.email);
        onCancel();
      }
    }
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    onHiding?.();
  };

  return (
    <>
      <Popup
        visible={visible}
        className="my-page-change-email-popup modal-content-popup my-page-change-email"
        onHiding={() => onHiding?.()}
        height={'100vh'}
        width={600}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      >
        <LoadPanel visible={changeEmailLoading} />
        <div className="my-page-change-email-header">
          <span className="title-page">{t('Change Email')}</span>
          <div>
            <Button
              stylingMode="contained"
              type="default"
              text={t('OK')}
              icon="check"
              style={{ marginRight: 5 }}
              onClick={onSubmit}
            />
            <Button
              stylingMode="contained"
              text={t('Cancel')}
              icon="close"
              onClick={() => setShowPopupConfirm(true)}
            />
          </div>
        </div>
        <Form
          ref={formRef}
          formData={formData}
          colCount={1}
          labelLocation="top"
          showColonAfterLabel={false}
          scrollingEnabled={true}
          focusStateEnabled={true}
          hoverStateEnabled={true}
          items={[
            {
              itemType: 'group',
              colCount: 1,
              items: [
                {
                  itemType: 'group',
                  items: [
                    {
                      label: {
                        text: t('New Email'),
                      },
                      dataField: 'email',
                      validationRules: [
                        {
                          type: 'required',
                          message: t('New Email is required'),
                        },
                      ],
                      helpText: emailError,
                    },
                    {
                      label: {
                        text: t('Current Password'),
                      },
                      dataField: 'oldPassword',
                      editorOptions: {
                        mode: showPassword ? 'text' : 'password',
                        buttons: [
                          {
                            name: 'confirm-password',
                            location: 'after',
                            options: {
                              stylingMode: 'text',
                              icon: IconEye,
                              onClick: () =>
                                setShowPassword(prevState => !prevState),
                            },
                          },
                        ],
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: t('Current Password is required'),
                        },
                      ],
                      helpText: passwordError,
                    },
                  ],
                },
              ],
            },
          ]}
        ></Form>
      </Popup>
      {showPopupConfirm && (
        <PopupConfirm
          title={t('Confirm Cancel')}
          visible={showPopupConfirm}
          content={t('Are you sure to cancel all the change?')}
          onOk={onCancel}
          onHiding={() => setShowPopupConfirm(false)}
        />
      )}
    </>
  );
};

export default ChangeEmail;
