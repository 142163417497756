/**
 * Update date: 26-05-2023
 * Screen 2043.6
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import FormProduct from './FormProduct';
import FormDetail from './FormDetail';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

const ListOrderHistory = React.lazy(() => import('./ListOrderHistory'));
/**
 * create context
 */
const ModuleContext = createContext({
  searchParams: {},
  listOrderHistory: [] || null,
  setSearchParams: () => {},
});

ModuleContext.displayName = 'InquiriesOrderHistory';

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const InquiriesOrderHistory = () => {
  const { t }: any = useDictionary({ programId: '2043.6' });
  const [searchParams, setSearchParams] = useState({
    searchKeywordIn: '1',
    keyword: '',
    fromDate: new Date(),
    endDate: new Date(),
    fromDeliveryDate: null,
    endDeliveryDate: null,
    fromDeliveryTime: null,
    endDeliveryTime: null,
    mallCodes: [],
    orderStatus: [],
    paymentStatus: '',
    shippingMethod: '',

    largeCategoryCodes: [],
    mediumCategoryCodes: [],
    smallCategoryCodes: [],
    subCategoryCodes: [],
    productGroupIds: [],
    productIds: [],
  });
  const [storedReceivableCds, setStoredReceivableCds] = useState([]);
  const [listOrderHistory, setListOrderHistory] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [dataLocal, setDataLocal] = useLocalStorage('2043.6_dataList', {});

  const [
    { data: resApiListOrderHistory, loading: loadingApiListOrderHistory },
    apiListOrderHistory,
  ] = useAxios(
    {
      url: '/ecommerce/orders',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    t,
    setModeView,
    modeView,
    searchParams,
    setSearchParams,
    listOrderHistory,
    apiListOrderHistory,
    storedReceivableCds,
    setStoredReceivableCds,
    dataLocal,
    setDataLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListOrderHistory />;
      case 'product':
        return <FormProduct />;
      case 'detail':
        return <FormDetail />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiListOrderHistory?.data) {
      setListOrderHistory(resApiListOrderHistory?.data);
    }
  }, [resApiListOrderHistory]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiListOrderHistory} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default InquiriesOrderHistory;
