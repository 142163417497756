/**
 * Update date: 17-05-2023
 * Screen 2012.3
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { useProductType } from 'hooks/useProductType';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));

const defaultParams = {
  storeIds: [],
  scale: '',
};
/**
 * create context
 */
const ProductsByStoreSizeContext = createContext({
  params: {},
  productStoreSizeData: [],
  setParams: () => {},
});

ProductsByStoreSizeContext.displayName = 'ProductsByStoreSizeContext';

export const useProductsByStoreSize = () => {
  const context = useContext(ProductsByStoreSizeContext);
  return context;
};

export default function ProductsByStoreSize() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [params, setParams] = useState(defaultParams);
  const [productStoreSizeData, setProductStoreSizeData] = useState([]);
  const [formData, setFormData] = useState<any>({
    scale: '',
    use: 1,
  });
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ data: storeGroupRes, loading: productLoading }, refetchData] =
    useAxios(
      {
        url: 'product/bundle-storesize',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  const [{ loading: productDeleteLoading }, productDelete] = useAxios(
    {
      url: 'product/bundle-storesize',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * on update data
   *
   * @param {*} record
   */
  const onUpdateData = record => {
    const existItem = productStoreSizeData?.some(
      (o: any) => o?.bundleStoresizeId === record?.bundleStoresizeId,
    );
    let l: any = [];
    if (existItem) {
      l = [...productStoreSizeData]?.map((o: any) => {
        if (o?.bundleStoresizeId === record?.bundleStoresizeId) return record;
        return o;
      });
    } else {
      l = [...productStoreSizeData];
      l.unshift(record);
    }
    setProductStoreSizeData(l);
  };

  const value: any = {
    productTpPermission,
    params,
    setParams,
    modeView,
    setModeView,
    productStoreSizeData,
    setProductStoreSizeData,
    refetchData,
    onUpdateData,
    productDelete,
    formData,
    setFormData,
  };

  useEffect(() => {
    if (storeGroupRes?.data) {
      setProductStoreSizeData(storeGroupRes?.data);
    }
  }, [storeGroupRes]);

  const loadingAll =
    productLoading || productDeleteLoading || loadingProductTpPermission;

  return (
    <ProductsByStoreSizeContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ProductsByStoreSizeContext.Provider>
  );
}
