/**
 * Update date: 26-05-2023
 * Screen 2043.1
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

// const FormTemplate = React.lazy(() => import('./Form'));
const ListTemplate = React.lazy(() => import('./List'));
const FormTemplate = React.lazy(() => import('./Form'));
/**
 * create context
 */
const ModuleContext = createContext({});

ModuleContext.displayName = 'RegistrationOrderUploadForm';

export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  return context;
};

export const defaultFormInfo = {
  master: {
    orderFormId: null,
    orderFormNm: '',
    mallCd: null,
    mallNm: '',
    mall: '',
    useTf: true,
    dataList: {
      mall: [],
    },
  },
  details: [],
};

const RegistrationOrderUploadForm = () => {
  const [searchParams, setSearchParams] = useState<any>({
    mailIdList: [],
    dataList: {
      mall: [],
    },
    mall: '',
    orderFormName: '',
  });
  const [listTemplate, setListTemplate] = useState([]);
  const [listFields, setListFields] = useState([]);
  const [formInfo, setFormInfo] = useState(defaultFormInfo);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  // api search list template
  const [{ data: resApiList, loading: loadingApiList }, apiList] = useAxios(
    {
      url: 'ecommerce/order-upload',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  // api delete template
  const [{ data: resApiDelete, loading: loadingApiDelete }, apiDelete] =
    useAxios(
      {
        url: 'ecommerce/order-upload/delete',
        method: 'POST',
      },
      { manual: true, autoCancel: true, useCache: false },
    );

  const onUpdateListTemplate = (record: any) => {
    // let l: any = [];
    // if (!record?.productDealId) return;
    // if (listTemplate.some(o => o['productDealId'] === record.productDealId)) {
    //   l = [...listTemplate]?.map((o: any) => {
    //     if (o?.productDealId == record?.productDealId) return record;
    //     return o;
    //   });
    // } else {
    //   l = [...listTemplate];
    //   l.push(record);
    // }
    // setListTemplate(l);
  };

  const value: any = {
    modeView,
    setModeView,
    searchParams,
    setSearchParams,
    formInfo,
    setFormInfo,
    listTemplate,
    setListTemplate,
    listFields,
    setListFields,
    apiList,
    apiDelete,
    onUpdateListTemplate,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListTemplate />;
      case 'create':
      case 'edit':
        return <FormTemplate />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiList?.data) {
      setListTemplate(resApiList?.data);
    }
  }, [resApiList]);

  useEffect(() => {
    setListFields([]);
  }, []);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiList} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default RegistrationOrderUploadForm;
