/**
 * Update date: 25-05-2023
 * Screen 2041.7
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import Form from 'devextreme-react/form';
import useFormatTime from 'hooks/useFormatTime';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useReceiving } from '..';

const listTypeCommon = {
  vendor: {
    codeType: 'VD',
    title: 'Search Vendor',
  },
  product: {
    codeType: 'PD',
    title: 'Search Product',
  },
  productType: {
    codeType: 'PT',
    title: 'Search Product Type',
  },
  productGroup: {
    codeType: 'PG',
    title: 'Search ProductGroup',
  },
  largeCategoryCodes: {
    codeType: 'LC',
    title: 'Search Large Category',
  },
  mediumCategoryCodes: {
    codeType: 'MC',
    title: 'Search Medium Category',
  },
  smallCategoryCodes: {
    codeType: 'SC',
    title: 'Search Small Category',
  },
  subCategoryCodes: {
    codeType: 'UC',
    title: 'Search Sub Category',
  },
  receiveTakeoutTp: {
    codeType: 'RD',
  },
};

const FormSearch = () => {
  const context: any = useReceiving();
  const formSearchRef: any = useRef(null);
  const { params, setParams, refetchReceivingData, t } = context;
  const [showPopupByType, setShowPopupByType] = useState('');
  const { DateFormat } = useFormatTime();

  // Onsubmit form search
  const onSubmit = async () => {
    const data = {
      ...params,
      fromDate: params?.fromDate
        ? moment(params?.fromDate).format('YYYY-MM-DD')
        : null,
      toDate: params?.toDate
        ? moment(params?.toDate).format('YYYY-MM-DD')
        : null,
      vendorIds: params?.formStore?.vendor?.map(o => o?.vendorId),
      productIds: params?.formStore?.product?.map(o => o?.productId),
      productGroupIds: params?.formStore?.productGroup?.map(
        o => o?.productGroupId,
      ),
      largeCategoryCodes: params?.formStore?.largeCategoryCodes?.map(
        o => o?.code,
      ),
      mediumCategoryCodes: params?.formStore?.mediumCategoryCodes?.map(
        o => o?.code,
      ),
      smallCategoryCodes: params?.formStore?.smallCategoryCodes?.map(
        o => o?.code,
      ),
      subCategoryCodes: params?.formStore?.subCategoryCodes?.map(o => o?.code),
      productTypes: params?.formStore?.productType?.map(o => o?.code),
      receiveTakeoutTps: params?.formStore?.receiveTakeoutTp?.map(o => o?.code),
    };
    delete data.vendor;
    delete data.product;
    delete data.productGroup;
    delete data.formStore;
    refetchReceivingData({
      data,
    });
  };
  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (params.toDate && e?.value && e?.value > params.toDate)
          formSearchRef?.current?.instance.updateData('toDate', null);
        break;
      case 'toDate':
        if (params.fromDate && e?.value && e?.value < params.fromDate)
          formSearchRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  /**
   * on change date
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formSearchRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formSearchRef?.current?.instance?.updateData('toDate', toDateValue);
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            text={params?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            mode="multiple"
            onSubmit={data => updateStore(popupType, data, 'productId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            mode="multiple"
            onSubmit={data => updateStore(popupType, data, 'productGroupId')}
          />
        );
      case 'largeCategoryCodes':
      case 'mediumCategoryCodes':
      case 'smallCategoryCodes':
      case 'subCategoryCodes':
      case 'productType':
      case 'receiveTakeoutTp':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on update store
   *
   * @param {*} popupType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (popupType, data, key: any = false) => {
    const filterData = uniqBy(
      [...params?.formStore?.[popupType], ...data],
      key,
    );
    const newStore = { ...params?.formStore, [popupType]: filterData };
    setParams(preState => ({
      ...preState,
      [popupType]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  /**
   * on remove store
   *
   * @param {*} popupType
   * @param {*} index
   */
  const removeStore = (popupType, index) => {
    let newStore;
    if (index === -1) {
      setParams(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [popupType]: [],
        },
      }));
    } else {
      const l = [...params?.formStore[popupType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setParams(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [popupType]: newStore,
        },
      }));
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          formData={params}
          showColonAfterLabel={false}
          ref={formSearchRef}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            {
              label: { text: t('End Date') },
              dataField: 'toDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            {
              label: { text: t('Vendor') },
              dataField: 'vendor',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('vendor'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.vendor[0]?.vendorName,
                      onClick: () => removeStore('vendor', 0),
                      visible: !!params?.formStore?.vendor[0]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.vendor[1]?.vendorName,
                      onClick: () => removeStore('vendor', 1),
                      visible: !!params?.formStore?.vendor[1]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${params?.formStore?.vendor?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: params?.formStore?.vendor?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.vendor?.length,
                      onClick: () => removeStore('vendor', -1),
                    },
                  },

                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('vendor'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Status') },
              dataField: 'status',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  { label: t('All'), value: '' },
                  { label: t('Pending'), value: '0' },
                  { label: t('Fixed'), value: '1' },
                ],
              },
            },
            {
              label: { text: t('Receive Data Owner') },
              dataField: 'receiveTakeoutTp',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('receiveTakeoutTp'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.receiveTakeoutTp[0]?.name,
                      onClick: () => removeStore('receiveTakeoutTp', 0),
                      visible: !!params?.formStore?.receiveTakeoutTp[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.receiveTakeoutTp[1]?.name,
                      onClick: () => removeStore('receiveTakeoutTp', 1),
                      visible: !!params?.formStore?.receiveTakeoutTp[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.receiveTakeoutTp?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.receiveTakeoutTp?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.receiveTakeoutTp?.length,
                      onClick: () => removeStore('receiveTakeoutTp', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('receiveTakeoutTp'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Task Type') },
              dataField: 'taskType',
              editorType: 'dxRadioGroup',
              editorOptions: {
                valueExpr: 'value',
                items: [
                  { text: t('All'), value: 2 },
                  { text: t('Receiving'), value: 0 },
                  { text: t('Take Out'), value: 1 },
                ],
                layout: 'horizontal',
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
          onFieldDataChanged={onFieldDataChanged}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'largeCategoryCodes',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('largeCategoryCodes'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.largeCategoryCodes[0]?.name,
                      onClick: () => removeStore('largeCategoryCodes', 0),
                      visible: !!params?.formStore?.largeCategoryCodes[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.largeCategoryCodes[1]?.name,
                      onClick: () => removeStore('largeCategoryCodes', 1),
                      visible: !!params?.formStore?.largeCategoryCodes[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.largeCategoryCodes?.length - 2
                      } ${t('more')}`,
                      visible:
                        params?.formStore?.largeCategoryCodes?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.largeCategoryCodes?.length,
                      onClick: () => removeStore('largeCategoryCodes', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('largeCategoryCodes'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mediumCategoryCodes',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('mediumCategoryCodes'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.mediumCategoryCodes[0]?.name,
                      onClick: () => removeStore('mediumCategoryCodes', 0),
                      visible:
                        !!params?.formStore?.mediumCategoryCodes[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.mediumCategoryCodes[1]?.name,
                      onClick: () => removeStore('mediumCategoryCodes', 1),
                      visible:
                        !!params?.formStore?.mediumCategoryCodes[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.mediumCategoryCodes?.length - 2
                      } ${t('more')}`,
                      visible:
                        params?.formStore?.mediumCategoryCodes?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.mediumCategoryCodes?.length,
                      onClick: () => removeStore('mediumCategoryCodes', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('mediumCategoryCodes'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('productGroup'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productGroup[0]?.productGroupNm,
                      onClick: () => removeStore('productGroup', 0),
                      visible:
                        !!params?.formStore?.productGroup[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productGroup[1]?.productGroupNm,
                      onClick: () => removeStore('productGroup', 1),
                      visible:
                        !!params?.formStore?.productGroup[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.productGroup?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.productGroup?.length,
                      onClick: () => removeStore('productGroup', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('productGroup'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smallCategoryCodes',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('smallCategoryCodes'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.smallCategoryCodes[0]?.name,
                      onClick: () => removeStore('smallCategoryCodes', 0),
                      visible: !!params?.formStore?.smallCategoryCodes[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.smallCategoryCodes[1]?.name,
                      onClick: () => removeStore('smallCategoryCodes', 1),
                      visible: !!params?.formStore?.smallCategoryCodes[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.smallCategoryCodes?.length - 2
                      } ${t('more')}`,
                      visible:
                        params?.formStore?.smallCategoryCodes?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.smallCategoryCodes?.length,
                      onClick: () => removeStore('smallCategoryCodes', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('smallCategoryCodes'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategoryCodes',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('subCategoryCodes'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.subCategoryCodes[0]?.name,
                      onClick: () => removeStore('subCategoryCodes', 0),
                      visible: !!params?.formStore?.subCategoryCodes[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.subCategoryCodes[1]?.name,
                      onClick: () => removeStore('subCategoryCodes', 1),
                      visible: !!params?.formStore?.subCategoryCodes[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.subCategoryCodes?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.subCategoryCodes?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.subCategoryCodes?.length,
                      onClick: () => removeStore('subCategoryCodes', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('subCategoryCodes'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('product'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.product[0]?.productNm,
                      onClick: () => removeStore('product', 0),
                      visible: !!params?.formStore?.product[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.product[1]?.productNm,
                      onClick: () => removeStore('product', 1),
                      visible: !!params?.formStore?.product[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${params?.formStore?.product?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: params?.formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.product?.length,
                      onClick: () => removeStore('product', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('product'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Type') },
              dataField: 'productType',
              editorOptions: {
                readOnly: params?.isReadOnlyProductTp,
                onEnterKey: () => setShowPopupByType('productType'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productType[0]?.name,
                      onClick: () => removeStore('productType', 0),
                      visible: !!params?.formStore?.productType[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productType[1]?.name,
                      onClick: () => removeStore('productType', 1),
                      visible: !!params?.formStore?.productType[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.productType?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.productType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.productType?.length,
                      onClick: () => removeStore('productType', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('productType'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={4}
        ></Form>
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
