/* Update date: 12-06-2023
 * Popup select product group component
 */
import { DataGridFull } from 'app/components/DataGrid';
import { Button, Popup, ScrollView, TextBox } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useRef, useEffect } from 'react';
import useAxios from 'axios-hooks';
import useFormatTime from 'hooks/useFormatTime';
import useDictionary from 'hooks/useDictionary';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';
interface IPopupSelectMultiProps {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit: (selected: any) => void;
  mode?: 'single' | 'multiple';
}

const PopupSelectProductGroup = ({
  visible,
  onHiding,
  text,
  onSubmit,
  mode = 'single',
}: IPopupSelectMultiProps) => {
  const { themePro }: any = useApp();
  const [name, setName] = useState<any>(text);
  const dataRef: any = useRef(null);
  const { DateTimeFormat } = useFormatTime();
  const { t } = useDictionary({});

  const [{ data: listData, loading }, fetchData] = useAxios(
    {
      url: '/product/product_group',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * on search
   *
   * @param {string} name
   */
  const onSearch = (name: string) => {
    fetchData({
      data: {
        productGroupNm: name,
        sortBy: true,
      },
    });
  };

  /**
   * on ok
   *
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!mode || mode === 'single') {
      const d = selectedRowKeys?.[0];
      onSubmit(d);
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  useEffect(() => {
    onSearch(name);
  }, []);

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Product Group')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }
            }
          >
            <TextBox
              width={themePro ? 400 : '40vw'}
              defaultValue={name}
              onValueChanged={e => setName(e?.value)}
              onEnterKey={e => onSearch(e?.component?.option('value') || '')}
              style={{ marginRight: 10 }}
            />
            <Button
              icon="search"
              text={t('Search')}
              onClick={() => onSearch(name)}
            />
          </div>
          <DataGridFull
            ref={dataRef}
            dataSource={listData?.data}
            columns={[
              {
                dataField: 'productGroupId',
                caption: t('ID'),
                alignment: 'left',
              },
              {
                dataField: 'productGroupNm',
                caption: t('Product Group'),
              },
              {
                dataField: 'regisDate',
                caption: t('Regist date time'),
                alignment: 'left',
                dataType: 'date',
                format: DateTimeFormat,
              },
            ]}
            options={{
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode,
                selectAllMode: 'allPages',
              },
              height: themePro
                ? 'calc(100vh - 200px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Product Group')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
};

export default PopupSelectProductGroup;
