/**
 * Update date: 07-06-2023
 * Screen 2081.8
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ListSupplyUnitPriceInquiryByMarket from './ListSupplyUnitPriceInquiryByMarket';
import { CONSTANT, TRADE_TYPE } from '../../../constant';
import useFormat from '../../../hooks/useFormat';
import useDictionary from 'hooks/useDictionary';
import { uniqWith } from 'lodash';
import { useProductType } from 'hooks/useProductType';

const defaultParams = {
  storeIds: [],
  supplyUnitPriceGroupIds: [],
  productTypes: [],
  product: '',
  largeCategoryCodes: [],
  mediumCategoryCodes: [],
  smallCategoryCodes: [],
  subCategoryCodes: [],
  productGroupIds: [],
};
/**
 * create context
 */
const SupplyUnitPriceInquiryByMarketContext = createContext({
  params: {},
  setParams: () => {},
  makeDataStores: () => {},
  modeView: {},
  setModeView: () => {},
  columns: {},
});

SupplyUnitPriceInquiryByMarketContext.displayName =
  'SupplyUnitPriceInquiryByMarketContext';

export const useSupplyUnitPriceInquiryByMarket = () => {
  return useContext(SupplyUnitPriceInquiryByMarketContext);
};

const { divisionTypes } = CONSTANT;

const SupplyUnitPriceInquiryByMarket = () => {
  const { t } = useDictionary({ programId: '2081.8' });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const dataGridRef: any = useRef(null);

  const [params, setParams] = useState(defaultParams);

  const [modeView, setModeView] = useState({ type: 'list' });

  const [dataStores, setDataStores] = useState<any>([]);

  const [columns, setColumns] = useState<any>([]);

  const [loadingAll, setLoadingAll] = useState<boolean>(true);

  const { AmountFormat } = useFormat();

  const store: any = new ArrayStore({
    data: dataStores,
  });

  const [{}, refetchReceive] = useAxios(
    {
      url: '/product/supply-rate',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  const [{}, executeGetCommonCode] = useAxios(
    {
      url: '/core/common-code',
      method: 'POST',
    },
    { useCache: false, autoCancel: true },
  );

  /**
   * render division type
   *
   * @param {*} record
   * @return {*}
   */
  const renderDivisionType = record => {
    if (!record?.value) return '';
    return divisionTypes.find(o => o.value == record?.value)?.label;
  };

  /**
   * on filter data
   *
   * @return {*}
   */
  const headerFilterDataDivisionType = () => {
    return divisionTypes.map(item => {
      return {
        text: item.label,
        value: ['divisionType', '=', `${t(item.value)}`],
      };
    });
  };

  /**
   * render trader type
   *
   * @param {*} record
   * @return {*}
   */
  const renderTraderType = record => {
    if (!record?.value) return '';
    return TRADE_TYPE.find(o => o.value == record?.value)?.text;
  };

  /**
   * on filter data
   *
   * @return {*}
   */
  const headerFilterDataTradeType = () => {
    return TRADE_TYPE.map(item => {
      return {
        text: item.text,
        value: ['tradeTp', '=', `${t(item.value)}`],
      };
    });
  };

  const makeDataGridColumns = async () => {
    const shipmentRes = await executeGetCommonCode({
      data: {
        codeType: 'FT',
      },
    });

    const shipmentDataList = shipmentRes?.data?.data;

    let makeColumns: any = [
      {
        caption: t('Store'),
        alignment: 'center',
        items: [
          {
            dataField: 'storeNm',
            caption: t('Store'),
            alignment: 'left',
          },
          {
            dataField: 'supplyUnitPriceGroupNm',
            caption: t('Supply Unit Price Group'),
            alignment: 'left',
          },
        ],
      },
      {
        caption: t('Product'),
        alignment: 'center',
        items: [
          {
            dataField: 'productTpNm',
            caption: t('Product Type'),
            alignment: 'left',
          },
          {
            dataField: 'productCd',
            caption: t('Product Code'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'productNm',
            caption: t('Product'),
            alignment: 'left',
          },
          {
            dataField: 'optionNm',
            caption: t('Product Option'),
            alignment: 'left',
          },
          {
            dataField: 'optionCd',
            caption: t('Option Code'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'divisionType',
            caption: t('Division Type'),
            cellRender: renderDivisionType,
            alignment: 'left',
            headerFilter: {
              dataSource: headerFilterDataDivisionType(),
            },
            visible: false,
          },
          {
            dataField: 'productMakerNm',
            caption: t('Maker'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'marketableSize',
            caption: t('Marketable Size'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'tradeTp',
            caption: t('Trade Type'),
            cellRender: renderTraderType,
            alignment: 'left',
            headerFilter: {
              dataSource: headerFilterDataTradeType(),
            },
            visible: false,
          },
          {
            dataField: 'quantityPerPack',
            caption: t('Quantity Per Pack'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'vendorNmDefault',
            caption: t('Default Vendor'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'standardUnitPrice',
            caption: t('Standard Unit Price'),
            format: AmountFormat,
            dataType: 'number',
          },
          {
            dataField: 'shippingRate',
            caption: t('Shipping Rate'),
            format: AmountFormat,
            dataType: 'number',
          },
        ],
      },
    ];

    let marginDynamicColumnFields: any = [];
    let supplyUnitPriceDynamicColumnFields: any = [];
    let shippingFeeDynamicColumnFields: any = [];

    shipmentDataList.forEach(shipment => {
      const { code, name } = shipment;

      marginDynamicColumnFields.push({
        dataField: `margin_${name}_${code}`,
        caption: t(`${name}(%)`),
        format: AmountFormat,
        dataType: 'number',
      });

      supplyUnitPriceDynamicColumnFields.push({
        dataField: `supplyUnitPrice_${name}_${code}`,
        caption: t(`${name}`),
        format: AmountFormat,
        dataType: 'number',
      });

      shippingFeeDynamicColumnFields.push({
        dataField: `shippingFee${name}_${code}`,
        caption: t(`${name}`),
        format: AmountFormat,
        dataType: 'number',
      });
    });

    makeColumns.push(
      {
        caption: t('Margin'),
        alignment: 'center',
        items: marginDynamicColumnFields,
      },
      {
        caption: t('Supply Unit Price'),
        alignment: 'center',
        items: supplyUnitPriceDynamicColumnFields,
      },
      {
        caption: t('Shipping'),
        alignment: 'center',
        items: shippingFeeDynamicColumnFields,
      },
    );

    setColumns(makeColumns);
    setLoadingAll(false);
  };

  /**
   * on make data store
   *
   * @param {*} params
   */
  const makeDataStores = async params => {
    setLoadingAll(true);

    const supplyRateRes = await refetchReceive({
      data: params,
    });

    const supplyRateData = supplyRateRes?.data?.data;

    const shipmentRes = await executeGetCommonCode({
      data: {
        codeType: 'FT',
      },
    });

    const shipmentDataList = shipmentRes?.data?.data;

    // const supplyGroupRes = await executeGetCommonCode({
    //   data: {
    //     codeType: 'SP',
    //   },
    // });

    // const supplyGroupDataList = supplyGroupRes?.data?.data;

    const { stores, products, marginRates } = supplyRateData;
    let makeDataStores: any = [];
    const marginRatesMap = marginRates?.map(o => {
      const shipment = shipmentDataList.find(f => f?.code === o?.shipmentType);
      if (shipment) {
        return { ...o, shipmentName: shipment?.name };
      }
    });
    const marginRatesUniqProductStore = uniqWith(
      marginRatesMap,
      (a: any, b: any) =>
        a?.supplyUnitPriceGroup === b?.supplyUnitPriceGroup &&
        a?.productType === b?.productType,
    );
    let marginStore: any = [];
    let dictionaryProduct = {};
    stores.forEach((e: any) => {
      marginRatesUniqProductStore.forEach((m: any) => {
        if (e?.supplyUnitPriceGroup === m?.supplyUnitPriceGroup) {
          marginStore.push({ ...e, ...m });
        }
      });
    });
    marginStore.forEach(o => {
      if (dictionaryProduct[o?.productType]) {
        dictionaryProduct[o?.productType] =
          dictionaryProduct[o?.productType].concat(o);
      } else {
        dictionaryProduct[o?.productType] = [o];
      }
    });
    let dictionaryMargin = {};
    marginRatesMap.forEach(o => {
      dictionaryMargin[
        `${o?.productType}_${o?.shipmentType}_${o?.supplyUnitPriceGroup}`
      ] = o;
    });

    for (const product of products) {
      const { standardUnitPrice, productTp } = product;
      if (dictionaryProduct[productTp] !== undefined) {
        dictionaryProduct[productTp].forEach((e: any) => {
          const { storeNm, supplyUnitPriceGroupNm, shippingRate } = e;
          let dataStoreItem = { ...product };
          dataStoreItem['storeNm'] = storeNm;
          dataStoreItem['shippingRate'] = shippingRate;
          dataStoreItem['supplyUnitPriceGroupNm'] = supplyUnitPriceGroupNm;
          shipmentDataList?.forEach(s => {
            const rate =
              dictionaryMargin[
                `${e?.productType}_${s?.code}_${e?.supplyUnitPriceGroup}`
              ]?.['marginRate'] || 0;
            dataStoreItem[`margin_${s?.name}_${s?.code}`] = rate;
            dataStoreItem[`supplyUnitPrice_${s?.name}_${s?.code}`] =
              (standardUnitPrice * (100 + (rate || 0))) / 100;
            dataStoreItem[`shippingFee${s?.name}_${s?.code}`] =
              ((standardUnitPrice * (100 + (rate || 0))) / 100 +
                (((standardUnitPrice * (100 + (rate || 0))) / 100) *
                  (rate || 0)) /
                  100) *
              ((shippingRate || 0) / 100);
          });
          makeDataStores.push(dataStoreItem);
        });
      }
    }
    setDataStores(makeDataStores);
    setLoadingAll(false);
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListSupplyUnitPriceInquiryByMarket ref={dataGridRef} />;
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    params,
    setParams,
    makeDataStores,
    columns,
    store,
    modeView,
    setModeView,
  };

  useEffect(() => {
    makeDataGridColumns();
  }, []);
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      productTypeData: productTpPermission,
      productTypes: productTpPermission?.map(o => o?.code),
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  return (
    <SupplyUnitPriceInquiryByMarketContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll || loadingProductTpPermission} />
        {renderContent()}
      </React.Suspense>
    </SupplyUnitPriceInquiryByMarketContext.Provider>
  );
};

export default SupplyUnitPriceInquiryByMarket;
