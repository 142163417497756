/**
 * Update date: 05-06-2023
 * Screen 2054.8
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const InventoryComparisonContext = createContext({
  productComparisonData: [],
});

InventoryComparisonContext.displayName = 'InventoryComparisonContext';

export const useInventoryComparison = () => {
  const context = useContext(InventoryComparisonContext);
  return context;
};

export default function InventoryComparison() {
  const [productComparisonData, setProductComparisonData] = useState([]);
  const [locationManage, setLocationManage] = useState('0');
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      dueDiligence: [],
      zone: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      product: [],
      productGroup: [],
      productMakers: [],
    },
    dueDiligence: '',
    zone: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    productMakers: '',
    splanStartDt: null,
    splanEndDt: null,
    splanAdjustDt: null,
    splanReasonNm: '',
  });

  // Get data
  const [{ data: productRes, loading: productLoading }, refetchData] = useAxios(
    {
      url: 'warehouse/inv-comp',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const value: any = {
    formSearchData,
    setFormSearchData,
    productComparisonData,
    refetchData,
    locationManage,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (productRes?.data) {
      setLocationManage(productRes?.data?.locationManage || '0');
      setProductComparisonData(productRes?.data?.data || []);
    }
  }, [productRes]);

  const loadingAll = productLoading;

  return (
    <InventoryComparisonContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <List />
      </React.Suspense>
    </InventoryComparisonContext.Provider>
  );
}
