/**
 * Update date: 05-06-2023
 * Screen 2054.9
 */
import useAxios from 'axios-hooks';
import React, { createContext, useContext, useState } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const InventoryStockTakeAdjustmentBeforeApprovalContext = createContext({});

InventoryStockTakeAdjustmentBeforeApprovalContext.displayName =
  'InventoryStockTakeAdjustmentBeforeApprovalContext';

export const useInventoryStockTakeAdjustmentBeforeApproval = () => {
  return useContext(InventoryStockTakeAdjustmentBeforeApprovalContext);
};

export default function InventoryStockTakeAdjustmentBeforeApproval() {
  const [data, setData] = useState([]);
  const [locationManage, setLocationManage] = useState('0');

  const [{ loading: productLoading }, refetchData] = useAxios(
    {
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: data,
    key: ['zoneId', 'locationId', 'productId'],
  });

  const value: any = {
    store,
    setData,
    refetchData,
    locationManage,
    setLocationManage,
  };

  return (
    <InventoryStockTakeAdjustmentBeforeApprovalContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={productLoading} />
        <List />
      </React.Suspense>
    </InventoryStockTakeAdjustmentBeforeApprovalContext.Provider>
  );
}
