/**
 * Create date: 09-08-2023
 * Screen 2016.7
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import { MALL_STATUS_TP, RESERVE_TYPE } from 'constant/dataConstant';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { createContext, useContext, useState } from 'react';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const CreateEditView = React.lazy(() => import('./CreateEditView'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2016_7_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2016_7 = () => {
  const { t }: any = useDictionary({ programId: '2016.7' });
  const [data, setData] = useState([]);
  const { AmountFormat } = useFormat();
  const [formSearchData, setFormSearchData] = useState<any>({
    collectionOptionName: '',
    display: '0',
  });
  const { DateTimeFormat } = useFormatTime();
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'collect_id',
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      collectionName: formSearchData?.collectionOptionName,
      display:
        formSearchData?.display === ''
          ? null
          : formSearchData?.display === '0'
          ? true
          : false,
    };

    const res = await refetchData({
      url: '/warehouse/collection-option',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const dataMapping = (res?.data?.data || []).map(o => ({
        ...o,
        reserve_tp_nm: o?.reserve_tp
          ? t(RESERVE_TYPE.find(r => r.value === o?.reserve_tp)?.label || '')
          : '',
        mall_status_tp_nm: o?.mall_status_tp
          ? t(
              MALL_STATUS_TP.find(m => m.value === o?.mall_status_tp)?.label ||
                '',
            )
          : '',
      }));
      setData(dataMapping);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    setData,
    modeView,
    AmountFormat,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    store,
    DateTimeFormat,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <CreateEditView />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2016_7;
