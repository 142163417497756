/**
 * Update date: 2023-08-31
 * Screen 2054.E
 */
import React, { useState, useContext, createContext } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import FormSearch from './FormSearch';
import useDictionary from 'hooks/useDictionary';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import GridAll from './GridAll';
import GridByZone from './GridByZone';
import GridDetail from './GridDetail';
import GridDetail2 from './GridDetail2';

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = '2054.E';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2054_E = () => {
  // create state
  const { t }: any = useDictionary({ programId: '2054.E' });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataTabAll, setDataTabAll] = useState<any>([]);
  const [dataTabZone, setDataTabZone] = useState<any>([]);
  const [dataDetail, setDataDetail] = useState<any>([]);
  const [dataDetail2, setDataDetail2] = useState<any>([]);

  /**
   * on change tab
   *
   * @param {*} e
   */
  const onSelectedIndexChange = e => {
    setSelectedIndex(e);
  };

  const value: any = {};

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <FormSearch
          selectedIndex={selectedIndex}
          setDataTabAll={setDataTabAll}
          setDataTabZone={setDataTabZone}
          setDataDetail={setDataDetail}
          setDataDetail2={setDataDetail2}
        />
        <TabPanel
          defaultSelectedIndex={selectedIndex}
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
          swipeEnabled={false}
          className="bg-white"
        >
          <Item title={t('All')}>
            <GridAll data={dataTabAll} t={t} />
          </Item>
          <Item title={t('Zone')}>
            <GridByZone data={dataTabZone} t={t} />
          </Item>
          <Item title={t('Detail')}>
            <GridDetail data={dataDetail} t={t} />
          </Item>
          <Item title={t('Detail 2')}>
            <GridDetail2 data={dataDetail2} t={t} />
          </Item>
        </TabPanel>
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2054_E;
