import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaginationState {
  itemPerPage: number;
  page: number;
  itemPerPageSecondary?: number;
  pageSecondary?: number;
}

const initialState: PaginationState = {
  itemPerPage: 20,
  page: 0,
  itemPerPageSecondary: 20,
  pageSecondary: 0,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    changeItemPerPage(state, action: PayloadAction<number>) {
      state.itemPerPage = action.payload;
      state.page = 0;
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    reset(state) {
      state.itemPerPage = 20;
      state.page = 0;
    },
    changeItemPerPageSecondary(state, action: PayloadAction<number>) {
      state.itemPerPageSecondary = action.payload;
      state.pageSecondary = 0;
    },
    changePageSecondary(state, action: PayloadAction<number>) {
      state.pageSecondary = action.payload;
    },
    resetSecondary(state) {
      state.itemPerPageSecondary = 20;
      state.pageSecondary = 0;
    },
  },
});
//Action
export const paginationActions = paginationSlice.actions;

//Selectors
const selectItemPerPage = (state: any) => state.pagination.itemPerPage;
const selectPage = (state: any) => state.pagination.page;
const selectItemPerPageSecondary = (state: any) =>
  state.pagination.itemPerPageSecondary;
const selectPageSecondary = (state: any) => state.pagination.pageSecondary;

export const paginationSelect = {
  selectItemPerPage,
  selectPage,
  selectItemPerPageSecondary,
  selectPageSecondary,
};

const paginationReducer = paginationSlice.reducer;

export default paginationReducer;
