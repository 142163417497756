/**
 * Update date: 18-05-2023
 * Screen 2013.5
 */
import React, { useState, useRef, useEffect } from 'react';
import { uniqBy } from 'lodash';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import useFormatTime from 'hooks/useFormatTime';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectSpecialDeal from 'app/components/PopupCommon/PopupSelectSpecialDeal';
import { useSpecialDeal } from '..';
import moment from 'moment';

const FormSearch = () => {
  const context: any = useSpecialDeal();
  const { refetchData, t, productTpPermission } = context;
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const [formStore, setFormStore] = useState<any>({
    lgCategory: [],
    mdCategory: [],
    smCategory: [],
    subCategory: [],
    product: [],
    productGroup: [],
    prdDealId: [],
    productType: [],
  });
  const [formData, setFormData] = useState<any>({
    lgCategory: '',
    mdCategory: '',
    smCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    prdDealNm: '',
    fromDate: new Date(),
    endDate: new Date(),
    use: '',
    prdDealId: '',
    productType: '',
  });

  const [isShowPopupCommon, setIsShowPopupCommon] = useState(false);
  const [typeCommon, setTypeCommon] = useState('');
  const [isShowPopupProductGroup, setIsShowPopupProductGroup] = useState(false);
  const [isShowPopupProduct, setIsShowPopupProduct] = useState(false);
  const [isShowPopupDeal, setIsShowPopupDeal] = useState(false);

  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormStore(preState => ({
      ...preState,
      productType: productTpPermission,
    }));
    setFormData(preState => ({
      ...preState,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  /**
   * on change date
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };
  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'SD':
        name = 'prdDealId';
        break;
      case 'PT':
        name = 'productType';
        break;
      default:
        break;
    }
    let newData: any =
      type === 'LC' ||
      type === 'MC' ||
      type === 'SC' ||
      type === 'UC' ||
      type === 'PT'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.code !== data?.code,
          )
        : type === 'PD'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productId !== data?.productId,
          )
        : type === 'PG'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productGroupId !== data?.productGroupId,
          )
        : [...formStore?.[name]].filter(
            (filter: any) => filter?.productDealId !== data?.productDealId,
          );
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
  };
  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'SD':
        name = 'prdDealId';
        break;
      case 'PT':
        name = 'productType';
        break;
      default:
        break;
    }
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: [] }));
  };
  const handleShowPopupProduct = type => {
    setIsShowPopupProduct(true);
    setTypeCommon(type);
  };

  const onPopupSpecialDeal = type => {
    setIsShowPopupDeal(true);
    setTypeCommon(type);
  };

  /**
   * on field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        setFormData(preState => ({ ...preState, fromDate: e?.value }));
        if (formData.endDate && e?.value && e?.value > formData.endDate)
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        setFormData(preState => ({ ...preState, endDate: e?.value }));
        if (formData.fromDate && e?.value && e?.value < formData.fromDate)
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  const handleShowPopupCommon = type => {
    setIsShowPopupCommon(true);
    setTypeCommon(type);
  };

  const handleShowPopupProductGroup = type => {
    setIsShowPopupProductGroup(true);
    setTypeCommon(type);
  };

  const closePopup = () => {
    setTypeCommon('');
    setIsShowPopupCommon(false);
    setIsShowPopupProduct(false);
    setIsShowPopupProductGroup(false);
    setIsShowPopupDeal(false);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          onFieldDataChanged={onFieldDataChanged}
          colCount={4}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
            {
              colSpan: 2,
              label: { text: t('Special Deal') },
              dataField: 'prdDealId',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  onPopupSpecialDeal('SD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.prdDealId?.[0]?.productDealName,
                      onClick: () =>
                        handleRemove('SD', formStore?.prdDealId?.[0]),
                      visible: !!formStore?.prdDealId?.[0]?.productDealId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.prdDealId?.[1]?.productDealName,
                      onClick: () =>
                        handleRemove('SD', formStore?.prdDealId?.[1]),
                      visible: !!formStore?.prdDealId?.[1]?.productDealId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.prdDealId?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.prdDealId?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.prdDealId?.length,
                      onClick: () => handleCleanAll('SD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => onPopupSpecialDeal('SD'),
                    },
                  },
                ],
              },
            },
          ]}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'lgCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('LC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[0]),
                      visible: !!formStore?.lgCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[1]),
                      visible: !!formStore?.lgCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.lgCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.lgCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.lgCategory?.length,
                      onClick: () => handleCleanAll('LC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('LC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mdCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('MC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[0]),
                      visible: !!formStore?.mdCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[1]),
                      visible: !!formStore?.mdCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mdCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mdCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mdCategory?.length,
                      onClick: () => handleCleanAll('MC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('MC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupProductGroup('PG');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[0]),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[1]),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => handleCleanAll('PG'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupProductGroup('PG'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('SC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[0]),
                      visible: !!formStore?.smCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[1]),
                      visible: !!formStore?.smCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smCategory?.length,
                      onClick: () => handleCleanAll('SC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('SC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('UC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[0]),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[1]),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => handleCleanAll('UC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('UC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupProduct('PD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[0]),
                      visible: !!formStore?.product?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[1]),
                      visible: !!formStore?.product?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => handleCleanAll('PD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupProduct('PD'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Type') },
              dataField: 'productType',
              editorType: 'dxTextBox',
              editorOptions: {
                readOnly: formData?.isReadOnlyProductTp,
                onEnterKey: () => {
                  handleShowPopupCommon('PT');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productType?.[0]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.productType?.[0]),
                      visible: !!formStore?.productType?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productType?.[1]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.productType?.[1]),
                      visible: !!formStore?.productType?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productType?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productType?.length,
                      onClick: () => handleCleanAll('PT'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('PT'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={4}
        ></Form>
      </>
    );
  };

  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    let name = '';

    switch (typeCommon) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'SD':
        name = 'prdDealId';
        break;
      case 'PT':
        name = 'productType';
        break;
      default:
        break;
    }
    let newData: any =
      typeCommon === 'LC' ||
      typeCommon === 'MC' ||
      typeCommon === 'SC' ||
      typeCommon === 'UC' ||
      typeCommon === 'PT'
        ? uniqBy([...formStore?.[name]].concat(data), 'code')
        : typeCommon === 'PD'
        ? uniqBy([...formStore?.[name]].concat(data), 'productId')
        : typeCommon === 'PG'
        ? uniqBy([...formStore?.[name]].concat(data), 'productGroupId')
        : uniqBy([...formStore?.[name]].concat(data), 'productDealId');

    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
    closePopup();
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      largeCategories: formStore.lgCategory.map((map: any) => map?.code),
      mediumCategories: formStore.mdCategory.map((map: any) => map?.code),
      smallCategories: formStore.smCategory.map((map: any) => map?.code),
      subCategories: formStore.subCategory.map((map: any) => map?.code),
      productIds: formStore.product.map((map: any) => map?.productId),
      productGroupIds: formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      productDealIds: formStore.prdDealId.map((map: any) => map?.productDealId),
      fromDate: formData.fromDate
        ? moment(formData.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData.endDate
        ? moment(formData.endDate).format('YYYY-MM-DD')
        : null,
      use: formData.use,
      productDealName: formData.prdDealNm,
      productTypes: formStore.productType.map((map: any) => map?.code),
    };
    refetchData({
      data,
    });
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {isShowPopupCommon && (
        <PopupSelectCommonCode
          visible={isShowPopupCommon}
          text={
            typeCommon === 'LC'
              ? formData.lgCategory
              : typeCommon === 'MC'
              ? formData.mdCategory
              : typeCommon === 'SC'
              ? formData.smCategory
              : typeCommon === 'UC'
              ? formData.subCategory
              : typeCommon === 'PT'
              ? formData.productType
              : ''
          }
          onHiding={() => {
            closePopup();
          }}
          codeType={typeCommon}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
      {isShowPopupProduct && (
        <PopupSelectProduct
          visible={isShowPopupProduct}
          text={formData.product}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}

      {isShowPopupProductGroup && (
        <PopupSelectProductGroup
          visible={isShowPopupProductGroup}
          text={formData.productGroup}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
      {isShowPopupDeal && (
        <PopupSelectSpecialDeal
          visible={isShowPopupDeal}
          text={formData.prdDealId}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
    </div>
  );
};

export default FormSearch;
