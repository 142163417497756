/**
 * Update date: 29-05-2023
 * Screen 2046.2
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const DirectVehicleContext = createContext({
  directVehicleListData: {},
});

DirectVehicleContext.displayName = 'DirectVehicleContext';

export const useDirectVehicle = () => {
  const context = useContext(DirectVehicleContext);
  return context;
};

export default function DirectVehicle() {
  const [directVehicleListData, setDirectVehicleListData] = useState([]);

  // Get data
  const [
    { data: directVehicleRes, loading: directVehicleLoading },
    refetchData,
  ] = useAxios(
    {
      url: 'ecommerce/direct-vehicle',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const [{ loading: directLoading }, refetchLoad] = useAxios(
    {
      url: 'ecommerce/direct-vehicle/auto',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const value: any = {
    directVehicleListData,
    setDirectVehicleListData,
    refetchData,
    refetchLoad,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (directVehicleRes?.data) {
      setDirectVehicleListData(directVehicleRes?.data);
    }
  }, [directVehicleRes]);

  const loadingAll = directVehicleLoading || directLoading;

  return (
    <DirectVehicleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <List />
      </React.Suspense>
    </DirectVehicleContext.Provider>
  );
}
