/**
 * Update date: 15-05-2023
 * Screen 1044.4
 */
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { useRef } from 'react';
import { useReportStore } from '..';
const exportFileName =
  'SMDC_1044.4 Report Store_' + moment().format('YYYYMMDD');
export default function ListReportStore(props) {
  const context: any = useReportStore();
  const { store } = context;
  const { t }: any = useDictionary({
    programId: '1044.4',
  });
  const dataGridRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  /**
   * export excel
   *
   */
  const onExportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const onCellTemplate = e => {
    return e?.document ? e?.document : ' ';
  };
  const columns: any = [
    {
      caption: t('Store Header'),
      alignment: 'center',
      columns: [
        {
          dataField: 'storeId',
          caption: t('ID'),
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,          
        },
        {
          dataField: 'storeCode',
          caption: t('Store Code'),
        },
        {
          dataField: 'storeType',
          caption: t('Type'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'storeName',
          caption: t('Store Name'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyUnitPriceGroup',
          caption: t('Supply Unit Price Group'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'representative',
          caption: t('Representative'),
          alignment: 'left',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Store Info'),
      alignment: 'center',
      items: [
        {
          dataField: 'mobile',
          caption: t('Mobil No.'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'phone',
          caption: t('Phone No.'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'email',
          caption: t('Email'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'use',
          caption: t('Use'),
          alignment: 'center',
          editorType: 'dxSwitch',
          trueText: t('Use'),
          falseText: t('Not Use'),
          showWhenGrouped: true,
        },
        {
          dataField: 'storeShortName',
          caption: t('Store Short Name'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'dealStartDate',
          caption: t('Deal StartDate'),
          alignment: 'left',
          dataType: 'date',
          format: DateFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'dealEndDate',
          caption: t('Deal EndDate'),
          alignment: 'left',
          dataType: 'date',
          format: DateFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'accountingCode',
          caption: t('Accounting Code'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'oldCode',
          caption: t('Old Code'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'countryName',
          caption: t('Country'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'fax',
          caption: t('Fax No'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'storePostCode',
          caption: t('Zip Code'),
          alignment: 'center',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'storeAddressBasic',
          caption: t('Address1'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'storeAddressDetail',
          caption: t('Address2'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'storeNote',
          caption: t('Note'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Business'),
      alignment: 'center',
      columns: [
        {
          dataField: 'businessNumber',
          caption: t('Business Number'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'storeBusiness',
          caption: t('Business'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'industry',
          caption: t('Industry'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'corporationNumber',
          caption: t('Corporation Number'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'document',
          caption: t('Documents'),
          alignment: 'right',
          calculateCellValue: onCellTemplate,
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Payment'),
      alignment: 'center',
      columns: [
        {
          dataField: 'bankName',
          caption: t('Bank'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'paymentAccountNumber',
          caption: t('Account Number'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'paymentDepositor',
          caption: t('Depositor'),
          alignment: 'left',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Manager'),
      alignment: 'center',
      columns: [
        {
          dataField: 'storeManager',
          caption: t('Store Manager'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'officeManagerName',
          caption: t('Office Manager'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'salesManagerName',
          caption: t('Sales Manager'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'managerNote',
          caption: t('Manager Note'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Delivery'),
      alignment: 'center',
      visible: false,
      columns: [
        {
          dataField: 'deliveryReceiver',
          caption: t('Receiver'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'deliveryPostCode',
          caption: t('Delivery Zip code'),
          alignment: 'center',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'deliveryAddressBasic',
          caption: t('Delivery Address1'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'deliveryAddressDetail',
          caption: t('Delivery Address2'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
  ];
  return (
    <DataGridFull
      ref={dataGridRef}
      dataSource={store}
      columns={columns}
      storageKeyInquiry
      options={{
        key: 'storeId',
        columnAutoWidth: true,
        height: '70vh',
        onToolbarPreparing: (e: any) => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'totalCount',
            },
            {
              location: 'before',
              widget: 'dxButton',
              options: {
                icon: 'xlsxfile',
                text: t('Export to Excel'),
                onClick: () => onExportExcel(),
              },
            },
          );
        },
        export: {
          fileName: exportFileName,
        },
      }}
    />
  );
}
