import useAxios from 'axios-hooks';
import { useEffect } from 'react';

function useProfileInfo() {
  const [{ data, loading }, fetchProfileInfo] = useAxios(
    {
      url: 'user/my-page',
      method: 'GET',
    },
    {
      manual: true,
      useCache: true,
    },
  );

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  return {
    fetchMyPageData: fetchProfileInfo,
    ...data,
    loading,
  };
}

export default useProfileInfo;
