/**
 * Update date: 12-12-2023
 * Screen 2013.9
 */
import { useState, useRef } from 'react';
import { cloneDeep, uniqBy } from 'lodash';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import { TRADE_TYPE } from 'constant';
import { useModuleContext } from '..';
import { notification } from 'utils/notification';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
  },
  mediumCategory: {
    codeType: 'MC',
  },
  smallCategory: {
    codeType: 'SC',
  },
  subCategory: {
    codeType: 'UC',
  },
  productType: {
    codeType: 'PT',
  },
  productMaker: {
    codeType: 'MK',
  },
};

const FormSearch = () => {
  const context: any = useModuleContext();
  const {
    t,
    refetchData,
    setStoreProductListData,
    setStoreProductListOptionData,
  } = context;
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);
  const [showPopupByType, setShowPopupByType] = useState('');
  const [formSearchStoreList, setFormSearchStoreList] = useState<any>({
    keyword: '',
    tradeTp: '',
    dataList: {
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      productGroup: [],
      productType: [],
      productMaker: [],
      vendor: [],
      store: '',
      shippingMethod: '',
    },
  });

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formSearchStoreList?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormSearchStoreList({
      ...formSearchStoreList,
      dataList: dataList,
    });
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formSearchStoreList?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formSearchStoreList,
      dataList: dataList,
      [storedType]: '',
    };
    setFormSearchStoreList(newData);
    setShowPopupByType('');
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formSearchStoreList?.dataList?.[fieldName] || [];
    const buttons = dataList?.slice(0, numberShowMore)?.map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan ?? 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly ?? false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {boolean} [readOnly=false]
   * @param {number} [colSpan=0]
   * @return {*}  {*}
   */
  const generateDxTextBoxSingle = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    readOnly = false,
    colSpan = 0,
  ): any => {
    const dataList = formSearchStoreList?.dataList?.[fieldName] || [];
    const buttons = dataList.map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    const item: any = {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        readOnly: readOnly || false,
        buttons: buttons,
      },
    };
    if (colSpan) {
      item.colSpan = colSpan;
    }
    return item;
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formSearchStoreList?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'store':
        return (
          <PopupSelectStore
            text={formSearchStoreList?.[popupType]}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('store', o)}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={showPopupByType === popupType}
            text={formSearchStoreList?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            mode="multiple"
            onSubmit={data => updateStore(popupType, data, 'productGroupId')}
          />
        );
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
      case 'productType':
      case 'productMaker':
        return (
          <PopupSelectCommonCode
            visible={showPopupByType === popupType}
            text={formSearchStoreList?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      case 'shippingMethod':
        return (
          <PopupSelectCommonCode
            text={formSearchStoreList?.[popupType]}
            mode="single"
            codeType="FT"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('shippingMethod', o)}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on sumbit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const data = {
      largeCategories: formSearchStoreList?.dataList?.largeCategory.map(
        (map: any) => map?.code,
      ),
      mediumCategories: formSearchStoreList?.dataList?.mediumCategory.map(
        (map: any) => map?.code,
      ),
      smallCategories: formSearchStoreList?.dataList?.smallCategory.map(
        (map: any) => map?.code,
      ),
      subCategories: formSearchStoreList?.dataList?.subCategory.map(
        (map: any) => map?.code,
      ),
      productGroupIds: formSearchStoreList?.dataList?.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      makerCds: formSearchStoreList?.dataList?.productMaker.map(
        (map: any) => map?.code,
      ),
      productTypes: formSearchStoreList?.dataList?.productType.map(
        (map: any) => map?.code,
      ),
      vendorIds: formSearchStoreList?.dataList?.vendor.map(
        (map: any) => map?.vendorId,
      ),
      shippingMethod: formSearchStoreList?.dataList?.shippingMethod[0]?.code,
      storeNos: formSearchStoreList?.dataList?.store[0]?.storeNo,
      keyword: formSearchStoreList?.keyword,
      tradeType: formSearchStoreList?.tradeTp,
    };
    const res = await refetchData({
      url: '/product/store-product-list',
      method: 'POST',
      data: data,
    });
    if (res?.data?.status === '200') {
      const productOption = new Set();
      const dataMapping = res?.data?.data?.productList?.map(o => {
        const type = TRADE_TYPE?.find(f => f.value === o?.trade)?.text;
        o.tradeTypeName = t(type);
        o.stockAllocationTypeName =
          o?.stock_allocation_tp === '0'
            ? t('Stock high')
            : o?.stock_allocation_tp === '1'
            ? t('Stock low')
            : '';
        o.taxTypeName =
          o?.tax_tp === '0'
            ? t('Taxation')
            : o?.tax_tp === '1'
            ? t('Tax free')
            : o?.tax_tp === '2'
            ? t('Tax zero')
            : '';
        res?.data?.data?.productOptionList?.map(option => {
          if (
            option?.product_id === o?.product_id &&
            (option?.barcode != null ||
              option?.quantity_per_pack != null ||
              option?.forward_unit_price != null)
          ) {
            o[`${option.option_nm}_barcode`] = option?.barcode;
            o[`${option.option_nm}_quantity_per_pack`] =
              option?.quantity_per_pack;
            o[`${option.option_nm}_forward_unit_price`] =
              option?.forward_unit_price;
            o[`${option.option_nm}_empty_bottle_unit_price`] =
              option?.empty_bottle_unit_price;
            o[`${option.option_nm}_container_unit_price`] =
              option?.container_unit_price;
            o[`${option.option_nm}_total_amount`] = option?.total_amount;
            productOption.add(option?.option_nm);
          }
        });
        return o;
      });
      setStoreProductListOptionData(Array.from(productOption).sort());
      setStoreProductListData(dataMapping);
    } else {
      notification({ res });
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formSearchStoreList}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          items={[
            generateDxTextBox('Product Type', 'productType', 'name'),
            generateDxTextBox('Maker', 'productMaker', 'name'),
            {
              label: { text: t('Product Keyword') },
              dataField: 'keyword',
              editorType: 'dxTextBox',
            },
            generateDxTextBoxSingle('Store', 'store', 'storeNm', {
              isRequired: true,
            }),
            generateDxTextBoxSingle(
              'Shipping Method',
              'shippingMethod',
              'name',
              {
                isRequired: true,
              },
            ),
            {
              label: { text: t('Trade Type') },
              dataField: 'tradeTp',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'name',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  {
                    name: t('All'),
                    value: '',
                  },
                  {
                    name: t('Traded'),
                    value: '0',
                  },
                  {
                    name: t('Non Traded'),
                    value: '1',
                  },
                ],
              },
            },
          ]}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formSearchStoreList}
          showColonAfterLabel={false}
          items={[
            generateDxTextBox('Large Category', 'largeCategory', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            generateDxTextBox('Small Category', 'smallCategory', 'name'),
            generateDxTextBox('Sub Category', 'subCategory', 'name'),
            generateDxTextBox('Vendor', 'vendor', 'vendorName'),
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit} showCollapse={true} ref={collapseRef}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
