/**
 * Update date: 25-05-2023
 * Screen 2041.9
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useProductForwardingStandardPrice } from '..';
import { dateFormatStr } from 'utils/format';

const exportFileName = `SMDC_2041.9_Expration_Date_Registration_${moment().format(
  'YYYYMMDD',
)}`;

function List() {
  const rootContext: any = useProductForwardingStandardPrice();
  const { t, dataStore, refetchSaving } = rootContext || {};
  const [showPopupSaveConfirm, setShowPopupSaveConfirm] =
    useState<boolean>(false);
  const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState(false);
  const dataGridRef: any = useRef(null);
  const dataGridInstance: any = dataGridRef?.current?.instance;

  const { DateFormat } = useFormatTime();
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();

  /**
   * render status
   *
   * @param {*} record
   * @return {*}
   */
  const renderStatus = (record: any) => {
    switch (record?.value) {
      case 'add':
        return '+';
      case 'edit':
        return 'V';
      case 'delete':
        return '-';
      default:
        return null;
    }
  };

  /**
   * render loading
   *
   * @param {*} record
   * @return {*}
   */
  const renderLoading = (record: any) => {
    return <span>{t(record?.value ? 'Loaded' : 'Waiting')}</span>;
  };

  /**
   * render validate date today
   *
   * @param {*} e
   * @return {*}
   */
  const validateDateToday = e => {
    if (e?.value) {
      const { old_expiration_end_dt } = e?.data || {};
      if (
        old_expiration_end_dt &&
        old_expiration_end_dt === new Date(e?.value).getTime()
      )
        return true;
      if (
        moment(e?.value).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
      ) {
        return false;
      }
    }
    return true;
  };
  /**
   * render validate date three year
   *
   * @param {*} e
   * @return {*}
   */
  const validateDateThreeYear = e => {
    if (e?.value) {
      const { old_expiration_end_dt } = e?.data || {};
      if (
        old_expiration_end_dt &&
        old_expiration_end_dt === new Date(e?.value).getTime()
      )
        return true;
      if (
        moment(e?.value).format('YYYY-MM-DD') >
        moment().add(3, 'years').format('YYYY-MM-DD')
      ) {
        return false;
      }
    }
    return true;
  };

  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowUpdating = e => {
    if (e?.newData) {
      dataStore.update(
        {
          receive_bill: e?.oldData?.receive_bill,
          line_id: e?.oldData?.line_id,
        },
        {
          status: 'edit',
        },
      );
    }
  };

  /**
   * on confirm
   *
   * @return {*}
   */
  const onConfirm = () => {
    const data = dataStore?._array?.filter(
      o => o?.status === 'edit' || o?.status === 'delete',
    );
    if (data?.length === 0) {
      return;
    }

    const dataGridInstance = dataGridRef?.current?.instance;
    if (dataGridInstance?.hasEditData?.()) {
      dataGridInstance?.saveEditData?.().then(() => {
        setShowPopupSaveConfirm(!showPopupSaveConfirm);
      });
    }
    setShowPopupSaveConfirm(!showPopupSaveConfirm);
  };

  /**
   * on remove
   *
   */
  const onRemove = () => {
    const selectedData = dataGridRef?.current?.instance?.getSelectedRowsData();
    selectedData.forEach(e => {
      const newData = {
        ...e,
        status: 'delete',
        expiration_end_dt: null,
      };
      dataStore
        .update(
          {
            receive_bill: e?.receive_bill,
            line_id: e?.line_id,
          },
          newData,
        )
        .done(() => {})
        .fail(() => {});
    });
    dataGridRef?.current?.instance?.deselectAll();
    dataGridRef?.current?.instance?.refresh();
    setShowPopupConfirmDelete(false);
  };

  /**
   * on save
   *
   */
  const onSaving = async () => {
    const data = dataGridRef?.current?.instance
      ?.getDataSource()
      ?._items?.filter(o => o?.status === 'edit' || o?.status === 'delete');
    const res = await refetchSaving({
      data: data?.map(o => ({
        receive_bill: o?.receive_bill,
        receive_id: o?.receive_id,
        line_id: o?.line_id,
        zone_id: o?.zone_id,
        location_id: o?.location_id,
        product_id: o?.product_id,
        option_id: o?.option_id,
        expiration_end_dt: dateFormatStr(o?.expiration_end_dt),
        use_end_dt: dateFormatStr(o?.use_end_dt),
        manufacturing_dt: dateFormatStr(o?.manufacturing_dt),
      })),
    });

    notification({
      res,
    });
    if (res?.data?.status === '201') {
      data.forEach(e => {
        const newData = {
          ...e,
          status: 'normal',
          expiration_end_dt:
            e?.status === 'delete' ? null : e?.expiration_end_dt,
        };
        dataStore
          .update(
            {
              receive_bill: e?.receive_bill,
              line_id: e?.line_id,
            },
            newData,
          )
          .done(() => {})
          .fail(() => {});
      });
      dataGridRef?.current?.instance?.deselectAll();
      dataGridRef?.current?.instance?.refresh();
    }
    setShowPopupSaveConfirm(false);
  };

  const columns: any = [
    {
      dataField: 'status',
      caption: t('Status'),
      alignment: 'center',
      cellRender: renderStatus,
      width: 70,
      fixedPosition: 'left',
      fixed: true,
      allowHeaderFiltering: false,
      allowFiltering: false,
      allowEditing: false,
    },
    {
      caption: t('Receiving'),
      alignment: 'center',
      items: [
        {
          dataField: 'receive_bill',
          caption: t('Bill ID'),
          allowEditing: false,
          fixedPosition: 'left',
          fixed: true,
        },
        {
          dataField: 'receive_dt',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          allowEditing: false,
        },
        {
          dataField: 'vendor_nm',
          caption: t('Vendor'),
          allowEditing: false,
        },
        {
          dataField: 'zone_nm',
          caption: t('Zone'),
          allowEditing: false,
        },
        {
          dataField: 'isloading',
          caption: t('Loading'),
          alignment: 'left',
          allowEditing: false,
          cellRender: renderLoading,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      items: [
        {
          dataField: 'product_cd',
          caption: t('Product Code'),
          allowEditing: false,
        },
        {
          dataField: 'product_nm',
          caption: t('Product Name'),
          allowEditing: false,
        },
        {
          dataField: 'marketable_size',
          caption: t('Marketable Size'),
          allowEditing: false,
        },
        {
          dataField: 'option_cd',
          caption: t('Option Code'),
          allowEditing: false,
        },
        {
          dataField: 'option_nm',
          caption: t('Option'),
          allowEditing: false,
        },
        {
          dataField: 'quantity_per_pack',
          caption: t('Quantity Per Pack'),
          format: QtyFormat,
          dataType: 'number',
          allowEditing: false,
        },
        {
          dataField: 'receive_qt',
          caption: t('Qty'),
          format: QtyFormat,
          dataType: 'number',
          allowEditing: false,
        },
        {
          dataField: 'expiration_end_dt',
          caption: t('Expiration Date'),
          dataType: 'date',
          format: DateFormat,
          editorOptions: { useMaskBehavior: true },
          allowEditing: true,
          width: 125,
          cssClass: 'gridcell-editing',
          validationRules: [
            {
              type: 'custom',
              validationCallback: validateDateToday,
              message: t('Expiration date can only be selected from today'),
            },
            {
              type: 'custom',
              validationCallback: validateDateThreeYear,
              message: t(
                'The expiration date can only be registered up to 36 months from today',
              ),
            },
          ],
        },
        {
          dataField: 'use_end_dt',
          caption: t('Use End Date'),
          dataType: 'date',
          format: DateFormat,
          editorOptions: { useMaskBehavior: true },
          allowEditing: true,
          cssClass: 'gridcell-editing',
          width: 120,
        },
        {
          dataField: 'manufacturing_dt',
          caption: t('Manufacturing Date'),
          dataType: 'date',
          format: DateFormat,
          editorOptions: { useMaskBehavior: true },
          allowEditing: true,
          cssClass: 'gridcell-editing',
          width: 125,
        },
        {
          dataField: 'bill_note',
          caption: t('Bill Note'),
          visible: false,
          allowEditing: false,
        },
        {
          dataField: 'line_note',
          caption: t('Line Note'),
          visible: false,
          allowEditing: false,
        },
        {
          dataField: 'bottle_id',
          caption: t('Bottle ID'),
          visible: false,
          allowEditing: false,
        },
        {
          dataField: 'bottle_unit_price',
          caption: t('Bottle Unit Price'),
          format: AmountFormat,
          visible: false,
          allowEditing: false,
        },
        {
          dataField: 'container_id',
          caption: t('Container ID'),
          visible: false,
          allowEditing: false,
        },
        {
          dataField: 'container_unit_price',
          caption: t('Container Unit Price'),
          format: AmountFormat,
          visible: false,
          allowEditing: false,
        },
        {
          dataField: 'maker',
          caption: t('Maker'),
          visible: false,
        },
        {
          dataField: 'origin',
          caption: t('Origin'),
          visible: false,
        },
      ],
    },
  ];

  /**
   * on export excel
   *
   */
  const onExportExcel = () => {
    dataGridInstance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={dataStore}
        options={{
          keyExpr: ['line_id', 'receive_bill'],
          onRowUpdating: onRowUpdating,
          editing: {
            mode: 'cell',
            allowUpdating: true,
            selectTextOnEditStart: true,
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                locateInMenu: 'auto',
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => onExportExcel(),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'save',
                  text: t('Save'),
                  onClick: () => onConfirm(),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  onClick: () => setShowPopupConfirmDelete(true),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'receiveQt',
                column: 'receiveQt',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
          },
        }}
      />
      {showPopupSaveConfirm && (
        <PopupConfirm
          title={t('Confirm Save')}
          visible={showPopupSaveConfirm}
          content={t('Are you sure to save?')}
          onOk={onSaving}
          onHiding={() => setShowPopupSaveConfirm(false)}
        />
      )}
      {showPopupConfirmDelete && (
        <PopupConfirmDelete
          visible={showPopupConfirmDelete}
          content={t(
            `Do you want to delete it ? If you click the save button after deleting it, it will be deleted finally.`,
          )}
          onOk={onRemove}
          onHiding={() => setShowPopupConfirmDelete(false)}
        />
      )}
    </>
  );
}

export default List;
