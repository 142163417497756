/**
 * Update date: 22-05-2023
 * Screen 2017.2
 */
import React, { useState, useRef } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import useFormatTime from 'hooks/useFormatTime';
import { isEqual } from 'lodash';
import moment from 'moment';
import PopupSelectMall from 'app/components/PopupCommon/PopupSelectMall';
import useDictionary from 'hooks/useDictionary';
interface IFormSearch {
  param?: any;
  setParam?: any;
  refetchData?: any;
}

const FormSearch = ({ param, setParam, refetchData }: IFormSearch) => {
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);

  const [formData] = useState({
    channelSec: param?.channelSec,
    mallCode: param?.mallCode,
    date: param?.date,
    target: param?.target,
    keyword: param?.keyword,
  });

  const [isShowOptionModal, setIsShowOptionModal] = useState<boolean>(false);
  const [valueOptionSelectedDefault, setValueOptionSelectedDefault] =
    useState<string>('');
  const { DateFormat } = useFormatTime();
  const { t }: any = useDictionary({
    programId: '2017.2',
  });
  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    collapseRef?.current?.onCollappse();
    if (
      (formRef?.current && !formRef?.current?.instance?.validate()?.isValid) ||
      !formRef?.current
    ) {
      return;
    }
    await refetchData();
  };

  /**
   * on field data change
   *
   */
  const onFieldDataChanged = () => {
    const d = new Date(formData.date);
    d.setDate(d.getDate());

    const data = {
      ...formData,
      channelSec: 'B2B',
      mallCode: valueOptionSelectedDefault,
      date: d ? moment(d).format('YYYY-MM-DD') : null, //set local time
      target: formData.target,
      keyword: formData.keyword,
    };

    const searchParams = {
      ...param,
      ...data,
    };

    if (!isEqual(param, searchParams)) {
      setParam && setParam(searchParams);
    }
  };

  /**
   * on change mall code
   *
   * @param {*} data
   */
  const onChangeMallCode = data => {
    const selectionCode = data;
    setParam({
      ...param,
      mallCode: selectionCode,
    });
    setValueOptionSelectedDefault(selectionCode);
    onCloseOptionModal();
  };

  const onCloseOptionModal = () => {
    setIsShowOptionModal(false);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <Form
        ref={formRef}
        formData={formData}
        showColonAfterLabel={false}
        labelLocation="top"
        colCount={3}
        onFieldDataChanged={onFieldDataChanged}
        items={[
          // {
          //   label: { text: t('Mall ID') },
          //   dataField: 'mallCode',
          //   isRequired: !valueOptionSelectedDefault,
          //   editorType: 'dxTextBox',
          //   editorOptions: {
          //     showClearButton: true,
          //     buttons: [
          //       {
          //         name: 'selectedMall',
          //         location: 'before',
          //         options: {
          //           icon: 'close',
          //           text: valueOptionSelectedDefault,
          //           onClick: () => {
          //             setValueOptionSelectedDefault('');
          //           },
          //           visible: !!valueOptionSelectedDefault,
          //           rtlEnabled: true,
          //         },
          //       },
          //       {
          //         name: 'search',
          //         location: 'after',
          //         options: {
          //           stylingMode: 'text',
          //           icon: 'search',
          //           onClick: () => setIsShowOptionModal(true),
          //         },
          //       },
          //     ],
          //   },
          // },
          {
            label: {
              text: t('Date'),
            },
            dataField: 'date',
            isRequired: !param?.date,
            editorType: 'dxDateBox',
            editorOptions: {
              max: Date(),
              displayFormat: DateFormat,
            },
          },
          {
            label: { text: t('Target') },
            dataField: 'target',
            editorType: 'dxSelectBox',
            editorOptions: {
              displayExpr: 'label',
              valueExpr: 'value',
              items: [
                {
                  label: t('All'),
                  value:
                    'stock,order,goods,ctorder,receive,takeout,forward,return,center',
                },
                // { label: t('Product'), value: 'product' },
                { label: t('Stock'), value: 'stock' },
                { label: t('Order'), value: 'order' },
                { label: t('Goods'), value: 'goods' },
                { label: t('Ctorder'), value: 'ctorder' },
                { label: t('Receive'), value: 'receive' },
                { label: t('Takeout'), value: 'takeout' },
                { label: t('Forward'), value: 'forward' },
                { label: t('Return'), value: 'return' },
                { label: t('Center'), value: 'center' },
              ],
              value: formData.target
                ? formData.target
                : 'stock,order,goods,ctorder,receive,takeout,forward,return,center',
            },
          },
          {
            label: { text: t('Keyword') },
            dataField: 'keyword',
            editorType: 'dxTextBox',
          },
        ]}
      />
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit} ref={collapseRef}>
        <Search name={t('Search')} />
      </Collapse>
      {/* updated version */}
      {isShowOptionModal && (
        <PopupSelectMall
          visible={isShowOptionModal}
          mode="single"
          onHiding={() => setIsShowOptionModal(!isShowOptionModal)}
          onSubmit={data => {
            onChangeMallCode(data?.mallCode);
            setIsShowOptionModal(!isShowOptionModal);
          }}
          text={formData?.mallCode}
        />
      )}
    </div>
  );
};

export default FormSearch;
