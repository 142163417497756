/**
 * Update date: 26-05-2023
 * Screen 2042.5
 */
import useAxios from 'axios-hooks';
import { CARRYOUT_INQUIRY_API_URL } from 'constant/apiUrl';
import { useEffect, useState } from 'react';
import Context from './Context';

const Provider = ({ children }) => {
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: CARRYOUT_INQUIRY_API_URL,
      method: 'POST',
    },
    { manual: true, useCache: false },
  );

  const [gridData, setGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const contextData: any = {
    refetchData,
    gridData,
    isLoading,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setGridData(data?.data);
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export default Provider;
