/**
 * Update date: 17-05-2023
 * Screen 2011.4
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useAxios from 'axios-hooks';
import useCenterInfo from 'hooks/useCenterInfo';
import React, { forwardRef, useState } from 'react';
import { getSumFormat } from 'utils/format';
import { notification } from 'utils/notification';
import { useContainerRegistration } from '..';
import FormSearch from '../FormSearch';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

function ListCenter(props, ref) {
  const { themePro }: any = useApp();
  const context: any = useContainerRegistration();
  const { setModeView, store, deselectAll } = context || {};
  const [showModalDelete, setShowModalDelete] = useState(false);
  const selectedKeys = ref?.current?.instance?.option().selectedRowKeys;
  const { amountPoint } = useCenterInfo();

  const { t }: any = useDictionary({
    programId: '2011.4',
  });

  const [{}, refetchDelete] = useAxios(
    {
      url: '/product/container',
      method: 'DELETE',
    },
    { manual: true },
  );
  /**
   * render conainer name
   *
   * @param {*} record
   * @return {*}
   */
  const cellContainerName = (record: any) => {
    const { containerId } = record?.data || {};
    return (
      <div
        className="cell-link"
        onClick={() => setModeView({ type: 'edit', id: containerId })}
      >
        {record.text || ''}
      </div>
    );
  };

  const sumFormat = getSumFormat(amountPoint);
  const columns: any = [
    {
      dataField: 'containerId',
      caption: t('ID'),
      alignment: 'left',
      fixedPosition: 'left',
      fixed: true,
      width: 100,
    },
    {
      dataField: 'productTypeName',
      caption: t('Product Type'),
    },
    {
      dataField: 'containerCode',
      caption: t('Container Code'),
    },
    {
      dataField: 'commonContainerCode',
      caption: t('Common Container Code (Take Back)'),
    },
    {
      dataField: 'commonReturnBackCode',
      caption: t('Common Container Code (Return Back)'),
    },
    {
      dataField: 'containerName',
      caption: t('Container'),
      cellRender: cellContainerName,
    },
    {
      dataField: 'containerTypeName',
      caption: t('Container Type'),
    },
    {
      dataField: 'containerKindName',
      caption: t('Container Kind'),
    },
    {
      dataField: 'unitPrice',
      caption: t('Unit Price'),
      format: sumFormat,
      dataType: 'number',
    },
    {
      dataField: 'importRate',
      caption: t('Import Rate'),
      format: sumFormat,
      dataType: 'number',
    },
    {
      dataField: 'exportRate',
      caption: t('Export Rate'),
      format: sumFormat,
      dataType: 'number',
    },
    {
      dataField: 'commTaxRate',
      caption: t('Commission Tax Rate'),
      format: sumFormat,
      dataType: 'number',
    },
    {
      dataField: 'cosmoInterlockTf',
      caption: t('Cosmo Interlock'),
      alignment: 'center',
      dataType: 'boolean',
      editorType: 'dxSwitch',
      trueText: t('Use'),
      falseText: t('Not Use'),
      width: 110,
    },
    {
      dataField: 'use',
      caption: t('Use'),
      alignment: 'center',
      dataType: 'boolean',
      editorType: 'dxSwitch',
      trueText: t('Use'),
      falseText: t('Not Use'),
      width: 100,
    },
    {
      dataField: 'takereturnbackTf',
      caption: t('Take Return Back'),
      alignment: 'center',
      dataType: 'boolean',
      editorType: 'dxSwitch',
      trueText: t('Use'),
      falseText: t('Not Use'),
    },
  ];

  /**
   * on delete
   *
   */
  const onDelete = async () => {
    const res = await refetchDelete({ data: selectedKeys });
    notification({
      res,
    });
    if (res?.data?.status === '200') {
      selectedKeys?.forEach(containerId => {
        store
          .remove(containerId)
          .done(() => {})
          .fail(() => {});
      });
      deselectAll();
      setShowModalDelete(false);
    }
  };

  return (
    <div>
      <BreadCrumbPremium />
      <FormSearch />
      <DataGridFull
        ref={ref}
        dataSource={store}
        columns={columns}
        options={{
          key: 'containerId',
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: themePro ? 'after' : 'before',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Create New'),
                  onClick: () => setModeView({ type: 'create' }),
                },
              },
              {
                location: themePro ? 'after' : 'before',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  // disabled: !selectedKeys?.length,
                  onClick: () => setShowModalDelete(!showModalDelete),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
          paging: {
            pageSize: 20,
          },
        }}
      />
      <PopupConfirmDelete
        visible={showModalDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onDelete}
        onHiding={() => setShowModalDelete(!showModalDelete)}
      />
    </div>
  );
}

export default forwardRef(ListCenter);
