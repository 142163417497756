import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CenterOperatorState {
  arrayId: number[];
}

const initialState: CenterOperatorState = {
  arrayId: [],
};

const centerOperatorSlice = createSlice({
  name: 'centerOperator',
  initialState,
  reducers: {
    updateArrayId(state, action: PayloadAction<number[]>) {
      state.arrayId = Array.from(new Set(action.payload));
    },
    refreshArrayId(state) {
      state.arrayId = [];
    },
  },
});
//Action
export const centerOperatorActions = centerOperatorSlice.actions;

//Selectors
const selectArrayId = (state: any) => state.centerOperator.arrayId;

export const centerOperatorSelect = {
  selectArrayId,
};

const centerOperatorReducer = centerOperatorSlice.reducer;

export default centerOperatorReducer;
