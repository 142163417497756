/**
 * Update date: 25-01-2024
 * Screen 1042.7
 */
import { useState, useRef, useEffect } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import { useModuleContext } from '..';
import { notification } from 'utils/notification';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

export const onSubmit = async (refetchData, setESLDesignList, formSearch) => {
  let designName =
    formSearch?.designName !== ''
      ? `?designName=${formSearch?.designName}`
      : '';
  const res = await refetchData({
    url: '/core/esl-design-setting' + designName,
    method: 'GET',
  });
  if (res?.data?.status === '200') {
    setESLDesignList(res?.data?.data);
  } else {
    notification({ res });
  }
};

const FormSearch = () => {
  const context: any = useModuleContext();
  const { t, refetchData, setESLDesignList, setModeView, modeView } = context;
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);
  const [formSearch, setFormSearch] = useState<any>({
    designName: '',
  });

  useEffect(() => {
    if (modeView?.autoLoad) {
      setModeView({ type: 'list', autoLoad: false });
      onSubmit(refetchData, setESLDesignList, formSearch);
    }
  }, []);

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formSearch}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={2}
          items={[
            {
              label: { text: t('Design name') },
              dataField: 'designName',
              editorType: 'dxTextBox',
            },
          ]}
        ></Form>
      </>
    );
  };

  return (
    <div>
      <BreadCrumbPremium />
      <Collapse
        onSubmit={() => onSubmit(refetchData, setESLDesignList, formSearch)}
        ref={collapseRef}
      >
        <Search name={t('Search')} />
      </Collapse>
    </div>
  );
};

export default FormSearch;
