import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import { notification } from 'utils/notification';

const View = React.lazy(() => import('./View'));
const List = React.lazy(() => import('./List'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2082_9_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Consignment Vendor Sales Settlement Inquiry
 *
 * @export
 * @return {*}
 */
export default function M2082_9() {
  // get multi language
  const { t }: any = useDictionary({ programId: '2082.9' });
  // Initialize state
  const [listData, setListData] = useState([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    operationType: 2,
    dataList: {
      vendor: [],
    },
    vendor: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: '',
  });

  // Get data
  const [{ loading }, refetchData] = useAxios(
    {
      url: 'user/m2082_9',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * On search data
   *
   */
  const onSearch = async () => {
    const searchParams = {
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      vendorIds: formData?.dataList.vendor.map((o: any) => o?.vendorId),
      operationType: formData?.operationType,
    };
    const res = await refetchData({
      data: { ...searchParams },
    });
    if (res?.data?.status === '200') {
      const dataMaping = (res?.data?.data || []).map(o => {
        const {
          payment_amount = 0,
          consignvendor_comm_amount = 0,
          total_amount = 0,
        } = o;
        const totalMinus = total_amount - payment_amount;
        return {
          ...o,
          payment_rt: total_amount
            ? ((payment_amount || 0) / total_amount) * 100
            : 0,
          consignvendor_comm_rt: totalMinus
            ? (consignvendor_comm_amount / totalMinus) * 100
            : 0,
        };
      });
      setListData(dataMaping);
    } else {
      notification({ res });
    }
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <View />;
      default:
        return <NotFoundPage />;
    }
  };

  // Values share with child component
  const value: any = {
    t,
    modeView,
    setModeView,
    formData,
    setFormData,
    listData,
    onSearch,
    refetchData,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
}
