/**
 * Update date: 14-06-2023
 * Pure collapse component
 */
import React, { useState } from 'react';
import { Button } from 'devextreme-react';
import './style.scss';

interface CollapseType {
  children: any;
}

function PureCollapse({ children }: CollapseType) {
  const [collapse, setCollapse] = useState<boolean>(true);

  return (
    <>
      <div className="pure-collapse-form-search">
        {Array.isArray(children) ? (
          children.map((map, i) => (
            <>
              <div className="pure-collapse-form-collapse-btn">
                <Button
                  type="default"
                  stylingMode="text"
                  icon={!collapse ? 'chevrondown' : 'chevronup'}
                  onClick={() => setCollapse(!collapse)}
                />
              </div>
              <div
                className={`pure-collapse-form ${
                  !collapse && 'pure-collapse-form-d-none'
                }`}
              >
                {map}
              </div>
            </>
          ))
        ) : children ? (
          <>
            <div className="pure-collapse-form-collapse-btn">
              <Button
                type="default"
                stylingMode="text"
                icon={!collapse ? 'chevrondown' : 'chevronup'}
                onClick={() => setCollapse(!collapse)}
              />
            </div>
            <div
              className={`pure-collapse-form ${
                !collapse && 'pure-collapse-form-d-none'
              }`}
            >
              {children}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default PureCollapse;
