/*
 * 2082.5 Trade Payable Balance Inquiry
 * @since 2022/11/24
 */
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { DataGridFull } from 'app/components/DataGrid';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useTradePayableBalanceInquiryContext } from '..';
import FormSearch from './FormSearch';

/**
 * View Component
 *
 * @return {*}
 */
function View() {
  // Get data from context
  const {
    storeView,
    AmountFormat,
    setModeView,
    t,
    formSearchView,
    // dataColumnPallet,
    setFormSearchView,
    setListDataDetail,
  }: any = useTradePayableBalanceInquiryContext();

  useState<boolean>(false);
  const dataGridRef: any = useRef(null);

  // Render column grid
  const columns = [
    {
      alignment: 'left',
      dataField: 'day_dt',
      caption: t('Date'),
      calculateCellValue: record => {
        const { day_dt } = record || {};
        if (typeof day_dt === 'string') {
          return day_dt;
        } else {
          return moment(day_dt).format('YYYY-MM-DD');
        }
      },
    },
    {
      dataField: 'btype_nm',
      caption: t('Type'),
      alignment: 'left',
    },
    {
      dataField: 'receive_product',
      caption: t('Receive Product'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'receive_container',
      caption: t('Receive Container'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'receive_amount',
      caption: t('Receive Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'takeout_product',
      caption: t('Takeout Product'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'takeout_container',
      caption: t('Takeout Container'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'takeout_amount',
      caption: t('Takeout Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'container_returnback',
      caption: t('Container Return Back'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'payment_amount',
      caption: t('Payment'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'deposit_amount',
      caption: t('Deposit'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'deduction_amount',
      caption: t('Deduction'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'debit_amount',
      caption: t('Debit'),
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  /**
   *  Export excel gridview
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * Go to back master component
   *
   */
  const onClose = () => {
    setModeView({
      type: 'list',
    });
    setFormSearchView({
      fromDate: new Date(),
      endDate: new Date(),
      vendorName: '',
      debitAmount: 0,
    });
    setListDataDetail([]);
  };

  return (
    <>
      <BreadCrumbPremium
        title={formSearchView?.vendorName || ''}
        buttons={[
          {
            title: 'Close',
            icon: 'close',
            type: 'normal',
            action: () => onClose(),
          },
        ]}
      />
      <FormSearch />
      <DataGridFull
        storageKeyInquiry="view"
        columns={columns}
        ref={dataGridRef}
        dataSource={storeView}
        fixedLeft={2}
        fixedRight={3}
        options={{
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName:
              'SMDC_2082.5 ' +
              (formSearchView?.vendorName || 'Vendor Name') +
              '_' +
              moment().format('YYYYMMDD'),
          },
          summary: {
            totalItems: [
              {
                column: 'receive_product',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receive_container',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receive_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'takeout_product',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'takeout_container',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'takeout_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'container_returnback',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'payment_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'deposit_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'deduction_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default View;
