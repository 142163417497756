/* eslint-disable import/prefer-default-export */
export const DBConfig = {
  name: 'SMDC',
  version: 1,
  objectStoresMeta: [
    {
      store: 'barcodes',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'barcode', keypath: 'barcode', options: { unique: false } },
      ],
    },
  ],
};
