/**
 * Update date: 29-05-2023
 * Screen 2044.7
 */
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import moment from 'moment';
import { useStoreContext } from '..';
import ArrayStore from 'devextreme/data/array_store';
import { notification } from 'utils/notification';
import useFormatTime from 'hooks/useFormatTime';
import printJS from 'print-js';
import PopupSelectUser from 'app/components/PopupCommon/PopupSelectUser';
import useProfileInfo from 'hooks/useProfileInfo';
import useFormat from 'hooks/useFormat';

function PicklistTable() {
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const context: any = useStoreContext();
  const {
    t,
    orderData,
    fetchDetail,
    fetchReport,
    setViewDetail,
    subFormData,
    setSubFormData,
    updatePrintInfo,
  } = context;
  const dataGridRef: any = useRef(null);
  const optionQtyRef: any = useRef(null);
  const [showDelivery, setShowDelivery] = useState<boolean>(false);
  const { name }: any = useProfileInfo();
  const { QtyFormat, AmountFormat } = useFormat();
  const store: any = new ArrayStore({
    data: orderData || [],
    key: 'wayBill',
  });

  const columns: any = useMemo(
    () => [
      {
        dataField: 'deliverDt',
        caption: t('Expecting Delivery Date'),
        dataType: 'date',
        format: DateFormat,
      },
      {
        dataField: 'deliverTm',
        caption: t('Expecting Delivery Time'),
      },
      {
        dataField: 'driverId',
        caption: t('Delivery Driver ID'),
        visible: false,
      },
      {
        dataField: 'driverNm',
        caption: t('Deliver Driver'),
      },
      {
        dataField: 'wayBill',
        caption: t('Way Bill'),
      },
      {
        dataField: 'receivableCd',
        caption: t('Receivable Code'),
        visible: false,
      },
      {
        dataField: 'receivableNm',
        caption: t('Receivable Name'),
      },
      {
        dataField: 'allocateSeq',
        caption: t('Allocate Seq'),
      },
      {
        dataField: 'allocateDt',
        caption: t('Allocate Date'),
        dataType: 'date',
        format: DateFormat,
      },
      {
        dataField: 'storeNm',
        caption: t('Store'),
      },
      {
        dataField: 'shipmentType',
        caption: t('Shipping Method'),
      },
      {
        dataField: 'sendDueDate',
        caption: t('Delivery Date'),
        dataType: 'date',
        format: DateFormat,
      },
      {
        dataField: 'deliveryStatus',
        caption: t('Delivery Status'),
        cellRender: e => {
          return (
            <div>
              {e?.value?.split(', ')?.map(o => (
                <span>
                  {`${t(o?.slice(0, o?.indexOf('(')))}${o?.slice(
                    o?.indexOf('('),
                  )}`}
                  <br />
                </span>
              ))}
            </div>
          );
        },
      },
      {
        dataField: 'pickingPrintedDm',
        caption: t('Print DateTime'),
        dataType: 'date',
        format: DateTimeFormat,
      },
      {
        dataField: 'pickingPrintedUser',
        caption: t('Print User'),
      },
      {
        dataField: 'pickingQuantity',
        caption: t('Order Qty'),
        format: QtyFormat,
        dataType: 'number',
      },
      {
        dataField: 'orderAmount',
        caption: t('Order Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
    ],
    [t],
  );

  /**
   * on detail
   *
   * @return {*}
   */
  const handleDetail = () => {
    const selectedKeys =
      dataGridRef?.current?.instance?.option?.()?.selectedRowKeys;

    if (selectedKeys?.length === 0 || selectedKeys?.length === undefined) {
      notification({
        message: t('No rows selected.'),
        type: 'error',
      });
      return;
    }
    fetchDetail({
      url: `/ecommerce/print-pick-list/detail?wayBillList=${[
        ...selectedKeys,
        ...[],
      ]}`,
      method: 'POST',
    });
    setViewDetail(true);
  };

  /**
   * on report
   *
   * @param {String} reportTyp
   * @return {*}
   */
  const handleReport = async (reportTyp: String) => {
    const wayBill = dataGridRef?.current?.instance?.option?.()?.selectedRowKeys;
    const selectedRows = dataGridRef?.current?.instance?.getSelectedRowsData();

    const emptyDriver = selectedRows?.filter((o: any) => {
      if (
        o?.driverId === null ||
        o?.driverId === undefined ||
        o?.driverId === ''
      ) {
        return o;
      }
    });

    if (emptyDriver?.length > 0) {
      notification({
        message: t('Please check missing deivery driver record(s).'),
        type: 'error',
      });
      // return;
    }

    if (wayBill?.length === 0 || wayBill?.length === undefined) {
      notification({
        message: t('Select at least one row to print report.'),
        type: 'error',
      });
      return;
    }

    try {
      const res = await fetchReport({
        url: '/ecommerce/print-pick-list/report',
        method: 'POST',
        responseType: 'arraybuffer',
        data: {
          wayBill: wayBill,
          reportTyp: reportTyp,
        },
      });
      const arrayData: any = new Uint8Array(res.data);
      const blob = new Blob([arrayData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      printJS({
        printable: url,
        showModal: true,
      });
      handleUpdatePrintInfo(wayBill);
    } catch (e) {
      notification({ type: 'error', message: t('Get Report Failed.') });
    }
  };

  /**
   * on update print info
   *
   */
  const handleUpdatePrintInfo = selectedKeys => {
    selectedKeys?.forEach(o => {
      const count = store?._array?.filter((oo: any) => {
        if (oo?.wayBill === o) {
          return oo;
        }
      });
      store
        .update(o, {
          pickingPrintedUser: name,
          pickingPrintedDm: Date.parse(moment().format('YYYY-MM-DD HH:mm:ss')),
          pickingPrintedCount: count?.[0]?.pickingPrintedCount + 1,
        })
        .done(() => {})
        .fail(() => {});
    });
    dataGridRef?.current?.instance?.refresh();

    const rawData = store?._array?.filter((o: any) =>
      selectedKeys.includes(o?.wayBill),
    );

    const data = rawData?.map((o: any) => {
      return {
        wayBill: o?.wayBill,
        driverId: o?.driverId,
        driverNm: o?.driverNm,
        pickingPrintedUser: o?.pickingPrintedUser,
        pickingPrintedDm: o?.pickingPrintedDm,
        pickingPrintedCount: o?.pickingPrintedCount,
      };
    });

    updatePrintInfo({
      data,
    });
  };

  /**
   * on delivery
   *
   */
  const handleDelivery = () => {
    setShowDelivery(true);
  };

  /**
   * on submit
   *
   * @param {*} data
   */
  const onSubmit = data => {
    setSubFormData({
      ...subFormData,
      userId: data?.userId,
      name: data?.name,
    });
    setShowDelivery(!showDelivery);
  };

  useEffect(() => {}, [subFormData, setSubFormData]);

  /**
   * on remove data
   *
   */
  const removeData = () => {
    setSubFormData({
      ...subFormData,
      userId: '',
      name: '',
    });
  };

  /**
   * on assign driver
   *
   */
  const assignDriver = () => {
    const selectedKeys =
      dataGridRef?.current?.instance?.option?.()?.selectedRowKeys;
    selectedKeys?.forEach(o => {
      store
        .update(o, {
          driverId: subFormData?.userId,
          driverNm: subFormData?.name,
        })
        .done(() => {})
        .fail(() => {});
    });
    dataGridRef?.current?.instance?.refresh();
  };

  /**
   * on remove driver
   *
   */
  const removeDriver = () => {
    const selectedKeys =
      dataGridRef?.current?.instance?.option?.()?.selectedRowKeys;
    selectedKeys?.forEach(o => {
      store
        .update(o, {
          driverId: null,
          driverNm: null,
        })
        .done(() => {})
        .fail(() => {});
    });
    dataGridRef?.current?.instance?.refresh();
  };

  /**
   * on exoprt excel
   *
   */
  const onExportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const exportFileName = 'SMDC_2044.7 ' + moment().format('YYYYMMDD');

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={optionQtyRef}
        style={{
          position: 'absolute',
          top: '-60px',
          right: '80px',
          zIndex: 1,
          left: '140px',
          textAlign: 'center',
          color: '#0000ffd4',
        }}
      ></div>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={store}
        options={{
          key: 'storeId',
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            isAllPages: true,
          },
          columnAutoWidth: true,
          onSelectionChanged: (e: any) => {
            const seletedData = e?.selectedRowsData || [];
            const dataOptions: any = [];
            seletedData.forEach(o => {
              dataOptions.push(...(o?.listOptionQuantity || []));
            });
            const result = dataOptions.reduce((obj, el) => {
              obj[el.optionNm] = (obj[el.optionNm] || 0) + el.quantity;
              return obj;
            }, {});
            const optionQtyStr = Object.keys(result)
              .map(key => `${key}[${result[key]}]`)
              .join(', ');
            if (optionQtyRef?.current) {
              optionQtyRef.current.innerHTML = `${optionQtyStr || ''}`;
            }
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                  icon: '',
                  text: t('Details'),
                  onClick: () => handleDetail(),
                },
              },
              {
                location: 'before',
                widget: 'dxTextBox',
                options: {
                  isRequired: true,
                  placeholder: t('Delivery Driver...'),
                  text: t(subFormData?.name),
                  buttons: [
                    {
                      name: 'data',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: t(subFormData?.name),
                        visible: !!subFormData?.userId,
                        onClick: () => removeData(),
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => handleDelivery(),
                      },
                    },
                  ],
                },
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'arrowright',
                  text: t('Assign Driver'),
                  onClick: () => assignDriver(),
                },
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'remove',
                  text: t('Remove Driver'),
                  onClick: () => removeDriver(),
                },
              },
              {
                location: 'before',
                locateInMenu: 'auto',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  onClick: () => onExportExcel(),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                  icon: '',
                  text: t('Picking List (Store)'),
                  onClick: () => handleReport('store'),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                  icon: '',
                  text: t('Picking List (Product)'),
                  onClick: () => handleReport('product'),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
        }}
      />
      {showDelivery && (
        <PopupSelectUser
          title={t('Select Delivery Driver')}
          text={t(subFormData?.driver?.name)}
          visible={showDelivery}
          onHiding={() => setShowDelivery(!showDelivery)}
          onSubmit={data => onSubmit(data)}
          dataBody={{ roleType: 'Delivery' }}
        />
      )}
    </div>
  );
}

export default PicklistTable;
