import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';

const defaultSearchParams = {
  searchBy: 1, // 1: by date, 2: by vendor
  fromDate: new Date(),
  endDate: new Date(),
  dataList: {
    vendor: [],
    receivableCd: [],
  },
  vendor: '',
  receivableCd: '',
};

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));

// Initialize use context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2082_6_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Employee/Day Career Estimated Statement
 *
 * @return {*}
 */
const M2082_6_Purchase_Summary = () => {
  // Get multi language
  const { t }: any = useDictionary({ programId: '2082.6' });
  // Initialize state
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>(
    cloneDeep({ ...defaultSearchParams }),
  );
  const [formDataStored, setFormDataStored] = useState(
    cloneDeep({ ...defaultSearchParams }),
  );
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  // Api call
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
  });

  // Values share with child component
  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    store,
    formDataStored,
    setFormDataStored,
  };

  /**
   *  Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2082_6_Purchase_Summary;
