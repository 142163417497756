/**
 * Update date: 16-05-2023
 * Screen 2011.2
 */
import TableList from './TableList';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { createContext, useContext, useEffect, useState } from 'react';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';

/**
 * create context
 */
const ProductBarcode = createContext({
  dataSource: {},
  refetchData: () => {},
  refetchBarcode: () => {},
});

ProductBarcode.displayName = 'ProductBarcode';

export const useProductBarcode = () => {
  const context = useContext(ProductBarcode);
  return context;
};

function ProductBarcodePrint() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [dataSource, setDataSource] = useState<any>();
  const store: any = new ArrayStore({
    data: dataSource,
    key: ['productId', 'optionId'],
  });

  const [{ data, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/product/barcodes',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const [{ loading: loadBarcode }, refetchBarcode] = useAxios(
    {
      url: '/product/barcodes/barcodes-print',
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingData && data?.data) {
      const d = data?.data?.map((o: any) => {
        let obj = { ...o, print_count: 0 };
        return obj;
      });
      setDataSource(d);
    }
  }, [data, loadingData]);

  const value: any = {
    productTpPermission,
    store,
    refetchData,
    refetchBarcode,
  };

  const loadingAll = loadingData || loadBarcode || loadingProductTpPermission;

  return (
    <>
      <ProductBarcode.Provider value={value}>
        <LoadPanel visible={loadingAll} />
        <FormSearch />
        <TableList />
      </ProductBarcode.Provider>
    </>
  );
}

export default ProductBarcodePrint;
