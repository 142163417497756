/**
 * Update date: 12-12-2023
 * Screen 2013.9
 */
import React, { createContext, useContext, useState, useMemo } from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import StoreProductListTable from './TableView';

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'StoreProductListInquiry';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const StoreProductListInquiry = () => {
  const { t }: any = useDictionary({});
  const [storeProductListData, setStoreProductListData] = useState<any>([]);
  const [storeProductListOptionData, setStoreProductListOptionData] =
    useState<any>([]);
  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value = useMemo(() => {
    return {
      t,
      refetchData,
      storeProductListData,
      setStoreProductListData,
      loadingFetchData,
      storeProductListOptionData,
      setStoreProductListOptionData,
    };
  }, [
    t,
    refetchData,
    storeProductListData,
    setStoreProductListData,
    loadingFetchData,
    storeProductListOptionData,
    setStoreProductListOptionData,
  ]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        <FormSearch />
        <StoreProductListTable />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default StoreProductListInquiry;
