/**
 * Update date: 24-05-2023
 * Screen 2033.1
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));
const ViewDetail = React.lazy(() => import('./View'));
/**
 * create context
 */
const DirectDeliveryContext = createContext({});

DirectDeliveryContext.displayName = 'DirectDeliveryContext';

export const useDirectDelivery = () => {
  return useContext(DirectDeliveryContext);
};

const DirectDeliveryConsignment = () => {
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    ctOrderId: null,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    vendorName: '',
    vendorId: null,
    dataList: {
      orderState: [],
      store: [],
    },
    orderState: '',
    store: '',
    fromDate: new Date(),
    endDate: new Date(),
    sendDueFromDate: null,
    sendDueToDate: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <ViewDetail />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <DirectDeliveryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </DirectDeliveryContext.Provider>
  );
};

export default DirectDeliveryConsignment;
