/**
 * Update date: 06-06-2023
 * Screen 2064.5
 */
import React, { useState, useContext, createContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));
/**
 * create context
 */
const RegisterDeliveryEmptyBottlesContext = createContext({});

RegisterDeliveryEmptyBottlesContext.displayName =
  'RegisterDeliveryEmptyBottlesContext';

export const useRegisterDeliveryEmptyBottles = () => {
  const context = useContext(RegisterDeliveryEmptyBottlesContext);
  return context;
};

const RegisterDirectDeliveryEmptyBottles = () => {
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    takeBackId: '',
    receivableCd: '',
    receivableNm: '',
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      store: [],
      receivableCd: [],
    },
    store: '',
    receivableCd: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    data,
    setData,
    refetchData,
    modeView,
    setModeView,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <RegisterDeliveryEmptyBottlesContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </RegisterDeliveryEmptyBottlesContext.Provider>
  );
};

export default RegisterDirectDeliveryEmptyBottles;
