/**
 * Update date: 26-05-2023
 * Screen 2042.5
 */
import { useContext } from 'react';
import Context from './Context';

export const useCustomContext = () => {
  const context = useContext(Context);
  return context;
};
