/**
 * Update date: 31-05-2023
 * Screen 2052.1
 */
import LoadPanel from 'app/components/LoadPanel';
import MenuSound from 'app/components/MenuSound';
import ArrayStore from 'devextreme/data/array_store';
import React, { createContext, useContext, useRef, useState } from 'react';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ManualRegistrationPDAContext = createContext({
  emptyBottleDepositListData: [],
});

ManualRegistrationPDAContext.displayName = 'ManualRegistrationPDAContext';

export const useManualRegistrationPDA = () => {
  const context = useContext(ManualRegistrationPDAContext);
  return context;
};

export default function ManualRegistrationPDA() {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const dataGridRef: any = useRef(null);
  const [emptyBottleDepositListData, setEmptyBottleDepositListData] = useState(
    [],
  );
  const [listDataBarcode, setListDataBarCode] = useState<any>([]);
  // Get data

  const store: any = new ArrayStore({
    data: listDataBarcode,
    key: 'productSku',
  });

  /**
   * on clear data grid
   *
   */
  const onClearDataGrid = () => {
    setListDataBarCode([]);
  };

  const value: any = {
    selectedTabIndex,
    setSelectedTabIndex,
    store,
    dataGridRef,
    listDataBarcode,
    setListDataBarCode,
    onClearDataGrid,
    emptyBottleDepositListData,
    setEmptyBottleDepositListData,
  };

  return (
    <ManualRegistrationPDAContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <List ref={dataGridRef} />
        <MenuSound />
      </React.Suspense>
    </ManualRegistrationPDAContext.Provider>
  );
}
