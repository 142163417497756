/**
 * Update date: 23-05-2023
 * Screen 2021.1
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));

/**
 * create context
 */
const RegurlarOrderContext = createContext({});
RegurlarOrderContext.displayName = 'RegularOrderContext';
export const useRegularOrder = () => {
  const context = useContext(RegurlarOrderContext);
  return context;
};

const RegularOrder = () => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    use: null,
    subject: '',
    dataList: {
      vendor: [],
      manager: [],
      zone: [],
    },
    vendor: '',
    manager: '',
    zone: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ data: resData, loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: '/warehouse/regular-order',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on update data
   *
   * @param {*} record
   */
  const onUpdateData = record => {
    const existItem = data?.some(
      (o: any) => o?.regularOrderId === record?.regularOrderId,
    );
    let l: any = [];
    if (existItem) {
      l = [...data]?.map((o: any) => {
        if (o?.regularOrderId === record?.regularOrderId) return record;
        return o;
      });
    } else {
      l = [...data];
      l.unshift(record);
    }
    setData(l);
  };

  const value: any = {
    modeView,
    setModeView,
    data,
    refetchData,
    onUpdateData,
    formData,
    setFormData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resData?.data) {
      setData(resData?.data);
    }
  }, [resData]);

  const loadingAll = loadingfetchData;

  return (
    <RegurlarOrderContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </RegurlarOrderContext.Provider>
  );
};

export default RegularOrder;
