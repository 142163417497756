/**
 * Update date: 31-05-2023
 * Screen 2051.6
 */
import React, { useState, useRef } from 'react';
import FormSearch from './FormSearch/index';
import { DataGridFull } from 'app/components/DataGrid';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { useCallback } from 'react';
import useFormat from 'hooks/useFormat';
import useDictionary from 'hooks/useDictionary';

export default function InventoryCostStatusByProduct() {
  const dataGridRef: any = useRef(null);
  const { t } = useDictionary({
    programId: '2051.6',
  });
  const { AmountFormat, DoubleFormat, QtyFormat, GetExcelFormat } = useFormat();

  const [inventoryData, setInventoryData] = useState([]);

  const columns: any = [
    {
      dataField: 'zoneName',
      caption: t('Zone'),
      showWhenGrouped: true,
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      showWhenGrouped: true,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      showWhenGrouped: true,
    },
    {
      dataField: 'taxNm',
      caption: t('Tax Name'),
      showWhenGrouped: true,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      showWhenGrouped: true,
    },
    {
      dataField: 'productCost',
      caption: t('Product Cost'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'inStockEA',
      caption: t('Stock EA'),
      format: QtyFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'inStockPrice',
      caption: t('Stock Cost'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'productMakerName',
      caption: t('Maker'),
      visible: false,
    },
    {
      dataField: 'productOriginNm',
      caption: t('Origin'),
      visible: false,
    },
    {
      dataField: 'vendorNmDefault',
      caption: t('Default Vendor'),
      visible: false,
    },
    {
      dataField: 'largeCategory',
      caption: t('Large Category'),
      visible: false,
    },
    {
      dataField: 'mediumCategory',
      caption: t('Medium Category'),
      visible: false,
    },
    {
      dataField: 'smallCategory',
      caption: t('Small Category'),
      visible: false,
    },
    {
      dataField: 'subCategory',
      caption: t('Sub Category'),
      visible: false,
    },
  ];
  /**
   * on export excel
   */
  const onExporting = useCallback(() => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const dataGrid = dataGridRef?.current.instance;
    exportDataGrid({
      component: dataGrid,
      worksheet: worksheet,
      customizeCell: function (option: any) {
        const { gridCell, excelCell } = option;
        if (gridCell.column.dataType === 'number') {
          if (gridCell.rowType === 'group' && gridCell.groupSummaryItems) {
            excelCell.value = gridCell.groupSummaryItems[0].value;
            excelCell.numFmt = GetExcelFormat(gridCell.column.format);
          }
          if (
            gridCell.rowType === 'groupFooter' ||
            gridCell.rowType === 'totalFooter'
          ) {
            excelCell.value = gridCell.value;
            excelCell.numFmt = GetExcelFormat(gridCell.column.format);
          }
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'SMDC_20516 Inventory cost status by product.xlsx',
        );
      });
    });
  }, []);

  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <FormSearch setInventoryData={setInventoryData} />
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={inventoryData}
        options={{
          keyExpr: 'productId',
          onExporting: onExporting,
          groupPanel: {
            visible: true,
            allowColumnDragging: true,
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !inventoryData?.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                name: 'productCost',
                column: 'productCost',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'inStockEA',
                column: 'inStockEA',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'inStockPrice',
                column: 'inStockPrice',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'productCost',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'inStockEA',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'inStockPrice',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </>
  );
}
