/**
 * Update date: 23-05-2023
 * Screen 2031.1
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import OrderReceiptTable from './TableView';
import FormSearch from './FormSearch';
import useProfileInfo from 'hooks/useProfileInfo';
import ProductPopup from './TableView/ProductPopup';
/**
 * create context
 */
const orderDetail = createContext({
  detailTable: [],
  refetchData: () => {},
  refetchUpdate: () => {},
  setSelectedRow: () => {},
  setShowPopup: () => {},
  updateData: '',
  loadingUpate: '',
});
orderDetail.displayName = 'orderDetail';
export const useOrderDetail = () => {
  const context = useContext(orderDetail);
  return context;
};

function OrderInquiryReceipt() {
  const { vendorName, vendorId } = useProfileInfo();

  const [orderData, setOrderData] = useState([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const billId = selectedRow?.ctOrderBill;

  const [{ data: ordData, loading: loadingOrder }, refetchData] = useAxios(
    {
      url: '/warehouse/order-inquiry',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (billId) {
      refetchDetail();
    }
  }, [billId]);

  const [{ data: subData, loading: loadingDetail }, refetchDetail] = useAxios(
    {
      url: `/warehouse/order-inquiry/${billId}`,
      method: 'GET',
    },
    {
      manual: true,
      useCache: false,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (subData && !loadingDetail) {
      setDataSource(subData?.data);
    }
  }, [subData, loadingDetail]);

  const [{ data: updateData, loading: loadingUpate }, refetchUpdate] = useAxios(
    {
      url: '/warehouse/order-inquiry',
      method: 'PUT',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const value: any = {
    vendorId,
    orderData,
    setSelectedRow,
    refetchUpdate,
    setShowPopup,
    vendorName,
    updateData,
    loadingUpate,
    refetchData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (ordData?.data) {
      setOrderData(ordData?.data);
    }
  }, [ordData]);

  const loadingAll = loadingOrder || loadingDetail || loadingUpate;

  return (
    <>
      <orderDetail.Provider value={value}>
        <LoadPanel visible={loadingAll} />
        <FormSearch />
        <OrderReceiptTable />
        <ProductPopup
          visible={showPopup}
          onHiding={() => {
            setShowPopup(false);
          }}
          dataSource={dataSource}
          selectedRow={selectedRow}
        />
      </orderDetail.Provider>
    </>
  );
}

export default OrderInquiryReceipt;
