/**
 * Update date: 29-05-2023
 * Screen 2046.1
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ForwardingInspectionProcessingContext = createContext({
  forwardBillListData: [],
});

ForwardingInspectionProcessingContext.displayName =
  'ForwardingInspectionProcessingContext';

export const useForwardingInspectionProcessing = () => {
  const context = useContext(ForwardingInspectionProcessingContext);
  return context;
};

export default function ForwardingInspectionProcessing() {
  const { t }: any = useDictionary({ programId: '2046.1' });
  const [forwardBillListData, setForwardBillListData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    forwardId: '',
  });

  const [{ data: forwardBillRes, loading: forwardBillLoading }, refetchData] =
    useAxios(
      {
        url: 'ecommerce/forward-inspection',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * on update data
   *
   * @param {*} record
   */
  const onUpdateData = record => {
    const existItem = forwardBillListData?.some(
      (o: any) => o?.storeGroupId === record?.storeGroupId,
    );
    let dataUpdate: any = [];
    if (existItem) {
      dataUpdate = [...forwardBillListData]?.map((o: any) => {
        if (o?.storeGroupId === record?.storeGroupId) return record;
        return o;
      });
    } else {
      dataUpdate = [...forwardBillListData];
      dataUpdate.unshift(record);
    }
    setForwardBillListData(dataUpdate);
  };

  const value: any = {
    modeView,
    setModeView,
    forwardBillListData,
    setForwardBillListData,
    refetchData,
    onUpdateData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (forwardBillRes?.data) {
      const dataMapping = (forwardBillRes?.data || []).map(o => {
        o.statusNm = o?.status === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setForwardBillListData(dataMapping);
    }
  }, [forwardBillRes]);

  const loadingAll = forwardBillLoading;

  return (
    <ForwardingInspectionProcessingContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ForwardingInspectionProcessingContext.Provider>
  );
}
