/**
 * Update date: 14-06-2023
 * Popup confirm delete component
 */
import React, { memo } from 'react';
import { Button, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import { fileDelete } from 'images';

interface IPopupConfirm {
  visible?: boolean;
  onOk?: () => void;
  onHiding?: () => void;
  content?: any;
  title?: string;
  textButtonDelete?: string;
}
function PopupConfirmDelete({
  visible,
  onOk,
  onHiding,
  content,
  title,
  textButtonDelete,
}: IPopupConfirm) {
  const { t } = useDictionary({});
  const { themePro }: any = useApp();

  return (
    <Popup
      visible={visible}
      onHiding={() => onHiding?.()}
      showTitle={true}
      title={themePro ? '' : title || t('Confirm')}
      width={themePro ? 350 : 300}
      height={'auto'}
      className="modal-confirm-popup"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          gap: themePro ? 10 : 20,
        }}
      >
        {themePro && (
          <div style={{ textAlign: 'center' }}>
            <img className="icon" src={fileDelete} alt="icon warning" />
          </div>
        )}
        {themePro && <div className="modal-confirm-popup-title">{title}</div>}
        <div className="modal-confirm-popup-des">{content}</div>
        {themePro && <br />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <Button
            icon={themePro ? '' : 'close'}
            text={t('Cancel')}
            onClick={() => onHiding?.()}
            width={themePro ? '100%' : 'auto'}
          />
          <Button
            className="button-error"
            icon={themePro ? '' : 'trash'}
            text={t(textButtonDelete ? textButtonDelete : 'Delete')}
            onClick={() => onOk?.()}
            width={themePro ? '100%' : 'auto'}
          />
        </div>
      </div>
    </Popup>
  );
}

export default memo(PopupConfirmDelete);
