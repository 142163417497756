/**
 * Update date: 19-05-2023
 * Screen 2014.1
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useInventoryContext } from '../index';
import PopupProductImageDragDrop from '../../../components/PopupCommon/DragAndDrop/PopupProductImageDragDrop';
import useFormatTime from 'hooks/useFormatTime';

const exportFileName =
  'SMDC_2014.1 Inquiry HUB Standard Barcode Regist List_' +
  moment().format('YYYYMMDD');

function InventoryTable(props) {
  const { t, store }: any = useInventoryContext();
  const gridRef = useRef<any>(null);
  const { AmountFormat } = useFormat();
  const { DateTimeFormat } = useFormatTime();
  const [showPopupImageView, setShowPopupImageView] = useState<any>({
    productId: null,
    visible: false,
    images: null,
    hubProductOptionCode: null,
  });

  /**
   * render view image
   *
   * @param {*} record
   * @return {*}
   */
  const cellRenderViewImage = (record: any) => {
    const { productId, dataImages, hubProductOptionCode } = record?.data || {};

    return dataImages?.length ? (
      <span
        className="cell-link"
        onClick={() =>
          setShowPopupImageView({
            productId: productId,
            visible: true,
            images: dataImages,
            hubProductOptionCode,
          })
        }
      >
        {t('Image View')}
      </span>
    ) : null;
  };

  /**
   * render product image
   *
   * @param {*} record
   * @return {*}
   */
  const renderProductImage = record => {
    const { productImg } = record?.data || {};
    return productImg ? (
      <div className="product-image-grid-custom">
        <img src={productImg} width={26} height={26} alt="" />
      </div>
    ) : null;
  };

  /**
   * render hub image
   *
   * @param {*} record
   * @return {*}
   */
  const renderHubImage = record => {
    const { dataImages } = record?.data || {};
    return dataImages?.length ? (
      <div className="product-image-grid-custom">
        <img src={dataImages[0]} width={26} height={26} alt="" />
      </div>
    ) : null;
  };

  const columns: any = [
    {
      dataField: 'no',
      caption: t('Numbers'),
      alignment: 'center',
      allowEditing: false,
    },
    {
      dataField: 'wmsSaveDt',
      caption: t('Wms Save Date'),
      dataType: 'datetime',
      format: DateTimeFormat,
      visible: true,
      allowEditing: false,
    },
    {
      dataField: 'hubModifyDm',
      caption: t('Hub Modify Date'),
      dataType: 'datetime',
      format: DateTimeFormat,
      allowEditing: false,
    },
    {
      dataField: 'hubProductOptionCode',
      caption: t('Hub Sku Code'),
      alignment: 'left',
      allowEditing: false,
      visible: false,
      dataType: 'string',
    },
    {
      dataField: 'standardProductCode',
      caption: t('Standard Product Code'),
      alignment: 'left',
      allowEditing: false,
      visible: false,
      dataType: 'string',
    },
    {
      dataField: 'productImg',
      caption: t('Product Image'),
      allowEditing: false,
      alignment: 'center',
      cellRender: renderProductImage,
    },
    {
      caption: t('Hub Image'),
      allowEditing: false,
      alignment: 'center',
      fixedPosition: 'right',
      fixed: true,
      cellRender: renderHubImage,
    },
    {
      dataField: 'productName',
      caption: t('Product'),
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'brandCodeName',
      caption: t('Brand Name'),
      alignment: 'left',
      allowEditing: false,
      visible: false,
      dataType: 'string',
    },
    {
      dataField: 'optionCode',
      caption: t('Option Code'),
      alignment: 'center',
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'optionName',
      caption: t('Option Name'),
      alignment: 'left',
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'largeCategoryNm',
      caption: t('Large Category'),
      visible: true,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'mediumCategoryNm',
      caption: t('Medium Category'),
      visible: true,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'smallCategoryNm',
      caption: t('Small Category'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'statusMessage',
      caption: t('Status Message'),
      alignment: 'center',
      allowEditing: false,
      visible: false,
      dataType: 'string',
    },
    {
      dataField: 'brandCode',
      caption: t('Brand Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'alcoholTypeCodeName',
      caption: t('Alcohol Type'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'alcoholTypeCode',
      caption: t('Alcohol Type Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'originCodeName',
      caption: t('Origin'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'originCode',
      caption: t('Origin Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'bottleCodeName',
      caption: t('Bottle'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'bottleCode',
      caption: t('Bottle Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'category',
      caption: t('Category'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'largeCategory',
      caption: t('Large Category Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'mediumCategory',
      caption: t('Medium Category Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'smallCategory',
      caption: t('Small Category Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'subCategory',
      caption: t('Sub Category Code'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'subCategoryNm',
      caption: t('Sub Category'),
      visible: false,
      allowEditing: false,
      dataType: 'string',
    },
    {
      dataField: 'productSku',
      caption: t('Product Sku'),
      visible: false,
      alignment: 'center',
      allowEditing: false,
    },
    {
      dataField: 'productOptionPrice',
      caption: t('Product Option Price'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'hubRegistDm',
      caption: t('Hub Register Date'),
      visible: false,
      dataType: 'datetime',
      format: DateTimeFormat,
      allowEditing: false,
    },
    {
      dataField: 'wmsSaveTf',
      caption: t('Wms Save'),
      visible: false,
      allowEditing: false,
    },
    {
      caption: t('Action'),
      alignment: 'left',
      fixedPosition: 'right',
      fixed: true,
      allowEditing: false,
      cellRender: cellRenderViewImage,
    },
  ];

  const exportExcel = () => {
    gridRef?.current?.instance?.exportToExcel();
  };

  // window['store'] = store;

  return (
    <>
      <div>
        <DataGridFull
          ref={gridRef}
          dataSource={store}
          columns={columns}
          storageKey="2014.1"
          options={{
            hiddenDetailProduct: true,
            columnAutoWidth: true,
            export: {
              fileName: exportFileName,
            },
            onToolbarPreparing: (e: any) => {
              e.toolbarOptions.items.unshift(
                {
                  location: 'before',
                  template: 'totalCount',
                },
                {
                  location: 'before',
                  widget: 'dxButton',
                  options: {
                    icon: 'xlsxfile',
                    text: t('Export Excel'),
                    // disabled: !tableData.length,
                    onClick: () => exportExcel(),
                  },
                },
              );
            },
            groupPanel: {
              visible: true,
            },
            summary: {
              totalItems: [
                {
                  column: '',
                  summaryType: 'sum',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  dataType: 'number',
                },
              ],
              groupItems: [
                {
                  count: 'sum',
                  displayFormat: '{0}',
                },
              ],
            },
          }}
        />
      </div>
      {showPopupImageView?.visible && (
        <PopupProductImageDragDrop
          productId={showPopupImageView?.productId}
          visible={showPopupImageView?.visible}
          dragImages={showPopupImageView?.images}
          onHiding={e => {
            if (e !== undefined) {
              store?.update(showPopupImageView?.hubProductOptionCode, {
                productImg: e,
              });
            }
            setShowPopupImageView({
              productId: null,
              visible: false,
              images: null,
              hubProductOptionCode: null,
            });
          }}
        />
      )}
    </>
  );
}

export default InventoryTable;
