/**
 * Update date: 2024-07-31
 * Screen 2088.1
 */
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useRef, useState } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import OrderPGPaymentTab from './OrderPGPaymentTab';
import ForwarDetailTab from './ForwarDetailTab';
import UploadHistoryTab from './UploadHistoryTab';

const FormSearch = React.lazy(() => import('./FormSearch'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2088_1_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2088_1 = () => {
  const { t }: any = useDictionary({ programId: '2088.1' });
  const refFormSearch = useRef<any>(null);
  const [tabPanelIndex, setTabPanelIndex] = useState<number>(0);
  const [dataHistory, setDataHistory] = useState([]);
  const [dataPg, setDataPg] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);

  const storeDataPg: any = new ArrayStore({
    data: dataPg,
    key: ['mallCd', 'mallOrderId'],
  });

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <div style={{ backgroundColor: '#fff', marginBottom: 10 }}>
        <FormSearch
          t={t}
          tabPanelIndex={tabPanelIndex}
          setDataHistory={setDataHistory}
          setDataPg={setDataPg}
          setDataDetail={setDataDetail}
          ref={refFormSearch}
        />
      </div>
      <TabPanel
        className="tab-panel-not-padding-content"
        deferRendering={false}
        defaultSelectedIndex={tabPanelIndex}
        selectedIndex={tabPanelIndex}
        swipeEnabled={false}
        onOptionChanged={(e: any) => {
          if (e?.name === 'selectedIndex') {
            setTabPanelIndex(e?.value);
          }
        }}
      >
        <Item title={t('PG Upload History')}>
          <UploadHistoryTab
            t={t}
            dataHistory={dataHistory}
            refFormSearch={refFormSearch}
          />
        </Item>
        <Item title={t('Order PG Payment')}>
          <OrderPGPaymentTab
            t={t}
            store={storeDataPg}
            refFormSearch={refFormSearch}
          />
        </Item>
        <Item title={t('Forward Details (No PG details)')}>
          <ForwarDetailTab t={t} dataDetail={dataDetail} />
        </Item>
      </TabPanel>
    </React.Suspense>
  );
};

export default M2088_1;
