import { createSlice } from '@reduxjs/toolkit';

interface DataSend {
  programId: string;
  programName: string;
  programType: string;
  programUrl: string;
  adminOnly: boolean;
  parentId: string;
}
export interface RegisterProgramPayLoad {
  data: DataSend;
  onSuccess: Function;
  onError: Function;
}

export interface RegisterProgramState {
  isCreating: boolean;
}
const initialState: RegisterProgramState = {
  isCreating: false,
};

const registerProgramSlice = createSlice({
  name: 'registerProgram',
  initialState,
  reducers: {
    registerProgramId(state, actions) {
      state.isCreating = true;
    },
    registerProgramIdSuccess(state) {
      state.isCreating = false;
    },
    registerProgramIdFail(state) {
      state.isCreating = false;
    },
    deleteProgram(state, actions) {},
  },
});
//Action
export const registerProgramActions = registerProgramSlice.actions;

//Selectors
export const isCreating = (state: any) => state.registerProgram.isCreating;

const registerProgramReducer = registerProgramSlice.reducer;

export default registerProgramReducer;
