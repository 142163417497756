/**
 * Update date: 23-05-2023
 * Screen 2031.1
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import { Popup, ScrollView } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import Form from 'devextreme-react/form';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { isEqual } from 'lodash';
import { memo, useRef, useState } from 'react';

interface IProductPopup {
  dataSource?: any;
  selectedRow?: any;
  visible?: boolean;
  onHiding?: () => void;
}

const ProductPopup = ({
  dataSource,
  selectedRow,
  visible,
  onHiding,
}: IProductPopup) => {
  const { themePro }: any = useApp();
  const [formData, setFormData] = useState({
    ctOrderBill: selectedRow?.ctOrderBill,
    ctOrderDt: selectedRow?.ctOrderDt,
    ctOrderMstNt: selectedRow?.ctOrderMstNt,
    ctOrderState: selectedRow?.ctOrderState,
    currencyTp: selectedRow?.currencyTp,
    deliverDueDate: selectedRow?.deliverDueDate,
    storeNm: selectedRow?.storeNm,
    vendorId: selectedRow?.vendorId,
    vendorNm: selectedRow?.vendorNm,
  });
  const formRef: any = useRef(null);
  const { AmountFormat, QtyFormat, PercentFormat } = useFormat();
  const { DateFormat } = useFormatTime();

  const { t }: any = useDictionary({
    programId: '2031.1',
  });

  const columns: any = [
    {
      dataField: 'ctOrderId',
      caption: t('ID'),
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
    },
    {
      dataField: 'productNm',
      caption: t('Product Name'),
    },
    {
      dataField: 'marketableSz',
      caption: t('Marketable Size'),
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
    },
    {
      dataField: 'qtyPerPack',
      caption: t('Quantity Per Pack'),
    },
    {
      dataField: 'purchaseUnitPrice',
      caption: t('Purchase Unit Price'),
      format: PercentFormat,
    },
    {
      dataField: 'ctOrderQt',
      caption: t('Qty'),
      format: QtyFormat,
    },
    {
      dataField: 'ctOrderAmount',
      caption: t('Amount'),
      format: AmountFormat,
    },
    {
      dataField: 'supplyAmount',
      caption: t('Supply Amount'),
      format: AmountFormat,
    },
    {
      dataField: 'vatAmount',
      caption: t('VAT'),
      format: AmountFormat,
    },
    {
      dataField: 'bottleAmount',
      caption: t('Bottle'),
      format: QtyFormat,
    },
    {
      dataField: 'containerAmount',
      caption: t('Container'),
      format: QtyFormat,
    },
    {
      dataField: 'totalAmount',
      caption: t('G.Total'),
      format: AmountFormat,
    },
    {
      dataField: 'ctOrderDetNt',
      caption: t('Note'),
    },
    {
      dataField: 'taxRate',
      caption: t('Tax Rate'),
      visible: false,
    },
    {
      dataField: 'bottleNm',
      caption: t('Bottle ID'),
      visible: false,
    },
    {
      dataField: 'bottleUnitPrice',
      caption: t('Bottle Unit Price'),
      format: AmountFormat,
      visible: false,
    },
    {
      dataField: 'containerNm',
      caption: t('Container ID'),
      visible: false,
    },
    {
      dataField: 'optionContainerUnitPrice',
      caption: t('Container Unit Price'),
      format: AmountFormat,
      visible: false,
    },
  ];

  if (!isEqual(formData, selectedRow)) {
    setFormData(selectedRow);
  }
  /**
   * rendern content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <div>
        <ScrollView height={themePro ? '100%' : 830}>
          <div>
            {themePro && (
              <div className="modal-popup-header">
                <span className="title-page">{t('Detail')}</span>
                <div>
                  <Button
                    stylingMode="contained"
                    text={t('Close')}
                    icon="close"
                    onClick={onHiding}
                  />
                </div>
              </div>
            )}
            <span
              className="targetForm"
              style={themePro ? { borderWidth: 0 } : {}}
            >
              <Form
                ref={formRef}
                formData={formData}
                colCount={3}
                labelLocation="top"
                readOnly={true}
                className="body-padding-white"
                items={[
                  {
                    dataField: 'ctOrderBill',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Order Bill'),
                    },
                  },
                  {
                    dataField: 'ctOrderDt',
                    editorType: 'dxDateBox',
                    label: {
                      text: t('Date'),
                    },
                    editorOptions: {
                      displayFormat: DateFormat,
                    },
                  },
                  {
                    dataField: 'ctOrderMstNt',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Note'),
                    },
                  },
                  {
                    dataField: 'ctOrderState',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Order State'),
                    },
                  },
                  {
                    dataField: 'currencyTp',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Currency'),
                    },
                  },
                  {
                    dataField: 'deliverDueDate',
                    editorType: 'dxDateBox',
                    label: {
                      text: t('Deliver Due Date'),
                    },
                    editorOptions: {
                      displayFormat: DateFormat,
                    },
                  },
                  {
                    dataField: 'storeNm',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Store'),
                    },
                  },
                  {
                    dataField: 'vendorNm',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Vendor'),
                    },
                  },
                ]}
              />
            </span>
          </div>
          <div className="body-padding-white">
            <DataGridFull
              columns={columns}
              dataSource={dataSource}
              options={{
                key: 'ordbillid',
                groupPanel: {
                  visible: true,
                },
                height: themePro ? 'calc(100vh - 400px)' : 'auto',
                columnAutoWidth: true,
                pager: {
                  allowedPageSize: [5, 10],
                  displayMode: 'compact',
                  showNavigationButtons: true,
                  showPageSizeSelector: true,
                  showInfo: true,
                },
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift({
                    location: 'before',
                    template: 'totalCount',
                  });
                },
                summary: {
                  totalItems: [
                    {
                      column: 'ctOrderQt',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'ctOrderAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'supplyAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'vatAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'bottleAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'containerAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'totalAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                  ],
                  groupItems: [
                    {
                      summaryType: 'count',
                      displayFormat: '{0}',
                    },
                    {
                      column: 'ctOrderQt',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'ctOrderAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'supplyAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'vatAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'bottleAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'containerAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                    {
                      column: 'totalAmount',
                      valueFormat: AmountFormat,
                      displayFormat: '{0}',
                      summaryType: 'sum',
                      alignByColumn: true,
                      showInGroupFooter: false,
                    },
                  ],
                },
              }}
            />
          </div>
          {!themePro && (
            <div
              style={{
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Button text={t('Close')} icon="close" onClick={onHiding} />
            </div>
          )}
        </ScrollView>
      </div>
    );
  };
  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      showTitle={true}
      title={t('Detail')}
      width={themePro ? '70vw' : '90%'}
      height={themePro ? '100vh' : '95%'}
      maxWidth={'auto'}
      contentRender={content}
    ></Popup>
  );
};
export default memo(ProductPopup);
