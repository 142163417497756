/**
 * Update date: 05-06-2023
 * Screen 2053.3
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { createContext, useContext, useEffect, useState } from 'react';
import FormSearch from './FormSearch';
import TableList from './TableList';
/**
 * create context
 */
const WeekIndex = createContext({
  dataSource: {},
  refetchData: () => {},
});

WeekIndex.displayName = 'WeekIndex';

export const useWeekIndex = () => {
  const context = useContext(WeekIndex);
  return context;
};

function ProductWeekIndexInquiry() {
  const [dataSource, setDataSource] = useState<any>();

  const [{ data, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/product/week-index',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingData && data?.data) {
      setDataSource(data?.data);
    }
  }, [data, loadingData]);

  const value: any = {
    dataSource,
    refetchData,
  };

  return (
    <>
      <WeekIndex.Provider value={value}>
        <LoadPanel visible={loadingData} />
        <FormSearch />
        <TableList />
      </WeekIndex.Provider>
    </>
  );
}

export default ProductWeekIndexInquiry;
