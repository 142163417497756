/**
 * Update date: 15-05-2023
 * Screen 1044.1
 */
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectPostCode from 'app/components/PopupCommon/PopupSelectPostCode';
import PopupSelectUserCenter from 'app/components/PopupCommon/PopupSelectUserCenter';
import FileUploadPreviews from 'app/components/Templates/FileUploadPreviews';
import useAxios from 'axios-hooks';
import { CONSTANT } from 'constant';
import { Button, Form, Template } from 'devextreme-react';
import useFormatTime from 'hooks/useFormatTime';
import React, { useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useRegisteringVendor } from '..';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import LoadPanel from 'app/components/LoadPanel';
import { restrictKeyboardPhone } from 'utils/common/restrict-keyboard-phone';
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { replaceWhiteSpaceValueChange } from 'utils/common/replaceWhiteSpaceValueChange';
import VendorManager from './VendorManager';
import ArrayStore from 'devextreme/data/array_store';

const defaultParams = {
  vendorId: null,
  vendorCode: '',
  vendorName: '',
  vendorShortName: '',
  use: true,
  dealStartDate: '',
  dealEndDate: '',
  vendorAccountingCode: '',
  dealOldCode: '',
  vendorRepresentative: '',
  vendorEmail: '',

  mobileZipCode: '+(82)',
  phoneZipCode: '+(82)',
  faxZipCode: '+(82)',

  vendorMobile: '',
  vendorPhone: '',
  vendorFax: '',
  vendorPostCode: '',
  vendorAddressBasic: '',
  vendorAddressDetail: '',
  vendorNote: '',

  businessNumber: '',
  companyNumber: '',
  vendorBusiness: '',
  vendorIndustry: '',

  paymentAccountNumber: '',
  paymentDepositor: '',

  vendorManager: '',
  managerNote: '',

  vendorReceiver: '',
  deliveryPostCode: '',
  deliveryAddressBasic: '',
  deliveryAddressDetail: '',

  vendorTypeCode: '',
  country: '',
  paymentBank: '',
  officeManager: '',
  salesManager: '',
  interlockCd: '',
  interlockCd2: '',
};
const listTypeCommon = {
  vendorTypeCode: {
    codeType: 'VT',
    title: 'Search Vendor Type',
  },
  paymentBank: {
    codeType: 'BK',
    title: 'Search Bank',
  },
  country: {
    codeType: 'CU',
    title: 'Search Country',
  },
  officeManager: {
    codeType: 'OM',
    title: 'Search Office Manager',
  },
  salesManager: {
    codeType: 'SM',
    title: 'Search Sales Manager',
  },
  vendorPostCode: {
    codeType: 'SC',
  },
  deliveryPostCode: {
    codeType: 'DC',
  },
};

function FormCreate(props) {
  const context: any = useRegisteringVendor();
  const { themePro }: any = useApp();
  const formRef: any = useRef(null);
  const listPhone: any = CONSTANT.listPhone;
  const zipCodePhone = listPhone.filter(o => o?.value);
  const { modeView, setModeView, onUpdateData } = context;
  const [showPopupByType, setShowPopupByType] = useState('');
  const [showModalConfirm, setShowModalConfirm] = useState<any>(false);
  const [contentPopupConfirm, setContentPopupConfirm] = useState('');
  const [elementSwitch, setElementSwitch] = useState<any>(null);
  const [formData, setFormData] = useState<any>({
    ...defaultParams,
    formStore: {
      vendorTypeCode: null,
      country: null,
      paymentBank: null,
      officeManager: null,
      salesManager: null,
    },
  });
  const [documentList, setDocumentList] = useState([]);
  const [vendorManagerList, seVendorManagerList] = useState([]);
  const { DateFormat } = useFormatTime();
  const { t }: any = useDictionary({
    programId: '1044.1',
  });

  const storeVendorManager: any = new ArrayStore({
    data: vendorManagerList,
  });

  const [{ loading }, refetchData] = useAxios(
    {
      url: 'user/vendor/',
      method: 'GET',
    },
    { manual: true, autoCancel: true },
  );

  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: '/user/vendor',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * fetch data
   */
  useEffect(() => {
    if (modeView?.type !== 'edit' || !modeView?.vendorId) return;
    const fetchData = async () => {
      if (!modeView?.vendorId) return;
      const res = await refetchData({
        url: '/user/vendor/' + modeView?.vendorId,
      });
      if (res?.data?.status === '200') {
        const { data } = res?.data;
        let vendorManagerName = '';
        const vendorManagerMapping = (data?.vendorManagerList || [])
          ?.sort((a, b) => b?.vendor_manager_id - a?.vendor_manager_id)
          .map((o, idx) => {
            if (o?.main_contact_tf) {
              vendorManagerName = o?.vendor_manager_nm;
            }
            return { ...o, ID: idx + 1 };
          });
        const dataForm = {
          vendorId: data?.vendorId,
          vendorCode: data?.vendorCode,
          vendorName: data?.vendorName,
          vendorShortName: data?.vendorShortName,
          use: data?.use,
          dealStartDate: data?.dealStartDate,
          dealEndDate: data?.dealEndDate,
          vendorAccountingCode: data?.vendorAccountingCode,
          dealOldCode: data?.dealOldCode,
          vendorRepresentative: data?.vendorRepresentative,
          vendorEmail: data?.vendorEmail,
          mobileZipCode: data?.vendorMobile?.slice(0, 5),
          phoneZipCode: data?.vendorPhone?.slice(0, 5),
          faxZipCode: data?.vendorFax?.slice(0, 5),
          vendorMobile: data?.vendorMobile?.slice(
            5,
            data?.vendorMobile?.length,
          ),
          vendorPhone: data?.vendorPhone?.slice(5, data?.vendorPhone?.length),
          vendorFax: data?.vendorFax?.slice(5, data?.vendorFax?.length),
          vendorPostCode: data?.vendorPostCode,
          vendorAddressBasic: data?.vendorAddressBasic,
          vendorAddressDetail: data?.vendorAddressDetail,
          vendorNote: data?.vendorNote,
          businessNumber: data?.businessNumber,
          companyNumber: data?.companyNumber,
          vendorBusiness: data?.vendorBusiness,
          vendorIndustry: data?.vendorIndustry,
          paymentAccountNumber: data?.paymentAccountNumber,
          paymentDepositor: data?.paymentDepositor,
          vendorManager: vendorManagerName || data?.vendorManager,
          managerNote: data?.managerNote,
          vendorReceiver: data?.vendorReceiver,
          deliveryPostCode: data?.deliveryPostCode,
          deliveryAddressBasic: data?.deliveryAddressBasic,
          deliveryAddressDetail: data?.deliveryAddressDetail,
          interlockCd: data?.interlockCd,
          interlockCd2: data?.interlockCd2,
          formStore: {
            vendorTypeCode:
              data?.vendorTypeName && data?.vendorTypeCode
                ? {
                    name: data?.vendorTypeName,
                    code: data?.vendorTypeCode,
                  }
                : null,
            country:
              data?.vendorCountryName && data?.vendorCountry
                ? {
                    name: data?.vendorCountryName,
                    code: data?.vendorCountry,
                  }
                : null,
            paymentBank:
              data?.bankName && data?.bankCode
                ? {
                    name: data?.bankName,
                    code: data?.bankCode,
                  }
                : null,
            officeManager:
              data?.officeManager && data?.officeManagerName
                ? {
                    name: data?.officeManagerName,
                    code: data?.officeManager,
                  }
                : null,
            salesManager:
              data?.salesManagerName && data?.salesManager
                ? {
                    name: data?.salesManagerName,
                    code: data?.salesManager,
                  }
                : null,
          },
        };
        setFormData(dataForm);
        setDocumentList(data?.vendorDocumentResponses);
        seVendorManagerList(vendorManagerMapping);
      } else {
        notification({ res });
      }
    };
    fetchData();
  }, [modeView?.type]);

  /**
   * refresh store code
   *
   */
  const refreshStoreCode = async () => {
    const resStoreCode = await refetchData({
      url: '/user/vendor/generate-vendor-code',
      method: 'GET',
    });

    if (resStoreCode?.data?.status === '200') {
      setFormData({ ...formData, vendorCode: resStoreCode?.data.data });
    } else {
      notification({
        res: resStoreCode,
      });
    }
  };

  /**
   * on submit data
   *
   * @param {*} { type }
   */
  const submitData = async ({ type }) => {
    const dataReq = {
      ...formData,
      vendorMobile: formData?.vendorMobile
        ? formData?.mobileZipCode + formData?.vendorMobile
        : '',
      vendorPhone: formData?.vendorPhone
        ? formData?.phoneZipCode + formData?.vendorPhone
        : '',
      vendorFax: formData?.vendorFax
        ? formData?.faxZipCode + formData?.vendorFax
        : '',
      vendorTypeCode: formData?.formStore?.vendorTypeCode?.code || null,
      vendorCountry: formData?.formStore?.country?.code || null,
      bank: formData?.formStore?.paymentBank?.code || null,
      officeManager: formData?.formStore?.officeManager?.code || null,
      salesManager: formData?.formStore?.salesManager?.code || null,
      dealStartDate: formData?.dealStartDate
        ? moment(formData?.dealStartDate).format('YYYY-MM-DD')
        : null,
      dealEndDate: formData?.dealEndDate
        ? moment(formData?.dealEndDate).format('YYYY-MM-DD')
        : null,
      vendorManagers: (storeVendorManager?._array || [])
        ?.sort((a, b) => a?.ID - b?.ID)
        ?.map(o => ({
          vendorManagerId: o?.vendor_manager_id || 0,
          vendorManagerNm: o?.vendor_manager_nm,
          mainContactTf: o?.main_contact_tf || false,
          email: o?.email,
          division: o?.division,
          position: o?.position,
          mobile: o?.mobile,
        })),
    };
    delete dataReq.mobileZipCode;
    delete dataReq.phoneZipCode;
    delete dataReq.faxZipCode;
    delete dataReq.formStore;
    const res = await refetchUpdate({
      data: dataReq,
    });
    notification({
      res,
    });
    if (res?.data?.status === '201') {
      const type = !formData?.vendorId ? 'create' : 'update';
      onUpdateData({ data: res?.data?.data, type });
    }
  };
  /**
   * on valid submit
   *
   * @return {*}
   */
  const handleSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    submitData({ type: 'list' });
  };
  /**
   * update store
   *
   * @param {*} vendorTypeCode
   * @param {*} data
   */
  const updateStore = (vendorTypeCode, data) => {
    const newStore = { ...formData?.formStore, [vendorTypeCode]: data };
    setFormData(preState => ({
      ...preState,
      [vendorTypeCode]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  /**
   * update manager
   *
   * @param {*} vendorTypeCode
   * @param {*} data
   */
  const updateManager = (vendorTypeCode, data) => {
    const newStore = {
      ...formData?.formStore,
      [vendorTypeCode]: {
        ...data,
        code: data?.userId,
      },
    };
    setFormData(preState => ({
      ...preState,
      [vendorTypeCode]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  /**
   * remove store
   *
   * @param {*} vendorTypeCode
   */
  const removeStore = vendorTypeCode => {
    setFormData(preState => ({
      ...preState,
      formStore: { ...preState?.formStore, [vendorTypeCode]: null },
    }));
  };
  /**
   * on change post code
   *
   * @param {*} popupType
   * @param {*} data
   */
  const onChangePostCode = (popupType, data) => {
    setFormData({
      ...formData,
      [popupType]: data?.zipNo,
      [popupType === 'vendorPostCode'
        ? 'vendorAddressBasic'
        : 'deliveryAddressBasic']: data?.roadAddrPart1,
      // [popupType === 'vendorPostCode'
      //   ? 'vendorAddressDetail'
      //   : 'deliveryAddressDetail']: data?.roadAddrPart2,
    });
    setShowPopupByType('');
  };
  /**
   * get poup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendorTypeCode':
      case 'paymentBank':
      case 'country':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="single"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data)}
          />
        );
      case 'officeManager':
      case 'salesManager':
        return (
          <PopupSelectUserCenter
            title={listTypeCommon?.[popupType]?.title}
            mode="single"
            visible={!!listTypeCommon?.[popupType]?.codeType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateManager(popupType, data)}
          />
        );
      case 'vendorPostCode':
      case 'deliveryPostCode':
        return (
          <PopupSelectPostCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            onHiding={() => {
              setShowPopupByType('');
            }}
            onSubmit={data => onChangePostCode(popupType, data)}
          />
        );
      default:
        return null;
    }
  };
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'dealStartDate':
        if (formData.dealEndDate && e?.value && e?.value > formData.dealEndDate)
          formRef?.current?.instance.updateData('dealEndDate', null);
        formRef?.current?.instance?.updateDimensions();
        break;
      case 'dealEndDate':
        if (
          formData.dealStartDate &&
          e.value &&
          e?.value < formData.dealStartDate
        )
          formRef?.current?.instance.updateData('dealStartDate', null);
        break;
      default:
        break;
    }
  };
  /**
   * on confirm
   *
   */
  const handleConfirm = async () => {
    submitData({ type: 'edit' });
    setShowModalConfirm(false);
    setElementSwitch(null);
  };
  /**
   * handle close
   *
   */
  const handleClose = () => {
    formRef?.current?.instance.updateData('status', elementSwitch);
    setShowModalConfirm(false);
    setElementSwitch(null);
  };
  /**
   * on change document
   *
   * @param {*} data
   */
  const handleChangeDocumentList = data => {
    setDocumentList(data);
  };

  return (
    <div>
      <LoadPanel visible={loading || loadingUpdate} />
      <BreadCrumbPremium
        onSubmit={handleSubmit}
        onCancel={() => setModeView({ type: 'list' })}
      />
      <Form
        formData={formData}
        ref={formRef}
        showColonAfterLabel={false}
        items={[
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Vendor Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'vendorId',
                editorType: 'dxTextBox',
                label: {
                  text: t('ID'),
                },
                editorOptions: {
                  disabled: true,
                },
              },
              {
                dataField: 'vendorCode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Vendor Code'),
                },
                isRequired: true,
                editorOptions: {
                  onValueChanged: replaceWhiteSpaceValueChange,
                  buttons: [
                    {
                      name: 'refresh',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'refresh',
                        onClick: () => {
                          refreshStoreCode();
                        },
                      },
                    },
                  ],
                },
                validationRules: [
                  {
                    type: 'required',
                    message: t('Vendor Code is required'),
                  },
                  {
                    type: 'stringLength',
                    max: 30,
                    message: t('The maximum allowed characters are 30'),
                  },
                ],
              },
              {
                dataField: 'vendorName',
                editorType: 'dxTextBox',
                label: {
                  text: t('Vendor Name'),
                },
                isRequired: true,
                validationRules: [
                  {
                    type: 'required',
                    message: t('Vendor Name is required'),
                  },
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'vendorShortName',
                editorType: 'dxTextBox',
                label: {
                  text: t('Vendor Short Name'),
                },
                isRequired: true,
                validationRules: [
                  {
                    type: 'required',
                    message: t('Vendor Short Name is required'),
                  },
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'vendorTypeCode',
                editorType: 'dxTextBox',
                isRequired: !formData?.formStore?.vendorTypeCode,
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('vendorTypeCode');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.vendorTypeCode?.name,
                        onClick: () => removeStore('vendorTypeCode'),
                        visible: !!formData?.formStore?.vendorTypeCode,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('vendorTypeCode'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Vendor Type'),
                },
                cssClass: formData?.formStore?.vendorTypeCode
                  ? 'show-required-mark'
                  : '',
              },
              {
                itemType: 'group',
                colCount: 2,
                items: [
                  {
                    dataField: 'dealStartDate',
                    editorType: 'dxDateBox',
                    label: {
                      text: t('Deal Start Date'),
                    },
                    editorOptions: {
                      displayFormat: DateFormat,
                      // max: formData?.dealEndDate,
                    },
                    isRequired: true,
                  },
                  {
                    dataField: 'dealEndDate',
                    editorType: 'dxDateBox',
                    label: {
                      text: t('Deal End Date'),
                    },
                    editorOptions: {
                      displayFormat: DateFormat,
                      // min: formData?.dealStartDate,
                    },
                  },
                ],
              },
              {
                dataField: 'vendorAccountingCode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Accounting Code'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 20,
                    message: t('The maximum allowed characters are 20'),
                  },
                ],
              },
              {
                dataField: 'use',
                editorType: 'dxSwitch',
                label: {
                  text: t('Use'),
                },
              },
              {
                dataField: 'dealOldCode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Old Code'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 20,
                    message: t('The maximum allowed characters are 20'),
                  },
                ],
              },
              {
                dataField: 'country',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('country');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.country?.name,
                        onClick: () => removeStore('country'),
                        visible: !!formData?.formStore?.country,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('country'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Country'),
                },
              },
              {
                dataField: 'vendorRepresentative',
                editorType: 'dxTextBox',
                label: {
                  text: t('Representative'),
                },
              },
              {
                dataField: 'vendorEmail',
                editorType: 'dxTextBox',
                label: {
                  text: t('Email'),
                },
                validationRules: [
                  {
                    type: 'email',
                    message: t('Email is valid'),
                  },
                ],
              },
              {
                itemType: 'group',
                colSpan: 1,
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'mobileZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      displayExpr: 'label',
                      valueExpr: 'value',
                      items: zipCodePhone,
                    },
                    label: {
                      text: t('Mobile No'),
                    },
                  },
                  {
                    dataField: 'vendorMobile',
                    editorType: 'dxTextBox',
                    colSpan: 2,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    label: {
                      text: t('Mobile Number'),
                      visible: false,
                    },
                  },
                ],
              },
              {
                itemType: 'group',
                colSpan: 1,
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'phoneZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      displayExpr: 'label',
                      valueExpr: 'value',
                      items: zipCodePhone,
                    },
                    label: {
                      text: t('Phone No'),
                    },
                  },
                  {
                    dataField: 'vendorPhone',
                    editorType: 'dxTextBox',
                    colSpan: 2,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    label: {
                      text: t('Phone Number'),
                      visible: false,
                    },
                  },
                ],
              },
              {
                itemType: 'group',
                colSpan: 1,
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'faxZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      displayExpr: 'label',
                      valueExpr: 'value',
                      items: zipCodePhone,
                    },
                    label: {
                      text: t('Fax No'),
                    },
                  },
                  {
                    dataField: 'vendorFax',
                    editorType: 'dxTextBox',
                    colSpan: 2,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    label: {
                      text: t('Fax Number'),
                      visible: false,
                    },
                  },
                ],
              },
              {
                itemType: 'group',
                colSpan: 1,
                colCount: 2,
                items: [
                  {
                    dataField: 'interlockCd',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Interlock Code'),
                    },
                  },
                  {
                    dataField: 'interlockCd2',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Interlock Code 2'),
                    },
                  },
                ],
              },
              {
                dataField: 'vendorPostCode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Address (Zip/Postcode)'),
                },
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('vendorPostCode');
                  },
                  buttons: [
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('vendorPostCode'),
                      },
                    },
                  ],
                },
              },
              {
                dataField: 'vendorAddressBasic',
                editorType: 'dxTextBox',
                label: {
                  text: t('Basic Address'),
                },
              },
              {
                itemType: 'empty',
              },
              {
                dataField: 'vendorAddressDetail',
                editorType: 'dxTextBox',
                label: {
                  text: t('Detail Address'),
                },
              },
              {
                dataField: 'vendorNote',
                editorType: 'dxTextArea',
                colSpan: 2,
                label: {
                  text: t('Note'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Business Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'businessNumber',
                editorType: 'dxTextBox',
                label: {
                  text: t('Business Number'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'companyNumber',
                editorType: 'dxTextBox',
                label: {
                  text: t('Company Number'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'vendorBusiness',
                editorType: 'dxTextBox',
                label: {
                  text: t('Business'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'vendorIndustry',
                editorType: 'dxTextBox',
                label: {
                  text: t('Industry'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'vendorDocumentRequests',
                editorType: 'dxTextBox',
                label: {
                  text: t('Upload Document'),
                },
                colSpan: 2,
                template: 'FileUploadPreviews',
                editorOptions: {
                  request: {
                    url: 'user/upload/vendor-document',
                    method: 'POST',
                  },
                  value: documentList,
                  onChangeValue: handleChangeDocumentList,
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Payment Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'paymentBank',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('paymentBank');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.paymentBank?.name,
                        onClick: () => removeStore('paymentBank'),
                        visible: !!formData?.formStore?.paymentBank,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('paymentBank'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Bank'),
                },
              },
              {
                dataField: 'paymentAccountNumber',
                editorType: 'dxTextBox',
                label: {
                  text: t('Account Number'),
                },
              },
              {
                dataField: 'paymentDepositor',
                editorType: 'dxTextBox',
                label: {
                  text: t('Depositor'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Manager Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'vendorManager',
                editorType: 'dxTextBox',
                label: {
                  text: t('Vendor Manager'),
                },
                colSpan: 2,
                editorOptions: {
                  disabled: true,
                },
              },
              {
                dataField: 'officeManager',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('officeManager');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.officeManager?.name,
                        onClick: () => removeStore('officeManager'),
                        visible: !!formData?.formStore?.officeManager,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('officeManager'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Office Manager'),
                },
              },
              {
                dataField: 'salesManager',
                editorType: 'dxTextBox',
                editorOptions: {
                  // disabled: true,
                  onEnterKey: () => {
                    setShowPopupByType('salesManager');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.salesManager?.name,
                        onClick: () => removeStore('salesManager'),
                        visible: !!formData?.formStore?.salesManager,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('salesManager'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Sales Manager'),
                },
              },
              {
                dataField: 'managerNote',
                editorType: 'dxTextArea',
                colSpan: 2,
                label: {
                  text: t('Manager Note'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Delivery Information'),
            cssClass: 'group-flex-start body-padding-white mb-10',
            items: [
              {
                dataField: 'vendorReceiver',
                editorType: 'dxTextBox',
                label: {
                  text: t('Receiver'),
                },
                colSpan: 2,
              },
              {
                dataField: 'deliveryPostCode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Zip/Postcode'),
                },
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('deliveryPostCode');
                  },
                  buttons: [
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('deliveryPostCode'),
                      },
                    },
                  ],
                },
              },
              {
                itemType: 'group',
                items: [
                  {
                    dataField: 'deliveryAddressBasic',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Basic Address'),
                    },
                  },
                  {
                    dataField: 'deliveryAddressDetail',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Detail Address'),
                    },
                  },
                ],
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Vendor Employee Information'),
            cssClass: 'body-padding-white mb-10 group-flex-start',
            items: [
              {
                // dataField: 'storeShippingMethod',
                colSpan: 2,
                template: 'vendor_manager_temp',
                editorOptions: {
                  value: storeVendorManager,
                  onMainContactChange: value =>
                    formRef?.current?.instance.updateData(
                      'vendorManager',
                      value,
                    ),
                },
              },
            ],
          },
        ]}
        labelLocation="top"
        colCount={2}
        onFieldDataChanged={onFieldDataChanged}
      >
        <Template name="FileUploadPreviews" component={FileUploadPreviews} />
        <Template name="vendor_manager_temp" component={VendorManager} />
      </Form>
      {getPopupByType(showPopupByType)}
      {!themePro && (
        <div
          style={{
            textAlign: 'center',
            padding: '20px',
            width: '100%',
          }}
        >
          <Button
            text={t('Save')}
            type="normal"
            icon="save"
            stylingMode="contained"
            onClick={() => handleSubmit()}
            style={{ marginRight: 10 }}
          />
          <Button
            text={t('Cancel')}
            type="normal"
            stylingMode="contained"
            icon="close"
            onClick={() => setModeView({ type: 'list' })}
          />
        </div>
      )}
      <PopupConfirm
        visible={showModalConfirm}
        content={contentPopupConfirm}
        onOk={handleConfirm}
        onHiding={() => setShowModalConfirm(false)}
        onHidingCallback={() => handleClose()}
      />
    </div>
  );
}

export default FormCreate;
