/**
 * Update date: 17-05-2023
 * Screen 2011.8
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext, useEffect } from 'react';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import './styles.scss';
import { cloneDeep } from 'lodash';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import useLocalStorage from 'hooks/useLocalStorage';

const defaultSearchParams = {
  productNames: '',
  dataList: {
    mall: [],
    largeCategory: [],
    mediumCategory: [],
    smallCategory: [],
    subCategory: [],
    productGroup: [],
    vendorIds: [],
    productMakers: [],
    productTypes: [],
  },
  mall: '',
  largeCategory: '',
  mediumCategory: '',
  smallCategory: '',
  subCategory: '',
  productGroup: '',
  vendorIds: '',
  productMakers: '',
  productTypes: '',
  tradeType: '0',
};

const Tab1 = React.lazy(() => import('./Tab1'));
const Tab2 = React.lazy(() => import('./Tab2'));
const FormSearchTab1 = React.lazy(() => import('./Tab1/FormSearchTab1'));
const FormSearchTab2 = React.lazy(() => import('./Tab2/FormSearchTab2'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2011_8_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2011_8 = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({});
  const [dataTab1, setDataTab1] = useState([]);
  const [dataTab2, setDataTab2] = useState([]);
  const [dataListLocal, setDataListLocal] = useLocalStorage(
    '2011.8_dataList',
    {},
  );

  const [formSearchTab1, setFormSearchTab1] = useState<any>(
    cloneDeep(defaultSearchParams),
  );
  const [formSearchTab2, setFormSearchTab2] = useState<any>(
    cloneDeep(defaultSearchParams),
  );
  const [tabPanelIndex, setTabPanelIndex] = useState(0);

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const storeTab1: any = new ArrayStore({
    data: dataTab1,
    key: 'product_id',
  });

  const storeTab2: any = new ArrayStore({
    data: dataTab2,
    key: ['product_id', 'option_id'],
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormSearchTab1(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productTypes: productTpPermission,
        mall: dataListLocal?.mall,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
    setFormSearchTab2(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productTypes: productTpPermission,
        mall: dataListLocal?.mall,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission, dataListLocal]);

  useEffect(() => {
    setFormSearchTab1(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        mall: dataListLocal?.mall,
      },
    }));
    setFormSearchTab2(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        mall: dataListLocal?.mall,
      },
    }));
  }, [dataListLocal]);

  const value: any = {
    t,
    themePro,
    storeTab1,
    storeTab2,
    setDataTab1,
    setDataTab2,
    refetchData,
    formSearchTab1,
    setFormSearchTab1,
    formSearchTab2,
    setFormSearchTab2,
    dataListLocal,
    setDataListLocal,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingProductTpPermission} />
        {/* {renderContent()} */}
        {tabPanelIndex === 0 ? <FormSearchTab1 /> : <FormSearchTab2 />}

        <TabPanel
          defaultSelectedIndex={0}
          selectedIndex={tabPanelIndex}
          swipeEnabled={false}
          onSelectionChanged={(e: any) => {
            if (tabPanelIndex === 0) {
              setFormSearchTab2(prev => {
                prev.dataList.mall = formSearchTab1?.dataList?.mall || [];
                prev.productNames = formSearchTab1?.productNames || '';
                return prev;
              });
            } else {
              setFormSearchTab1(prev => {
                prev.dataList.mall = formSearchTab2?.dataList?.mall || [];
                prev.productNames = formSearchTab2?.productNames || '';
                return prev;
              });
            }
            setTabPanelIndex(tabPanelIndex === 0 ? 1 : 0);
          }}
          className={
            themePro ? 'themePro-custom-tab-panel' : 'normal-custom-tab-panel'
          }
        >
          <Item title={t('Product Display')}>
            <Tab1 />
          </Item>
          <Item title={t('Product Option')}>
            <Tab2 />
          </Item>
        </TabPanel>
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2011_8;
