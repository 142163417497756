/**
 * Update date: 31-05-2023
 * Screen 2051.4
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';
import { useProductType } from 'hooks/useProductType';
import { dateTimeRequestFormat } from 'utils/format';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));

const defaultParams = {};
/**
 * create context
 */
const ProductCodeContext = createContext({});

ProductCodeContext.displayName = 'ProductCodeContext';

export const useProductCost = () => {
  const context = useContext(ProductCodeContext);
  return context;
};

const ProductCost = () => {
  const { t } = useDictionary({
    programId: '2051.4',
  });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [params, setParams] = useState<any>(defaultParams);
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ data: resData, loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: '/warehouse/productCost',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    t,
    modeView,
    setModeView,
    params,
    setParams,
    data,
    productTpPermission,
    refetchData: () =>
      refetchData({
        data: {
          ...params,
          fromMonth: dateTimeRequestFormat(params?.fromMonth),
          toMonth: dateTimeRequestFormat(params?.toMonth),
        },
      }),
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resData?.data) {
      const dataMapping = (resData?.data || []).map(o => {
        o.costStatusNm = o?.costStatusId === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setData(dataMapping);
    }
  }, [resData]);

  const loadingAll = loadingfetchData || loadingProductTpPermission;

  return (
    <ProductCodeContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ProductCodeContext.Provider>
  );
};

export default ProductCost;
