import { CLIENT_ID, GRANT_TYPE, LANG_DEFAULT } from 'constant';
import { notification } from 'utils/notification';
import { api } from './api';

export const apiLogin = async (
  username: string,
  password: string,
  language = LANG_DEFAULT,
) => {
  let data: any = null;
  let form_data = new FormData();
  form_data.append('client_id', CLIENT_ID);
  form_data.append('username', username);
  form_data.append('password', password);
  form_data.append('grant_type', GRANT_TYPE.password);
  form_data.append('language', language);
  await api({
    url: 'iam/oauth/token',
    method: 'POST',
    data: form_data,
  })
    .then(res => {
      data = res.data;
    })
    .catch(e => {
      const { data } = e?.response;
      notification({
        message: data?.error_description || data?.error,
        type: 'error',
      });
    });
  return {
    data,
  };
};

export const apiUserRegistration = async (url: string, data: any) => {
  return await api({
    url: `user/${url}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error.response;
    });
};
export const apiContactAdminstration = async (data: any) => {
  return await api({
    url: `user/confirm-admin`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error.response;
    });
};
