/**
 * Update date: 18-05-2023
 * Screen 2013.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import useDictionary from 'hooks/useDictionary';
import { useRef } from 'react';
import { useForwardPrice } from '..';

const TableList = () => {
  const { t }: any = useDictionary({ programId: '2013.3' });
  const { AmountFormat, DoubleFormat, QtyFormat } = useFormat();
  const dataGridRef = useRef<any>(null);
  const context: any = useForwardPrice();
  const { dataSource } = context;

  const columns: any = [
    {
      title: t('ID'),
      dataField: 'id',
      alignment: 'center',
    },
    {
      dataField: 'product_id',
      caption: t('Product ID'),
      alignment: 'center',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'product_tp',
      caption: t('Product Type Id'),
      alignment: 'center',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'producttpnm',
      caption: t('Product Type'),
      showWhenGrouped: true,
    },
    {
      dataField: 'product_cd',
      caption: t('Product Code'),
      showWhenGrouped: true,
    },
    {
      dataField: 'product_nm',
      caption: t('Product'),
      showWhenGrouped: true,
      alignment: 'left',
    },
    {
      dataField: 'marketable_size',
      caption: t('Marketable Size'),
      showWhenGrouped: true,
    },
    {
      dataField: 'option_cd',
      caption: t('Option Code'),
      alignment: 'center',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'option_nm',
      caption: t('Option'),
      showWhenGrouped: true,
    },
    {
      dataField: 'vendor_nm_default',
      caption: t('Default Vendor'),
      alignment: 'left',
    },
    {
      dataField: 'quantity_per_pack',
      caption: t('Quantity Per Pack'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'purchase_unit_price',
      caption: t('Purchase Unit Price'),
      dataType: 'number',
      format: DoubleFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'supply_unit_price',
      caption: t('Supply Unit Price'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'maker',
      caption: t('Maker'),
      visible: false,
    },
    {
      dataField: 'origin',
      caption: t('Origin'),
      visible: false,
    },
    {
      dataField: 'largecategory',
      caption: t('Large Category'),
      visible: false,
    },
    {
      dataField: 'mediumcategory',
      caption: t('Medium Category'),
      visible: false,
    },
    {
      dataField: 'smallcategory',
      caption: t('Small Category'),
      visible: false,
    },
    {
      dataField: 'subcategory',
      caption: t('Sub Category'),
      visible: false,
    },
  ];

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={dataSource}
        storageKeyInquiry
        options={{
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: '',
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName:
              'SMDC_2013.3 Product Standart Forward Price Inquiry ' +
              moment().format('YYYYMMDD'),
          },
        }}
      />
    </>
  );
};

export default TableList;
