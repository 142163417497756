import useAxios from 'axios-hooks';

export const useProductType = () => {
  const [{ data: resProductTp, loading: loadingProductTp }] = useAxios(
    {
      url: '/user/user/permission/product-type',
      method: 'GET',
    },
    { manual: false, useCache: false },
  );
  return {
    productTpPermission: resProductTp?.data || [],
    loadingProductTpPermission: loadingProductTp,
  };
};
