/*
 * 2082.5 Trade Payable Balance Inquiry
 * @since 2022/11/24
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import Form from 'devextreme-react/form';
import useFormatTime from 'hooks/useFormatTime';
import { useRef } from 'react';
import { useTradePayableBalanceInquiryContext } from '..';

/**
 * From search view component
 *
 * @return {*}
 */
const FormSearch = () => {
  // Get data from context
  const { t, formSearchView, onSearchView, AmountFormat }: any =
    useTradePayableBalanceInquiryContext();

  const formRef: any = useRef(null);
  const { DateFormat } = useFormatTime();

  /**
   * On search
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    onSearchView();
  };

  /**
   *  Form field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (
          formSearchView.endDate &&
          e?.value &&
          e?.value > formSearchView.endDate
        ) {
          formRef?.current?.instance.updateData('endDate', null);
        }
        break;
      case 'endDate':
        if (
          formSearchView.fromDate &&
          e?.value &&
          e?.value < formSearchView.fromDate
        )
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  /**
   *  Onchange date when choose date option
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };

  /**
   *  Render search tab content
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
          defaultDate={formSearchView?.endDate}
        />
        <Form
          ref={formRef}
          formData={formSearchView}
          showColonAfterLabel={false}
          style={{ width: '100%' }}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: true,
            },
            {
              label: { text: t('End Date') },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
                disabled: true,
              },
              isRequired: true,
            },
            {
              label: {
                text: t('Vendor'),
              },
              dataField: 'vendorName',
              editorOptions: {
                disabled: true,
              },
            },
            {
              label: {
                text: t('Debit Amount'),
              },
              dataField: 'debitAmount',
              editorType: 'dxNumberBox',
              editorOptions: {
                disabled: true,
                format: AmountFormat,
              },
            },
          ]}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit} showCollapse>
        <Search name={t('Search')} />
      </Collapse>
    </div>
  );
};

export default FormSearch;
