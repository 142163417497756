/**
 * Update date: 07-06-2023
 * Screen 2081.5
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { useRef } from 'react';
import { notification } from 'utils/notification';
import { useDepositCheckByOrder } from '..';

function List() {
  const rootContext: any = useDepositCheckByOrder();
  const { t, dataStore, refetchSaving } = rootContext || {};
  const dataGridRef: any = useRef(null);

  const { DateFormat } = useFormatTime();
  const { AmountFormat } = useFormat();

  /**
   * on deposit
   *
   * @param {*} order_id
   * @param {*} invoice_no
   */
  const onDeposit = (order_id, invoice_no) => {
    dataStore?.byKey({ order_id, invoice_no }).done(async o => {
      const res: any = await refetchSaving({
        data: {
          order_id,
          invoice_no,
          deposit_amount: o?.deposit_amount || 0,
        },
      });
      notification({ res });
    });
  };

  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    const { order_id, invoice_no } = record?.data;
    return (
      <div
        onClick={() => {
          const dataGrid: any = dataGridRef?.current?.instance;
          if (dataGrid?.hasEditData?.()) {
            dataGrid?.saveEditData?.()?.then(() => {
              onDeposit(order_id, invoice_no);
            });
          } else {
            onDeposit(order_id, invoice_no);
          }
        }}
        className="cell-link"
      >
        {t('Deposit')}
      </div>
    );
  };

  const columns: any = [
    {
      dataField: 'invoice_no',
      caption: t('Invoice No'),
      allowEditing: false,
      fixedPosition: 'left',
      fixed: true,
    },
    {
      dataField: 'order_date',
      caption: t('Order Date'),
      dataType: 'date',
      format: DateFormat,
      allowEditing: false,
    },
    {
      dataField: 'delivery_request_dm',
      caption: t('Wished Delivery Date'),
      dataType: 'date',
      format: DateFormat,
      allowEditing: false,
    },
    {
      dataField: 'order_number',
      caption: t('Order Number'),
      allowEditing: false,
    },
    {
      dataField: 'store_nm',
      caption: t('Store'),
      allowEditing: false,
    },
    {
      dataField: 'order_status',
      caption: t('Order Status'),
      allowEditing: false,
    },
    {
      dataField: 'tracking_tp_nm',
      caption: t('Tracking Status'),
      allowEditing: false,
    },
    {
      dataField: 'delivery_user_nm',
      caption: t('Delivery User'),
      allowEditing: false,
    },
    {
      dataField: 'buyer_name',
      caption: t('Buyer'),
      allowEditing: false,
    },
    {
      dataField: 'receiver_name',
      caption: t('Receiver'),
      allowEditing: false,
    },
    {
      dataField: 'receiver_mobile',
      caption: t('Receiver Mobile No'),
      allowEditing: false,
    },
    {
      dataField: 'shipping_method_nm',
      caption: t('Shipping Method'),
      allowEditing: false,
    },
    {
      dataField: 'payment_method_nm',
      caption: t('Payment Method'),
      allowEditing: false,
    },
    {
      dataField: 'payment_status',
      caption: t('Payment Status'),
      allowEditing: false,
    },
    {
      dataField: 'order_amount',
      caption: t('Order Amount'),
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'order_shipping_fee',
      caption: t('Shipping Fee'),
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'discount_amount',
      caption: t('Discount Amount'),
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'forward_amount',
      caption: t('Forward Amount'),
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'total_amount',
      caption: t('Total Amount'),
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'deposit_amount',
      dataType: 'number',
      caption: t('Deposit Amount'),
      allowEditing: true,
      cssClass: 'gridcell-editing',
      format: AmountFormat,
    },
    {
      caption: t('Action'),
      cellRender: renderAction,
      fixedPosition: 'right',
      fixed: true,
    },
    {
      dataField: 'buyer_phone',
      caption: t('Buyer Phone No'),
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'buyer_mobile',
      caption: t('Buyer Mobile No'),
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'buyer_email',
      caption: t('Buyer Email'),
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'receiver_phone',
      caption: t('Receiver Phone No'),
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'shipping_message',
      caption: t('Shipping Message'),
      allowEditing: false,
      visible: false,
    },
  ];

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={dataStore}
        options={{
          groupPanel: {
            visible: true,
          },
          editing: {
            mode: 'cell',
            allowUpdating: true,
            selectTextOnEditStart: true,
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift({
              location: 'before',
              template: 'totalCount',
            });
          },
        }}
      />
    </>
  );
}

export default List;
