/**
 * Update date: 19-05-2023
 * Screen 2014.1
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import InventoryTable from './TableView';
import useDictionary from 'hooks/useDictionary';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';

const HUBBarcodeListContext = createContext({
  dataList: {},
  refetchData: () => {},
});
/**
 * create context
 */
HUBBarcodeListContext.displayName = 'HUBBarcodeListContext';

export const useInventoryContext = () => {
  return useContext(HUBBarcodeListContext);
};

function InquiryHUBStandardBarcode() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [dataList, setDataList] = useState<any>([]);

  const { t } = useDictionary({
    programId: '2014.1',
  });

  const [{ data: fetchedData, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/ecommerce/hub-product-option',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  const store: any = new ArrayStore({
    data: dataList,
    key: 'hubProductOptionCode',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingData && fetchedData) {
      const dataMaping = fetchedData?.data.map((o, index) => {
        const dataImages = [
          o?.hubImageUrl1,
          o?.hubImageUrl2,
          o?.hubImageUrl3,
          o?.hubImageUrl4,
          o?.hubImageUrl5,
        ].filter(b => b);
        o.no = index + 1;
        o.dataImages = dataImages;
        return o;
      });
      setDataList(dataMaping);
    }
  }, [fetchedData, loadingData]);

  const value: any = {
    productTpPermission,
    store,
    t,
    dataList,
    refetchData,
  };

  return (
    <>
      <HUBBarcodeListContext.Provider value={value}>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel visible={loadingData || loadingProductTpPermission} />
          <FormSearch />
          <InventoryTable />
        </React.Suspense>
      </HUBBarcodeListContext.Provider>
    </>
  );
}

export default InquiryHUBStandardBarcode;
