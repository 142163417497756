/**
 * Update date: 15-05-2023
 * Screen 1043.3
 */
import FileUpload from 'app/components/Templates/FileUpload';
import { Button, LoadPanel, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

function PopupFileUpload(props) {
  const { visible, onDownLoad, onOk, onHiding } = props;
  const [file, setFile] = useState<any>(null);
  const { t }: any = useDictionary({
    programId: '1043.3',
  });
  /**
   * render content
   *
   * @return {*} 
   */
  const content = () => {
    return (
      <div>
        <FileUpload
          allowedFileExtensions={['.xlsx, .xls']}
          accept={'.xlsx, .xls'}
          mode="single"
          onSelectedFile={file => setFile(file)}
        />
        <br />
        <div>
          {t('Please click')}
          <span
            className="cell-link"
            onClick={onDownLoad}
            style={{ padding: '0 5px' }}
          >
            {t('here')}
          </span>
          {t('to download XLSX upload template')}
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <Button
            icon="check"
            type="default"
            text={t('OK')}
            onClick={() => onOk(file)}
          />
          <Button icon="close" text={t('Cancel')} onClick={() => onHiding()} />
        </div>
      </div>
    );
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      title={t('Import File Excel')}
      contentRender={content}
      height="auto"
      width={isMobile ? '96%' : '40vw'}
      maxHeight="100vh"
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupFileUpload;
