/**
 * Update date: 2024-04-16
 * Screen 2011.C
 */
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import { useProductType } from 'hooks/useProductType';
import React, { createContext, useContext, useState } from 'react';
import FormSearch from './FormSearch';
import ProductMasterTable from './TableView';

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2011_C_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

function M2011_C() {
  const { t }: any = useDictionary({ programId: '2011.C' });
  const [productData, setProductData] = useState([]);
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  const value: any = {
    t,
    productTpPermission,
    productData,
    setProductData,
  };

  return (
    <>
      <ModuleContext.Provider value={value}>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel visible={loadingProductTpPermission} />
          <FormSearch />
          <ProductMasterTable />
        </React.Suspense>
      </ModuleContext.Provider>
    </>
  );
}

export default M2011_C;
