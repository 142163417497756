/**
 * Update date: 2024-04-16
 * Screen 2011.C
 */
import React, { useState, useRef, useEffect } from 'react';
import { cloneDeep, uniqBy } from 'lodash';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import { useModuleContext } from '..';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';
import { TRADE_TYPE } from 'constant';
interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
  },
  mediumCategory: {
    codeType: 'MC',
  },
  smallCategory: {
    codeType: 'SC',
  },
  subCategory: {
    codeType: 'UC',
  },
  productTypes: {
    codeType: 'PT',
  },
  productMakers: {
    codeType: 'MK',
  },
};

const FormSearch = () => {
  const context: any = useModuleContext();
  const { t, productTpPermission, setProductData } = context;
  const formRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({
    keyword: '',
    optionUse: 1,
    tradeType: '',
    dataList: {
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      productMakers: [],
      productTypes: [],
      productGroup: [],
      vendor: [],
    },
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    productMakers: '',
    productTypes: '',
    productGroup: '',
    vendor: '',
  });
  const [showPopupByType, setShowPopupByType] = useState('');

  const [{ loading }, refetchData] = useAxios(
    {
      url: '/product/product-master-inquiry-extension',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productTypes: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  /**
   * on sumbit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    //API 's parameter go changed here
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const dataRequest = {
      largeCategories: formData?.dataList?.largeCategory?.map(
        (map: any) => map?.code,
      ),
      mediumCategories: formData?.dataList?.mediumCategory?.map(
        (map: any) => map?.code,
      ),
      smallCategories: formData?.dataList?.smallCategory?.map(
        (map: any) => map?.code,
      ),
      subCategories: formData?.dataList?.subCategory?.map(
        (map: any) => map?.code,
      ),
      makerCds: formData?.dataList?.productMakers?.map((map: any) => map?.code),
      productTypes: formData?.dataList?.productTypes?.map(
        (map: any) => map?.code,
      ),
      productGroupIds: formData?.dataList?.productGroup?.map(
        (map: any) => map?.productGroupId,
      ),
      vendorIds: formData?.dataList?.vendor?.map((map: any) => map?.vendorId),
      keyword: formData?.keyword,
      optionUse: formData?.optionUse,
      tradeType: formData?.tradeType,
    };
    const res = await refetchData({ data: dataRequest });
    if (res?.data?.status == '200') {
      const dataMapping = (res?.data?.data || []).map(o => {
        const type = TRADE_TYPE?.find(f => f.value == o?.trade)?.text;
        o.tradeTypeName = t(type);
        o.stockAllocationTypeName =
          o?.stock_allocation_tp === '0'
            ? t('Stock high')
            : o?.stock_allocation_tp === '1'
            ? t('Stock low')
            : '';
        o.taxTypeName =
          o?.tax_tp === '0'
            ? t('Taxation')
            : o?.tax_tp === '1'
            ? t('Tax free')
            : o?.tax_tp === '2'
            ? t('Tax zero')
            : '';
        o.package_tp_nm =
          o?.package_tp === '0'
            ? t('Single product package')
            : o?.package_tp === '1'
            ? t('Combined product package')
            : '';
        return o;
      });
      setProductData(dataMapping);
    } else {
      notification({ res });
    }
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    const newData = {
      ...formData,
      dataList: dataList,
    };
    setFormData(newData);
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={showPopupByType === popupType}
            text={formData?.[popupType]}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data =>
              updateStore('productGroup', data, 'productGroupId')
            }
          />
        );
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
      case 'productTypes':
      case 'productMakers':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          items={[
            generateDxTextBox('Product Type', 'productTypes', 'name', {
              readOnly: formData?.isReadOnlyProductTp,
            }),
            generateDxTextBox('Maker', 'productMakers', 'name'),
            {
              cssClass: 'default-input-language',
              label: { text: t('Product Keyword') },
              dataField: 'keyword',
              editorType: 'dxTextBox',
            },
            generateDxTextBox('Vendor', 'vendor', 'vendorName'),
            {
              label: {
                text: t('Option use'),
              },
              dataField: 'optionUse',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: 1,
                items: [
                  { label: t('All'), value: 0 },
                  { label: t('Use'), value: 1 },
                  { label: t('Not use'), value: 2 },
                ],
              },
            },
            {
              label: { text: t('Trade Type') },
              dataField: 'tradeType',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'name',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  { name: t('All'), value: '' },
                  { name: t('Traded'), value: '0' },
                  { name: t('Non Traded'), value: '1' },
                ],
              },
            },
          ]}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          ref={formRef}
          items={[
            generateDxTextBox('Large Category', 'largeCategory', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            generateDxTextBox('Small Category', 'smallCategory', 'name'),
            generateDxTextBox('Sub Category', 'subCategory', 'name'),
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      <LoadPanel visible={loading} />
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
