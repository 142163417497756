/**
 * Update date: 19-05-2023
 * Screen 2013.6
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState, useRef } from 'react';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
  /**
   * create context
   */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2013_6_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2013_6 = () => {
  const { t }: any = useDictionary({ programId: '2013_6' });
  const dataGridRef: any = useRef(null);
  const [data, setData] = useState<any>([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    productName: '',
    tradeTp: '0',
    marginYn: '0',
    dataList: {
      productType: [],
      maker: [],
      vendor: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      productGroup: [],
      productMarginGroup: [],
    },
    productType: '',
    maker: '',
    vendor: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    productGroup: '',
    productMarginGroup: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    zone: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'product_id',
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      productName: formSearchData.productName,
      tradeTp: formSearchData.tradeTp,
      marginYn: formSearchData?.marginYn || '',
      productTypes: formSearchData?.dataList.productType.map(
        (o: any) => o?.code,
      ),
      makers: formSearchData?.dataList.maker.map((o: any) => o?.code),
      vendorIds: formSearchData?.dataList.vendor.map((o: any) => o?.vendorId),
      largeCategoryCodes: formSearchData?.dataList?.largeCategory?.map(
        (o: any) => o?.code,
      ),
      mediumCategoryCodes: formSearchData?.dataList?.mediumCategory?.map(
        (o: any) => o?.code,
      ),
      smallCategoryCodes: formSearchData?.dataList?.smallCategory?.map(
        (o: any) => o?.code,
      ),
      subCategoryCodes: formSearchData?.dataList?.subCategory?.map(
        (o: any) => o?.code,
      ),
      productGroupIds: formSearchData?.dataList?.productGroup?.map(
        (o: any) => o?.productGroupId,
      ),
    };

    const res = await refetchData({
      url: '/product/product-margin-group',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      setData(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  /**
   * on save
   *
   */
  const onSave = async () => {
    const dataEdit = store?._array
      ?.filter(o => o?.status === 'edit' && o?.margin_group)
      .map(o => ({ productId: o?.product_id, marginGroup: o?.margin_group }));

    const dataDelete = store?._array
      .filter(
        o =>
          o?.status === 'delete' || (o?.status === 'edit' && !o?.margin_group),
      )
      .map(o => o?.product_id);

    const res = await refetchData({
      url: 'product/product-margin-group',
      method: 'PUT',
      data: { saveData: dataEdit, deleteData: dataDelete },
    });
    notification({ res });
    if (res?.data?.status === '201') {
      setData(prev => {
        return prev.map(o => {
          o.status = null;
          if (dataDelete.some(pid => pid == o?.product_id)) {
            o.margin_group = '';
            o.margin_group_nm = '';
          }
          return o;
        });
      });
      dataGridRef?.current?.instance?.deselectAll();
    }
  };

  /**
   * on apply
   *
   * @return {*}
   */
  const onApply = () => {
    const selectedKey = dataGridRef?.current?.instance?.getSelectedRowKeys();
    if (!selectedKey?.length) {
      return notification({
        message: t('You must be select at least one record. Please try again!'),
        type: 'error',
      });
    }
    const marginGroup = formSearchData?.dataList?.productMarginGroup?.[0] || {};
    selectedKey.forEach((key, indx) => {
      store
        .update(key, {
          margin_group: marginGroup?.code || '',
          margin_group_nm: marginGroup?.name || '',
          status: 'edit',
        })
        .done(() => {
          if (indx === selectedKey.length - 1) {
            dataGridRef?.current?.instance?.refresh();
            dataGridRef?.current?.instance?.deselectAll();
          }
        })
        .fail(() => {});
    });
  };

  const value: any = {
    t,
    store,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    onSave,
    onApply,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List ref={dataGridRef} />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2013_6;
