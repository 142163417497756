/**
 * Update date: 05-06-2023
 * Screen 2053.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import useDictionary from 'hooks/useDictionary';
import { useRef } from 'react';
import { useWeekIndex } from '..';

function TableList(props) {
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();
  const dataGridRef = useRef<any>(null);
  const context: any = useWeekIndex();
  const { dataSource } = context;

  const { t }: any = useDictionary({
    programId: '2053.3',
  });

  const columns: any = [
    {
      dataField: 'zone_id',
      caption: t('Zone'),
      showWhenGrouped: true,
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'zone_name',
      caption: t('Zone'),
      showWhenGrouped: true,
      alignment: 'left',
    },
    {
      dataField: 'product_cd',
      caption: t('Product Code'),
      showWhenGrouped: true,
    },
    {
      dataField: 'product_nm',
      caption: t('Product Name'),
      showWhenGrouped: true,
      alignment: 'left',
    },
    {
      dataField: 'marketable_size',
      caption: t('Marketable Size'),
      showWhenGrouped: true,
    },
    {
      dataField: 'vendor_nm_default',
      caption: t('Default Vendor'),
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      dataField: 'purchase_unit_price',
      caption: t('Purchase Unit Price'),
      dataType: 'number',
      format: DoubleFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'last7_receive',
      caption: t('Previous Week Receive Ea'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'last7_forward',
      caption: t('Previous Week Forward Ea'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'maker',
      caption: t('Maker'),
      showWhenGrouped: true,
    },
    {
      dataField: 'origin',
      caption: t('Origin'),
      showWhenGrouped: true,
    },
    {
      dataField: 'stock_ea',
      caption: t('Stock Ea'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'stock_cost_amt',
      caption: t('Stock Cost Amount'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'week_index',
      dataType: 'number',
      format: AmountFormat,
      caption: t('Stock Week Index'),
      showWhenGrouped: true,
    },
    {
      dataField: 'largecategory',
      caption: t('Large Category'),
      visible: false,
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'mediumcategory',
      caption: t('Medium Category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'smallcategory',
      caption: t('Small Category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'subcategory',
      caption: t('Sub Category'),
      visible: false,
      showWhenGrouped: true,
    },
  ];

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const exportFileName =
    'SMDC_2053.3 Product week index inquiry_' + moment().format('YYYYMMDD');

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={dataSource}
        options={{
          columnAutoWidth: true,
          groupPanel: {
            visible: true,
          },
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: '',
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: '',
                column: 'last7_receive',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: '',
                column: 'last7_forward',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: '',
                column: 'stock_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: '',
                column: 'stock_cost_amt',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'last7_receive',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                dataType: 'number',
              },
              {
                column: 'last7_forward',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                dataType: 'number',
              },
              {
                column: 'stock_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                dataType: 'number',
              },
              {
                column: 'stock_cost_amt',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default TableList;
