/**
 * Update date: 31-05-2023
 * Screen 2046.A
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';

const TableContent = React.lazy(() => import('./TableContent'));
const FormSearch = React.lazy(() => import('./FormSearch'));
/**
 * create context
 */
const LostAndOtherAccountsForwardingInquiryContext = createContext({});

LostAndOtherAccountsForwardingInquiryContext.displayName =
  'LostAndOtherAccountsForwardingInquiryContext';

export const useLostAndOtherAccountsForwardingInquiry = () => {
  const context = useContext(LostAndOtherAccountsForwardingInquiryContext);
  return context;
};

const LostAndOtherAccountsForwardingInquiry = () => {
  const [data, setData] = useState([]);

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: 'warehouse/otherForwardMst/detail',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    data,
    setData,
    refetchData,
  };

  return (
    <LostAndOtherAccountsForwardingInquiryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        <FormSearch />
        <TableContent />
      </React.Suspense>
    </LostAndOtherAccountsForwardingInquiryContext.Provider>
  );
};

export default LostAndOtherAccountsForwardingInquiry;
