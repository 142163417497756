/**
 * Update date: 24-05-2023
 * Screen 2041.2
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState, useEffect } from 'react';
import useProfileInfo from 'hooks/useProfileInfo';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ReceivingScheduledProductContext = createContext({});

ReceivingScheduledProductContext.displayName =
  'ReceivingScheduledProductContext';

export const useReceivingScheduled = () => {
  return useContext(ReceivingScheduledProductContext);
};

const ReceivingScheduledProductStackingLocationInquiry = () => {
  const { vendorName, vendorId } = useProfileInfo();
  const [data, setData] = useState<any>([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      receiveDueState: [],
      zone: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      product: [],
      productGroup: [],
      vendor: [],
    },
    receiveDueState: '',
    zone: '',
    fromDate: new Date(),
    toDate: new Date(),
    sendDueFromDate: null,
    sendDueToDate: null,
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    vendor: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!vendorId) return;
    setFormSearchData({
      ...formSearchData,
      disableVendor: true,
      dataList: {
        ...formSearchData?.dataList,
        vendor: [{ vendorId: vendorId, vendorName: vendorName }],
      },
    });
  }, [vendorId]);

  const value: any = {
    data,
    setData,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  return (
    <ReceivingScheduledProductContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        <List />
      </React.Suspense>
    </ReceivingScheduledProductContext.Provider>
  );
};

export default ReceivingScheduledProductStackingLocationInquiry;
