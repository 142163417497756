/**
 * Update date: 08-06-2023
 * My page component
 */
import { Button } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import { useState } from 'react';
import AccountDetails from '../MyPagePopup/AccountDetail';
import PasswordSecurity from '../MyPagePopup/PasswordSecurity';
import Preference from '../MyPagePopup/Preference';
import LocalStorage from '../MyPagePopup/LocalStorage';

const MyPagePremium = () => {
  const [tabPanelIndex, setTabPanelIndex] = useState<number>(0);
  const { t } = useDictionary({});
  const onOpenNewTab = () => {
    const currentPath = window.location.origin;
    window.open(currentPath, '_blank');
  };
  return (
    <div className="my-page">
      <div className="title-page">{t('My Page')}</div>
      <div className="body-padding-white p-0">
        <TabPanel
          swipeEnabled={false}
          defaultSelectedIndex={tabPanelIndex}
          selectedIndex={tabPanelIndex}
          onOptionChanged={(e: any) => {
            if (e?.name === 'selectedIndex') {
              setTabPanelIndex(e?.value);
            }
          }}
        >
          <TabItem title={t('Account Detail')}>
            <div style={{ padding: '20px' }}>
              {tabPanelIndex === 0 && <AccountDetails />}
            </div>
          </TabItem>
          <TabItem title={t('Preference')}>
            <div style={{ padding: '20px' }}>
              {tabPanelIndex === 1 && <Preference />}
            </div>
          </TabItem>
          <TabItem title={t('Password & Security')}>
            <div style={{ padding: '20px' }}>
              {tabPanelIndex === 2 && <PasswordSecurity />}
            </div>
          </TabItem>
          <TabItem title={t('Duplicate Page')}>
            <div style={{ padding: '20px' }}>
              {tabPanelIndex === 3 && (
                <>
                  <div className="my-page-caption">
                    <div>
                      <div className="my-page-caption-title">
                        {t('Duplicate Page')}
                      </div>
                      <div className="my-page-caption-des">
                        {t('Execute additional programs.')}
                      </div>
                    </div>
                  </div>
                  <Button
                    text={t('Open New Tab')}
                    stylingMode="contained"
                    type="default"
                    onClick={onOpenNewTab}
                  />
                </>
              )}
            </div>
          </TabItem>
          <TabItem title={t('List Setting Grid')}>
            <div style={{ padding: '20px' }}>
              {tabPanelIndex === 4 && <LocalStorage />}
            </div>
          </TabItem>
        </TabPanel>
      </div>
    </div>
  );
};

export default MyPagePremium;
