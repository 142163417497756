/*
 * 2082.3 Vendor withdrawal registration
 * @since 2022/10/20
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));

// Initialize context
const M2082_8 = createContext({});
M2082_8.displayName = 'M2082_8';
export const useVendorWithDrawalRegis = () => {
  const context = useContext(M2082_8);
  return context;
};

/**
 * Vendor Deposit Registration(Empty bottle)
 *
 * @export
 * @return {*}
 */
export default function VendorWithDrawalRegis() {
  // Initialize state
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
  });
  const [listData, setListData] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      vendor: [],
      vendorType: [],
    },
    vendor: '',
    vendorType: '',
  });
  // get multi language
  const { t }: any = useDictionary({
    programId: '2082.8',
  });

  // Api get data
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'user/vendor-deposit',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  // Api create or update data
  const [{ loading: loadingCreate }, refetchCreate] = useAxios(
    {
      url: 'user/vendor-deposit',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );

  // Api delete data
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: 'user/vendor-deposit',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );

  // Api get detail
  const [{ data: dataDetail, loading: loadingDetail }, refetchDetail] =
    useAxios(
      {
        url: 'user/vendor-deposit/detail',
        method: 'GET',
      },
      { manual: true, autoCancel: true },
    );

  const store: any = new ArrayStore({
    data: listData || [],
    key: ['line_id', 'deposit_dt'],
  });

  /**
   * On search
   *
   */
  const onSearch = () => {
    const searchParams = {
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      vendorIds: formData?.dataList.vendor.map((o: any) => o?.vendorId),
      vendorTypes: formData?.dataList.vendorType.map((o: any) => o?.code),
    };
    refetchData({
      data: {
        ...searchParams,
      },
    });
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };

  // Set data list to state
  useEffect(() => {
    if (dataList?.data && !loadingList) {
      setListData(dataList?.data);
    }
  }, [dataList, loadingList]);

  // Set data detail to state
  useEffect(() => {
    if (dataDetail?.data && !loadingDetail) {
      setDetailData(dataDetail?.data);
    }
  }, [dataDetail, loadingDetail]);

  // Values share with child component
  const value: any = {
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    refetchCreate,
    refetchDelete,
    refetchDetail,
    formData,
    onSearch,
    setFormData,
    detailData,
    setDetailData,
    setListData,
  };

  const loadingAll =
    loadingList || loadingCreate || loadingDelete || loadingDetail;

  return (
    <M2082_8.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </M2082_8.Provider>
  );
}
