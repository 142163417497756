/**
 * Update date: 17-10-2023
 * Screen 2011.A
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const CreateEditView = React.lazy(() => import('./CreateNew'));
const SettingMileage = React.lazy(() => import('./SettingMileage'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'RegisterMileageSettingsByProduct';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const RegisterMileageSettingsByProduct = () => {
  const { t }: any = useDictionary({ programId: '2011.A' });
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    mileageNm: '',
    useTf: null,
  });

  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const mileage: any = new ArrayStore({
    data: data,
    key: 'mileageId',
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      mileageNm: formSearchData?.mileageNm,
      useTf: formSearchData?.useTf,
    };
    const res = await refetchData({
      url: '/product/mileage',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const mappingData = (res?.data?.data || []).map(o => {
        return {
          ...o.mileage,
          productCount: o['productCount'],
        };
      });
      setData(mappingData);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    mileage,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <CreateEditView />;
      case 'mileage':
        return <SettingMileage />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default RegisterMileageSettingsByProduct;
