/**
 * Update date: 31-05-2023
 * Screen 2052.5
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import InventoryInquiryLocationTable from './TableView';
/**
 * create context
 */
const Inventory = createContext({
  refetchData: () => {},
  loadingData: false,
  inventoryData: [],
});

Inventory.displayName = 'Inventory';

export const useInventory = () => {
  const context = useContext(Inventory);
  return context;
};

function InventoryInquiryLocation() {
  const [inventoryData, setInventoryData] = useState([]);
  const [{ data, loading: loadingInventoryData }, refetchData] = useAxios(
    {
      url: '/product/InventoryInquiryLocation',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const value: any = {
    refetchData,
    inventoryData,
    loadingInventoryData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setInventoryData(data?.data);
    }
  }, [data]);

  return (
    <>
      <Inventory.Provider value={value}>
        <FormSearch />
        <InventoryInquiryLocationTable />
      </Inventory.Provider>
    </>
  );
}

export default InventoryInquiryLocation;
