/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';

interface IPopupCheckTaxName {
  visible?: boolean;
  onHiding?: () => void;
  data: {
    storeId: number;
    invoice: string;
    taxType: string;
    receivableCd: string;
    orderDate: string;
  };
}

function PopupCheckTaxName({ visible, onHiding, data }: IPopupCheckTaxName) {
  const { t }: any = useDictionary({ programId: '2043.0' });
  const dataGridRef: any = useRef(null);
  const [dataProduct, setDataProduct] = useState<any>([]);

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, useCache: false, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!Object.keys(data || {})?.length) return;
    const getData = async () => {
      const res = await refetchData({
        url: 'user/m2043_0/send-tax-invoice/get-tax-name-blank',
        method: 'POST',
        data: data,
      });
      if (res?.data?.status === '200') {
        setDataProduct(res?.data?.data || []);
      } else {
        notification({ res });
      }
    };
    getData();
  }, [data]);

  const generateTaxname = async () => {
    const productIds = (dataProduct || []).map(o => o.product_id);
    if (!productIds?.length) return;

    const res = await refetchData({
      url: 'product/product/generate/list/product-tax-name',
      method: 'POST',
      data: productIds,
    });
    if (res?.data?.status === '200') {
      setDataProduct(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  /**
   * on update
   *
   * @return {*}
   */
  const onUpdateTaxName = async () => {
    const dataGrid =
      dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
    const updateData = dataGrid
      .filter(o => o?.tax_nm)
      .map(o => ({ product_id: o?.product_id, tax_nm: o?.tax_nm }));
    if (!updateData?.length) return;
    const res = await refetchData({
      url: 'product/product/update/list/product-tax-name',
      method: 'POST',
      data: { updateData },
    });
    notification({ res });
    if (res?.data?.status === '200') {
      onHiding?.();
    }
  };

  /**
   * on update
   *
   */
  const onUpdateClick = () => {
    if (dataGridRef?.hasEditData?.()) {
      dataGridRef?.saveEditData?.().then(() => {
        if (!dataGridRef?.hasEditData?.()) {
          onUpdateTaxName();
        }
      });
    } else {
      onUpdateTaxName();
    }
  };

  const columns: any = [
    {
      caption: t('ID'),
      dataField: 'product_id',
      alignment: 'left',
      width: 100,
      allowEditing: false,
    },
    {
      caption: t('Product Code'),
      dataField: 'product_cd',
      alignment: 'left',
      allowEditing: false,
    },
    {
      caption: t('Product Name'),
      dataField: 'product_nm',
      alignment: 'left',
      allowEditing: false,
    },
    {
      caption: t('Maker'),
      dataField: 'maker_nm',
      alignment: 'left',
      allowEditing: false,
    },
    {
      caption: t('Tax Name'),
      dataField: 'tax_nm',
      alignment: 'left',
      minWidth: 200,
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
  ];

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading} />
          <div className="modal-popup-header">
            <span className="title-page">{t('Check Tax Name product')}</span>
            <div>
              <Button
                stylingMode="contained"
                type="default"
                text={t('Save')}
                icon="check"
                style={{ marginRight: 10 }}
                onClick={() => onUpdateClick()}
              />
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
          <DataGridFull
            columns={columns}
            ref={dataGridRef}
            dataSource={dataProduct}
            fixedLeft={0}
            fixedRight={0}
            options={{
              keyExpr: 'product_id',
              height: isMobile ? 'calc(80vh - 200px)' : 'calc(100vh - 140px)',
              columnAutoWidth: true,
              editing: {
                mode: 'cell',
                allowUpdating: true,
                selectTextOnEditStart: true,
              },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                      icon: 'check',
                      type: 'default',
                      text: t('Generate'),
                      onClick: () => generateTaxname(),
                    },
                  },
                );
              },
            }}
          />
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Zone')}
      contentRender={content}
      height={'100vh'}
      width={isMobile ? '96%' : '65vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupCheckTaxName;
