/**
 * Update date: 12-05-2023
 * Screen 1042.6
 */

import React, { useState, useRef } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectCommonCodeType from 'app/components/PopupCommon/PopupSelectCommonCodeType';
import { uniqBy } from 'lodash';
import { useCodeContext } from '..';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';

const FormSearch = () => {
  const { themePro }: any = useApp();
  const context: any = useCodeContext();
  const { refetchData } = context;

  const [formStore, setFormStore] = useState<any>({
    codeLst: [],
    keyword: '',
  });
  const [formData, setFormData] = useState<any>({
    codeLst: [],
    keyword: '',
  });

  const formRef: any = useRef(null);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const { t }: any = useDictionary({
    programId: '1042.6',
  });

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    if (
      (formRef?.current && !formRef?.current?.instance?.validate()?.isValid) ||
      !formRef?.current
    ) {
      return;
    }
    const data = {
      codeType: formStore.codeLst.map((map: any) => map?.codeType),
      keyword: formData.keyword,
    };
    await refetchData({
      data,
    });
  };

  /**
   * on remove
   *
   * @param {*} data
   */
  const handleRemove = data => {
    let newData: any = [...formStore?.['codeLst']].filter(
      (filter: any) => filter?.codeType !== data?.codeType,
    );
    setFormStore(preState => ({ ...preState, codeLst: newData }));
    setFormData(preState => ({ ...preState, codeLst: '' }));
  };
  /**
   * on clean all
   *
   */
  const handleCleanAll = () => {
    setFormData(preState => ({ ...preState, codeLst: '' }));
    setFormStore(preState => ({ ...preState, codeLst: [] }));
  };
  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    let newData: any = uniqBy(
      [...formStore?.['codeLst']].concat(data),
      'codeType',
    );
    setFormData(preState => ({ ...preState, codeLst: '' }));
    setFormStore(preState => ({ ...preState, codeLst: newData }));
    setIsShowPopup(false);
  };

  /**
   * render content
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <Form
        ref={formRef}
        formData={formData}
        showColonAfterLabel={false}
        labelLocation="top"
        colCount={themePro ? 2 : 3}
        items={[
          {
            label: { text: t('Code Type') },
            dataField: 'codeLst',
            editorType: 'dxTextBox',
            editorOptions: {
              onEnterKey: () => {
                setIsShowPopup(true);
              },
              buttons: [
                {
                  name: 'data1',
                  location: 'before',
                  options: {
                    icon: 'close',
                    text: formStore?.codeLst?.[0]?.codeType,
                    onClick: () => handleRemove(formStore?.codeLst?.[0]),
                    visible: !!formStore?.codeLst[0]?.codeTypeName,
                    rtlEnabled: true,
                  },
                },
                {
                  name: 'data2',
                  location: 'before',
                  options: {
                    icon: 'close',
                    text: formStore?.codeLst?.[1]?.codeType,
                    onClick: () => handleRemove(formStore?.codeLst?.[1]),
                    visible: !!formStore?.codeLst?.[1]?.codeTypeName,
                    rtlEnabled: true,
                  },
                },
                {
                  name: 'storeMore',
                  location: 'before',
                  options: {
                    text: `+ ${formStore?.codeLst?.length - 2} ${t('more')}`,
                    visible: formStore?.codeLst?.length > 2,
                  },
                },
                {
                  name: 'clearAll',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'close',
                    visible: !!formStore?.codeLst?.length,
                    onClick: () => handleCleanAll(),
                  },
                },
                {
                  name: 'search',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'search',
                    onClick: () => setIsShowPopup(true),
                  },
                },
              ],
            },
          },
          {
            label: { text: t('Keyword') },
            dataField: 'keyword',
            editorType: 'dxTextBox',
          },
        ]}
      ></Form>
    );
  };
  /**
   * render
   */
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {isShowPopup && (
        <PopupSelectCommonCodeType
          visible={isShowPopup}
          text={formData?.codeLst}
          mode="multiple"
          onHiding={() => setIsShowPopup(!isShowPopup)}
          onSubmit={handleSubmitPopup}
        />
      )}
    </div>
  );
};

export default FormSearch;
