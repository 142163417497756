/**
 * Update date: 2023-08-04
 * Screen 2054.C
 */
import React, { createContext, useContext, useState } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
const List = React.lazy(() => import('./List'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2054_C_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

export default function M2054_C() {
  const [data, setData] = useState([]);

  const store: any = new ArrayStore({
    data: data,
    key: ['zoneId', 'locationId', 'productId'],
  });

  const value: any = {
    store,
    setData,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <List />
      </React.Suspense>
    </ModuleContext.Provider>
  );
}
