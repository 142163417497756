import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CounterState {
  value: boolean;
}

const initialState: CounterState = {
  value: true,
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    showNav: state => {
      state.value = true;
    },
    hideNav: state => {
      state.value = false;
    },
    showHideNav: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

// Actions
export const { showNav, hideNav, showHideNav } = navSlice.actions;

//Selectors

export const selectIsNav = (state: any) => state.nav.value;

const navReducer = navSlice.reducer;

export default navReducer;
