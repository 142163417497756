/**
 * Update date: 18-05-2023
 * Screen 2013.4
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const FormSpecialDeal = React.lazy(() => import('./FormSpecialDeal'));
const ListSpecialDeal = React.lazy(() => import('./ListSpecialDeal'));
/**
 * create context
 */
const ModuleContext = createContext({
  searchParams: {},
  setSearchParams: () => {},
  listSpecialDeals: [] || null,
  onUpdateListSpecialDeals: () => {},
});

ModuleContext.displayName = 'RegistrationSpecialDeal';

export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  return context;
};

const RegistrationSpecialDeal = () => {
  const [searchParams, setSearchParams] = useState({
    fromDate: new Date(),
    endDate: new Date(),
    productDealName: '',
    use: '',
  });
  const [listSpecialDeals, setListSpecialDeals] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: resApiList, loading: loadingApiList }, excuteApiList] =
    useAxios(
      {
        url: '/account/product-deal',
        method: 'POST',
      },
      { manual: true, autoCancel: true, useCache: false },
    );
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiList?.data) {
      setListSpecialDeals(resApiList?.data);
    }
  }, [resApiList]);

  /**
   * on update
   *
   * @param {*} record
   * @return {*}
   */
  const onUpdateListSpecialDeals = (record: any) => {
    let l: any = [];
    if (!record?.productDealId) return;

    if (
      listSpecialDeals.some(o => o['productDealId'] === record.productDealId)
    ) {
      l = [...listSpecialDeals]?.map((o: any) => {
        if (o?.productDealId == record?.productDealId) return record;
        return o;
      });
    } else {
      l = [...listSpecialDeals];
      l.push(record);
    }
    setListSpecialDeals(l);
  };

  const value: any = {
    setModeView,
    modeView,
    searchParams,
    setSearchParams,
    listSpecialDeals,
    excuteApiList,
    onUpdateListSpecialDeals,
    refetchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListSpecialDeal />;
      case 'create':
      case 'edit':
        return <FormSpecialDeal />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiList || loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default RegistrationSpecialDeal;
