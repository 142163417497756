import useCenterInfo from './useCenterInfo';

function useFormat() {
  const { amountPoint } = useCenterInfo();

  const QtyFormat = {
    type: 'fixedPoint',
    precision: 0,
  };

  const AmountFormat = {
    type: 'fixedPoint',
    precision: amountPoint,
  };

  const PercentFormat = {
    type: 'fixedPoint',
    precision: 2,
  };

  const IntegerFormat = {
    type: 'fixedPoint',
    precision: 0,
  };

  const DoubleFormat = {
    type: 'fixedPoint',
    precision: 2,
  };

  const ThreethFormat = {
    type: 'fixedPoint',
    precision: 3,
  };

  const FifthFormat = {
    type: 'fixedPoint',
    precision: 5,
  };

  const SingleFormat = {
    type: 'fixedPoint',
    precision: 1,
  };

  const GetExcelFormat = format => {
    let str = '#,##0';

    if (format?.precision !== undefined) {
      if (format?.precision > 0) {
        str = str + '.' + new Array(format?.precision).fill('0').join('');
      }
    } else {
      str = format?.replace('#,###', '#,##0');
    }
    return `${str}`;
  };

  return {
    QtyFormat,
    AmountFormat,
    PercentFormat,
    IntegerFormat,
    DoubleFormat,
    GetExcelFormat,
    ThreethFormat,
    FifthFormat,
    SingleFormat,
  };
}

export default useFormat;
