/**
 * Update date: 26-05-2023
 * Screen 2043.2
 */
import DateOptions from 'app/components/DateOptions';
import LoadPanel from 'app/components/LoadPanel';
import PopupConfirmWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmWithRef';
import PopupSelectMall from 'app/components/PopupCommon/PopupSelectMall';
import useAxios from 'axios-hooks';
import { Button } from 'devextreme-react';
import Form from 'devextreme-react/form';
import useFormatTime from 'hooks/useFormatTime';
import useLocalStorage from 'hooks/useLocalStorage';
import { cloneDeep, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { compareDate, dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const FormContent = ({ t, formIndex }) => {
  const [dataLocal, setDataLocal] = useLocalStorage(
    `2043.2_MallIntegration_${formIndex}`,
    {},
  );
  const formRef: any = useRef(null);
  const confirmSaveRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  const [showPopupByType, setShowPopupByType] = useState('');
  const [resultSend, setResultSend] = useState<any>({});
  const [formInfo, setFormInfo] = useState<any>({
    fromDate: moment(new Date()).subtract(30, 'days'),
    endDate: new Date(),
    dataList: {
      mall: [],
    },
    mall: '',
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  useEffect(() => {
    if (!dataLocal) return;
    setFormInfo(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        mall: dataLocal?.mall || [],
      },
    }));
  }, [dataLocal]);
  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const dateStartSend = dateFormatStr(new Date(), 'YYYY-MM-DD HH:mm:ss');
    const data = {
      startDate: dateFormatStr(formInfo?.fromDate),
      endDate: dateFormatStr(formInfo?.endDate),
      mallCd: formInfo?.dataList?.mall?.[0]?.mallCode || '',
    };
    const res = await refetchData({
      url: 'ecommerce/process-order-upload/send-mall-integration',
      method: 'POST',
      data: data,
    });
    notification({ res });
    if (res?.data?.status === '200') {
      setResultSend({
        dateStartSend,
        dateEndSend: dateFormatStr(new Date(), 'YYYY-MM-DD HH:mm:ss'),
        count: res?.data?.data || '',
      });
    }
  };

  /**
   * remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formInfo?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormInfo({
      ...formInfo,
      dataList: dataList,
    });
    if (storedType === 'mall') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formInfo?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formInfo,
      dataList: dataList,
      [storedType]: '',
    };
    setFormInfo(newData);
    setShowPopupByType('');
    if (storedType === 'mall') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formInfo?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };
  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'mall':
        return (
          <PopupSelectMall
            text={formInfo?.[popupType]}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('mall', o)}
            dataBody={{ mainMallYn: true }}
          />
        );
      default:
        return null;
    }
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    setFormInfo(preState => ({
      ...preState,
      fromDate: fromDateValue,
      endDate: toDateValue,
    }));
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (formInfo?.endDate && e?.value) {
          if (
            compareDate(e?.value, formInfo?.endDate) === 1 ||
            compareDate(moment(e?.value).add(30, 'days'), formInfo?.endDate) ===
              -1
          ) {
            setFormInfo(preState => ({
              ...preState,
              fromDate: e?.value,
              endDate: null,
            }));
          } else {
            setFormInfo(prev => ({ ...prev }));
          }
        } else {
          setFormInfo(prev => ({ ...prev }));
        }
        break;
      case 'endDate':
        if (formInfo?.fromDate && e?.value) {
          if (
            compareDate(e?.value, formInfo?.fromDate) === -1 ||
            compareDate(
              moment(e?.value).subtract(30, 'days'),
              formInfo?.fromDate,
            ) === 1
          ) {
            setFormInfo(preState => ({
              ...preState,
              fromDate: null,
              endDate: e?.value,
            }));
          } else {
            setFormInfo(prev => ({ ...prev }));
          }
        } else {
          setFormInfo(prev => ({ ...prev }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="bg-white" style={{ borderRadius: 5 }}>
      <div
        style={{
          height: 48,
          borderBottom: ' 1px solid #E4E7EC',
          paddingLeft: 20,
          paddingTop: 15,
          fontWeight: 'bold',
        }}
      >
        {t('Mall Integration')}
      </div>
      <div className="p-20">
        <DateOptions
          onChangeValue={onChangeDate}
          disableButton={['180days', '90days']}
        />
        <Form
          ref={formRef}
          formData={formInfo}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
                max: formInfo?.endDate || null,
                min: formInfo?.endDate
                  ? moment(formInfo?.endDate).subtract(30, 'days')
                  : null,
              },
              isRequired: !formInfo?.fromDate && formInfo?.endDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('From Date is required'),
                },
              ],
            },
            {
              label: { text: t('End Date') },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
                min: formInfo?.fromDate || null,
                max: formInfo?.fromDate
                  ? moment(formInfo?.fromDate).add(30, 'days')
                  : null,
              },
              isRequired: !formInfo?.endDate && formInfo?.fromDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('End Date is required'),
                },
              ],
            },
            generateDxTextBox('Mall ID', 'mall', 'mallName', {
              isRequired: true,
            }),
          ]}
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: 20,
          }}
        >
          <div
            style={{
              flex: 1,
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
            }}
          >
            <div>
              {`${t('Data Collection Start')}: ${
                resultSend?.dateStartSend || ''
              }`}
            </div>
            <div>
              {`${t('Data Collection Completed')}: ${
                resultSend?.dateEndSend || ''
              }`}
            </div>
          </div>
          <div style={{ flex: 1, width: '50%' }}>
            {`${resultSend?.count || ''}`}
          </div>
        </div>
      </div>
      <div
        style={{
          borderTop: ' 1px solid #E4E7EC',
          paddingLeft: 20,
          padding: '10px 30px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          stylingMode="contained"
          type="default"
          text={t('Order Collection')}
          style={{ marginRight: 5 }}
          onClick={() => {
            const isValid = formRef?.current?.instance?.validate()?.isValid;
            if (!isValid) {
              return;
            }
            confirmSaveRef?.current?.onOpen?.();
          }}
        />
      </div>
      {getPopupByType(showPopupByType)}
      <LoadPanel visible={loading} />
      <PopupConfirmWithRef
        ref={confirmSaveRef}
        content={'Are you sure want to order collection?'}
        isTranslate
        onOk={onSubmit}
      />
    </div>
  );
};
export default FormContent;
