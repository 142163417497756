/*
 * 2082.5 Employee/Daily Salary Calculation
 * @since 2022/11/24
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import React, { createContext, useContext, useState, useEffect } from 'react';
import View from './View';
import { TRADE_PAYABLE_VENDOR_TYPE } from 'constant/dataConstant';

const List = React.lazy(() => import('./List'));
// Initialize context
const TradePayableBalanceInquiryContext = createContext({});
TradePayableBalanceInquiryContext.displayName =
  'TradePayableBalanceInquiryContext';
export const useTradePayableBalanceInquiryContext = () => {
  const context = useContext(TradePayableBalanceInquiryContext);
  return context;
};

/**
 * 2082.5 Employee/Daily Salary Calculation
 *
 * @export
 * @return {*}
 */
export default function TradePayableBalanceInquiry() {
  // Get multiple language
  const { t }: any = useDictionary({ programId: '2082.5' });
  // Initialize state
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
  });
  const { AmountFormat }: any = useFormat();
  const [listData, setListData] = useState([]);
  const [listDataDetail, setListDataDetail] = useState<any>([]);
  const [dateSearch, setDateSearch] = useState(new Date());
  const [formData, setFormData] = useState<any>({
    dataList: {
      vendor: [],
      vendorType: [],
    },
    vendor: '',
    vendorType: '',
    date: new Date(),
  });
  const [formSearchView, setFormSearchView] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    vendorName: '',
    debitAmount: 0,
  });
  // Api data master grid
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'user/trade-payable-inquiry',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  // Api data detail
  const [{ loading: loadingDetail }, refetchDetail] = useAxios(
    {
      url: 'user/trade-payable-inquiry/detail',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  // const [{ data: dataColumnPallet, loading: loadingColumnPallet }] = useAxios(
  //   {
  //     url: 'user/trade-payable-inquiry/column-pallet',
  //     method: 'GET',
  //   },
  //   { autoCancel: true, useCache: false },
  // );

  const store: any = new ArrayStore({
    data: listData,
    // key: [],
  });
  const storeView: any = new ArrayStore({
    data: listDataDetail,
    // key: [],
  });

  /**
   * On search data master grid
   *
   */
  const onSearch = async () => {
    const searchParams = {
      vendorIds: formData?.dataList.vendor.map((o: any) => o?.vendorId),
      vendorTypes: formData?.dataList.vendorType.map((o: any) => o?.code),
      today: formData?.date ? moment(formData?.date).format('YYYY-MM-DD') : '',
    };
    const res: any = await refetchData({
      data: {
        ...searchParams,
      },
    });
    if (res?.data?.status === '200') {
      setDateSearch(formData?.date);
    }
  };

  /**
   * On search data View page
   *
   */
  const onSearchView = async () => {
    const res: any = await refetchDetail({
      data: {
        fromDate: formSearchView?.fromDate
          ? moment(formSearchView?.fromDate).format('YYYY-MM-DD')
          : null,
        endDate: formSearchView?.endDate
          ? moment(formSearchView?.endDate).format('YYYY-MM-DD')
          : null,
        vendorId: formSearchView?.vendorId || null,
      },
    });
    if (res?.data?.status === '200') {
      setListDataDetail(
        res?.data?.data?.map(o => {
          return {
            ...o,
            btype_nm: t(
              TRADE_PAYABLE_VENDOR_TYPE.find(t => t.value === o?.btype)
                ?.label || '',
            ),
            day_dt:
              o?.atype === '2'
                ? o?.day_dt
                : `${t('The Past')} - ${moment(formSearchView?.fromDate)
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')}`,
          };
        }),
      );
      setFormSearchView(preState => ({
        ...preState,
        debitAmount:
          res?.data?.data?.[res?.data?.data?.length - 1]?.['debit_amount'] ||
          preState?.debitAmount,
      }));
    }
  };

  /**
   *  Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <View />;
      default:
        break;
    }
  };

  // Set data to state
  useEffect(() => {
    if (!loadingList && dataList?.data) {
      setListData(dataList?.data);
    }
  }, [loadingList, dataList?.data]);

  // Values share with child component
  const value: any = {
    storeView,
    AmountFormat,
    listData,
    setListData,
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    refetchDetail,
    formData,
    onSearch,
    setFormData,
    formSearchView,
    setFormSearchView,
    onSearchView,
    // dataColumnPallet,
    setListDataDetail,
    dateSearch,
  };

  const loadingAll = loadingList || loadingDetail;

  return (
    <TradePayableBalanceInquiryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </TradePayableBalanceInquiryContext.Provider>
  );
}
