/**
 * Update date: 25-01-2024
 * ESL Design Setting
 */
import { useMemo, useRef, useState } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import moment from 'moment';
import { useModuleContext } from '..';
import FormSearch, { onSubmit } from './FormSearch';
import { notification } from 'utils/notification';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import PopupMatchingCode from '../Popup/PopupMatchingCode';
import PopupMatchedProducts from '../Popup/PopupMatchedProducts';

function StoreProductListTable() {
  const context: any = useModuleContext();
  const { t, setESLDesignList, eslDesignList, refetchData, setModeView } =
    context;
  const dataGridRef: any = useRef(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showMatchingCode, setShowMatchingCode] = useState<boolean>(false);
  const [showMatchedProducts, setShowMatchedProducts] =
    useState<boolean>(false);
  const [getRowByDesignId, setGetRowByDesignId] = useState<any>({});
  const [selectedKeys, setSelectedKeys] = useState<any>([]);

  /**
   * render design name
   *
   * @param {*} record
   */
  const renderDesignName = (record: any) => (
    <div
      onClick={() => {
        setModeView({ type: 'edit', data: record?.data });
      }}
      className="cell-link"
    >
      {record?.value}
    </div>
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'eslDesignId',
        caption: t('Design ID'),
        alignment: 'left',
      },
      {
        dataField: 'designNm',
        caption: t('Design Name'),
        cellRender: renderDesignName,
      },
      {
        dataField: 'designCd',
        caption: t('Design Code'),
        alignment: 'left',
      },
      {
        dataField: 'designImage',
        caption: t('Design Image'),
        cellRender: record => {
          const { designImage } = record?.data || {};
          return designImage ? (
            <div className="product-image-grid-custom">
              <img src={designImage} width={26} height={26} alt="" />
            </div>
          ) : null;
        },
      },
      {
        dataField: 'productQty',
        caption: t('Product Qty'),
        alignment: 'left',
      },
      {
        dataField: 'designType',
        caption: t('Design Type'),
        alignment: 'left',
      },
      {
        dataField: 'sortNo',
        caption: t('Sort No'),
        alignment: 'left',
      },
      {
        dataField: 'action',
        caption: t('Action'),
        alignment: 'center',
        type: 'buttons',
        allowEditing: false,
        buttons: [
          {
            text: t('Matching code'),
            cssClass: 'btn',
            onClick: row => {
              setGetRowByDesignId({
                title: row?.row?.data?.designNm,
                designId: row?.row?.data?.eslDesignId,
                productQty: row?.row?.data?.productQty,
              });
              setShowMatchingCode(true);
            },
          },
          {
            text: '|',
          },
          {
            text: t('Matched products'),
            cssClass: 'btn',
            onClick: row => {
              setGetRowByDesignId({
                title: row?.row?.data?.designNm,
                designId: row?.row?.data?.eslDesignId,
              });
              setShowMatchedProducts(true);
            },
          },
        ],
        fixed: true,
        fixedPosition: 'left',
        minWidth: 200,
      },
    ],
    [t],
  );

  const handleConfirm = async () => {
    const res = await refetchData({
      url: '/core/esl-design-setting',
      method: 'DELETE',
      data: selectedKeys.map((item: any) => item.eslDesignId),
    });
    if (res?.data?.status === '200') {
      notification({ res });
      setShowConfirm(false);
      onSubmit(refetchData, setESLDesignList, { designName: '' });
    } else {
      notification({ res });
      setShowConfirm(false);
    }
  };

  return (
    <>
      <FormSearch />
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={eslDesignList}
        isStorageFilter={false}
        options={{
          selection: {
            mode: 'multiple',
            isAllPages: true,
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                locateInMenu: 'auto',
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () =>
                    dataGridRef?.current?.instance?.exportToExcel(),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Create New'),
                  onClick: () =>
                    setModeView({ type: 'create', autoLoad: false }),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  onClick: () => {
                    const selected =
                      dataGridRef?.current?.instance?.getSelectedRowsData();
                    if (selected?.length > 0) {
                      setSelectedKeys(selected);
                      setShowConfirm(true);
                    }
                  },
                },
              },
            );
          },
          export: {
            fileName:
              'SMDC_1042.7_ESLDesignSetting_' + moment().format('YYYYMMDD'),
          },
          summary: {
            totalItems: [{}],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
            ],
          },
        }}
      />
      {showConfirm && (
        <PopupConfirmDelete
          visible={showConfirm}
          content={`${t('Do you want to delete {0} items?')}`.replace(
            '{0}',
            selectedKeys?.length,
          )}
          onOk={handleConfirm}
          onHiding={() => setShowConfirm(false)}
        />
      )}
      {showMatchingCode && getRowByDesignId?.designId && (
        <PopupMatchingCode
          visible={showMatchingCode}
          onHiding={() => setShowMatchingCode(false)}
          title={getRowByDesignId?.title}
          designId={getRowByDesignId?.designId}
          productQty={getRowByDesignId?.productQty}
        />
      )}
      {showMatchedProducts && getRowByDesignId != null && (
        <PopupMatchedProducts
          visible={showMatchedProducts}
          onHiding={() => setShowMatchedProducts(false)}
          title={getRowByDesignId?.title}
          designId={getRowByDesignId?.designId}
        />
      )}
    </>
  );
}

export default StoreProductListTable;
