import useAxios from 'axios-hooks';
import { ALARM_PRIORITY_COLOR } from 'constant/dataConstant';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { forwardRef, memo, useImperativeHandle, useState } from 'react';

function PopupAlarmContent({ readCallBack = (data = null) => {} }, ref) {
  const { t }: any = useDictionary({});
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [contentShow, setContentShow] = useState<any>({});

  const [{}, fetchData] = useAxios(
    {
      url: '/core/alarm/update-user-reader',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = (data: any) => {
    if (data) {
      const priorityColor =
        ALARM_PRIORITY_COLOR.find(o => o.value == data?.noticePriority)
          ?.color || '#fff';
      data.priorityColor = priorityColor;
      setContentShow(data);
      if (!data.hasRead) {
        fetchData({
          data: { noticeId: data.noticeId, alarmCd: data.alarmCd },
        }).then(result => {
          if (result?.data?.status == '200') {
            readCallBack?.(data?.noticeId);
          }
        });
      }
    }
    setShowPopupConfirm(true);
  };

  const onClose = () => {
    setShowPopupConfirm(false);
    setContentShow({});
  };

  const informationItem = (label, value) => {
    return value ? (
      <div
        style={{
          display: 'flex',
          borderRadius: 6,
          alignItems: 'center',
          borderBottom: '1px solid #E4E7EC',
          minHeight: 40,
          width: '100%',
        }}
      >
        <div style={{ width: 120, fontWeight: 600 }}>{label}</div>
        <div style={{ width: 'calc( 100% - 120px)' }}>{value}</div>
      </div>
    ) : (
      <></>
    );
  };

  const content = () => {
    return (
      <div>
        <div className="modal-popup-header">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <span
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  background: contentShow?.priorityColor,
                  marginRight: 10,
                  marginLeft: 5,
                  border: '1px solid #e4e7ec',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                }}
              />
            </div>
            <span
              className="title-page"
              style={{ fontSize: 20, margin: '10px 0', lineHeight: '25px' }}
            >
              {contentShow?.noticeTitle}
            </span>
          </div>

          <div>
            <Button stylingMode="text" icon="close" onClick={onClose} />
          </div>
        </div>
        <div
          style={{
            height: 'calc(100vh - 90px)',
            color: '#475467',
            background: '#fff',
            borderRadius: 5,
          }}
        >
          <ScrollView>
            <div style={{ padding: 10 }}>
              {informationItem(t('Sender') + ':', contentShow?.noticeSender)}
              {informationItem(t('Send Time') + ':', contentShow?.noticeDm)}
              {informationItem(t('Target') + ':', contentShow?.noticeTarget)}
              {informationItem(t('Reason') + ':', contentShow?.noticeReason)}
              {informationItem(t('Note') + ':', contentShow?.noticeNote)}
              {informationItem(t('Link') + ':', contentShow?.noticeLink)}
              {contentShow?.noticeContent && (
                <div>
                  <div
                    style={{
                      fontWeight: 600,
                      color: '#475467',
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    {t('Content') + ':'}
                  </div>
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {contentShow?.noticeContent}
                  </div>
                </div>
              )}
            </div>
          </ScrollView>
        </div>
      </div>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={showPopupConfirm}
      onHiding={onClose}
      closeOnOutsideClick
      contentRender={content}
      height={'100vh'}
      width={734}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}
export default memo(forwardRef(PopupAlarmContent));
