/**
 * Update date: 12-05-2023
 * Screen 1042.3
 */

import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import { Button, Form } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { forwardRef, useEffect, useState } from 'react';
import { notification } from 'utils/notification';
import { useCategoryRegistration } from '..';
import { useApp } from 'app';

function FormCreate(props, ref) {
  const { themePro }: any = useApp();
  const rootContext: any = useCategoryRegistration();
  const { selected, setSelected, refetchData, onUpdate, onDelete } =
    rootContext;
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});

  const { t }: any = useDictionary({});

  /**
   * content name
   *
   * @return {*}
   */
  const contentName = () => {
    if (selected?.type) {
      const { data } = selected;
      switch (selected?.type) {
        case 'edit':
        case 'create':
          return `${selected?.type === 'edit' ? t('Edit') : t('Create')}  ${
            selected?.type === 'create' ? ' for' : ''
          } ${data?.name}`;

        default:
          return '';
      }
    }
  };

  /**
   * generate category product code
   *
   */
  const generateCateProductCode = async () => {
    const res = await refetchData({
      url: `/core/category-product/generate-cate-product-code?codeType=${formData?.codeType}&parentCode=${formData?.parentCd}`,
      method: 'GET',
    });

    if (res?.data?.status === '200') {
      const cateCode = res?.data?.data;
      ref?.current?.instance?.updateData('code', cateCode);
      ref?.current?.instance?.updateData(
        'id',
        `${formData?.codeType}_${cateCode}`,
      );
    }
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const handleSubmit = async () => {
    const isValid = ref?.current?.instance?.validate()?.isValid;
    if (!isValid) return;

    const res = await refetchData({
      url: '/core/category-product',
      method: 'PUT',
      data: { ...formData },
    });

    notification({ res });

    if (res?.data?.status === '201') {
      const resData = res?.data?.data;
      onUpdate({ ...formData, orders: resData?.orders, isCreate: false });
    }
  };

  /**
   * on delete
   *
   */
  const handleDelete = async () => {
    setShowModalDelete(false);

    const params = {
      codeType: formData?.codeType,
      code: formData?.code,
      children: formData?.children,
    };

    const res = await refetchData({
      url: 'core/category-product',
      method: 'DELETE',
      data: params,
    });

    notification({ res });

    if (res?.data?.status === '200') {
      onDelete(selected?.data);
    }
  };

  /**
   * on cancel
   *
   */
  const handleCancel = () => {
    if (formData?.isCreate) {
      onDelete(formData);
    }

    setShowModalCancel(false);
    setSelected({ type: '', data: null });
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (selected?.data) {
      const { data } = selected;
      setFormData({ ...data });
    }
  }, [selected]);

  /**
   * Form field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'code':
        ref?.current?.instance?.updateData(
          'id',
          `${formData?.codeType}_${e?.value || ''}`,
        );
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={
        themePro
          ? {
              padding: 20,
              backgroundColor: '#fff',
              borderRadius: 6,
              width: 'calc(100% - 420px)',
            }
          : { padding: 20, flexGrow: 1 }
      }
    >
      {selected?.data && (
        <>
          {themePro ? (
            <div className="my-page-caption">
              <div>
                <div className="my-page-caption-title">{contentName()}</div>
              </div>
              <div>
                <Button
                  icon="check"
                  stylingMode="contained"
                  type="default"
                  text={t('Save')}
                  onClick={() => handleSubmit()}
                  style={{ marginRight: 5 }}
                />
                <Button
                  icon="trash"
                  stylingMode="contained"
                  text={t('Delete')}
                  onClick={() => setShowModalDelete(true)}
                  style={{ marginRight: 5 }}
                  visible={selected?.type === 'edit'}
                />
                <Button
                  icon="undo"
                  text={t('Cancel')}
                  type="normal"
                  stylingMode="contained"
                  onClick={() => setShowModalCancel(true)}
                />
              </div>
            </div>
          ) : (
            <h4>{contentName()}</h4>
          )}
          <br />
          <Form
            ref={ref}
            labelLocation="top"
            showColonAfterLabel={false}
            formData={formData}
            onFieldDataChanged={onFieldDataChanged}
            items={[
              {
                dataField: 'code',
                editorType: 'dxTextBox',
                label: {
                  text: t('Code'),
                },
                isRequired: true,
                editorOptions: {
                  disabled: selected?.type === 'edit' ? true : false,
                  buttons: [
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'refresh',
                        onClick: () => generateCateProductCode(),
                      },
                    },
                  ],
                },
              },
              {
                dataField: 'name',
                editorType: 'dxTextBox',
                label: {
                  text: t('Code Name'),
                },
                isRequired: true,
              },

              {
                dataField: 'use',
                editorType: 'dxSwitch',
                label: {
                  text: t('Use'),
                },
              },
              {
                dataField: 'note',
                editorType: 'dxTextArea',
                label: {
                  text: t('Note'),
                },
              },
            ]}
            colCount={themePro ? 2 : 1}
          />

          <br />
          {!themePro && (
            <div style={{ textAlign: 'center' }}>
              <Button
                icon="save"
                text={t('Save')}
                type="normal"
                stylingMode="contained"
                style={{ marginRight: 10 }}
                onClick={() => handleSubmit()}
              />
              <Button
                icon="trash"
                text={t('Delete')}
                type="normal"
                stylingMode="contained"
                style={{
                  marginRight: 10,
                  opacity: selected?.type === 'create' ? '0.5' : '1',
                }}
                onClick={() => setShowModalDelete(true)}
                disabled={selected?.type === 'create' ? true : false}
              />
              <Button
                icon="undo"
                text={t('Cancel')}
                type="normal"
                stylingMode="contained"
                onClick={() => setShowModalCancel(true)}
              />
            </div>
          )}
        </>
      )}

      <PopupConfirmDelete
        visible={showModalDelete}
        content={`${t('Do you want to delete "{0}"?')}`.replace(
          '{0}',
          selected?.data?.name,
        )}
        onOk={handleDelete}
        onHiding={() => setShowModalDelete(!showModalDelete)}
      />

      <PopupConfirm
        title={t('Confirm Cancel')}
        visible={showModalCancel}
        content={t('Do you want to cancel?')}
        onOk={handleCancel}
        onHiding={() => setShowModalCancel(!showModalCancel)}
      />
    </div>
  );
}

export default forwardRef(FormCreate);
