/**
 * Update date: 06-06-2023
 * Screen 2055.2
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import React, { createContext, useContext, useRef, useState } from 'react';
import FormSearch from './FormSearch';
import ListInventory from './ListInvetory';

const defaultParams = {
  largeCategories: '',
  mediumCategories: '',
  smallCategories: '',
  subCategories: '',
  product: '',
  productGroup: '',
  zone: '',
  reason: '',
  status: '2',
};
/**
 * create context
 */
const InquiresOfScheduledContext = createContext({});
InquiresOfScheduledContext.displayName = 'InquiresOfScheduledContext';

export const useInquiresOfScheduled = () => {
  const context = useContext(InquiresOfScheduledContext);
  return context;
};

function InquiresOfDefective() {
  const formRef: any = useRef(null);
  const [params, setParams] = useState({
    ...defaultParams,
    fromDate: new Date(),
    toDate: new Date(),
    formStore: {
      largeCategories: [],
      mediumCategories: [],
      smallCategories: [],
      subCategories: [],
      product: [],
      productGroup: [],
      zone: [],
      reason: [],
    },
  });
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: '/warehouse/inq-def',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );
  const store: any = new ArrayStore({
    data: data?.data,
    key: 'disposalId',
  });
  const value: any = {
    store,
    refetchData,
    params,
    setParams,
  };

  const loadingAll = loading;

  return (
    <InquiresOfScheduledContext.Provider value={value}>
      <LoadPanel visible={loadingAll} />
      <FormSearch ref={formRef} />
      <ListInventory />
    </InquiresOfScheduledContext.Provider>
  );
}

export default InquiresOfDefective;
