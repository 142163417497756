/**
 * Update date: 15-05-2023
 * Screen 1044.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { useRef } from 'react';
import { useReportVendor } from '..';
const exportFileName =
  'SMDC_1044.3 Report Vendor_' + moment().format('YYYYMMDD');
export default function ListReportVendor(props) {
  const context: any = useReportVendor();
  const { store } = context;
  const { t }: any = useDictionary({
    programId: '1044.3',
  });
  const dataGridRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  /**
   * export excel
   *
   */
  const onExportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const onCellTemplate = e => {
    return e?.document ? e?.document : ' ';
  };
  const columns: any = [
    {
      caption: t('Vendor Header'),
      alignment: 'center',
      columns: [
        {
          dataField: 'vendorId',
          caption: t('ID'),
          alignment: 'center',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorTypeName',
          caption: t('Type'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorTypeCode',
          caption: t('Vendor Code'),
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'vendorName',
          caption: t('Vendor Name'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorRepresentative',
          caption: t('Representative'),
          alignment: 'left',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Vendor Info'),
      alignment: 'center',
      items: [
        {
          dataField: 'vendorMobile',
          caption: t('Mobil No.'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorPhone',
          caption: t('Phone No.'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'use',
          caption: t('Use'),
          alignment: 'center',
          editorType: 'dxSwitch',
          trueText: t('Use'),
          falseText: t('Not Use'),
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorShortName',
          caption: t('Vendor Short Name'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'dealStartDate',
          caption: t('Deal Start Date'),
          alignment: 'left',
          dataType: 'date',
          format: DateFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'dealEndDate',
          caption: t('Deal EndDate'),
          alignment: 'left',
          dataType: 'date',
          format: DateFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorAccountingCode',
          caption: t('Accounting Code'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'dealOldCode',
          caption: t('Old Code'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorCountryName',
          caption: t('Country'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorFax',
          caption: t('Fax No'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorPostCode',
          caption: t('Zip Code'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorAddressBasic',
          caption: t('Address1'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorAddressDetail',
          caption: t('Address2'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorNote',
          caption: t('Note'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Business'),
      alignment: 'center',
      columns: [
        {
          dataField: 'businessNumber',
          caption: t('Business Number'),
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorBusiness',
          caption: t('Business'),
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorIndustry',
          caption: t('Industry'),
          showWhenGrouped: true,
        },
        {
          dataField: 'companyNumber',
          caption: t('Corporation Number'),
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'document',
          caption: t('Documents'),
          alignment: 'right',
          calculateCellValue: onCellTemplate,
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Payment'),
      alignment: 'center',
      columns: [
        {
          dataField: 'bankName',
          caption: t('Bank'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'paymentAccountNumber',
          caption: t('Account Number'),
          alignment: 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'paymentDepositor',
          caption: t('Depositor'),
          alignment: 'left',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Manager'),
      alignment: 'center',
      columns: [
        {
          dataField: 'vendorManager',
          caption: t('Vendor Manager'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'officeManagerName',
          caption: t('Office Manager'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'salesManagerName',
          caption: t('Sales Manager'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'managerNote',
          caption: t('Manager Note'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Delivery'),
      alignment: 'center',
      visible: false,
      columns: [
        {
          dataField: 'vendorReceiver',
          caption: t('Receiver'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'deliveryPostCode',
          caption: t('Delivery Zip code'),
          alignment: 'center',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'deliveryAddressBasic',
          caption: t('Delivery Address1'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'deliveryAddressDetail',
          caption: t('Delivery Address2'),
          alignment: 'left',
          visible: false,
          showWhenGrouped: true,
        },
      ],
    },
  ];
  return (
    <DataGridFull
      ref={dataGridRef}
      dataSource={store}
      columns={columns}
      storageKeyInquiry
      options={{
        key: 'vendorId',
        columnAutoWidth: true,
        height: '70vh',
        onToolbarPreparing: (e: any) => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'totalCount',
            },
            {
              location: 'before',
              widget: 'dxButton',
              options: {
                icon: 'xlsxfile',
                text: t('Export to Excel'),
                onClick: () => onExportExcel(),
              },
            },
          );
        },
        export: {
          fileName: exportFileName,
        },
      }}
    />
  );
}
