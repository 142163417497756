/**
 * Update date: 16-05-2023
 * Screen 2011.3
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const View = React.lazy(() => import('./View'));

const defaultParams = {};

/**
 * create context
 */
const ProductGroupContext = createContext({});

ProductGroupContext.displayName = 'ProductGroupContext';

export const useProductGroup = () => {
  const context = useContext(ProductGroupContext);
  if (!context) throw new Error('Product Group Context Error');
  return context;
};

const ProductGroupRegistration = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ data: resData, loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: '/product/product_group',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const store = new ArrayStore({
    data: data,
    key: 'productGroupId',
  });

  const value: any = {
    productTpPermission,
    modeView,
    setModeView,
    params,
    setParams,
    data,
    refetchData,
    store,
  };
  /**
   * render content
   */

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <Create />;
      case 'view':
        return <View />;
      default:
        return <NotFoundPage />;
    }
  };

  useEffect(() => {
    if (resData?.data) {
      setData(resData?.data);
    }
  }, [resData]);

  const loadingAll = loadingfetchData || loadingProductTpPermission;

  return (
    <ProductGroupContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ProductGroupContext.Provider>
  );
};

export default ProductGroupRegistration;
