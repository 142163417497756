/**
 * Update date: 2023-10-09
 * Screen 1044.8
 */
import React, { useState, useContext, createContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import ListStore from './ListStore';
import useDictionary from 'hooks/useDictionary';

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M1044_8_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M1044_8 = () => {
  const { t }: any = useDictionary({ programId: '1044.8' });
  const [params, setParams] = useState({
    storeName: '',
    use: null,
    representative: '',
    dataList: {
      storeType: [],
      storeGroup: [],
    },
    storeType: '',
    storeGroup: '',
  });
  const [listData, setListData] = useState<any>([]);
  const [modeView] = useState<any>({
    type: 'list',
  });
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: listData,
    key: 'store_id',
  });

  const value: any = {
    t,
    store,
    setListData,
    params,
    setParams,
    refetchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListStore />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};
export default M1044_8;
