/**
 * Update date: 07-06-2023
 * Screen 2081.C
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';

const FormDeduction = React.lazy(() => import('./FormDeduction'));
const ListDeduction = React.lazy(() => import('./ListDeduction'));
/**
 * create context
 */
const ModuleContext = createContext({
  searchParams: {},
  listDeduction: [] || null,
  setSearchParams: () => {},
  onUpdateListDeduction: () => {},
});

ModuleContext.displayName = 'RegistrationStoreDeduction';

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const RegistrationStoreDeduction = () => {
  const { t }: any = useDictionary({
    programId: '2081.C',
  });
  const [searchParams, setSearchParams] = useState({
    fromDate: new Date(),
    endDate: new Date(),
    storeIds: [],
    storeGroupIds: [],
    deductionTypes: [],
  });
  const [listDeduction, setListDeduction] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [
    { data: resApiListDeduction, loading: loadingApiListDeduction },
    apiListDeduction,
  ] = useAxios(
    {
      url: '/user/store-deduction',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const onUpdateListDeduction = (record: any) => {
    const existItem = listDeduction?.some(
      (o: any) =>
        o?.deductionDate == record?.deductionDate &&
        o?.lineId == record?.lineId,
    );
    let l: any = [];
    if (record?.type === 'delete') {
      l = [...listDeduction]?.filter(
        (o: any) =>
          o?.deductionDate != record?.deductionDate &&
          o?.lineId != record?.lineId,
      );
    } else {
      if (existItem) {
        l = [...listDeduction]?.map((o: any) => {
          if (
            o?.deductionDate == record?.deductionDate &&
            o?.lineId == record?.lineId
          )
            return record;
          return o;
        });
      } else {
        l = [...listDeduction];
        l.unshift(record);
      }
    }
    setListDeduction(l);
  };

  const value: any = {
    t,
    setModeView,
    modeView,
    searchParams,
    setSearchParams,
    listDeduction,
    apiListDeduction,
    onUpdateListDeduction,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListDeduction />;
      case 'create':
      case 'edit':
        return <FormDeduction />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiListDeduction?.data) {
      setListDeduction(resApiListDeduction?.data);
    }
  }, [resApiListDeduction]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiListDeduction} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default RegistrationStoreDeduction;
