/**
 * Update date: 07-06-2023
 * Screen 2081.2
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { USER_MEMBER_DUES } from 'constant/apiUrl';
import ArrayStore from 'devextreme/data/array_store';
import moment from 'moment';
import { dateFormatStr } from 'utils/format';

const MemberDetail = React.lazy(() => import('./MemberDetail'));
const ListMember = React.lazy(() => import('./ListMember'));
const CreateDue = React.lazy(() => import('./CreateDue'));
const EditDue = React.lazy(() => import('./EditDue'));

const defaultParamsMember = {
  memberName: '',
  approvals: [],
  duesKind: '',
  formStore: {
    duesKind: [],
  },
};
/**
 * create context
 */
const DepositContext = createContext({
  params: {},
  dataMember: [] || null,
  setParams: () => {},
  onUpdateListOrder: () => {},
});

DepositContext.displayName = 'DepositContext';

export const useDeposit = () => {
  const context = useContext(DepositContext);
  return context;
};

const Deposit = () => {
  const [params, setParams] = useState({
    ...defaultParamsMember,
    fromDate: moment().subtract(30, 'days'),
    toDate: new Date(),
  });
  const [dataMember, setDataMember] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const store: any = new ArrayStore({
    data: dataMember,
    key: 'memberId',
  });
  const [{ data: resMembers, loading: loadingMembers }, fetchMember] = useAxios(
    {
      url: USER_MEMBER_DUES,
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * on update
   *
   * @param {*} e
   */
  const onUpdate = e => {
    store.byKey(e?.memberId).then(
      dataItem => {
        store
          .update(e?.memberId, { ...dataItem, dues: dataItem.dues + e?.dues })
          .done(() => {})
          .fail(() => {});
      },
      error => {
        /* Handle the "error" here */
      },
    );
  };
  const value: any = {
    store,
    setModeView,
    modeView,
    params,
    setParams,
    dataMember,
    fetchMember,
    onUpdate,
    dateFormatStr,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListMember />;
      case 'create':
        return <CreateDue />;
      case 'edit':
        return <EditDue />;
      case 'view':
        return <MemberDetail />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resMembers?.data) {
      setDataMember(resMembers?.data);
    }
  }, [resMembers]);

  const loadingAll = loadingMembers;

  return (
    <DepositContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </DepositContext.Provider>
  );
};

export default Deposit;
