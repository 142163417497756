/**
 * Update date: 23-05-2023
 * Screen 2023.2
 */
import React, { useRef, useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import { useOrderInquiry } from '..';
import { uniqBy, isEqual } from 'lodash';
import DateOptions from 'app/components/DateOptions';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectUser from 'app/components/PopupCommon/PopupSelectUser';
import useDictionary from 'hooks/useDictionary';
const FormSearch = () => {
  const context: any = useOrderInquiry();
  const { params, setParams, refetchReceive } = context;
  const formRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  const [showModalCommonCode, setShowModalCommonCode] = useState(false);
  const [showModalProductGroup, setShowModalProductGroup] = useState(false);
  const [showModalProduct, setShowModalProduct] = useState(false);
  const [typeModalCommonCode, setTypeModalCommonCode] = useState<string>('');
  const [showModalVendor, setShowModalVendor] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);

  const [formData, setFormData] = useState<any>({
    ...params,
    vendorName: '',
    vendorData: [],
    vendorIds: [],
    status: '',
    userIds: [],
    userData: [],
    userName: '',
    largeCategoryCodes: [],
    largeCategoryData: [],
    largeCategoryName: '',
    mediumCategoryCodes: [],
    mediumCategoryData: [],
    mediumCategoryName: '',
    smallCategoryCodes: [],
    smallCategoryData: [],
    smallCategoryName: '',
    subCategoryCodes: [],
    subCategoryData: [],
    subCategoryName: '',
    productGroupIds: [],
    productGroupData: [],
    productGroupName: '',
    productIds: [],
    productData: [],
    productName: '',
  });
  const { t }: any = useDictionary({
    programId: '2023.2',
  });
  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const fixFormData = {
      ...formData,
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
    };
    delete fixFormData.vendorName;
    delete fixFormData.productGroupName;
    delete fixFormData.productName;
    delete fixFormData.largeCategoryName;
    delete fixFormData.mediumCategoryName;
    delete fixFormData.smallCategoryName;
    delete fixFormData.subCategoryName;
    delete fixFormData.userName;

    delete fixFormData.largeCategoryData;
    delete fixFormData.smallCategoryData;
    delete fixFormData.mediumCategoryData;
    delete fixFormData.subCategoryData;
    delete fixFormData.productGroupData;
    delete fixFormData.productData;
    delete fixFormData.vendorData;
    delete fixFormData.userData;

    refetchReceive({
      data: { ...fixFormData },
    });
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    const searchParams = {
      ...params,
      ...formData,
    };
    if (!isEqual(params, searchParams)) {
      setParams && setParams(searchParams);
    }
  };

  /**
   * on change custom field
   *
   * @param {*} data
   */
  const onChangeCustomizeField = (data: any) => {
    setFormData({ ...formData, ...data });
    setParams({ ...formData, ...data });
  };

  /**
   * on change common code
   *
   * @param {*} data
   * @param {*} typeModalCommonCode
   */
  const onChangeCommonCode = (data, typeModalCommonCode) => {
    const codes = [...data]?.map(o => o?.code);
    switch (typeModalCommonCode) {
      case 'largeCategory':
        onChangeCustomizeField({
          largeCategoryCodes: codes,
          largeCategoryName: '',
          largeCategoryData: data,
        });
        break;
      case 'smallCategory':
        onChangeCustomizeField({
          smallCategoryCodes: codes,
          smallCategoryName: '',
          smallCategoryData: data,
        });
        break;
      case 'mediumCategory':
        onChangeCustomizeField({
          mediumCategoryCodes: codes,
          mediumCategoryName: '',
          mediumCategoryData: data,
        });
        break;
      case 'subCategory':
        onChangeCustomizeField({
          subCategoryCodes: codes,
          subCategoryName: '',
          subCategoryData: data,
        });
        break;
      default:
        break;
    }
  };

  /**
   * on remove common code
   *
   * @param {*} code
   * @param {*} typeModalCommonCode
   */
  const onRemoveCommonCode = (code, typeModalCommonCode) => {
    switch (typeModalCommonCode) {
      case 'largeCategory':
        const largeData = [...formData?.largeCategoryData]?.filter(
          (o: any) => o?.code !== code,
        );
        const largeCodes = [...largeData]?.map(o => o?.code);

        onChangeCustomizeField({
          largeCategoryCodes: largeCodes,
          largeCategoryName: '',
          largeCategoryData: largeData,
        });
        break;

      case 'smallCategory':
        const smallData = [...formData?.smallCategoryData]?.filter(
          (o: any) => o?.code !== code,
        );
        const smallCodes = [...smallData]?.map(o => o?.code);

        onChangeCustomizeField({
          smallCategoryCodes: smallCodes,
          smallCategoryName: '',
          smallCategoryData: smallData,
        });
        break;

      case 'mediumCategory':
        const mediumData = [...formData?.mediumCategoryData]?.filter(
          (o: any) => o?.code !== code,
        );
        const mediumCodes = [...mediumData]?.map(o => o?.code);

        onChangeCustomizeField({
          mediumCategoryCodes: mediumCodes,
          mediumCategoryName: '',
          mediumCategoryData: mediumData,
        });
        break;
      case 'subCategory':
        const subData = [...formData?.subCategoryData]?.filter(
          (o: any) => o?.code !== code,
        );
        const subCodes = [...subData]?.map(o => o?.code);

        onChangeCustomizeField({
          subCategoryCodes: subCodes,
          subCategoryName: '',
          subCategoryData: subData,
        });
        break;
      default:
        break;
    }
  };

  /**
   * on submit common code
   *
   * @param {*} data
   */
  const onSubmitCommonCode = data => {
    let lData: any = [];
    switch (typeModalCommonCode) {
      case 'largeCategory':
        lData = [...formData?.largeCategoryData].concat(data);
        break;
      case 'mediumCategory':
        lData = [...formData?.mediumCategoryData].concat(data);
        break;
      case 'smallCategory':
        lData = [...formData?.smallCategoryData].concat(data);
        break;
      case 'subCategory':
        lData = [...formData?.subCategoryData].concat(data);
        break;
    }
    onChangeCommonCode(uniqBy(lData, 'code'), typeModalCommonCode);
    setShowModalCommonCode(!showModalCommonCode);
  };

  let commonCodeTitle = '';
  let commonCodeText = '';
  let commonCodeType = '';

  switch (typeModalCommonCode) {
    case 'largeCategory':
      commonCodeTitle = t('Large Category');
      commonCodeText = formData.largeCategoryName;
      commonCodeType = 'LC';
      break;
    case 'mediumCategory':
      commonCodeTitle = t('Medium Category');
      commonCodeText = formData.mediumCategoryName;
      commonCodeType = 'MC';
      break;
    case 'smallCategory':
      commonCodeTitle = t('Small Category');
      commonCodeText = formData.smallCategoryName;
      commonCodeType = 'SC';
      break;
    case 'subCategory':
      commonCodeTitle = t('Sub Category');
      commonCodeText = formData.subCategoryName;
      commonCodeType = 'UC';
      break;
    default:
      break;
  }

  /**
   * on change product group
   *
   * @param {*} data
   */
  const onChangeProductGroup = data => {
    const ids = [...data]?.map(o => o?.productGroupId);
    onChangeCustomizeField({
      productGroupIds: ids,
      productGroupName: '',
      productGroupData: data,
    });
  };

  /**
   * on remove product group
   *
   * @param {*} productGroupId
   */
  const onRemoveProductGroup = productGroupId => {
    const l = [...formData?.productGroupData]?.filter(
      (o: any) => o?.productGroupId !== productGroupId,
    );
    const ids = [...l]?.map(o => o?.productGroupId);
    onChangeCustomizeField({
      productGroupIds: ids,
      productGroupName: '',
      productGroupData: l,
    });
  };

  /**
   * on change product
   *
   * @param {*} data
   */
  const onChangeProduct = data => {
    const ids = [...data]?.map(o => o?.productId);
    onChangeCustomizeField({
      productIds: ids,
      productName: '',
      productData: data,
    });
  };

  /**
   * on remove product
   *
   * @param {*} productId
   */
  const onRemoveProduct = productId => {
    const l = [...formData?.productData]?.filter(
      (o: any) => o?.productId !== productId,
    );
    const ids = [...l]?.map(o => o?.productId);
    onChangeCustomizeField({
      productIds: ids,
      productName: '',
      productData: l,
    });
  };

  /**
   * on change vendor
   *
   * @param {*} data
   */
  const onChangeVendor = data => {
    const ids = [...data]?.map(o => o?.vendorId);
    onChangeCustomizeField({
      vendorIds: ids,
      vendorName: '',
      vendorData: data,
    });
  };

  /**
   * on remove vendor
   *
   * @param {*} vendorId
   */
  const onRemoveVendor = vendorId => {
    const l = [...formData?.vendorData]?.filter(
      (o: any) => o?.vendorId !== vendorId,
    );
    const ids = [...l]?.map(o => o?.vendorId);
    onChangeCustomizeField({
      vendorIds: ids,
      vendorName: '',
      vendorData: l,
    });
  };

  /**
   * on change user
   *
   * @param {*} data
   */
  const onChangeUser = data => {
    const ids = [...data]?.map(o => o?.userId);
    onChangeCustomizeField({
      userIds: ids,
      userName: '',
      userData: data,
    });
  };

  /**
   * on remove user
   *
   * @param {*} userId
   */
  const onRemoveUser = userId => {
    const l = [...formData?.userData]?.filter((o: any) => o?.userId !== userId);
    const ids = [...l]?.map(o => o?.userId);
    onChangeCustomizeField({
      userIds: ids,
      userName: '',
      userData: l,
    });
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, endDateValue) => {
    if (!fromDateValue || !endDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', endDateValue);
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, endDate) => onChangeDate(fromDate, endDate)}
        />
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          items={[
            {
              itemType: 'group',
              colCount: 2,
              items: [
                {
                  dataField: 'fromDate',
                  editorType: 'dxDateBox',
                  label: {
                    text: t('From Date'),
                  },
                  editorOptions: {
                    displayFormat: DateFormat,
                  },
                  isRequired:
                    !formData.fromDate && formData.endDate ? true : false,
                },
                {
                  dataField: 'endDate',
                  editorType: 'dxDateBox',
                  label: {
                    text: t('End Date'),
                  },
                  editorOptions: {
                    displayFormat: DateFormat,
                  },
                  isRequired:
                    !formData.endDate && formData.fromDate ? true : false,
                },
              ],
            },
            {
              label: {
                text: t('Vendor'),
              },
              dataField: 'vendorName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setShowModalVendor(!showModalVendor);
                },
                showClearButton: true,
                buttons: [
                  {
                    name: 'vendor0',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.vendorData?.[0]?.vendorName,
                      onClick: () =>
                        onRemoveVendor(formData?.vendorData?.[0]?.vendorId),
                      visible: !!formData?.vendorData?.[0]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'vendor1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.vendorData?.[1]?.vendorName,
                      onClick: () =>
                        onRemoveVendor(formData?.vendorData?.[1]?.vendorId),
                      visible: !!formData?.vendorData?.[1]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'vendorMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.vendorData?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formData?.vendorData?.length > 2,
                    },
                  },
                  {
                    name: 'vendorClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.vendorData?.length,
                      onClick: () => onChangeVendor([]),
                    },
                  },
                  {
                    name: 'vendorSearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => {
                        setShowModalVendor(!showModalVendor);
                      },
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Status') },
              dataField: 'status',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  { label: t('All'), value: '' },
                  { label: t('Pending'), value: '0' },
                  { label: t('Fixed'), value: '1' },
                ],
              },
            },
            {
              label: { text: t('Orderer') },
              dataField: 'userName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setShowModalUser(!showModalUser);
                },
                showClearButton: true,
                buttons: [
                  {
                    name: 'user1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.userData?.[0]?.name,
                      onClick: () =>
                        onRemoveUser(formData?.userData?.[0]?.userId),
                      visible: !!formData?.userData?.[0]?.userId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'user2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.userData?.[1]?.name,
                      onClick: () =>
                        onRemoveUser(formData?.userData?.[1]?.userId),
                      visible: !!formData?.userData?.[1]?.userId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'userMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.userData?.length - 2} ${t('more')}`,
                      visible: formData?.userData?.length > 2,
                    },
                  },
                  {
                    name: 'userClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.userData?.length,
                      onClick: () => onChangeUser([]),
                    },
                  },
                  {
                    name: 'userSearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowModalUser(!showModalUser),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={2}
          // onFieldDataChanged={onFieldDataChanged}
        />
      </>
    );
  };
  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'largeCategoryName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeModalCommonCode('largeCategory');
                  setShowModalCommonCode(!showModalCommonCode);
                },
                showClearButton: true,
                buttons: [
                  {
                    name: 'largeCategory1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.largeCategoryData?.[0]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.largeCategoryData?.[0]?.code,
                          'largeCategory',
                        ),
                      visible: !!formData?.largeCategoryData?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'largeCategory2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.largeCategoryData?.[1]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.largeCategoryData?.[1]?.code,
                          'largeCategory',
                        ),
                      visible: !!formData?.largeCategoryData?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'largeCategoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.largeCategoryData?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formData?.largeCategoryData?.length > 2,
                    },
                  },
                  {
                    name: 'largeCategoryClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.largeCategoryData?.length,
                      onClick: () => onChangeCommonCode([], 'largeCategory'),
                    },
                  },
                  {
                    name: 'largeCategorySearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => {
                        setTypeModalCommonCode('largeCategory');
                        setShowModalCommonCode(!showModalCommonCode);
                      },
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mediumCategoryName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeModalCommonCode('mediumCategory');
                  setShowModalCommonCode(!showModalCommonCode);
                },
                showClearButton: true,
                buttons: [
                  {
                    name: 'mediumCategory1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.mediumCategoryData?.[0]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.mediumCategoryData?.[0]?.code,
                          'mediumCategory',
                        ),
                      visible: !!formData?.mediumCategoryData?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'mediumCategory2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.mediumCategoryData?.[1]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.mediumCategoryData?.[1]?.code,
                          'mediumCategory',
                        ),
                      visible: !!formData?.mediumCategoryData?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'mediumCategoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.mediumCategoryData?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formData?.mediumCategoryData?.length > 2,
                    },
                  },
                  {
                    name: 'mediumCategoryClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.mediumCategoryData?.length,
                      onClick: () => onChangeCommonCode([], 'mediumCategory'),
                    },
                  },
                  {
                    name: 'mediumCategorySearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => {
                        setTypeModalCommonCode('mediumCategory');
                        setShowModalCommonCode(!showModalCommonCode);
                      },
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroupName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () =>
                  setShowModalProductGroup(!showModalProductGroup),
                showClearButton: true,
                buttons: [
                  {
                    name: 'productGroup1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.productGroupData?.[0]?.productGroupNm,
                      onClick: () =>
                        onRemoveProductGroup(
                          formData?.productGroupData?.[0]?.productGroupId,
                        ),
                      visible:
                        !!formData?.productGroupData?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'productGroup2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.productGroupData?.[1]?.productGroupNm,
                      onClick: () =>
                        onRemoveProductGroup(
                          formData?.productGroupData?.[1]?.productGroupId,
                        ),
                      visible:
                        !!formData?.productGroupData?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'productGroupMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.productGroupData?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formData?.productGroupData?.length > 2,
                    },
                  },
                  {
                    name: 'productGroupClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.productGroupData?.length,
                      onClick: () => onChangeProductGroup([]),
                    },
                  },
                  {
                    name: 'productGroupSearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () =>
                        setShowModalProductGroup(!showModalProductGroup),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smallCategoryName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeModalCommonCode('smallCategory');
                  setShowModalCommonCode(!showModalCommonCode);
                },
                showClearButton: true,
                buttons: [
                  {
                    name: 'smallCategory1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.smallCategoryData?.[0]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.smallCategoryData?.[0]?.code,
                          'smallCategory',
                        ),
                      visible: !!formData?.smallCategoryData?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'smallCategory2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.smallCategoryData?.[1]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.smallCategoryData?.[1]?.code,
                          'smallCategory',
                        ),
                      visible: !!formData?.smallCategoryData?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'smallCategoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.smallCategoryData?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formData?.smallCategoryData?.length > 2,
                    },
                  },
                  {
                    name: 'smallCategoryClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.smallCategoryData?.length,
                      onClick: () => onChangeCommonCode([], 'smallCategory'),
                    },
                  },
                  {
                    name: 'smallCategorySearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => {
                        setTypeModalCommonCode('smallCategory');
                        setShowModalCommonCode(!showModalCommonCode);
                      },
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategoryName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeModalCommonCode('subCategory');
                  setShowModalCommonCode(!showModalCommonCode);
                },
                showClearButton: true,
                buttons: [
                  {
                    name: 'subCategory1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.subCategoryData?.[0]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.subCategoryData?.[0]?.code,
                          'subCategory',
                        ),
                      visible: !!formData?.subCategoryData?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'subCategory2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.subCategoryData?.[1]?.name,
                      onClick: () =>
                        onRemoveCommonCode(
                          formData?.subCategoryData?.[1]?.code,
                          'subCategory',
                        ),
                      visible: !!formData?.subCategoryData?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'subCategoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.subCategoryData?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formData?.subCategoryData?.length > 2,
                    },
                  },
                  {
                    name: 'subCategoryClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.subCategoryData?.length,
                      onClick: () => onChangeCommonCode([], 'subCategory'),
                    },
                  },
                  {
                    name: 'subCategorySearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => {
                        setTypeModalCommonCode('subCategory');
                        setShowModalCommonCode(!showModalCommonCode);
                      },
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'productName',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowModalProduct(!showModalProduct),
                showClearButton: true,
                buttons: [
                  {
                    name: 'product1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.productData?.[0]?.productNm,
                      onClick: () =>
                        onRemoveProduct(formData?.productData?.[0]?.productId),
                      visible: !!formData?.productData?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'productGroup2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.productData?.[1]?.productNm,
                      onClick: () =>
                        onRemoveProduct(formData?.productData?.[1]?.productId),
                      visible: !!formData?.productData?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'productMore',
                    location: 'before',
                    options: {
                      text: `+ ${formData?.productData?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formData?.productData?.length > 2,
                    },
                  },
                  {
                    name: 'productClearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.productData?.length,
                      onClick: () => onChangeProduct([]),
                    },
                  },
                  {
                    name: 'productSearch',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowModalProduct(!showModalProduct),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
          // onFieldDataChanged={onFieldDataChanged}
        ></Form>
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {showModalVendor && (
        <PopupSelectVendor
          visible={showModalVendor}
          mode="multiple"
          onHiding={() => setShowModalVendor(!showModalVendor)}
          onSubmit={data => {
            const l = [...formData?.vendorData].concat(data);
            onChangeVendor(uniqBy(l, 'vendorId'));
            setShowModalVendor(!showModalVendor);
          }}
          text={formData?.vendorName}
        />
      )}
      {showModalUser && (
        <PopupSelectUser
          title={t('Orderer')}
          visible={showModalUser}
          mode="multiple"
          onHiding={() => setShowModalUser(!showModalUser)}
          onSubmit={data => {
            const l = [...formData?.userData].concat(data);
            onChangeUser(uniqBy(l, 'userId'));
            setShowModalUser(!showModalUser);
          }}
          text={formData?.userName}
        />
      )}
      {showModalProduct && (
        <PopupSelectProduct
          visible={showModalProduct}
          text={formData?.productName}
          onHiding={() => {
            setShowModalProduct(!showModalProduct);
          }}
          mode="multiple"
          onSubmit={data => {
            const l = [...formData?.productData].concat(data);
            onChangeProduct(uniqBy(l, 'productId'));
            setShowModalProduct(!showModalProduct);
          }}
        />
      )}
      {showModalProductGroup && (
        <PopupSelectProductGroup
          visible={showModalProductGroup}
          text={formData?.productGroupName}
          onHiding={() => {
            setShowModalProductGroup(!showModalProductGroup);
          }}
          mode="multiple"
          onSubmit={data => {
            const l = [...formData?.productGroupData].concat(data);
            onChangeProductGroup(uniqBy(l, 'productGroupId'));
            setShowModalProductGroup(!showModalProductGroup);
          }}
        />
      )}
      {showModalCommonCode && (
        <PopupSelectCommonCode
          visible={showModalCommonCode}
          text={commonCodeText}
          codeType={commonCodeType}
          mode="multiple"
          onHiding={() => setShowModalCommonCode(!showModalCommonCode)}
          onSubmit={data => onSubmitCommonCode(data)}
        />
      )}
    </div>
  );
};

export default FormSearch;
