/**
 * Update date: 29-05-2023
 * Screen 2044.2
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

const List = React.lazy(() => import('./List'));
const Product = React.lazy(() => import('./Product'));
const Detail = React.lazy(() => import('./Detail'));
const CancelStockAllocation = React.lazy(
  () => import('./CancelStockAllocation'),
);
/**
 * create context
 */
const OrderPickRequestManualContext = createContext({});

OrderPickRequestManualContext.displayName = 'OrderPickRequestManualContext';

export const useOrderPickRequestManual = () => {
  const context = useContext(OrderPickRequestManualContext);
  return context;
};

const OrderPickRequestManual = () => {
  const { t }: any = useDictionary({});
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    orderId: '',
    productType: '',
    isCancelStock: false,
  });
  const [dataListLocal, setDataListLocal] = useLocalStorage(
    '2044.2_dataList',
    {},
  );
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    fromDeliveryDate: null,
    endDeliveryDate: null,
    fromDeliveryTime: null,
    endDeliveryTime: null,
    productKeyword: null,
    dataList: {
      shippingMethod: [],
      mall: [],
      zone: [],
      productType: [],
      storeGroup: [],
      store: [],
      receivableCd: [],
    },
    shippingMethod: '',
    mall: '',
    zone: '',
    productType: '',
    storeGroup: '',
    store: '',
    receivableCd: '',
  });

  const [formInfoData, setFormInfoData] = useState<any>({
    sendDue: new Date(),
    waybillRule: '2',
    note: '',
    dataList: {
      sendUser: [],
    },
    sendUser: '',
  });

  const [dataCancelStock, setDataCancelStock] = useState([]);
  const [formSearchCancel, setFormSearchCancel] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    waybill: '',
    dataList: {
      shippingMethod: [],
      sendUser: [],
      allocation: [],
    },
    shippingMethod: '',
    sendUser: '',
    allocation: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        mall: dataListLocal?.mall || [],
        zone: dataListLocal?.zone || [],
        receivableCd: dataListLocal?.receivableCd || [],
        shippingMethod: dataListLocal?.shippingMethod || [],
      },
    }));
  }, [dataListLocal]);

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    formInfoData,
    setFormInfoData,
    //cancel stock
    dataCancelStock,
    setDataCancelStock,
    formSearchCancel,
    setFormSearchCancel,
    dataListLocal,
    setDataListLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'product':
        return <Product />;
      case 'detail':
        return <Detail />;
      case 'cancelStock':
        return <CancelStockAllocation />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <OrderPickRequestManualContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </OrderPickRequestManualContext.Provider>
  );
};

export default OrderPickRequestManual;
