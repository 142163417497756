import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import useDictionary from 'hooks/useDictionary';

const List = React.lazy(() => import('./List'));
const ViewDetail = React.lazy(() => import('./View'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2044_8_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Send Delivery Tracking
 *
 * @return {*}
 */
const M2044_8 = () => {
  // Get multi language
  const { t }: any = useDictionary({ programId: '2044.8' });
  // Initialize state
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    order: {
      orderId: null,
      wayBill: null,
      store: null,
      sendUser: null,
    },
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    allocateDate: new Date(),
    wayBill: '',
    taskType: '1',
    dataList: {
      storeGroup: [],
      store: [],
      deliveryDriver: [],
      allocation: [],
      receivableCd: [],
    },
    storeGroup: '',
    store: '',
    deliveryDriver: '',
    receiver: '',
    allocation: '',
    receivableCd: '',
  });

  // Api call
  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  // Api call send
  const [{ loading: loadingSend }, refetchSend] = useAxios(
    {
      url: '/core/send-delivery-tracking',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  // Values share with child component
  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    refetchSend,
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <ViewDetail />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel
          visible={loadingfetchData || loadingSend}
          message={loadingSend ? t('Sending...') : ''}
        />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2044_8;
