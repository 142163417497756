import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import React, { forwardRef, useState, useEffect } from 'react';
import { useModuleContext } from '..';

/**
 * View data component
 *
 * @return {*}
 */
const TableContent = () => {
  const contextRoot: any = useModuleContext();
  const {
    t,
    modeView,
    setModeView,
    DateFormat,
    AmountFormat,
    QtyFormat,
    listReferenceType,
  } = contextRoot || {};

  const { billId } = modeView?.data || {};
  const [details, setDetails] = useState([]);

  const [{ data: dataDetail, loading: loadingDetail }] = useAxios(
    {
      url: 'user/m2086_3',
      params: {
        billId,
      },
      method: 'GET',
    },
    {
      manual: billId ? false : true,
      autoCancel: true,
      useCache: false,
    },
  );

  useEffect(() => {
    if (!dataDetail?.data) return;
    const dataMapping = (dataDetail?.data || []).map(o => {
      if (o?.reference_tp == '1') {
        o.reference_qt = -(o?.reference_qt || 0);
        o.reference_ea = -(o?.reference_ea || 0);
        o.supply_amount = -(o?.supply_amount || 0);
        o.vat_amount = -(o?.vat_amount || 0);
        o.total_amount = -(o?.total_amount || 0);
      }

      return { ...o };
    });
    setDetails(dataMapping);
  }, [dataDetail?.data]);

  /**
   * Close View page
   *
   */
  const closeCancel = () => {
    setModeView({ type: 'list', data: null });
  };

  // Render column grid layout
  const columns = [
    {
      dataField: 'reference_tp',
      caption: t('Reference Type'),
      editorType: 'dxSelectbox',
      lookup: {
        dataSource: listReferenceType,
        displayExpr: 'name',
        valueExpr: 'type',
      },
    },
    {
      dataField: 'reference_id',
      caption: t('Reference ID'),
      alignment: 'left',
    },
    {
      dataField: 'reference_dt',
      caption: t('Reference Date'),
      alignment: 'left',
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'store_cd',
      caption: t('Store Code'),
      alignment: 'left',
    },
    {
      dataField: 'store_nm',
      caption: t('Store Name'),
      alignment: 'left',
    },
    {
      dataField: 'product_cd',
      caption: t('Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'product_nm',
      caption: t('Product Name'),
      alignment: 'left',
    },
    {
      dataField: 'option_nm',
      caption: t('Option'),
      alignment: 'left',
    },
    {
      dataField: 'quantity_per_pack',
      caption: t('Qty Per Pack'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'reference_qt',
      caption: t('Qty'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'reference_ea',
      caption: t('EA'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'supply_amount',
      caption: t('Supply Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'vat_amount',
      caption: t('Vat Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'total_amount',
      caption: t('Total Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingDetail} />
      <BreadCrumbPremium onClose={() => closeCancel()} />
      <DataGridFull
        dataSource={details}
        columns={columns}
        options={{
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift({
              location: 'before',
              template: 'totalCount',
            });
          },
          summary: {
            totalItems: [
              {
                column: 'reference_qt',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'reference_ea',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'total_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'reference_qt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'reference_ea',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'total_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </React.Suspense>
  );
};

export default forwardRef(TableContent);
