/**
 * Update date: 22-05-2023
 * Screen 2017.2
 */
import React, { useState, memo, useRef, useEffect } from 'react';
import Form, { Item, SimpleItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { Popup, ScrollView, TextBox } from 'devextreme-react';
import { isEqual } from 'lodash';
import { Template } from 'devextreme-react/core/template';
import useDictionary from 'hooks/useDictionary';
import './styles.scss';
import { useApp } from 'app';

interface ITextBoxes {
  param?: any;
  selectedRow?: any;
  visible?: boolean;
  onHiding?: () => void;
}

const TextBoxes = ({ param, selectedRow, visible, onHiding }: ITextBoxes) => {
  const { themePro }: any = useApp();
  const [formData, setFormData] = useState({
    apiDate: selectedRow?.apiDate,
    apiType: selectedRow?.apiType,
    logId: selectedRow?.logId,
    id: selectedRow?.id,
    apiUrl: selectedRow?.apiUrl,
    startDate: selectedRow?.startDate,
    endDate: selectedRow?.endDate,
    requestText: selectedRow?.requestText,
    responseText: selectedRow?.responseText,
    responseHeader: selectedRow?.responseHeader,
    errorText: selectedRow?.errorText,
    mallCode: selectedRow?.mallCode,
  });

  const formRef: any = useRef(null);

  const [textSearch, setTextSearch] = useState<any>('');

  const { t }: any = useDictionary({
    programId: '2017.2',
  });

  if (!isEqual(formData, selectedRow)) {
    setFormData(selectedRow);
  }

  /**
   * render highlight
   *
   * @param {*} string
   * @param {*} text
   */
  const highlight = (string, text) => {
    for (var j = 0; j < text.length; j++) {
      var innerHTML = string.innerHTML;
      if (text[j] != '') {
        var indx = [...innerHTML.matchAll(new RegExp(text[j], 'gi'))];
        var reversed = indx.reverse();
        for (var i = 0; i < reversed.length; i++) {
          var index = reversed[i].index;
          if (index >= 0) {
            innerHTML =
              innerHTML.substring(0, index) +
              "<span class='hightlight'>" +
              innerHTML.substring(index, index + text[j].length) +
              '</span>' +
              innerHTML.substring(index + text[j].length);
            string.innerHTML = innerHTML;
          }
        }
      }
    }
  };

  useEffect(() => {}, [highlight]);

  /**
   * on search
   *
   */
  const onSearch = () => {
    if (textSearch != ' ') {
      highlight(document.getElementById('requestText'), textSearch.split(' '));
      highlight(
        document.getElementById('responseHeader'),
        textSearch.split(' '),
      );
      highlight(document.getElementById('responseText'), textSearch.split(' '));
      highlight(document.getElementById('errorText'), textSearch.split(' '));
    }
  };

  /**
   * render request text
   *
   * @return {*}
   */
  const RequestText = () => {
    return (
      <ScrollView>
        <div className="targetForm" id="requestText">
          {formData?.requestText}
        </div>
      </ScrollView>
    );
  };
  /**
   * render response text
   *
   * @return {*}
   */
  const ResponseText = () => {
    return (
      <ScrollView>
        <div className="targetForm" id="responseText">
          {formData?.responseText}
        </div>
      </ScrollView>
    );
  };
  /**
   * render response header
   *
   * @return {*}
   */
  const ResponseHeader = () => {
    return (
      <ScrollView>
        <div className="targetForm1" id="responseHeader">
          {formData?.responseHeader}
        </div>
      </ScrollView>
    );
  };
  /**
   * render error text
   *
   * @return {*}
   */
  const ErrorText = () => {
    return (
      <ScrollView>
        <div className="targetForm1" id="errorText">
          {formData?.errorText}
        </div>
      </ScrollView>
    );
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <div className="">
        <ScrollView height={themePro ? '100%' : 830}>
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{formData?.logId}</span>
              <div>
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    paddingTop: '30px',
                    paddingBottom: '30px',
                    marginLeft: '50px',
                    alignContent: 'center',
                    display: 'flex',
                    justifyContent: 'right',
                    gap: 10,
                  }
            }
            className="button-wrap-content"
          >
            <TextBox
              width={themePro ? 400 : 'auto'}
              placeholder={t('Search Keywords...')}
              onValueChanged={e => setTextSearch(e?.value)}
              onEnterKey={onSearch}
              style={themePro ? { marginRight: 10 } : {}}
            />
            <Button icon="search" text={t('Search')} onClick={onSearch} />
          </div>
          <div className="body-padding-white readonly-bg-white">
            <Form
              ref={formRef}
              formData={formData}
              colCount={2}
              labelLocation="top"
              readOnly={true}
            >
              <Item
                label={{ text: t('Target') }}
                dataField="apiType"
                editorType="dxTextBox"
                colSpan={1}
              />
              <Item
                label={{ text: t('Log ID') }}
                dataField="logId"
                editorType="dxTextBox"
                colSpan={1}
              />
              <Item
                label={{ text: t('Request Text') }}
                dataField="requestText"
                colSpan={1}
                template={'RequestText'}
              >
                <Template name="RequestText" component={RequestText} />
              </Item>
              <Item
                label={{ text: t('Response Text') }}
                dataField="responseText"
                colSpan={1}
                template={'ResponseText'}
              >
                <Template name="ResponseText" component={ResponseText} />
              </Item>
              <Item
                label={{ text: t('Header Text') }}
                dataField="responseHeader"
                colSpan={1}
                template={'ResponseHeader'}
              >
                <Template name="ResponseHeader" component={ResponseHeader} />
              </Item>
              <Item
                label={{ text: t('Result Text') }}
                dataField="errorText"
                colSpan={1}
                template={'ErrorText'}
              >
                <Template name="ErrorText" component={ErrorText} />
              </Item>
            </Form>
          </div>
          {!themePro && (
            <div
              style={{
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Button text={t('Close')} icon="close" onClick={onHiding} />
            </div>
          )}
        </ScrollView>
      </div>
    );
  };
  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      showTitle={true}
      title={t('Log')}
      width={themePro ? '80%' : '90%'}
      height={themePro ? '100vh' : 'auto'}
      maxWidth={'90%'}
      contentRender={content}
    />
  );
};
export default memo(TextBoxes);
