/**
 * Update date: 2023-07-04
 * Screen 2081.G
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2081_G_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2081_G = () => {
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const value: any = {
    setData,
    data,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2081_G;
