/**
 * Update date: 15-05-2023
 * Screen 1044.3
 */
import React, { useRef, useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { uniqBy } from 'lodash';
import { useReportVendor } from '..';
import useDictionary from 'hooks/useDictionary';

const listTypeCommon = {
  vendorType: {
    codeType: 'VT',
    title: 'Search Member Vendor Type',
  },
};

const FormSearch = () => {
  const context: any = useReportVendor();
  const { refetchData } = context;
  const [formData, setFormData] = useState<any>({
    vendorName: '',
    use: [],
    vendorType: '',
    vendorRepresentative: '',
    formStore: {
      vendorType: [],
    },
  });
  const formRef: any = useRef(null);
  const [showPopupByType, setShowPopupByType] = useState('');

  const { t }: any = useDictionary({
    programId: '1044.3',
  });

  /**
   * Onsubmit form search
   *
   * @return {*} 
   */
  const onSubmit = async () => {
    if (formRef?.current && !formRef?.current?.instance?.validate()?.isValid)
      return;
    const data = {
      ...formData,
      vendorType: formData?.formStore?.vendorType.map((o: any) => o?.code),
    };
    delete data.formStore;
    refetchData({
      data,
    });
  };
  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*} 
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendorType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };
  const updateStore = (vendorType, data, key: any = false) => {
    const filterData = uniqBy(
      [...formData?.formStore?.[vendorType], ...data],
      key,
    );
    const newStore = { ...formData?.formStore, [vendorType]: filterData };
    setFormData(preState => ({
      ...preState,
      [vendorType]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  /**
   * remove store
   *
   * @param {*} vendorType
   * @param {*} index
   */
  const removeStore = (vendorType, index) => {
    let newStore;
    if (index === -1) {
      setFormData(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [vendorType]: [],
        },
      }));
    } else {
      const l = [...formData?.formStore[vendorType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setFormData(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [vendorType]: newStore,
        },
      }));
    }
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*} 
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          ref={formRef}
          items={[
            {
              label: {
                text: t('Vendor Name'),
              },
              dataField: 'vendorName',
              editorType: 'dxTextBox',
            },
            {
              dataField: 'vendorType',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setShowPopupByType('vendorType');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.formStore?.vendorType[0]?.name,
                      onClick: () => removeStore('vendorType', 0),
                      visible: !!formData?.formStore?.vendorType[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.formStore?.vendorType[1]?.name,
                      onClick: () => removeStore('vendorType', 1),
                      visible: !!formData?.formStore?.vendorType[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        formData?.formStore?.vendorType?.length - 2
                      } ${t('more')}`,
                      visible: formData?.formStore?.vendorType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.formStore?.vendorType?.length,
                      onClick: () => removeStore('vendorType', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('vendorType'),
                    },
                  },
                ],
              },
              label: {
                text: t('Vendor Type'),
              },
            },
            {
              label: { text: t('Use') },
              dataField: 'use',
              editorType: 'dxTagBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                items: [
                  {
                    label: t('Use'),
                    value: true,
                  },
                  {
                    label: t('Not Use'),
                    value: false,
                  },
                ],
              },
            },
            {
              label: {
                text: t('Representative'),
              },
              dataField: 'vendorRepresentative',
              editorType: 'dxTextBox',
            },
          ]}
          labelLocation="top"
          colCount={2}
        />
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
