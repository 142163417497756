/**
 * Update date: 09-06-2023
 * Program component
 */
import { iconC_P, iconE_P, iconJ_P, iconR_P } from 'images';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigation } from '.';
import { LayoutContext } from '../Layout';
import { uniqBy, cloneDeep } from 'lodash';

const classesBookMark: any = {
  iconArrow: {
    color: '#667085',
    fontSize: 22,
  },
  iconCoreMenu: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
};

const ProgramMenuBookmark = () => {
  const history = useHistory();
  const context: any = useNavigation();
  const {
    listProgram,
    programSeleted,
    setProgramSeleted,
    setHiddenProgramMenu,
  } = context;
  const layOutContext: any = useContext(LayoutContext);
  const { mainMenu, setListLastUsed } = layOutContext;
  const [dataMenu, setDataMenu] = useState<Array<any>>([]);

  useEffect(() => {
    setDataMenu(cloneDeep(listProgram?.list || []));
  }, [listProgram]);

  /**
   * render program type
   *
   * @param {*} type
   * @return {*}
   */
  const renderProgramType = type => {
    let icon = '';
    switch (type) {
      case 'E':
        icon = iconE_P;
        break;
      case 'C':
        icon = iconC_P;
        break;
      case 'R':
        icon = iconR_P;
        break;
      default:
        icon = iconJ_P;
        break;
    }
    return (
      <img
        src={icon}
        style={{ width: 13, marginRight: 5, marginTop: 5 }}
        alt="icon"
      />
    );
  };

  /**
   * on history push
   *
   * @param {*} e
   */
  const onHistoryPush = async e => {
    setListLastUsed(preState => {
      const list = [e].concat(preState);
      setHiddenProgramMenu(true);
      return uniqBy(list.slice(0, 6), 'programId');
    });
  };

  const onCollapseGroup = programId => {
    const dataFind = dataMenu?.find(m => m.programId === programId);
    if (dataFind) {
      dataFind.collapsed = !dataFind.collapsed;
    }
    setDataMenu([...dataMenu]);
  };

  const renderNotIncludeChilren = o => {
    return (
      <div
        className="sidebar-program-menu-list-detail"
        style={
          programSeleted === o?.programId ? { backgroundColor: '#344054' } : {}
        }
        onClick={() => {
          setProgramSeleted(o?.programId);
          onHistoryPush(o);
          let breakFor = false;
          for (let i = 0; i < mainMenu.length && !breakFor; i++) {
            const subMenu = mainMenu[i]?.children;
            for (let j = 0; j < subMenu.length && !breakFor; j++) {
              const programMenu = subMenu[j]?.children;
              for (let x = 0; x < programMenu.length && !breakFor; x++) {
                if (o?.programId === programMenu[x]?.programId) {
                  const url = [
                    '',
                    mainMenu[i]?.programId,
                    subMenu[j]?.programId,
                    o?.programId,
                  ].join('/');
                  history.push(url, {
                    programId1: mainMenu[i]?.programId,
                    programId2: subMenu[j]?.programId,
                    programId3: o?.programId,
                  });
                  breakFor = true;
                  break;
                }
              }
              if (breakFor) break;
            }
            if (breakFor) break;
          }
        }}
      >
        <span>{renderProgramType(o?.programType)}</span>
        <span className="sidebar-program-name">{o?.programName}</span>
        <span className="sidebar-program-id">{o?.programId}</span>
      </div>
    );
  };

  const renderIncludeChilren = (o, index) => {
    return (
      <>
        <div
          className={`nav-link-core`}
          onClick={() => onCollapseGroup(o?.programId)}
        >
          <div style={{ color: '#c5c4c4' }}>
            <span>{o?.programName}</span>
          </div>
          <i
            className={
              !o?.collapsed ? 'dx-icon-chevronup' : 'dx-icon-chevrondown'
            }
            style={{
              ...classesBookMark.iconArrow,
              ...classesBookMark.iconCoreMenu,
            }}
          />
        </div>
        <div
          className="nav-link-sub"
          style={
            !o?.collapsed
              ? { maxHeight: 1000, paddingLeft: 5 }
              : { maxHeight: 0, overflow: 'hidden', paddingLeft: 5 }
          }
        >
          {o?.children?.map((f, j) => (
            <div key={`${index}-${j}`}>{renderNotIncludeChilren(f)}</div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="sidebar-program-menu-submenu">{listProgram?.subMenu}</div>
      <div className="sidebar-program-menu-list">
        {dataMenu?.map((o, i) => (
          <div key={i}>
            {!o?.children?.length && renderNotIncludeChilren(o)}
            {o?.children?.length && renderIncludeChilren(o, i)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramMenuBookmark;
