/**
 * Update date: 17-05-2023
 * Screen 2011.5
 */
import React, { useRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useProductMaster } from '..';
import LoadPanel from 'app/components/LoadPanel';
import useFormatTime from '../../../../hooks/useFormatTime';

function ProductMasterTable(props) {
  const { DateTimeFormat } = useFormatTime();
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useProductMaster();
  const { t, productData, loadingProductData } = context;

  const columns: any = [
    {
      caption: t('Product'),
      alignment: 'center',
      items: [
        {
          dataField: 'product_id',
          caption: t('ID'),
          showWhenGrouped: true,
          visible: false,
          fixed: true,
        },
        {
          dataField: 'product_tp_nm',
          caption: t('Product Type'),
          showWhenGrouped: true,
        },
        {
          dataField: 'product_option_img',
          caption: t('Product Image'),
          alignment: 'center',
          cellRender: record => {
            const { product_option_img } = record?.data || {};
            return product_option_img ? (
              <div className="product-image-grid-custom">
                <img src={product_option_img} width={26} height={26} alt="" />
              </div>
            ) : null;
          },
        },
        {
          dataField: 'complex_id',
          caption: t('Complex ID'),
          alignment: 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'product_cd',
          caption: t('Product Code'),
          alignment: 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'product_nm',
          caption: t('Product'),
          showWhenGrouped: true,
        },
        {
          dataField: 'tax_nm',
          caption: t('Tax Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'maker',
          caption: t('Maker'),
          showWhenGrouped: true,
        },
        {
          dataField: 'marketable_size',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        {
          dataField: 'tradeTypeName',
          caption: t('Trade Type'),
          showWhenGrouped: true,
          allowFiltering: true,
        },
        {
          dataField: 'taxTypeName',
          caption: t('Tax Type'),
          showWhenGrouped: true,
          allowFiltering: true,
        },
        {
          dataField: 'tax_rate',
          caption: t('Tax Rate'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendor_nm_default',
          caption: t('Default Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'order_target_tp',
          caption: t('Order Target Type'),
          showWhenGrouped: true,
        },
        {
          dataField: 'bottle_nm',
          caption: t('Bottle'),
          showWhenGrouped: true,
        },
        {
          dataField: 'safety_stock',
          caption: t('Safety Stock'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'min_ctorder',
          caption: t('Min Center Order'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'min_order',
          caption: t('Minimum Order'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'max_order',
          caption: t('Maximum Order'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'origin',
          caption: t('Origin'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'product_order_tf',
          caption: t('Order'),
          editorType: 'dxSwitch',
          dataType: 'boolean',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'product_return_tf',
          caption: t('Return'),
          editorType: 'dxSwitch',
          dataType: 'boolean',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'product_online_tf',
          caption: t('Online'),
          editorType: 'dxSwitch',
          dataType: 'boolean',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'largecategory',
          caption: t('Large Category'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'mediumcategory',
          caption: t('Medium Category'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'smallcategory',
          caption: t('Small Category'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'subcategory',
          caption: t('Sub Category'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'stockAllocationTypeName',
          caption: t('Stock Allocation'),
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
    {
      caption: t('Option'),
      alignment: 'center',
      columns: [
        {
          dataField: 'warehouse_product_name',
          caption: t('Warehouse Product Name'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'option_cd',
          caption: t('Option Code'),
          alignment: 'center',
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'option_nm',
          caption: t('Option'),
          showWhenGrouped: true,
        },
        {
          dataField: 'package_tp_nm',
          caption: t('Package Type'),
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'option_use_tf',
          caption: t('Option Use'),
          editorType: 'dxSwitch',
          dataType: 'boolean',
          showWhenGrouped: true,
        },
        {
          dataField: 'quantity_per_pack',
          caption: t('Quantity Per Pack'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'supply_unit_price',
          caption: t('Supply Unit Price'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'deal_supply_unit_price',
          caption: t('Deal Unit Price'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'option_ctorder_tf',
          caption: t('Option Center Order'),
          editorType: 'dxSwitch',
          dataType: 'boolean',
          showWhenGrouped: true,
        },
        {
          dataField: 'final_vendor_name',
          caption: t('Final Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'final_purchase_unit_price',
          caption: t('Final Purchase Unit Price'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'qrcode_url',
          caption: t('QR Code'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'product_upc',
          caption: t('P Code'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'weight',
          caption: t('Weight'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'volume',
          caption: t('Volume'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'option_container_nm',
          caption: t('Option Center Order'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'regist_dm',
          caption: t('Register Datetime'),
          dataType: 'datetime',
          format: DateTimeFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'register_user_id',
          caption: t('Register User'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'register_program_cd',
          caption: t('Register ProgramID'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'modify_dm',
          caption: t('Modify Datetime'),
          dataType: 'datetime',
          format: DateTimeFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'modify_user_id',
          caption: t('Modify User'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'modify_program_cd',
          caption: t('Modify ProgramID'),
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
    {
      dataField: 'product_sku',
      caption: t('Product Sku'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'barcode',
      caption: t('Barcode'),
      showWhenGrouped: true,
      visible: false,
    },
  ];

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const exportFileName =
    'SMDC_2011.5 ProductMasterInquiry_' + moment().format('YYYYMMDD');

  return (
    <>
      <LoadPanel visible={loadingProductData} />
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={productData}
        storageKeyInquiry="2011_5_storage_v1"
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  disabled: !productData.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [{}],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default ProductMasterTable;
