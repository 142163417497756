/**
 * Update date: 31-05-2023
 * Screen 2046.7
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useAxios from 'axios-hooks';
import { Button, Form } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useFormatTime from 'hooks/useFormatTime';
import React, { useEffect, useRef, useState } from 'react';
import { dateFormat } from 'utils/format';
import { notification } from 'utils/notification';
import { useCenterReturn } from '..';
import useDictionary from 'hooks/useDictionary';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { useApp } from 'app';

function FormCreate() {
  const { themePro }: any = useApp();
  const context: any = useCenterReturn();
  const { modeView, setModeView, onAdd, onUpdate } = context || {};
  const { DateFormat } = useFormatTime();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const formRef: any = useRef(null);
  const [formData, setFormData] = useState({
    returnControlId: null,
    returnControlName: '',
    unitPriceType: '',
    regularUnitPriceTp: '0',
    regularReturnTf: true,
    use: true,
    salesStartDate: null,
    salesEndDate: null,
    returnStartDate: null,
    returnEndDate: null,
    returnControlNote: '',
    returnControlGroups: [],
  });
  const id = modeView?.id;
  const { t }: any = useDictionary({
    programId: '2046.7',
  });
  const [{ loading }, refetchDataDetail] = useAxios(
    {
      url: `/warehouse/return-control/${id}`,
      method: 'GET',
    },
    { manual: true, useCache: false, autoCancel: true },
  );
  const [{ loading: loadingCommon }, refetchDataCommon] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );
  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: `/warehouse/return-control`,
      method: 'PUT',
    },
    { manual: true, useCache: false, autoCancel: true },
  );
  /**
   * on field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'salesStartDate':
        setFormData(preState => ({ ...preState, salesStartDate: e?.value }));
        if (
          formData.salesEndDate &&
          e?.value &&
          e?.value > Number(formData.salesEndDate)
        )
          formRef?.current?.instance.updateData('salesEndDate', null);
        break;
      case 'salesEndDate':
        setFormData(preState => ({ ...preState, salesEndDate: e?.value }));
        if (
          formData.salesStartDate &&
          e?.value &&
          e?.value < Number(formData.salesStartDate)
        )
          formRef?.current?.instance.updateData('salesStartDate', null);
        break;
      case 'returnStartDate':
        setFormData(preState => ({ ...preState, returnStartDate: e?.value }));
        if (
          formData.returnEndDate &&
          e?.value &&
          e?.value > Number(formData.returnEndDate)
        )
          formRef?.current?.instance.updateData('returnEndDate', null);
        break;
      case 'returnEndDate':
        setFormData(preState => ({ ...preState, returnEndDate: e?.value }));
        if (
          formData.returnStartDate &&
          e?.value &&
          e?.value < Number(formData.returnStartDate)
        )
          formRef?.current?.instance.updateData('returnStartDate', null);
        break;
      case 'regularReturnTf':
        if (e?.value) {
          formRef?.current?.instance.updateData({
            ...formData,
            unitPriceType: '',
            regularUnitPriceTp: '0',
            salesStartDate: null,
            salesEndDate: null,
            returnStartDate: null,
            returnEndDate: null,
            regularReturnTf: e?.value,
          });
        } else {
          formRef?.current?.instance.updateData({
            ...formData,
            unitPriceType: '0',
            regularUnitPriceTp: '',
            salesStartDate: new Date(),
            salesEndDate: new Date(),
            returnStartDate: new Date(),
            returnEndDate: new Date(),
            regularReturnTf: e?.value,
          });
        }
        setFormData(preState => ({ ...preState, regularReturnTf: e?.value }));
        break;
      default:
        break;
    }
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const dataReq = {
      ...formData,
      salesStartDate: !formData?.regularReturnTf
        ? dateFormat({ date: formData.salesStartDate, type: 'date' })
        : '',
      salesEndDate: !formData?.regularReturnTf
        ? dateFormat({ date: formData.salesEndDate, type: 'date' })
        : '',
      returnStartDate: !formData?.regularReturnTf
        ? dateFormat({ date: formData.returnStartDate, type: 'date' })
        : '',
      returnEndDate: !formData?.regularReturnTf
        ? dateFormat({ date: formData.returnEndDate, type: 'date' })
        : '',
      unitPriceType: !formData?.regularReturnTf ? formData.unitPriceType : '',
      regularUnitPriceTp: formData?.regularReturnTf
        ? formData.regularUnitPriceTp
        : '',
    };
    const res = await refetchUpdate({
      data: dataReq,
    });
    notification({
      res,
    });
    if (res?.data?.status === '200') {
      if (!formData.returnControlId) {
        onAdd(res?.data?.data);
      } else {
        onUpdate(res?.data?.data);
      }
      setModeView({
        type: 'list',
      });
    }
  };
  /**
   * on cancel
   *
   */
  const onCancel = () => {
    setModeView({ type: 'list' });
  };
  /**
   * fetch data
   */
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const res = await refetchDataDetail();
        const resData = res?.data?.data || {};
        setFormData({
          ...resData,
          unitPriceType: !resData?.regularReturnTf
            ? resData?.unitPriceType
            : '',
          regularUnitPriceTp: resData?.regularReturnTf
            ? resData?.regularUnitPriceTp
            : '',
        });
      } else {
        const res = await refetchDataCommon({
          data: {
            codeType: 'SP',
          },
        });
        if (res?.data?.status === '200') {
          const returnControlGroups = res?.data?.data?.map(o => ({
            supplyUnitPriceGroup: o.code,
            supplyUnitPriceGroupName: o.name,
            returnRate: null,
          }));
          setFormData(preState => ({
            ...preState,
            returnControlGroups,
          }));
        }
      }
    };
    fetchData();
  }, [id]);

  const loadingAll = loading || loadingCommon || loadingUpdate;

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingAll} />
      <BreadCrumbPremium
        onSubmit={() => onSubmit()}
        onCancel={() => setShowPopupConfirm(true)}
      />
      <Form
        className="body-padding-white"
        ref={formRef}
        labelLocation="top"
        showColonAfterLabel={false}
        onFieldDataChanged={onFieldDataChanged}
        formData={formData}
        items={[
          {
            dataField: 'returnControlId',
            editorType: 'dxTextBox',
            label: {
              text: t('ID'),
            },
            editorOptions: {
              readOnly: true,
            },
          },
          {
            dataField: 'returnControlName',
            editorType: 'dxTextBox',
            label: {
              text: t('Return Control'),
            },
            isRequired: true,
          },
          {
            itemType: 'group',
            colCount: 4,
            items: [
              {
                dataField: 'use',
                editorType: 'dxSwitch',
                label: {
                  text: t('Use'),
                },
              },
              {
                dataField: 'regularReturnTf',
                editorType: 'dxSwitch',
                label: {
                  text: t('Regular Return'),
                },
              },
              {
                label: { text: t('Regular Unit Price') },
                dataField: 'regularUnitPriceTp',
                editorType: 'dxSelectBox',
                colSpan: 2,
                editorOptions: {
                  displayExpr: 'label',
                  valueExpr: 'value',
                  defaultValue: '0',
                  disabled: !formData?.regularReturnTf,
                  items: [
                    { label: t('Product Unit Price(Margin)'), value: '0' },
                    { label: t('Product Unit Price(No Margin)'), value: '1' },
                  ],
                },
              },
            ],
          },
          {
            label: { text: t('Unit Price') },
            dataField: 'unitPriceType',
            editorType: 'dxSelectBox',
            editorOptions: {
              displayExpr: 'label',
              valueExpr: 'value',
              defaultValue: '0',
              disabled: formData?.regularReturnTf,
              items: [
                { label: t('Final Unit Price'), value: '0' },
                { label: t('Max Unit Price'), value: '1' },
                { label: t('Min Unit Price'), value: '2' },
                { label: t('Avg Unit Price'), value: '3' },
              ],
            },
          },
          {
            itemType: 'group',
            colCount: 2,
            items: [
              {
                dataField: 'salesStartDate',
                editorType: 'dxDateBox',
                label: {
                  text: t('Sale Start Date'),
                },
                editorOptions: {
                  disabled: formData?.regularReturnTf,
                  displayFormat: DateFormat,
                },
                isRequired: true,
              },
              {
                dataField: 'salesEndDate',
                editorType: 'dxDateBox',
                label: {
                  text: t('Sale End Date'),
                },
                editorOptions: {
                  disabled: formData?.regularReturnTf,
                  displayFormat: DateFormat,
                },
                isRequired: true,
              },
            ],
          },
          {
            itemType: 'group',
            colCount: 2,
            items: [
              {
                dataField: 'returnStartDate',
                editorType: 'dxDateBox',
                label: {
                  text: t('Return Start Date'),
                },
                editorOptions: {
                  disabled: formData?.regularReturnTf,
                  displayFormat: DateFormat,
                },
                isRequired: true,
              },
              {
                dataField: 'returnEndDate',
                editorType: 'dxDateBox',
                label: {
                  text: t('Return End Date'),
                },
                editorOptions: {
                  disabled: formData?.regularReturnTf,
                  displayFormat: DateFormat,
                },
                isRequired: true,
              },
            ],
          },
          {
            dataField: 'returnControlNote',
            editorType: 'dxTextArea',
            label: {
              text: t('Note'),
            },
            colSpan: 2,
          },
        ]}
        colCount={2}
      />
      <br />
      <DataGridFull
        dataSource={formData?.returnControlGroups}
        columns={[
          {
            dataField: 'supplyUnitPriceGroupName',
            caption: t('Supply Unit Price Group'),
            alignment: themePro ? 'left' : 'center',
            allowEditing: false,
          },
          {
            dataField: 'returnRate',
            caption: t('Return Rate(%)'),
            dataType: 'number',
            alignment: themePro ? 'left' : 'center',
            cssClass: 'gridcell-editing',
            validationRules: [
              {
                type: 'range',
                max: '100',
                min: '0',
              },
            ],
          },
        ]}
        options={{
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          editing: {
            mode: 'cell',
            allowUpdating: true,
          },
          paging: {
            pageSize: 10,
          },
        }}
      />
      {!themePro && (
        <div
          style={{
            margin: '40px 0',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            text={t('Save')}
            icon="save"
            style={{ marginRight: 10 }}
            onClick={() => onSubmit()}
          />
          <Button
            text={t('Cancel')}
            icon="close"
            onClick={() => setShowPopupConfirm(true)}
          />
        </div>
      )}
      {showPopupConfirm && (
        <PopupConfirm
          visible={showPopupConfirm}
          content={t(`Do you want to cancel all changed?`)}
          onOk={onCancel}
          onHiding={() => setShowPopupConfirm(false)}
        />
      )}
    </React.Suspense>
  );
}

export default FormCreate;
