/**
 * Update date: 31-05-2023
 * Screen 2046.8
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { WAREHOUSE_RETURNABLE_AMOUNT_URL } from 'constant/apiUrl';
import LoadPanel from 'app/components/LoadPanel';

const ListReturnableAmount = React.lazy(() => import('./ListReturnableAmount'));

const defaultParamsReturnable = {
  returnControlId: null,
  returnControlData: {},
};
/**
 * create context
 */
const ReturnableAmountContext = createContext({
  vendor: {},
  commonCodeData: {},
  params: {},
  dataStockCount: [] || null,
  setParams: () => {},
});

ReturnableAmountContext.displayName = 'ReturnableAmountContext';

export const useReturnableAmount = () => {
  const context = useContext(ReturnableAmountContext);
  return context;
};

const ReturnableAmount = () => {
  const [params, setParams] = useState(defaultParamsReturnable);
  const [dataStockCount, setDataStockCount] = useState([]);

  const [
    { data: stockCountRes, loading: loadingForward },
    refetchReturnableAmount,
  ] = useAxios(
    {
      url: WAREHOUSE_RETURNABLE_AMOUNT_URL,
      method: 'POST',
    },
    { manual: true },
  );

  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const value: any = {
    setModeView,
    modeView,
    params,
    setParams,
    dataStockCount,
    refetchReturnableAmount,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (stockCountRes?.data) {
      setDataStockCount(stockCountRes?.data);
    }
  }, [stockCountRes]);

  const loadingAll = loadingForward;
  return (
    <ReturnableAmountContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <ListReturnableAmount />
      </React.Suspense>
    </ReturnableAmountContext.Provider>
  );
};

export default ReturnableAmount;
