/**
 * Update date: 07-06-2023
 * Screen 2081.7
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import React, { createContext, useContext, useEffect, useState } from 'react';
import ListSupplyUnitPriceGroupMarginRate from './ListSupplyUnitPriceGroupMarginRateManagement';
/**
 * create context
 */
const SupplyUnitPriceGroupMarginRateManagementContext = createContext({
  params: {},
  setParams: () => {},
  refetchReceive: () => {},
  onCancel: () => {},
  listData: {} || null,
  modeView: {},
  setModeView: () => {},
});

SupplyUnitPriceGroupMarginRateManagementContext.displayName =
  'SupplyUnitPriceGroupMarginRateManagementContext';

export const useSupplyUnitPriceGroupMarginRateManagement = () => {
  return useContext(SupplyUnitPriceGroupMarginRateManagementContext);
};

const SupplyUnitPriceGroupMarginRateManagement = () => {
  const { t } = useDictionary({ programId: '2081.7' });

  const [modeView, setModeView] = useState({ type: 'list' });

  const [dataStores, setDataStores] = useState<any>([]);

  const [columns, setColumns] = useState<any>([]);

  const [productTypes, setProductTypes] = useState<any>([]);

  const [shipmentTypes, setShipmentTypes] = useState<any>([]);

  const [loadingAll, setLoadingAll] = useState<boolean>(true);

  const { AmountFormat } = useFormat();

  const store: any = new ArrayStore({
    data: dataStores,
    key: 'supplyUnitPriceGroupCode',
  });
  const [{ data: dataMarginRate }, refetchReceive] = useAxios(
    {
      url: '/product/maginRate',
      method: 'GET',
    },
    {
      useCache: false,
      autoCancel: true,
    },
  );

  const [{}, executeGetCommonCode] = useAxios(
    {
      url: '/core/common-code',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return (
          <ListSupplyUnitPriceGroupMarginRate
            columns={columns}
            productTypes={productTypes}
            shipmentTypes={shipmentTypes}
          />
        );
      default:
        return <NotFoundPage />;
    }
  };

  const onCancel = async () => {
    setLoadingAll(true);
    await refetchReceive();
  };

  /**
   * on get list data
   *
   * @param {*} marginRateDataList
   * @return {*}
   */
  const handleListData = async marginRateDataList => {
    const supplyGroupRes = await executeGetCommonCode({
      data: {
        codeType: 'SP',
      },
    });

    const supplyGroupDataList = supplyGroupRes?.data?.data;

    if (!supplyGroupDataList) return;

    let makeDataStores: any = [];
    let columnFields = new Set<string>();

    const productRes = await executeGetCommonCode({
      data: {
        codeType: 'PT',
      },
    });

    const productDataList = productRes?.data?.data;

    const shipmentRes = await executeGetCommonCode({
      data: {
        codeType: 'FT',
      },
    });

    const shipmentDataList = shipmentRes?.data?.data;

    supplyGroupDataList.forEach(supplyGroupItem => {
      let dataStoreItem = {};
      dataStoreItem['supplyUnitPriceGroupCode'] = supplyGroupItem.code;
      dataStoreItem['supplyUnitPriceGroupName'] = supplyGroupItem.name;

      marginRateDataList.forEach(marginRateItem => {
        const {
          supplyUnitPriceGroup,
          productType,
          shipmentType,
          marginRate,
          shippingRate,
        } = marginRateItem;

        if (supplyGroupItem.code !== supplyUnitPriceGroup) return;

        const shipmentName = shipmentDataList
          .filter(it => it.code === shipmentType)
          .map(it => it.name)[0];

        dataStoreItem[`col_${shipmentName}_${shipmentType}_${productType}`] =
          marginRate;
        dataStoreItem[`col_S_${shipmentName}_${shipmentType}_${productType}`] =
          shippingRate;
      });

      makeDataStores.push(dataStoreItem);
    });

    // Make all columns for all products
    productDataList.forEach(product => {
      shipmentDataList.forEach(shipment => {
        columnFields.add(`productType_${product.code}`);
        columnFields.add(`shipmentType_${shipment.code}`);
        columnFields.add(`${product.name}_${product.code}`);
        columnFields.add(
          `col_${shipment.name}_${shipment.code}_${product.code}`,
        );
      });
    });

    let makeDataGridColumns: any = [];

    makeDataGridColumns.push({
      dataField: 'supplyUnitPriceGroupCode',
      visible: false,
    });

    makeDataGridColumns.push({
      dataField: 'supplyUnitPriceGroupName',
      caption: t('Supply Unit Price Group'),
      alignment: 'left',
      allowEditing: false,
      fixed: true,
    });
    const columnFieldsArr = Array.from(columnFields);

    const productTypes = columnFieldsArr
      .filter(item => item.startsWith('productType_'))
      .map(item => item.substring(12, item.length));

    setProductTypes(productTypes);

    const shipmentTypes = columnFieldsArr
      .filter(item => item.startsWith('shipmentType_'))
      .map(item => item.substring(13, item.length));

    setShipmentTypes(shipmentTypes);

    productTypes.forEach(pType => {
      const showFields = columnFieldsArr.filter(
        item =>
          !item.startsWith('productType_') &&
          !item.startsWith('shipmentType_') &&
          item.endsWith(`_${pType}`),
      );
      let header = showFields.filter(item => !item.startsWith('col_'))[0];
      header = header.substring(0, header.lastIndexOf(`_${pType}`));

      let initCol = {
        caption: t(header),
        alignment: 'center',
      };
      const initColMarginRate = {
        caption: t('Margin Rate'),
        alignment: 'center',
      };
      const initColShippingRate = {
        caption: t('Shipping Rate'),
        alignment: 'center',
      };

      let columnsM: any = [];
      let columnsS: any = [];

      showFields.forEach(field => {
        if (!field.startsWith('col_')) return;
        //Logic set default value is 0 when column has no data
        makeDataStores
          .filter(store => !store[field])
          .forEach(store => {
            store[field] = 0;
          });
        shipmentTypes.forEach(shipmentType => {
          let captionName = field.substring(4, field.length);
          captionName = captionName.substring(
            0,
            captionName.lastIndexOf(`_${pType}`),
          );
          if (!captionName.endsWith(`_${shipmentType}`)) return;

          captionName = captionName
            .substring(0, captionName.lastIndexOf(`_${shipmentType}`))
            .concat('(%)');
          const colDataInit = {
            dataField: field,
            caption: t(captionName),
            format: AmountFormat,
            dataType: 'number',
            cssClass: 'gridcell-editing',
            validationRules: [
              {
                type: 'numeric',
              },
              {
                type: 'range',
                max: 100,
                min: 0,
              },
            ],
          };
          columnsM.push(colDataInit);
          columnsS.push({
            ...colDataInit,
            dataField: field.replace('col_', 'col_S_'),
          });
        });
      });
      initColMarginRate['columns'] = columnsM;
      initColShippingRate['columns'] = columnsS;
      initCol['columns'] = [initColMarginRate, initColShippingRate];
      makeDataGridColumns.push(initCol);
    });
    setColumns(makeDataGridColumns);
    setDataStores(makeDataStores);
    setLoadingAll(false);
  };

  const value: any = {
    t,
    store,
    modeView,
    setModeView,
    refetchReceive,
    onCancel,
  };
  useEffect(() => {
    if (dataMarginRate?.data) handleListData(dataMarginRate?.data);
  }, [dataMarginRate]);
  return (
    <SupplyUnitPriceGroupMarginRateManagementContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </SupplyUnitPriceGroupMarginRateManagementContext.Provider>
  );
};

export default SupplyUnitPriceGroupMarginRateManagement;
