import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'store/features/auth/authSlice';
import useLocalStorage from './useLocalStorage';

function useBarcodeSound() {
  const [soundTp, setSoundTp] = useLocalStorage('soundType', 'male_a');
  const [soundTypeState, setSoundTypeState] = useState(soundTp);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [dataUrl, setDataUrl] = useState<any>({});

  const [{ loading: loadingVoice }, refetchVoice] = useAxios(
    {
      url: '/core/system-resource',
      method: 'GET',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  useEffect(() => {
    if (!isLoggedIn || !soundTypeState) return;
    const getVoice = async () => {
      const res = await refetchVoice({
        params: {
          resource_group: 'voice',
          resource_tp: soundTypeState,
        },
      });
      if (res?.data?.status === '200') {
        const resData = res?.data?.data || [];
        const dataMaping: any = {};
        resData.forEach((o, i) => {
          dataMaping[o?.id?.resource_cd || ''] = new Audio(
            o?.resource_url || '',
          );
        });
        setDataUrl(dataMaping);
      }
    };
    getVoice();
  }, [isLoggedIn, soundTypeState, refetchVoice]);

  const setSoundType = code => {
    setSoundTp(code);
    setSoundTypeState(code);
  };

  const getPlay = code => {
    dataUrl[`${code}`]?.play();
  };

  const s = resourceCd => {
    if (+resourceCd > 30) {
      resourceCd = 'next';
    }
    getPlay(resourceCd);
  };
  return {
    s,
    soundTypeState,
    setSoundType,
    loadingVoice,
  };
}

export default useBarcodeSound;
