import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import React, { useState, useRef } from 'react';
import { uniqBy } from 'lodash';
import Collapse from 'app/components/Collapse';
import useDictionary from 'hooks/useDictionary';
import { useStoreContext } from '..';
import PopupSelectStoreGroup from 'app/components/PopupCommon/PopupSelectStoreGroup';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import useFormatTime from 'hooks/useFormatTime';
import DateOptions from 'app/components/DateOptions';
import moment from 'moment';

interface IFormSearchProps {
  onSearch?: any;
}

const listTypeCommon = {
  unitPrice: {
    codeType: 'SP',
    title: 'Supply Unit Price Group',
  },
  productTypes: {
    codeType: 'PT',
    title: 'Product Type',
  },
};

/**
 * Form Search Component
 *
 * @param {IFormSearchProps} { onSearch }
 * @return {*}
 */
const FormSearch = ({ onSearch }: IFormSearchProps) => {
  const { t }: any = useDictionary({});

  const [showPopupByType, setShowPopupByType] = useState('');
  const formRef: any = useRef(null);
  const context: any = useStoreContext();
  const {
    formData,
    setFormData,
    formStore,
    setFormStore,
    refetchDataPrd,
    tapName,
  } = context;
  const { DateFormat } = useFormatTime();

  /**
   * Search data
   *
   * @return {*}
   */
  function onSubmit() {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;

    const searchParams = {
      store: formStore.store?.map(o => o?.storeNo),
      storeGroup: formStore.storeGroup?.map(o => o?.storeGroupId),
      unitPrice: formStore.unitPrice?.map(o => o?.code),
      productTypes: formStore.productTypes?.map(o => o?.code),
      receivableCd: formStore.receivableCd.map((o: any) => o.code),
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
    };
    if (tapName === t('Product')) {
      refetchDataPrd({ data: searchParams });
    } else {
      onSearch(searchParams);
    }
  }

  /**
   * Remote data input
   *
   * @param {*} varNm
   * @param {*} index
   */
  const removeStore = (varNm, index) => {
    let newStore;
    if (index === -1) {
      setFormStore({ ...formStore, [varNm]: [] });
    } else {
      const l = [...formStore[varNm]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setFormStore({ ...formStore, [varNm]: newStore });
    }
  };

  /**
   * Update data input from popup
   *
   * @param {*} varNm
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (varNm, data, key: any = false) => {
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(formStore?.[varNm] || []), ...data], key);
    }
    const newStore = { ...formStore, [varNm]: filterData };
    setFormData({ ...formData, [varNm]: '' });
    setFormStore({ ...newStore });
    setShowPopupByType('');
  };

  /**
   * Generate type dxTextBox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {number} [numberShowMore=2]
   * @param {boolean} [readOnly=false]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    numberShowMore = 2,
    readOnly = false,
  ): any => {
    const dataList = formStore?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });

    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      editorOptions: {
        readOnly: readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * Render list popup
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'productTypes':
      case 'unitPrice':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      case 'storeGroup':
        return (
          <PopupSelectStoreGroup
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('storeGroup', o, 'storeGroupId')}
          />
        );
      case 'store':
        return (
          <PopupSelectStore
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('store', o, 'storeNo')}
          />
        );
      case 'receivableCd':
        return (
          <PopupSelectCommonCode
            text={formData?.[popupType]}
            mode="multiple"
            codeType="RC"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('receivableCd', o, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Onchange date when choose date option
   *
   * @param {*} e
   */
  const onFieldDataChange = e => {
    if (e?.dataField == 'fromDate' || e?.dataField == 'endDate') {
      setFormData({
        ...formData,
        fromDate: formData?.fromDate
          ? moment(formData?.fromDate).format('YYYY-MM-DD')
          : null,
        endDate: formData?.endDate
          ? moment(formData?.endDate).format('YYYY-MM-DD')
          : null,
      });
    }

    switch (e?.dataField) {
      case 'fromDate':
        if (formData.endDate && e?.value && e?.value > formData.endDate) {
          setFormData({
            ...formData,
            endDate: null,
          });
        }
        break;
      case 'endDate':
        if (formData.fromDate && e?.value && e?.value < formData.fromDate) {
          setFormData({
            ...formData,
            fromDate: null,
          });
        }
        break;
      default:
        break;
    }
  };

  /**
   * Onchange date when choose date option
   *
   * @param {*} fromDateValue
   * @param {*} endDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, endDateValue) => {
    if (!fromDateValue || !endDateValue) return;
    setFormData({
      ...formData,
      fromDate: fromDateValue,
      endDate: endDateValue,
    });
  };

  /**
   * Search content
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, endDate) => onChangeDate(fromDate, endDate)}
        />
        <Form
          ref={formRef}
          formData={formData}
          readOnly={false}
          showColonAfterLabel={false}
          colCount={4}
          onFieldDataChanged={onFieldDataChange}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              label: { text: t('To Date') },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
          ]}
          labelLocation="top"
        />
      </>
    );
  };

  /**
   * Advanced Search content
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          items={[
            generateDxTextBox('Store Group', 'storeGroup', 'storeGroupName'),
            generateDxTextBox('Store', 'store', 'storeNm'),
            generateDxTextBox('Supply Unit Price', 'unitPrice', 'name'),
            generateDxTextBox(
              'Product Type',
              'productTypes',
              'name',
              2,
              formData?.isReadOnlyProductTp,
            ),
            generateDxTextBox('Receivable Group', 'receivableCd', 'name'),
          ]}
          onFieldDataChanged={onFieldDataChange}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
