/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';

interface IViewTaxInvoiceProps {
  visible?: boolean;
  onHiding?: () => void;
  data: number;
}

function PopupViewTaxInvoice({
  visible,
  onHiding,
  data,
}: IViewTaxInvoiceProps) {
  const { t }: any = useDictionary({ programId: '2043.0' });
  const { DateFormat } = useFormatTime();
  const { QtyFormat, AmountFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const [dataProduct, setDataProduct] = useState<any>([]);

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, useCache: false, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!data) return;
    const getData = async () => {
      const res = await refetchData({
        url: 'user/m2043_0/send-tax-invoice/detail',
        method: 'GET',
        params: { billId: data },
      });
      if (res?.data?.status === '200') {
        setDataProduct(res?.data?.data || []);
      } else {
        notification({ res });
      }
    };
    getData();
  }, [data]);

  const columns = [
    {
      dataField: 'reference_id',
      caption: t('Reference ID'),
      alignment: 'left',
    },
    {
      dataField: 'reference_dt',
      caption: t('Reference Date'),
      alignment: 'left',
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'store_cd',
      caption: t('Store Code'),
      alignment: 'left',
    },
    {
      dataField: 'store_nm',
      caption: t('Store Name'),
      alignment: 'left',
    },
    {
      dataField: 'product_cd',
      caption: t('Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'product_nm',
      caption: t('Product Name'),
      alignment: 'left',
    },
    {
      dataField: 'option_nm',
      caption: t('Option'),
      alignment: 'left',
    },
    {
      dataField: 'quantity_per_pack',
      caption: t('Qty Per Pack'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
      width: 90,
    },
    {
      dataField: 'reference_qt',
      caption: t('Qty'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
      width: 90,
    },
    {
      dataField: 'reference_ea',
      caption: t('EA'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
      width: 90,
    },
    {
      dataField: 'supply_amount',
      caption: t('Supply Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'vat_amount',
      caption: t('Vat Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'total_amount',
      caption: t('Total Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading} />
          <div className="modal-popup-header">
            <span className="title-page">
              {t('Issue of sales tax invoice')}
            </span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
          <DataGridFull
            columns={columns}
            ref={dataGridRef}
            dataSource={dataProduct}
            fixedLeft={2}
            fixedRight={3}
            options={{
              height: isMobile ? 'calc(80vh - 200px)' : 'calc(100vh - 140px)',
              columnAutoWidth: true,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              summary: {
                totalItems: [
                  {
                    column: 'reference_qt',
                    summaryType: 'sum',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'reference_ea',
                    summaryType: 'sum',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'supply_amount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'vat_amount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'total_amount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                ],
                groupItems: [
                  {
                    summaryType: 'count',
                    displayFormat: '{0}',
                  },
                  {
                    column: 'reference_qt',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'reference_ea',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'supply_amount',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'vat_amount',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'total_amount',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                ],
              },
            }}
          />
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Zone')}
      contentRender={content}
      height={'100vh'}
      width={isMobile ? '96%' : '75vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupViewTaxInvoice;
