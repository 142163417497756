import { Link, useLocation } from 'react-router-dom';
import { useLayout } from '../Layout';
import useDictionary from 'hooks/useDictionary';

const NavLeft = () => {
  const layoutContext: any = useLayout();
  const { mainMenu } = layoutContext;
  const location: any = useLocation;
  const programId1 = location?.state?.programId1;
  const { t } = useDictionary({});

  return (
    <ul className="nav-left">
      {mainMenu.map((o: any) => (
        <Link
          to={{
            pathname: '/',
            state: {
              nomarlMenu: true,
              programId1: o.programId,
              // programId2: o?.children?.[0].programId,
              // programId3: o?.children?.[0].children?.[0]?.programId,
            },
          }}
          className={`nav-item ${
            o?.programId == programId1 && layoutContext?.subMenu?.length
              ? 'active'
              : ''
          }`}
          key={o?.programId}
        >
          <div className="nav-link">
            {o.programIcon ? (
              <img src={o.programIcon} alt="icon" />
            ) : (
              <i className={`icon-ring`} />
            )}
            <span>{t(o?.programName)}</span>
          </div>
        </Link>
      ))}
    </ul>
  );
};

export default NavLeft;
