/**
 * Create date: 2024-06-20
 * Screen 2082.E
 */

import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import { Button } from 'devextreme-react';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { memo, useRef } from 'react';
import ChangeCommissionMargin from './ChangeCommissionMargin';
import useAxios from 'axios-hooks';
import { notification } from 'utils/notification';
import { dateFormatStr } from 'utils/format';

const SummaryTab = ({ t, store, formSearchRef }) => {
  const dataGridRef: any = useRef(null);
  const { QtyFormat, AmountFormat } = useFormat();
  const { DateFormat } = useFormatTime();
  const commissionUpdateRef: any = useRef(null);
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const onEditCommission = async data => {
    const requestData = {
      fromDate: dateFormatStr(data?.minDate),
      endDate: dateFormatStr(data?.maxDate),
      vendorId: data?.vendorId,
      zoneId: data?.zoneId,
      taxTp: data?.taxTp,
      manufacturer: data?.manufacturer,
      commissionRate: data?.newCommission,
    };

    const res = await refetchData({
      url: `/user/m2082_e/summary`,
      method: 'PUT',
      data: requestData,
    });
    notification({ res });
    if (res?.data?.status === '201') {
      formSearchRef?.current?.onSearch?.();
    }
  };

  const columns = [
    {
      dataField: 'vendorNm',
      caption: t('Vendor'),
      alignment: 'left',
      groupIndex: 0,
    },
    {
      dataField: 'manufacturerNm',
      caption: t('Manufacturer'),
      alignment: 'left',
    },
    {
      dataField: 'zoneNm',
      caption: t('Zone'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'taxTpNm',
      caption: t('Taxation Classification'),
    },
    {
      dataField: 'minDate',
      caption: t('From (Date)'),
      dataType: 'date',
      format: DateFormat,
      alignment: 'left',
    },
    {
      dataField: 'maxDate',
      caption: t('To (Date)'),
      dataType: 'date',
      format: DateFormat,
      alignment: 'left',
    },
    {
      dataField: 'suppCommision',
      caption: t('Commission (Margin)'),
      alignment: 'right',
      format: '##0.##',
      dataType: 'number',
      allowEditing: false,
      cellRender: (record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <span>{record?.value}</span>
            <Button
              icon="edit"
              stylingMode="text"
              className="btn-edit-icon-purple"
              onClick={() =>
                commissionUpdateRef?.current?.onOpen?.({
                  ...record?.data,
                  currentCommission: record?.value,
                })
              }
            />
          </div>
        );
      },
    },
    {
      dataField: 'saleQt',
      caption: t('Sale Qty'),
      alignment: 'right',
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'totalSaleAmount',
      caption: t('Total Sales Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'discountAmount',
      caption: t('Brand Coupon Discount Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'productSaleAmount',
      caption: t('Product Sales Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'saleCommisionAmount',
      caption: t('Sales Commission Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'receiveAmount',
      caption: t('Scheduled Payment Amount(Including VAT)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'supplyAmount',
      caption: t('Scheduled Payment Amount (Supply Price)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'vatAmount',
      caption: t('Scheduled Payment Amount (VAT)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  /**
   * Export excel gridview
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        columns={columns}
        fixedLeft={2}
        fixedRight={0}
        isShowZeroNumber
        options={{
          columnAutoWidth: true,
          groupPanel: {
            visible: true,
          },
          export: {
            fileName: `M2082.E: Report on Specific Purchase and Sales (Summary)_${moment().format(
              'YYYYMMDD',
            )}`,
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export To Excel'),
                  onClick: () => exportExcel(),
                  disabled: !store?._array?.length,
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                column: 'saleQt',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalSaleAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'productSaleAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'saleCommisionAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receiveAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vatAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'saleQt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalSaleAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'productSaleAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'saleCommisionAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receiveAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vatAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
      <ChangeCommissionMargin
        ref={commissionUpdateRef}
        onOk={onEditCommission}
      />
      <LoadPanel visible={loading} />
    </React.Suspense>
  );
};

export default memo(SummaryTab);
