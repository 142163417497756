/**
 * Update date: 07-06-2023
 * Screen 2081.9
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import React, { forwardRef } from 'react';
import { useMemberBondBalanceInquiry } from '..';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';

function ListDirectDeliveryEmptyBottle(props, ref) {
  const { themePro }: any = useApp();
  const context: any = useMemberBondBalanceInquiry();
  const { t, storeMember, setModeView } = context || {};
  const { AmountFormat } = useFormat();

  /**
   * render detail
   *
   * @param {*} record
   * @return {*}
   */
  const renderDetail = record => {
    const {
      receivableCd,
      receivableCdName,
      storeId,
      storeNm,
      loanlimitamount,
      bondingbalace,
      avaiableBalance,
    } = record?.data || {};
    if (!storeId) return;
    return (
      <div
        onClick={() => {
          setModeView({
            type: 'view',
            receivableCd: {
              code: receivableCd,
              name: receivableCdName,
            },
            storeId,
            storeNm,
            loanlimitamount,
            bondingbalace,
            avaiableBalance,
          });
        }}
        className="cell-link"
      >
        {t('Detail')}
      </div>
    );
  };

  const columns: any = [
    {
      dataField: 'storeId',
      caption: t('ID'),
      alignment: 'left',
    },
    {
      dataField: 'receivableCdName',
      caption: t('Receivable Group'),
      alignment: 'left',
    },
    {
      dataField: 'storeTpNm',
      caption: t('Type'),
      alignment: 'left',
    },
    {
      dataField: 'storeCd',
      caption: t('Store Code'),
      alignment: 'left',
    },
    {
      dataField: 'storeNm',
      caption: t('Store Name'),
      alignment: 'left',
    },
    {
      dataField: 'bussinessNumber',
      caption: t('Business Number'),
      alignment: 'left',
    },
    {
      dataField: 'supplyUnitPriceGroupnm',
      caption: t('Supply Unit Price Group'),
      alignment: 'left',
    },
    {
      dataField: 'representative',
      caption: t('Representative'),
      alignment: 'left',
    },
    {
      dataField: 'mobile',
      caption: t('Mobile No'),
      alignment: 'left',
    },
    {
      dataField: 'phone',
      caption: t('Phone No'),
      alignment: 'left',
    },
    {
      dataField: 'useTf',
      caption: t('Use'),
      dataType: 'boolean',
      editorType: 'dxSwitch',
    },
    {
      dataField: 'loanlimitamount',
      caption: t('Loan Limit'),
      dataType: 'number',
      format: AmountFormat,
      visible: false,
    },
    {
      dataField: 'bondingbalace',
      caption: t('Bond Balance'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'avaiableBalance',
      caption: t('Available Balance'),
      dataType: 'number',
      format: AmountFormat,
      visible: false,
    },
    {
      caption: t('Action'),
      alignment: themePro ? 'left' : 'center',
      fixed: true,
      fixedPosition: 'right',
      allowSearch: false,
      cellRender: renderDetail,
    },
  ];

  const loadingAll = false;

  return (
    <div>
      <LoadPanel visible={loadingAll} />
      <FormSearch />
      <DataGridFull
        ref={ref}
        storageKeyInquiry
        dataSource={storeMember}
        columns={columns}
        options={{
          key: 'memberId',
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift({
              location: 'before',
              template: 'totalCount',
            });
          },
          summary: {
            totalItems: [
              {
                name: 'loanLimit',
                column: 'loanlimitamount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'bondingBalace',
                column: 'bondingbalace',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'availableBalance',
                column: 'avaiableBalance',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
            ],
          },
          selection: {
            mode: 'single',
          },
        }}
      />
    </div>
  );
}

export default forwardRef(ListDirectDeliveryEmptyBottle);
