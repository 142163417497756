const arrayKey = ['+', '-', '.', '(', ')', 'Delete', 'Backspace'];
export const restrictKeyboardPhone = (e: any) => {
  const { keyCode, key, ctrlKey, shiftKey } = e?.event;
  if (
    (keyCode >= 48 && keyCode <= 57 && !shiftKey) ||
    (keyCode >= 96 && keyCode <= 105) ||
    (keyCode === 65 && ctrlKey) ||
    arrayKey.includes(key) || keyCode === 9
  ) {
    return;
  }
  e?.event.preventDefault();
};
