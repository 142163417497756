/**
 * Update date: 12-05-2023
 * Screen 1042.3
 */

import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FormCreate from './FormCreate';
import TreeViewList from './TreeViewList';
import { useApp } from 'app';

// create context
const CategoryRegistrationContext = createContext({
  refetchData: () => {},
  loadingData: false,
  dataInventory: [],
});

CategoryRegistrationContext.displayName = 'CategoryRegistrationContext';

export const useCategoryRegistration = () => {
  const context = useContext(CategoryRegistrationContext);
  return context;
};

function CategoryRegistration(props) {
  const { themePro }: any = useApp();
  const treeViewCategoryRef: any = useRef(null);
  const formRef: any = useRef(null);
  const [treeList, setTreeList] = useState<any>([]);
  const [isCreatedCategory, setIsCreatedCategory] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({
    type: '',
    data: null,
  });

  const [{ loading }, refetchData] = useAxios({}, { manual: true });

  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: '/core/program/update_order',
      method: 'POST',
    },
    { manual: true },
  );

  /**
   * convert data
   *
   * @param {*} data
   * @return {*}
   */
  const formatData = data => {
    return data
      .map((o: any) => {
        let codeTypeParent = '';
        switch (o?.codeType) {
          case 'MC':
            codeTypeParent = 'LC';
            break;
          case 'SC':
            codeTypeParent = 'MC';
            break;
          case 'UC':
            codeTypeParent = 'SC';
            break;
          default:
            break;
        }
        const item = {
          ...o,
          id: `${o?.codeType}_${o?.code}`,
          parentId:
            o?.parentCd && codeTypeParent
              ? `${codeTypeParent}_${o?.parentCd}`
              : null,
        };
        return item;
      })
      .sort((a, b) => {
        return a?.orders - b?.orders;
      });
  };

  /**
   * on update
   *
   * @param {*} data
   */
  const onUpdate = data => {
    if (selected?.type === 'edit') {
      const cateIndex = treeList.findIndex(o => o?.id === data?.id);
      setTreeList(preState => {
        preState.splice(cateIndex, 1, {
          ...data,
        });
        return [...preState];
      });
    } else {
      const cateCreateIndex = treeList.findIndex(o => o?.isCreate);
      setTreeList(preState => {
        preState.splice(cateCreateIndex, 1, {
          ...data,
        });
        return [...preState];
      });
    }

    setSelected({ type: '', data: null });
    setIsCreatedCategory(false);
    setTimeout(() => {
      treeViewCategoryRef?.current?.instance?.selectItem(data?.id);
      treeViewCategoryRef?.current?.instance?.scrollToItem(data?.id);
    }, 500);
  };

  /**
   * on delete
   *
   * @param {*} data
   */
  const onDelete = data => {
    setTreeList(preState => preState.filter((o: any) => o?.id !== data?.id));
    setSelected({ type: '', data: null });
    setIsCreatedCategory(false);
  };

  /**
   * fetch data
   */

  const fetchData = useCallback(async () => {
    const res = await refetchData({
      url: '/core/category-product',
      method: 'GET',
    });
    if (res?.data?.status === '200') {
      const { data } = res?.data;
      const dataFormat = formatData(data);
      setTreeList(dataFormat);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const value: any = {
    treeList,
    setTreeList,
    isCreatedCategory,
    setIsCreatedCategory,
    selected,
    setSelected,
    refetchData,
    refetchUpdate,
    formatData,
    onUpdate,
    onDelete,
  };
  return (
    <>
      <LoadPanel visible={loading || loadingUpdate} />
      <CategoryRegistrationContext.Provider value={value}>
        <div
          style={
            themePro
              ? { display: 'flex', gap: 20 }
              : { display: 'flex', border: '1px solid #ccc' }
          }
        >
          <TreeViewList ref={treeViewCategoryRef} />
          <FormCreate ref={formRef} />
        </div>
      </CategoryRegistrationContext.Provider>
    </>
  );
}

export default CategoryRegistration;
