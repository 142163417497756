/**
 * Update date: 26-05-2023
 * Screen 2042.5
 */
import './style.scss';
import FormSearch from './FormSearch';
import List from './List';
import { CustomProvider } from './store';

function CarryoutInquiry() {
  return (
    <CustomProvider>
      <FormSearch />
      <List />
    </CustomProvider>
  );
}

export default CarryoutInquiry;
