/**
 * Update date: 20-06-2023
 * Screen 2044.9
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import useFormat from 'hooks/useFormat';
import ArrayStore from 'devextreme/data/array_store';
import { dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const ForwardPopup = React.lazy(() => import('./ForwardPopup'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2044_9_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Send Delivery Tracking
 *
 * @return {*}
 */
const M2044_9 = () => {
  // Get multi language
  const { t }: any = useDictionary({ programId: '2044.9' });
  // Initialize state
  const [data, setData] = useState([]);
  const [dataForward, setDataForward] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, QtyFormat } = useFormat();
  const [formSearchData, setFormSearchData] = useState<any>({
    searchDateType: 1,
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      productType: [],
      mall: [],
    },
    productType: '',
    mall: '',
  });

  // Api call
  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  // Api call
  const [{ loading: loadingfetchDataDetail }, refetchDataDetail] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'id_no',
  });

  const storeForward: any = new ArrayStore({
    data: dataForward,
  });

  const onSearch = async () => {
    const res: any = await refetchData({
      url: '/ecommerce/2044_9',
      method: 'POST',
      data: {
        searchDateType: formSearchData?.searchDateType || 1,
        fromDate: dateFormatStr(formSearchData?.fromDate),
        endDate: dateFormatStr(formSearchData?.endDate),
        productTypes: formSearchData?.dataList?.productType?.map(
          (o: any) => o.code,
        ),
        mallCds: formSearchData?.dataList?.mall?.map((o: any) => o.mallCode),
      },
    });
    if (res?.data?.status === '200') {
      const dataMapping = (res?.data?.data || []).map((o, idx) => ({
        ...o,
        id_no: idx,
        approval_nm:
          o.approval_cd === '1'
            ? t('Cash')
            : o.approval_cd === '2'
            ? t('Banking')
            : o.approval_cd === '3'
            ? t('Card')
            : '',
      }));
      setData(dataMapping);
    } else {
      notification({ res });
    }
  };

  const onForwardDetail = async data => {
    const res: any = await refetchDataDetail({
      method: 'POST',
      url: '/ecommerce/2044_9/forward-detail',
      data: data,
    });
    setDataForward(res?.data?.data);
    setModeView({
      type: 'view',
    });
  };

  const onCloseForwardDetail = () => {
    setModeView({ type: 'list' });
    setDataForward([]);
  };

  // Values share with child component
  const value: any = {
    t,
    modeView,
    setModeView,
    formSearchData,
    setFormSearchData,
    DateFormat,
    DateTimeFormat,
    AmountFormat,
    QtyFormat,
    store,
    onSearch,
    refetchDataDetail,
    onForwardDetail,
    storeForward,
    onCloseForwardDetail,
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <ForwardPopup />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingfetchDataDetail} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2044_9;
