/**
 * Update date: 09-06-2023
 * Program component
 */
import { iconC_P, iconE_P, iconJ_P, iconR_P } from 'images';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigation } from '.';
import { LayoutContext } from '../Layout';
import { uniqBy } from 'lodash';

const ProgramMenu = () => {
  const context: any = useNavigation();
  const {
    subMenuSeleted,
    listProgram,
    programSeleted,
    setProgramSeleted,
    setHiddenProgramMenu,
  } = context;
  const layOutContext: any = useContext(LayoutContext);
  const { mainMenu, setListLastUsed } = layOutContext;
  const history = useHistory();

  // const [{}, refetchHistory] = useAxios(
  //   {
  //     url: 'user/user/program-history',
  //     method: 'POST',
  //   },
  //   { useCache: false, autoCancel: true, manual: true },
  // );
  /**
   * render program type
   *
   * @param {*} type
   * @return {*}
   */
  const renderProgramType = type => {
    let icon = '';
    switch (type) {
      case 'E':
        icon = iconE_P;
        break;
      case 'C':
        icon = iconC_P;
        break;
      case 'R':
        icon = iconR_P;
        break;
      default:
        icon = iconJ_P;
        break;
    }
    return (
      <img
        src={icon}
        style={{ width: 13, marginRight: 5, marginTop: 5 }}
        alt="icon"
      />
    );
  };

  /**
   * on history push
   *
   * @param {*} e
   */
  const onHistoryPush = async e => {
    setListLastUsed(preState => {
      const list = [e].concat(preState);
      setHiddenProgramMenu(true);
      return uniqBy(list.slice(0, 6), 'programId');
    });
  };
  return (
    <div>
      <div className="sidebar-program-menu-submenu">{listProgram?.subMenu}</div>
      <div className="sidebar-program-menu-list">
        {listProgram?.list?.map((o, i) => (
          <div
            className="sidebar-program-menu-list-detail"
            style={
              programSeleted === o?.programId
                ? { backgroundColor: '#344054' }
                : {}
            }
            key={i}
            onClick={() => {
              setProgramSeleted(o?.programId);
              onHistoryPush(o);
              if (!subMenuSeleted?.parentId || !subMenuSeleted?.selected) {
                let breakFor = false;
                for (let i = 0; i < mainMenu.length && !breakFor; i++) {
                  const subMenu = mainMenu[i]?.children;
                  for (let j = 0; j < subMenu.length && !breakFor; j++) {
                    const programMenu = subMenu[j]?.children;
                    for (let x = 0; x < programMenu.length && !breakFor; x++) {
                      if (o?.programId === programMenu[x]?.programId) {
                        const url = [
                          '',
                          mainMenu[i]?.programId,
                          subMenu[j]?.programId,
                          o?.programId,
                        ].join('/');
                        history.push(url, {
                          programId1: mainMenu[i]?.programId,
                          programId2: subMenu[j]?.programId,
                          programId3: o?.programId,
                        });
                        breakFor = true;
                        break;
                      }
                    }
                    if (breakFor) break;
                  }
                  if (breakFor) break;
                }
              } else {
                const url = [
                  '',
                  subMenuSeleted?.parentId,
                  subMenuSeleted?.selected,
                  o?.programId,
                ].join('/');
                history.push(url, {
                  programId1: subMenuSeleted?.parentId,
                  programId2: subMenuSeleted?.selected,
                  programId3: o?.programId,
                });
              }
            }}
          >
            <span>{renderProgramType(o?.programType)}</span>
            <span className="sidebar-program-name">{o?.programName}</span>
            <span className="sidebar-program-id">{o?.programId}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramMenu;
