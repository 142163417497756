/**
 * Update date: 24-05-2023
 * Screen 2041.4
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import PopupScanBarcode from 'app/components/PopupCommon/PopupScanBarcode';
import { Form } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import React, { forwardRef, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useIncomingLoadreg } from '..';
import PopupLoading from '../PopupLoading';
function LoadingWait(props, ref) {
  const { themePro }: any = useApp();
  const context: any = useIncomingLoadreg();
  const {
    params,
    fetchLoadingWait,
    storeWait,
    setModeView,
    refComplete,
    storeComplete,
    modeView,
    setDataLoading,
  } = context;
  const formRef: any = useRef(null);
  const [errorBarcode, setErrorBarcode] = useState('');
  const { AmountFormat } = useFormat();
  const [formData, setFormData] = useState({
    barcode: '',
  });
  const { t }: any = useDictionary({
    programId: '2041.4',
  });
  const [showPopupScanBarcode, setShowPopupScanBarcode] = useState(false);

  /**
   * on search
   *
   * @param {*} search
   * @param {*} data
   * @return {*}
   */
  const onSearch = async (search, data) => {
    if (!params?.zoneData?.zoneId) {
      notification({
        message: t('Please choose Zone!'),
        type: 'error',
      });
      return;
    }
    if (!formData?.barcode && !search) {
      setErrorBarcode(t('Please input barcode!'));
      return;
    }
    const res = await fetchLoadingWait({
      data: {
        zoneId: params?.zoneData?.zoneId || null,
        barcode: formData?.barcode || data || '',
      },
    });
    if (res?.data?.status === '200') {
      setErrorBarcode('');
    }
    formRef?.current?.instance?.updateData('barcode', '');
    if (!search) {
      formRef?.current?.instance?.getEditor('barcode')?.focus();
    }
  };
  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <span
          onClick={() => {
            setModeView({ type: 'loading', id: params?.zoneData?.zoneId });
            setDataLoading(record?.data);
          }}
          className="cell-link"
        >
          {t('Loading')}
        </span>
        {' | '}
        <span onClick={() => onExcept(record?.data)} className="cell-link">
          {t('Except')}
        </span>
      </div>
    );
  };
  /**
   * on except
   *
   * @param {*} data
   */
  const onExcept = data => {
    storeWait
      .remove(data?.ID)
      .done(() => {})
      .fail(() => {});
    storeComplete
      .insert({
        ...data,
        status: 'Except',
        locationToId: params?.zoneData?.locationId,
        locationToNm: '',
        zoneToId: params?.zoneData?.zoneId,
        zoneToNm: params?.zoneData?.zoneName,
        expirationEndDate: null,
        moveQuantity: data?.receiveQt,
      })
      .done(() => {})
      .fail(() => {});
    ref?.current?.instance?.refresh();
    refComplete?.current?.instance?.refresh();
    setModeView({
      type: 'wait',
      id: params?.zoneData?.zoneId,
      expirationEndDate: null,
    });
  };
  const columns: any = [
    {
      dataField: 'receiveBill',
      caption: t('Bill ID'),
      alignment: 'left',
    },
    {
      dataField: 'vendorNm',
      caption: t('Vendor'),
      alignment: 'left',
    },
    {
      dataField: 'receiveId',
      caption: t('ID'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'productNm',
      caption: t('Product Name'),
      alignment: 'left',
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
      alignment: 'left',
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      alignment: 'right',
    },
    // {
    //   dataField: 'receiveQt',
    //   caption: t('Qty'),
    //   alignment: 'right',
    //   format: AmountFormat,
    // },
    {
      dataField: 'status',
      caption: t('Status'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: '',
      caption: t('Action'),
      alignment: themePro ? 'left' : 'center',
      cellRender: renderAction,
    },
  ];
  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <Form
        ref={formRef}
        formData={formData}
        colCount={3}
        labelLocation="top"
        showColonAfterLabel={false}
        className="body-padding-white"
        items={[
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            items: [
              {
                dataField: 'barcode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Scan the barcode'),
                },
                editorOptions: {
                  onEnterKey: () => {
                    onSearch(null, '');
                  },
                  buttons: [
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'photo',
                        onClick: () => setShowPopupScanBarcode(true),
                      },
                    },
                  ],
                },
              },
              {
                itemType: 'button',
                buttonOptions: {
                  icon: 'search',
                  text: t('Search'),
                  onClick: () => onSearch(true, ''),
                },
                horizontalAlignment: 'left',
              },
            ],
          },
        ]}
      ></Form>
      {errorBarcode && <p className="cell-error-text"> {errorBarcode} </p>}
      <DataGridFull
        ref={ref}
        dataSource={storeWait}
        columns={columns}
        options={{
          key: 'ID',
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift({
              location: 'before',
              template: 'totalCount',
            });
          },
          summary: {
            totalItems: [
              {
                column: 'receiveQt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
            ],
          },
        }}
      />
      {showPopupScanBarcode && (
        <PopupScanBarcode
          visible={showPopupScanBarcode}
          onHiding={() => setShowPopupScanBarcode(false)}
          error={errorBarcode}
          onSubmit={async e => {
            if (e) {
              await formRef?.current?.instance?.updateData('barcode', e);
              await onSearch(null, e);
            }
          }}
        />
      )}
      {modeView?.type === 'loading' && <PopupLoading />}
    </React.Suspense>
  );
}

export default forwardRef(LoadingWait);
