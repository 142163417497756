/**
 * Update date: 2023-07-06
 * Screen 2081.I
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';

const List = React.lazy(() => import('./List'));
const Detail = React.lazy(() => import('./Detail'));
const DepositCreate = React.lazy(() => import('./DepositCreate'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2081_I_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 *  Deposit of bond balance by store
 *
 * @return {*}
 */
const M2081_I = () => {
  const [data, setData] = useState([]);
  const [depositDate, setDepositDate] = useState(new Date());
  const [formSearchData, setFormSearchData] = useState({
    date: new Date(),
    representative: '',
    dataList: {
      store: [],
      storeType: [],
      storeGroup: [],
      receivableCd: [],
    },
    store: '',
    storeType: '',
    storeGroup: '',
    receivableCd: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const value: any = {
    data,
    setData,
    modeView,
    setModeView,
    formSearchData,
    setFormSearchData,
    depositDate,
    setDepositDate,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'detail':
        return <Detail />;
      case 'deposit':
        return <DepositCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2081_I;
