/**
 * Update date: 17-05-2023
 * Screen 2011.4
 */
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import useAxios from 'axios-hooks';
import { Button, Form } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import React, { useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useContainerRegistration } from '..';
import useDictionary from 'hooks/useDictionary';
import useCenterInfo from 'hooks/useCenterInfo';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { useApp } from 'app';
import { replaceWhiteSpaceValueChange } from 'utils/common/replaceWhiteSpaceValueChange';

const defaultData = {
  containerId: null,
  containerName: '',
  productType: '',
  containerType: '',
  containerKind: '',
  capacity: '',
  taxTp: '',
  use: true,
  taxRate: 0,
  importRate: 0,
  unitPrice: 0,
  exportRate: 0,
  containerCode: '',
  commonContainerCode: '',
  commonReturnBackCode: '',
  formStore: {
    productType: {},
    containerType: {},
    containerKind: {},
  },
  commTaxRate: 10,
  cosmoInterlockTf: false,
  takereturnbackTf: false,
};

function FormCreate() {
  const { themePro }: any = useApp();
  const context: any = useContainerRegistration();
  const { modeView, setModeView, onAdd, onUpdate } = context || {};
  const { defaultVat } = useCenterInfo();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [typeCommon, setTypeCommon] = useState('');
  const formRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({
    ...defaultData,
    commTaxRate: defaultVat,
  });
  const id = modeView?.id;

  const { t }: any = useDictionary({
    programId: '2011.4',
  });

  // const [{ data: taxData, loading: taxLoading }] = useAxios(
  //   {
  //     url: 'core/center-info/tax',
  //     method: 'GET',
  //   },
  //   {
  //     useCache: false,
  //     autoCancel: true,
  //   },
  // );
  const [{ loading }, refetchDataDetail] = useAxios(
    {
      url: `/product/container/${id}`,
      method: 'GET',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: `/product/container`,
      method: 'PUT',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  const [{ loading: loadingProductCd }, refetchProductCd] = useAxios(
    {
      url: '/product/product/default-product-cd',
      method: 'POST',
    },
    { manual: true },
  );

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const data = {
      ...formData,
      productType: formData?.formStore?.productType?.code || '',
      containerType: formData?.formStore?.containerType?.code || '',
      containerKind: formData?.formStore?.containerKind?.code || '',
      taxRate: formData?.taxRate || 0,
      importRate: formData?.importRate || 0,
      unitPrice: formData?.unitPrice || 0,
      exportRate: formData?.exportRate || 0,
      commTaxRate: formData?.commTaxRate || 0,
      cosmoInterlockTf: formData?.cosmoInterlockTf,
      takereturnbackTf: formData?.takereturnbackTf,
    };
    delete data.formStore;
    const res = await refetchUpdate({
      data,
    });
    notification({
      res,
    });
    if (res?.data?.status === '201' && res?.data?.data) {
      if (!formData.containerId) {
        onAdd(res?.data?.data);
      } else {
        onUpdate(res?.data?.data);
      }
      setModeView({
        type: 'list',
      });
    }
  };
  /**
   * on popup common
   *
   * @param {*} type
   */
  const handleShowPopupCommon = type => {
    setTypeCommon(type);
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    setModeView({ type: 'list' });
  };
  // const onFieldDataChanged = e => {
  //   switch (e?.dataField) {
  //     case 'taxTp':
  //       const taxItemOnchange = taxData.data.find(tax => tax.type === e.value);
  //       formRef?.current?.instance.updateData('taxRate', taxItemOnchange.rate);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  /**
   * on close popup
   *
   */
  const closePopup = () => {
    setTypeCommon('');
  };
  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    let name = '';
    switch (typeCommon) {
      case 'PT':
        name = 'productType';
        break;
      case 'CT':
        name = 'containerType';
        break;
      case 'CK':
        name = 'containerKind';
        break;
      default:
        break;
    }
    let newData = data;

    setFormData(preState => ({
      ...preState,
      [name]: '',
      formStore: {
        ...preState?.formStore,
        [name]: newData,
      },
    }));
    closePopup();
  };
  /**
   * on remove
   *
   * @param {*} type
   */
  const handleRemove = type => {
    let name = '';
    switch (type) {
      case 'PT':
        name = 'productType';
        break;
      case 'CT':
        name = 'containerType';
        break;
      case 'CK':
        name = 'containerKind';
        break;
      default:
        break;
    }
    setFormData(preState => ({
      ...preState,
      [name]: '',
      formStore: {
        ...preState?.formStore,
        [name]: {},
      },
    }));
  };
  /**
   * refresh product cd
   *
   */
  const refreshProductCd = async () => {
    const res = await refetchProductCd();
    if (res?.data?.status == 200 && res?.data?.data) {
      formRef?.current?.instance?.updateData('containerCode', res?.data?.data);
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const res = await refetchDataDetail();
        setFormData({
          ...res?.data?.data,
          productType: '',
          containerType: '',
          containerKind: '',
          formStore: {
            productType: {
              name: res?.data?.data?.productTypeName,
              code: res?.data?.data?.productType,
            },
            containerType: {
              name: res?.data?.data?.containerTypeName,
              code: res?.data?.data?.containerType,
            },
            containerKind: {
              name: res?.data?.data?.containerKindName,
              code: res?.data?.data?.containerKind,
            },
          },
        });
      }
    };
    fetchData();
  }, [id]);

  // useEffect(() => {
  //   if (taxData) {
  //     const taxItemOnchange = taxData.data.find(tax => tax.type === '0');
  //     if (taxItemOnchange) {
  //       formRef?.current?.instance.updateData({
  //         ...formData,
  //         taxTp: taxItemOnchange.type,
  //         taxRate: taxItemOnchange.rate,
  //       });
  //     }
  //   }
  // }, [taxData]);

  const loadingAll = loading || loadingUpdate || loadingProductCd;

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingAll} />
      <BreadCrumbPremium
        onSubmit={() => onSubmit()}
        onCancel={() => setShowPopupConfirm(true)}
      />
      <Form
        ref={formRef}
        labelLocation="top"
        showColonAfterLabel={false}
        className="body-padding-white"
        // onFieldDataChanged={onFieldDataChanged}
        formData={formData}
        items={[
          {
            dataField: 'containerId',
            editorType: 'dxTextBox',
            label: {
              text: t('ID'),
            },
            editorOptions: {
              readOnly: true,
            },
          },
          {
            dataField: 'containerCode',
            editorType: 'dxTextBox',
            label: {
              text: t('Container Code'),
            },
            editorOptions: {
              onValueChanged: replaceWhiteSpaceValueChange,
              buttons: [
                {
                  name: 'refresh',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'refresh',
                    onClick: () => {
                      refreshProductCd();
                    },
                  },
                },
              ],
            },
            isRequired: true,
          },
          {
            dataField: 'containerName',
            editorType: 'dxTextBox',
            label: {
              text: t('Container Name'),
            },
            isRequired: true,
          },
          {
            label: { text: t('Product Type') },
            dataField: 'productType',
            editorType: 'dxTextBox',
            isRequired: !formData?.formStore?.productType?.code,
            editorOptions: {
              onEnterKey: () => {
                handleShowPopupCommon('PT');
              },
              buttons: [
                {
                  name: 'data1',
                  location: 'before',
                  options: {
                    icon: 'close',
                    text: formData?.formStore?.productType?.name,
                    onClick: () => {
                      handleRemove('PT');
                    },
                    visible: !!formData?.formStore?.productType?.code,
                    rtlEnabled: true,
                  },
                },
                {
                  name: 'search',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'search',
                    onClick: () => {
                      handleShowPopupCommon('PT');
                    },
                  },
                },
              ],
            },
          },
          {
            label: { text: t('Container Type') },
            dataField: 'containerType',
            editorType: 'dxTextBox',
            isRequired: !formData?.formStore?.containerType?.code,
            editorOptions: {
              onEnterKey: () => {
                handleShowPopupCommon('CT');
              },
              buttons: [
                {
                  name: 'data1',
                  location: 'before',
                  options: {
                    icon: 'close',
                    text: formData?.formStore?.containerType?.name,
                    onClick: () => {
                      handleRemove('CT');
                    },
                    visible: !!formData?.formStore?.containerType?.code,
                    rtlEnabled: true,
                  },
                },
                {
                  name: 'search',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'search',
                    onClick: () => {
                      handleShowPopupCommon('CT');
                    },
                  },
                },
              ],
            },
          },
          {
            label: { text: t('Container Kind') },
            dataField: 'containerKind',
            editorType: 'dxTextBox',
            isRequired: !formData?.formStore?.containerKind?.code,
            editorOptions: {
              onEnterKey: () => {
                handleShowPopupCommon('CK');
              },
              buttons: [
                {
                  name: 'data1',
                  location: 'before',
                  options: {
                    icon: 'close',
                    text: formData?.formStore?.containerKind?.name,
                    onClick: () => {
                      handleRemove('CK');
                    },
                    visible: !!formData?.formStore?.containerKind?.code,
                    rtlEnabled: true,
                  },
                },
                {
                  name: 'search',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'search',
                    onClick: () => {
                      handleShowPopupCommon('CK');
                    },
                  },
                },
              ],
            },
          },
          {
            dataField: 'capacity',
            editorType: 'dxTextBox',
            label: {
              text: t('Capacity'),
            },
          },
          {
            itemType: 'group',
            colCount: 2,
            items: [
              {
                dataField: 'commonContainerCode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Common Container Code (Take Back)'),
                },
              },
              {
                dataField: 'commonReturnBackCode',
                editorType: 'dxTextBox',
                label: {
                  text: t('Common Container Code (Return Back)'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            colCount: 4,
            colSpan: 2,
            items: [
              {
                label: { text: t('Commission Tax Rate') },
                dataField: 'commTaxRate',
                editorType: 'dxNumberBox',
              },
              {
                label: { text: t('Cosmo Interlock') },
                dataField: 'cosmoInterlockTf',
                editorType: 'dxSwitch',
              },
              {
                colSpan: 2,
                dataField: 'unitPrice',
                editorType: 'dxTextBox',
                label: {
                  text: t('Unit Price'),
                },
                validationRules: [
                  {
                    type: 'numeric',
                    message: t('Unit Price must be number'),
                  },
                ],
              },
            ],
          },

          {
            itemType: 'group',
            colCount: 4,
            colSpan: 2,
            items: [
              {
                dataField: 'use',
                editorType: 'dxSwitch',
                label: {
                  text: t('Use'),
                },
              },
              {
                label: { text: t('Take Return Back') },
                dataField: 'takereturnbackTf',
                editorType: 'dxSwitch',
              },
              {
                colSpan: 2,
                dataField: 'importRate',
                editorType: 'dxTextBox',
                label: {
                  text: t('Import Rate(%)'),
                },
                validationRules: [
                  {
                    type: 'numeric',
                    message: t('Import Rate must be number'),
                  },
                ],
              },
            ],
          },
          {
            dataField: 'exportRate',
            editorType: 'dxTextBox',
            label: {
              text: t('Export Rate(%)'),
            },
            validationRules: [
              {
                type: 'numeric',
                message: t('Export Rate must be number'),
              },
            ],
          },
        ]}
        colCount={2}
      />
      <br />
      {!themePro && (
        <div
          style={{
            margin: '40px 0',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            text={t('Save')}
            icon="save"
            style={{ marginRight: 10 }}
            onClick={() => onSubmit()}
          />
          <Button
            text={t('Cancel')}
            icon="close"
            onClick={() => setShowPopupConfirm(true)}
          />
        </div>
      )}
      <PopupSelectCommonCode
        visible={typeCommon !== ''}
        onHiding={closePopup}
        codeType={typeCommon}
        onSubmit={handleSubmitPopup}
      />
      {showPopupConfirm && (
        <PopupConfirm
          visible={showPopupConfirm}
          content={t('Do you want to cancel all changed?')}
          onOk={onCancel}
          onHiding={() => setShowPopupConfirm(false)}
        />
      )}
    </React.Suspense>
  );
}

export default FormCreate;
