/**
 * Update date: 18-05-2023
 * Screen 2013.5
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import SpecialDealTable from './TableView';
import FormSearch from './FormSearch';
import { useProductType } from 'hooks/useProductType';
import useDictionary from 'hooks/useDictionary';
import LoadPanel from 'app/components/LoadPanel';

const SpecialDeal = createContext({
  refetchData: () => {},
  loadingData: false,
  dealData: [],
});
/**
 * create context
 */
SpecialDeal.displayName = 'SpecialDeal';
export const useSpecialDeal = () => {
  const context = useContext(SpecialDeal);
  return context;
};

function SpecialDealInquiry() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({ programId: '2013.5' });
  const [dealData, setDealData] = useState([]);
  const [{ data, loading: loadingDeal }, refetchData] = useAxios(
    {
      url: '/account/product-deal/inquiry',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const value: any = {
    productTpPermission,
    t,
    refetchData,
    dealData,
    loadingDeal,
  };

  useEffect(() => {
    if (data?.data) {
      setDealData(data?.data);
    }
  }, [data]);

  return (
    <>
      <SpecialDeal.Provider value={value}>
        <LoadPanel visible={loadingProductTpPermission} />
        <FormSearch />
        <SpecialDealTable />
      </SpecialDeal.Provider>
    </>
  );
}

export default SpecialDealInquiry;
