/*
 * 2082.1 Employee/Daily Purchase Settlement Registration
 * @since 2022/09/29
 */

import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApp } from 'app';
import useDictionary from 'hooks/useDictionary';
import ArrayStore from 'devextreme/data/array_store';
import moment from 'moment';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));

// Initialize context
const PurchaseSettlementRegistrationContext = createContext({});

PurchaseSettlementRegistrationContext.displayName =
  'PurchaseSettlementRegistrationContext';

export const usePurchaseSettlementRegistration = () => {
  return useContext(PurchaseSettlementRegistrationContext);
};

/**
 * Employee/Daily Purchase Settlement Registration
 *
 * @return {*}
 */
const PurchaseSettlementRegistration = () => {
  // Get multi langua
  const { t }: any = useDictionary({
    programId: '2082.1',
  });
  //Get data from app context
  const appContext: any = useApp();
  const { s } = appContext || {};
  // Initialize state
  const [modeView, setModeView] = useState({
    type: 'list',
    id: null,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      vendor: [],
    },
    vendor: '',
    purchaseSettleNumber: '',
    fromDate: new Date(),
    endDate: new Date(),
  });

  const [dataListCreate, setDataListCreate] = useState([]);
  const [storeView, setStoreView] = useState([]);
  // Api get data master
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    { url: 'warehouse/purchase-settle', method: 'POST' },
    { manual: true, autoCancel: true },
  );
  // Api get data to create or update
  const [{ data: dataCreate, loading: loadingCreate }, refetchCreate] =
    useAxios(
      { url: 'warehouse/purchase-settle/get-data-create', method: 'POST' },
      { manual: true, autoCancel: true },
    );
  // Api delete data
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    { url: 'warehouse/purchase-settle', method: 'DELETE' },
    { manual: true, autoCancel: true },
  );
  // Api get purchase settle detail
  const [
    { data: dataDetailSettle, loading: loadingDetailSettle },
    refetchDetailSettle,
  ] = useAxios(
    {
      url: 'warehouse/purchase-settle/detail',
      method: 'GET',
    },
    { manual: true, autoCancel: true },
  );
  // Api get bill detail
  const [
    { data: dataDetailBill, loading: loadingDetailBill },
    refetchDetailBill,
  ] = useAxios(
    {
      url: 'warehouse/purchase-settle/bill-detail',
      method: 'GET',
    },
    { manual: true, autoCancel: true },
  );
  // Api create purchase settle data
  const [{ loading: loadingSave }, refetchSave] = useAxios(
    {
      url: 'warehouse/purchase-settle',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );
  // store data grid
  const store: any = new ArrayStore({
    data: dataList?.data || [],
    key: 'purchase_settle_id',
  });

  const storeCreate: any = new ArrayStore({
    data: dataListCreate,
    key: 'id',
  });

  const storeBill: any = new ArrayStore({
    data: dataDetailBill?.data || [],
  });

  /**
   * On search data master grid
   *
   */
  const onSearch = async () => {
    const data = {
      vendorIds: formSearchData.dataList.vendor.map((o: any) => o.vendorId),
      fromDate: formSearchData?.fromDate
        ? moment(formSearchData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formSearchData?.endDate
        ? moment(formSearchData?.endDate).format('YYYY-MM-DD')
        : null,
      purchaseSettleNumber: formSearchData?.purchaseSettleNumber,
    };
    refetchData({
      data: data,
    });
  };
  // Values share with child component
  const value: any = {
    t,
    s,
    store,
    storeCreate,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    refetchCreate,
    refetchDetailSettle,
    dataDetailSettle,
    refetchDelete,
    refetchDetailBill,
    storeBill,
    refetchSave,
    setDataListCreate,
    onSearch,
    storeView,
    setStoreView,
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'view':
      case 'viewBill':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };
  // Set data form Api to state
  useEffect(() => {
    if (dataCreate?.status === '200' && !loadingCreate) {
      setDataListCreate(dataCreate?.data || []);
    }
  }, [dataCreate, loadingCreate]);

  useEffect(() => {
    if (dataDetailSettle?.status === '200' && !loadingDetailSettle) {
      setStoreView(dataDetailSettle?.data?.details || []);
    }
  }, [dataDetailSettle, loadingDetailSettle]);

  const loadingAll =
    loadingList ||
    loadingCreate ||
    loadingDetailSettle ||
    loadingDelete ||
    loadingDetailBill ||
    loadingSave;

  return (
    <PurchaseSettlementRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </PurchaseSettlementRegistrationContext.Provider>
  );
};

export default PurchaseSettlementRegistration;
