/**
 * Update date: 31-05-2023
 * Screen 2046.B
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import useAxios from 'axios-hooks';
import { ROW_STATUS } from 'constant';
import { notification } from 'utils/notification';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import useLocalStorage from 'hooks/useLocalStorage';

const FormSearch = React.lazy(() => import('./FormSearch'));
const Tab1 = React.lazy(() => import('./Tab1'));
const Tab2 = React.lazy(() => import('./Tab2'));
const Tab2Edit = React.lazy(() => import('./Tab2Edit'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'ModifyingForwardingContext';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

export default function ModifyingForwarding() {
  const [dataLocal, setDataLocal] = useLocalStorage(
    '2046.B_search_dataList',
    {},
  );
  const { t }: any = useDictionary({ programId: '2046.B' });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataTab1, setDataTab1] = useState<any>([]);
  const [dataTab2, setDataTab2] = useState<any>([]);
  const [modeView, setModeView] = useState<any>({
    type: 'list',
    forwardId: null,
  });
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      productIds: [],
      storeId: [],
      receivableCd: [],
      vendor: [],
    },
    productIds: '',
    storeId: '',
    receivableCd: '',
    vendor: '',
  });
  const [storedDataSearch, setStoredDataSearch] = useState<any>(null);
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setFormData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  const [{ loading }, refetchSearch] = useAxios(
    {
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * on search submit
   *
   * @param {*} params
   */
  const onSearchSubmit = async params => {
    if (!params && storedDataSearch) {
      params = storedDataSearch;
    }
    const res: any = await refetchSearch({
      url:
        selectedIndex === 0
          ? 'warehouse/forward/modify-forward'
          : 'warehouse/forward/modify-date-forward-data',
      data: params,
    });

    if (res?.data?.status === '200') {
      setStoredDataSearch({ ...params });
      if (selectedIndex === 0) {
        const dataMapping = res?.data?.data?.map(o => ({
          ...o,
          status: ROW_STATUS.NORMAL,
        }));
        setDataTab1(dataMapping);
      } else {
        setDataTab2(res?.data?.data);
      }
    } else {
      notification({ res });
    }
  };

  /**
   * on select index
   *
   * @param {*} e
   */
  const onSelectedIndexChange = e => {
    setSelectedIndex(e);
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return (
          <>
            <BreadCrumbPremium />
            <FormSearch />
            <TabPanel
              deferRendering={false}
              swipeEnabled={false}
              repaintChangesOnly
              showNavButtons
              defaultSelectedIndex={0}
              selectedIndex={selectedIndex}
              onSelectedIndexChange={onSelectedIndexChange}
              className="bg-white"
            >
              <Item title={t('Edit Qty/Unit Price')}>
                <Tab1 dataTab1={dataTab1} />
              </Item>
              <Item title={t('Edit Forward Date')}>
                <Tab2 dataTab2={dataTab2} />
              </Item>
            </TabPanel>
          </>
        );
      case 'edit':
        return <Tab2Edit />;
      default:
        return <NotFoundPage />;
    }
  };

  const valueContext = {
    modeView,
    setModeView,
    formData,
    setFormData,
    setDataLocal,
    onSearchSubmit,
  };

  return (
    <ModuleContext.Provider value={valueContext}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
}
