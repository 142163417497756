/**
 * Update date: 2024-07-31
 * Screen 2088.1
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import PopupConfirmDeleteWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmDeleteWithRef';
import useAxios from 'axios-hooks';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { memo, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import PopupDetail from '../PopupDetail';
import PopupOrder from 'app/components/PopupCommon/PopupOrder';
import moment from 'moment';

const OrderPGPaymentTab = ({ t, store, refFormSearch }) => {
  const dataGridRef: any = useRef(null);
  const confirmDeleteRef: any = useRef(null);
  const detailRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  const { AmountFormat } = useFormat();
  const [showPopupOrder, setShowPopupOrder] = useState<any>({
    visible: false,
    orderId: null,
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on validate delete
   *
   * @return {*}
   */
  const validateDelete = () => {
    const selectedRowKeysLength =
      dataGridRef?.current?.instance?.getSelectedRowKeys?.()?.length;
    if (!selectedRowKeysLength) {
      return notification({
        type: 'error',
        message: t('You must be select at least one record. Please try again!'),
      });
    }
    confirmDeleteRef?.current?.onOpen?.(selectedRowKeysLength);
  };

  /**
   * on delete
   *
   */
  const onDelete = async () => {
    const selectedRowKeys =
      dataGridRef?.current?.instance?.getSelectedRowKeys?.();
    const res = await refetchData({
      url: '/account/m2088_1',
      method: 'DELETE',
      data: {
        deleteData: selectedRowKeys?.map(o => ({
          mallCode: o.mallCd,
          orderNumber: o.mallOrderId,
        })),
      },
    });
    notification({ res });
    if (res?.data?.status === '200') {
      refFormSearch?.current?.onSearch?.();
      dataGridRef?.current?.instance?.clearSelection();
    }
  };

  const onRecollectionOrderData = async (mallCd, orderNumber) => {
    const data = {
      mallCd: mallCd,
      orderNumber: orderNumber,
    };
    const res = await refetchData({
      url: '/ecommerce/order-popup/send-recollection',
      method: 'POST',
      data: data,
    });
    notification({ res });
    // if (res?.data?.status === '200') {
    // }
  };

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const columns: any = [
    {
      dataField: 'pgpaymentDt',
      caption: t('Payment Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'mallOrderId',
      caption: t('Order Number'),
      cellRender: e => {
        const orderId = e?.data?.orderId;
        return orderId ? (
          <div
            style={{ color: 'brown', cursor: 'pointer' }}
            onClick={() => {
              setShowPopupOrder({
                visible: true,
                orderId: orderId,
              });
            }}
          >
            {e?.value}
          </div>
        ) : (
          <div>{e?.value}</div>
        );
      },
    },
    {
      dataField: 'pgpaymentAmount',
      caption: t('PG Paymen Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'mallPaymentAmount',
      caption: t('Order Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'shippingFee',
      caption: t('Shipping Fee'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'totalOrder',
      caption: t('Total Order'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'forwardAmount',
      caption: t('Forward Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'notForwardAmount',
      caption: t('Not Forward Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'diffAmount',
      caption: t('Difference Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      caption: t('Action'),
      dataField: 'action_column',
      alignment: 'left',
      cellRender: record => {
        const { mallOrderId, isDetail, mallCd } = record?.data || {};
        return (
          <div>
            <span
              onClick={() => onRecollectionOrderData(mallCd, mallOrderId)}
              className="cell-link"
            >
              {t('Order Re-collection')}
            </span>
            <span style={{ marginRight: 10, marginLeft: 10 }}>|</span>
            {isDetail ? (
              <>
                <span
                  onClick={() => detailRef?.current?.onOpen?.(mallOrderId)}
                  className="cell-link"
                >
                  {t('Forward Detail')}
                </span>
              </>
            ) : (
              <span>{t('Forward Detail')}</span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        columns={columns}
        fixedLeft={3}
        fixedRight={1}
        options={{
          columnAutoWidth: true,
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
          },
          export: {
            fileName: `2088.1-Order PG Payment_${moment().format('YYYYMMDD')}`,
          },
          onCellClick: e => {
            if (
              e?.rowType === 'data' &&
              e?.column?.dataField === 'action_column'
            ) {
              e?.event?.preventDefault();
              e?.event?.stopPropagation();
              e?.event?.stopImmediatePropagation?.();
            }
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !store?._array?.length,
                  onClick: () => exportExcel(),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  onClick: () => validateDelete(),
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                column: 'pgpaymentAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'mallPaymentAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'shippingFee',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalOrder',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'forwardAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'notForwardAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'diffAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
      <LoadPanel visible={loading} />
      <PopupConfirmDeleteWithRef ref={confirmDeleteRef} onOk={onDelete} />
      <PopupDetail ref={detailRef} />
      {showPopupOrder?.visible && showPopupOrder?.orderId && (
        <PopupOrder
          orderId={showPopupOrder?.orderId}
          onClose={() => {
            setShowPopupOrder({ visible: false, orderId: null });
          }}
        />
      )}
    </div>
  );
};

export default memo(OrderPGPaymentTab);
