/**
 * Update date: 2024-01-24
 * Screen 2054.E
 */
import React, { useRef, memo } from 'react';
import moment from 'moment';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';

// File name export data grid
const exportFileName =
  'SMDC_2054.E Inventory adjustment details inquiry_detail2_' +
  moment().format('YYYYMMDD');

/**
 * Grid by year component
 *
 * @param {*} { data, t }
 * @return {*}
 */
function GridDetail2({ data, t }) {
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();
  const dataGridRef: any = useRef(null);

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  // Generate column grid
  const columns = [
    {
      dataField: 'product_id',
      caption: t('Product ID'),
      visible: false,
    },
    {
      dataField: 'product_cd',
      caption: t('Product Code'),
    },
    {
      dataField: 'product_nm',
      caption: t('Product Name'),
    },
    {
      dataField: 'marketable_size',
      caption: t('Marketable Size'),
    },
    {
      dataField: 'vendortype',
      caption: t('Vendor Type'),
      visible: false,
    },
    {
      dataField: 'vendor_nm_default',
      caption: t('Vendor Name'),
      visible: false,
    },
    {
      dataField: 'barcode',
      caption: t('Barcode'),
      visible: false,
    },
    {
      dataField: 'product_cost',
      caption: t('Cost'),
      format: DoubleFormat,
      dataType: 'number',
    },
    {
      dataField: 'stock_ea',
      caption: t('Stock EA'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'stocktaking_ea',
      caption: t('Stock Taking EA'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'stockadjust_ea',
      caption: t('Adjust EA'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'cost_amount',
      caption: t('Adjust Cost Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  return (
    <DataGridFull
      storageKeyInquiry="detail2"
      columns={columns}
      ref={dataGridRef}
      dataSource={data}
      fixedLeft={2}
      fixedRight={0}
      options={{
        columnAutoWidth: true,
        groupPanel: { visible: true },
        export: { fileName: exportFileName },
        onToolbarPreparing: (e: any) => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'totalCount',
            },
            {
              location: 'before',
              widget: 'dxButton',
              options: {
                disabled: !data?.length,
                icon: 'xlsxfile',
                text: t('Export to Excel'),
                onClick: () => exportExcel(),
              },
            },
          );
        },
        summary: {
          totalItems: [
            {
              column: 'stocktaking_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
            },
            {
              column: 'stockadjust_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
            },
            {
              column: 'stock_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
            },
            {
              column: 'cost_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
          ],
          groupItems: [
            {
              summaryType: 'count',
              displayFormat: '{0}',
            },
            {
              column: 'stocktaking_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
            {
              column: 'stockadjust_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
            {
              column: 'stock_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
            {
              column: 'cost_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
          ],
        },
      }}
    />
  );
}

export default memo(GridDetail2);
