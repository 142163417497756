import { ScrollView } from 'devextreme-react';
import ViewBottom from './ViewBottom';
import ViewCenter from './ViewCenter';
import ViewTop from './ViewTop';

const ProductAnalysis = () => {
  return (
    <ScrollView
      width="100%"
      height={'calc(95vh - 110px)'}
      direction="both"
      showScrollbar="never"
    >
      <ViewTop />
      <ViewCenter />
      <ViewBottom />
    </ScrollView>
  );
};
export default ProductAnalysis;
