/* Update date: 12-06-2023
 * Popup select product component
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Form, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import { useProductType } from 'hooks/useProductType';
import { cloneDeep, uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import PopupSelectCommonCode from './PopupSelectCommonCode';
interface IPopupSelectMultiProps {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit: (selected: any) => void;
  mode?: 'single' | 'multiple';
  dataBody?: {
    optionCtOrderTf?: boolean;
  };
}

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const PopupSelectProduct = ({
  visible,
  onHiding,
  text,
  onSubmit,
  mode = 'single',
  dataBody = {},
}: IPopupSelectMultiProps) => {
  const { themePro }: any = useApp();
  const dataRef: any = useRef(null);
  const formRef: any = useRef(null);
  const { DateTimeFormat } = useFormatTime();
  const { t } = useDictionary({});
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [formData, setFormData] = useState<any>({
    productNames: '',
    tradeType: '',
    dataList: {
      productType: [],
    },
    productType: '',
  });
  const [showPopupByType, setShowPopupByType] = useState('');

  const [{ data: listData, loading }, fetchData] = useAxios(
    {
      url: '/product/product/popup',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * on search
   *
   * @param {string} [name]
   * @param {*} [productType]
   */
  const onSearch = (name?: string, productType?: any) => {
    fetchData({
      data: {
        productNames: formData.productNames || name,
        tradeType: formData?.tradeType,
        productTypes:
          productType || formData?.dataList?.productType?.map(o => o?.code),
      },
    });
  };

  /**
   * on ok
   *
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!mode || mode === 'single') {
      const d = selectedRowKeys?.[0];
      onSubmit(d);
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'productType':
        return (
          <PopupSelectCommonCode
            visible={showPopupByType === popupType}
            text={formData?.[popupType]}
            codeType={'PT'}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingProductTpPermission) {
      setFormData(preState => ({
        ...preState,
        productNames: text || '',
        dataList: {
          productType: productTpPermission,
        },
        isReadOnlyProductTp: productTpPermission?.length > 0,
      }));
      onSearch(
        text || '',
        productTpPermission?.map(o => o?.code),
      );
    }
  }, [loadingProductTpPermission]);

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading || loadingProductTpPermission} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Product')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <Form
            formData={formData}
            showColonAfterLabel={false}
            className="body-padding-white mb-10"
            ref={formRef}
            items={[
              {
                label: { text: t('Keyword') },
                dataField: 'productNames',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    onSearch();
                  },
                },
              },
              generateDxTextBox('Product Type', 'productType', 'name', {
                colSpan: 1,
                readOnly: formData?.isReadOnlyProductTp,
              }),
              {
                itemType: 'group',
                colCount: 2,
                items: [
                  {
                    label: { text: t('Trade Type') },
                    dataField: 'tradeType',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      displayExpr: 'name',
                      valueExpr: 'value',
                      defaultValue: '',
                      items: [
                        {
                          name: t('All'),
                          value: '',
                        },
                        {
                          name: t('Traded'),
                          value: '0',
                        },
                        {
                          name: t('Non Traded'),
                          value: '1',
                        },
                      ],
                    },
                  },
                  {
                    itemType: 'button',
                    horizontalAlignment: 'left',
                    verticalAlignment: 'bottom',
                    buttonOptions: {
                      text: t('Search'),
                      icon: 'search',
                      onClick: () => onSearch(),
                    },
                  },
                ],
              },
            ]}
            labelLocation="top"
            colCount={3}
          ></Form>
          <DataGridFull
            ref={dataRef}
            dataSource={listData?.data}
            columns={[
              {
                dataField: 'productId',
                caption: t('ID'),
                alignment: 'left',
              },
              {
                dataField: 'productTpName',
                caption: t('Product Type'),
              },
              {
                dataField: 'tradeTp',
                caption: t('Trade Type'),
                cellRender: e => {
                  switch (e?.value) {
                    case '0':
                      return <span>{t('Traded')}</span>;
                    case '1':
                      return <span>{t('Non Traded')}</span>;
                    case '2':
                      return <span>{t('Discontinued')}</span>;
                    default:
                      return '';
                  }
                },
              },
              {
                dataField: 'productCd',
                caption: t('Product Code'),
              },
              {
                dataField: 'productMakerName',
                caption: t('Maker'),
              },
              {
                dataField: 'vendorName',
                caption: t('Vendor'),
              },
              {
                dataField: 'productNm',
                caption: t('Product'),
              },
              {
                dataField: 'marketableSize',
                caption: t('Marketable Size'),
              },
              {
                dataField: 'regisDate',
                caption: t('Regist date time'),
                alignment: 'left',
                dataType: 'date',
                format: DateTimeFormat,
              },
            ]}
            options={{
              hiddenDetailProduct: true,
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode,
                selectAllMode: 'allPages',
              },
              height: themePro
                ? 'calc(100vh - 220px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
        {getPopupByType(showPopupByType)}
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Product')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={'75vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
};

export default PopupSelectProduct;
