/**
 * Update date: 08-06-2023
 * Screen 2086.2
 */
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import React, { forwardRef } from 'react';
import { useModuleContext } from '..';

const TableContent = () => {
  const contextRoot: any = useModuleContext();
  const {
    t,
    modeView,
    setModeView,
    DateFormat,
    AmountFormat,
    QtyFormat,
    listReferenceType,
  } = contextRoot || {};

  const { billId } = modeView?.data || {};

  const [{ data: dataDetail, loading: loadingDetail }] = useAxios(
    {
      url: 'user/m2086_2',
      params: {
        billId,
      },
      method: 'GET',
    },
    {
      manual: billId ? false : true,
      autoCancel: true,
      useCache: false,
    },
  );

  const closeCancel = () => {
    setModeView({ type: 'list', data: null });
  };

  const columns = [
    {
      dataField: 'reference_tp',
      caption: t('Reference Type'),
      editorType: 'dxSelectbox',
      lookup: {
        dataSource: listReferenceType,
        displayExpr: 'name',
        valueExpr: 'type',
      },
    },
    {
      dataField: 'reference_id',
      caption: t('Reference ID'),
      alignment: 'left',
    },
    {
      dataField: 'reference_dt',
      caption: t('Reference Date'),
      alignment: 'left',
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'store_cd',
      caption: t('Store Code'),
      alignment: 'left',
    },
    {
      dataField: 'store_nm',
      caption: t('Store Name'),
      alignment: 'left',
    },
    {
      dataField: 'product_cd',
      caption: t('Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'product_nm',
      caption: t('Product Name'),
      alignment: 'left',
    },
    {
      dataField: 'option_nm',
      caption: t('Option'),
      alignment: 'left',
    },
    {
      dataField: 'quantity_per_pack',
      caption: t('Qty Per Pack'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'reference_qt',
      caption: t('Qty'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'reference_ea',
      caption: t('EA'),
      alignment: 'right',
      allowEditing: false,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'supply_amount',
      caption: t('Supply Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'vat_amount',
      caption: t('Vat Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'total_amount',
      caption: t('Total Amount'),
      alignment: 'right',
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingDetail} />
      <BreadCrumbPremium onClose={() => closeCancel()} />
      <DataGridFull
        dataSource={dataDetail?.data}
        columns={columns}
        options={{
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift({
              location: 'before',
              template: 'totalCount',
            });
          },
          summary: {
            totalItems: [
              {
                column: 'quantity_per_pack',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'reference_qt',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'reference_ea',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'total_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'quantity_per_pack',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'reference_qt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'reference_ea',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'total_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </React.Suspense>
  );
};

export default forwardRef(TableContent);
