/**
 * Update date: 15-05-2023
 * Screen 1044.1
 */
import React, { useRef, useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { uniqBy } from 'lodash';
import { useRegisteringVendor } from '..';
import useDictionary from 'hooks/useDictionary';
const listTypeCommon = {
  vendorType: {
    codeType: 'VT',
    title: 'Search Member Vendor Type',
  },
};
const FormSearch = () => {
  const context: any = useRegisteringVendor();
  const { params, refetchData, setParams } = context;
  const formRef: any = useRef(null);
  const [showPopupByType, setShowPopupByType] = useState('');
  const { t }: any = useDictionary({
    programId: '1044.1',
  });
  /**
   * Onsubmit form search
   *
   * @return {*} 
   */
  const onSubmit = async () => {
    if (formRef?.current && !formRef?.current?.instance?.validate()?.isValid)
      return;
    const data = {
      ...params,
      vendorType: params?.formStore?.vendorType.map((o: any) => o?.code),
    };
    delete data.formStore;
    refetchData({
      data,
    });
  };
  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*} 
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendorType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };
  /**
   * update store
   *
   * @param {*} vendorType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (vendorType, data, key: any = false) => {
    const filterData = uniqBy(
      [...params?.formStore?.[vendorType], ...data],
      key,
    );
    const newStore = { ...params?.formStore, [vendorType]: filterData };
    setParams(preState => ({
      ...preState,
      [vendorType]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  const removeStore = (vendorType, index) => {
    let newStore;
    if (index === -1) {
      setParams(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [vendorType]: [],
        },
      }));
    } else {
      const l = [...params?.formStore[vendorType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setParams(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [vendorType]: newStore,
        },
      }));
    }
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*} 
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          ref={formRef}
          items={[
            {
              label: {
                text: t('Vendor Name'),
              },
              dataField: 'vendorName',
              editorType: 'dxTextBox',
            },
            {
              dataField: 'vendorType',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setShowPopupByType('vendorType');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.vendorType[0]?.name,
                      onClick: () => removeStore('vendorType', 0),
                      visible: !!params?.formStore?.vendorType[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.vendorType[1]?.name,
                      onClick: () => removeStore('vendorType', 1),
                      visible: !!params?.formStore?.vendorType[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${params?.formStore?.vendorType?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: params?.formStore?.vendorType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.vendorType?.length,
                      onClick: () => removeStore('vendorType', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('vendorType'),
                    },
                  },
                ],
              },
              label: {
                text: t('Vendor Type'),
              },
            },
            {
              label: { text: t('Use') },
              dataField: 'use',
              editorType: 'dxTagBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                items: [
                  {
                    label: t('Use'),
                    value: true,
                  },
                  {
                    label: t('Not Use'),
                    value: false,
                  },
                ],
              },
            },
            {
              label: {
                text: t('Representative'),
              },
              dataField: 'vendorRepresentative',
              editorType: 'dxTextBox',
            },
          ]}
          labelLocation="top"
          colCount={2}
        />
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
