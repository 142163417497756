/**
 * Update date: 22-05-2023
 * Screen 2017.2
 */
import React, { useState, useRef, useEffect } from 'react';
import 'jspdf-autotable';
import useAxios from 'axios-hooks';
import TextBoxes from './B2BTextArea';
import FormSearch from './FormSearch';
import useFormatTime from 'hooks/useFormatTime';
import LoadPanel from 'app/components/LoadPanel';
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import './styles.scss';
import { useApp } from 'app';
import { dateFormatStr } from 'utils/format';
  /**
   * create context
   */
export const MallContext = React.createContext({
  mallData: [],
});

export default function B2BSendHistory() {
  const { themePro }: any = useApp();
  const [param, setParam] = useState({
    channelSec: 'B2B',
    mallCode: '',
    date: new Date(),
    target: 'stock,order,goods,ctorder,receive,takeout,forward,return,center',
    keyword: '',
  });
  const [selectedRow, setSelectedRow] = useState<any>();
  const [mallData, setMallData] = useState<any>([]);
  const [showModalView, setShowModalView] = useState<boolean>(false);
  const { DateTimeFormat } = useFormatTime();
  const dataGridRef: any = useRef(null);
  const { t }: any = useDictionary({
    programId: '2017.2',
  });
  const [{ data: dataMall, loading: loadingDataMall }] = useAxios({
    url: '/ecommerce/mall-info',
    method: 'POST',
    data: {},
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (dataMall && !loadingDataMall) {
      setMallData(dataMall?.data);
    }
  }, [dataMall, loadingDataMall]);

  const [{ data: dataMallInfo, loading: loadingMallInfo }, refetchData] =
    useAxios(
      {
        url: '/ecommerce/mall-api-log',
        method: 'POST',
        data: {
          ...param,
          channelSec: param.channelSec,
          // mallCode: param.mallCode,
          mallCode: 'AOS',
          date: dateFormatStr(param.date),
          target: param.target
            ? param.target.split(',').map(item => item.trim())
            : [],
          keyword: param.keyword,
        },
      },
      {
        manual: true,
      },
    );

  /**
   * render view
   *
   * @param {*} record
   * @return {*}
   */
  const renderView = (record: any) => {
    return (
      <div
        className="cell-link"
        onClick={() => {
          setSelectedRow(record?.data);
          setShowModalView(true);
        }}
      >
        {t('View')}
      </div>
    );
  };

  /**
   * on click
   *
   * @param {*} recData
   */
  const onClick = recData => {
    setSelectedRow(recData.recData.recData);
    setShowModalView(true);
  };

  const columns: any = [
    {
      dataField: 'apiType',
      caption: t('Target'),
      alignment: themePro ? 'left' : 'center',
      allowEditing: false,
    },
    {
      dataField: 'logId',
      caption: t('Log ID'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'apiUrl',
      caption: t('Api URL'),
      alignment: themePro ? 'left' : themePro ? 'left' : 'left',
      allowEditing: false,
    },
    {
      dataField: 'startDate',
      caption: t('Start Datetime'),
      alignment: themePro ? 'left' : 'center',
      dataType: 'date',
      format: DateTimeFormat,
      allowEditing: false,
    },
    {
      dataField: 'endDate',
      caption: t('End Datetime'),
      alignment: themePro ? 'left' : 'center',
      dataType: 'date',
      format: DateTimeFormat,
      allowEditing: false,
    },
    {
      dataField: '',
      caption: t('Action'),
      alignment: themePro ? 'left' : 'center',
      cellRender: renderView,
    },
  ];

  const value: any = {
    mallData,
    refetchData,
  };

  const loadingAll = loadingMallInfo;

  return (
    <MallContext.Provider value={value}>
      <>
        <LoadPanel visible={loadingAll} />
        <div className="b2b-send-history">
          <div className="main">
            <FormSearch
              param={param}
              setParam={setParam}
              refetchData={refetchData}
            />
          </div>
          <div className="main">
            <DataGridFull
              ref={dataGridRef}
              dataSource={dataMallInfo?.data}
              columns={columns}
              options={{
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift({
                    location: 'before',
                    template: 'totalCount',
                  });
                },
                selection: {
                  mode: 'single',
                  showCheckBoxesMode: 'onClick',
                },
                columnAutoWidth: true,
              }}
            />
          </div>
          <div className="main">
            <TextBoxes
              visible={showModalView}
              onHiding={() => {
                setShowModalView(!showModalView);
              }}
              param={param}
              selectedRow={selectedRow}
            />
          </div>
        </div>
      </>
    </MallContext.Provider>
  );
}
