/**
 * Update date: 06-06-2023
 * Screen 2064.7
 */
import React, { useState, useContext, createContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));
/**
 * create context
 */
const EmptyBottleCarryOutRegistrationContext = createContext({});

EmptyBottleCarryOutRegistrationContext.displayName =
  'RegisterDeliveryEmptyBottlesContext';

export const useEmptyBottleCarryOutRegistration = () => {
  const context = useContext(EmptyBottleCarryOutRegistrationContext);
  return context;
};

const EmptyBottleCarryOutRegistration = () => {
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    returnBackId: '',
    vendorTp: '',
    vendorTpNm: '',
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      vendor: [],
      vendorType: [],
    },
    vendor: '',
    vendorType: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ loading: loadingPdf }, executeFetchPdf] = useAxios(
    {
      url: `/warehouse/return-container/print`,
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const value: any = {
    data,
    setData,
    refetchData,
    modeView,
    setModeView,
    formSearchData,
    setFormSearchData,
    executeFetchPdf,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <EmptyBottleCarryOutRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingPdf} />
        {renderContent()}
      </React.Suspense>
    </EmptyBottleCarryOutRegistrationContext.Provider>
  );
};

export default EmptyBottleCarryOutRegistration;
