/**
 * Update date: 22-05-2023
 * Screen 2017.1
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ListMall from './ListMall';
import { useProductType } from 'hooks/useProductType';
/**
 * create context
 */
const MallProductMasterContext = createContext({
  params: {},
  mallProductData: {} || null,
  setParams: () => {},
});

MallProductMasterContext.displayName = 'MallProductMasterContext';

export const useMallProductMaster = () => {
  const context = useContext(MallProductMasterContext);
  return context;
};

export default function MallProductMaster() {
  const [params, setParams] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    mallInfoId: '',
    largeCategories: [],
    mediumCategories: [],
    smallCategories: [],
    subCategories: [],
    productGroupIds: [],
    productIds: [],
  });
  const [mallProductData, setMallProductData] = useState({});
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  // Get data mall product master
  const [{ data: mallProductRes, loading: mallProductLoading }, refetchData] =
    useAxios(
      {
        url: 'warehouse/mall-stock',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  const [
    { data: mallProductUpdateRes, loading: mallProducUpdatetLoading },
    updateMallProduct,
  ] = useAxios(
    {
      url: 'warehouse/mall-stock',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );

  const value: any = {
    params,
    setParams,
    mallProductData,
    refetchData,
    updateMallProduct,
    mallProductUpdateRes,
    productTpPermission,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (mallProductRes?.data) {
      setMallProductData(mallProductRes?.data);
    }
  }, [mallProductRes]);

  const loadingAll =
    mallProductLoading ||
    mallProducUpdatetLoading ||
    loadingProductTpPermission;

  return (
    <MallProductMasterContext.Provider value={value}>
      <>
        <LoadPanel visible={loadingAll} />
        <ListMall />
      </>
    </MallProductMasterContext.Provider>
  );
}
