/**
 * Update date: 24-05-2023
 * Screen 2041.4
 */
import { useApp } from 'app';
import { DataGridSimple } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useAxios from 'axios-hooks';
import { Button, Form, LoadPanel, Popup, ScrollView } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { dateTimeRequestFormat } from 'utils/format';
import { notification } from 'utils/notification';
import { useIncomingLoadreg } from '..';

function PopupLoading() {
  const { themePro }: any = useApp();
  const context: any = useIncomingLoadreg();
  const {
    params,
    dataLoading,
    modeView,
    setModeView,
    storeComplete,
    storeWait,
    refWait,
    refComplete,
  } = context;
  const dataRef: any = useRef(null);
  const formRef: any = useRef(null);
  const { t } = useDictionary({ programId: '2041.4' });
  const [formData, setFormData] = useState<any>({
    zoneId: null,
    productCd: '',
    productNm: '',
    optionNm: '',
    receiveQt: null,
    zoneFromNm: '',
    expirationEndDate: null,
    location: '',
  });
  const [dataLocation] = useState<any>([]);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [locationResponse, setLocationResponse] = useState([]);
  const [errorBarcode, setErrorBarcode] = useState('');
  const store: any = new ArrayStore({
    data: dataLocation,
    key: 'ID',
  });
  const [{ loading, data }] = useAxios({
    url: `ecommerce/load-rec-pda/${modeView?.id}`,
    method: 'GET',
  });

  /**
   * on scan location
   *
   */
  const onScanLocation = () => {
    if (
      locationResponse.some((o: any) => o?.locationCd === formData?.location)
    ) {
      const location: any = locationResponse.find(
        (o: any) => o?.locationCd === formData?.location,
      );
      const isExisted = store?._array?.some(
        (o: any) => o?.locationToId === location?.locationId,
      );
      const newData = {
        ...dataLoading,
        locationToId: location?.locationId,
        locationToNm: location?.locationNm,
        zoneToId: params?.zoneData?.zoneId,
        zoneToNm: params?.zoneData?.zoneName,
        idParent: dataLoading?.ID,
        ID: `${dataLoading?.ID}-${location?.locationId}`,
        status: 'Loaded',
        moveQuantity: 1,
        expirationEndDate: dateTimeRequestFormat(formData?.expirationEndDate),
      };
      const otherQty = (store?._array || [])
        .filter((o: any) => o?.locationToId !== location?.locationId)
        .reduce((sum, o) => o?.moveQuantity + sum, 0);
      if (isExisted) {
        const data = (store?._array || []).find(
          (o: any) => o?.locationToId === location?.locationId,
        );
        let moveQuantity = data?.moveQuantity;
        if (+formData?.receiveQt > data?.moveQuantity + otherQty) {
          moveQuantity += 1;
        } else {
          notification({
            message: t(
              'Quantity can not greater than Order Quantity. Please check again',
            ),
            type: 'error',
          });
        }
        store.update(data?.ID, {
          ...data,
          moveQuantity: moveQuantity,
          expirationEndDate: formData?.expirationEndDate,
        });
      } else {
        if (+formData?.receiveQt < newData?.moveQuantity + otherQty) {
          notification({
            message: t(
              'Quantity can not greater than Order Quantity. Please check again',
            ),
            type: 'error',
          });
        } else {
          store
            .insert(newData)
            .done(() => {})
            .fail(() => {});
        }
      }
      setErrorBarcode('');
    } else {
      setErrorBarcode(t('Location not found!'));
    }
    formRef?.current?.instance?.updateData('location', '');
    formRef?.current?.instance?.getEditor('location')?.focus();
    dataRef?.current?.instance?.refresh();
  };

  /**
   * on complete
   *
   * @return {*}
   */
  const onComplete = () => {
    const list =
      dataRef?.current?.instance?.getDataSource()?._store?._array || [];
    if (!list?.length) return;
    const qty = list?.reduce((sum, o) => o?.moveQuantity + sum, 0);
    if (+formData?.receiveQt > qty) {
      setShowPopupConfirm(true);
      return;
    }
    onCompleteSuccess();
  };

  /**
   * on complete success
   *
   */
  const onCompleteSuccess = () => {
    const list =
      dataRef?.current?.instance?.getDataSource()?._store?._array || [];
    list.forEach(o => {
      storeComplete
        .insert(o)
        .done(() => {})
        .fail(() => {});
    });
    const listParent = uniqBy(list, 'idParent');
    listParent.forEach(o => {
      storeWait
        .remove(o?.idParent)
        .done(() => {})
        .fail(() => {});
    });
    setModeView({
      type: 'complete',
      id: modeView?.id,
      expirationEndDate: formData?.expirationEndDate,
    });
    refWait?.current?.instance?.refresh();
    refComplete?.current?.instance?.refresh();
  };

  /**
   * on validate number
   *
   * @param {*} e
   * @return {*}
   */
  const validateNumber = e => {
    const otherQty = (store?._array || [])
      .filter((o: any) => o?.locationToId !== e?.data?.locationToId)
      .reduce((sum, o) => o?.moveQuantity + sum, 0);
    return +formData?.receiveQt >= e?.data?.moveQuantity + otherQty;
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Loading')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('Loading Complete')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={() => onComplete()}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={() => setModeView({ type: 'wait' })}
                />
              </div>
            </div>
          )}
          <Form
            ref={formRef}
            formData={formData}
            showColonAfterLabel={false}
            className="body-padding-white"
            items={[
              {
                dataField: 'productCd',
                label: {
                  text: t('Product Code'),
                },
                editorType: 'dxTextBox',
                editorOptions: {
                  readOnly: true,
                },
              },
              {
                dataField: 'productNm',
                label: {
                  text: t('Product Name'),
                },
                editorType: 'dxTextBox',
                editorOptions: {
                  readOnly: true,
                },
              },
              {
                dataField: 'optionNm',
                label: {
                  text: t('Option'),
                },
                editorType: 'dxTextBox',
                editorOptions: {
                  readOnly: true,
                },
              },
              {
                dataField: 'receiveQt',
                label: {
                  text: t('Actual Stock'),
                },
                editorType: 'dxTextBox',
                editorOptions: {
                  readOnly: true,
                },
              },
              {
                dataField: 'zoneFromNm',
                label: {
                  text: t('Zone'),
                },
                editorType: 'dxTextBox',
                editorOptions: {
                  readOnly: true,
                },
              },
              {
                dataField: 'expirationEndDate',
                label: {
                  text: t('Expiration End Date'),
                },
                editorType: 'dxDateBox',
              },

              {
                itemType: 'group',
                colSpan: 3,
                colCount: 3,
                items: [
                  {
                    dataField: 'location',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Scan the location'),
                    },
                    editorOptions: {
                      onEnterKey: () => {
                        onScanLocation();
                      },
                    },
                  },
                ],
              },
            ]}
            labelLocation="top"
            colCount={3}
          ></Form>
          {errorBarcode && <p className="cell-error-text"> {errorBarcode} </p>}
          <br />
          <DataGridSimple
            ref={dataRef}
            dataSource={store}
            columns={[
              {
                caption: t('Location'),
                dataField: 'locationToNm',
                allowEditing: false,
              },
              {
                caption: t('Qty'),
                dataField: 'moveQuantity',
                alignment: 'right',
                editorOptions: {
                  step: 0,
                },
                validationRules: [
                  {
                    type: 'required',
                  },
                  {
                    type: 'custom',
                    validationCallback: validateNumber,
                    message: t(
                      'Quantity can not greater than Order Quantity. Please check again',
                    ),
                  },
                ],
              },
            ]}
            options={{
              key: 'ID',
              columnAutoWidth: true,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    visible: !themePro,
                    location: 'after',
                    locateInMenu: 'never',
                    widget: 'dxButton',
                    options: {
                      icon: 'todo',
                      text: t('Loading Complete'),
                      onClick: () => onComplete(),
                    },
                  },
                  {
                    visible: !themePro,
                    location: 'after',
                    locateInMenu: 'never',
                    widget: 'dxButton',
                    options: {
                      icon: 'close',
                      text: t('Cancel'),
                      onClick: () => setModeView({ type: 'wait' }),
                    },
                  },
                );
              },
              paging: {
                pageSize: 10,
              },
              editing: {
                mode: 'cell',
                allowUpdating: true,
                selectTextOnEditStart: true,
              },
              height: themePro ? 'calc(100vh - 350px)' : 'auto',
            }}
          />
        </div>
        {showPopupConfirm && (
          <PopupConfirm
            title={t('Confirm Loading')}
            visible={showPopupConfirm}
            content={t(
              'Quantity less than Order Quantity. Do you want to save this bill?',
            )}
            onOk={onCompleteSuccess}
            onHiding={() => setShowPopupConfirm(false)}
          />
        )}
      </ScrollView>
    );
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (dataLoading) {
      setFormData(preState => ({
        ...preState,
        zoneId: params?.zoneData?.zoneId,
        productCd: dataLoading?.productCd,
        productNm: dataLoading?.productNm,
        optionNm: dataLoading?.optionNm,
        receiveQt: dataLoading?.receiveQt,
        zoneFromNm: params?.zoneData?.zoneName,
      }));
    }
  }, [dataLoading]);
  useEffect(() => {
    if (data && !loading) {
      setLocationResponse(data?.data);
    }
  }, [loading, data]);
  const loadingAll = loading;
  return (
    <Popup
      className="modal-content-popup"
      visible={true}
      title={t('Loading')}
      onHidden={() => setModeView({ type: 'wait' })}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupLoading;
