/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Buyer from './Buyer';
import Products from './Products';
import './index.scss';
import Payment from './Payment';

interface IPopupOrder {
  orderId: number | undefined;
  onClose: () => void;
}

const PopupOrderContext = createContext({});

PopupOrderContext.displayName = 'PopupOrderContext';

export const usePopupOrder = () => {
  const context = useContext(PopupOrderContext);
  return context;
};

const PopupOrder = (props: IPopupOrder, ref) => {
  const { onClose, orderId } = props;
  const [productData, setProductData] = useState<any>({});
  const [formData, setFormData] = useState({
    orderNumber: '',
    mallId: '',
    store: '',
    orderDateTime: '',
  });
  const [formInformation, setFormInformation] = useState<any>({});
  const [shippingInformation, setShippingInformation] = useState<any>([]);
  const [taxInvoiceData, setTaxInvoiceData] = useState<any>([]);
  const [payments, setPayments] = useState<any>([]);
  const productRef: any = useRef(null);
  const [listCountry, setListCountry] = useState<any>([]);
  const [listClaimMethod, setListClaimMethod] = useState<any>([]);
  const { t } = useDictionary({ programId: '2043.0' });
  const { DateFormat, DateTimeFormat, DateTimeCompactFormat, TimeFormat } =
    useFormatTime();
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();

  const [{ data, loading }, refechtData] = useAxios(
    {
      url: `/ecommerce/order-popup/${orderId}`,
      method: 'GET',
    },
    { useCache: false, manual: true },
  );

  const [{ data: dataCountry, loading: loadingCountry }] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
      data: { codeType: 'CU' },
    },
    { useCache: false },
  );

  const [{ data: dataClaimMethod, loading: loadingClaimMethod }] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
      data: { codeType: 'OC' },
    },
    { useCache: false },
  );
  const [{ data: dataProductStatus }] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
      data: { codeType: 'MS' },
    },
    { useCache: false },
  );

  useEffect(() => {
    refechtData();
  },[])
  useEffect(() => {
    if (!data?.data) return;
    const { mall_order_id, mall_name, store_name, order_date } =
      data?.data?.orderAndBuyerResponse;
    setFormData({
      orderNumber: mall_order_id,
      mallId: mall_name,
      store: store_name,
      orderDateTime: order_date,
    });
    setFormInformation({
      ...data?.data?.orderAndBuyerResponse,
      productDate: data?.data?.productsResponse[0]?.reservation_dt || '',
      productTime: data?.data?.productsResponse[0]?.reservation_dt || '',
    });
    setShippingInformation(
      (data?.data?.shippingInformationResponse || [])?.map((o, i) => ({
        ...o,
        id_no: 'no-' + i,
      })),
    );
    setPayments(
      (data?.data?.paymentResponse || [])?.map((o, i) => ({
        ...o,
        id_no: `${i + 1}`,
      })),
    );
    setTaxInvoiceData(
      (data?.data?.shippingInformationResponse || [])
        ?.filter(o => o?.store_taxbill_id)
        ?.map(o => ({ ...o })),
    );
  }, [data]);

  useEffect(() => {
    if (!loadingCountry && dataCountry) {
      setListCountry(
        dataCountry?.data
          ?.filter(o => o?.use)
          .map(o => ({
            label: o?.name,
            value: o?.code,
          })),
      );
    }
  }, [dataCountry, loadingCountry]);
  useEffect(() => {
    if (!loadingClaimMethod && dataClaimMethod) {
      setListClaimMethod(
        dataClaimMethod?.data
          ?.filter(o => o?.use)
          .map(o => ({
            label: o?.name,
            value: o?.code,
          })),
      );
    }
  }, [dataClaimMethod, loadingClaimMethod]);

  const statusSelect = useMemo(() => {
    if (!dataProductStatus?.data) return [];
    const codes = ['N10', 'N30', 'N40', 'C40', 'P10', 'P20', 'P30'];
    let arrayStatus: any = [];
    dataProductStatus?.data?.map(o => {
      if (codes?.some(s => s === o?.code)) {
        arrayStatus.push(o);
      }
    });
    return arrayStatus;
  }, [dataProductStatus]);

  const storeClaims: any = new ArrayStore({
    data: data?.data?.claimsManagementResponse,
    key: ['order_id', 'line_id'],
  });

  const storeShipping: any = new ArrayStore({
    data: shippingInformation,
    key: 'id_no',
  });

  const storeTaxInvoice: any = new ArrayStore({
    data: taxInvoiceData,
    key: ['invoice_no', 'receivable_cd', 'tax_tp'],
  });
  const storePayment: any = new ArrayStore({
    data: payments,
    key: 'id_no',
  });

  const storeProduct: any = new ArrayStore({
    data: data?.data?.productsResponse?.map((o, i) => ({
      ...o,
      id_no: 'no-' + i,
    })),
    key: 'id_no',
  });

  const informationItem = (title, value, date?) => {
    return (
      <span
        style={{
          display: 'inline-block',
          padding: 10,
          backgroundColor: '#f2f4f7',
          borderRadius: 6,
          margin: '10px 10px 0 0',
        }}
      >
        <span style={{ color: '#667085' }}>{t(title)}: </span>
        <span style={{ color: '#101828', fontWeight: 600 }}>
          {date ? moment(value).format('MM/DD/YYYY HH:mm:ss') : value}
        </span>
      </span>
    );
  };

  // const onOk = () => {
  //   productRef?.current.onSaveProduct();
  // };

  const numberToCurrency = (x, format) => {
    if (!x && x !== 0) return Number(0).toFixed(format?.precision || 0);
    return x
      .toFixed(format?.precision || 0)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  };

  const content = () => {
    return (
      <div className="popup-order-custom">
        <LoadPanel visible={loading} />
        <div className="modal-popup-header">
          <span className="title-page">{t('Order')}</span>
          <div>
            {/* <Button
              stylingMode="contained"
              type="default"
              text={t('Ok')}
              icon="check"
              style={{ marginRight: 5 }}
              onClick={onOk}
            /> */}
            <Button
              stylingMode="contained"
              text={t('Close')}
              icon="close"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="body-padding-white" style={{ paddingBottom: 10 }}>
          <div className="dx-form-group-caption">{t('Order Information')}</div>
          <div>
            {informationItem('Order Number', formData.orderNumber)}
            {informationItem('Mall ID', formData.mallId)}
            {informationItem('Store', formData.store)}
            {informationItem('Order Date Time', formData.orderDateTime)}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <TabPanel
            className="custom-tabpanel-border-none"
            deferRendering={false}
            swipeEnabled={false}
          >
            <TabItem title={t('Buyer')}>
              <Buyer />
            </TabItem>
            <TabItem title={t('Products')}>
              <Products />
            </TabItem>
            <TabItem title={t('Payment')}>
              <Payment />
            </TabItem>
          </TabPanel>
        </div>
      </div>
    );
  };

  const value: any = {
    storeClaims,
    storeShipping,
    storeProduct,
    t,
    formData,
    productData,
    setProductData,
    DateFormat,
    DateTimeFormat,
    DateTimeCompactFormat,
    QtyFormat,
    AmountFormat,
    DoubleFormat,
    statusSelect,
    listCountry,
    formInformation,
    listClaimMethod,
    TimeFormat,
    productRef,
    numberToCurrency,
    storeTaxInvoice,
    storePayment,
    refechtData,
  };
  return (
    <PopupOrderContext.Provider value={value}>
      <Popup
        className="modal-content-popup"
        visible={true}
        title={t('Product')}
        contentRender={content}
        animation={{ show: undefined, hide: undefined }}
        height={'100vh'}
        width={'90vw'}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
    </PopupOrderContext.Provider>
  );
};

export default forwardRef(PopupOrder);
