/**
 * Update date: 15-05-2023
 * Screen 1044.2
 */
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectPostCode from 'app/components/PopupCommon/PopupSelectPostCode';
import PopupSelectUserCenter from 'app/components/PopupCommon/PopupSelectUserCenter';
import FileUploadPreviews from 'app/components/Templates/FileUploadPreviews';
import useAxios from 'axios-hooks';
import { CONSTANT } from 'constant';
import { Button, Form, Template } from 'devextreme-react';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useRegisteringStore } from '..';
import LoadPanel from 'app/components/LoadPanel';
import { restrictKeyboardPhone } from 'utils/common/restrict-keyboard-phone';
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { dateFormatStr } from 'utils/format';
import AddStoreShippingMethod from './AddStoreShippingMethod';
import { replaceWhiteSpaceValueChange } from 'utils/common/replaceWhiteSpaceValueChange';
import ArrayStore from 'devextreme/data/array_store';
import StoreManager from './StoreManager';

const defaultParams = {
  storeNo: null,
  storeCd: '',
  storeNm: '',
  storeShortNm: '',
  useflag: true,
  onlineApprovalTf: false,
  shippingTrackingTf: false,
  dealStrDt: '',
  dealEndDt: '',
  loanLimitAmount: 0,
  storeSize: 0,
  accoutingcd: '',
  oldCd: '',
  representative: '',
  email: '',

  mobileZipCode: '+(82)',
  phoneZipCode: '+(82)',
  faxZipCode: '+(82)',

  mobile: '',
  phone: '',
  fax: '',
  storePostCd: '',
  storeAddressBasic: '',
  storeAddressDetail: '',
  storeNt: '',

  bussinessNumber: '',
  comporationNumber: '',
  storeBussiness: '',
  businessIndustry: '',

  paymentAccountNum: '',
  paymentDepositor: '',

  storeManager: '',
  managerNt: '',

  deliveryReceiver: '',
  deliveryPostCd: '',
  deliveryAddressBasic: '',
  deliveryAddressDetail: '',

  storeTp: '',
  unitPrice: '',
  country: '',
  paymentBank: '',
  officeManager: '',
  salesManager: '',
  divisionLevel1: '',
  divisionLevel2: '',
  divisionLevel3: '',
  divisionLevel4: '',
  interlockCd1: '',
  interlockCd2: '',
};

const listTypeCommon = {
  storeTp: {
    codeType: 'ST',
    title: 'Store Type',
  },
  unitPrice: {
    codeType: 'SP',
    title: 'Supply Unit Price Group',
  },
  paymentBank: {
    codeType: 'BK',
    title: 'Bank',
  },
  country: {
    codeType: 'CU',
    title: 'Country',
  },
  officeManager: {
    codeType: 'OM',
    title: 'Office Manager',
  },
  salesManager: {
    codeType: 'SM',
    title: 'Sales Manager',
  },
  storePostCd: {
    codeType: 'SC',
  },
  deliveryPostCd: {
    codeType: 'DC',
  },
};

function FormCreate(props) {
  const { themePro }: any = useApp();
  const context: any = useRegisteringStore();
  const formRef: any = useRef(null);
  const listPhone: any = CONSTANT.listPhone;
  const zipCodePhone = listPhone.filter(o => o?.value);
  const { modeView, setModeView, onUpdateData, t } = context;
  const [showPopupByType, setShowPopupByType] = useState('');
  const [showModalConfirm, setShowModalConfirm] = useState<any>(false);
  const [contentPopupConfirm, setContentPopupConfirm] = useState('');
  const [elementSwitch, setElementSwitch] = useState<any>(null);
  const [formData, setFormData] = useState<any>({
    ...defaultParams,
    formStore: {
      storeTp: null,
      unitPrice: null,
      country: null,
      paymentBank: null,
      officeManager: null,
      salesManager: null,
    },
  });
  const [documentList, setDocumentList] = useState([]);
  const [receivableShippingList, setReceivableShippingList] = useState<any>([
    {
      receivableGroup: '1',
      receivableGroupName: 'Normal',
      shippingMethod: '1',
      shippingMethodName: 'Center Pickup',
    },
    {
      receivableGroup: '2',
      receivableGroupName: 'Alcohol',
      shippingMethod: '1',
      shippingMethodName: 'Center Pickup',
    },
  ]);
  const [storeManagerList, setStoreManagerList] = useState([]);

  const { DateFormat } = useFormatTime();
  const { IntegerFormat } = useFormat();
  const [{ loading }, refetchData] = useAxios(
    {
      url: 'user/store/',
      method: 'GET',
    },
    { manual: true, autoCancel: true },
  );

  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: '/user/store',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );

  const storedManagerData: any = new ArrayStore({
    data: storeManagerList,
  });

  /**
   * fetch data
   */
  useEffect(() => {
    if (modeView?.type !== 'edit' || !modeView?.storeNo) return;
    const fetchData = async () => {
      if (!modeView?.storeNo) return;
      const res = await refetchData({
        url: '/user/store/' + modeView?.storeNo,
      });
      if (res?.data?.status === '200') {
        const { data } = res?.data;
        let storeManagerName = '';
        const storeManagerMapping = (data?.storeManagerList || [])
          ?.sort((a, b) => b?.store_manager_id - a?.store_manager_id)
          .map((o, idx) => {
            if (o?.main_contact_tf) {
              storeManagerName = o?.store_manager_nm;
            }
            return { ...o, ID: idx + 1 };
          });
        const dataForm = {
          storeNo: data?.storeNo,
          storeCd: data?.storeCd,
          storeNm: data?.storeNm,
          storeShortNm: data?.storeShortNm,
          useflag: data?.useflag,
          onlineApprovalTf: data?.onlineApprovalTf || false,
          shippingTrackingTf: data?.shippingTrackingTf || false,
          dealStrDt: data?.dealStrDt,
          dealEndDt: data?.dealEndDt,
          loanLimitAmount: data?.loanLimitAmount || 0,
          storeSize: data?.storeSize || 0,
          interlockCd1: data?.interlockCd1,
          interlockCd2: data?.interlockCd2,
          accoutingcd: data?.accoutingcd,
          oldCd: data?.oldCd,
          representative: data?.representative,
          email: data?.email,
          mobileZipCode: data?.mobile?.slice(0, 5),
          phoneZipCode: data?.phone?.slice(0, 5),
          faxZipCode: data?.fax?.slice(0, 5),
          mobile: data?.mobile?.slice(5, data?.mobile?.length),
          phone: data?.phone?.slice(5, data?.phone?.length),
          fax: data?.fax?.slice(5, data?.fax?.length),
          storePostCd: data?.storePostCd,
          storeAddressBasic: data?.storeAddressBasic,
          storeAddressDetail: data?.storeAddressDetail,
          storeNt: data?.storeNt,
          bussinessNumber: data?.bussinessNumber,
          comporationNumber: data?.comporationNumber,
          storeBussiness: data?.storeBussiness,
          businessIndustry: data?.businessIndustry,
          paymentAccountNum: data?.paymentAccountNum,
          paymentDepositor: data?.paymentDepositor,
          storeManager: storeManagerName || data?.storeManager,
          managerNt: data?.managerNt,
          deliveryReceiver: data?.deliveryReceiver,
          deliveryPostCd: data?.deliveryPostCd,
          deliveryAddressBasic: data?.deliveryAddressBasic,
          deliveryAddressDetail: data?.deliveryAddressDetail,
          formStore: {
            storeTp:
              data?.storeTpName && data?.storeTp
                ? {
                    name: data?.storeTpName,
                    code: data?.storeTp,
                  }
                : null,
            unitPrice:
              data?.unitPriceCode && data?.unitPrice
                ? {
                    name: data?.unitPrice,
                    code: data?.unitPriceCode,
                  }
                : null,
            country:
              data?.countryName && data?.country
                ? {
                    name: data?.countryName,
                    code: data?.country,
                  }
                : null,
            paymentBank:
              data?.paymentBankName && data?.paymentBank
                ? {
                    name: data?.paymentBankName,
                    code: data?.paymentBank,
                  }
                : null,
            officeManager:
              data?.officeManagerId && data?.officeManagerName
                ? {
                    name: data?.officeManagerName,
                    code: data?.officeManagerId,
                  }
                : null,
            salesManager:
              data?.salesManagerName && data?.salesManagerId
                ? {
                    name: data?.salesManagerName,
                    code: data?.salesManagerId,
                  }
                : null,
          },
        };
        setFormData(dataForm);
        setDocumentList(data?.storeDocumentList);
        setReceivableShippingList(data?.receivableShippingList || []);
        setStoreManagerList(storeManagerMapping);
      } else {
        notification({ res });
      }
    };
    fetchData();
  }, [modeView?.type]);

  /**
   * refresh store code
   *
   */
  const refreshStoreCode = async () => {
    const resStoreCode = await refetchData({
      url: '/user/store/generate-store-code',
      method: 'GET',
    });

    if (resStoreCode?.data?.status === '200') {
      setFormData({ ...formData, storeCd: resStoreCode?.data.data });
    } else {
      notification({
        res: resStoreCode,
      });
    }
  };

  /**
   * on submit data
   *
   * @param {*} { type }
   */
  const submitData = async ({ type }) => {
    const dataReq = {
      ...formData,
      mobile: formData?.mobile
        ? formData?.mobileZipCode + formData?.mobile
        : '',
      phone: formData?.phone ? formData?.phoneZipCode + formData?.phone : '',
      fax: formData?.fax ? formData?.faxZipCode + formData?.fax : '',
      storeTp: formData?.formStore?.storeTp?.code || null,
      unitPrice: formData?.formStore?.unitPrice?.code || null,
      country: formData?.formStore?.country?.code || null,
      paymentBank: formData?.formStore?.paymentBank?.code || null,
      officeManager: formData?.formStore?.officeManager?.code || null,
      salesManager: formData?.formStore?.salesManager?.code || null,
      dealStrDt: dateFormatStr(formData?.dealStrDt),
      dealEndDt: dateFormatStr(formData?.dealEndDt),
      onlineApprovalTf: formData?.onlineApprovalTf || false,
      shippingTrackingTf: formData?.shippingTrackingTf || false,
      receivableShippingList,
      storeManagers: (storedManagerData?._array || [])
        ?.sort((a, b) => a?.ID - b?.ID)
        ?.map(o => ({
          storeManagerId: o?.store_manager_id || 0,
          storeManagerNm: o?.store_manager_nm,
          mainContactTf: o?.main_contact_tf || false,
          email: o?.email,
          division: o?.division,
          position: o?.position,
          mobile: o?.mobile,
        })),
    };
    delete dataReq.mobileZipCode;
    delete dataReq.phoneZipCode;
    delete dataReq.faxZipCode;
    delete dataReq.formStore;
    const res = await refetchUpdate({
      data: dataReq,
    });
    notification({
      res,
    });
    if (res?.data?.status === '201') {
      const type = !formData?.storeNo ? 'create' : 'update';
      onUpdateData({ data: res?.data?.data, type });
    }
  };

  /**
   * on valid submit
   *
   * @return {*}
   */
  const handleSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) {
      return notification({
        type: 'error',
        message: t('Please check required fields.'),
      });
    }
    submitData({ type: 'list' });
  };

  /**
   * update store
   *
   * @param {*} storeType
   * @param {*} data
   */
  const updateStore = (storeType, data) => {
    const newStore = { ...formData?.formStore, [storeType]: data };
    setFormData(preState => ({
      ...preState,
      [storeType]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
    // if (storeType === 'shippingMethod') {
    //   setDataLocal({
    //     shippingMethod: data,
    //   });
    // }
  };
  /**
   * update manager
   *
   * @param {*} storeType
   * @param {*} data
   */
  const updateManager = (storeType, data) => {
    const newStore = {
      ...formData?.formStore,
      [storeType]: {
        ...data,
        code: data?.userId,
      },
    };
    setFormData(preState => ({
      ...preState,
      [storeType]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  /**
   * remove store
   *
   * @param {*} storeType
   */
  const removeStore = storeType => {
    setFormData(preState => ({
      ...preState,
      formStore: { ...preState?.formStore, [storeType]: null },
    }));
    // if (storeType === 'shippingMethod') {
    //   setDataLocal({
    //     shippingMethod: null,
    //   });
    // }
  };
  /**
   * on change post code
   *
   * @param {*} popupType
   * @param {*} data
   * @param {*} dataVector
   */
  const onChangePostCode = (popupType, data, dataVector) => {
    let dataReqVector: any = {};
    if (dataVector) {
      const structure = dataVector?.response?.refined?.structure;
      const point = dataVector?.response?.result?.point;
      dataReqVector.divisionLevel0 = structure?.level0;
      dataReqVector.divisionLevel1 = structure?.level1;
      dataReqVector.divisionLevel2 = structure?.level2;
      dataReqVector.divisionLevel3 = structure?.level3;
      dataReqVector.divisionLevel4 = structure?.level4A;
      dataReqVector.divisionLevel5 = structure?.level5;
      dataReqVector.gisLatitude = point?.x;
      dataReqVector.gisLongitude = point?.y;
    }
    setFormData({
      ...dataReqVector,
      ...formData,
      [popupType]: data?.zipNo,
      [popupType === 'storePostCd'
        ? 'storeAddressBasic'
        : 'deliveryAddressBasic']: data?.roadAddrPart1,
      [popupType === 'storePostCd'
        ? 'storeAddressDetail'
        : 'deliveryAddressDetail']: '',
      divisionLevel1: data?.siNm || '',
      divisionLevel2: data?.sggNm || '',
      divisionLevel3: data?.emdNm || '',
      divisionLevel4: data?.liNm || '',
    });
    setShowPopupByType('');
  };
  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'storeTp':
      case 'unitPrice':
      case 'paymentBank':
      case 'country':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="single"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data)}
          />
        );
      case 'officeManager':
      case 'salesManager':
        return (
          <PopupSelectUserCenter
            mode="single"
            visible={!!listTypeCommon?.[popupType]?.codeType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateManager(popupType, data)}
          />
        );
      case 'storePostCd':
      case 'deliveryPostCd':
        return (
          <PopupSelectPostCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            onHiding={() => {
              setShowPopupByType('');
            }}
            vector={popupType === 'storePostCd' ? true : false}
            onSubmit={(data, dataVector) =>
              onChangePostCode(popupType, data, dataVector)
            }
          />
        );
      default:
        return null;
    }
  };

  /**
   * on confirm
   *
   */
  const handleConfirm = async () => {
    submitData({ type: 'edit' });
    setShowModalConfirm(false);
    setElementSwitch(null);
  };
  /**
   * on close
   *
   */
  const handleClose = () => {
    formRef?.current?.instance.updateData('status', elementSwitch);
    setShowModalConfirm(false);
    setElementSwitch(null);
  };

  /**
   * on change document
   *
   * @param {*} data
   */
  const handleChangeDocumentList = data => {
    setDocumentList(data);
  };

  /**
   * on change receivable
   *
   * @param {*} [data=[]]
   * @param {boolean} [isDelete=false]
   */
  const handleChangeReceivableShippingList = (
    data: any = [],
    isDelete = false,
  ) => {
    if (isDelete) {
      setReceivableShippingList(prev => {
        return prev.filter(
          o =>
            !data?.some(
              t =>
                o.receivableGroup === t.receivableGroup &&
                o.shippingMethod === t.shippingMethod,
            ),
        );
      });
    } else {
      setReceivableShippingList(prev => [...prev, ...data]);
    }
  };

  /**
   * on field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'dealStrDt':
        if (formData.dealEndDt && e?.value && e?.value > formData.dealEndDt)
          formRef?.current?.instance.updateData('dealEndDt', null);
        formRef?.current?.instance?.updateDimensions();
        break;
      case 'dealEndDt':
        if (formData.dealStrDt && e.value && e?.value < formData.dealStrDt)
          formRef?.current?.instance.updateData('dealStrDt', null);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <LoadPanel visible={loading || loadingUpdate} />
      <BreadCrumbPremium
        onSubmit={() => handleSubmit()}
        onCancel={() => setModeView({ type: 'list' })}
      />
      <Form
        formData={formData}
        ref={formRef}
        showColonAfterLabel={false}
        items={[
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Store Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'storeNo',
                editorType: 'dxTextBox',
                label: {
                  text: t('ID'),
                },
                editorOptions: {
                  disabled: true,
                },
              },
              {
                dataField: 'storeCd',
                editorType: 'dxTextBox',
                label: {
                  text: t('Store Code'),
                },
                isRequired: true,
                editorOptions: {
                  onValueChanged: replaceWhiteSpaceValueChange,
                  buttons: [
                    {
                      name: 'refresh',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'refresh',
                        onClick: () => {
                          refreshStoreCode();
                        },
                      },
                    },
                  ],
                },
                validationRules: [
                  {
                    type: 'required',
                    message: t('Store Code is required'),
                  },
                  {
                    type: 'stringLength',
                    max: 30,
                    message: t('The maximum allowed characters are 30'),
                  },
                ],
              },
              {
                dataField: 'storeNm',
                editorType: 'dxTextBox',
                label: {
                  text: t('Store Name'),
                },
                isRequired: true,
                validationRules: [
                  {
                    type: 'required',
                    message: t('Store Name is required'),
                  },
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'storeShortNm',
                editorType: 'dxTextBox',
                label: {
                  text: t('Store Short Name'),
                },
                isRequired: true,
                validationRules: [
                  {
                    type: 'required',
                    message: t('Store Short Name is required'),
                  },
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'storeTp',
                editorType: 'dxTextBox',
                isRequired: !formData?.formStore?.storeTp,
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('storeTp');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.storeTp?.name,
                        onClick: () => removeStore('storeTp'),
                        visible: !!formData?.formStore?.storeTp,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('storeTp'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Store Type'),
                },
              },
              {
                dataField: 'unitPrice',
                editorType: 'dxTextBox',
                isRequired: !formData?.formStore?.unitPrice,
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('unitPrice');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.unitPrice?.name,
                        onClick: () => removeStore('unitPrice'),
                        visible: !!formData?.formStore?.unitPrice,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('unitPrice'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Supply Unit Price Group'),
                },
              },
              {
                itemType: 'group',
                colCount: 2,
                items: [
                  {
                    dataField: 'dealStrDt',
                    editorType: 'dxDateBox',
                    label: {
                      text: t('Deal Start Date'),
                    },
                    editorOptions: {
                      displayFormat: DateFormat,
                      // max: formData?.dealEndDt,
                    },
                    isRequired: true,
                  },
                  {
                    dataField: 'dealEndDt',
                    editorType: 'dxDateBox',
                    label: {
                      text: t('Deal End Date'),
                    },
                    editorOptions: {
                      displayFormat: DateFormat,
                      // min: formData?.dealStrDt,
                    },
                  },
                ],
              },
              {
                itemType: 'group',
                colCount: 3,
                items: [
                  {
                    dataField: 'useflag',
                    editorType: 'dxSwitch',
                    label: {
                      text: t('Use'),
                    },
                  },
                  {
                    dataField: 'onlineApprovalTf',
                    editorType: 'dxSwitch',
                    label: {
                      text: t('AOS Card Payment'),
                    },
                  },
                  {
                    dataField: 'shippingTrackingTf',
                    editorType: 'dxSwitch',
                    label: {
                      text: t('Shipping Tracking'),
                    },
                  },
                ],
              },
              {
                dataField: 'loanLimitAmount',
                editorType: 'dxNumberBox',
                label: { text: t('Set Loan Amount') },
                editorOptions: {
                  format: IntegerFormat,
                  step: 0,
                },
              },
              {
                itemType: 'group',
                colCount: 2,
                items: [
                  {
                    dataField: 'oldCd',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Old Code'),
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        max: 20,
                        message: t('The maximum allowed characters are 20'),
                      },
                    ],
                  },
                  {
                    dataField: 'accoutingcd',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Accounting Code'),
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        max: 20,
                        message: t('The maximum allowed characters are 20'),
                      },
                    ],
                  },
                ],
              },
              {
                dataField: 'representative',
                editorType: 'dxTextBox',
                label: {
                  text: t('Representative'),
                },
              },
              {
                itemType: 'group',
                colCount: 2,
                items: [
                  {
                    dataField: 'interlockCd1',
                    editorType: 'dxTextBox',
                    label: { text: t('Interlock Code 1') },
                    validationRules: [
                      {
                        type: 'stringLength',
                        max: 50,
                        message: t('The maximum allowed characters are 50'),
                      },
                    ],
                  },
                  {
                    dataField: 'interlockCd2',
                    editorType: 'dxTextBox',
                    label: { text: t('Interlock Code 2') },
                    validationRules: [
                      {
                        type: 'stringLength',
                        max: 50,
                        message: t('The maximum allowed characters are 50'),
                      },
                    ],
                  },
                ],
              },
              {
                dataField: 'country',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('country');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.country?.name,
                        onClick: () => removeStore('country'),
                        visible: !!formData?.formStore?.country,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('country'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Country'),
                },
              },
              {
                itemType: 'group',
                colSpan: 1,
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'mobileZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      displayExpr: 'label',
                      valueExpr: 'value',
                      items: zipCodePhone,
                    },
                    label: {
                      text: t('Mobile No'),
                    },
                  },
                  {
                    dataField: 'mobile',
                    editorType: 'dxTextBox',
                    colSpan: 2,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    label: {
                      text: '',
                      visible: false,
                    },
                  },
                ],
              },
              {
                itemType: 'group',
                colSpan: 1,
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'phoneZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      displayExpr: 'label',
                      valueExpr: 'value',
                      items: zipCodePhone,
                    },
                    label: {
                      text: t('Phone No'),
                    },
                  },
                  {
                    dataField: 'phone',
                    editorType: 'dxTextBox',
                    colSpan: 2,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    label: {
                      text: '',
                      visible: false,
                    },
                  },
                ],
              },
              {
                dataField: 'email',
                editorType: 'dxTextBox',
                label: {
                  text: t('Email'),
                },
                validationRules: [
                  {
                    type: 'email',
                    message: t('Email is valid'),
                  },
                ],
              },
              {
                itemType: 'group',
                colSpan: 1,
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'faxZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      displayExpr: 'label',
                      valueExpr: 'value',
                      items: zipCodePhone,
                    },
                    label: {
                      text: t('Fax No'),
                    },
                  },
                  {
                    dataField: 'fax',
                    editorType: 'dxTextBox',
                    colSpan: 2,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    label: {
                      text: '',
                      visible: false,
                    },
                  },
                ],
              },
              {
                dataField: 'storeSize',
                editorType: 'dxNumberBox',
                label: { text: t('Store Size') },
                editorOptions: {
                  format: IntegerFormat,
                  step: 0,
                },
              },
              {
                dataField: 'storePostCd',
                editorType: 'dxTextBox',
                label: {
                  text: t('Address (Zip/Postcode)'),
                },
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('storePostCd');
                  },
                  buttons: [
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('storePostCd'),
                      },
                    },
                  ],
                },
              },
              {
                dataField: 'storeAddressBasic',
                editorType: 'dxTextBox',
                label: {
                  text: t('Basic Address'),
                },
              },
              { itemType: 'empty' },
              {
                dataField: 'storeAddressDetail',
                editorType: 'dxTextBox',
                label: {
                  text: t('Detail Address'),
                },
              },
              {
                dataField: 'storeNt',
                editorType: 'dxTextArea',
                colSpan: 2,
                label: {
                  text: t('Note'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Business Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'bussinessNumber',
                editorType: 'dxTextBox',
                label: {
                  text: t('Business Number'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'comporationNumber',
                editorType: 'dxTextBox',
                label: {
                  text: t('Company Number'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'storeBussiness',
                editorType: 'dxTextBox',
                label: {
                  text: t('Business'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'businessIndustry',
                editorType: 'dxTextBox',
                label: {
                  text: t('Industry'),
                },
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    message: t('The maximum allowed characters are 100'),
                  },
                ],
              },
              {
                dataField: 'storeDocumentRequests',
                editorType: 'dxTextBox',
                label: {
                  text: t('Upload Document'),
                },
                colSpan: 2,
                template: 'FileUploadPreviews',
                editorOptions: {
                  request: {
                    url: 'user/upload/store-document',
                    method: 'POST',
                  },
                  value: documentList,
                  onChangeValue: handleChangeDocumentList,
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Payment Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'paymentBank',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('paymentBank');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.paymentBank?.name,
                        onClick: () => removeStore('paymentBank'),
                        visible: !!formData?.formStore?.paymentBank,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('paymentBank'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Bank'),
                },
              },
              {
                dataField: 'paymentAccountNum',
                editorType: 'dxTextBox',
                label: {
                  text: t('Account Number'),
                },
              },
              {
                dataField: 'paymentDepositor',
                editorType: 'dxTextBox',
                label: {
                  text: t('Depositor'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Manager Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'storeManager',
                editorType: 'dxTextBox',
                label: {
                  text: t('Store Manager'),
                },
                colSpan: 2,
                editorOptions: {
                  disabled: true,
                },
              },
              {
                dataField: 'officeManager',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('officeManager');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.officeManager?.name,
                        onClick: () => removeStore('officeManager'),
                        visible: !!formData?.formStore?.officeManager,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('officeManager'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Office Manager'),
                },
              },
              {
                dataField: 'salesManager',
                editorType: 'dxTextBox',
                editorOptions: {
                  // disabled: true,
                  onEnterKey: () => {
                    setShowPopupByType('salesManager');
                  },
                  buttons: [
                    {
                      name: 'label',
                      location: 'before',
                      options: {
                        icon: 'close',
                        text: formData?.formStore?.salesManager?.name,
                        onClick: () => removeStore('salesManager'),
                        visible: !!formData?.formStore?.salesManager,
                        rtlEnabled: true,
                      },
                    },
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('salesManager'),
                      },
                    },
                  ],
                },
                label: {
                  text: t('Sales Manager'),
                },
              },
              {
                dataField: 'managerNt',
                editorType: 'dxTextArea',
                colSpan: 2,
                label: {
                  text: t('Manager Note'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Delivery Information'),
            cssClass: 'body-padding-white mb-10 group-flex-start',
            items: [
              {
                dataField: 'deliveryReceiver',
                editorType: 'dxTextBox',
                label: {
                  text: t('Receiver'),
                },
                colSpan: 2,
              },
              {
                dataField: 'deliveryPostCd',
                editorType: 'dxTextBox',
                label: {
                  text: t('Zip/Postcode'),
                },
                editorOptions: {
                  onEnterKey: () => {
                    setShowPopupByType('deliveryPostCd');
                  },
                  buttons: [
                    {
                      name: 'search',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: 'search',
                        onClick: () => setShowPopupByType('deliveryPostCd'),
                      },
                    },
                  ],
                },
              },
              {
                itemType: 'group',
                items: [
                  {
                    dataField: 'deliveryAddressBasic',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Basic Address'),
                    },
                  },
                  {
                    dataField: 'deliveryAddressDetail',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Detail Address'),
                    },
                  },
                ],
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Store Shipping Method'),
            cssClass: 'body-padding-white mb-10 group-flex-start',
            items: [
              {
                // dataField: 'storeShippingMethod',
                colSpan: 2,
                template: 'AddStoreShippingMethodTemp',
                editorOptions: {
                  value: receivableShippingList,
                  onChangeValue: handleChangeReceivableShippingList,
                },
              },
            ],
          },
          {
            itemType: 'group',
            colSpan: 2,
            colCount: 2,
            caption: t('Store Employee Information'),
            cssClass: 'body-padding-white mb-10 group-flex-start',
            items: [
              {
                colSpan: 2,
                template: 'store_manager_temp',
                editorOptions: {
                  value: storedManagerData,
                  onMainContactChange: value =>
                    formRef?.current?.instance.updateData(
                      'storeManager',
                      value,
                    ),
                },
              },
            ],
          },
        ]}
        labelLocation="top"
        colCount={2}
        onFieldDataChanged={onFieldDataChanged}
      >
        <Template name="FileUploadPreviews" component={FileUploadPreviews} />
        <Template
          name="AddStoreShippingMethodTemp"
          component={AddStoreShippingMethod}
        />
        <Template name="store_manager_temp" component={StoreManager} />
      </Form>
      {/* <AddStoreShippingMethod /> */}
      {getPopupByType(showPopupByType)}
      {!themePro && (
        <div
          style={{
            textAlign: 'center',
            padding: '20px',
            width: '100%',
          }}
        >
          <Button
            text={t('Save')}
            type="normal"
            icon="save"
            stylingMode="contained"
            onClick={() => handleSubmit()}
            style={{ marginRight: 10 }}
          />
          <Button
            text={t('Cancel')}
            type="normal"
            stylingMode="contained"
            icon="close"
            onClick={() => setModeView({ type: 'list' })}
          />
        </div>
      )}
      <PopupConfirm
        visible={showModalConfirm}
        content={contentPopupConfirm}
        onOk={handleConfirm}
        onHiding={() => setShowModalConfirm(false)}
        onHidingCallback={() => handleClose()}
      />
    </div>
  );
}

export default FormCreate;
