/**
 * Update date: 25-05-2023
 * Screen 2041.7
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, { createContext, useContext, useEffect, useState } from 'react';
import FormSearch from './FormSearch/index';
import ListReceiving from './ListReceiving';
import { useProductType } from 'hooks/useProductType';
import useDictionary from 'hooks/useDictionary';

const defaultParams = {
  status: '',
  taskType: 2,
  receiveType: '',
  vendor: '',
  product: '',
  productGroup: '',
  productType: '',
  receiveTakeoutTp: '',
};
/**
 * create context
 */
const ReceivingInquiryContext = createContext({});

ReceivingInquiryContext.displayName = 'ReceivingInquiryContext';

export const useReceiving = () => {
  const context = useContext(ReceivingInquiryContext);
  return context;
};

export default function ReceivingInquiry() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({
    programId: '2041.7',
  });
  const [params, setParams] = useState({
    ...defaultParams,
    fromDate: new Date(),
    toDate: new Date(),
    formStore: {
      vendor: [],
      product: [],
      productGroup: [],
      largeCategoryCodes: [],
      mediumCategoryCodes: [],
      smallCategoryCodes: [],
      subCategoryCodes: [],
      productType: [],
      receiveTakeoutTp: [],
    },
  });

  // Get data receiving inquiry
  const [{ data, loading }, refetchReceivingData] = useAxios(
    {
      url: 'warehouse/receive/by-line',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );
  const store: any = new ArrayStore({
    data: data?.data?.map(o =>
      o?.taskType === 2
        ? {
            ...o,
            quantityPerPack: o?.quantityPerPack * -1,
            purchaseUnitPrice: o?.purchaseUnitPrice * -1,
            receiveQuantity: o?.receiveQuantity * -1,
            receiveEa: o?.receiveEa * -1,
            receiveAmount: o?.receiveAmount * -1,
            supplyAmount: o?.supplyAmount * -1,
            vatAmount: o?.vatAmount * -1,
            bottleAmount: o?.bottleAmount * -1,
            containerAmount: o?.containerAmount * -1,
            totalAmount: o?.totalAmount * -1,
            bottleUnitPrice: o?.bottleUnitPrice * -1,
            optionContainerUnitPrice: o?.optionContainerUnitPrice * -1,
          }
        : o,
    ),
    key: 'lineId',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      formStore: {
        ...preState?.formStore,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    t,
    store,
    params,
    setParams,
    refetchReceivingData,
  };

  const loadingAll = loading || loadingProductTpPermission;

  return (
    <ReceivingInquiryContext.Provider value={value}>
      <>
        <LoadPanel visible={loadingAll} />
        <FormSearch />
        <ListReceiving />
      </>
    </ReceivingInquiryContext.Provider>
  );
}
