/**
 * Update date: 15-05-2023
 * Screen 1044.2
 */
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import ListStore from './ListStore';
import FormCreate from './FormCreate';
import useDictionary from 'hooks/useDictionary';
// import useLocalStorage from 'hooks/useLocalStorage';

/**
 * create context
 */
const RegisteringStoreContext = createContext({});

RegisteringStoreContext.displayName = 'RegisteringStoreContext';

export const useRegisteringStore = () => {
  const context = useContext(RegisteringStoreContext);
  return context;
};
const defaultParams = {
  storeName: '',
  storeType: '',
  use: [true],
  representative: '',
  // ids: [],
  orderByName: 'storeNo',
  ascending: false,
};
export default function RegisteringStore() {
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    ...defaultParams,
    formStore: {
      storeType: [],
    },
  });
  const [listData, setListData] = useState<any>([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const { t }: any = useDictionary({
    programId: '1044.2',
  });
  // const [dataLocal, setDataLocal] = useLocalStorage('1044.2_dataList', {});
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: 'user/store',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: listData,
    key: 'storeNo',
  });

  /**
   * on update data
   *
   * @param {*} { data, type }
   */
  const onUpdateData = ({ data, type }) => {
    if (type === 'create') {
      setListData(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
      dataGridRef?.current?.instance?.refresh();
    } else {
      store
        .update(data?.storeNo, data)
        .done(() => {})
        .fail(() => {});
    }
    setModeView({ type: 'list' });
  };

  const value: any = {
    t,
    store,
    params,
    setParams,
    setModeView,
    modeView,
    onUpdateData,
    refetchData,
    // dataLocal,
    // setDataLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListStore ref={dataGridRef} />;
      case 'create':
      case 'edit':
        return <FormCreate />;
      default:
        return <NotFoundPage />;
    }
  };
  useEffect(() => {
    if (data?.data) {
      setListData(data?.data);
    }
  }, [data, loading]);
  const loadingAll = loading;

  return (
    <RegisteringStoreContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </RegisteringStoreContext.Provider>
  );
}
