/*
 * 2087.6 Sale Summary By Store
 * @since 2022/11/28
 */
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { DataGridFull } from 'app/components/DataGrid';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { useRef, useState, useMemo } from 'react';
import { useSaleSummaryByStoreContext } from '..';

/**
 * View detail data
 *
 * @return {*}
 */
function View() {
  const {
    modeView,
    storeView,
    AmountFormat,
    setModeView,
    t,
    formData,
    setListDataDetail,
  }: any = useSaleSummaryByStoreContext();

  useState<boolean>(false);
  const dataGridRef: any = useRef(null);
  const { DateFormat }: any = useFormatTime();

  // Render coumn grid
  const columns = useMemo(
    () =>
      formData?.searchBy
        ? [
            {
              dataField: 'receivable_nm',
              caption: t('Receivable Group'),
            },
            {
              dataField: 'forward_bill',
              caption: t('Forward Bill'),
            },
            {
              dataField: 'bussiness_n',
              caption: t('Business Number'),
            },
            {
              dataField: 'store_nm',
              caption: t('Store Name'),
            },
            {
              dataField: 'manager_nt',
              caption: t('Manager Note'),
              visible: false,
            },
            {
              dataField: 'taxation_supply',
              caption: t('Taxation Supply'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'taxation_vat',
              caption: t('Taxation VAT'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'taxfree_supply',
              caption: t('Tax Free Supply'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'taxzero_supply',
              caption: t('Tax Zero Supply'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'forward_amount',
              caption: t('Forward Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'bottle_amount',
              caption: t('Bottle Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'container_amount',
              caption: t('Container Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'container_takeback_amount',
              caption: t('Container Takeback Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'palette_amount',
              caption: t('Palette Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'total_amount',
              caption: t('Total Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
          ]
        : [
            {
              dataField: 'receivable_nm',
              caption: t('Receivable Group'),
            },
            {
              dataField: 'forward_bill',
              caption: t('Forward Bill'),
            },
            {
              dataField: 'forward_dt',
              caption: t('Forward Date'),
              dataType: 'date',
              format: DateFormat,
            },
            {
              dataField: 'taxation_supply',
              caption: t('Taxation Supply'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'taxation_vat',
              caption: t('Taxation VAT'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'taxfree_supply',
              caption: t('Tax Free Supply'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'taxzero_supply',
              caption: t('Tax Zero Supply'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'forward_amount',
              caption: t('Forward Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'bottle_amount',
              caption: t('Bottle Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'container_amount',
              caption: t('Container Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'container_takeback_amount',
              caption: t('Container Takeback Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'shipping_fee',
              caption: t('Shipping Fee'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'total_amount',
              caption: t('Total Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
          ],
    [formData?.searchBy, t],
  );

  /**
   * Export excel data grid
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * Close component
   *
   */
  const onClose = () => {
    setModeView({
      type: 'list',
    });
    setListDataDetail([]);
  };
  return (
    <>
      <BreadCrumbPremium
        title={modeView?.name || ''}
        buttons={[
          {
            title: 'Close',
            icon: 'close',
            type: 'normal',
            action: () => onClose(),
          },
        ]}
      />
      <DataGridFull
        storageKeyInquiry="view"
        columns={columns}
        ref={dataGridRef}
        dataSource={storeView}
        fixedRight={1}
        options={{
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          groupPanel: {
            visible: true,
          },
          export: {
            fileName:
              'SMDC_2087.6 ' +
              (modeView?.name || 'Store') +
              '_' +
              moment().format('YYYYMMDD'),
          },
          summary: {
            totalItems: [
              {
                column: 'taxation_supply',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'taxation_vat',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'taxfree_supply',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'taxzero_supply',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'forward_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'bottle_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'container_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'container_takeback_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'palette_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'shipping_fee',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'total_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default View;
