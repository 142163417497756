import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserRegistrationState {
  showUserInfo: boolean;
  idUser: number | null;
  refresh: boolean;
  sortBy: string;
}

const initialState: UserRegistrationState = {
  showUserInfo: false,
  idUser: null,
  refresh: false,
  sortBy: 'userId',
};

const userRegistrationSlice = createSlice({
  name: 'userRegistration',
  initialState,
  reducers: {
    openUserInfo(state, action: PayloadAction<null | number>) {
      state.showUserInfo = true;
      state.idUser = action.payload;
    },
    closeUserInfo(state) {
      state.showUserInfo = false;
      state.idUser = null;
    },
    refreshData(state) {
      state.refresh = true;
    },
    refreshSuccess(state) {
      state.refresh = false;
    },
    changeSort(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },
  },
});
//Action
export const userRegistrationActions = userRegistrationSlice.actions;

//Selectors
const selectIdUser = (state: any) => state.userRegistration.idUser;
const selectShowUserInfo = (state: any) => state.userRegistration.showUserInfo;
const selectRefresh = (state: any) => state.userRegistration.refresh;
const selectSortBy = (state: any) => state.userRegistration.sortBy;

export const userRegistrationSelect = {
  selectIdUser,
  selectShowUserInfo,
  selectRefresh,
  selectSortBy,
};

const userRegistrationReducer = userRegistrationSlice.reducer;

export default userRegistrationReducer;
