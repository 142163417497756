/**
 * Update date: 07-06-2023
 * Screen 2081.B
 */
import React, { useEffect, useState, useRef } from 'react';
import Form from 'devextreme-react/form';
import { useModuleContext } from '../index';
import LoadPanel from 'app/components/LoadPanel';
import { Button } from 'devextreme-react';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { notification } from 'utils/notification';
import useFormatTime from 'hooks/useFormatTime';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { cloneDeep, uniqBy } from 'lodash';
import useFormat from 'hooks/useFormat';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const defaultData = {
  depositDate: new Date(),
  lineId: null,
  depositAmount: null,
  depositAccountNo: null,
  depositDepositor: null,
  depositNote: '',
  dataList: {
    store: [],
    receivableCd: [],
    depositType: [],
    paymentMethod: [],
    bank: [],
  },
  store: '',
  receivableCd: '',
  depositType: '',
  paymentMethod: '',
  bank: '',
};

const FormCreateDeposit = props => {
  const { themePro }: any = useApp();
  const {
    t,
    modeView,
    setModeView,
    apiListStoreDeposit,
    searchParams,
    storedReceivableCd,
  }: any = useModuleContext();
  const { depositDate, lineId, type } = modeView || {};
  const [formData, setFormData] = useState<any>(
    cloneDeep({ ...defaultData, depositDate: new Date() }),
  );
  const [showPopupByType, setShowPopupByType] = useState('');
  const [showPopupConfirmCancel, setShowPopupConfirmCancel] = useState(false);
  const [showPopupConfirmSubmit, setShowPopupConfirmSubmit] = useState(false);
  const { DateFormat } = useFormatTime();
  const { AmountFormat } = useFormat();
  const ref: any = useRef(null);

  const [{ loading: loadingCreate }, apiCreateStoreDeposit] = useAxios(
    {
      url: 'user/store-deposit',
      method: 'PUT',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const [{ data: depositDetail, loading: loadingDetail }] = useAxios(
    {
      url: `user/store-deposit/detail`,
      method: 'GET',
      params: {
        depositDate: moment(depositDate).format('YYYY-MM-DD'),
        lineId: lineId,
      },
    },
    {
      manual: depositDate && lineId ? false : true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (depositDetail?.data) {
      const resData = depositDetail?.data || {};
      setFormData({
        depositDate: resData?.depositDate,
        lineId: lineId,
        depositAmount: resData?.depositAmount,
        depositAccountNo: resData?.depositAccountNo,
        depositDepositor: resData?.depositDepositor,
        depositNote: resData?.depositNote,
        dataList: {
          store: resData.storeId
            ? [{ storeNo: resData?.storeId, storeNm: resData?.storeName }]
            : [],
          receivableCd: resData?.receivableCd
            ? [{ code: resData?.receivableCd, name: resData?.receivableCdName }]
            : [],
          depositType: resData?.depositTypeCode
            ? [
                {
                  code: resData?.depositTypeCode,
                  name: resData?.depositTypeName,
                },
              ]
            : [],
          paymentMethod: resData?.paymentMethodCode
            ? [
                {
                  code: resData?.paymentMethodCode,
                  name: resData?.paymentMethodName,
                },
              ]
            : [],
          bank: resData?.bankCode
            ? [{ code: resData?.bankCode, name: resData?.bankName }]
            : [],
        },
        store: '',
        receivableCd: '',
        depositType: '',
        paymentMethod: '',
        bank: '',
      });
    }
  }, [depositDetail]);

  /**
   * on hide
   *
   */
  const onHiding = () => {
    if (type === 'create') {
      setModeView({ type: 'list' });
    } else {
      setModeView({ type: 'list-detail', depositDate });
    }
  };
  /**
   * on valid save
   *
   * @return {*}
   */
  const validateSave = () => {
    const isValid = ref?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    setShowPopupConfirmSubmit(true);
  };

  /**
   * on create save
   *
   */
  const onCreateSave = async () => {
    const dataRequest = {
      depositDate: moment(formData?.depositDate).format('YYYY-MM-DD'),
      lineId: formData?.lineId || null,
      depositAmount: formData?.depositAmount,
      depositAccountNo: formData?.depositAccountNo,
      depositDepositor: formData?.depositDepositor,
      depositNote: formData?.depositNote,
      storeId: formData?.dataList?.store[0]?.storeNo,
      receivableCd: formData?.dataList?.receivableCd[0]?.code,
      depositTypeCode: formData?.dataList?.depositType[0]?.code,
      paymentMethodCode: formData?.dataList?.paymentMethod[0]?.code,
      bankCode: formData?.dataList?.bank[0]?.code,
    };
    const res: any = await apiCreateStoreDeposit({ data: dataRequest });
    notification({ res });
    if (res?.data?.status === '201') {
      apiListStoreDeposit({
        data: {
          ...searchParams,
          fromDate: searchParams?.fromDate
            ? moment(searchParams?.fromDate).format('YYYY-MM-DD')
            : null,
          endDate: searchParams?.endDate
            ? moment(searchParams?.endDate).format('YYYY-MM-DD')
            : null,
          receivableCd: storedReceivableCd,
        },
      });
      onHiding();
    }
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'store':
        return (
          <PopupSelectStore
            text={formData?.[popupType]}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('store', o)}
          />
        );
      case 'depositType':
        return (
          <PopupSelectCommonCode
            text={formData?.[popupType]}
            mode="single"
            codeType="DP"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('depositType', o)}
          />
        );
      case 'paymentMethod':
        return (
          <PopupSelectCommonCode
            text={formData?.[popupType]}
            mode="single"
            codeType="PM"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('paymentMethod', o)}
          />
        );
      case 'bank':
        return (
          <PopupSelectCommonCode
            text={formData?.[popupType]}
            mode="single"
            codeType="BK"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('bank', o)}
          />
        );
      case 'receivableCd':
        return (
          <PopupSelectCommonCode
            text={formData?.[popupType]}
            mode="single"
            codeType="RC"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('receivableCd', o)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingDetail || loadingCreate} />
      <BreadCrumbPremium
        onSubmit={() => validateSave()}
        onCancel={() => {
          setShowPopupConfirmCancel(true);
        }}
      />
      <Form
        ref={ref}
        labelLocation="top"
        showColonAfterLabel={false}
        formData={formData}
        readOnly={modeView?.type === 'view'}
        className="form-data-order body-padding-white"
        items={[
          {
            dataField: 'lineId',
            editorType: 'dxTextBox',
            label: {
              text: t('ID'),
            },
            editorOptions: {
              disabled: true,
            },
          },
          {
            dataField: 'depositDate',
            editorType: 'dxDateBox',
            label: {
              text: t('Date'),
            },
            isRequired: true,
            editorOptions: {
              displayFormat: DateFormat,
              min: moment().subtract(7, 'days'),
              max: moment().add(1, 'days'),
              readOnly: modeView?.type === 'create' ? false : true,
            },
          },
          generateDxTextBox('Store', 'store', 'storeNm', {
            isRequired: true,
          }),
          generateDxTextBox('Receivable Group', 'receivableCd', 'name', {
            isRequired: true,
          }),
          {
            label: { text: t('Deposit') },
            dataField: 'depositAmount',
            editorType: 'dxNumberBox',
            editorOptions: {
              format: AmountFormat,
            },
            validationRules: [
              {
                type: 'numeric',
              },
              {
                type: 'required',
              },
            ],
          },
          generateDxTextBox('Deposit Type', 'depositType', 'name'),
          generateDxTextBox('Payment Method', 'paymentMethod', 'name'),
          generateDxTextBox('Bank', 'bank', 'name'),
          {
            label: { text: t('Account Number') },
            dataField: 'depositAccountNo',
            editorType: 'dxNumberBox',
            validationRules: [
              {
                type: 'numeric',
              },
            ],
          },
          {
            dataField: 'depositDepositor',
            editorType: 'dxTextBox',
            label: {
              text: t('Depositor'),
            },
          },
          {
            dataField: 'depositNote',
            editorType: 'dxTextBox',
            colSpan: 2,
            label: {
              text: t('Note'),
            },
          },
        ]}
        colCount={3}
      />
      {!themePro && (
        <div
          style={{
            paddingTop: '30px',
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
          className="button-wrap-content"
        >
          <div style={{ marginRight: '50px' }}>
            <Button
              width={120}
              text={t('Save')}
              icon="save"
              onClick={() => validateSave()}
            />
          </div>
          <Button
            width={120}
            text={t('Cancel')}
            icon="remove"
            type="normal"
            onClick={() => {
              setShowPopupConfirmCancel(true);
            }}
          />
        </div>
      )}
      {getPopupByType(showPopupByType)}
      {showPopupConfirmCancel && (
        <PopupConfirm
          visible={showPopupConfirmCancel}
          content={t(`Do you want to cancel all changed?`)}
          onOk={onHiding}
          onHiding={() => setShowPopupConfirmCancel(false)}
        />
      )}
      {showPopupConfirmSubmit && (
        <PopupConfirm
          visible={showPopupConfirmSubmit}
          content={t(`Do you want to submit data?`)}
          onOk={onCreateSave}
          onHiding={() => setShowPopupConfirmSubmit(false)}
        />
      )}
    </React.Suspense>
  );
};

export default FormCreateDeposit;
