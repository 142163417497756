/**
 * Update date: 23-05-2023
 * Screen 2032.2
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import Form from 'devextreme-react/form';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import { uniqBy } from 'lodash';
import moment from 'moment';
import React, { forwardRef, useState } from 'react';
import { useInquiresOfScheduled } from '..';
const listTypeCommon = {
  vendor: {
    codeType: 'VD',
    title: 'Search Vendor',
  },
  product: {
    codeType: 'PD',
    title: 'Search Product',
  },
  productGroup: {
    codeType: 'PG',
    title: 'Search ProductGroup',
  },
  largeCategories: {
    codeType: 'LC',
    title: 'Search Large Category',
  },
  mediumCategories: {
    codeType: 'MC',
    title: 'Search Medium Category',
  },
  smallCategories: {
    codeType: 'SC',
    title: 'Search Small Category',
  },
  subCategories: {
    codeType: 'UC',
    title: 'Search Sub Category',
  },
  productType: {
    codeType: 'PT',
  },
};

const FormSearch = (props, ref) => {
  const context: any = useInquiresOfScheduled();
  const { params, setParams, refetchData } = context;
  const [showPopupByType, setShowPopupByType] = useState('');

  const { DateFormat } = useFormatTime();
  const { t }: any = useDictionary({
    programId: '2032.2',
  });
  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = ref?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      largeCategories: params?.formStore.largeCategories.map(
        (map: any) => map?.code,
      ),
      mediumCategories: params?.formStore.mediumCategories.map(
        (map: any) => map?.code,
      ),
      smallCategories: params?.formStore.smallCategories.map(
        (map: any) => map?.code,
      ),
      subCategories: params?.formStore.subCategories.map(
        (map: any) => map?.code,
      ),
      productIds: params?.formStore.product.map((map: any) => map?.productId),
      productGroupIds: params?.formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      productTypes: params?.formStore.productType.map((o: any) => o?.code),
      vendorId: params?.vendorId,
      fromDate: params.fromDate
        ? moment(params.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: params.endDate
        ? moment(params.endDate).format('YYYY-MM-DD')
        : null,
      status: params.status,
    };
    refetchData({
      data,
    });
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    ref?.current?.instance?.updateData('fromDate', fromDateValue);
    ref?.current?.instance?.updateData('endDate', toDateValue);
  };
  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (params.endDate && e?.value && e?.value > params.endDate)
          ref?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        if (params.fromDate && e?.value && e?.value < params.fromDate)
          ref?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };
  /**
   * render get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            text={params?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            mode="multiple"
            onSubmit={data => updateStore(popupType, data, 'productId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            mode="multiple"
            onSubmit={data => updateStore(popupType, data, 'productGroupId')}
          />
        );
      case 'largeCategories':
      case 'mediumCategories':
      case 'smallCategories':
      case 'subCategories':
      case 'productType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on update store
   *
   * @param {*} popupType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (popupType, data, key: any = false) => {
    const filterData = uniqBy(
      [...params?.formStore?.[popupType], ...data],
      key,
    );
    const newStore = { ...params?.formStore, [popupType]: filterData };
    setParams(preState => ({
      ...preState,
      [popupType]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  /**
   * on remove store
   *
   * @param {*} popupType
   * @param {*} index
   */
  const removeStore = (popupType, index) => {
    let newStore;
    if (index === -1) {
      setParams(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [popupType]: [],
        },
      }));
    } else {
      const l = [...params?.formStore[popupType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setParams(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [popupType]: newStore,
        },
      }));
    }
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={ref}
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            {
              label: { text: t('Vendor') },
              dataField: 'vendor',
              editorType: 'dxTextBox',
              editorOptions: {
                readOnly: true,
              },
            },
            {
              label: { text: t('Status') },
              dataField: 'status',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  { label: t('All'), value: '' },
                  { label: t('Pending'), value: '0' },
                  { label: t('Fixed'), value: '1' },
                ],
              },
            },
          ]}
          labelLocation="top"
          onFieldDataChanged={onFieldDataChanged}
          colCount={4}
        ></Form>
      </>
    );
  };
  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'largeCategories',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('largeCategories'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.largeCategories[0]?.name,
                      onClick: () => removeStore('largeCategories', 0),
                      visible: !!params?.formStore?.largeCategories[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.largeCategories[1]?.name,
                      onClick: () => removeStore('largeCategories', 1),
                      visible: !!params?.formStore?.largeCategories[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.largeCategories?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.largeCategories?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.largeCategories?.length,
                      onClick: () => removeStore('largeCategories', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('largeCategories'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mediumCategories',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('mediumCategories'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.mediumCategories[0]?.name,
                      onClick: () => removeStore('mediumCategories', 0),
                      visible: !!params?.formStore?.mediumCategories[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.mediumCategories[1]?.name,
                      onClick: () => removeStore('mediumCategories', 1),
                      visible: !!params?.formStore?.mediumCategories[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.mediumCategories?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.mediumCategories?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.mediumCategories?.length,
                      onClick: () => removeStore('mediumCategories', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('mediumCategories'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('productGroup'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productGroup[0]?.productGroupNm,
                      onClick: () => removeStore('productGroup', 0),
                      visible:
                        !!params?.formStore?.productGroup[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productGroup[1]?.productGroupNm,
                      onClick: () => removeStore('productGroup', 1),
                      visible:
                        !!params?.formStore?.productGroup[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.productGroup?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.productGroup?.length,
                      onClick: () => removeStore('productGroup', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('productGroup'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smallCategories',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('smallCategories'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.smallCategories[0]?.name,
                      onClick: () => removeStore('smallCategories', 0),
                      visible: !!params?.formStore?.smallCategories[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.smallCategories[1]?.name,
                      onClick: () => removeStore('smallCategories', 1),
                      visible: !!params?.formStore?.smallCategories[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.smallCategories?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.smallCategories?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.smallCategories?.length,
                      onClick: () => removeStore('smallCategories', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('smallCategories'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategories',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('subCategories'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.subCategories[0]?.name,
                      onClick: () => removeStore('subCategories', 0),
                      visible: !!params?.formStore?.subCategories[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.subCategories[1]?.name,
                      onClick: () => removeStore('subCategories', 1),
                      visible: !!params?.formStore?.subCategories[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.subCategories?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.subCategories?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.subCategories?.length,
                      onClick: () => removeStore('subCategories', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('subCategories'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('product'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.product[0]?.productNm,
                      onClick: () => removeStore('product', 0),
                      visible: !!params?.formStore?.product[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.product[1]?.productNm,
                      onClick: () => removeStore('product', 1),
                      visible: !!params?.formStore?.product[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${params?.formStore?.product?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: params?.formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.product?.length,
                      onClick: () => removeStore('product', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('product'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Type') },
              dataField: 'productType',
              editorType: 'dxTextBox',
              editorOptions: {
                readOnly: params?.isReadOnlyProductTp,
                onEnterKey: () => setShowPopupByType('productType'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productType[0]?.name,
                      onClick: () => removeStore('productType', 0),
                      visible: !!params?.formStore?.productType[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productType[1]?.name,
                      onClick: () => removeStore('productType', 1),
                      visible: !!params?.formStore?.productType[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'dataMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.productType?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.productType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.productType?.length,
                      onClick: () => removeStore('productType', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('productType'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit} swipeEnabled={false}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default forwardRef(FormSearch);
