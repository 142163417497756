/**
 * Update date: 15-05-2023
 * Screen 1044.1
 */
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useAxios from 'axios-hooks';
import useDictionary from 'hooks/useDictionary';
import { iconExcel } from 'images';
import moment from 'moment';
import React, { forwardRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useRegisteringVendor } from '..';
import FormSearch from '../FormSearch';
const exportFileName =
  'SMDC_1044.1 Registering Vendor_' + moment().format('YYYYMMDD');
function ListVendor(props, ref) {
  const { themePro }: any = useApp();
  const context: any = useRegisteringVendor();
  const { setModeView, store } = context;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { t }: any = useDictionary({
    programId: '1044.1',
  });
  const selectedKeys = ref?.current?.instance?.option().selectedRowKeys;
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: 'user/vendor',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * on delete
   *
   * @return {*} 
   */
  const onDelete = async () => {
    if (!selectedKeys?.length) return;
    const res = await refetchDelete({
      data: selectedKeys,
    });
    notification({
      res,
    });
    if (res?.data?.status === '200') {
      selectedKeys?.forEach(vendorId => {
        store
          .remove(vendorId)
          .done(() => {})
          .fail(() => {});
      });
      deselectAll();
      setShowModalDelete(false);
    }
  };

  /**
   * on desect all
   *
   */
  const deselectAll = () => {
    ref?.current?.instance?.deselectAll();
  };

  /**
   * render vendor
   *
   * @param {*} record
   * @return {*} 
   */
  const renderVendor = record => {
    const { vendorName, vendorId } = record?.data || {};
    return (
      <div
        onClick={() => {
          setModeView({ type: 'edit', vendorId });
        }}
        className="cell-link"
      >
        {vendorName}
      </div>
    );
  };
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    ref?.current?.instance?.exportToExcel();
  };
  return (
    <>
      <LoadPanel visible={loadingDelete} />
      <BreadCrumbPremium />
      <FormSearch />
      <DataGridFull
        ref={ref}
        dataSource={store}
        columns={[
          {
            dataField: 'vendorId',
            caption: t('ID'),
            alignment: 'left',
          },
          {
            dataField: 'vendorCode',
            caption: t('Vendor Code'),
            alignment: 'left',
          },
          {
            dataField: 'vendorType',
            caption: t('Type'),
          },
          {
            dataField: 'vendorName',
            caption: t('Vendor'),
            cellRender: renderVendor,
          },
          {
            dataField: 'businessNumber',
            caption: t('Business Number'),
          },
          {
            dataField: 'vendorRepresentative',
            caption: t('Representative'),
          },
          {
            dataField: 'vendorMobile',
            caption: t('Mobile No'),
          },
          {
            dataField: 'vendorPhone',
            caption: t('Phone No'),
          },
          {
            dataField: 'interlockCd',
            caption: t('Interlock Code'),
            visible: false,
          },
          {
            dataField: 'interlockCd2',
            caption: t('Interlock Code 2'),
            visible: false,
          },
          {
            dataField: 'use',
            caption: t('Use'),
            alignment: 'center',
            editorType: 'dxSwitch',
            trueText: t('Use'),
            falseText: t('Not Use'),
          },
        ]}
        storageKey="1044.1_v1"
        fixedLeft={0}
        fixedRight={0}
        options={{
          key: 'vendorId',
          columnAutoWidth: true,
          height: '70vh',
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: themePro ? iconExcel : 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => exportExcel(),
                },
              },
              {
                location: themePro ? 'after' : 'before',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Create New'),
                  onClick: () => setModeView({ type: 'create' }),
                },
              },
              {
                location: themePro ? 'after' : 'before',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  // disabled: !selectedKeys?.length,
                  onClick: () => setShowModalDelete(!showModalDelete),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
        }}
      />
      <PopupConfirmDelete
        visible={showModalDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onDelete}
        onHiding={() => setShowModalDelete(!showModalDelete)}
      />
    </>
  );
}

export default forwardRef(ListVendor);
