/* Update date: 12-06-2023
* Popup select special component
*/
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView, TextBox } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';

const defaultParams = {
  pageIndex: 1,
  pageSize: 10,
  fromDate: '',
  endDate: '',
  productDealName: '',
  use: '',
  productDealIds: [],
  largeCategoryCodes: [''],
  mediumCategoryCodes: [''],
  smallCategoryCodes: [''],
  subCategoryCodes: [''],
  productGroupIds: [],
  productIds: [],
};
interface IPopupSelectSpecialDeal {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit?: void | any;
  mode?: 'single' | 'multiple';
  title?: string;
}

function PopupSelectSpecialDeal({
  visible,
  onHiding,
  text,
  onSubmit,
  mode = 'single',
  title,
}: IPopupSelectSpecialDeal) {
  const { themePro }: any = useApp();
  const [dealName, setDealName] = useState(text);
  const dataRef: any = useRef(null);
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { t } = useDictionary({});

  const [{ data: resData, loading }, fetchData] = useAxios(
    {
      url: '/account/product-deal',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on search
   *
   */
  const onSearch = () => {
    fetchData({
      data: { productDealName: dealName },
    });
  };

  /**
   * on ok
   *
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!mode || mode === 'single') {
      const d = selectedRowKeys?.[0];
      onSubmit(d);
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  useEffect(() => {
    onSearch();
  }, []);

  /**
   * render use
   *
   * @param {*} e
   */
  const renderUse = e => (e?.value ? 'Use' : 'Not use');

  const loadingAll = loading;

  /**
   * render content
   *
   * @return {*} 
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Special Deal')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }
            }
          >
            <TextBox
              width={themePro ? 400 : '40vw'}
              defaultValue={dealName}
              onValueChanged={e => setDealName(e?.value)}
              onEnterKey={onSearch}
              style={{ marginRight: 10 }}
            />
            <Button icon="search" text={t('Search')} onClick={onSearch} />
          </div>
          <DataGridFull
            ref={dataRef}
            dataSource={resData?.data}
            fixedLeft={2}
            fixedRight={0}
            columns={[
              {
                dataField: 'productDealId',
                caption: t('ID'),
                alignment: 'center',
              },
              {
                dataField: 'productDealName',
                caption: t('Name'),
                alignment: 'left',
              },
              {
                dataField: 'productDealNote',
                caption: t('Note'),
                alignment: 'left',
              },
              {
                dataField: 'purchaseStartDate',
                caption: t('Purchase Start Date'),
                dataType: 'date',
                alignment: 'center',
                format: DateFormat,
              },
              {
                dataField: 'purchaseEndDate',
                caption: t('Purchase End Date'),
                dataType: 'date',
                alignment: 'center',
                format: DateFormat,
              },
              {
                dataField: 'saleEndDate',
                caption: t('Sale End Date'),
                dataType: 'date',
                alignment: 'center',
                format: DateFormat,
              },
              {
                dataField: 'saleStartDate',
                caption: t('Sale Start Date'),
                dataType: 'date',
                alignment: 'center',
                format: DateFormat,
              },
              {
                dataField: 'use',
                caption: t('Use'),
                alignment: 'center',
                renderCell: renderUse,
              },
              {
                dataField: 'monday',
                caption: t('Monday'),
                alignment: 'center',
                format: DateTimeFormat,
              },
              {
                dataField: 'tuesday',
                caption: t('Tuesday'),
                alignment: 'center',
                format: DateTimeFormat,
              },
              {
                dataField: 'wednesday',
                caption: t('Wednesday'),
                alignment: 'center',
                format: DateTimeFormat,
              },
              {
                dataField: 'thursday',
                caption: t('Thursday'),
                alignment: 'center',
                format: DateTimeFormat,
              },
              {
                dataField: 'friday',
                caption: t('Friday'),
                alignment: 'center',
                format: DateTimeFormat,
              },
              {
                dataField: 'saturday',
                caption: t('Saturday'),
                alignment: 'center',
                format: DateTimeFormat,
              },
              {
                dataField: 'sunday',
                caption: t('Sunday'),
                alignment: 'center',
                format: DateTimeFormat,
              },
            ]}
            options={{
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode,
              },
              height: themePro
                ? 'calc(100vh - 200px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Product Special Deal')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
    />
  );
}

export default PopupSelectSpecialDeal;
