/**
 * Update date: 16-05-2023
 * Screen 2011.1
 */
import React, {
  useState,
  useContext,
  createContext,
  memo,
  useEffect,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import useLocalStorage from 'hooks/useLocalStorage';
import useDictionary from 'hooks/useDictionary';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const Mall = React.lazy(() => import('./Mall'));
const Option = React.lazy(() => import('./Option'));

const defaultParams = {
  orderByName: '',
  orderBy: true,
  productTypes: '',
  productMakers: '',
  productNames: '',
  vendorIds: '',
  largeCategory: '',
  mediumCategory: '',
  smallCategory: '',
  subCategory: '',
  productGroup: '',
  tradeType: '0',
  isReadOnlyProductTp: false,
};

/**
 * create context
 */
export const ProductContext = createContext({
  params: {},
  setParams: () => {},
  fetchData: () => {},
  commonCode: [],
  vendor: [],
  tax: [],
  container: {},
});

ProductContext.displayName = 'ProductContext';

export const useProduct = () => {
  const context = useContext(ProductContext);
  if (!context) throw new Error('Product Context Error');
  return context;
};

const ProductRegistration = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [params, setParams] = useState(defaultParams);
  const [formStore, setFormStore] = useState<any>({
    largeCategory: [],
    mediumCategory: [],
    smallCategory: [],
    subCategory: [],
    productGroup: [],
    vendorIds: [],
    productMakers: [],
    productTypes: [],
  });
  const { t } = useDictionary({});
  const [dataListLocal, setDataListLocal] = useLocalStorage(
    '2011.1_dataList',
    {},
  );
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [{ data: resData, loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: '/product/product/list-product-master',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams({
      ...params,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    });
    setFormStore({
      ...formStore,
      productTypes: productTpPermission,
    });
  }, [productTpPermission]);

  const store: any = new ArrayStore({
    data: data,
    key: 'productId',
  });

  /**
   * on add record
   *
   * @param {*} record
   */
  const onAddRecord = record => {
    store?.insert(record);
  };

  /**
   * on update record
   *
   * @param {*} record
   */
  const onUpdateRecord = record => {
    store?.update(record?.productId, { ...record });
  };
  /**
   * on remove record
   *
   * @param {*} record
   */
  const onRemoveRecord = record => {
    store?.remove(record?.productId);
  };

  const value: any = {
    params,
    setParams,
    refetchData,
    modeView,
    setModeView,
    data,
    setData,
    store,
    onAddRecord,
    onRemoveRecord,
    onUpdateRecord,
    formStore,
    setFormStore,
    dataListLocal,
    setDataListLocal,
    t,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      case 'mall':
        return <Mall />;
      case 'option':
        return <Option />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resData?.data) {
      setData(resData?.data);
    }
  }, [resData]);

  const loadingAll = loadingfetchData || loadingProductTpPermission;
  /**
   * render content
   */

  return (
    <ProductContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ProductContext.Provider>
  );
};

export default memo(ProductRegistration);
