/**
 * Update date: 31-05-2023
 * Screen 2052.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import LoadPanel from 'app/components/LoadPanel';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';

interface IPopup2052_3Detail {
  visible?: boolean;
  onHiding?: () => void;
  data: any;
}

function Popup2052_3Detail({ visible, onHiding, data }: IPopup2052_3Detail) {
  const { t }: any = useDictionary({ programId: '2052.3' });
  const { QtyFormat } = useFormat();
  const { DateTimeFormat } = useFormatTime();
  const dataGridRef: any = useRef(null);

  const [{ data: subData, loading: loadingSubTable }] = useAxios(
    {
      url: '/product/inventory-product-location/location',
      method: 'POST',
      data: data,
    },
    { manual: false, useCache: false },
  );

  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    return (
      <div className="cell-link" onClick={() => {}}>
        {t('History')}
      </div>
    );
  };

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const columns: any = [
    {
      caption: t('Location'),
      dataField: 'location_id',
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      caption: t('Name'),
      dataField: 'location_nm',
      showWhenGrouped: true,
    },
    {
      caption: t('Route Order'),
      dataField: 'location_route_order',
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      caption: t('Stock Quantity'),
      dataField: 'location_ea',
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      caption: t('Actual Stock'),
      dataField: 'actual_atock',
      showWhenGrouped: true,
      dataType: 'number',
      format: QtyFormat,
    },
    {
      caption: t('Order Allocation'),
      dataField: 'assign_ea',
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      caption: t('Load Type'),
      dataField: 'location_load_tp_nm',
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      caption: t('Disribution Period'),
      dataField: 'location_distribution_period_dm',
      dataType: 'date',
      alignment: 'left',
      format: DateTimeFormat,
      showWhenGrouped: true,
    },
    {
      caption: t('Action'),
      alignment: 'center',
      showWhenGrouped: true,
      cellRender: renderAction,
    },
  ];

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingSubTable} />
          <div className="modal-popup-header">
            <span className="title-page">
              {t('Inventory Inquiry By Product/Location Detail')}
            </span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
          <DataGridFull
            storageKeyInquiry="view"
            columns={columns}
            ref={dataGridRef}
            dataSource={subData?.data}
            fixedLeft={2}
            fixedRight={1}
            options={{
              height: isMobile ? 'calc(80vh - 200px)' : 'calc(100vh - 140px)',
              columnAutoWidth: true,
              groupPanel: { visible: true },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                      icon: 'xlsxfile',
                      text: t('Export Excel'),
                      disabled: !subData?.data?.length,
                      onClick: () => exportExcel(),
                    },
                  },
                );
              },
              export: {
                fileName:
                  'SMDC_2052.3 Inventory Inquiry by Product/Location SubTable ' +
                  moment().format('YYYYMMDD'),
              },
              summary: {
                totalItems: [
                  {
                    column: 'location_ea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    summaryType: 'sum',
                  },
                  {
                    column: 'assign_ea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    summaryType: 'sum',
                  },
                  {
                    column: 'actual_atock',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    summaryType: 'sum',
                  },
                ],
                groupItems: [
                  {
                    summaryType: 'count',
                    displayFormat: '{0}',
                  },
                  {
                    column: 'location_ea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'actual_atock',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'assign_ea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                ],
              },
            }}
          />
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Zone')}
      contentRender={content}
      height={'100vh'}
      width={isMobile ? '96%' : '70vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default Popup2052_3Detail;
