// Update date: 26-06-2023
// Screen 2087.B
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { createContext, useContext, useState } from 'react';
import { dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';

const View = React.lazy(() => import('./View'));
const List = React.lazy(() => import('./List'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2087_B_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Forwarding return inquiry by Bill
 *
 * @export
 * @return {*}
 */
export default function M2087_B() {
  // get multi language
  const { t }: any = useDictionary({ programId: '2087.B' });
  // Initialize state
  const [listData, setListData] = useState([]);
  const [listDataView, setListDataView] = useState([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      receivableCd: [],
      store: [],
    },
    receivableCd: '',
    store: '',
  });
  const [request, setRequest] = useState<any>({
    fromDate: '',
    endDate: '',
    receivableGroups: [],
  });
  const { AmountFormat } = useFormat();
  const { DateFormat } = useFormatTime();
  const [modeView, setModeView] = useState({
    type: 'list',
    data: '',
  });

  // Get data
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: listData,
  });
  const storeView: any = new ArrayStore({
    data: listDataView,
  });

  /**
   * On search data
   *
   */
  const onSearch = async () => {
    const searchParams = {
      fromDate: dateFormatStr(formData?.fromDate),
      endDate: dateFormatStr(formData?.endDate),
      receivableGroups: formData?.dataList?.receivableCd?.map?.(
        (o: any) => o?.code,
      ),
      storeIds: formData.dataList?.store?.map((o: any) => o?.storeNo),
    };

    const res = await refetchData({
      url: '/ecommerce/store-receipt',
      method: 'POST',
      data: { ...searchParams },
    });
    if (res?.data?.status === '200') {
      setListData(res?.data?.data?.listData || []);
      setRequest(res?.data?.data?.request || {});
    } else {
      notification({ res });
    }
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <View />;
      default:
        return <NotFoundPage />;
    }
  };

  // Values share with child component
  const value: any = {
    t,
    modeView,
    setModeView,
    formData,
    setFormData,
    listData,
    onSearch,
    refetchData,
    store,
    storeView,
    AmountFormat,
    DateFormat,
    request,
    setListDataView,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
}
