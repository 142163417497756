/**
 * Update date: 23-05-2023
 * Screen 2032.2
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import useProfileInfo from 'hooks/useProfileInfo';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FormSearch from './FormSearch';
import ListInventory from './ListInvetory';
import { useProductType } from 'hooks/useProductType';

const defaultParams = {
  largeCategories: '',
  mediumCategories: '',
  smallCategories: '',
  subCategories: '',
  product: '',
  productGroup: '',
  vendor: '',
  status: '',
  productType: '',
};
/**
 * create context
 */
const InquiresOfScheduledContext = createContext({});
InquiresOfScheduledContext.displayName = 'InquiresOfScheduledContext';

export const useInquiresOfScheduled = () => {
  const context = useContext(InquiresOfScheduledContext);
  return context;
};

function InquiresOfScheduled() {
  const { vendorName, vendorId } = useProfileInfo();
  const formRef: any = useRef(null);
  const [params, setParams] = useState<any>({
    ...defaultParams,
    fromDate: new Date(),
    endDate: new Date(),
    vendor: vendorName || '',
    vendorId: vendorId,
    formStore: {
      largeCategories: [],
      mediumCategories: [],
      smallCategories: [],
      subCategories: [],
      product: [],
      productGroup: [],
      productType: [],
    },
  });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: '/ecommerce/receive-and-order/scan',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );
  const store: any = new ArrayStore({
    data: data?.data?.map((o, i) => ({
      ...o,
      id: i + 1,
    })),
    key: 'id',
  });
  const value: any = {
    store,
    refetchData,
    params,
    setParams,
  };

  const loadingAll = loading || loadingProductTpPermission;
  useEffect(() => {
    formRef?.current?.instance?.updateData('vendorId', vendorId);
    formRef?.current?.instance?.updateData('vendor', vendorName);
  }, [vendorName, vendorId]);
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      formStore: {
        ...preState.formStore,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);
  return (
    <InquiresOfScheduledContext.Provider value={value}>
      <LoadPanel visible={loadingAll} />
      <FormSearch ref={formRef} />
      <ListInventory />
    </InquiresOfScheduledContext.Provider>
  );
}

export default InquiresOfScheduled;
