/**
 * Update date: 31-05-2023
 * Screen 2046.9
 */
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useInventoryReceiving } from '..';
import { uniqBy } from 'lodash';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import DateOptions from 'app/components/DateOptions';
import Collapse from 'app/components/Collapse';
import useFormatTime from 'hooks/useFormatTime';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';
// Define type:
// LC: large category
// MC: medium category
// SC: small category
// UC: sub category
// PD: product
// PG: product group
// ST: store
const FormSearch = () => {
  const { t }: any = useDictionary({ programId: '2046.9' });
  const [dataLocal, setDataLocal] = useLocalStorage('2046.9_dataList', {});
  const context: any = useInventoryReceiving();
  const { refetchData } = context;
  const [formStore, setFormStore] = useState<any>({
    lgCategory: [],
    mdCategory: [],
    smCategory: [],
    subCategory: [],
    product: [],
    productGroup: [],
    store: [],
    receivableCd: [],
    shippingMethod: [],
  });
  const [formData, setFormData] = useState({
    lgCategory: '',
    mdCategory: '',
    smCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    store: '',
    receivableCd: '',
    shippingMethod: '',
    fromDate: new Date(),
    endDate: new Date(),
    status: '',
    taskType: 0,
  });
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [typeCommon, setTypeCommon] = useState('');
  const formRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setFormStore(prev => ({
      ...prev,
      ...dataLocal,
    }));
  }, [dataLocal]);

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      largeCategories: formStore.lgCategory.map((map: any) => map?.code),
      mediumCategories: formStore.mdCategory.map((map: any) => map?.code),
      smallCategories: formStore.smCategory.map((map: any) => map?.code),
      subCategories: formStore.subCategory.map((map: any) => map?.code),
      shippingMethods: formStore?.shippingMethod.map((map: any) => map?.code),
      productIds: formStore.product.map((map: any) => map?.productId),
      productGroupIds: formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      storeIds: formStore.store.map(map => map?.storeNo),
      receivableCds: formStore?.receivableCd?.map((o: any) => o?.code) || [],

      fromDate: formData.fromDate
        ? moment(formData.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData.endDate
        ? moment(formData.endDate).format('YYYY-MM-DD')
        : null,
      status: formData.status,
      taskType: formData.taskType,
    };
    refetchData({
      data,
    });
  };

  /**
   * on show popup
   *
   * @param {*} type
   */
  const handleShowPopup = type => {
    setIsShowPopup(true);
    setTypeCommon(type);
  };

  /**
   * on close popup
   *
   */
  const closePopup = () => {
    setTypeCommon('');
    setIsShowPopup(false);
  };

  /**
   * on type switch
   *
   * @param {*} type
   * @return {*}
   */
  const typeSwitch = type => {
    let name = '';

    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'ST':
        name = 'store';
        break;
      case 'RC':
        name = 'receivableCd';
        break;
      case 'FT':
        name = 'shippingMethod';
        break;
      default:
        break;
    }
    return name;
  };

  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    const name = typeSwitch(typeCommon);

    let newData: any =
      typeCommon === 'LC' ||
      typeCommon === 'MC' ||
      typeCommon === 'SC' ||
      typeCommon === 'UC' ||
      typeCommon === 'RC' ||
      typeCommon === 'FT'
        ? uniqBy([...formStore?.[name]].concat(data), 'code')
        : typeCommon === 'PD'
        ? uniqBy([...formStore?.[name]].concat(data), 'productId')
        : typeCommon === 'PG'
        ? uniqBy([...formStore?.[name]].concat(data), 'productGroupId')
        : uniqBy([...formStore?.[name]].concat(data), 'storeNo');

    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
    closePopup();
    if (typeCommon === 'RC' || typeCommon === 'FT') {
      setDataLocal(prev => ({ ...prev, [name]: newData }));
    }
  };
  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    const name = typeSwitch(type);
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: [] }));
    if (name === 'receivableCd' || name === 'shippingMethod') {
      setDataLocal(prev => ({ ...prev, [name]: [] }));
    }
  };

  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    const name = typeSwitch(type);
    let newData: any =
      type === 'LC' ||
      type === 'MC' ||
      type === 'SC' ||
      type === 'UC' ||
      type === 'RC' ||
      type === 'FT'
        ? [...formStore?.[name]].filter((o: any) => o?.code !== data?.code)
        : type === 'PD'
        ? [...formStore?.[name]].filter(
            (o: any) => o?.productId !== data?.productId,
          )
        : type === 'PG'
        ? [...formStore?.[name]].filter(
            (o: any) => o?.productGroupId !== data?.productGroupId,
          )
        : [...formStore?.[name]].filter(
            (o: any) => o?.storeNo !== data?.storeNo,
          );
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
    if (name === 'receivableCd' || name === 'shippingMethod') {
      setDataLocal(prev => ({ ...prev, [name]: newData }));
    }
  };

  /**
   * on field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        setFormData(preState => ({ ...preState, fromDate: e?.value }));
        if (formData.endDate && e?.value && e?.value > formData.endDate)
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        setFormData(preState => ({ ...preState, endDate: e?.value }));
        if (formData.fromDate && e?.value && e?.value < formData.fromDate)
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };
  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          formData={formData}
          ref={formRef}
          showColonAfterLabel={false}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
            {
              label: { text: t('Store') },
              dataField: 'store',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('ST');
                },
                buttons: [
                  {
                    name: 'store1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.store?.[0]?.storeNm,
                      onClick: () => handleRemove('ST', formStore?.store?.[0]),
                      visible: !!formStore?.store?.[0]?.storeNo,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'store2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.store?.[1]?.storeNm,
                      onClick: () => handleRemove('ST', formStore?.store?.[1]),
                      visible: !!formStore?.store?.[1]?.storeNo,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.store?.length - 2} ${t('more')}`,
                      visible: formStore?.store?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.store?.length,
                      onClick: () => handleCleanAll('ST'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('ST'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Shipping Method') },
              dataField: 'shippingMethod',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('FT');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.shippingMethod?.[0]?.name,
                      onClick: () =>
                        handleRemove('FT', formStore?.shippingMethod?.[0]),
                      visible: !!formStore?.shippingMethod?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.shippingMethod?.[1]?.name,
                      onClick: () =>
                        handleRemove('FT', formStore?.shippingMethod?.[1]),
                      visible: !!formStore?.shippingMethod?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.shippingMethod?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.shippingMethod?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.shippingMethod?.length,
                      onClick: () => handleCleanAll('FT'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('FT'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Receivable Group') },
              dataField: 'receivableCd',
              editorType: 'dxTextBox',
              colSpan: 2,
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('RC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.receivableCd?.[0]?.name,
                      onClick: () =>
                        handleRemove('RC', formStore?.receivableCd?.[0]),
                      visible: !!formStore?.receivableCd?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.receivableCd?.[1]?.name,
                      onClick: () =>
                        handleRemove('RC', formStore?.receivableCd?.[1]),
                      visible: !!formStore?.receivableCd?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.receivableCd?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.receivableCd?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.receivableCd?.length,
                      onClick: () => handleCleanAll('RC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('RC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Status') },
              dataField: 'status',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  { label: t('All'), value: '' },
                  { label: t('Pending'), value: '0' },
                  { label: t('Fixed'), value: '1' },
                ],
              },
            },
            {
              label: { text: t('Task Type') },
              colSpan: 2,
              dataField: 'taskType',
              editorType: 'dxRadioGroup',
              editorOptions: {
                valueExpr: 'value',
                items: [
                  { text: t('All'), value: 0 },
                  { text: t('Forward'), value: 1 },
                  { text: t('Return'), value: 2 },
                ],
                layout: 'horizontal',
              },
            },
          ]}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
        ></Form>
      </>
    );
  };
  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'lgCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('LC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[0]),
                      visible: !!formStore?.lgCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[1]),
                      visible: !!formStore?.lgCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.lgCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.lgCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.lgCategory?.length,
                      onClick: () => handleCleanAll('LC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('LC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mdCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('MC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[0]),
                      visible: !!formStore?.mdCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[1]),
                      visible: !!formStore?.mdCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mdCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mdCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mdCategory?.length,
                      onClick: () => handleCleanAll('MC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('MC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('PG');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[0]),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[1]),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => handleCleanAll('PG'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('PG'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('SC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[0]),
                      visible: !!formStore?.smCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[1]),
                      visible: !!formStore?.smCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smCategory?.length,
                      onClick: () => handleCleanAll('SC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('SC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('UC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[0]),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[1]),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => handleCleanAll('UC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('UC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopup('PD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[0]),
                      visible: !!formStore?.product?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[1]),
                      visible: !!formStore?.product?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => handleCleanAll('PD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('PD'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };

  let commonCodeTitle = '';
  switch (typeCommon) {
    case 'largeCategory':
      commonCodeTitle = t('Large Category');
      break;
    case 'mediumCategory':
      commonCodeTitle = t('Medium Category');
      break;
    case 'smallCategory':
      commonCodeTitle = t('Small Category');
      break;
    case 'subCategory':
      commonCodeTitle = t('Sub Category');
      break;
    default:
      break;
  }
  /**
   * render content popup
   *
   * @return {*}
   */
  const renderContentPopup = () => {
    if (!isShowPopup) return;
    switch (typeCommon) {
      case 'LC':
      case 'MC':
      case 'SC':
      case 'UC':
      case 'RC':
      case 'FT':
        return (
          <PopupSelectCommonCode
            visible={isShowPopup}
            text={
              typeCommon === 'LC'
                ? formData.lgCategory
                : typeCommon === 'MC'
                ? formData.mdCategory
                : typeCommon === 'SC'
                ? formData.smCategory
                : typeCommon === 'UC'
                ? formData.subCategory
                : typeCommon === 'RC'
                ? formData.receivableCd
                : typeCommon === 'FT'
                ? formData.shippingMethod
                : ''
            }
            title={commonCodeTitle}
            onHiding={() => {
              closePopup();
            }}
            codeType={typeCommon}
            mode="multiple"
            onSubmit={handleSubmitPopup}
          />
        );
      case 'PD':
        return (
          <PopupSelectProduct
            visible={isShowPopup}
            text={formData.product}
            onHiding={() => {
              closePopup();
            }}
            mode="multiple"
            onSubmit={handleSubmitPopup}
          />
        );
      case 'PG':
        return (
          <PopupSelectProductGroup
            visible={isShowPopup}
            text={formData.productGroup}
            onHiding={() => {
              closePopup();
            }}
            mode="multiple"
            onSubmit={handleSubmitPopup}
          />
        );
      case 'ST':
        return (
          <PopupSelectStore
            visible={isShowPopup}
            onHiding={() => {
              closePopup();
            }}
            text={formData.store}
            onSubmit={handleSubmitPopup}
            mode="multiple"
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="inventory-receiving-form-search">
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {renderContentPopup()}
    </div>
  );
};

export default FormSearch;
