/**
 * Update date: 08-06-2023
 * Date options component
 */

import React, { memo } from 'react';
import { Button } from 'devextreme-react/button';
import moment from 'moment';
import useDictionary from 'hooks/useDictionary';
import './style.scss';

interface PopupSelectMultiProps {
  onChangeValue?: any;
  optionSelectAll?: boolean;
  defaultDate?: Date;
  disableButton?: Array<
    'all' | 'today' | '3days' | '7days' | '30days' | '90days' | '180days'
  >;
  show15Day?: boolean;
}

const DateOptions = (props: PopupSelectMultiProps) => {
  const {
    onChangeValue,
    optionSelectAll = false,
    defaultDate = new Date(),
    disableButton = [],
    show15Day = false,
  } = props || {};
  const { t }: any = useDictionary({});

  // on date option selected
  const onDateSelected = (option: any) => {
    switch (option) {
      case 'all':
        onChangeValue(null, null);
        break;
      case 'today':
        onChangeValue(
          moment(defaultDate).startOf('date'),
          moment(defaultDate).endOf('date'),
        );
        break;
      case '3days':
        onChangeValue(
          moment(defaultDate).subtract(3, 'days').startOf('date'),
          moment(defaultDate).endOf('date'),
        );
        break;
      case '7days':
        onChangeValue(
          moment(defaultDate).subtract(7, 'days').startOf('date'),
          moment(defaultDate).endOf('date'),
        );
        break;
      case '15days':
        onChangeValue(
          moment(defaultDate).subtract(15, 'days').startOf('date'),
          moment(defaultDate).endOf('date'),
        );
        break;
      case '30days':
        onChangeValue(
          moment(defaultDate).subtract(30, 'days').startOf('date'),
          moment(defaultDate).endOf('date'),
        );
        break;
      case '90days':
        onChangeValue(
          moment(defaultDate).subtract(90, 'days').startOf('date'),
          moment(defaultDate).endOf('date'),
        );
        break;
      case '180days':
        onChangeValue(
          moment(defaultDate).subtract(180, 'days').startOf('date'),
          moment(defaultDate).endOf('date'),
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="date-options date-option-premiums">
        {optionSelectAll && !disableButton?.includes('all') && (
          <Button
            visible={optionSelectAll}
            text={t('All')}
            onClick={() => onDateSelected('all')}
          />
        )}
        {!disableButton?.includes('today') && (
          <Button text={t('Today')} onClick={() => onDateSelected('today')} />
        )}
        {!disableButton?.includes('3days') && (
          <Button
            text={t('Last 3 days')}
            onClick={() => onDateSelected('3days')}
            focusStateEnabled
          />
        )}
        {!disableButton?.includes('7days') && (
          <Button
            text={t('Last 7 days')}
            onClick={() => onDateSelected('7days')}
          />
        )}
        {show15Day && (
          <Button
            text={t('Last 15 days')}
            onClick={() => onDateSelected('15days')}
          />
        )}
        {!disableButton?.includes('30days') && (
          <Button
            text={t('Last 30 days')}
            onClick={() => onDateSelected('30days')}
          />
        )}
        {!disableButton?.includes('90days') && (
          <Button
            text={t('Last 90 days')}
            onClick={() => onDateSelected('90days')}
          />
        )}
        {!disableButton?.includes('180days') && (
          <Button
            text={t('Last 180 days')}
            onClick={() => onDateSelected('180days')}
          />
        )}
      </div>
    </>
  );
};

export default memo(DateOptions);
