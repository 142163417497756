/**
 * Update date: 29-05-2023
 * Screen 2044.7
 */
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import React, { useState, useRef, useEffect } from 'react';
import { uniqBy } from 'lodash';
import Collapse from 'app/components/Collapse';
import useFormatTime from 'hooks/useFormatTime';
import { useStoreContext } from '..';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import PopupSelectAllocationOrder from 'app/components/PopupCommon/PopupSelectAllocationOrder';
import useLocalStorage from 'hooks/useLocalStorage';
import { dateFormatStr } from 'utils/format';

interface IFormSearchProps {
  onSearch?: any;
}

const listTypeCommon = {
  receivableCode: {
    codeType: 'RC',
    title: 'Receivable Group',
  },
  shippingMethod: {
    codeType: 'FT',
    title: 'Shipping Method',
  },
  storeid: {
    codeType: 'storeid',
    title: 'Store',
  },
  allocation: {
    codeType: 'allocation',
    title: 'Allocation Order',
  },
};

const FormSearch = ({ onSearch }: IFormSearchProps) => {
  const context: any = useStoreContext();
  const { t } = context;
  const [dataLocal, setDataLocal] = useLocalStorage('2044.7_dataList', {});
  const [formStore, setFormStore] = useState<any>({
    receivableCode: [],
    shippingMethod: [],
    inquiryDate: new Date(),
    storeid: [],
    allcation: [
      {
        allocate_dm: new Date(),
        allocate_seq: 0,
        way_bill: '',
      },
    ],
  });
  const [formData, setFormData] = useState<any>({
    receivableCode: '',
    shippingMethod: '',
    inquiryDate: new Date(),
    storeid: '',
    allocation: '',
    sendTF: true,
  });
  const [showPopupByType, setShowPopupByType] = useState('');
  const formRef: any = useRef(null);
  const { DateFormat } = useFormatTime();

  /**
   * on submit
   *
   * @return {*}
   */
  function onSubmit() {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;

    const allocateSeq: Array<number> = [];

    // let allocateDt;
    if (formStore?.allocation?.length > 0) {
      // allocateDt = moment(formStore?.allocation?.[0]?.allocate_dm).format(
      //   'YYYY-MM-DD',
      // );
      formStore?.allocation?.forEach((o: any) => {
        allocateSeq.push(o?.allocate_seq);
      });
    }
    const searchParams = {
      receivableCd: formStore?.receivableCode?.map((o: any) => {
        return o?.code;
      }),
      shippingMethod: formStore?.shippingMethod?.map((o: any) => {
        return o?.code;
      }),
      // sendDueDt: moment(formData?.inquiryDate).format('YYYY-MM-DD'),
      storeId: formStore?.storeid?.map((o: any) => {
        return o?.storeNo;
      }),
      allocateSeq: allocateSeq,
      allocateDt: dateFormatStr(formData?.inquiryDate), //allocateDt,
      sendTF: formData?.sendTF,
    };
    onSearch(searchParams);
  }

  /**
   * on remove store
   *
   * @param {*} varNm
   * @param {*} index
   */
  const removeStore = (varNm, index) => {
    let newStore;
    if (index === -1) {
      setFormStore({ ...formStore, [varNm]: [] });
    } else {
      const l = [...formStore[varNm]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setFormStore({ ...formStore, [varNm]: newStore });
    }
    if (varNm === 'receivableCode') {
      setDataLocal({
        receivableCode: newStore,
      });
    }
  };

  /**
   * on update store
   *
   * @param {*} varNm
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (varNm, data, key: any = false) => {
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(formStore?.[varNm] || []), ...data], key);
    }
    const newStore = { ...formStore, [varNm]: filterData };
    setFormData({ ...formData, [varNm]: '' });
    setFormStore({ ...newStore });
    setShowPopupByType('');
    if (varNm === 'receivableCode') {
      setDataLocal({
        receivableCode: filterData,
      });
    }
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    numberShowMore = 2,
  ): any => {
    const dataList = formStore?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });

    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      editorOptions: {
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'receivableCode':
      case 'shippingMethod':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      case 'storeid':
        return (
          <PopupSelectStore
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'storeNo')}
          />
        );
      case 'allocation':
        const allocateParam = `fromDate=${dateFormatStr(
          formData?.inquiryDate,
        )}&endDate=${dateFormatStr(formData?.inquiryDate)}`;
        return (
          <PopupSelectAllocationOrder
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'way_bill')}
            params={allocateParam}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChange = e => {
    if (e?.dataField === 'inquiryDate') {
      setFormStore({ ...formStore, allocation: [] });
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          readOnly={false}
          showColonAfterLabel={false}
          colCount={3}
          onFieldDataChanged={onFieldDataChange}
          items={[
            {
              label: { text: t(' Allocation Date') },
              dataField: 'inquiryDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.inquiryDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('Inquiring Date is required'),
                },
              ],
            },
            generateDxTextBox(
              'Allocation Order',
              'allocation',
              'allocate_seq',
              2,
            ),
            generateDxTextBox('Receivable Group', 'receivableCode', 'name', 2),
            generateDxTextBox('Store', 'storeid', 'storeNm'),
            generateDxTextBox('Shipping Method', 'shippingMethod', 'name', 2),
            {
              dataField: 'sendTF',
              editorType: 'dxCheckBox',
              label: {
                text: t('Show Unprinted'),
                location: 'left',
              },
            },
          ]}
          labelLocation="top"
        />
      </>
    );
  };
  /**
   * fetch data
   */
  useEffect(() => {
    setFormStore(preState => ({
      ...preState,
      receivableCode: dataLocal?.receivableCode || [],
    }));
  }, [dataLocal]);

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
