/**
 * Update date: 14-06-2023
 * Popup confirm component
 */
import React, { memo } from 'react';
import { Button, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { file } from 'images';

interface IPopupConfirm {
  visible?: boolean;
  onOk?: () => void;
  onHiding?: () => void;
  onHidingCallback?: () => void;
  content?: any;
  title?: string;
  des?: string;
}
function PopupConfirm({
  visible,
  onOk,
  onHiding,
  onHidingCallback,
  content,
  title,
  des,
}: IPopupConfirm) {
  const { t }: any = useDictionary({});
  return (
    <Popup
      visible={visible}
      onHiding={() => onHiding?.()}
      showTitle={true}
      title={''}
      width={350}
      height={'auto'}
      className="modal-confirm-popup"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          gap: 10,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <img className="icon" src={file} alt="icon warning" />
        </div>
        <div className="modal-confirm-popup-des">
          {content || des || t('Do you want to cancel changed?')}
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <Button
            text={t('No')}
            onClick={() => {
              onHiding?.();
              onHidingCallback?.();
            }}
            width={'100%'}
          />
          <Button
            className="button-warning"
            text={t('Yes')}
            onClick={() => onOk?.()}
            width={'100%'}
          />
        </div>
      </div>
    </Popup>
  );
}

export default memo(PopupConfirm);
