/**
 * Create date: 2024-09-09
 * Update date: 2024-09-09
 * Screen 1041.2
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button } from 'devextreme-react';
import Sortable from 'devextreme-react/sortable';
import TreeView from 'devextreme-react/tree-view';
import { forwardRef, useContext } from 'react';
import { dataBookMark, useModuleContext } from '..';
import iconBookmarkP from '../../../../images/themePro/icon_bookmark.svg';
import { LayoutContext } from 'app/components/Layout';

function TreeViewList(props, ref) {
  const layoutContext: any = useContext(LayoutContext);
  const context: any = useModuleContext();
  const { t, treeList, setSelected, mapDataToTree, setExpanded } = context;
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const onRemoveBookmark = async programId => {
    const res = await refetchData({
      url: '/user/m1041_2/update-bookmark-menu',
      method: 'PUT',
      data: {
        programId: programId,
        bookMark: false,
        isReturnData: true,
      },
    });
    if (res?.data?.status === '201') {
      layoutContext.updateBookMark({
        programId: programId,
        bookMark: false,
      });
      mapDataToTree(res?.data?.data);
    }
  };
  // on drag change
  const onDragChange = e => {
    const treeViewRef = ref?.current?.instance;
    const fromNode = findNode(treeViewRef, e.fromIndex);
    const toNode = findNode(treeViewRef, calculateToIndex(e));
    // not drag and drop item in notGroup
    if (
      fromNode?.itemData?.parentId === dataBookMark.notGroup &&
      toNode?.itemData?.parentId === dataBookMark.notGroup
    ) {
      e.cancel = true;
    }
    // not drag and drop from item isGroup to notGroup
    if (
      fromNode?.itemData?.parentId === dataBookMark.isGroup &&
      (toNode?.itemData?.id === dataBookMark.notGroup ||
        toNode?.itemData?.parentId === dataBookMark.notGroup)
    ) {
      e.cancel = true;
    }
    // not drag and drop from item notGroup to isGroup parent
    if (
      fromNode?.itemData?.parentId === dataBookMark.notGroup &&
      toNode?.itemData?.id === dataBookMark.isGroup
    ) {
      e.cancel = true;
    }
    // not drag and drop from item isGroup to isGroup parent
    if (toNode?.itemData?.id === dataBookMark.isGroup) {
      e.cancel = true;
    }
    if (
      fromNode?.itemData?.parentId === dataBookMark.isGroup &&
      toNode?.itemData?.level === 2
    ) {
      e.cancel = true;
    }
  };
  // calculate to index
  const calculateToIndex = e => {
    if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
      return e.toIndex;
    }
    return e.fromIndex >= e.toIndex ? e.toIndex : e.toIndex + 1;
  };

  // on drag end
  const onDragEnd = async e => {
    if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
      return;
    }
    const treeViewRef = ref?.current?.instance;
    const fromNode = findNode(treeViewRef, e.fromIndex);
    let toNode = findNode(treeViewRef, calculateToIndex(e));
    if (!fromNode?.itemData?.parentId) {
      return;
    }
    if (!toNode) {
      toNode = findNode(treeViewRef, e.toIndex);
    }
    const { programId: fromProgramId, parentId: fromParentId } =
      fromNode?.itemData || {};
    const {
      programId: toProgramId,
      parentId: toParentId,
      id: toId,
    } = toNode?.itemData;
    let apiConfig: any = {};
    if (
      fromParentId === dataBookMark.isGroup &&
      (toParentId === dataBookMark.isGroup || toNode?.itemData?.level === 2)
    ) {
      apiConfig = {
        url: '/user/m1041_2/update-sort-no-group',
        method: 'POST',
        data: {
          bookmarkId: fromProgramId,
          sortNo:
            toNode?.itemData?.level === 2
              ? toNode?.parent?.itemData?.sortNo
              : toNode?.itemData.sortNo,
        },
      };
    } else if (
      fromParentId !== dataBookMark.isGroup &&
      (toId === dataBookMark.notGroup || toParentId === dataBookMark.notGroup)
    ) {
      apiConfig = {
        url: '/user/m1041_2/item',
        method: 'DELETE',
        data: {
          bookmarkId: fromParentId,
          programId: fromProgramId,
        },
      };
    } else {
      const dataRequest: any = {};
      dataRequest.programId = fromProgramId;
      dataRequest.oldParentId =
        fromParentId === dataBookMark.notGroup ? '' : fromParentId;
      if (toParentId === dataBookMark.isGroup) {
        dataRequest.newParentId = toProgramId;
        dataRequest.sortNo = 1;
      } else {
        dataRequest.newParentId = toParentId;
        dataRequest.sortNo = toNode?.itemData.sortNo;
      }
      apiConfig = {
        url: '/user/m1041_2/save-item',
        method: 'PUT',
        data: dataRequest,
      };
      setExpanded(prev => {
        prev.push(dataRequest.newParentId);
        return prev;
      });
    }
    const res = await refetchData(apiConfig);
    if (res?.data?.status === '201' || res?.data?.status === '200') {
      mapDataToTree(res?.data?.data);
    }
  };

  const findNode = (treeView, index) => {
    const nodeElement = treeView
      .element()
      .querySelectorAll('.dx-treeview-node')?.[index];
    if (nodeElement) {
      return findNodeById(
        treeView.getNodes(),
        nodeElement.getAttribute('data-item-id'),
      );
    }
    return null;
  };

  const findNodeById = (nodes, id) => {
    for (let i = 0; i < nodes.length; i += 1) {
      if (nodes[i].itemData.id === id) {
        return nodes[i];
      }
      if (nodes[i].children) {
        const node = findNodeById(nodes[i].children, id);
        if (node != null) {
          return node;
        }
      }
    }
    return null;
  };
  // render tree list
  const renderTreeList = e => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
          onClick={() => {
            if (e?.parentId === dataBookMark.isGroup) {
              setSelected({
                type: 'edit',
                data: e,
              });
            } else {
              setSelected(null);
            }
          }}
        >
          {e?.level !== 0 && (
            <i
              style={{ marginRight: 5 }}
              className="dx-icon dx-icon-dragvertical"
            ></i>
          )}
          {!e?.parentId || e?.parentId === dataBookMark.isGroup
            ? `${!e?.parentId ? t(e.programName) : e.programName}`
            : e?.programId + ': ' + e.programName}
        </div>
        {(e?.level === 2 || e?.parentId === dataBookMark.notGroup) && (
          <img
            src={iconBookmarkP}
            width={20}
            height={20}
            title={t('Remove Bookmark')}
            alt={t('Remove Bookmark')}
            onClick={(event: any) => {
              onRemoveBookmark(e?.programId);
              event?.stopPropagation?.();
            }}
          ></img>
        )}
      </div>
    );
  };

  return (
    <>
      <div style={{ background: 'white' }}>
        <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Button
            stylingMode="contained"
            type="default"
            text={t('Add Bookmark Group')}
            onClick={() => {
              setSelected({
                type: 'create',
                data: null,
              });
            }}
            style={{ marginRight: 5 }}
          />
        </div>
        <Sortable
          filter=".dx-treeview-item"
          group="shared"
          allowDropInsideItem={true}
          allowReordering={true}
          onDragStart={e => {
            const treeViewRef = ref?.current?.instance;
            const fromNode = findNode(treeViewRef, e.fromIndex);
            if (!fromNode?.itemData?.parentId) {
              e.cancel = true;
            }
          }}
          onDragChange={onDragChange}
          onDragEnd={onDragEnd}
          style={{
            padding: '5px 20px 20px 20px',
            backgroundColor: '#fff',
            borderRadius: 6,
            width: 630,
          }}
        >
          <TreeView
            id="treeviewPrograme"
            expandNodesRecursive={false}
            expandAllEnabled={true}
            dataStructure="plain"
            ref={ref}
            items={treeList}
            itemRender={renderTreeList}
            width={'100%'}
            height={'650'}
            displayExpr="programName"
            onItemCollapsed={(e: any) => {
              setExpanded(prev => {
                return prev?.filter(item => item !== e?.itemData?.id) || [];
              });
            }}
            onItemExpanded={e => {
              setExpanded(prev => {
                prev.push(e?.itemData?.id);
                return prev;
              });
            }}
          />
        </Sortable>
        <LoadPanel visible={loading} />
      </div>
    </>
  );
}

export default forwardRef(TreeViewList);
