/**
 * Update date: 23-05-2023
 * Screen 2031.2
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { useRef } from 'react';
import { useOrderDetail } from '..';

function SpecialDealTable(props) {
  const { themePro }: any = useApp();
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useOrderDetail();
  const { orderData } = context;

  const { t }: any = useDictionary({
    programId: '2031.2',
  });

  /**
   * render order progess
   *
   * @param {*} e
   * @return {*}
   */
  const renderOrderProgress = e => {
    return t(e?.value);
  };

  const columns: any = [
    {
      caption: t('Order'),
      alignment: 'center',
      items: [
        {
          dataField: 'ordbillid',
          caption: t('Bill ID'),
          showWhenGrouped: true,
        },
        {
          dataField: 'orddate',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'ordvendornm',
          caption: t('Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'ordstorenm',
          caption: t('Store'),
          showWhenGrouped: true,
        },
        {
          dataField: 'ordsenddate',
          caption: t('Send Due Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'ordnumber',
          caption: t('Order Number'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'ordprogress',
          caption: t('Progress'),
          showWhenGrouped: true,
          cellRender: renderOrderProgress,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'prdcd',
          caption: t('Product code'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'prdnm',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'prdmarketsz',
          caption: t('Marketable Size'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'prdoptioncd',
          caption: t('Option Code'),
          showWhenGrouped: true,
          visible: false,
          alignment: themePro ? 'left' : 'center',
        },
        {
          dataField: 'prdoptionnm',
          caption: t('Option'),
          showWhenGrouped: true,
          alignment: themePro ? 'left' : 'center',
        },
        {
          dataField: 'prdqtyperpack',
          caption: t('Quantity Per Pack'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      columns: [
        {
          dataField: 'cntunitprice',
          caption: t('Purchase Unit Price'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntea',
          caption: t('Ea'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntqty',
          caption: t('Qty'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntamount',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntsupplyamt',
          caption: t('Supply Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntvat',
          caption: t('VAT'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntbottle',
          caption: t('Bottle'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntcontainer',
          caption: t('Container'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntgtotal',
          caption: t('G.Total'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      dataField: 'billnote',
      caption: t('Bill Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'linenote',
      caption: t('Line Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'bottleNm',
      caption: t('Bottle ID'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'bottleunitprice',
      caption: t('Bottle Unit Price'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerid',
      caption: t('Container ID'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerunitprice',
      caption: t('Container Unit Price'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'currency',
      caption: t('Currency'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'makernm',
      caption: t('Maker'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'originnm',
      caption: t('Origin'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'defltvendornm',
      caption: t('Default Vendor'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'largecategorynm',
      caption: t('Large category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'mediumcategorynm',
      caption: t('Medium category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'smallcategorynm',
      caption: t('Small category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'subcategorynm',
      caption: t('Sub category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerdm',
      caption: t('Register Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerusername',
      caption: t('Register User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerprogramnm',
      caption: t('Register Program ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifydm',
      caption: t('Modify Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyusername',
      caption: t('Modify User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyprogramnm',
      caption: t('Modify Program ID'),
      showWhenGrouped: true,
      visible: false,
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName =
    'SMDC_2031.2 Order details check' + moment().format('YYYYMMDD');

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={orderData}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !orderData,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'cntqty',
                column: 'cntqty',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntea',
                column: 'cntea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntamount',
                column: 'cntamount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntsupplyamt',
                column: 'cntsupplyamt',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntvat',
                column: 'cntvat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntbottle',
                column: 'cntbottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntcontainer',
                column: 'cntcontainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntgtotal',
                column: 'cntgtotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'cntea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntqty',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntamount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntsupplyamt',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntvat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntbottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntcontainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntgtotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
    </>
  );
}

export default SpecialDealTable;
