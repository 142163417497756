/**
 * Update date: 28-08-2023
 * Popup product detail component
 */
import { DataGridFull } from 'app/components/DataGrid';
import { Button } from 'devextreme-react';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { useState, useEffect, useRef } from 'react';
import { useProductDetail } from '..';
import moment from 'moment';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';

function SalesInformation() {
  const { t, data, dateFormatStr, productId }: any = useProductDetail();
  const [selected, setSelected] = useState('3days');
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, QtyFormat, IntegerFormat } = useFormat();
  const [listData, setListData] = useState([]);
  const dataGridRef: any = useRef(null);
  const onDateSelected = async (option: any) => {
    setSelected(option);
    let startDate = '';
    let endDate = '';
    switch (option) {
      case '3days':
        startDate = dateFormatStr(moment(new Date()).subtract(3, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '7days':
        startDate = dateFormatStr(moment(new Date()).subtract(7, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '30days':
        startDate = dateFormatStr(moment(new Date()).subtract(30, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '90days':
        startDate = dateFormatStr(moment(new Date()).subtract(90, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '180days':
        startDate = dateFormatStr(moment(new Date()).subtract(660, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      default:
        break;
    }
    const res = await refetch({
      data: {
        productId: productId,
        startDate,
        endDate,
        type: '2',
      },
    });
    setListData(res?.data?.data?.sale);
  };

  const [{ loading }, refetch] = useAxios(
    {
      url: 'product/product/popup-purchase-sale-event',
      method: 'POST',
    },
    {
      manual: true,
      autoCancel: true,
      useCache: false,
    },
  );

  /**
   * render status
   *
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    return (
      <div>
        {e?.value === '0' ? t('Pending') : e?.value === '1' ? t('Fixed') : ''}
      </div>
    );
  };

  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  useEffect(() => {
    if (data?.data) {
      setListData(data?.data?.sale);
    }
  }, [data?.data]);
  return (
    <>
      <LoadPanel visible={loading} />
      <div className="date-options date-option-premiums">
        <Button
          text={t('Last 3 days')}
          onClick={() => onDateSelected('3days')}
          className={`${
            selected === '3days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 7 days')}
          onClick={() => onDateSelected('7days')}
          className={`${
            selected === '7days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 30 days')}
          onClick={() => onDateSelected('30days')}
          className={`${
            selected === '30days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 90 days')}
          onClick={() => onDateSelected('90days')}
          className={`${
            selected === '90days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 180 days')}
          onClick={() => onDateSelected('180days')}
          className={`${
            selected === '180days' && 'popup-product-selected-button'
          }`}
        />
      </div>
      <DataGridFull
        storageKeyInquiry="sales_information"
        ref={dataGridRef}
        dataSource={listData}
        options={{
          columnAutoWidth: true,
          height: 'calc(100vh - 260px)',
          groupPanel: {
            visible: true,
          },
          export: {
            fileName: 'Sales Information',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                column: 'ea',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'discount_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'bottle_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'container_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'shipping_fee',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'add_shipping_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'g_total',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'ea',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discount_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'bottle_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'container_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'shipping_fee',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'add_shipping_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'g_total',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
        columns={[
          {
            caption: t('Forward/Return'),
            alignment: 'center',
            items: [
              {
                dataField: 'task_type_nm',
                caption: t('Task Type'),
                showWhenGrouped: true,
              },
              {
                dataField: 'bill_id',
                caption: t('Bill ID'),
                showWhenGrouped: true,
              },
              {
                dataField: 'date',
                caption: t('Date'),
                dataType: 'date',
                format: DateFormat,
                alignment: 'left',
                showWhenGrouped: true,
              },
              {
                dataField: 'store_nm',
                caption: t('Store'),
                showWhenGrouped: true,
              },
              {
                dataField: 'zone_nm',
                caption: t('Zone'),
                showWhenGrouped: true,
              },
              {
                dataField: 'state_nm',
                caption: t('State'),
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'status',
                caption: t('Status'),
                cellRender: renderStatus,
                showWhenGrouped: true,
                visible: false,
              },
            ],
          },
          {
            caption: t('Product'),
            alignment: 'center',
            columns: [
              {
                dataField: 'product_cd',
                caption: t('Product code'),
                visible: false,
                showWhenGrouped: true,
              },
              {
                dataField: 'product_nm',
                caption: t('Product Name'),
                visible: false,
                showWhenGrouped: true,
              },
              {
                dataField: 'marketable_size',
                caption: t('Marketable Size'),
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'option_cd',
                caption: t('Option Code'),
                showWhenGrouped: true,
              },
              {
                dataField: 'option_nm',
                caption: t('Option'),
                showWhenGrouped: true,
              },
              {
                dataField: 'receivable_cd_nm',
                caption: t('Receivable Group'),
                dataType: 'string',
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'deposit_processing_tf',
                caption: t('Deposit Processing'),
                editorType: 'dxSwitch',
                dataType: 'boolean',
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'quantity_per_pack',
                caption: t('Quantity Per Pack'),
                showWhenGrouped: true,
              },
            ],
          },
          {
            caption: t('Content'),
            alignment: 'center',
            columns: [
              {
                dataField: 'supply_unit_price',
                caption: t('Supply Unit Price'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'quantity',
                caption: t('Qty'),
                format: QtyFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'ea',
                caption: t('EA'),
                format: QtyFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'supply_standard_unit_price',
                caption: t('Supply Standard Unit Price'),
                format: IntegerFormat,
                dataType: 'number',
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'margin_rate',
                caption: t('Margin Rate'),
                format: IntegerFormat,
                dataType: 'number',
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'dc_unit_price',
                caption: t('Discount Unit Price'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'discount_amount',
                caption: t('Discount Amount'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
                visible: false,
              },
              {
                dataField: 'amount',
                caption: t('Amount'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'supply_amount',
                caption: t('Supply Amount'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'vat_amount',
                caption: t('VAT'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'bottle_amount',
                caption: t('Bottle'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'container_amount',
                caption: t('Container'),
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'shipping_fee',
                caption: t('Shipping Fee'),
                alignment: 'right',
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'add_shipping_amount',
                caption: t('Add Shipping Amount'),
                alignment: 'right',
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'g_total',
                caption: t('G.Total'),
                alignment: 'right',
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
            ],
          },
          {
            caption: t('Product Info'),
            alignment: 'center',
            visible: false,
            columns: [
              {
                dataField: 'tax_nm',
                caption: t('Tax Name'),
                showWhenGrouped: true,
              },
              {
                dataField: 'payment_method',
                caption: t('Payment Method'),
                showWhenGrouped: true,
              },
              {
                dataField: 'shipping_rate',
                caption: t('Shipping Rate'),
                alignment: 'right',
                format: AmountFormat,
                dataType: 'number',
                showWhenGrouped: true,
              },
              {
                dataField: 'order_number',
                caption: t('Order Number'),
                showWhenGrouped: true,
              },
              {
                dataField: 'order_name',
                caption: t('Orderer Name'),
                showWhenGrouped: true,
              },
              {
                dataField: 'receiver',
                caption: t('Receiver'),
                showWhenGrouped: true,
              },
              {
                dataField: 'way_bill',
                caption: t('Way Bill'),
                showWhenGrouped: true,
              },
              {
                dataField: 'invoice',
                caption: t('Invoice'),
                showWhenGrouped: true,
              },
              {
                dataField: 'delivery_drive_id',
                caption: t('Delivery Driver Id'),
                showWhenGrouped: true,
              },
              {
                dataField: 'delivery_driver_nm',
                caption: t('Delivery Driver Name'),
                showWhenGrouped: true,
              },
            ],
          },
          {
            dataField: 'bill_nt',
            caption: t('Bill Note'),
            visible: false,
          },
          {
            dataField: 'line_nt',
            caption: t('Line Note'),
            visible: false,
          },
          {
            dataField: 'bottle_id',
            caption: t('Bottle ID'),
            visible: false,
          },
          {
            dataField: 'bottle_unit_price',
            caption: t('Bottle Unit Price'),
            visible: false,
          },
          {
            dataField: 'container_id',
            caption: t('Container ID'),
            visible: false,
          },
          {
            dataField: 'container_unit_price',
            caption: t('Container Unit Price'),
            visible: false,
          },
          {
            dataField: 'maker_nm',
            caption: t('Maker'),
            visible: false,
          },
          {
            dataField: 'origin_nm',
            caption: t('Origin'),
            visible: false,
          },
          {
            dataField: 'vendor_nm_default',
            caption: t('Default Vendor'),
            visible: false,
          },
          {
            dataField: 'large_category',
            caption: t('Large Category'),
            visible: false,
          },
          {
            dataField: 'medium_category',
            caption: t('Medium Category'),
            visible: false,
          },
          {
            dataField: 'small_category',
            caption: t('Small Category'),
            visible: false,
          },
          {
            dataField: 'sub_category',
            caption: t('Sub Category'),
            visible: false,
          },
          {
            dataField: 'regist_dm',
            caption: t('Register Datetime'),
            dataType: 'date',
            format: DateTimeFormat,
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'register_user_nm',
            caption: t('Register User'),
            visible: false,
          },
          {
            dataField: 'register_program_id',
            caption: t('Register ProgramID'),
            visible: false,
          },
          {
            dataField: 'modify_dm',
            caption: t('Modify Datetime'),
            dataType: 'date',
            format: DateTimeFormat,
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'modify_user_nm',
            caption: t('Modify User'),
            visible: false,
          },
          {
            dataField: 'modify_program_id',
            caption: t('Modify ProgramID'),
            visible: false,
          },
        ]}
      />
    </>
  );
}

export default SalesInformation;
