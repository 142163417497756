/**
 * Create date: 2024-06-20
 * Screen 2082.C
 */

import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import React, { memo, useRef } from 'react';

const SummaryTab = ({ t, store }) => {
  const dataGridRef: any = useRef(null);
  const { QtyFormat, AmountFormat } = useFormat();

  const columns = [
    {
      dataField: 'vendorNm',
      caption: t('Vendor'),
      alignment: 'left',
      groupIndex: 0,
    },
    {
      dataField: 'manufacturerNm',
      caption: t('Manufacturer'),
      alignment: 'left',
    },
    {
      dataField: 'taxTpNm',
      caption: t('Taxation Classification'),
    },
    {
      dataField: 'suppCommision',
      caption: t('Sales Commission Rate (%)'),
      alignment: 'right',
      format: '##0.##',
      dataType: 'number',
    },
    {
      dataField: 'saleQt',
      caption: t('Sale Qty'),
      alignment: 'right',
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'totalSaleAmount',
      caption: t('Total Sales Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'discountAmount',
      caption: t('Brand Coupon Discount Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'productSaleAmount',
      caption: t('Product Sales Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'saleCommisionAmount',
      caption: t('Sales Commission Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'receiveAmount',
      caption: t('Scheduled Payment Amount(Including VAT)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'supplyAmount',
      caption: t('Scheduled Payment Amount (Supply Price)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'vatAmount',
      caption: t('Scheduled Payment Amount (VAT)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  /**
   * Export excel gridview
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        columns={columns}
        fixedLeft={2}
        fixedRight={0}
        isShowZeroNumber
        options={{
          columnAutoWidth: true,
          groupPanel: {
            visible: true,
          },
          export: {
            fileName: `M2082.C: Report on Specific Purchase and Sales (Summary)_${moment().format(
              'YYYYMMDD',
            )}`,
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export To Excel'),
                  onClick: () => exportExcel(),
                  disabled: !store?.length,
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                column: 'saleQt',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalSaleAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'productSaleAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'saleCommisionAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receiveAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vatAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'saleQt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalSaleAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'productSaleAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'saleCommisionAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receiveAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vatAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </React.Suspense>
  );
};

export default memo(SummaryTab);
