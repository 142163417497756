/**
 * Update date: 08-06-2023
 * My page component
 */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LoadPanel from 'app/components/LoadPanel';
import { Button, Form } from 'devextreme-react';
import useAxios from 'axios-hooks';
import { notification } from 'utils/notification';
import { useMyPage } from '..';
import { authActions } from 'store/features/auth/authSlice';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import * as languageList from 'utils/language.json';
import * as themeList from 'utils/theme.json';
import * as fontList from 'utils/font.json';
import * as fontSizeList from 'utils/font-size.json';
import { locale } from 'devextreme/localization';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import { useLayout } from 'app/components/Layout';
import { LANG_DEFAULT } from 'constant';

const Preference = () => {
  const { setShowOtherPage }: any = useLayout();
  const appContext: any = useApp();
  const { myPageApp, themePro } = appContext || {};
  const myPageContext: any = useMyPage();
  const { setModeView } = myPageContext || {};
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [showPopupConfirmLogout, setShowPopupConfirmLogout] = useState(false);
  const languageDefault = myPageApp?.language || languageList?.list[0].value;
  const [fontListData, setFontListData] = useState([]);
  const [formData, setFormData] = useState<any>({
    // timezoneName: myPageData?.timezoneName || timeZoneList?.list[0].value,
    timezoneName: '',
    language: myPageApp?.language || languageList?.list[0].value,
    defaultInputLanguage:
      myPageApp?.defaultInputLanguage || languageList?.list[0].value,
    fontName: myPageApp?.fontName || fontList?.list[0].value,
    fontSize: myPageApp?.fontSize || 'medium',
    theme: myPageApp?.theme || themeList?.list[0].value,
  });
  const formRef: any = useRef(null);
  const fontListJSON = JSON.parse(JSON.stringify(fontList?.list));

  const dispatch = useDispatch();

  const { t }: any = useDictionary({
    programId: '1031.7',
  });

  const [{ loading: updatePreferenceLoading }, updatePreference] = useAxios(
    {
      url: 'user/my-page/preferences',
      method: 'PUT',
    },
    {
      manual: true,
    },
  );

  /**
   * on language changed
   *
   * @param {*} e
   */
  const onLanguageChanged = e => {
    const fontByLanguageList = fontListJSON.filter(
      item => item?.lang == e?.value,
    );

    formRef?.current?.instance.updateData(
      'fontName',
      fontByLanguageList[0]?.value,
    );
    setFontListData(fontByLanguageList);
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    setShowPopupConfirmLogout(true);
  };
  const onOkLogout = async () => {
    const params = { ...formData };
    const res = await updatePreference({ data: params });

    if (res?.data?.status === '200') {
      const myPagePreferenceLocal = JSON.parse(
        localStorage.getItem('myPagePreference') ?? '{}',
      );
      localStorage.setItem(
        'myPagePreference',
        JSON.stringify({
          ...myPagePreferenceLocal,
          ...formData,
        }),
      );
      setShowPopupConfirmLogout(true);
    }
    setShowOtherPage('');
    document.documentElement.lang = formData?.language;
    locale(formData?.language);
    changeSetting();
    dispatch(authActions.logout());
    notification({
      res,
    });
  };

  /**
   * on change setting
   *
   */
  const changeSetting = () => {
    locale(formData?.language || LANG_DEFAULT);
    document.body.style.fontFamily = `${formData?.fontName}, sans-serif`;
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    const cssLink = `${window.location.origin}/themes/dx.${
      formData?.theme || 'light'
    }.${formData?.fontSize || 'medium'}.css`;
    link.setAttribute('href', cssLink);
    document.head.appendChild(link);
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    setModeView(preState => ({ ...preState, type: 'list' }));
  };

  useEffect(() => {
    const fontByLanguageList = fontListJSON.filter(
      item => item?.lang == myPageApp?.language,
    );

    setFontListData(fontByLanguageList);
  }, [myPageApp]);

  return (
    <>
      <LoadPanel visible={updatePreferenceLoading} />
      {themePro && (
        <div className="my-page-caption">
          <div>
            <div className="my-page-caption-title">{t('Preference')}</div>
            <div className="my-page-caption-des">
              {t('Change the time, language, font, etc.')}
            </div>
          </div>
          <Button
            icon="check"
            stylingMode="contained"
            type="default"
            text={t('Save')}
            onClick={onSubmit}
          />
        </div>
      )}
      <Form
        ref={formRef}
        formData={formData}
        colCount={1}
        labelLocation="top"
        showColonAfterLabel={false}
        scrollingEnabled={true}
        focusStateEnabled={true}
        hoverStateEnabled={true}
        items={[
          {
            caption: themePro ? '' : t('Preference'),
            itemType: 'group',
            colCount: 2,
            items: [
              {
                itemType: 'group',
                colSpan: themePro ? 2 : 1,
                colCount: themePro ? 2 : 1,
                items: [
                  // {
                  //   label: {
                  //     text: t('Time Zone'),
                  //   },
                  //   dataField: 'timezoneName',
                  //   editorType: 'dxSelectBox',
                  //   editorOptions: {
                  //     items: timeZoneList?.list,
                  //     displayExpr: 'text',
                  //     valueExpr: 'value',
                  //     searchEnabled: true,
                  //   },
                  // },
                  {
                    label: {
                      text: t('Language'),
                    },
                    dataField: 'language',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: languageList?.list,
                      displayExpr: 'label',
                      valueExpr: 'value',
                      onValueChanged: onLanguageChanged,
                    },
                  },
                  {
                    label: {
                      text: t('Font'),
                    },
                    dataField: 'fontName',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: fontListData,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                  },
                  {
                    label: {
                      text: t('Font Size'),
                    },
                    dataField: 'fontSize',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: fontSizeList?.list,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                  },
                  {
                    label: {
                      text: t('Theme'),
                    },
                    dataField: 'theme',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: themeList?.list,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                  },
                  // {
                  //   label: {
                  //     text: t('Default Input Language'),
                  //   },
                  //   dataField: 'defaultInputLanguage',
                  //   editorType: 'dxSelectBox',
                  //   editorOptions: {
                  //     items: languageList?.list,
                  //     displayExpr: 'label',
                  //     valueExpr: 'value',
                  //     // onValueChanged: onLanguageChanged,
                  //   },
                  // },
                ],
              },
            ],
          },
        ]}
      ></Form>
      {!themePro && (
        <div className="my-page-btn-footer">
          <Button icon="save" text={t('Save')} onClick={onSubmit} />
          <Button
            icon="close"
            text={t('Cancel')}
            onClick={() => setShowPopupConfirm(true)}
          />
        </div>
      )}
      {showPopupConfirm && (
        <PopupConfirm
          title={t('Confirm Cancel')}
          visible={showPopupConfirm}
          content={t('Are you sure to cancel all the change?')}
          onOk={onCancel}
          onHiding={() => setShowPopupConfirm(false)}
        />
      )}
      {showPopupConfirmLogout && (
        <PopupConfirm
          title={t('Confirm Logout')}
          visible={showPopupConfirmLogout}
          content={t('Are you want to logout to save the change?')}
          onOk={onOkLogout}
          onHiding={() => setShowPopupConfirmLogout(false)}
        />
      )}
    </>
  );
};

export default Preference;
