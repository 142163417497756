import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/user';
import tokenService from 'services/auth.service';

export interface LoginPayLoad {
  dataLogin: object;
  loginSuccess: Function;
  loginFail: Function;
}

export interface AuthState {
  isLoggedIn: boolean;
  logging?: boolean;
  currentUser?: User;
  messageLogin?: string;
}
const initialState: AuthState = {
  isLoggedIn: Boolean(tokenService.getLocalAccessToken()),
  logging: false,
  currentUser: tokenService.getLocalCurrentUser(),
  messageLogin: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayLoad>) {
      state.logging = true;
    },
    loginSuccess(state, action: PayloadAction<User>) {
      state.isLoggedIn = true;
      state.logging = false;
      state.currentUser = action.payload;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.logging = false;
      state.messageLogin = action.payload;
    },
    logout(state) {},
    logoutSuccess(state) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      state.messageLogin = '';
    },
    setDefaultMessage(state) {
      state.messageLogin = '';
    },
  },
});
//Action
export const authActions = authSlice.actions;

//Selectors
export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn;
export const selectIsLogging = (state: any) => state.auth.logging;
export const selectLoginMessage = (state: any) => state.auth.messageLogin;
export const selectCurrentUser = (state: any) => state.auth.currentUser;

const authReducer = authSlice.reducer;

export default authReducer;
