/**
 * Update date: 25-05-2023
 * Screen 2042.1
 */
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useLocalStorage from 'hooks/useLocalStorage';
/**
 * create context
 */
const RegisterSchedule = React.lazy(() => import('./RegisterSchedule'));
const ListSchedule = React.lazy(() => import('./ListSchedule'));

const defaultParamsReceive = {
  vendor: '',
  takeoutDueStates: '',
  vendorType: '',
  zone: '',
};

const ScheduleContext = createContext({
  params: {},
  dataTakeoutDue: [] || null,
  setParams: () => {},
  onUpdateListTakeoutDue: () => {},
});

ScheduleContext.displayName = 'ScheduleContext';

export const useSchedule = () => {
  const context = useContext(ScheduleContext);
  return context;
};

const RegistrationSchedule = () => {
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    ...defaultParamsReceive,
    dateFrom: new Date(),
    dateTo: new Date(),
    formStore: {
      vendor: [],
      takeoutDueStates: [],
      vendorType: [],
      zone: [],
    },
  });
  const [dataLocalMst, setDataLocalMst] = useLocalStorage(
    '2042.1_Mst_dataList',
    {},
  );
  const [dataTakeoutDue, setDataTakeoutDue] = useState<any>([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [
    { data: resTakeoutDue, loading: loadingTakeoutDue },
    refetchTakeoutDue,
  ] = useAxios(
    {
      url: '/warehouse/takeout-due',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  const store: any = new ArrayStore({
    data: dataTakeoutDue,
    key: 'takeoutDueId',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    setParams(preState => ({
      ...preState,
      formStore: {
        ...preState.formStore,
        vendorType: dataLocalMst?.vendorType || [],
        zone: dataLocalMst?.zone || [],
      },
    }));
  }, [dataLocalMst]);

  useEffect(() => {
    if (resTakeoutDue?.data) {
      setDataTakeoutDue(resTakeoutDue?.data);
    }
  }, [resTakeoutDue]);

  const onUpdateListTakeoutDue = (data, type) => {
    if (type === 'add') {
      setDataTakeoutDue(preState => {
        preState?.unshift(data);
        return preState;
      });
      dataGridRef?.current?.instance?.refresh();
    } else {
      store
        .update(data?.takeoutDueId, data)
        .done(() => {})
        .fail(() => {});
    }
  };

  const value: any = {
    store,
    setModeView,
    modeView,
    params,
    setParams,
    dataTakeoutDue,
    refetchTakeoutDue,
    onUpdateListTakeoutDue,
    setDataLocalMst,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListSchedule ref={dataGridRef} />;
      case 'create':
      case 'edit':
      case 'view':
        return <RegisterSchedule />;
      default:
        return <NotFoundPage />;
    }
  };

  const loadingAll = loadingTakeoutDue;

  return (
    <ScheduleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ScheduleContext.Provider>
  );
};

export default RegistrationSchedule;
