import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));

/**
 * Create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2087_C_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Quarterly Closing Net Income Inquiry
 *
 * @return {*}
 */
const M2087_C = () => {
  // Get multi language
  const { t }: any = useDictionary({ programId: '2087.C' });
  const [data, setData] = useState({});
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      categoryIds: [],
    },
    categoryIds: '',
  });

  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  // Values share with child component
  const value: any = {
    t,
    data,
    setData,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2087_C;
