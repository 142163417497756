import { CURRENT_USER } from 'constant';
import { useState } from 'react';

export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const userName =
        JSON.parse(localStorage.getItem(CURRENT_USER) || '{}')?.name || '';
      const newKey = userName ? `${userName}_${key}` : key;
      const item = window.localStorage.getItem(newKey);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      const userName =
        JSON.parse(localStorage.getItem(CURRENT_USER) || '{}')?.name || '';
      const newKey = userName ? `${userName}_${key}` : key;
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(newKey, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  const getAgainData = () => {
    if (typeof window === 'undefined') return;
    try {
      // Get from local storage by key
      const userName =
        JSON.parse(localStorage.getItem(CURRENT_USER) || '{}')?.name || '';
      const newKey = userName ? `${userName}_${key}` : key;
      const item = window.localStorage.getItem(newKey);
      // Parse stored json or if none return initialValue
      return setStoredValue(item ? JSON.parse(item) : initialValue);
    } catch (error) {
      // If error also return initialValue
      return;
    }
  };
  return [storedValue, setValue, getAgainData];
}
