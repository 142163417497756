/**
 * Update date: 15-05-2023
 * Screen 1043.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import useFormat from 'hooks/useFormat';
import 'jspdf-autotable';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { notification } from 'utils/notification';
import FormSearch from './FormSearch';
import FormZone from './FormZone';
import PopupFileUpload from './PopupFileUpload';
import ViewLocation from './ViewLocation';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import { iconExcel } from 'images';

const defaultParams = {
  zoneNm: '',
  zoneUseTf: [],
};
const fileName = 'SMDC_1043.3 Center Zone';
/**
 * create context
 */

export const CenterZoneContext = createContext({
  listLocation: [],
  setListLocation: () => {},
  onCloseFormLocation: () => {},
});

export const useCenterZone = () => {
  const context = useContext(CenterZoneContext);
  if (!context) throw new Error('Center Zone Context Error');
  return context;
};

export default function CenterZone() {
  const { themePro }: any = useApp();
  const [showLocation, setShowLocation] = useState(false);
  const [showZoneInfo, setShowZoneInfo] = useState(false);
  const [zoneId, setZoneId] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [isOpenModalImport, setIsOpenModalImport] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [listLocation, setListLocation] = useState([]);

  const dataRef: any = useRef(null);
  const selectedKeys = dataRef?.current?.instance?.option().selectedRowKeys;
  const { IntegerFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '1043.3',
  });

  const [{ data, loading }, refetch] = useAxios(
    {
      url: '/core/zone-info',
      method: 'POST',
      data: { ...params },
    },
    { manual: true, useCache: false },
  );

  const [{ data: dataDelete, loading: loadingDelete }, executeDeleteZone] =
    useAxios(
      {
        url: '/core/zone-info',
        method: 'DELETE',
      },
      { manual: true },
    );

  const [{ loading: loadingUpload }, executeUpload] = useAxios(
    {
      url: '/core/zone-info/import-excel',
      method: 'POST',
    },
    { manual: true },
  );
  const [
    { data: dataDownload, loading: loadingDownload, error: errorDownload },
    executeDownload,
  ] = useAxios(
    {
      url: '/core/zone-info/download-file',
      responseType: 'blob',
    },
    { manual: true },
  );

  const store: any = new ArrayStore({
    data: data?.data,
    key: 'zoneId',
  });
  const storeLocation: any = new ArrayStore({
    data: listLocation,
    key: 'ID',
  });

  /**
   * on upload
   *
   * @param {*} file
   * @return {*}
   */
  const onUpload = async file => {
    if (selectedKeys?.length !== 1) {
      return notification({
        message: t('Please select one Zone!'),
        type: 'warning',
      });
    }
    if (!file || file?.size == 0) {
      return notification({
        message: t('Please upload file'),
        type: 'warning',
      });
    }
    const formData = new FormData();
    formData.append('file', file[0]);
    const res = await executeUpload({
      data: formData,
      params: {
        zoneId: selectedKeys[0],
      },
    });
    notification({ res });
    if (res.data.status == 201) {
      setIsOpenModalImport(false);
      refetch();
    } else if (res.data.status == 426) {
      const binary_string = window.atob(res.data.data);
      const len = binary_string.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      const byteArray = new Uint8Array(bytes.buffer);
      const downloadLink = window.document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: 'application/octet-stream' }),
      );
      downloadLink.download = '1043.3-CenterZoneTemplate-Fail.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  /**
   * dowload file success
   *
   * @param {*} data
   */
  const downloadFileSuccess = (data: any) => {
    const filename = 'CenterZoneTemplate.xlsx';
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
  };

  /**
   * download file error
   *
   */
  const downloadFileError = () => {
    notification({
      message: t('Can not download file'),
      type: 'error',
    });
  };

  /**
   * on download
   *
   */
  const handleDownload = () => {
    executeDownload();
  };

  /**
   * render location
   *
   * @param {*} e
   */
  const renderLocation = e => (
    <div
      className="column-hover-cursor cell-link"
      onClick={() => {
        const zoneId = e?.data?.zoneId;
        setShowLocation(true);
        setZoneId(zoneId);
      }}
    >
      {t('View')}
    </div>
  );

  /**
   * on edit zone
   *
   * @param {*} data
   * @return {*}
   */
  const handleEditZone = (data: any) => {
    if (data.columnIndex !== 3) return;
    const zoneId = data?.data?.zoneId;
    setZoneId(zoneId);
    setShowZoneInfo(true);
  };

  /**
   * on confirm
   *
   */
  const handleConfirm = () => {
    executeDeleteZone({
      data: selectedKeys,
    });
  };

  /**
   * on close view location
   *
   */
  const closeViewLocation = () => {
    setShowLocation(false);
    setZoneId(null);
  };

  /**
   * handle close zone info
   *
   */
  const handleCloseZoneInfo = () => {
    setShowZoneInfo(false);
    setListLocation([]);
    setZoneId(null);
  };

  const columns: any = [
    {
      dataField: 'zoneId',
      caption: t('ID'),
      fixed: true,
      alignment: 'left',
    },
    {
      dataField: 'zoneCd',
      caption: t('Zone Code'),
      alignment: 'left',
    },
    {
      dataField: 'zoneNm',
      caption: t('Zone Name'),
      alignment: 'left',
      allowSearch: true,
      cssClass: 'column-data',
    },
    {
      dataField: 'zoneStockInterlockTf',
      caption: t('Stockinterlock'),
      alignment: 'center',
      allowSearch: true,
      editorType: 'dxSwitch',
    },
    {
      dataField: 'zoneUseTf',
      caption: t('Use'),
      alignment: 'center',
      editorType: 'dxSwitch',
    },
    {
      dataField: 'zoneOrder',
      caption: t('Sort Order'),
      dataType: 'number',
      format: IntegerFormat,
    },
    {
      dataField: 'zoneNt',
      caption: t('Note'),
      alignment: 'left',
      allowSearch: true,
    },
    {
      caption: t('Location'),
      alignment: themePro ? 'left' : 'center',
      cellRender: renderLocation,
    },
  ];

  /**
   * on export excel
   */
  const onExportExcel = useCallback(() => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const dataGrid = dataRef?.current.instance;
    exportDataGrid({
      component: dataGrid,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${fileName}.xlsx`,
        );
      });
    });
  }, []);

  const deselectAll = () => {
    dataRef?.current?.instance?.deselectAll();
  };
  /**
   * refetch delete
   */
  useEffect(() => {
    if (dataDelete && !loadingDelete) {
      notification({
        res: dataDelete,
      });
      setShowConfirm(false);
      if (dataDelete.status === '200') {
        selectedKeys.forEach(key => {
          store.remove(key).fail(() => {});
        });
        deselectAll();
      }
    }
  }, [dataDelete, loadingDelete]);
  /**
   * refetch download
   */
  useEffect(() => {
    if (dataDownload && !loadingDownload) {
      downloadFileSuccess(dataDownload);
    } else if (errorDownload) {
      downloadFileError();
    }
  }, [dataDownload, loadingDownload, errorDownload]);

  const value: any = {
    t,
    storeLocation,
    setListLocation,
    refresh: refetch,
  };

  return (
    <CenterZoneContext.Provider value={value}>
      <LoadPanel visible={loading || loadingDelete || loadingUpload} />
      <FormSearch
        params={params}
        setParams={setParams}
        refetch={refetch}
        t={t}
      />
      <DataGridFull
        dataSource={store}
        columns={columns}
        ref={dataRef}
        storageKey="1043.3"
        options={{
          onCellClick: handleEditZone,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                locateInMenu: 'auto',
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: themePro ? iconExcel : 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => onExportExcel(),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: iconExcel,
                  text: t('Excel Location Import'),
                  onClick: () => setIsOpenModalImport(true),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Create New'),
                  onClick: () => setShowZoneInfo(true),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  onClick: () => setShowConfirm(true),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
          },
        }}
      />
      {showZoneInfo && (
        <FormZone id={zoneId} handleClose={handleCloseZoneInfo} />
      )}
      {showLocation && (
        <ViewLocation id={zoneId} closeView={closeViewLocation} />
      )}
      {isOpenModalImport && (
        <PopupFileUpload
          visible={isOpenModalImport}
          onDownLoad={handleDownload}
          onHiding={() => setIsOpenModalImport(false)}
          onOk={onUpload}
        />
      )}
      {showConfirm && (
        <PopupConfirmDelete
          visible={showConfirm}
          content={`${t('Do you want to delete {0} items?')} ${t(
            'selected zone name and the relevant locations?',
          )}`.replace('{0}', selectedKeys?.length)}
          onOk={handleConfirm}
          onHiding={() => setShowConfirm(false)}
        />
      )}
    </CenterZoneContext.Provider>
  );
}
