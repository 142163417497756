/**
 * Update date: 05-06-2023
 * Screen 2054.A
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const InventoryDueDateRegistrationContext = createContext({});

InventoryDueDateRegistrationContext.displayName =
  'InventoryDueDateRegistrationContext';

export const useInventoryDueDateRegistration = () => {
  return useContext(InventoryDueDateRegistrationContext);
};

export default function InventoryDueDateRegistration() {
  const [data, setData] = useState([]);
  const [planSelected, setPlanSelected] = useState({});
  const [locationManage, setLocationManage] = useState('0');

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  const value: any = {
    data,
    setData,
    refetchData,
    locationManage,
    setLocationManage,
    planSelected,
    setPlanSelected,
  };

  return (
    <InventoryDueDateRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        <List />
      </React.Suspense>
    </InventoryDueDateRegistrationContext.Provider>
  );
}
