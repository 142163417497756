/**
 * Update date: 06-06-2023
 * Screen 2064.8
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { useRef } from 'react';
import { useEmptyBottle } from '..';

function EmptyBottleTable(props) {
  const { themePro }: any = useApp();
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useEmptyBottle();
  const { bottleData } = context;
  const { t }: any = useDictionary({
    programId: '2064.8',
  });

  const columns: any = [
    {
      caption: t('Take Out'),
      alignment: 'center',
      items: [
        {
          dataField: 'bill',
          caption: t('Bill ID'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'date',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorTpNm',
          caption: t('Vendor Type'),
        },
        {
          dataField: 'vendorNm',
          caption: t('Vendor'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'statusNm',
          caption: t('Status'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        // {
        //   dataField: '', //empty
        //   caption: t('Send'),
        //   alignment: 'center',
        //   showWhenGrouped: true,
        // },
      ],
    },
    {
      caption: t('Container'),
      alignment: 'center',
      columns: [
        {
          dataField: 'containerCd',
          caption: t('Container code'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'containerNm',
          caption: t('Container Name'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'containerTp',
          caption: t('Container Type'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'containerKind',
          caption: t('Container Kind'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      columns: [
        {
          dataField: 'unitPrice',
          caption: t('Unit Price'),
          dataType: 'number',
          alignment: 'right',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'returnBackRate',
          caption: t('Export Price'),
          dataType: 'number',
          alignment: 'right',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'returnBackQty',
          caption: t('Qty'),
          dataType: 'number',
          alignment: 'right',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'returnBackAmount',
          caption: t('Sum Amount'),
          dataType: 'number',
          alignment: 'right',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          caption: t('Container Amount'),
          dataField: 'containerAmount',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          caption: t('Commission Amount'),
          dataField: 'commissionAmount',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          caption: t('Commission Supply'),
          dataField: 'commissionSupply',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          caption: t('Commission VAT'),
          dataField: 'commissionVat',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      dataField: 'returnBackId',
      caption: t('Return Back ID'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerId',
      caption: t('Container ID'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerDateTime',
      caption: t('Register Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerUserName',
      caption: t('Register User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerProgramId',
      caption: t('Register Program ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyDateTime',
      caption: t('Modify Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyUserName',
      caption: t('Modify User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyProgramId',
      caption: t('Modify Program ID'),
      showWhenGrouped: true,
      visible: false,
    },
  ];
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName =
    'SMDC_2064.8 Empty Bottle Carry Out Inquiry_' + moment().format('YYYYMMDD');

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={bottleData}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !bottleData.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'returnBackQty',
                column: 'returnBackQty',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'returnBackAmount',
                column: 'returnBackAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'containerAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'commissionAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'commissionSupply',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'commissionVat',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'returnBackQty',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'returnBackAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'containerAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'commissionAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'commissionSupply',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'commissionVat',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
    </>
  );
}

export default EmptyBottleTable;
