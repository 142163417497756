/**
 * Update date: 09-06-2023
 * Menu sound component
 */
import { useApp } from 'app';
import { Button, Form, Popover } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { useState } from 'react';

export default function MenuSound() {
  const appContext: any = useApp();
  const { soundTypeState, setSoundType } = appContext || {};
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useDictionary({});

  const [formData] = useState<any>({
    soundType: soundTypeState,
  });

  /**
   * on hiding menu
   *
   */
  const onHidingMenu = () => {
    setSoundType(formData?.soundType);
    setShowMenu(false);
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    if (e?.dataField == 'soundType') {
      setSoundType(e?.value);
      setShowMenu(false);
    }
  };

  /**
   * menu content
   *
   * @return {*}
   */
  const menuContent = () => {
    return (
      <>
        <h4
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          {t('Menu Sound')}
        </h4>
        <Form
          formData={formData}
          onFieldDataChanged={onFieldDataChanged}
          items={[
            {
              dataField: 'soundType',
              label: {
                text: t('Type'),
              },
              editorType: 'dxSelectBox',
              editorOptions: {
                items: [
                  { label: t('Male A'), value: 'male_a' },
                  { label: t('Male B'), value: 'male_b' },
                  { label: t('Female A'), value: 'female_a' },
                  { label: t('Female B'), value: 'female_b' },
                ],
                valueExpr: 'value',
                displayExpr: 'label',
                placeholder: '',
              },
            },
          ]}
          labelLocation="left"
          showColonAfterLabel={false}
        />
      </>
    );
  };
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '75px',
        right: '28px',
        zIndex: 2,
      }}
    >
      <Button
        icon="music"
        disabled={showMenu}
        onClick={() => setShowMenu(true)}
        id="menuSound"
      />
      {showMenu && (
        <Popover
          target="#menuSound"
          position="top"
          width={300}
          visible={showMenu}
          onHiding={onHidingMenu}
          shading={true}
        >
          {menuContent()}
        </Popover>
      )}
    </div>
  );
}
