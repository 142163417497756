/**
 * Update date: 2023-10-20
 * Screen 1047.1
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import { UPLOAD_EXCEL_PROGRAMCD } from 'constant/dataConstant';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';
import { dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const DetailData = React.lazy(() => import('./Detail'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M1047_1_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M1047_1 = () => {
  const { t }: any = useDictionary({ programId: '1047.1' });
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    status: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'import_excel_id',
  });

  /**
   * on submit
   *
   * @return {*}
   */
  const onSearchUpload = async () => {
    const requestData = {
      fromDate: dateFormatStr(formSearchData?.fromDate),
      toDate: dateFormatStr(formSearchData?.endDate),
      status: formSearchData?.status,
      programCd: UPLOAD_EXCEL_PROGRAMCD['1047_1'],
    };
    const res = await refetchData({
      url: '/core/verify-import-excel',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const dataMapping = (res?.data?.data || []).map(o => {
        let erro_y_n = '';
        if (o?.status != '0') {
          erro_y_n = !o?.error_count
            ? t('No')
            : `${t('Yes')}(${o?.error_count}/${
                o?.error_count + o?.success_count
              })`;
        }
        return {
          ...o,
          erro_y_n,
          status_nm:
            o?.status === '2'
              ? t('Save')
              : o?.status === '1'
              ? t('Verify')
              : t('Upload'),
        };
      });
      setData(dataMapping);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    store,
    setData,
    modeView,
    setModeView,
    formSearchData,
    setFormSearchData,
    refetchData,
    onSearchUpload,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'detail':
        return <DetailData />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M1047_1;
