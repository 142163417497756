/**
 * Update date: 14-06-2023
 * Popup add container component
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView, TextBox } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';

interface IPopupAddContainer {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit?: void | any;
  url?: string;
  isConverPrice?: boolean;
  method?: any;
  dataBody?: {
    storeId?: number;
    salesStartDate?: string | null;
    salesEndDate?: string | null;
  };
  columns?: any;
}

function PopupAddContainer({
  visible,
  onHiding,
  text,
  onSubmit,
  url,
  dataBody = {},
  method,
  isConverPrice = false,
  columns,
}: IPopupAddContainer) {
  const { themePro }: any = useApp();
  const [containerName, setContainerName] = useState(text);
  const dataRef: any = useRef(null);
  const { t } = useDictionary({});
  const { DoubleFormat } = useFormat();

  const [{ data: resContainerName, loading }, fetchContainerByName] = useAxios(
    {
      url: url ? url : '/warehouse/receive/container-true',
      method: method || 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on search
   *
   */
  const onSearch = () => {
    fetchContainerByName({
      params: { ...dataBody, containerName },
    });
  };

  /**
   * on submit
   *
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    onSubmit(selectedRowKeys);
  };

  const loadingAll = loading;

  const dataSource = resContainerName?.data?.map((o: any) => ({
    ...o,
    productCode: o?.containerCode,
    productName: o?.containerName,
    purchaseUnitPrice: isConverPrice ? o.containerUnitPrice : 0,
    supplyUnitPrice: isConverPrice ? o.containerUnitPrice : 0,
    containerUnitPrice: isConverPrice ? 0 : o.containerUnitPrice,
    optionContainerUnitPrice: isConverPrice ? 0 : o.containerUnitPrice,
    bottleUnitPrice: 0,
    quantityPerPack: o?.quantityPerPack || 1,
    receiveQuantity: o?.receiveQuantity || 0,
    option: '*',
    optionName: '*',
    latestPurchaseUnitPrice: o?.latestPurchaseUnitPrice,
  }));

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Container')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }
            }
          >
            <TextBox
              width={themePro ? 400 : '40vw'}
              defaultValue={containerName}
              onValueChanged={e => setContainerName(e?.value)}
              onEnterKey={onSearch}
              style={{ marginRight: 10 }}
            />
            <Button icon="search" text={t('Search')} onClick={onSearch} />
          </div>
          <DataGridFull
            ref={dataRef}
            dataSource={dataSource}
            columns={
              columns || [
                {
                  dataField: 'containerId',
                  caption: t('ID'),
                  alignment: 'left',
                },
                {
                  dataField: 'productTypeName',
                  caption: t('Product Type'),
                  allowSearch: false,
                },
                {
                  dataField: 'containerCode',
                  caption: t('Container Code'),
                },
                {
                  dataField: 'containerName',
                  caption: t('Container'),
                },
                {
                  dataField: 'containerTypeName',
                  caption: t('Container Type'),
                },
                {
                  dataField: 'containerKindName',
                  caption: t('Container Kind'),
                },
                {
                  dataField: 'capacity',
                  caption: t('Capacity'),
                },
                {
                  dataField: 'commonContainerCode',
                  caption: t('Common Container Code'),
                },
                {
                  dataField: 'containerUnitPrice',
                  caption: t('Unit Price'),
                  format: DoubleFormat,
                  dataType: 'number',
                },
                {
                  dataField: 'latestPurchaseUnitPrice',
                  caption: t('Final Unit Price'),
                  format: DoubleFormat,
                  dataType: 'number',
                },
              ]
            }
            options={{
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode: 'single',
              },
              height: themePro
                ? 'calc(100vh - 200px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Search for the container')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={themePro ? '80vw' : isMobile ? '96%' : '60vw'}
    />
  );
}

export default PopupAddContainer;
