/**
 * Update date: 26-05-2023
 * Screen 2043.6
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useModuleContext } from '..';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { useApp } from 'app';

const FormDetailInfo = React.lazy(() => import('./FormDetailInfo'));
const TableContent = React.lazy(() => import('./TableContent'));
/**
 * create context
 */
const FormDetailContext = createContext({});
export const useFormDetail = () => {
  return useContext(FormDetailContext);
};

function FormDetail() {
  const { themePro }: any = useApp();
  const { t, modeView, storedReceivableCds }: any = useModuleContext();
  const orderId = modeView?.orderId;

  const [listProduct, setListProduct] = useState<any>([]);

  const refFormInfo: any = useRef(null);
  const refTableContent: any = useRef(null);
  const [
    { data: resApiGetOrderDetail, loading: loadingGetDetail },
    apiGetOrderDetail,
  ] = useAxios(
    {
      url: `/ecommerce/orders/${orderId}`,
      method: 'GET',
      params: storedReceivableCds?.length
        ? {
            storedReceivableCds: storedReceivableCds.reduce(
              (f, s) => `${f},${s}`,
            ),
          }
        : {},
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const value: any = {
    listProduct,
    orderDetail: resApiGetOrderDetail?.data,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiGetOrderDetail?.data) {
      const data = resApiGetOrderDetail?.data?.products?.map((o, i) => ({
        ...o,
        ID: i + 1,
      }));
      setListProduct(data);
    }
  }, [resApiGetOrderDetail]);

  useEffect(() => {
    if (orderId) {
      apiGetOrderDetail();
    }
  }, [orderId]);

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingGetDetail} />
      {themePro ? (
        <BreadCrumbPremium
          title={t('Detail')}
          onClose={() => refTableContent?.current?.onClose()}
        />
      ) : (
        <h2>{t('Detail')}</h2>
      )}
      <FormDetailContext.Provider value={value}>
        <FormDetailInfo ref={refFormInfo} />
        <br />
        <TableContent ref={refTableContent} />
      </FormDetailContext.Provider>
    </React.Suspense>
  );
}

export default FormDetail;
