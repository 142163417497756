/**
 * Update date: 12-05-2023
 * Screen 1042.4
 */

import React, { useState, useRef, useEffect } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import { Switch } from 'devextreme-react';
import { notification } from 'utils/notification';
import PopupRegisteringCommonCode from './RegisteringCommonCode';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import LoadPanel from 'app/components/LoadPanel';
import useFormat from 'hooks/useFormat';

interface ITableCommonCode {
  codeType?: string;
}

function TableCommonCode({ codeType }: ITableCommonCode) {
  const [params] = useState({
    codeType: '',
    code: '',
    name: '',
    note: '',
    use: false,
    system: false,
    orders: 0,
  });
  const [commonData, setCommonData]: any = useState(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [visiblePopupAdd, setVisiblePopupAdd] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isUpdateCnt, setIsUpdateCnt] = useState<boolean>(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [keepInput, setKeepInput] = useState(false);
  const dataGridRef: any = useRef(null);
  const selectedKeys = dataGridRef?.current?.instance
    ?.option()
    .selectedRowKeys?.map(o => o);

  const { t } = useDictionary({
    programId: '1042.4',
  });

  const { QtyFormat } = useFormat();

  const [{ data: resCommonCode, loading }, fetchCommonCode] = useAxios(
    {
      url: '/core/common-code',
      method: 'POST',
    },
    {
      manual: true,
      autoCancel: true,
      useCache: false,
    },
  );

  const [{ data: comCdData, loading: comCdLoading }, putCommonCode] = useAxios(
    {
      url: '/core/common-code',
      method: 'PUT',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  const [{ data: dataDelete, loading: loadingDelete }, refetchDelete] =
    useAxios(
      {
        url: '/core/common-code',
        method: 'DELETE',
      },
      { manual: true },
    );

  const [{ data: dataUpOdr, loading: loadingUpOdr }, fetchUpdate] = useAxios(
    {
      url: '/core/common-code/order',
      method: 'PUT',
    },
    {
      manual: true,
      autoCancel: true,
      useCache: false,
    },
  );

  // create store
  const store = new ArrayStore({
    data: commonData,
    key: 'code',
  });

  /**
   * on add
   *
   */
  const onHandleAdd = () => {
    setIsCreating(true);
    setVisiblePopupAdd(true);
  };

  /**
   * on insert
   *
   * @param {*} data
   * @return {*}
   */
  const onHandleInsert = data => {
    deselectAll();
    let newCommonData: any = [];
    const l = [...commonData];
    const orderCommonData = [...commonData];
    const existCode = l
      ?.filter((o: any) => o?.status !== 'delete')
      ?.some((o: any) => o?.code == data?.code);
    if (existCode) {
      notification({
        message: t('Pleace check for code duplication.'),
        type: 'error',
      });
      return;
    }

    orderCommonData.sort((a, b) => (a?.orders > b?.orders ? 1 : -1));
    let nOrder = orderCommonData.pop()?.orders;

    if (!(nOrder > 0)) {
      nOrder = 0;
    }

    newCommonData = l;
    newCommonData.push({
      ...data,
      status: 'add',
      codeType: codeType,
      orders: nOrder + 1,
      system: codeType === 'LA' ? true : false,
    });
    setCommonData(newCommonData);
    setKeepInput(true);
  };

  const onHandleDelete = () => {
    setIsDeleting(true);
    setShowConfirmDelete(true);
  };

  /**
   * on delete
   *
   * @return {*}
   */
  const onDelete = () => {
    const d = dataGridRef?.current?.instance?.option().selectedRowKeys;
    let l = commonData;
    if (!d?.length) return;

    let newCommonData: any = l.map((o: any) => {
      const exist = d?.some(e => e == o?.code);
      if (exist) return { ...o, status: 'delete' };
      return o;
    });

    setCommonData(newCommonData);
    deselectAll();
    setShowConfirmDelete(false);
  };

  /**
   * on reorder
   *
   * @param {*} e
   */
  const onReorder = e => {
    const visibleRows = e.component.getVisibleRows();
    const d = [...visibleRows.map(o => o.data)] || []; // data from dataGrid
    let fromIndex = d.indexOf(e.itemData);
    let toIndex = d.indexOf(visibleRows[e.toIndex].data);

    let tempF = visibleRows[fromIndex].data.orders;
    let tempT = visibleRows[toIndex].data.orders;

    visibleRows[fromIndex].data.orders = tempT;
    visibleRows[toIndex].data.orders = tempF;

    setIsUpdate(true);
    setCommonData(d);
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    deselectAll();
    const data = resCommonCode?.data?.map((o, i) => ({
      ...o,
      status: 'normal',
    }));
    setCommonData(data);
    setShowPopupConfirm(false);
  };

  /**
   * on keep input
   *
   */
  const onKeepInput = () => {
    setKeepInput(false);
    setVisiblePopupAdd(true);
  };

  /**
   * on no keep input
   *
   */
  const onNoKeepInput = () => {
    setKeepInput(false);
    setVisiblePopupAdd(false);
  };

  /**
   * on save confirm
   *
   */
  const onHandleSave = () => {
    if (dataGridRef?.current?.instance?.hasEditData?.()) {
      dataGridRef?.current?.instance?.saveEditData?.().then(() => {
        if (!dataGridRef?.current?.instance?.hasEditData?.()) {
          setShowConfirm(true);
          setMessage(t('Confirm to save the modificaiton(s)? inside'));
        }
      });
    } else {
      setShowConfirm(true);
      setMessage(t('Confirm to save the modificaiton(s)? outside'));
    }
  };

  /**
   * on save
   *
   */
  const onSave = () => {
    const d =
      dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
    if (isCreating || isUpdateCnt) {
      const addL = [...d]
        ?.filter(o => o?.status === 'add' || o?.status === 'edit')
        ?.map((o: any) => {
          delete o?.status;
          delete o?.cdDocumentRequests;
          delete o?.cdDocumentResponses;
          return o;
        });
      putCommonCode({
        data: addL,
      });
    }

    if (isDeleting) {
      const deleteL = [...d]
        ?.filter(o => o?.status === 'delete')
        ?.map((o: any) => {
          delete o?.status;
          return o?.code;
        });
      refetchDelete({
        data: {
          codeType: codeType,
          code: deleteL,
        },
      });
    }

    if (isUpdate) {
      fetchUpdate({
        data: {
          ...params,
          codeType: codeType,
          commonCodeRequests: commonData,
        },
      });
      deselectAll();
    }
    setShowConfirm(false);
  };

  /**
   * notification
   */
  useEffect(() => {
    if (comCdData && !comCdLoading) {
      if (comCdData?.status == '201') {
        if (isCreating) {
          notification({
            res: comCdData,
            message: t('Create Successfully!'),
            type: 'success',
          });
          setIsCreating(false);
        }
        if (isUpdateCnt) {
          notification({
            res: comCdData,
            message: t('Updated Successfully!'),
            type: 'success',
          });
          setIsUpdateCnt(false);
        }
      } else {
        notification({
          res: comCdData,
          message: comCdData.message,
          type: 'error',
        });
      }
    }
  }, [comCdData, comCdLoading, fetchCommonCode]);

  /**
   * is delete
   */
  useEffect(() => {
    if (isDeleting) {
      if (dataDelete && !loadingDelete) {
        if (dataDelete.status === '200') {
          notification({
            res: dataDelete,
            message: dataDelete.message,
            type: 'success',
          });
          fetchCommonCode({
            data: {
              ...params,
              codeType: codeType,
            },
          });
          setIsDeleting(false);
        } else {
          notification({
            res: dataDelete,
            message: dataDelete.message,
            type: 'success',
          });
        }
      }
    }
  }, [dataDelete, loadingDelete, fetchCommonCode]);

  /**
   * is update
   */
  useEffect(() => {
    if (isUpdate) {
      if (dataUpOdr && !loadingUpOdr) {
        if (dataUpOdr.status === '201') {
          notification({
            res: dataUpOdr,
            message: dataUpOdr.message,
            type: 'success',
          });
          setIsUpdate(false);
        } else {
          notification({
            res: dataUpOdr,
            message: dataUpOdr.message,
            type: 'error',
          });
        }
      }
    }
  }, [dataUpOdr, loadingUpOdr]);

  /**
   * fetch data
   */
  useEffect(() => {
    if (codeType) {
      fetchCommonCode({
        data: { ...params, codeType },
      });
    }
  }, [codeType]);

  useEffect(() => {
    if (resCommonCode?.data) {
      let l = [...resCommonCode?.data].map((o: any) => ({
        ...o,
        status: 'normal',
      }));
      setCommonData(l);
    }
  }, [resCommonCode]);

  /**
   * deselect all
   *
   */
  const deselectAll = () => {
    dataGridRef?.current?.instance?.deselectAll();
  };

  const switchActiveState = cell => {
    let handleActiveState = e => handleActiveStates(cell, e);
    return (
      <Switch defaultValue={cell.value} onValueChanged={handleActiveState} />
    );
  };
  const handleActiveStates = (cell, e) => {
    cell.setValue(e.value ? true : false);
  };

  /**
   * on row updating
   *
   * @param {*} e
   */
  const onRowUpdating = e => {
    const d =
      dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
    const addL = [...d]
      ?.filter(o => o?.status === 'add')
      ?.map((o: any) => {
        return o;
      });

    let existNew = false;
    addL?.map((o: any) => {
      if (e?.oldData?.code == o?.code) {
        existNew = true;
      }
    });

    if (!existNew) {
      if (!e?.newData?.status) {
        store.update(e?.key, { status: 'edit' });
        setIsUpdateCnt(true);
      }
    }
  };

  /**
   * on row inserting
   *
   * @param {*} e
   */
  const onRowInserting = e => {
    if (!e?.newData?.status) {
      store.update(e?.key, { status: 'add' });
    }
  };

  const renderStatus = (record: any) => {
    switch (record?.value) {
      case 'add':
        return '+';
      case 'edit':
        return 'V';
      case 'delete':
        return '-';
      default:
        return null;
    }
  };

  const columns: any = [
    {
      dataField: 'status',
      caption: t('Status'),
      width: 100,
      fixed: true,
      alignment: 'center',
      allowEditing: false,
      cellRender: renderStatus,
    },
    {
      dataField: 'codeType',
      caption: t('Code Type'),
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'code',
      caption: t('Code'),
      fixed: true,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'name',
      caption: t('Code Name'),
      alignment: 'left',
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'orders',
      caption: t('Order'),
      alignment: 'right',
      allowEditing: true,
      format: QtyFormat,
      dataType: 'number',
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'use',
      editorType: 'dxSwitch',
      caption: t('Use'),
      showEditorAlways: true,
      editCellRender: switchActiveState,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'system',
      editorType: 'dxSwitch',
      caption: t('System'),
      allowEditing: false,
    },
    {
      dataField: 'note',
      caption: t('Note'),
      alignment: 'left',
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
  ];

  const loadingAll = loading;

  window['store'] = store;

  return (
    <div style={{ padding: 20 }}>
      <LoadPanel visible={loadingAll} />
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        columns={columns}
        storageKey="1042.4"
        options={{
          key: 'code',
          height: '70vh',
          onRowUpdating: onRowUpdating,
          onRowInserting: onRowInserting,
          onSelectionChanged: (e: any) => {
            let selectedRows = e.selectedRowKeys;
            selectedRows.forEach(o => {
              if (o?.receiveStatus == 1) {
                e?.component.deselectRows([o]);
              }
            });
          },
          onEditorPreparing: (e: any) => {
            if (e?.parentType == 'dataRow' && e?.type == 'selection') {
              if (e?.row?.data?.receiveStatus == 1) {
                e.editorOptions.disabled = true;
              }
            }
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('New'),
                  onClick: onHandleAdd,
                  disabled: !codeType,
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'save',
                  text: t('Save'),
                  onClick: onHandleSave,
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  onClick: onHandleDelete,
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'close',
                  text: t('Cancel'),
                  onClick: () => setShowPopupConfirm(true),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
          rowDragging: {
            allowReordering: true,
            onReorder: onReorder,
          },
          editing: {
            mode: 'cell',
            allowUpdating: true,
            startEditAction: 'click',
            selectTextOnEditStart: true,
            refreshMode: 'reshape',
          },
          keyExpr: 'code',
          focusedRowEnabled: false,
        }}
      />
      {visiblePopupAdd && (
        <PopupRegisteringCommonCode
          visible={visiblePopupAdd}
          onSubmit={onHandleInsert}
          onHiding={() => setVisiblePopupAdd(false)}
        />
      )}
      <PopupConfirmDelete
        visible={showConfirmDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onDelete}
        onHiding={() => setShowConfirmDelete(false)}
      />
      <PopupConfirm
        visible={showConfirm}
        content={message}
        des={message}
        onOk={onSave} // case reducer here
        onHiding={() => setShowConfirm(false)}
      />
      <PopupConfirm
        visible={showPopupConfirm}
        content={t('Do you want to cancel all changed?')}
        des={t('Do you want to cancel all changed?')}
        onOk={onCancel}
        onHiding={() => setShowPopupConfirm(false)}
      />
      <PopupConfirm
        visible={keepInput}
        content={t('Input next code?')}
        des={t('Input next code?')}
        onOk={onKeepInput}
        onHiding={onNoKeepInput}
      />
    </div>
  );
}
export default TableCommonCode;
