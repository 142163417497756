/**
 * Create date: 2023-06-12
 * Screen 2082.A
 */
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import DataTable from './TableView';
import FormSearch from './FormSearch';
// import { useProductType } from 'hooks/useProductType';
import { notification } from 'utils/notification';
import useDictionary from 'hooks/useDictionary';
import { dateFormatStr } from 'utils/format';

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2082_A_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};
/**
 * Sales Summary by Vendor ( vendor/product)
 *
 * @return {*}
 */
function M2082_A() {
  const { t }: any = useDictionary({ programId: '2082.A' });
  // const { productTpPermission, loadingProductTpPermission }: any =
  //   useProductType();
  const formRef: any = useRef(null);
  const [salesData, setSalesData] = useState<any>([]);
  const [tapName, setTapName] = useState<any>('');
  const [prdData, setPrdData] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      vendor: [],
      // productTypes: [],
    },
    vendor: '',
    // productTypes: '',
  });

  // Fetch api get data
  const [{ loading }, refetchData] = useAxios(
    { method: 'POST' },
    {
      manual: true,
      useCache: false,
    },
  );

  // get product type of user
  // useEffect(() => {
  //   if (!productTpPermission?.length) return;
  //   setFormData(preState => ({
  //     ...preState,
  //     dataList: {
  //       ...preState.dataList,
  //       productTypes: productTpPermission,
  //     },
  //     isReadOnlyProductTp: productTpPermission?.length > 0,
  //   }));
  // }, [productTpPermission]);

  /**
   * Search data product tab
   *
   * @param {*} data
   */
  const onSearchProduct = async data => {
    const res = await refetchData({
      url: 'ecommerce/sale-summary-by-vendor/product',
      data,
    });
    if (res?.data?.status === '200') {
      setPrdData(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  /**
   * Search data vendor tab
   *
   * @param {*} data
   */
  const onSearchVendor = async data => {
    const res = await refetchData({
      url: 'ecommerce/sale-summary-by-vendor',
      data,
    });
    if (res?.data?.status === '200') {
      setSalesData(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  /**
   * Search data
   *
   * @param {*} data
   */
  const onSearch = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    if (!formData?.fromDate || !formData?.endDate) return;
    const searchParams = {
      fromDate: dateFormatStr(formData?.fromDate),
      endDate: dateFormatStr(formData?.endDate),
      vendorIds: formData?.dataList?.vendor?.map(o => o.vendorId) || [],
      // productTypes: formData?.dataList?.productTypes?.map(o => o?.code),
    };
    if (tapName === t('Product')) {
      onSearchProduct(searchParams);
    } else {
      onSearchVendor(searchParams);
    }
  };

  // Set data to state
  useEffect(() => {
    if (!tapName) return;
    onSearch();
  }, [tapName]);

  // Values share with child component
  const value: any = {
    t,
    onSearch,
    formData,
    setFormData,
    salesData,
    prdData,
    tapName,
    setTapName,
  };

  return (
    <ModuleContext.Provider value={value}>
      <LoadPanel visible={loading} />
      <FormSearch ref={formRef} />
      <DataTable />
    </ModuleContext.Provider>
  );
}

export default M2082_A;
