/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Form, Popup, ScrollView } from 'devextreme-react';
import useLocalStorage from 'hooks/useLocalStorage';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { usePopupOrder } from '..';

interface IAddClaim {
  onOk: any;
  onClose: () => void;
  dataClaim: any | undefined;
}

function AddClaim({ onOk, onClose, dataClaim }: IAddClaim) {
  const { t, DateTimeFormat, formInformation, listClaimMethod }: any =
    usePopupOrder();
  const formRef: any = useRef(null);
  const [dataLocal] = useLocalStorage('myPagePreference', {});
  const [formClaim, setFormClaim] = useState({
    order_id: formInformation?.order_id,
    line_id: null,
    mall_order_id: formInformation?.mall_order_id || '',
    claim_dm: new Date(),
    claim_method: '0',
    claim_contents: '',
    result_dm: null,
    result_contents: '',
    status: '0',
    applicant_id: dataLocal?.userId || null,
    applicant_name: dataLocal?.name || '',
    management_id: null,
    management_name: '',
    comment: '',
  });

  const [{ loading: loadingClaim }, refetchSaveClaim] = useAxios(
    {
      url: `/ecommerce/order-popup/claim`,
      method: 'GET',
    },
    { useCache: false, manual: true },
  );

  useEffect(() => {
    const fetchData = async () => {
      if (dataClaim?.order_id) {
        const res: any = await refetchSaveClaim({
          params: {
            orderId: dataClaim?.order_id,
            lineId: dataClaim?.line_id,
          },
        });
        if (res?.data?.status === '200') {
          setFormClaim(res?.data?.data);
        }
      }
    };
    fetchData();
  }, []);

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%" useNative={true}>
        <LoadPanel visible={loadingClaim} />
        <div className="modal-popup-header">
          <span className="title-page">{t('Claim')}</span>
          <div>
            <Button
              stylingMode="contained"
              type="default"
              text={t('Save')}
              icon="check"
              style={{ marginRight: 5 }}
              onClick={() => {
                const dataForm = {
                  ...formClaim,
                  claim_dm: formClaim?.claim_dm
                    ? moment(formClaim?.claim_dm).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  result_dm: formClaim?.result_dm
                    ? moment(formClaim?.result_dm).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                };
                onOk(dataForm);
              }}
            />
            <Button
              stylingMode="contained"
              text={t('Cancel')}
              icon="close"
              onClick={onClose}
            />
          </div>
        </div>
        <Form
          labelLocation="top"
          showColonAfterLabel={false}
          formData={formClaim}
          ref={formRef}
          onFieldDataChanged={e => {
            if (e?.dataField === 'status') {
              if (e?.value === '0') {
                formRef?.current?.instance?.updateData('management_id', 0);
                formRef?.current?.instance?.updateData('management_name', '');
                if (formClaim?.line_id) {
                  formRef?.current?.instance?.updateData(
                    'result_dm',
                    new Date(),
                  );
                } else {
                  formRef?.current?.instance?.updateData('result_dm', null);
                }
              } else {
                formRef?.current?.instance?.updateData(
                  'management_id',
                  dataLocal?.userId || 0,
                );
                formRef?.current?.instance?.updateData(
                  'management_name',
                  dataLocal?.name || '',
                );
                formRef?.current?.instance?.updateData('result_dm', new Date());
              }
            }
          }}
          className="body-padding-white"
          items={[
            {
              caption: t('Claim Detail'),
              itemType: 'group',
              cssClass: 'group-caption-second',
              items: [
                {
                  label: { text: t('Date Time') },
                  dataField: 'claim_dm',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    type: 'datetime',
                    displayFormat: DateTimeFormat,
                  },
                },
                {
                  dataField: 'claim_method',
                  editorType: 'dxSelectBox',
                  label: {
                    text: t('Method'),
                  },
                  editorOptions: {
                    items: listClaimMethod,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                },
                {
                  dataField: 'claim_contents',
                  editorType: 'dxTextArea',
                  label: {
                    text: t('Request Content'),
                  },
                  editorOptions: {
                    height: 90,
                  },
                },
                {
                  label: { text: t('Result Date Time') },
                  dataField: 'result_dm',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    type: 'datetime',
                    displayFormat: DateTimeFormat,
                  },
                },
                {
                  dataField: 'result_contents',
                  editorType: 'dxTextArea',
                  label: {
                    text: t('Result Content'),
                  },
                  editorOptions: {
                    height: 90,
                  },
                },
                {
                  dataField: 'status',
                  editorType: 'dxSelectBox',
                  label: {
                    text: t('Status'),
                  },
                  editorOptions: {
                    items: [
                      { label: t('Waiting'), value: '0' },
                      { label: t('Complete'), value: '1' },
                    ],
                    defaultValue: '0',
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                },
                {
                  dataField: 'applicant_name',
                  editorType: 'dxTextBox',
                  label: {
                    text: t('Applicant'),
                  },
                  editorOptions: {
                    disabled: true,
                  },
                },
              ],
            },
            {
              caption: t('Management'),
              itemType: 'group',
              cssClass: 'group-caption-second',
              items: [
                {
                  dataField: 'management_name',
                  editorType: 'dxTextBox',
                  label: {
                    text: t('Manager'),
                  },
                  editorOptions: {
                    disabled: true,
                  },
                },
                {
                  dataField: 'comment',
                  editorType: 'dxTextArea',
                  label: {
                    text: t('Comment'),
                  },
                  editorOptions: {
                    height: 90,
                  },
                },
              ],
            },
          ]}
        />
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup popup-order-custom-claim"
      visible={true}
      //   onHiding={onHiding}
      contentRender={content}
      height={'100vh'}
      width={500}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default AddClaim;
