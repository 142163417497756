/**
 * Update date: 17-05-2023
 * Screen 2013.1
 */
import React, { useState, createContext, useContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import FormSearch from './FormSearch';
import List from './List';
import { useProductType } from 'hooks/useProductType';
import useDictionary from 'hooks/useDictionary';

/**
 * create context
 */
const ProductForwardingStandardPriceRegistrationContext = createContext({
  params: {},
  productForwardingData: [],
  setParams: () => {},
});

ProductForwardingStandardPriceRegistrationContext.displayName =
  'ProductForwardingStandardPriceRegistrationContext';

export const useProductForwardingStandardPrice = () => {
  const context = useContext(ProductForwardingStandardPriceRegistrationContext);
  return context;
};

export default function ProductForwardingStandardPrice() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({ programId: '2013.1' });
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      vendor: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      product: [],
      productGroup: [],
      productType: [],
    },
    fromDate: new Date(),
    toDate: new Date(),
    vendor: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    productType: '',
  });
  const [productForwardingData, setProductForwardingData] = useState([]);

  const dataStore: any = new ArrayStore({
    data: productForwardingData,
    key: ['mallCd', 'productId', 'optionId'],
  });

  const [{ data, loading: dataLoading }, refetchData] = useAxios(
    {
      method: 'POST',
      url: '/product/pfspr',
    },
    { manual: true, autoCancel: true },
  );
  const [{ loading: loadSaving }, refetchSaving] = useAxios(
    {
      method: 'PUT',
      url: '/product/pfspr',
    },
    { manual: true, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (data && !dataLoading) {
      setProductForwardingData(data?.data || []);
    }
  }, [data, dataLoading]);
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    t,
    formSearchData,
    setFormSearchData,
    dataStore,
    setProductForwardingData,
    refetchData,
    refetchSaving,
  };

  return (
    <ProductForwardingStandardPriceRegistrationContext.Provider value={value}>
      <>
        <LoadPanel
          visible={dataLoading || loadSaving || loadingProductTpPermission}
        />
        <FormSearch />
        <List />
      </>
    </ProductForwardingStandardPriceRegistrationContext.Provider>
  );
}
