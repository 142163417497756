/**
 * Update date: 18-05-2023
 * Screen 2013.3
 */
import React, { useState, useRef, useEffect } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { useForwardPrice } from '..';
import useDictionary from 'hooks/useDictionary';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { useProductType } from 'hooks/useProductType';
import { notification } from 'utils/notification';

const FormSearch = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const context: any = useForwardPrice();
  const { setDataSource } = context;
  const { t }: any = useDictionary({ programId: '2013.3' });
  const formRef: any = useRef(null);
  const [typeCommon, setTypeCommon] = useState('');
  const [formStore, setFormStore] = useState<any>({
    lgCategory: [],
    mdCategory: [],
    smCategory: [],
    subCategory: [],
    product: [],
    productGroup: [],
    productType: [],
    maker: [],
    vender: [],
  });

  const [formData, setFormData] = useState<any>({
    lgCategory: '',
    mdCategory: '',
    smCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    productType: '',
    maker: '',
    vender: '',
  });

  const [{ loading: loadingData }, refetchData] = useAxios(
    {
      url: '/product/forward_price',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const data = {
      largeCategoryCodes: formStore.lgCategory.map((map: any) => map?.code),
      mediumCategoryCodes: formStore.mdCategory.map((map: any) => map?.code),
      smallCategoryCodes: formStore.smCategory.map((map: any) => map?.code),
      subCategoryCodes: formStore.subCategory.map((map: any) => map?.code),
      productIds: formStore.product.map((map: any) => map?.productId),
      productGroupIds: formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      vendorIds: formStore.vender.map((map: any) => map?.vendorId),
      productType: formStore.productType.map((map: any) => map?.code),
      makerCodes: formStore.maker.map((map: any) => map?.code),
    };
    const res = await refetchData({ data });
    if (res?.data?.status === '200') {
      setDataSource(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormStore(preState => ({
      ...preState,
      productType: productTpPermission,
    }));
    setFormData(preState => ({
      ...preState,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'MK':
        name = 'maker';
        break;
      case 'PT':
        name = 'productType';
        break;
      case 'VD':
        name = 'vender';
        break;
      default:
        break;
    }
    let newData: any =
      type === 'LC' ||
      type === 'MC' ||
      type === 'SC' ||
      type === 'UC' ||
      type === 'MK' ||
      type === 'PT'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.code !== data?.code,
          )
        : type === 'PD'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productId !== data?.productId,
          )
        : type === 'PG'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productGroupId !== data?.productGroupId,
          )
        : [...formStore?.[name]].filter(
            (filter: any) => filter?.vendorId !== data?.vendorId,
          );
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
  };

  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'PT':
        name = 'productType';
        break;
      case 'MK':
        name = 'maker';
        break;
      case 'VD':
        name = 'vender';
        break;
      default:
        break;
    }
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: [] }));
  };
  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'LC':
      case 'MC':
      case 'SC':
      case 'UC':
      case 'PT':
      case 'MK':
        return (
          <PopupSelectCommonCode
            visible={
              popupType === 'LC' ||
              popupType === 'MC' ||
              popupType === 'SC' ||
              popupType === 'UC' ||
              popupType === 'MK' ||
              popupType === 'PT'
            }
            text={
              popupType === 'LC'
                ? formData.lgCategory
                : popupType === 'MC'
                ? formData.mdCategory
                : popupType === 'SC'
                ? formData.smCategory
                : popupType === 'UC'
                ? formData.subCategory
                : popupType === 'MK'
                ? formData.maker
                : formData.productType
            }
            onHiding={() => {
              setTypeCommon('');
            }}
            codeType={popupType}
            mode="multiple"
            onSubmit={data => {
              if (popupType === 'LC') {
                setFormData({ ...formData, lgCategory: '' });
                setFormStore({ ...formStore, lgCategory: data });
              }
              if (popupType === 'MC') {
                setFormData({ ...formData, mdCategory: '' });
                setFormStore({ ...formStore, mdCategory: data });
              }
              if (popupType === 'SC') {
                setFormData({ ...formData, smCategory: '' });
                setFormStore({ ...formStore, smCategory: data });
              }
              if (popupType === 'UC') {
                setFormData({ ...formData, subCategory: '' });
                setFormStore({ ...formStore, subCategory: data });
              }
              if (popupType === 'MK') {
                setFormData({ ...formData, maker: '' });
                setFormStore({ ...formStore, maker: data });
              }
              if (popupType === 'PT') {
                setFormData({ ...formData, productType: '' });
                setFormStore({ ...formStore, productType: data });
              }
              setTypeCommon('');
            }}
          />
        );
      case 'PG':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'PG'}
            text={formData.productGroup}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, productGroup: '' });
              setFormStore({ ...formStore, productGroup: data });
              setTypeCommon('');
            }}
          />
        );
      case 'PD':
        return (
          <PopupSelectProduct
            visible={popupType === 'PD'}
            text={formData.product}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, product: '' });
              setFormStore({ ...formStore, product: data });
              setTypeCommon('');
            }}
          />
        );
      case 'VD':
        return (
          <PopupSelectVendor
            visible={popupType === 'VD'}
            text={formData.vender}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, vender: '' });
              setFormStore({ ...formStore, vender: data });
              setTypeCommon('');
            }}
          />
        );
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={2}
          items={[
            {
              label: { text: t('Product Type') },
              dataField: 'productType',
              editorType: 'dxTextBox',
              editorOptions: {
                readOnly: formData?.isReadOnlyProductTp,
                onEnterKey: () => {
                  setTypeCommon('PT');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productType?.[0]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.productType?.[0]),
                      visible: !!formStore?.productType?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productType?.[1]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.productType?.[1]),
                      visible: !!formStore?.productType?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productType?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productType?.length,
                      onClick: () => handleCleanAll('PT'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PT'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Maker') },
              dataField: 'maker',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('MK');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.maker?.[0]?.name,
                      onClick: () => handleRemove('MK', formStore?.maker?.[0]),
                      visible: !!formStore?.maker?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.maker?.[1]?.name,
                      onClick: () => handleRemove('MK', formStore?.maker?.[1]),
                      visible: !!formStore?.maker?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.maker?.length - 2} ${t('more')}`,
                      visible: formStore?.maker?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.maker?.length,
                      onClick: () => handleCleanAll('MK'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('MK'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('PD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[0]),
                      visible: !!formStore?.product?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[1]),
                      visible: !!formStore?.product?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => handleCleanAll('PD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PD'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Vendor') },
              dataField: 'vender',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('VD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.vender?.[0]?.vendorName,
                      onClick: () => handleRemove('VD', formStore?.vender?.[0]),
                      visible: !!formStore?.vender?.[0]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.vender?.[1]?.vendorName,
                      onClick: () => handleRemove('VD', formStore?.vender?.[1]),
                      visible: !!formStore?.vender?.[1]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.vender?.length - 2} ${t('more')}`,
                      visible: formStore?.vender?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.vender?.length,
                      onClick: () => handleCleanAll('VD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('VD'),
                    },
                  },
                ],
              },
            },
          ]}
        ></Form>
      </>
    );
  };
  /**
   * render advanced search
   *
   * @param {*} name
   * @return {*}
   */
  const AdvancedSearch = name => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'lgCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('LC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[0]),
                      visible: !!formStore?.lgCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[1]),
                      visible: !!formStore?.lgCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.lgCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.lgCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.lgCategory?.length,
                      onClick: () => handleCleanAll('LC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('LC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mdCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('MC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[0]),
                      visible: !!formStore?.mdCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[1]),
                      visible: !!formStore?.mdCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mdCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mdCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mdCategory?.length,
                      onClick: () => handleCleanAll('MC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('MC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('SC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[0]),
                      visible: !!formStore?.smCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[1]),
                      visible: !!formStore?.smCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smCategory?.length,
                      onClick: () => handleCleanAll('SC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('SC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('UC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[0]),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[1]),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => handleCleanAll('UC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('UC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('PG');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[0]),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[1]),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => handleCleanAll('PG'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PG'),
                    },
                  },
                ],
              },
            },
          ]}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      <LoadPanel visible={loadingData || loadingProductTpPermission} />
      {getPopupByType(typeCommon)}
    </div>
  );
};

export default FormSearch;
