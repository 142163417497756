import {
  ACCESS_TOKEN,
  CURRENT_USER,
  REFRESH_TOKEN,
  TIME_VERIFICATION_PAGE,
  USER_REGISTRATION,
} from 'constant';

interface UserRegister {
  email?: string;
  timeResendCode?: number;
}

const getLocalRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

const getLocalAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

const updateLocalAccessToken = (access_token: string) => {
  localStorage.setItem(ACCESS_TOKEN, access_token);
};

const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

const updateLocalUserRegistration = ({
  email,
  timeResendCode,
}: UserRegister) => {
  const userRegistration: string | null =
    localStorage.getItem(USER_REGISTRATION);

  const useEmail = email
    ? email
    : userRegistration
    ? JSON.parse(userRegistration).email
    : '';
  const expired = !userRegistration
    ? new Date().getTime() + parseInt(TIME_VERIFICATION_PAGE) * 600000
    : JSON.parse(userRegistration).expired;
  const timeRCode = timeResendCode
    ? timeResendCode
    : userRegistration
    ? JSON.parse(userRegistration).timeResendCode
    : 0;

  const data = {
    email: useEmail,
    expired,
    timeResendCode: timeRCode,
  };
  localStorage.setItem(USER_REGISTRATION, JSON.stringify(data));
};

const removeUserRegistration = () => {
  localStorage.removeItem(USER_REGISTRATION);
};

const getLocalCurrentUser = () => {
  const currentUser = localStorage.getItem(CURRENT_USER);
  if (currentUser === null) {
    return undefined;
  }
  return JSON.parse(currentUser);
};

const updateLocalCurrentUser = (user: any) => {
  localStorage.setItem(CURRENT_USER, JSON.stringify(user));
};

const removeLocalCurrentUser = () => {
  localStorage.removeItem(CURRENT_USER);
};

const tokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  removeToken,
  updateLocalUserRegistration,
  removeUserRegistration,
  getLocalCurrentUser,
  updateLocalCurrentUser,
  removeLocalCurrentUser,
};

export default tokenService;
