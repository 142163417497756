// Update date: 10-05-2023
// Screen 1042.1

import React, { useEffect, useRef, useState } from 'react';
import { CONSTANT } from 'constant';
import LoadPanel from 'app/components/LoadPanel';
import { Button, Form, Template } from 'devextreme-react';
import useAxios from 'axios-hooks';
import { notification } from 'utils/notification';
import useDictionary from 'hooks/useDictionary';
import PopupSelectPostCode from 'app/components/PopupCommon/PopupSelectPostCode';
import './style.scss';
import ImageUpload from 'app/components/Templates/ImageUpload';
import { useApp } from 'app';
import { restrictKeyboardPhone } from 'utils/common/restrict-keyboard-phone';
import ImageUploadPremium from 'app/components/Templates/ImageUploadPremium';

interface IFormData {
  amountPoint?: number;
  centerAddressBasic?: string;
  centerAddressDetail?: string;
  centerBusiness?: string;
  centerCode?: string;
  centerCorporateNumber?: string;
  centerCustomerFax?: string;
  centerIndustry?: string;
  centerManager?: string;
  centerName?: string;
  centerLogoUrl?: string;
  loginLogoUrl?: string;
  faviconUrl?: string;
  centerPostCode?: string;
  centerRepresentativeFax?: string;
  centerRepresentativeName?: string;
  centerRepresentativePhone?: string;
  companyName?: string;
  csOperatingTime?: string;
  defaultVat?: number;
  divisionType?: string;
  orderMail?: string;
  orderPhone?: string;
  privacyOfficer?: string;
  privacyOfficerContact?: string;
  privacyOfficerDepartment?: string;
  privacyOfficerEmail?: string;
  privacyOfficerPosition?: string;
  receiveOnlyEmail?: string;
  representativeEmail?: string;
  representativeMobile?: string;
  sendOnlyEmail?: string;
  smsReceiveNo?: string;
  representativeMobileCode?: string;
  centerRepresentativePhoneCode?: string;
  centerRepresentativeFaxCode?: string;
  orderPhoneCode?: string;
  centerCustomerFaxCode?: string;
  smsReceiveNoCode?: string;
}

const { inputPhoneRegExp, listPhone } = CONSTANT;

const initialFormData: IFormData = {
  amountPoint: 0,
  centerAddressBasic: '',
  centerAddressDetail: '',
  centerBusiness: '',
  centerCode: '',
  centerCorporateNumber: '',
  centerCustomerFax: '',
  centerIndustry: '',
  centerManager: '',
  centerName: '',
  centerLogoUrl: '',
  faviconUrl: '',
  loginLogoUrl: '',
  centerPostCode: '',
  centerRepresentativeFax: '',
  centerRepresentativeName: '',
  centerRepresentativePhone: '',
  companyName: '',
  csOperatingTime: '',
  defaultVat: 10,
  divisionType: '',
  orderMail: '',
  orderPhone: '',
  privacyOfficer: '',
  privacyOfficerContact: '',
  privacyOfficerDepartment: '',
  privacyOfficerEmail: '',
  privacyOfficerPosition: '',
  receiveOnlyEmail: '',
  representativeEmail: '',
  representativeMobile: '',
  sendOnlyEmail: '',
  smsReceiveNo: '',
  representativeMobileCode: listPhone[0].value,
  centerRepresentativePhoneCode: listPhone[0].value,
  centerRepresentativeFaxCode: listPhone[0].value,
  orderPhoneCode: listPhone[0].value,
  centerCustomerFaxCode: listPhone[0].value,
  smsReceiveNoCode: listPhone[0].value,
};

function CenterRegistration() {
  const { setAppLogo, themePro }: any = useApp();
  const [showModalPostCode, setShowModalPostCode] = useState(false);
  const [editCenterCode, setEditCenterCode] = useState(true);
  const [faviconOld, setFaviconOld] = useState('');
  const [formData, setFormData] = useState<any>({
    ...initialFormData,
  });

  const formRef = useRef<any>(null);
  const { t }: any = useDictionary({
    programId: '1042.1',
  });
  // on change post code
  const onChangePostCode = data => {
    formRef?.current?.instance?.updateData('centerPostCode', data?.zipNo);
    formRef?.current?.instance?.updateData(
      'centerAddressBasic',
      data?.roadAddrPart1,
    );
    formRef?.current?.instance?.updateData('centerAddressDetail', '');
    setShowModalPostCode(false);
  };
  // on cancel
  const onCancel = () => {
    setFormData({ ...initialFormData });
    setEditCenterCode(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const [{ data: getCenterInfoData, loading: loadingGetCenterInfo }] = useAxios(
    {
      url: '/core/center-info',
      method: 'GET',
    },
    {
      useCache: false,
      autoCancel: true,
    },
  );
  // get info data
  const handleCenterInfoData = (data: any) => {
    const handleData = data;
    const fieldPhoneNo = [
      'centerCustomerFax',
      'centerRepresentativeFax',
      'centerRepresentativePhone',
      'orderPhone',
      'representativeMobile',
      'smsReceiveNo',
    ];
    for (const [field, value] of Object.entries(handleData)) {
      if (!fieldPhoneNo.includes(field)) {
        handleData[field] = value;
        continue;
      }
      if (!value) continue;

      const phone: any = value;
      const phoneOrFaxFormat = phone.split(')');

      if (phoneOrFaxFormat.length > 1) {
        const zipCode = phoneOrFaxFormat[0].concat(')');
        const val = phoneOrFaxFormat[1];
        handleData[`${field}Code`] = zipCode;
        handleData[field] = val;
      }
    }
    setFormData({ ...handleData });
    setFaviconOld(handleData?.faviconUrl);
    setEditCenterCode(!data.centerCode);
  };

  useEffect(() => {
    if (
      getCenterInfoData?.status == '200' &&
      getCenterInfoData?.data &&
      !loadingGetCenterInfo
    ) {
      handleCenterInfoData(getCenterInfoData.data);
    }
  }, [getCenterInfoData, loadingGetCenterInfo]);

  const [{ loading: loadingPutCenterInfoData }, executeUpdateCenterInfo] =
    useAxios(
      {
        url: '/core/center-info',
        method: 'PUT',
      },
      {
        manual: true,
        useCache: false,
        autoCancel: true,
      },
    );
  // on submit
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;

    const dataReq: any = {
      ...formData,
      representativeMobile: formData.representativeMobile
        ? formData.representativeMobileCode + formData.representativeMobile
        : '',
      centerRepresentativePhone: formData.centerRepresentativePhone
        ? formData.centerRepresentativePhoneCode +
          formData.centerRepresentativePhone
        : '',
      orderPhone: formData.orderPhone
        ? formData.orderPhoneCode + formData.orderPhone
        : '',
      smsReceiveNo: formData.smsReceiveNo
        ? formData.smsReceiveNoCode + formData.smsReceiveNo
        : '',
      centerRepresentativeFax: formData.centerRepresentativeFax
        ? formData.centerRepresentativeFaxCode +
          formData.centerRepresentativeFax
        : '',
      centerCustomerFax: formData.centerCustomerFax
        ? formData.centerCustomerFaxCode + formData.centerCustomerFax
        : '',
      defaultVat:
        formData.defaultVat === '0' ? null : parseInt(formData.defaultVat),
    };
    delete dataReq.representativeMobileCode;
    delete dataReq.centerRepresentativePhoneCode;
    delete dataReq.orderPhoneCode;
    delete dataReq.smsReceiveNoCode;
    delete dataReq.centerRepresentativeFaxCode;
    delete dataReq.centerCustomerFaxCode;

    try {
      const res = await executeUpdateCenterInfo({
        data: { ...dataReq },
      });
      notification({ res });

      if (res?.data?.status === '201') {
        setAppLogo(res?.data?.data?.data?.centerLogoUrl);
        window.sessionStorage.setItem(
          'centerInfo',
          JSON.stringify(res?.data?.data?.data),
        );
        if (res?.data?.data?.data?.faviconUrl !== faviconOld) {
          window.location.reload();
        }
      }
    } catch (e) {
      notification({ type: 'error', message: 'Update Fail' });
    }
  };
  // on upload image
  const onUploadImage = (name, value) => {
    formRef?.current?.instance?.updateData(name, value);
  };

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingGetCenterInfo || loadingPutCenterInfoData} />
      {themePro && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="title-page">
            {t('Center Information Registration')}
          </div>
          <div>
            <Button
              text={t('Save')}
              type="default"
              icon="check"
              onClick={() => onSubmit()}
              style={{ marginRight: 10 }}
              width={100}
            />
            <Button
              text={t('Cancel')}
              icon="close"
              onClick={() => onCancel()}
              width={100}
            />
          </div>
        </div>
      )}
      <Form
        showColonAfterLabel={false}
        labelLocation="top"
        formData={formData}
        ref={formRef}
        className="center-registration-form"
        items={[
          {
            itemType: 'group',
            colCount: 3,
            caption: t('Basic Information'),
            cssClass: 'basic-information-group body-padding-white mb-10',
            items: [
              {
                dataField: 'centerCode',
                label: {
                  text: t('Center Code'),
                },
                editorType: 'dxTextBox',
                editorOptions: {
                  disabled: !editCenterCode,
                },
                validationRules: [
                  {
                    type: 'required',
                    trim: true,
                  },
                  {
                    type: 'stringLength',
                    max: 30,
                    trim: true,
                  },
                ],
              },
              {
                dataField: 'centerManager',
                label: {
                  text: t('Center Manager'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'required',
                  },
                  {
                    type: 'stringLength',
                    max: 100,
                    trim: true,
                  },
                ],
              },
              {
                dataField: 'centerName',
                label: {
                  text: t('Center Name'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'required',
                    trim: true,
                  },
                  {
                    type: 'stringLength',
                    max: 100,
                    trim: true,
                  },
                ],
              },
              {
                dataField: 'representativeEmail',
                label: {
                  text: t('Representative E-mail'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'email',
                  },
                ],
              },
              {
                itemType: 'group',
                cssClass: 'group-padding-small',
                colSpan: 6,
                colCount: 5,
                items: [
                  {
                    dataField: 'representativeMobileCode',
                    label: {
                      text: t('Representative Mobile No'),
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.representativeMobileCode
                        ? formData.representativeMobileCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                    cssClass: 'center-info-label center-info-select',
                  },
                  {
                    dataField: 'representativeMobile',
                    label: { visible: false },
                    editorType: 'dxTextBox',
                    colSpan: 4,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        trim: true,
                        max: 15,
                      },
                      {
                        type: 'pattern',
                        pattern: inputPhoneRegExp,
                      },
                    ],
                  },
                ],
              },
              { itemType: 'empty' },
              {
                label: {
                  text: t('Center Logo'),
                  alignment: themePro ? 'left' : 'center',
                },
                dataField: 'centerLogoUrl',
                template: 'ImageUpload',
              },
              {
                label: {
                  text: t('Login Image'),
                  alignment: themePro ? 'left' : 'center',
                },
                dataField: 'loginLogoUrl',
                template: 'LoginImage',
              },
              {
                label: {
                  text: t('Favicon Image'),
                  alignment: themePro ? 'left' : 'center',
                },
                dataField: 'faviconUrl',
                template: 'FaviconImage',
              },
            ],
          },
          {
            itemType: 'group',
            colCount: 3,
            caption: t('Business Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'centerCorporateNumber',
                label: {
                  text: t('Corporate Registration Number'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
              {
                dataField: 'companyName',
                label: {
                  text: t('Company Name'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    max: 100,
                    trim: true,
                  },
                ],
              },
              {
                dataField: 'centerRepresentativeName',
                label: {
                  text: t('Representative Name'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
              {
                dataField: 'centerBusiness',
                label: {
                  text: t('Business'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
              {
                dataField: 'centerIndustry',
                label: {
                  text: t('Industry'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
              {
                itemType: 'group',
                cssClass: 'group-padding-small',
                colCount: 5,
                items: [
                  {
                    dataField: 'centerRepresentativePhoneCode',
                    label: {
                      text: t('Representative Phone No'),
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.centerRepresentativePhoneCode
                        ? formData.centerRepresentativePhoneCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                    cssClass: 'center-info-label center-info-select',
                  },
                  {
                    dataField: 'centerRepresentativePhone',
                    label: { visible: false },
                    editorType: 'dxTextBox',
                    colSpan: 4,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        trim: true,
                        max: 15,
                      },
                      {
                        type: 'pattern',
                        pattern: inputPhoneRegExp,
                      },
                    ],
                  },
                ],
              },
              {
                dataField: 'sendOnlyEmail',
                label: {
                  text: t('Send only E-mail'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'email',
                  },
                ],
              },
              {
                dataField: 'receiveOnlyEmail',
                label: {
                  text: t('Receive only E-mail'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'email',
                  },
                ],
              },
              {
                itemType: 'group',
                colCount: 5,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'centerRepresentativeFaxCode',
                    label: {
                      text: t('Representative Fax No'),
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.centerRepresentativeFaxCode
                        ? formData.centerRepresentativeFaxCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                    cssClass: 'center-info-label center-info-select',
                  },
                  {
                    dataField: 'centerRepresentativeFax',
                    label: { visible: false },
                    editorType: 'dxTextBox',
                    colSpan: 4,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        trim: true,
                        max: 20,
                      },
                      {
                        type: 'pattern',
                        pattern: inputPhoneRegExp,
                      },
                    ],
                  },
                ],
              },
              {
                itemType: 'group',
                colSpan: 6,
                colCount: 6,
                items: [
                  {
                    dataField: 'centerPostCode',
                    label: {
                      text: t('Post code'),
                    },
                    editorType: 'dxTextBox',
                    editorOptions: {
                      onEnterKey: () =>
                        setShowModalPostCode(!showModalPostCode),
                      buttons: [
                        {
                          name: 'search',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            icon: 'search',
                            onClick: () =>
                              setShowModalPostCode(!showModalPostCode),
                          },
                        },
                      ],
                    },
                    colSpan: 6,
                    validationRules: [
                      {
                        type: 'stringLength',
                        trim: true,
                        max: 100,
                      },
                    ],
                  },
                ],
              },
              {
                dataField: 'centerAddressBasic',
                label: {
                  text: t('Address'),
                },
                editorType: 'dxTextBox',
              },
              {
                dataField: 'centerAddressDetail',
                label: {
                  text: t('Address Detail'),
                },
                editorType: 'dxTextBox',
              },
            ],
          },
          {
            itemType: 'group',
            colCount: 3,
            caption: t('Customer Service Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                itemType: 'group',
                colCount: 5,
                cssClass: 'group-padding-small',
                items: [
                  {
                    dataField: 'orderPhoneCode',
                    label: {
                      text: t('Consultation/Order Phone No'),
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.orderPhoneCode
                        ? formData.orderPhoneCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'label',
                    },
                    cssClass: 'center-info-label center-info-select',
                  },
                  {
                    dataField: 'orderPhone',
                    label: { visible: false },
                    editorType: 'dxTextBox',
                    colSpan: 4,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        max: 15,
                      },
                    ],
                  },
                ],
              },
              {
                dataField: 'orderMail',
                label: {
                  text: t('Consultation/Order E-mail'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'email',
                  },
                ],
              },
              {
                itemType: 'group',
                cssClass: 'group-padding-small',
                colCount: 5,
                items: [
                  {
                    dataField: 'centerCustomerFaxCode',
                    label: {
                      text: t('Fax No'),
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.centerCustomerFaxCode
                        ? formData.centerCustomerFaxCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                    cssClass: 'center-info-label center-info-select',
                  },
                  {
                    dataField: 'centerCustomerFax',
                    label: { visible: false },
                    editorType: 'dxTextBox',
                    colSpan: 4,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        trim: true,
                        max: 20,
                      },
                      {
                        type: 'pattern',
                        pattern: inputPhoneRegExp,
                      },
                    ],
                  },
                ],
              },
              {
                itemType: 'group',
                cssClass: 'group-padding-small',
                colCount: 5,
                items: [
                  {
                    dataField: 'smsReceiveNoCode',
                    label: {
                      text: t('SMS Receive No'),
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.smsReceiveNoCode
                        ? formData.smsReceiveNoCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                    cssClass: 'center-info-label center-info-select',
                  },
                  {
                    dataField: 'smsReceiveNo',
                    label: { visible: false },
                    editorType: 'dxTextBox',
                    colSpan: 4,
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'stringLength',
                        trim: true,
                        max: 15,
                      },
                      {
                        type: 'pattern',
                        pattern: inputPhoneRegExp,
                      },
                    ],
                  },
                ],
              },
              {
                dataField: 'csOperatingTime',
                label: {
                  text: t('C/S Operating Time'),
                },
                editorType: 'dxTextArea',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
            ],
          },
          {
            itemType: 'group',
            colCount: 3,
            caption: t('Privacy Officer Information'),
            cssClass: 'body-padding-white mb-10',
            items: [
              {
                dataField: 'privacyOfficer',
                label: {
                  text: t('Privacy Officer'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
              {
                dataField: 'privacyOfficerPosition',
                label: {
                  text: t('Privacy Officer Position'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
              {
                dataField: 'privacyOfficerDepartment',
                label: {
                  text: t('Privacy Officer Department'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 100,
                  },
                ],
              },
              {
                dataField: 'privacyOfficerContact',
                label: {
                  text: t('Privacy Officer Contact'),
                },
                editorType: 'dxTextBox',
                validationRules: [
                  {
                    type: 'stringLength',
                    trim: true,
                    max: 250,
                  },
                ],
              },
              {
                dataField: 'privacyOfficerEmail',
                label: {
                  text: t('Privacy Officer E-mail'),
                },
                editorType: 'dxTextBox',
                name: 'privacyOfficerEmail',
                validationRules: [
                  {
                    type: 'email',
                  },
                ],
              },
            ],
          },
          {
            itemType: themePro ? 'empty' : 'group',
            colCount: 2,
            items: [
              {
                itemType: 'button',
                buttonOptions: {
                  icon: 'save',
                  text: t('Save'),
                  onClick: () => onSubmit(),
                },
                horizontalAlignment: 'right',
              },
              {
                itemType: 'button',
                buttonOptions: {
                  icon: 'close',
                  text: t('Cancel'),
                  onClick: () => onCancel(),
                },
                horizontalAlignment: 'left',
              },
            ],
          },
        ]}
      >
        <Template
          name="ImageUpload"
          component={() =>
            themePro ? (
              <ImageUploadPremium
                value={formData?.centerLogoUrl}
                onChangeValue={data => onUploadImage('centerLogoUrl', data)}
                width="100%"
              />
            ) : (
              <ImageUpload
                value={formData?.centerLogoUrl}
                onChangeValue={data => onUploadImage('centerLogoUrl', data)}
              />
            )
          }
        />
        <Template
          name="LoginImage"
          component={() =>
            themePro ? (
              <ImageUploadPremium
                value={formData?.loginLogoUrl}
                onChangeValue={data => onUploadImage('loginLogoUrl', data)}
                id="loginImage"
                width="100%"
              />
            ) : (
              <ImageUpload
                value={formData?.loginLogoUrl}
                onChangeValue={data => onUploadImage('loginLogoUrl', data)}
                id="loginImage"
              />
            )
          }
        />
        <Template
          name="FaviconImage"
          component={() =>
            themePro ? (
              <ImageUploadPremium
                value={formData?.faviconUrl}
                onChangeValue={data => onUploadImage('faviconUrl', data)}
                id="faviconImage"
                accept=".ico"
                allowedFile={['.ico']}
                width="100%"
              />
            ) : (
              <ImageUpload
                value={formData?.faviconUrl}
                onChangeValue={data => onUploadImage('faviconUrl', data)}
                id="faviconImage"
                accept=".ico"
                allowedFile={['.ico']}
              />
            )
          }
        />
      </Form>
      {showModalPostCode && (
        <PopupSelectPostCode
          visible={showModalPostCode}
          text={formData?.centerPostCode}
          onHiding={() => {
            setShowModalPostCode(!showModalPostCode);
          }}
          onSubmit={data => onChangePostCode(data)}
        />
      )}
    </React.Suspense>
  );
}

export default CenterRegistration;
