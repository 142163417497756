/**
 * Update date: 05-06-2023
 * Screen 2055.1
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const RegistrationDefectiveDisposalContext = createContext({
  billListData: [],
});

RegistrationDefectiveDisposalContext.displayName =
  'RegistrationDefectiveDisposalContext';

export const useRegistrationDefectiveDisposal = () => {
  const context = useContext(RegistrationDefectiveDisposalContext);
  return context;
};

export default function RegistrationDefectiveDisposal() {
  const { t }: any = useDictionary({ programId: '2055.1' });
  const [billListData, setBillListData] = useState<any>([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      zone: [],
      reason: [],
    },
    stockDate: null,
    fromDate: new Date(),
    endDate: new Date(),
    zone: '',
    reason: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    disposalId: '',
  });

  const [{ data: billRes, loading: billLoading }, refetchData] = useAxios(
    {
      url: 'warehouse/disposal-registration',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * on update data
   *
   * @param {*} record
   */
  const onUpdateData = record => {
    const existItem = billListData?.some(
      (o: any) => o?.storeGroupId === record?.storeGroupId,
    );
    let dataUpdate: any = [];
    if (existItem) {
      dataUpdate = [...billListData]?.map((o: any) => {
        if (o?.storeGroupId === record?.storeGroupId) return record;
        return o;
      });
    } else {
      dataUpdate = [...billListData];
      dataUpdate.unshift(record);
    }
    setBillListData(dataUpdate);
  };

  const value: any = {
    modeView,
    setModeView,
    formSearchData,
    setFormSearchData,
    billListData,
    setBillListData,
    refetchData,
    onUpdateData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (billRes?.data) {
      const dataMapping = (billRes?.data || []).map(o => {
        o.disposalStatusNm =
          o?.disposalStatus === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setBillListData(dataMapping);
    }
  }, [billRes]);

  const loadingAll = billLoading;

  return (
    <RegistrationDefectiveDisposalContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </RegistrationDefectiveDisposalContext.Provider>
  );
}
