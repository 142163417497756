import { CONSTANT } from 'constant';

interface NumberSplit {
  phone: string;
  zipCode: string;
}

const { listPhone } = CONSTANT;

const phoneNumberSplit = (number: any): NumberSplit | undefined => {
  var numberSplit: NumberSplit = { phone: '', zipCode: '' };
  for (let i = 0; i < listPhone.length; i++) {
    const zipCodeItem = listPhone[i];
    const zipCodeExist = number ? number.includes(zipCodeItem.value) : false;

    if (!zipCodeExist) {
      continue;
    } else {
      var zipCode = number.substring(0, number.indexOf(zipCodeItem.value) + 5);
      var phone = number.substring(number.indexOf(zipCodeItem.value) + 5);
    }
  }
  return (numberSplit = { phone: phone || number, zipCode: zipCode || '' });
};

export { phoneNumberSplit };
