import useAxios from 'axios-hooks';
import { CONSTANT, TIME_RESEND, USER_REGISTRATION } from 'constant';
import LoadPanel from 'app/components/LoadPanel';
import { Button, Form } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import tokenService from 'services/auth.service';
import { notification } from 'utils/notification';
import { useApp } from 'app';
import { welcomeOnboard } from 'images';

function Verification(props) {
  const { t }: any = useDictionary({});
  const { themePro }: any = useApp();
  const formRef: any = useRef(null);
  const [redirect, setRedirect] = useState<boolean>(false);
  const history = useHistory();
  const [count, setCount] = useState<number>(0);
  const countRef = useRef(0);
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const userRegistration: any = localStorage.getItem(USER_REGISTRATION);
  const [formData] = useState<any>({
    otp: '',
  });
  const [isResendCode, setIsResendCode] = useState<boolean>(false);
  const [{ loading: loadingConfirm }, refetchConfirm] = useAxios(
    {
      url: 'user/verification-otp',
      method: 'POST',
    },
    { manual: true },
  );
  const [{ loading: loadingResend }, refetchResend] = useAxios(
    {
      url: 'user/create-otp',
      method: 'POST',
    },
    { manual: true },
  );

  const handleSubmit = async () => {
    if (!userRegistration) return history.push(CONSTANT.router.sign_up);
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    setCount(countRef.current);
    const email = (JSON.parse(userRegistration) || {})['email'];
    const data = {
      ...formData,
      email,
    };
    const res = await refetchConfirm({
      data,
    });
    notification({ res });
    if (res?.data?.status === '200') {
      setRedirect(true);
      setTimeout(() => {
        return history.push(CONSTANT.router.login);
      }, 5000);
      return;
    }
  };
  const handleResendCode = async () => {
    if (count) return;
    if (!userRegistration) return history.push(CONSTANT.router.sign_up);
    const email = JSON.parse(userRegistration).email;
    const data = {
      email,
    };
    const res = await refetchResend({
      data,
    });
    if (res?.data?.status === '200') {
      if (!userRegistration) return history.push(CONSTANT.router.sign_up);
      const timeResendCode =
        new Date().getTime() + parseInt(TIME_RESEND) * 60000;
      tokenService.updateLocalUserRegistration({ timeResendCode });
      setCount(parseInt(TIME_RESEND) * 60);
      setIsResendCode(true);
      if (history.location.search === '?resend-code') {
        setShowVerify(true);
      }
    }
  };
  const ButtonConfirmCode = () => {
    return (
      <Button
        text={t('Confirm')}
        type="default"
        stylingMode="contained"
        width={themePro ? '100%' : 300}
        onClick={() => handleSubmit()}
      />
    );
  };
  const ButtonResendCode = () => {
    const [timeLeft, setTimeLeft] = useState(count);

    useEffect(() => {
      if (!timeLeft) return setCount(0);

      const intervalId = setInterval(() => {
        countRef.current = timeLeft - 1;
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }, [timeLeft]);
    return (
      <Button
        text={`${t('Resend Verification Code')}${
          timeLeft ? ` (${timeLeft}s)` : ''
        }`}
        type="normal"
        stylingMode="contained"
        width={themePro ? '100%' : 300}
        onClick={() => handleResendCode()}
        style={!!timeLeft ? { cursor: 'not-allowed' } : {}}
      ></Button>
    );
  };

  const ButtonRedirectLogin = () => {
    const [timeLeft, setTimeLeft] = useState(5);

    useEffect(() => {
      if (!timeLeft) return setTimeLeft(0);
      const intervalId = setInterval(() => {
        setTimeLeft(preState => preState - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }, [timeLeft]);
    return (
      <Button
        text={`${t('Login')} (${timeLeft}s)`}
        type="default"
        stylingMode="contained"
      />
    );
  };
  const VerificationCode = () => {
    return (
      <>
        <Form
          formData={formData}
          ref={formRef}
          showColonAfterLabel={false}
          hoverStateEnabled={true}
          items={[
            {
              dataField: 'otp',
              editorType: 'dxTextBox',
              label: {
                text: t('Verification Code'),
              },
              editorOptions: {
                maxLength: 6,
              },
              isRequired: true,
            },
          ]}
          labelLocation="top"
          colCount={1}
        />
        <br />
        <div className="title-description" style={{ lineHeight: 1.5 }}>
          <p>{t('The 6-digits code has been sent to your e-mail.')}</p>
          <p>
            {t(
              'The verification code will expire in 03:00 and can only be used once.',
            )}
          </p>
          <p>
            {t(
              'Other wise you can request another verification code in 3 minutes',
            )}
          </p>
        </div>
        <div style={{ textAlign: 'center', margin: '50px 0 50px' }}>
          <ButtonConfirmCode />
          {!showVerify && (
            <div>
              <br />
              <ButtonResendCode />
            </div>
          )}
        </div>
      </>
    );
  };
  const ResendCode = () => {
    return (
      <>
        <p style={{ color: 'red', textAlign: 'center' }}>
          {t(
            'Sorry, your email has been existed. please get the new verification code by clicking on the Resend verification code button as below.',
          )}
        </p>
        <div style={{ textAlign: 'center', margin: '50px 0 50px' }}>
          <ButtonResendCode />
        </div>
        {isResendCode && (
          <p style={{ color: '#00BFDF', textAlign: 'center', marginTop: 30 }}>
            {t('The 6-digits code has sent to your e-mail')}
          </p>
        )}
        {showVerify && <VerificationCode />}
      </>
    );
  };
  const contentRender = () => {
    const { search } = history?.location;
    switch (search) {
      case '?verification-code':
        return <VerificationCode />;
      case '?resend-code':
        return <ResendCode />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!userRegistration) return;
    const timeResendCode = JSON.parse(userRegistration).timeResendCode;
    const countDown = Math.floor(
      (timeResendCode - new Date().getTime()) / 1000,
    );
    if (countDown > 0) {
      setCount(countDown);
      setIsResendCode(true);
      if (history.location.search === '?resend-code') {
        setShowVerify(true);
      }
    }
  }, [userRegistration]);
  return (
    <div>
      <LoadPanel visible={loadingConfirm || loadingResend} />
      <div className="title">
        {redirect ? t('Welcome Onboard') : t('Verification Code')}
      </div>
      {redirect ? (
        <>
          {themePro && (
            <div style={{ textAlign: 'center' }}>
              <img src={welcomeOnboard} alt="welcome" />
            </div>
          )}
          <p
            style={
              themePro
                ? {
                    margin: '20px 0',
                    textAlign: 'center',
                  }
                : {
                    color: '#00BFDF',
                    textAlign: 'center',
                    margin: '30px 0',
                  }
            }
          >
            {t(
              'Welcome onboard. Your registration has been successfully, and you can login now or wait 5s.',
            )}
          </p>
          {themePro && <ButtonRedirectLogin />}
        </>
      ) : (
        contentRender()
      )}
    </div>
  );
}

export default Verification;
