/**
 * Update date: 2023-10-03
 * Screen 2011.9
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const CreateEditView = React.lazy(() => import('./CreateEditView'));
const ProductPackingQuantity = React.lazy(
  () => import('./ProductPackingQuantity'),
);
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2011_9_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2011_9 = () => {
  const { t }: any = useDictionary({ programId: '2011.9' });
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    containerName: '',
    use: null,
    dataList: {
      zone: [],
      productType: [],
    },
    zone: '',
    productType: '',
  });

  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'container_id',
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      containerName: formSearchData?.containerName,
      use: formSearchData?.use,
      zoneIds: formSearchData?.dataList?.zone?.map?.((o: any) => o.zoneId),
      productTypes: formSearchData?.dataList?.productType?.map(
        (o: any) => o?.code,
      ),
    };
    const res = await refetchData({
      url: '/product/m2011_9',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const mappingData = (res?.data?.data || []).map(o => {
        const {
          inner_diameter_length = 0,
          inner_diameter_width = 0,
          inner_diameter_height = 0,
          outer_diameter_length = 0,
          outer_diameter_width = 0,
          outer_diameter_height = 0,
        } = o;
        return {
          ...o,
          inside_diameter: `${inner_diameter_length} * ${inner_diameter_width} * ${inner_diameter_height}`,
          outside_diameter: `${outer_diameter_length} * ${outer_diameter_width} * ${outer_diameter_height}`,
        };
      });
      setData(mappingData);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    store,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <CreateEditView />;
      case 'product':
        return <ProductPackingQuantity />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2011_9;
