/**
 * Update date: 2023-08-31
 * Screen 2054.E
 */
import React, { useRef, memo } from 'react';
import moment from 'moment';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';

// File name export data grid
const exportFileName =
  'SMDC_2054.E Inventory adjustment details inquiry_by Zone_' +
  moment().format('YYYYMMDD');

/**
 * Grid by month component
 *
 * @param {*} { data, t }
 * @return {*}
 */
function GridByZone({ data, t }) {
  const { DateFormat } = useFormatTime();
  const { AmountFormat, QtyFormat } = useFormat();
  const dataGridRef: any = useRef(null);

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  // Generate column grid
  const columns = [
    {
      dataField: 'plan_id',
      caption: t('ID'),
      alignment: 'center',
    },
    {
      dataField: 'plan_no',
      caption: t('Inventory Due Diligence'),
    },
    {
      dataField: 'stock_taking_time',
      caption: t('Stock Taking Period'),
    },
    {
      dataField: 'stockadjust_dt',
      caption: t('Adjust Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'zone_nm',
      caption: t('Zone'),
    },
    {
      dataField: 'stock_ea',
      caption: t('Stock EA'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'stocktaking_ea',
      caption: t('Stock Taking EA'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'stockadjust_ea',
      caption: t('Adjust EA'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'cost_amount',
      caption: t('Adjust Cost Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  return (
    <DataGridFull
      storageKeyInquiry="zone"
      columns={columns}
      ref={dataGridRef}
      dataSource={data}
      fixedLeft={2}
      fixedRight={1}
      options={{
        columnAutoWidth: true,
        groupPanel: { visible: true },
        export: { fileName: exportFileName },
        onToolbarPreparing: (e: any) => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'totalCount',
            },
            {
              location: 'before',
              widget: 'dxButton',
              options: {
                disabled: !data?.length,
                icon: 'xlsxfile',
                text: t('Export to Excel'),
                onClick: () => exportExcel(),
              },
            },
          );
        },
        summary: {
          totalItems: [
            {
              column: 'stocktaking_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
            },
            {
              column: 'stockadjust_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
            },
            {
              column: 'stock_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
            },
            {
              column: 'cost_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
          ],
          groupItems: [
            {
              summaryType: 'count',
              displayFormat: '{0}',
            },
            {
              column: 'stocktaking_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
            {
              column: 'stockadjust_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
            {
              column: 'stock_ea',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
            {
              column: 'cost_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              alignByColumn: true,
              showInGroupFooter: false,
            },
          ],
        },
      }}
    />
  );
}

export default memo(GridByZone);
