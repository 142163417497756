// Update date: 10-05-2023
// Screen 1041.1

import { useApp } from 'app';
import { PROGRAM_TYPES, PROGRAM_TYPES_PREMIUM } from 'constant';
import { Button } from 'devextreme-react';
import Sortable from 'devextreme-react/sortable';
import { Tooltip } from 'devextreme-react/tooltip';
import TreeView from 'devextreme-react/tree-view';
import { unionBy } from 'lodash';
import { forwardRef, useState } from 'react';
import { useRegisterProgram } from '..';

function TreeViewList(props, ref) {
  const context: any = useRegisterProgram();
  const { themePro }: any = useApp();
  const {
    treeList,
    setTreeList,
    setSelected,
    refetchData,
    formatData,
    refetchUpdate,
  } = context;

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipId, setTooltipId] = useState<string>('');

  // on drag change
  const onDragChange = e => {
    const treeViewRef = ref?.current?.instance;

    const fromNode = findNode(treeViewRef, e.fromIndex);
    const toNode = findNode(treeViewRef, calculateToIndex(e));

    if (toNode !== null && fromNode.itemData.level !== toNode.itemData.level) {
      e.cancel = true;
    }
  };
  // calculate to index
  const calculateToIndex = e => {
    if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
      return e.toIndex;
    }
    return e.fromIndex >= e.toIndex ? e.toIndex : e.toIndex + 1;
  };
  // remove children
  const moveChildren = (node, fromDataSource, toDataSource) => {
    if (!node.itemData.isDirectory) {
      return;
    }

    node.children.forEach(child => {
      if (child.itemData.isDirectory) {
        moveChildren(child, fromDataSource, toDataSource);
      }

      const fromIndex = fromDataSource.findIndex(
        item => item.id === child.itemData.id,
      );
      fromDataSource.splice(fromIndex, 1);
      toDataSource.splice(toDataSource.length, 0, child.itemData);
    });
  };
  // on drag end
  const onDragEnd = async e => {
    if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
      return;
    }

    const treeViewRef = ref?.current?.instance;

    const fromNode = findNode(treeViewRef, e.fromIndex);
    let toNode = findNode(treeViewRef, calculateToIndex(e));
    let newOrders = toNode?.itemData?.orders;
    if (
      !toNode &&
      (!fromNode?.itemData?.parentId || fromNode?.itemData?.parentId == '0')
    ) {
      toNode = findNode(treeViewRef, e.toIndex);
      newOrders = toNode?.itemData?.orders + 1;
    }

    if (e.dropInsideItem && toNode !== null && !toNode.itemData.isDirectory) {
      return;
    }

    const data = {
      programIdOld: fromNode.itemData.programId || '',
      programIdNew: fromNode.itemData.programId || '',
      programName: fromNode.itemData.programName || '',
      programType: fromNode.itemData.programType || '',
      programUrl: fromNode.itemData.programUrl || '',
      adminOnly: fromNode.itemData.adminOnly,
      programTheme: fromNode.itemData.programTheme || '',
      programIcon: fromNode.itemData.programIcon || '',
      parentId: toNode?.itemData?.parentId || '0',
      orders: newOrders,
      pdaTf: fromNode?.itemData?.pdaTf,
    };
    const res = await refetchUpdate({
      data,
    });
    if (res?.data?.status === '201') {
      const dataRes = await refetchData();
      if (dataRes?.data?.status === '200') {
        const { data } = dataRes?.data;
        const dataFormat = formatData(data);
        let l: any = [];
        l = [...treeList]
          .filter(o => o?.parentId !== toNode.itemData.parentId)
          .filter(o => o?.parentId !== fromNode.itemData.parentId);
        const toData = [...dataFormat].filter(
          o => o?.parentId === toNode.itemData.parentId,
        );
        const fromData = [...dataFormat].filter(
          o => o?.parentId === fromNode.itemData.parentId,
        );
        l = l.concat(toData, fromData).sort((a, b) => {
          if (a?.level === b?.level) {
            return a?.orders - b?.orders;
          }
          return a?.level - b?.level;
        });
        setTreeList(unionBy(l, 'programId'));
      }
    }
  };
  const findNode = (treeView, index) => {
    const nodeElement = treeView
      .element()
      .querySelectorAll('.dx-treeview-node')?.[index];
    if (nodeElement) {
      return findNodeById(
        treeView.getNodes(),
        nodeElement.getAttribute('data-item-id'),
      );
    }
    return null;
  };
  const findNodeById = (nodes, id) => {
    for (let i = 0; i < nodes.length; i += 1) {
      if (nodes[i].itemData.id === id) {
        return nodes[i];
      }
      if (nodes[i].children) {
        const node = findNodeById(nodes[i].children, id);
        if (node != null) {
          return node;
        }
      }
    }
    return null;
  };
  // render tree list
  const renderTreeList = e => {
    const programType: any = (
      themePro ? PROGRAM_TYPES_PREMIUM : PROGRAM_TYPES
    ).find(item => item.value === e.programType);
    const programIdTooltip = e?.programId.replace(/\./g, '');
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={() =>
          setSelected({
            type: 'edit',
            data: e,
          })
        }
        onMouseEnter={toggleTooltipEnter}
        onMouseLeave={toggleTooltipLeave}
        id={'menu' + programIdTooltip}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {e?.level !== 1 && (
            <i
              style={{ marginRight: 5 }}
              className="dx-icon dx-icon-dragvertical"
            ></i>
          )}
          {e?.level === 3 && (
            <img
              style={{ maxWidth: 15, marginRight: 5 }}
              src={programType?.iconUrl || PROGRAM_TYPES[0].iconUrl}
              alt={programType?.text}
            />
          )}
          {e.programName}
        </div>
        {e?.level !== 3 && (
          <Button
            className="program-registration-button"
            icon="plus"
            type="normal"
            stylingMode={themePro ? 'contained' : 'text'}
            onClick={(event: any) => {
              setSelected({
                type: 'create',
                data: e,
              });
              event?.event.stopPropagation();
            }}
          />
        )}
        <Tooltip
          target={'#menu' + programIdTooltip}
          visible={'menu' + programIdTooltip === tooltipId && tooltipVisible}
          closeOnOutsideClick={false}
          position="top"
        >
          <div>{e?.programId + ': ' + e.programName}</div>
        </Tooltip>
      </div>
    );
  };

  const toggleTooltipEnter = e => {
    const id = e.target.id || e.target.parentNode.id;
    setTooltipId(id);
    setTooltipVisible(true);
  };

  const toggleTooltipLeave = e => {
    setTooltipId('');
    setTooltipVisible(false);
  };

  return (
    <Sortable
      filter=".dx-treeview-item"
      group="shared"
      allowDropInsideItem={true}
      allowReordering={true}
      onDragChange={onDragChange}
      onDragEnd={onDragEnd}
      style={
        themePro
          ? {
              padding: 20,
              backgroundColor: '#fff',
              borderRadius: 6,
              width: 400,
            }
          : { borderRight: '1px solid #ccc', padding: 20 }
      }
    >
      <TreeView
        id="treeviewPrograme"
        expandNodesRecursive={false}
        dataStructure="plain"
        ref={ref}
        items={treeList}
        itemRender={renderTreeList}
        width={350}
        height={700}
        displayExpr="programName"
      />
    </Sortable>
  );
}

export default forwardRef(TreeViewList);
