/**
 * Update date: 05-06-2023
 * Screen 2054.1
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const Form = React.lazy(() => import('./Form'));
const List = React.lazy(() => import('./List'));
/**
 * create context
 */
export const ModuleContext = createContext({});

ModuleContext.displayName = 'RegistrationInventoryDueDiligenceContext';

export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  return context;
};

const RegistrationInventoryDueDiligence = () => {
  const [mainSearchParams, setMainSearchParams] = useState({
    subject: '',
    manager: '',
    // fromDate: new Date(),
    // toDate: new Date(),
  });
  const [listRegistration, setListRegistration] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [
    { data: resApiSearchRegistration, loading: loadingApiSearchRegistration },
    apiSearchRegistration,
  ] = useAxios(
    {
      url: 'warehouse/iddr',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    modeView,
    setModeView,
    mainSearchParams,
    setMainSearchParams,
    listRegistration,
    setListRegistration,
    apiSearchRegistration,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'edit':
      case 'create':
        return <Form />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiSearchRegistration?.data) {
      setListRegistration(
        resApiSearchRegistration?.data?.sort((a, b) => b?.splanId - a?.splanId),
      );
    }
  }, [resApiSearchRegistration]);

  const loadingAll = loadingApiSearchRegistration;

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default RegistrationInventoryDueDiligence;
