/**
 * Update date: 15-06-2023
 * Screen 2087.9
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import { uniqBy } from 'lodash';
import { useRef, useState } from 'react';
import { useModuleContext } from '../index';
import useFormatTime from 'hooks/useFormatTime';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

// common code type
const listTypeCommon = {
  productType: {
    codeType: 'PT',
  },
};
/**
 * Form search component
 *
 * @return {*}
 */
const FormSearch = () => {
  const context: any = useModuleContext();
  const { params, setParams, onSubmit, t } = context;
  const [showPopupByType, setShowPopupByType] = useState('');
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);

  /**
   *  Update data input from popup
   *
   * @param {*} popupType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (popupType, data, key: any = false) => {
    const filterData = uniqBy([...params?.dataList?.[popupType], ...data], key);
    const newStore = { ...params?.dataList, [popupType]: filterData };
    setParams(preState => ({
      ...preState,
      [popupType]: '',
      dataList: newStore,
    }));
    setShowPopupByType('');
  };

  /**
   * Remote data input
   *
   * @param {*} popupType
   * @param {*} index
   */
  const removeStore = (popupType, index) => {
    let newStore;
    if (index === -1) {
      setParams(preState => ({
        ...preState,
        dataList: {
          ...preState?.dataList,
          [popupType]: [],
        },
      }));
    } else {
      const l = [...params?.dataList[popupType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setParams(preState => ({
        ...preState,
        dataList: {
          ...preState?.dataList,
          [popupType]: newStore,
        },
      }));
    }
  };
  /**
   * Generate select box
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = params?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 1) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * Render list popup
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'productType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Onchange date when choose date option
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };

  /**
   * Render search tab Content
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={formRef}
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            {
              label: { text: t('End Date') },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            generateDxTextBox('Product Type', 'productType', 'name', {
              readOnly: params?.isReadOnlyProductTp,
              colSpan: 2,
            }),
          ]}
          labelLocation="top"
          colCount={4}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
