/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Form } from 'devextreme-react';
import { useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { usePopupOrder } from '..';
import PopupSelectPostCode from '../../PopupSelectPostCode';
import AddClaim from './AddClaim';
import ShippingRequest from './ShippingRequest';

export default function BuyerContent() {
  const {
    t,
    storeClaims,
    storeShipping,
    DateTimeFormat,
    listCountry,
    formInformation,
    listClaimMethod,
    storeTaxInvoice,
  }: any = usePopupOrder();
  const formRef: any = useRef(null);
  const claimGridRef: any = useRef(null);

  const [showPopupPostCode, setShowPopupPostCode] = useState(false);
  const [claim, setClaim] = useState<any>({
    visible: false,
  });

  const [{ loading: loadingOrder }, refetchSaveOrder] = useAxios(
    {},
    { useCache: false, manual: true },
  );

  const [{ loading: loadingClaim }, refetchSaveClaim] = useAxios(
    {
      url: `/ecommerce/order-popup/claim`,
      method: 'PUT',
    },
    { useCache: false, manual: true },
  );

  /**
   * onchange post code
   *
   * @param {*} data
   */
  const onChangePostCode = data => {
    formRef?.current?.instance?.updateData('receiver_zipcode', data?.zipNo);
    formRef?.current?.instance?.updateData(
      'receiver_address1',
      data?.roadAddrPart1,
    );
    formRef?.current?.instance?.updateData(
      'receiver_address2',
      data?.roadAddrPart2,
    );
    setShowPopupPostCode(false);
  };

  /**
   * on save Buyer Information
   *
   */
  const onSaveBuyerInfor = async () => {
    const res: any = await refetchSaveOrder({
      url: `/ecommerce/order-popup/update-order-buyer`,
      method: 'POST',
      data: formInformation,
    });
    notification({ res });
  };

  /**
   * on save Receiver Information
   *
   */
  const onSaveReceiverInfor = async () => {
    const res: any = await refetchSaveOrder({
      url: `/ecommerce/order-popup/update-order-receiver`,
      method: 'POST',
      data: formInformation,
    });
    notification({ res });
  };

  /**
   * on save claim
   *
   * @param {*} data
   */
  const onSaveClaim = async data => {
    const res: any = await refetchSaveClaim({
      data: data,
    });
    notification({ res });
    if (res?.data?.status === '200') {
      if (data?.line_id) {
        storeClaims
          ?.update(
            { order_id: data?.order_id, line_id: data?.line_id },
            res?.data?.data,
          )
          .done(() => {
            claimGridRef?.current?.instance?.refresh();
          });
      } else {
        storeClaims?.insert(res?.data?.data).done(() => {
          claimGridRef?.current?.instance?.refresh();
        });
      }
      setClaim({ visible: false });
    }
  };

  return (
    <div>
      <LoadPanel visible={loadingClaim || loadingOrder} />
      <div style={{ display: 'flex' }} className="mb-10">
        <div style={{ flex: '1 0 50%', paddingRight: 8, position: 'relative' }}>
          <div style={{ position: 'absolute', top: 10, right: 15 }}>
            <Button
              stylingMode="contained"
              type="default"
              text={t('Save')}
              icon="check"
              style={{ marginRight: 5 }}
              onClick={onSaveBuyerInfor}
            />
          </div>
          <Form
            labelLocation="top"
            showColonAfterLabel={false}
            formData={formInformation}
            className="body-padding-white"
            items={[
              {
                caption: t('Buyer Information'),
                itemType: 'group',
                cssClass: 'group-caption-second',
                colCount: 2,
                items: [
                  {
                    dataField: 'buyer_name',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Name'),
                    },
                  },
                  {
                    dataField: 'buyer_email',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Email'),
                    },
                  },
                  {
                    dataField: 'buyer_phone',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Phone No'),
                    },
                  },
                  {
                    dataField: 'buyer_cellphone',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Mobile No'),
                    },
                  },
                ],
              },
              {
                caption: t('Shipping Message'),
                itemType: 'group',
                cssClass: 'group-caption-second',
                items: [
                  {
                    dataField: 'shipping_message',
                    editorType: 'dxTextArea',
                    label: {
                      text: t('Message'),
                    },
                    editorOptions: {
                      height: 136,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
        <div style={{ flex: '1 0 50%', paddingLeft: 8 }}>
          <div className="body-padding-white" style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 10, right: 8 }}>
              <Button
                stylingMode="contained"
                type="default"
                text={t('Save')}
                icon="check"
                style={{ marginRight: 5 }}
                onClick={onSaveReceiverInfor}
              />
            </div>
            <Form
              labelLocation="top"
              showColonAfterLabel={false}
              formData={formInformation}
              onFieldDataChanged={e => {
                if (e?.dataField === 'country_code') {
                  const country = listCountry?.find(o => o?.value === e?.value);
                  formRef?.current?.instance?.updateData(
                    'country_name',
                    country?.label || '',
                  );
                }
              }}
              ref={formRef}
              items={[
                {
                  caption: t('Receiver Information'),
                  itemType: 'group',
                  cssClass: 'group-caption-second',
                  colCount: 2,
                  items: [
                    {
                      dataField: 'receiver_name',
                      editorType: 'dxTextBox',
                      label: {
                        text: t('Receiver'),
                      },
                    },
                    {
                      dataField: 'receiver_phone',
                      editorType: 'dxTextBox',
                      label: {
                        text: t('Phone No'),
                      },
                    },
                    {
                      dataField: 'receiver_cellphone',
                      editorType: 'dxTextBox',
                      label: {
                        text: t('Mobile No'),
                      },
                    },
                    {
                      dataField: 'country_code',
                      editorType: 'dxSelectBox',
                      label: {
                        text: t('Country'),
                      },
                      editorOptions: {
                        items: listCountry,
                        displayExpr: 'label',
                        valueExpr: 'value',
                      },
                    },
                    {
                      label: {
                        text: t('Post Code'),
                      },
                      dataField: 'receiver_zipcode',
                      colSpan: 2,
                      editorOptions: {
                        onEnterKey: () => setShowPopupPostCode(true),
                        buttons: [
                          {
                            name: 'search',
                            location: 'after',
                            options: {
                              stylingMode: 'text',
                              icon: 'search',
                              onClick: () => setShowPopupPostCode(true),
                            },
                          },
                        ],
                      },
                    },
                    {
                      dataField: 'receiver_address1',
                      editorType: 'dxTextBox',
                      label: {
                        text: t('Address 1'),
                      },
                      colSpan: 2,
                    },
                    {
                      dataField: 'receiver_address2',
                      editorType: 'dxTextBox',
                      label: {
                        text: t('Address 2'),
                      },
                      colSpan: 2,
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="body-padding-white" style={{ marginBottom: 10 }}>
        <div style={{ position: 'relative', marginBottom: 5 }}>
          <div className="dx-form-group-caption">{t('Claims Management')}</div>
          <div style={{ position: 'absolute', top: -5, right: 10 }}>
            <Button
              stylingMode="contained"
              text={t('Add')}
              icon="plus"
              onClick={() =>
                setClaim({
                  visible: true,
                })
              }
            />
          </div>
        </div>
        <DataGridFull
          dataSource={storeClaims}
          options={{
            searchPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false,
            },
            filterPanel: {
              visible: false,
            },
            scrolling: {
              mode: 'infinite',
            },
            className: 'datagrid-relative-header',
          }}
          ref={claimGridRef}
          columns={[
            {
              dataField: 'line_id',
              caption: t('No'),
              alignment: 'left',
              width: 100,
            },
            {
              dataField: 'claim_dm',
              caption: t('Date and Time'),
              format: DateTimeFormat,
              dataType: 'date',
              alignment: 'left',
            },
            {
              dataField: 'claim_method',
              caption: t('Method'),
              alignment: 'left',
              cellRender: e => {
                const data = listClaimMethod.find(o => o?.value === e?.value);
                return data?.label || '';
              },
            },
            {
              dataField: 'claim_contents',
              caption: t('Content'),
              alignment: 'left',
            },
            {
              dataField: 'applicant_name',
              caption: t('Applicant'),
              alignment: 'left',
            },
            {
              dataField: 'result_contents',
              caption: t('Result Content'),
              alignment: 'left',
            },
            {
              dataField: 'management_name',
              caption: t('Management'),
              alignment: 'left',
            },
            {
              dataField: 'status',
              caption: t('Status'),
              alignment: 'left',
              cellRender: e => {
                return e?.value === '0' ? t('Waiting') : t('Complete');
              },
            },
            {
              caption: t('Action'),
              alignment: 'left',
              width: 150,
              cellRender: e => {
                return (
                  <span
                    onClick={() => {
                      setClaim({
                        visible: true,
                        data: e?.data,
                      });
                    }}
                    className="cell-link"
                  >
                    {t('Edit')}
                  </span>
                );
              },
            },
          ]}
        />
      </div>
      <ShippingRequest
        storeShipping={storeShipping}
        storeTaxInvoice={storeTaxInvoice}
      />
      {claim?.visible && (
        <AddClaim
          dataClaim={claim?.data}
          onOk={onSaveClaim}
          onClose={() =>
            setClaim({
              visible: false,
            })
          }
        />
      )}

      {showPopupPostCode && (
        <PopupSelectPostCode
          visible={showPopupPostCode}
          text={formInformation?.receiver_zipcode}
          onHiding={() => {
            setShowPopupPostCode(false);
          }}
          onSubmit={data => onChangePostCode(data)}
        />
      )}
    </div>
  );
}
