interface PasswordSecurityDto {
  twoStepVerify?: boolean;
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export const passwordSecurityDto: PasswordSecurityDto = {
  twoStepVerify: false,
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};
