/**
 * Update date: 14-06-2023
 * Tabpanel component
 */
import { useApp } from 'app';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
interface CollapseType {
  children: any[];
  onSelected?: any;
  isSelected?: any;
}

function TabPanelCustom({ children, onSelected, isSelected }: CollapseType) {
  const { t } = useDictionary({});
  const { themePro }: any = useApp();
  return (
    <div className={`${themePro && 'tab-custom-premium'}`}>
      <TabPanel
        defaultSelectedIndex={parseInt(isSelected) || 0}
        onTitleClick={(e: any) => onSelected(e?.itemIndex)}
        swipeEnabled={false}
      >
        {Array.isArray(children)
          ? children.map((map, i) => (
              <TabItem key={i} title={map?.props?.name || t('Tab Panel')}>
                <div style={themePro ? {} : { padding: 20 }}>{map}</div>
              </TabItem>
            ))
          : null}
      </TabPanel>
    </div>
  );
}

export default TabPanelCustom;
