/**
 * Update date: 23-05-2023
 * Screen 2023.1
 */
import React, { useRef, useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import { useOrderRegistration } from '..';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import { uniqBy } from 'lodash';
import DateOptions from 'app/components/DateOptions';
import useFormatTime from 'hooks/useFormatTime';
import { dateFormatStr } from 'utils/format';
// Define type:
// VD: vendor
// OT: order state
const FormSearch = () => {
  const context: any = useOrderRegistration();
  const { params, setParams, refetchData, t } = context;
  const formRef: any = useRef(null);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [typeCommon, setTypeCommon] = useState('');
  const { DateFormat } = useFormatTime();
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      ...params,
      orderState: params?.formStore?.orderState.map(o => o?.code),
      vendorIds: params?.formStore?.vendor.map(o => o?.vendorId),
      fromDate: dateFormatStr(params?.fromDate),
      endDate: dateFormatStr(params?.endDate),
    };
    delete data.formStore;
    refetchData({
      data,
    });
  };
  /**
   * on show popup
   *
   * @param {*} type
   */
  const handleShowPopup = type => {
    setTypeCommon(type);
    setIsShowPopup(true);
  };
  /**
   * on close popup
   *
   */
  const handleClosePopup = () => {
    setTypeCommon('');
    setIsShowPopup(false);
  };
  /**
   * on switch type
   *
   * @param {*} type
   * @return {*}
   */
  const switchType = type => {
    let name = '';
    switch (type) {
      case 'VD':
        name = 'vendor';
        break;
      case 'OT':
        name = 'orderState';
        break;
      default:
        break;
    }
    return name;
  };

  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    let name = switchType(type);
    setParams(preState => ({
      ...preState,
      [name]: '',
      formStore: {
        ...preState?.formStore,
        [name]: [],
      },
    }));
  };
  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    let name = switchType(type);
    const newData: any =
      type === 'VD'
        ? params?.formStore?.[name].filter(
            (filter: any) => filter?.vendorId !== data?.vendorId,
          )
        : params?.formStore?.orderState.filter(
            (o: any) => o?.code !== data?.code,
          );
    setParams(preState => ({
      ...preState,
      [name]: '',
      formStore: {
        ...preState?.formStore,
        [name]: newData,
      },
    }));
  };
  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    let name = switchType(typeCommon);
    const newData: any =
      typeCommon === 'VD'
        ? uniqBy([...params?.formStore?.[name]].concat(data), 'vendorId')
        : uniqBy([...params?.formStore?.[name]].concat(data), 'code');
    setParams(preState => ({
      ...preState,
      [name]: '',
      formStore: {
        ...preState?.formStore,
        [name]: newData,
      },
    }));
    handleClosePopup();
  };
  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        setParams(preState => ({ ...preState, fromDate: e?.value }));
        if (params.endDate && e?.value && e?.value > params.endDate)
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        setParams(preState => ({ ...preState, endDate: e?.value }));
        if (params.fromDate && e?.value && e?.value < params.fromDate)
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };
  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, endDateValue) => {
    if (!fromDateValue || !endDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', endDateValue);
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Vendor') },
              dataField: 'vendor',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => handleShowPopup('VD'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.vendor?.[0]?.vendorName,
                      onClick: () =>
                        handleRemove('VD', params?.formStore?.vendor?.[0]),
                      visible: !!params?.formStore?.vendor?.[0]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.vendor?.[1]?.vendorName,
                      onClick: () =>
                        handleRemove('VD', params?.formStore?.vendor?.[1]),
                      visible: !!params?.formStore?.vendor?.[1]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${params?.formStore?.vendor?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: params?.formStore?.vendor?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.vendor?.length,
                      onClick: () => handleCleanAll('VD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('VD'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Order State') },
              dataField: 'orderState',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => handleShowPopup('OT'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.orderState?.[0]?.name,
                      onClick: () =>
                        handleRemove('OT', params?.formStore?.orderState?.[0]),
                      visible: !!params?.formStore?.orderState?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.orderState?.[1]?.name,
                      onClick: () =>
                        handleRemove('OT', params?.formStore?.orderState?.[1]),
                      visible: !!params?.formStore?.orderState?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${params?.formStore?.orderState?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: params?.formStore?.orderState?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.orderState?.length,
                      onClick: () => handleCleanAll('OT'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopup('OT'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={2}
        />
      </>
    );
  };
  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, endDate) => onChangeDate(fromDate, endDate)}
        />
        <Form
          ref={formRef}
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !params.fromDate && params.endDate ? true : false,
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !params.endDate && params.fromDate ? true : false,
            },
          ]}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
        ></Form>
      </>
    );
  };
  /**
   * render content popup
   *
   * @return {*}
   */
  const renderContentPopup = () => {
    if (!isShowPopup) return;
    switch (typeCommon) {
      case 'OT':
        return (
          <PopupSelectCommonCode
            visible={isShowPopup}
            text={params.orderState}
            onHiding={() => handleClosePopup()}
            codeType="OT"
            mode="multiple"
            onSubmit={handleSubmitPopup}
          />
        );
      case 'VD':
        return (
          <PopupSelectVendor
            visible={isShowPopup}
            text={params.vendor}
            onHiding={() => {
              handleClosePopup();
            }}
            mode="multiple"
            onSubmit={handleSubmitPopup}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {renderContentPopup()}
    </div>
  );
};

export default FormSearch;
