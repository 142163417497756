/**
 * Update date: 2024-05-18
 * Screen 2052.C
 */
import React, { useState, useRef } from 'react';
import { uniqBy } from 'lodash';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectZone from 'app/components/PopupCommon/PopupSelectZone';
import { useModuleContext } from '..';
import useDictionary from 'hooks/useDictionary';

const FormSearch = () => {
  const { t } = useDictionary({ programId: '2052.C' });
  const context: any = useModuleContext();
  const { refetchData } = context;
  const formRef: any = useRef(null);
  const [formStore, setFormStore] = useState<any>({
    lgCategory: [],
    mdCategory: [],
    smCategory: [],
    subCategory: [],
    productGroup: [],
    product: [],
    zone: [],
    location: '',
    stockCondition: '',
  });
  const [formData, setFormData] = useState({
    lgCategory: '',
    mdCategory: '',
    smCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    zone: '',
    location: '',
    stockCondition: '',
  });

  const [isShowPopupCommon, setIsShowPopupCommon] = useState(false);
  const [typeCommon, setTypeCommon] = useState('');
  const [isShowPopupProductGroup, setIsShowPopupProductGroup] = useState(false);
  const [isShowPopupProduct, setIsShowPopupProduct] = useState(false);
  const [isShowPopupZone, setIsShowPopupZone] = useState(false);

  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'ZN':
        name = 'zone';
        break;
      default:
        break;
    }
    let newData: any =
      type === 'LC' || type === 'MC' || type === 'SC' || type === 'UC'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.code !== data?.code,
          )
        : type === 'PG'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productGroupId !== data?.productGroupId,
          )
        : type === 'PD'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productId !== data?.productId,
          )
        : [...formStore?.[name]].filter(
            (filter: any) => filter?.zoneId !== data?.zoneId,
          );
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
  };
  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'ZN':
        name = 'zone';
        break;
      default:
        break;
    }
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: [] }));
  };

  /**
   * on show popup product
   *
   * @param {*} type
   */
  const handleShowPopupProduct = type => {
    setIsShowPopupProduct(true);
    setTypeCommon(type);
  };

  /**
   * on show popup common
   *
   * @param {*} type
   */
  const handleShowPopupCommon = type => {
    setIsShowPopupCommon(true);
    setTypeCommon(type);
  };

  /**
   * on show popup product group
   *
   * @param {*} type
   */
  const handleShowPopupProductGroup = type => {
    setIsShowPopupProductGroup(true);
    setTypeCommon(type);
  };

  /**
   * on show popup zone
   *
   * @param {*} type
   */
  const handleShowPopupZone = type => {
    setIsShowPopupZone(true);
    setTypeCommon(type);
  };

  /**
   * on close popup
   *
   */
  const closePopup = () => {
    setTypeCommon('');
    setIsShowPopupCommon(false);
    setIsShowPopupProduct(false);
    setIsShowPopupProductGroup(false);
    setIsShowPopupZone(false);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          items={[
            {
              label: { text: t('Zone') },
              dataField: 'zone',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupZone('ZN');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.zone?.[0]?.zoneName,
                      onClick: () => handleRemove('ZN', formStore?.zone?.[0]),
                      visible: !!formStore?.zone?.[0]?.zoneId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.zone?.[1]?.zoneName,
                      onClick: () => handleRemove('ZN', formStore?.zone?.[1]),
                      visible: !!formStore?.zone?.[1]?.zoneId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.zone?.length - 2} ${t('more')}`,
                      visible: formStore?.zone?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.zone?.length,
                      onClick: () => handleCleanAll('ZN'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupZone('ZN'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Location') },
              dataField: 'location',
              editorType: 'dxTextBox',
            },
            {
              label: { text: t('Stock Quantity') },
              dataField: 'stockCondition',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                items: [
                  { label: '', value: '' },
                  { label: '= 0', value: '=' },
                  { label: '<> 0', value: '<>' },
                  { label: '> 0', value: '>' },
                  { label: '< 0', value: '<' },
                ],
                value: formData.stockCondition ? formData.stockCondition : '',
              },
            },
          ]}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'lgCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('LC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[0]),
                      visible: !!formStore?.lgCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[1]),
                      visible: !!formStore?.lgCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.lgCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.lgCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.lgCategory?.length,
                      onClick: () => handleCleanAll('LC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('LC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mdCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('MC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[0]),
                      visible: !!formStore?.mdCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[1]),
                      visible: !!formStore?.mdCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mdCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mdCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mdCategory?.length,
                      onClick: () => handleCleanAll('MC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('MC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupProductGroup('PG');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[0]),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[1]),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => handleCleanAll('PG'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupProductGroup('PG'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('SC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[0]),
                      visible: !!formStore?.smCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[1]),
                      visible: !!formStore?.smCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smCategory?.length,
                      onClick: () => handleCleanAll('SC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('SC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('UC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[0]),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[1]),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => handleCleanAll('UC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('UC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupProduct('PD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[0]),
                      visible: !!formStore?.product?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[1]),
                      visible: !!formStore?.product?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => handleCleanAll('PD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupProduct('PD'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };

  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    let name = '';
    switch (typeCommon) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'ZN':
        name = 'zone';
        break;
      default:
        break;
    }
    let newData: any =
      typeCommon === 'LC' ||
      typeCommon === 'MC' ||
      typeCommon === 'SC' ||
      typeCommon === 'UC'
        ? uniqBy([...formStore?.[name]].concat(data), 'code')
        : typeCommon === 'PG'
        ? uniqBy([...formStore?.[name]].concat(data), 'productGroupId')
        : typeCommon === 'PD'
        ? uniqBy([...formStore?.[name]].concat(data), 'productId')
        : uniqBy([...formStore?.[name]].concat(data), 'zoneId');

    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
    closePopup();
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    //API 's parameter go changed here

    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      largeCategories: formStore.lgCategory.map((map: any) => map?.code),
      mediumCategories: formStore.mdCategory.map((map: any) => map?.code),
      smallCategories: formStore.smCategory.map((map: any) => map?.code),
      subCategories: formStore.subCategory.map((map: any) => map?.code),
      productGroupIds: formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      productIds: formStore.product.map((map: any) => map?.productId),
      zoneIds: formStore.zone.map((map: any) => map?.zoneId),
      location: formData.location,
      stockCondition: formData.stockCondition,
    };
    refetchData({
      data,
    });
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {isShowPopupCommon && (
        <PopupSelectCommonCode
          visible={isShowPopupCommon}
          text={
            typeCommon === 'LC'
              ? formData.lgCategory
              : typeCommon === 'MC'
              ? formData.mdCategory
              : typeCommon === 'SC'
              ? formData.smCategory
              : typeCommon === 'UC'
              ? formData.subCategory
              : typeCommon === 'PD'
              ? formData.product
              : typeCommon === 'PG'
              ? formData.productGroup
              : typeCommon === 'ZN'
              ? formData.zone
              : ''
          }
          onHiding={() => {
            closePopup();
          }}
          codeType={typeCommon}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
      {isShowPopupProduct && (
        <PopupSelectProduct
          visible={isShowPopupProduct}
          text={formData.product}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
      {isShowPopupProductGroup && (
        <PopupSelectProductGroup
          visible={isShowPopupProductGroup}
          text={formData.productGroup}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
      {isShowPopupZone && (
        <PopupSelectZone
          visible={isShowPopupZone}
          text={formData.zone}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
    </div>
  );
};

export default FormSearch;
