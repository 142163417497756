/**
 * Update date: 14-06-2023
 * Popup product detail component
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import { useEffect, useState } from 'react';
import { notification } from 'utils/notification';
import { useProductDetail } from '../..';
import TableMultipleEditor from '../../TableMultipleEditor';

const ViewBottom = () => {
  const { productId, visible }: any = useProductDetail();
  const [dataSource, setDataSource] = useState<any>([]);
  const { t } = useDictionary({});
  const { QtyFormat, AmountFormat, FifthFormat }: any = useFormat();
  const [{ loading }, refetchData] = useAxios(
    {
      url: '/product/product/product-unit-popup',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  const [{ loading: loadingUpdate }, refetchDataUpdate] = useAxios(
    {
      url: '/product/product/save-unit-popup',
      method: 'PUT',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const getData = async () => {
    const res = await refetchData({
      data: { type: '3', productId: productId },
    });
    if (res?.data?.status === '200') {
      setDataSource(
        (res?.data?.data || []).map(o => ({
          ...o,
          receivingUnitPrice: null,
          forwardingUnitPrice: null,
        })),
      );
    } else {
      notification({ res });
    }
  };

  useEffect(() => {
    if (visible && productId) {
      getData();
    } else if (!visible) {
      setDataSource([]);
    }
  }, [visible, productId]);

  const columnsInformation: any = [
    {
      caption: t('Option information'),
      columns: [
        {
          label: t('Sales unit'),
          dataField: 'option_nm',
        },
        {
          label: t('Quantity per pack'),
          dataField: 'quantity_per_pack',
          format: QtyFormat,
        },
        {
          label: t('Barcode'),
          dataField: 'barcode',
        },
      ],
    },
    {
      caption: t('Cumulative receiving information'),
      columns: [
        {
          label: t('Receive quantity'),
          dataField: 'receive_ea',
          format: QtyFormat,
        },
        {
          label: t('Receive density (%)'),
          dataField: 'receive_rt',
        },
      ],
    },
    {
      caption: t('Cumulative forwarding information'),
      columns: [
        {
          label: t('Forward quantity'),
          dataField: 'forward_ea',
          format: QtyFormat,
        },
        {
          label: t('Forward density (%)'),
          dataField: 'forward_rt',
        },
      ],
    },
    {
      caption: t('Cumulative carry-in information'),
      columns: [
        {
          label: t('Carry-in quantity'),
          dataField: 'return_ea',
          format: QtyFormat,
        },
        {
          label: t('Carry-in rate (%)'),
          dataField: 'return_rt',
        },
      ],
    },
  ];

  const columnsReceiving: any = [
    {
      caption: t('Receiving information'),
      columns: [
        {
          label: t('Receiving unit price (standard)'),
          dataField: 'purchase_unit_price',
          format: AmountFormat,
          width: 115,
        },
        {
          label: t('Receiving unit price (final)'),
          dataField: 'purchase_last_price',
          format: AmountFormat,
          width: 115,
        },
        {
          label: t('Receiving unit price (adjusted)'),
          dataField: 'receivingUnitPrice',
          format: FifthFormat,
          editing: true,
          width: 115,
        },
      ],
    },
  ];
  const columnsForwarding: any = [
    {
      caption: t('Forwarding information'),
      columns: [
        {
          label: t('Forwarding unit price (standard)'),
          dataField: 'supply_unit_price',
          format: AmountFormat,
          width: 115,
        },
        {
          label: t('Forwarding unit price (final)'),
          dataField: 'supply_last_price',
          format: AmountFormat,
          width: 115,
        },
        {
          label: t('Forwarding unit price (adjusted)'),
          dataField: 'forwardingUnitPrice',
          format: AmountFormat,
          editing: true,
          width: 115,
        },
      ],
    },
  ];

  return (
    <>
      <LoadPanel visible={loadingUpdate || loading} />
      <div style={{ textAlign: 'right' }}>
        <Button
          type="default"
          text={t('Change price -> Product master storage')}
          onClick={async () => {
            if (dataSource?.length > 0) {
              const res: any = await refetchDataUpdate({
                data: dataSource?.map(o => ({
                  ...o,
                  receivingUnitPrice: o?.receivingUnitPrice || null,
                  forwardingUnitPrice: o?.forwardingUnitPrice || null,
                })),
              });
              if (res?.data?.status === '201') {
                if (visible && productId) {
                  getData();
                } else {
                  console.log('ProductId not found');
                }
              }
              notification({ res });
            }
          }}
        />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '5fr 3fr 3fr',
          gridGap: '0 20px',
          marginTop: 10,
        }}
      >
        <div>
          {/* <ScrollView width="100%" height={260}> */}
          <TableMultipleEditor
            columns={columnsInformation}
            dataSource={dataSource}
            scroll
          />
          {/* </ScrollView> */}
        </div>
        <div>
          {/* <ScrollView
            width="100%"
            height={dataSource?.length > 3 ? 180 : 'auto'}
          > */}
          <TableMultipleEditor
            columns={columnsReceiving}
            dataSource={dataSource}
            scroll
          />
          {/* </ScrollView> */}
          <div
            style={{
              width: '100%',
              padding: 8,
              backgroundColor: '#F2F4F7',
              borderRadius: 6,
              marginTop: 10,
            }}
          >
            <span>
              *{' '}
              {t(
                'Receiving information/forwarding information includes receiving/forwarding data on the same day',
              )}
            </span>
          </div>
        </div>
        <div>
          {/* <ScrollView
            width="100%"
            height={dataSource?.length > 2 ? 150 : 'auto'}
          > */}
          <TableMultipleEditor
            columns={columnsForwarding}
            dataSource={dataSource}
            scroll
          />
          {/* </ScrollView> */}
          {/* <div
            style={{
              width: '100%',
              padding: 8,
              backgroundColor: '#F2F4F7',
              borderRadius: 6,
              display: 'flex',
              lineHeight: 20,
              flexDirection: 'column',
              marginTop: 10,
            }}
          >
            <span>
              * {t('Forwarding unit price: Pohang is marked as not applicable')}
            </span>
            <span>
              * {t('Changwon/Buchon actually displays forwarding unit price')}
            </span>
          </div> */}
        </div>
        <div />
      </div>
    </>
  );
};

export default ViewBottom;
