/**
 * Update date: 23-05-2023
 * Screen 2023.1
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FormCreate from './FormCreate';
import ListOrder from './ListOrder';
import useDictionary from 'hooks/useDictionary';
import useProfileInfo from 'hooks/useProfileInfo';
const defaultParams = {
  vendor: '',
  orderState: '',
  formStore: {
    vendor: [],
    orderState: [],
  },
};
/**
 * create context
 */
const OrderRegistrationContext = createContext({
  params: {},
  setParams: () => {},
  refetchData: () => {},
  listData: null,
  modeView: {},
  setModeView: () => {},
});

OrderRegistrationContext.displayName = 'OrderRegistrationContext';

export const useOrderRegistration = () => {
  const context = useContext(OrderRegistrationContext);
  return context;
};

function OrderRegistration() {
  const { t }: any = useDictionary({ programId: '2023.1' });
  const userInfo = useProfileInfo();
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    ...defaultParams,
    fromDate: new Date(),
    endDate: new Date(),
  });
  const [modeView, setModeView] = useState({ type: 'list' });
  const [listData, setListData] = useState<any>([]);
  const store: any = new ArrayStore({
    data: listData,
    key: 'ctOrderId',
  });
  const [{ data, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/warehouse/order-registration',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  const [{ data: resOrderState, loading: loadingOrderState }] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
      data: { codeType: 'OT' },
    },
    { manual: false, useCache: false, autoCancel: true },
  );
  /**
   *  render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListOrder ref={dataGridRef} />;
      case 'create':
      case 'edit':
      case 'view':
        return <FormCreate />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * deselect all
   *
   */
  const deselectAll = () => {
    dataGridRef?.current?.instance?.deselectAll();
  };

  /**
   * on update list order
   *
   * @param {*} data
   * @param {*} type
   */
  const onUpdateListOrder = (data, type) => {
    if (data) {
      data.ctOrderStatusNm =
        data?.ctOrderStatus === '0' ? t('Pending') : t('Fixed');
    }
    if (type === 'add') {
      setListData(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
    } else {
      store
        .update(data?.ctOrderId, data)
        .done(() => {})
        .fail(() => {});
    }
    dataGridRef?.current?.instance?.refresh();
  };

  const value: any = {
    t,
    params,
    setParams,
    refetchData,
    store,
    deselectAll,
    modeView,
    setModeView,
    onUpdateListOrder,
    resOrderState,
    userInfo,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      const dataMapping = (data?.data || []).map(o => {
        o.ctOrderStatusNm =
          o?.ctOrderStatus === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setListData(dataMapping);
    }
  }, [data]);

  return (
    <OrderRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingData || loadingOrderState} />
        {renderContent()}
      </React.Suspense>
    </OrderRegistrationContext.Provider>
  );
}

export default OrderRegistration;
