/**
 * Update date: 12-05-2023
 * Screen 1043.1
 */

import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import TabPanelCustom from 'app/components/TabPanel';
import useAxios from 'axios-hooks';
import { ROLE_TYPE } from 'constant';
import Form from 'devextreme-react/form';
import React, { useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useRegistration } from '..';
import useDictionary from 'hooks/useDictionary';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

const ListRegistration = props => {
  const { themePro }: any = useApp();
  const context: any = useRegistration();
  const {
    setModeView,
    store,
    setListData,
    refetchData,
    itemSelected,
    setItemSelected,
    formSearchData,
  } = context;
  const dataGridRef: any = useRef(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState<any>(false);

  const selectedKeys = dataGridRef?.current?.instance?.option().selectedRowKeys;
  const { t }: any = useDictionary({
    programId: '1043.1',
  });

  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: 'user/user',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );
  const [{ loading: loadingApprove }, refetchApprove] = useAxios(
    {
      url: 'user/user/approve',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * on search
   */
  const handleSearch = async () => {
    const res = await refetchData({
      data: {
        username: formSearchData?.userName,
        orderBy: true,
        roleType: ROLE_TYPE?.[itemSelected]?.value,
        orderByName: 'userId',
      },
    });
    setListData(preState => ({
      ...preState,
      [itemSelected]: res?.data?.data,
    }));
  };
  /**
   * on delete
   * @return {*}
   */
  const onDelete = async () => {
    if (!selectedKeys?.length) return;
    const res = await refetchDelete({ data: selectedKeys });
    notification({
      res,
    });
    if (res?.data?.status === '200') {
      selectedKeys?.forEach(userId => {
        store
          .remove(userId)
          .done(() => {})
          .fail(() => {});
      });
      deselectAll();
      setShowModalDelete(false);
    }
  };
  /**
   * on confirm
   * @return {*}
   */
  const handleConfirm = async () => {
    if (!selectedKeys?.length) return;
    const res = await refetchApprove({ data: selectedKeys });
    notification({
      res,
    });
    if (res?.data?.status === '200') {
      selectedKeys?.forEach(userId => {
        store
          .update(userId, {
            status: '2',
          })
          .done(() => {})
          .fail(() => {});
      });
      deselectAll();
      setShowModalConfirm(false);
    }
  };

  /**
   * deselect all
   */
  const deselectAll = () => {
    dataGridRef?.current?.instance?.deselectAll();
  };
  /**
   * render user name
   * @param {*} record
   * @return {*}
   */
  const renderUserName = (record: any) => {
    const { userId } = record?.data || {};
    const type = 'edit';

    return (
      <div
        onClick={() => {
          setModeView({ type, id: userId });
        }}
        className="cell-link"
      >
        {record?.value}
      </div>
    );
  };
  /**
   * render status premium
   * @param {*} e
   * @return {*}
   */
  const renderStatusPremium = (e: any) => {
    switch (e?.value) {
      case '0':
        return (
          <span className="text-unauthorized text-custom-approval">
            {t('Unauthorized')}
          </span>
        );
      case '1':
        return (
          <span className="text-authorized text-custom-approval">
            {t('Authorized')}
          </span>
        );
      case '2':
        return (
          <span className="text-approve text-custom-approval">
            {t('Approve')}
          </span>
        );
      default:
        return null;
    }
  };
  /**
   * render status
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    switch (e?.value) {
      case '0':
        return t('Unauthorized');
      case '1':
        return t('Authorized');
      case '2':
        return t('Approve');
      default:
        return null;
    }
  };

  /**
   * tab render datagrid
   * @param {*} name
   * @return {*}
   */
  const TabRenderDataGrid = name => {
    return (
      <>
        <div className={`${themePro && 'bg-white p-20'}`}>
          <Form
            formData={formSearchData}
            showColonAfterLabel={false}
            items={[
              {
                label: { visible: false },
                dataField: 'userName',
                editorType: 'dxTextBox',
                editorOptions: {
                  showClearButton: true,
                  onEnterKey: handleSearch,
                },
              },
              {
                itemType: 'button',
                horizontalAlignment: 'left',
                buttonOptions: {
                  text: t('Search'),
                  icon: 'search',
                  type: 'default',
                  stylingMode: 'contained',
                  onClick: handleSearch,
                },
              },
            ]}
            labelLocation="top"
            colCount={2}
          />
        </div>
        <br />
        <DataGridFull
          ref={dataGridRef}
          dataSource={store}
          columns={[
            {
              dataField: 'userId',
              caption: t('ID'),
              alignment: 'left',
            },
            {
              dataField: 'name',
              caption: t('Username'),
              alignment: 'left',
              cellRender: renderUserName,
            },
            {
              dataField: 'loginId',
              caption: t('Login ID'),
              alignment: 'left',
            },
            {
              dataField: 'email',
              caption: t('Email'),
              alignment: 'left',
            },
            {
              dataField: 'mobile',
              caption: t('Mobile No'),
              alignment: 'left',
            },
            {
              dataField: 'phone',
              caption: t('Phone No'),
              alignment: 'left',
            },
            {
              dataField: 'status',
              caption: t('Approval'),
              alignment: 'left',
              customizeText: themePro ? null : renderStatus,
              cellRender: themePro ? renderStatusPremium : null,
            },
          ]}
          storageKey="1043.1"
          options={{
            key: 'userId',
            columnAutoWidth: true,
            height: '70vh',
            onToolbarPreparing: (e: any) => {
              e.toolbarOptions.items.unshift(
                {
                  location: 'before',
                  template: 'totalCount',
                },
                {
                  location: themePro ? 'after' : 'before',
                  widget: 'dxButton',
                  options: {
                    icon: 'add',
                    text: t('Create New'),
                    onClick: () => setModeView({ type: 'create' }),
                  },
                },
                {
                  location: themePro ? 'after' : 'before',
                  widget: 'dxButton',
                  options: {
                    icon: 'edit',
                    text: t('Approve'),
                    // disabled: !selectedKeys?.length,
                    onClick: () => setShowModalConfirm(!showModalDelete),
                  },
                },
                {
                  location: themePro ? 'after' : 'before',
                  widget: 'dxButton',
                  options: {
                    icon: 'trash',
                    text: t('Delete'),
                    // disabled: !selectedKeys?.length,
                    onClick: () => setShowModalDelete(!showModalDelete),
                  },
                },
              );
            },
            selection: {
              mode: 'multiple',
              selectAllMode: 'allPages',
              showCheckBoxesMode: 'onClick',
            },
          }}
        />
      </>
    );
  };
  /**
   * render
   */
  return (
    <>
      <LoadPanel visible={loadingDelete || loadingApprove} />
      <BreadCrumbPremium />
      <TabPanelCustom onSelected={setItemSelected} isSelected={itemSelected}>
        {ROLE_TYPE.map((o, i) => (
          <TabRenderDataGrid name={t(o?.name)} key={i} />
        ))}
      </TabPanelCustom>
      <PopupConfirmDelete
        visible={showModalDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onDelete}
        onHiding={() => setShowModalDelete(!showModalDelete)}
      />
      <PopupConfirm
        visible={showModalConfirm}
        content={`${t('Do you want to approve {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={handleConfirm}
        onHiding={() => setShowModalConfirm(false)}
      />
    </>
  );
};

export default ListRegistration;
