/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';

import navReducer from './features/navbar/navSlice';
import authReducer from './features/auth/authSlice';
import registerProgramReducer from './features/registerProgram/registerProgramSlice';
import menuProgramReducer from './features/registerProgram/menuProgramSlice';
import userRegistrationReducer from './features/centerOperator/userRegistrationSlice';
import paginationReducer from './features/pagination/paginationSlice';
import centerOperatorReducer from './features/centerOperator/centerOperatorSlice';
import centerZoneReducer from './features/centerOperator/centerZoneSlice';
import centerRegistrationReducer from './features/centerRegistration/centerRegistrationSlice';
import mainMenuReducer from './features/mainMenu/mainMenuSlice';
import mainMenuFixedReducer from './features/mainMenu/mainMenuFixed';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return state => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}

export const rootReducer = createReducer({
  nav: navReducer,
  auth: authReducer,
  registerProgram: registerProgramReducer,
  menuProgram: menuProgramReducer,
  userRegistration: userRegistrationReducer,
  pagination: paginationReducer,
  centerOperator: centerOperatorReducer,
  centerZone: centerZoneReducer,
  centerRegistration: centerRegistrationReducer,
  mainMenu: mainMenuReducer,
  mainMenuFixed: mainMenuFixedReducer,
});
