/**
 * Update date: 12-06-2023
 * Popup select post code component
 */
import { DataGridFull } from 'app/components/DataGrid';
import { Button, Popup, ScrollView, TextBox } from 'devextreme-react';
import React, { useState, useRef, useEffect } from 'react';
import notify from 'devextreme/ui/notify';
import useDictionary from 'hooks/useDictionary';
import { isMobile } from 'react-device-detect';
import useAxios from 'axios-hooks';
import { useApp } from 'app';
interface IPopupSelectSingleProps {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit: (selected: any, dataVector?: any) => void;
  vector?: boolean;
}

const PopupSelectPostCode = ({
  visible,
  onHiding,
  text,
  onSubmit,
  vector,
}: IPopupSelectSingleProps) => {
  const { themePro }: any = useApp();
  const [keyword, setKeyword] = useState<any>(text);
  const [postCodeData, setPostCodeData] = useState<any>([]);
  const dataRef: any = useRef(null);
  const { t } = useDictionary({});
  const [{}, refetchVector] = useAxios(
    {
      method: 'GET',
    },
    { manual: true },
  );
  /**
   * fetch data
   */
  const fetchData = (keyword: string = '') => {
    fetch(
      `https://www.juso.go.kr/addrlink/addrLinkApi.do?confmKey=U01TX0FVVEgyMDE3MDIyNDEwNDUwNDE5MjUw&currentPage=1&countPerPage=100&keyword=${keyword}&resultType=json`,
    )
      .then(response => {
        if (response.status !== 200) {
          return notify(
            {
              message: t('Get data fail'),
              position: 'top right',
              width: 400,
            },
            'error',
            2000,
          );
        }
        response.json().then(data => {
          if (data.results.common.errorCode !== '0') {
            return notify(
              {
                message: data.results.common.errorMessage,
                position: 'top right',
                width: 400,
              },
              'error',
              2000,
            );
          }
          setPostCodeData(data.results.juso);
        });
      })
      .catch(err => {
        return notify(
          {
            message: t('Get data fail'),
            position: 'top right',
            width: 400,
          },
          'error',
          2000,
        );
      });
  };

  /**
   * on search
   *
   * @param {string} keyword
   */
  const onSearch = (keyword: string) => {
    fetchData(keyword);
  };

  /**
   * on ok
   *
   */
  const onOk = async () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;

    if (!vector) {
      onSubmit(selectedRowKeys?.[0]);
    } else {
      let res: any;
      try {
        res = await refetchVector({
          url: `https://api.vworld.kr/req/address?service=address&request=getcoord&version=2.0&crs=epsg:4326&address=${selectedRowKeys?.[0]?.roadAddrPart1}&refine=true&simple=false&format=json&type=road&key=E3B15819-371A-3770-95B8-BF63E509802E`,
        });
      } catch (error) {}
      onSubmit(selectedRowKeys?.[0], res?.data);
    }
  };

  useEffect(() => {
    onSearch(keyword);
  }, []);

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Post Code')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }
            }
          >
            <TextBox
              width={themePro ? 400 : '40vw'}
              defaultValue={keyword}
              onValueChanged={e => setKeyword(e?.value)}
              onEnterKey={e => onSearch(e?.component?.option('value') || '')}
              style={{ marginRight: 10 }}
            />
            <Button
              icon="search"
              text={t('Search')}
              onClick={() => onSearch(keyword)}
            />
          </div>
          <div className="data-grid">
            <DataGridFull
              ref={dataRef}
              dataSource={postCodeData}
              columns={[
                {
                  dataField: 'roadAddrPart1',
                  caption: t('Address 1'),
                },
                {
                  dataField: 'roadAddrPart2',
                  caption: t('Address 2'),
                },
                {
                  dataField: 'zipNo',
                  caption: t('Zip Code'),
                },
              ]}
              options={{
                onRowDblClick: onOk,
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift({
                    location: 'before',
                    template: 'totalCount',
                  });
                },
                selection: {
                  mode: 'single',
                },
                height: themePro
                  ? 'calc(100vh - 250px)'
                  : isMobile
                  ? 'calc(80vh - 200px)'
                  : 'calc(70vh - 200px)',
                columnAutoWidth: true,
              }}
            />
          </div>
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Post Code')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
};

export default PopupSelectPostCode;
