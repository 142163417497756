/**
 * Update date: 26-05-2023
 * Screen 2042.5
 */
import { AxiosRequestConfig } from 'axios';
import { RefetchOptions } from 'axios-hooks';
import { createContext } from 'react';

const defaultContext = {
  refetchData: (
    config?: AxiosRequestConfig | undefined,
    options?: RefetchOptions | undefined,
  ) => {},
  isLoading: false,
  gridData: [],
};
/**
 * create context
 */
const Context = createContext(defaultContext);

Context.displayName = 'CarryoutInquiry';

export { defaultContext };
export default Context;
