/**
 * Update date: 12-05-2023
 * Screen 1043.1
 */

import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { TreeView } from 'devextreme-react';
import { useApp } from 'app';
import useDictionary from 'hooks/useDictionary';

function TreeViewSelection(props) {
  const { themePro }: any = useApp();
  const { t } = useDictionary({});
  const [listData, setListData] = useState<any>([]);
  const [treeList, setTreeList] = useState<any>({
    left: [],
    right: [],
  });
  const { idSelected, treeViewLeftRef, treeViewRightRef } = props;
  const [{ loading, data: dataTreeView }] = useAxios(
    {
      url: 'core/program/by-role',
      method: 'GET',
    },
    { useCache: false },
  );
  /**
   * render tree view item
   * @param {*} e
   * @return {*}
   */
  const renderTreeViewItem = e => {
    return (
      <div style={{ height: 32, display: 'flex', alignItems: 'center' }}>
        {t(e?.programName)}
      </div>
    );
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (dataTreeView?.data) {
      const { data } = dataTreeView;
      const dataFormat = data?.map(({ parentId, children, ...o }) => {
        return {
          ...o,
          items: children?.map(({ children, ...x }) => {
            return { ...x, items: children };
          }),
        };
      });
      setListData(dataFormat);
    }
  }, [dataTreeView]);
  /**
   * is exist
   *
   * @param {*} id
   * @return {*}
   */
  const isExist = id => {
    return idSelected?.some(s => s === id);
  };
  /**
   * slice data tree view
   *
   * @param {*} list
   * @return {*}
   */
  const sliceDataTreeView = list => {
    list.forEach(o => {
      if (isExist(o?.id)) {
        o.selected = true;
      }
      o?.items?.forEach(n => {
        if (isExist(n?.id)) {
          n.selected = true;
        }
        n?.items?.forEach(m => {
          if (isExist(m?.id)) {
            m.selected = true;
          }
        });
      });
    });
    return list;
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (listData.length) {
      let l = [...listData];
      const half = Math.ceil(l.length / 2);

      const left = themePro
        ? sliceDataTreeView(l)
        : sliceDataTreeView(l.splice(0, half));
      const right = themePro ? [] : sliceDataTreeView(l.splice(-half));

      setTreeList({
        left,
        right,
      });
    }
  }, [idSelected, listData]);

  /**
   * render
   */
  return (
    <div
      style={
        themePro ? {} : { display: 'grid', gridTemplateColumns: '1fr 1fr' }
      }
      className="user-registration-premium"
    >
      <TreeView
        ref={treeViewLeftRef}
        height={themePro ? 'auto' : 250}
        items={treeList?.left}
        selectByClick={true}
        showCheckBoxesMode="normal"
        itemRender={renderTreeViewItem}
      />
      {!themePro && (
        <TreeView
          ref={treeViewRightRef}
          height={themePro ? 'auto' : 250}
          items={treeList?.right}
          selectByClick={true}
          showCheckBoxesMode="normal"
          itemRender={renderTreeViewItem}
        />
      )}
    </div>
  );
}

export default TreeViewSelection;
