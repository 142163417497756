/**
 * Update date: 25-01-2024
 * ESL Design Setting
 */
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import LoadPanel from 'app/components/LoadPanel';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import ImageUploadPremium from 'app/components/Templates/ImageUploadPremium';
import { Form, Template } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useModuleContext } from '..';
import useFormat from 'hooks/useFormat';

function CreateOrUpdate() {
  const context: any = useModuleContext();
  const { t, refetchData, setModeView, modeView } = context;
  const { QtyFormat } = useFormat();
  const [showPopupConfirmCancel, setShowPopupConfirmCancel] = useState(false);
  const [showPopupConfirmSave, setShowPopupConfirmSave] = useState(false);
  const [formData, setFormData] = useState<any>({
    eslDesignId: null,
    designNm: '',
    designCd: '',
    productQty: '',
    designImage: '',
    designType: '',
    sortNo: 1,
  });
  const formRef: any = useRef(null);

  // get ESL design code
  useEffect(() => {
    if (!modeView?.data) return;
    setFormData({ ...(modeView?.data || {}) });
  }, [modeView?.data]);
  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const res = await refetchData({
      url: '/core/esl-design-setting',
      method: 'POST',
      data: formData,
    });
    if (res?.data?.status === '200') {
      notification({ res });
      setShowPopupConfirmSave(false);
      setModeView({ type: 'list', autoLoad: true });
    } else {
      notification({ res });
    }
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    setModeView({ type: 'list', autoLoad: false });
  };

  /**
   * on upload image
   *
   * @param {*} name
   * @param {*} value
   */
  const onUploadImage = (name, value) => {
    formRef?.current?.instance?.updateData(name, value);
  };

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <BreadCrumbPremium
        onSubmit={() => {
          const isValid = formRef?.current?.instance?.validate()?.isValid;
          if (!isValid) return;
          setShowPopupConfirmSave(true);
        }}
        onCancel={() => setShowPopupConfirmCancel(true)}
      />
      <Form
        ref={formRef}
        formData={formData}
        labelLocation="top"
        showColonAfterLabel={false}
        scrollingEnabled={true}
        focusStateEnabled={true}
        hoverStateEnabled={true}
        className="body-padding-white"
        colCount={2}
        items={[
          {
            itemType: 'group',
            items: [
              {
                itemType: 'group',
                items: [
                  {
                    label: { text: t('ID') },
                    dataField: 'eslDesignId',
                    editorType: 'dxTextBox',
                    editorOptions: {
                      disabled: true,
                    },
                  },
                  {
                    label: { text: t('Design Name') },
                    dataField: 'designNm',
                    editorType: 'dxTextBox',
                    isRequired: true,
                  },
                  {
                    label: { text: t('Design Code') },
                    dataField: 'designCd',
                    editorType: 'dxTextBox',
                    isRequired: true,
                  },
                  {
                    label: { text: t('Design Type') },
                    dataField: 'designType',
                    editorType: 'dxTextBox',
                  },
                  {
                    itemType: 'group',
                    colCount: 2,
                    items: [
                      {
                        label: { text: t('Product Qty') },
                        dataField: 'productQty',
                        editorType: 'dxNumberBox',
                        isRequired: true,
                        editorOptions: {
                          format: QtyFormat,
                          step: 0,
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: t('Product Qty is required'),
                          },
                          {
                            type: 'numeric',
                            message: t('Product Qty must be number'),
                          },
                          {
                            type: 'range',
                            min: 1,
                            max: 3,
                            message: t('Product Qty must be from 1 to 3'),
                          },
                        ],
                      },
                      {
                        label: { text: t('Sort No') },
                        dataField: 'sortNo',
                        editorType: 'dxNumberBox',
                        editorOptions: {
                          format: QtyFormat,
                          step: 0,
                          min: 1,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: {
              text: t('Image'),
            },
            itemType: 'simple',
            template: 'formUpload',
            editorOptions: {
              value: formData?.designImage,
            },
          },
        ]}
      >
        <Template
          name="formUpload"
          component={() => (
            <ImageUploadPremium
              value={formData?.designImage}
              onChangeValue={data => onUploadImage('designImage', data)}
              width="100%"
              height={250}
            />
          )}
        />
      </Form>
      {showPopupConfirmSave && (
        <PopupConfirm
          title={t('Confirm Save')}
          visible={showPopupConfirmSave}
          content={t('Are you want to save this design?')}
          onOk={onSubmit}
          onHiding={() => setShowPopupConfirmSave(false)}
        />
      )}
      {showPopupConfirmCancel && (
        <PopupConfirm
          title={t('Confirm Cancel')}
          visible={showPopupConfirmCancel}
          content={t('Are you sure to cancel all the change?')}
          onOk={onCancel}
          onHiding={() => setShowPopupConfirmCancel(false)}
        />
      )}
    </React.Suspense>
  );
}

export default CreateOrUpdate;
