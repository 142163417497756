/**
 * Update date: 25-05-2023
 * Screen 2041.8
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import PopupSelectZone from 'app/components/PopupCommon/PopupSelectZone';
import Form from 'devextreme-react/form';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useReceivingLoad } from '..';

const FormSearch = () => {
  const context: any = useReceivingLoad();
  const { refetchData } = context;
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const [formStore, setFormStore] = useState<any>({
    lgCategory: [],
    mdCategory: [],
    smCategory: [],
    subCategory: [],
    product: [],
    productGroup: [],
    vendor: [],
    zone: [],
  });
  const [formData, setFormData] = useState({
    lgCategory: '',
    mdCategory: '',
    smCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    vendor: '',
    zone: '',
    fromDate: new Date(),
    endDate: new Date(),
  });

  const [typeCommon, setTypeCommon] = useState('');

  const { t, t2 }: any = useDictionary({
    programId: '2041.8',
  });

  /**
   * on change date
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        setFormData(preState => ({ ...preState, fromDate: e?.value }));
        if (formData.endDate && e?.value && e?.value > formData.endDate)
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        setFormData(preState => ({ ...preState, endDate: e?.value }));
        if (formData.fromDate && e?.value && e?.value < formData.fromDate)
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          onFieldDataChanged={onFieldDataChanged}
          colCount={4}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
            {
              label: {
                text: t('Vendor'),
              },
              dataField: 'vendor',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setTypeCommon('vendor'),
                showClearButton: true,
                buttons: [
                  {
                    name: 'vendor0',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.vendor?.[0]?.vendorName,
                      onClick: () =>
                        handleRemove('vendor', formStore?.vendor?.[0]),
                      visible: !!formStore?.vendor?.[0]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'vendor1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.vendor?.[1]?.vendorName,
                      onClick: () =>
                        handleRemove('vendor', formStore?.vendor?.[1]),
                      visible: !!formStore?.vendor?.[1]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'vendorMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.vendor?.length - 2} ${t('more')}`,
                      visible: formStore?.vendor?.length > 2,
                    },
                  },
                  {
                    name: 'clearAllVendor',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.vendor?.length,
                      onClick: () => handleCleanAll('vendor'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('vendor'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Zone') },
              dataField: 'zone',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setTypeCommon('zone'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.zone?.[0]?.zoneName,
                      onClick: () => handleRemove('zone', formStore?.zone?.[0]),
                      visible: !!formStore?.zone?.[0]?.zoneId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.zone?.[1]?.zoneName,
                      onClick: () => handleRemove('zone', formStore?.zone?.[1]),
                      visible: !!formStore?.zone?.[1]?.zoneId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.zone?.length - 2} ${t('more')}`,
                      visible: formStore?.zone?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.zone?.length,
                      onClick: () => handleCleanAll('zone'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('zone'),
                    },
                  },
                ],
              },
            },
          ]}
        ></Form>
      </>
    );
  };

  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'zone':
        name = 'zone';
        break;
      case 'vendor':
        name = 'vendor';
        break;
      default:
        break;
    }
    let newData: any =
      type === 'LC' || type === 'MC' || type === 'SC' || type === 'UC'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.code !== data?.code,
          )
        : type === 'PD'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productId !== data?.productId,
          )
        : type === 'PG'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productGroupId !== data?.productGroupId,
          )
        : type === 'zone'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.zoneId !== data?.zoneId,
          )
        : [...formStore?.[name]].filter(
            (filter: any) => filter?.vendorId !== data?.vendorId,
          );
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
  };

  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'zone':
        name = 'zone';
        break;
      case 'vendor':
        name = 'vendor';
        break;
      default:
        break;
    }
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: [] }));
  };

  /**
   * render advanced search
   *
   * @param {*} name
   * @return {*}
   */
  const AdvancedSearch = name => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'lgCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('LC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[0]),
                      visible: !!formStore?.lgCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[1]),
                      visible: !!formStore?.lgCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.lgCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.lgCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.lgCategory?.length,
                      onClick: () => handleCleanAll('LC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('LC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mdCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('MC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[0]),
                      visible: !!formStore?.mdCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[1]),
                      visible: !!formStore?.mdCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mdCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mdCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mdCategory?.length,
                      onClick: () => handleCleanAll('MC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('MC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('SC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[0]),
                      visible: !!formStore?.smCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[1]),
                      visible: !!formStore?.smCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smCategory?.length,
                      onClick: () => handleCleanAll('SC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('SC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('UC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[0]),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[1]),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => handleCleanAll('UC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('UC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('PG');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[0]),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[1]),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => handleCleanAll('PG'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PG'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('PD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[0]),
                      visible: !!formStore?.product?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[1]),
                      visible: !!formStore?.product?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => handleCleanAll('PD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PD'),
                    },
                  },
                ],
              },
            },
          ]}
        />
      </>
    );
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'LC':
      case 'MC':
      case 'SC':
      case 'UC':
        return (
          <PopupSelectCommonCode
            visible={
              popupType === 'LC' ||
              popupType === 'MC' ||
              popupType === 'SC' ||
              popupType === 'UC'
            }
            text={
              popupType === 'LC'
                ? formData.lgCategory
                : popupType === 'MC'
                ? formData.mdCategory
                : popupType === 'SC'
                ? formData.smCategory
                : popupType === 'UC'
                ? formData.subCategory
                : ''
            }
            onHiding={() => {
              setTypeCommon('');
            }}
            codeType={popupType}
            mode="multiple"
            onSubmit={data => {
              if (popupType === 'LC') {
                setFormData({ ...formData, lgCategory: '' });
                setFormStore({ ...formStore, lgCategory: data });
              }
              if (popupType === 'MC') {
                setFormData({ ...formData, mdCategory: '' });
                setFormStore({ ...formStore, mdCategory: data });
              }
              if (popupType === 'SC') {
                setFormData({ ...formData, smCategory: '' });
                setFormStore({ ...formStore, smCategory: data });
              }
              if (popupType === 'UC') {
                setFormData({ ...formData, subCategory: '' });
                setFormStore({ ...formStore, subCategory: data });
              }
              setTypeCommon('');
            }}
          />
        );
      case 'PG':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'PG'}
            text={formData.productGroup}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, productGroup: '' });
              setFormStore({ ...formStore, productGroup: data });
              setTypeCommon('');
            }}
          />
        );
      case 'PD':
        return (
          <PopupSelectProduct
            visible={popupType === 'PD'}
            text={formData.product}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, product: '' });
              setFormStore({ ...formStore, product: data });
              setTypeCommon('');
            }}
          />
        );
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formData.vendor}
            mode="multiple"
            visible={true}
            onHiding={() => setTypeCommon('')}
            onSubmit={data => {
              setFormData({ ...formData, vendor: '' });
              setFormStore({ ...formStore, vendor: data });
              setTypeCommon('');
            }}
          />
        );
      case 'zone':
        return (
          <PopupSelectZone
            visible={popupType === 'zone'}
            text={formData.zone}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, zone: '' });
              setFormStore({ ...formStore, zone: data });
              setTypeCommon('');
            }}
          />
        );
    }
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      largeCategoryCodes: formStore.lgCategory.map((map: any) => map?.code),
      mediumCategoryCodes: formStore.mdCategory.map((map: any) => map?.code),
      smallCategoryCodes: formStore.smCategory.map((map: any) => map?.code),
      subCategoryCodes: formStore.subCategory.map((map: any) => map?.code),
      productId: formStore.product.map((map: any) => map?.productId),
      productGroupId: formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      vendorId: formStore.vendor.map((map: any) => map?.vendorId),
      zoneId: formStore.zone.map((map: any) => map?.zoneId),
      startDate: formData.fromDate
        ? moment(formData.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData.endDate
        ? moment(formData.endDate).format('YYYY-MM-DD')
        : null,
    };
    refetchData({
      data,
    });
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(typeCommon)}
    </div>
  );
};

export default FormSearch;
