/**
 * Update date: 09-06-2023
 * Navigation component
 */
import { useApp } from 'app';
import { WIDTH_SIDEBAR_COLLAPSE, WIDTH_SIDEBAR_EXPAND } from 'constant';
import { router } from 'constant/router';
import { Tooltip } from 'devextreme-react/tooltip';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectIsLoggedIn } from 'store/features/auth/authSlice';
import { LayoutContext } from '../Layout';
import DynamicMenuDesign from './DynamicMenuDesgin';
import NotAuthMenu from './NotAuthMenu';
import ProgramMenu from './ProgramMenu';
import { showHideNav } from 'store/features/navbar/navSlice';
import ProgramMenuBookmark from './ProgramMenuBookmark';

const classes: any = {
  sidebar: {
    width: WIDTH_SIDEBAR_EXPAND,
  },
  sidebarCollapse: {
    width: WIDTH_SIDEBAR_COLLAPSE,
  },
  sidebarPremium: {
    width: 256,
    // backgroundImage: `url(${navigationImage})`,
  },
};

const NavigationContext = createContext({});

NavigationContext.displayName = 'NavigationContext';

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  return context;
};

function Navigation() {
  const dispatch = useDispatch();
  const { appLogo }: any = useApp();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const layOutContext: any = useContext(LayoutContext);
  const [tooltipExpandVisible, setTooltipExpandVisible] = useState(false);
  const [subMenuSeleted, setSubMenuSeleted] = useState({
    selected: '',
    parentId: '',
  });
  const [listProgram, setListProgram] = useState<any>({
    list: [],
    subMenu: '',
  });
  const [hiddenProgramMenu, setHiddenProgramMenu] = useState(true);
  const [programSeleted, setProgramSeleted] = useState('');
  const [coreMenuSeleted, setCoreMenuSeleted] = useState<string[]>(['']);

  const toggleSidebar = () => {
    layOutContext.setShowProgramMenu(layOutContext.collapse);
    layOutContext.toggleSidebar();
    setTimeout(() => {
      dispatch(showHideNav(layOutContext.collapse));
    }, 500);
  };
  // Toogle expand tooltip
  const toggleExpandTooltip = () => {
    setTooltipExpandVisible(!tooltipExpandVisible);
  };
  /**
   * render child
   *
   * @return {*}
   */
  const renderChild = () => {
    if (isLoggedIn) return <DynamicMenuDesign />;
    return <NotAuthMenu />;
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (layOutContext.collapse) {
      setHiddenProgramMenu(true);
    }
  }, [layOutContext.collapse]);

  useEffect(() => {
    if (hiddenProgramMenu) {
      setListProgram(preState => ({
        list: [],
        subMenu: '',
      }));
    }
  }, [hiddenProgramMenu]);

  const value: any = {
    subMenuSeleted,
    setSubMenuSeleted,
    setListProgram,
    listProgram,
    setProgramSeleted,
    programSeleted,
    coreMenuSeleted,
    setCoreMenuSeleted,
    hiddenProgramMenu,
    setHiddenProgramMenu,
  };

  return (
    <NavigationContext.Provider value={value}>
      <div
        className="sidebar sidebar-premium"
        style={Object.assign(
          {},
          classes.sidebar,
          classes.sidebarPremium,
          layOutContext.collapse && classes.sidebarCollapse,
        )}
      >
        <div className="backgound-premium">
          <div
            className={`sidebar-brand ${
              layOutContext.collapse && 'sidebar-brand-collapse'
            }`}
          >
            {!layOutContext.collapse && (
              <NavLink to={router.home}>
                {appLogo && <img src={appLogo} alt="logo" />}
              </NavLink>
            )}
            <button
              onClick={toggleSidebar}
              className="btn btn-sidebar"
              id="expandBtn"
              onMouseEnter={toggleExpandTooltip}
              onMouseLeave={toggleExpandTooltip}
            >
              <i className="fas fa-bars" />
              <Tooltip
                target="#expandBtn"
                position="right"
                visible={tooltipExpandVisible && layOutContext.collapse}
              >
                <div>Expand</div>
              </Tooltip>
            </button>
          </div>
          <div className="sidebar_register-menu">{renderChild()}</div>
        </div>
        <div
          className="sidebar-program-menu"
          style={
            hiddenProgramMenu
              ? { width: 0, opacity: 0 }
              : subMenuSeleted?.selected || listProgram?.subMenu
              ? { width: 337 }
              : { width: 0, opacity: 0 }
          }
        >
          {listProgram?.isBookmark ? <ProgramMenuBookmark /> : <ProgramMenu />}
        </div>

        {(subMenuSeleted?.selected || listProgram?.subMenu) &&
          !hiddenProgramMenu && (
            <div
              id="outside-sidebar"
              onClick={() => {
                setHiddenProgramMenu(true);
              }}
            ></div>
          )}
      </div>
    </NavigationContext.Provider>
  );
}

export default Navigation;
