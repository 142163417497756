// Update date: 10-05-2023
// Screen 1042.2

import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const List = React.lazy(() => import('./List'));

const defaultParams = {};

// create context
const RegisterDictionaryContext = createContext({});

RegisterDictionaryContext.displayName = 'RegisterDictionaryContext';

export const useRegisterDictionary = () => {
  const context = useContext(RegisterDictionaryContext);
  return context;
};

const RegisterMultilingualDictionary = () => {
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [type, setType] = useState('ui');
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ data: resData, loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: '/core/multi-language',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    modeView,
    setModeView,
    params,
    setParams,
    refetchData,
    data,
    setData,
    type,
    setType,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  useEffect(() => {
    if (resData?.data) {
      setData(resData?.data?.sort((a, b) => a?.base - b?.base));
    }
  }, [resData]);

  useEffect(() => {
    if (data?.length) {
      setData([]);
    }
  }, [type]);
  const loadingAll = loadingfetchData;

  return (
    <RegisterDictionaryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </RegisterDictionaryContext.Provider>
  );
};

export default RegisterMultilingualDictionary;
