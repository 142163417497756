/**
 * Update date: 06-06-2023
 * Screen 2061.1
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const RegisterDirectShippingContext = createContext({});

RegisterDirectShippingContext.displayName = 'RegisterDirectShippingContext';

export const useRegisterDirectShipping = () => {
  return useContext(RegisterDirectShippingContext);
};

const RegisterDirectShippingPriority = () => {
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    date: new Date(),
    sendUser: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    data,
    setData,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  return (
    <RegisterDirectShippingContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        <List />
      </React.Suspense>
    </RegisterDirectShippingContext.Provider>
  );
};

export default RegisterDirectShippingPriority;
