/**
 * Update date: 31-05-2023
 * Screen 2051.8
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import InventoryTable from './TableView';
import LoadPanel from 'app/components/LoadPanel';
import { useProductType } from 'hooks/useProductType';
/**
 * Update date: 15-05-2023
 * Screen 1043.3
 */
const InventoryContext = createContext({
  tableData: {},
  setTableData: () => {},
  refetchData: () => {},
});

InventoryContext.displayName = 'InquiryStoreDepositDeduction';

export const useInventoryContext = () => {
  return useContext(InventoryContext);
};

function InventoryAgeAnalysis() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [tableData, setTableData] = useState<any>([]);

  const [{ data: fetchedData, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/warehouse/inventory-age-analysis',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingData) {
      if (fetchedData?.data) {
        setTableData(fetchedData?.data);
      }
    }
  }, [fetchedData, loadingData]);

  const value: any = {
    productTpPermission,
    tableData,
    setTableData,
    refetchData,
  };

  return (
    <>
      <InventoryContext.Provider value={value}>
        <LoadPanel visible={loadingData || loadingProductTpPermission} />
        <FormSearch />
        <InventoryTable />
      </InventoryContext.Provider>
    </>
  );
}

export default InventoryAgeAnalysis;
