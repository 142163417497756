/**
 * Update date: 26-05-2023
 * Screen 2043.7
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ShipmentScheduleContext = createContext({
  shipmentScheduleListData: [],
});

ShipmentScheduleContext.displayName = 'ShipmentScheduleContext';

export const useShipmentSchedule = () => {
  const context = useContext(ShipmentScheduleContext);
  return context;
};

export default function InquiriesShipmentSchedule() {
  const [shipmentScheduleListData, setShipmentScheduleListData] = useState([]);

  // Get data
  const [
    { data: shipmentScheduleRes, loading: shipmentScheduleLoading },
    refetchData,
  ] = useAxios(
    {
      url: 'ecommerce/orders/same-day-shipment',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const value: any = {
    shipmentScheduleListData,
    setShipmentScheduleListData,
    refetchData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (shipmentScheduleRes?.data) {
      setShipmentScheduleListData(shipmentScheduleRes?.data);
    }
  }, [shipmentScheduleRes]);

  const loadingAll = shipmentScheduleLoading;

  return (
    <ShipmentScheduleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <List />
      </React.Suspense>
    </ShipmentScheduleContext.Provider>
  );
}
