/**
 * Update date: 06-06-2023
 * Screen 2064.9
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import moment from 'moment';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const View = React.lazy(() => import('./View'));
const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const EmptyBottleDepositContext = createContext({
  emptyBottleDepositListData: [],
});

EmptyBottleDepositContext.displayName = 'EmptyBottleDepositContext';

export const useEmptyBottleDeposit = () => {
  const context = useContext(EmptyBottleDepositContext);
  return context;
};

export default function EmptyBottleCarryDepositAPISend() {
  const [emptyBottleDepositListData, setEmptyBottleDepositListData] = useState(
    [],
  );
  const [listSearchParams, setListSearchParams] = useState<any>({
    sendType: 1,
    sendResult: 0,
    fromDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    sendType: 1,
    sendResult: 0,
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
    billId: '',
  });

  // Get data
  const [
    { data: emptyBottleDepositRes, loading: emptyBottleDepositLoading },
    refetchData,
  ] = useAxios(
    {
      url: 'warehouse/container-cosmo',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
        return <Create />;
      case 'view':
        return <View />;
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    modeView,
    setModeView,
    listSearchParams,
    setListSearchParams,
    emptyBottleDepositListData,
    setEmptyBottleDepositListData,
    refetchData,
    formData,
    setFormData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (emptyBottleDepositRes?.data) {
      setEmptyBottleDepositListData(emptyBottleDepositRes?.data);
    }
  }, [emptyBottleDepositRes]);

  const loadingAll = emptyBottleDepositLoading;

  return (
    <EmptyBottleDepositContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </EmptyBottleDepositContext.Provider>
  );
}
