/**
 * Update date: 31-05-2023
 * Screen 2052.3
 */
import React, { useRef, useState } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useLocation } from '..';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import PopupDetail from '../SubTableView';

function TableView(props) {
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({ programId: '2052.3' });
  const { AmountFormat, QtyFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const locationContext: any = useLocation();
  const { tableData } = locationContext;
  const [isShowPopupDetail, setIsShowPopupDetail] = useState<any>({
    isShow: false,
    data: null,
  });

  const columns: any = [
    {
      caption: t('Zone'),
      dataField: 'zone_nm',
      fixed: true,
      fixedPosition: 'left',
      alignment: themePro ? 'left' : 'center',
      showWhenGrouped: true,
    },
    {
      caption: t('Product Code'),
      dataField: 'product_cd',
      fixed: true,
      fixedPosition: 'left',
      alignment: themePro ? 'left' : 'center',
      showWhenGrouped: true,
    },
    {
      caption: t('Product Name'),
      dataField: 'product_nm',
      fixed: true,
      fixedPosition: 'left',
      showWhenGrouped: true,
    },
    {
      caption: t('Product'),
      alignment: 'center',
      items: [
        {
          caption: t('Maker'),
          dataField: 'product_maker_nm',
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          caption: t('Marketable Size'),
          dataField: 'marketable_size',
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          caption: t('Product Cost'),
          dataField: 'product_cost',
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Stock'),
      alignment: 'center',
      items: [
        {
          caption: t('Quantity'),
          dataField: 'stock_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          caption: t('Location Quantity'),
          dataField: 'location_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        // {
        //   caption: t('Cost'),
        //   dataField: 'stock_cost',
        //   dataType: 'number',
        //   format: AmountFormat,
        //   showWhenGrouped: true,
        // },
        {
          caption: t('Order Allocation'),
          dataField: 'assign_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          caption: t('Actual Stock'),
          dataField: 'actualstock_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Receive'),
      alignment: 'center',
      items: [
        {
          caption: t('Quantity'),
          dataField: 'receive_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          caption: t('Amount'),
          dataField: 'receive_amount',
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        // {
        //   caption: t('Cost'),
        //   dataField: 'receive_cost',
        //   dataType: 'number',
        //   format: AmountFormat,
        //   showWhenGrouped: true,
        // },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      items: [
        {
          caption: t('Quantity'),
          dataField: 'forward_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          caption: t('Amount'),
          dataField: 'forward_amount',
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        // {
        //   caption: t('Cost'),
        //   dataField: 'forward_cost',
        //   dataType: 'number',
        //   format: AmountFormat,
        //   showWhenGrouped: true,
        // },
      ],
    },
    {
      caption: t('Adjust'),
      alignment: 'center',
      items: [
        {
          caption: t('Quantity'),
          dataField: 'adjust_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          caption: t('Amount'),
          dataField: 'adjust_amount',
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        // {
        //   caption: t('Cost'),
        //   dataField: 'adjust_cost',
        //   dataType: 'number',
        //   format: AmountFormat,
        //   showWhenGrouped: true,
        // },
      ],
    },
    {
      caption: t('Move'),
      alignment: 'center',
      items: [
        {
          caption: t('Quantity'),
          dataField: 'move_ea',
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          caption: t('Cost'),
          dataField: 'move_cost',
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Origin'),
      dataField: 'product_origin_nm',
      showWhenGrouped: true,
      visible: false,
    },
    {
      caption: t('Default Vendor'),
      dataField: 'vendor_nm_default',
      showWhenGrouped: true,
      visible: false,
    },
    {
      caption: t('Large Category'),
      dataField: 'large_category_nm',
      showWhenGrouped: true,
      visible: false,
    },
    {
      caption: t('Medium Category'),
      dataField: 'medium_category_nm',
      showWhenGrouped: true,
      visible: false,
    },
    {
      caption: t('Small Category'),
      dataField: 'small_category_nm',
      showWhenGrouped: true,
      visible: false,
    },
    {
      caption: t('Sub Category'),
      dataField: 'sub_category_nm',
      showWhenGrouped: true,
      visible: false,
    },
    {
      caption: t('Action'),
      dataField: 'action_grid',
      alignment: 'center',
      showWhenGrouped: true,
      fixedPosition: 'right',
      fixed: true,
      cellRender: (record: any) => {
        const { zone_id, product_id } = record?.data || {};
        return (
          <div
            className="cell-link"
            onClick={() =>
              setIsShowPopupDetail({
                isShow: true,
                data: {
                  sclZoneId: zone_id,
                  sclProductId: product_id,
                },
              })
            }
          >
            {t('Detail')}
          </div>
        );
      },
    },
  ];

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const exportFileName =
    'SMDC_2052.3 Inventory Inquiry by Product/Location ' +
    moment().format('YYYYMMDD');

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={tableData}
        options={{
          key: 'product_cd',
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !tableData,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                column: 'product_cost',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'stock_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'location_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              // {
              //   column: 'stock_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   summaryType: 'sum',
              // },
              {
                column: 'assign_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'receive_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'receive_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              // {
              //   column: 'receive_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   summaryType: 'sum',
              // },
              {
                column: 'forward_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'forward_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              // {
              //   column: 'forward_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   summaryType: 'sum',
              // },
              {
                column: 'adjust_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'adjust_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              // {
              //   column: 'adjust_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   summaryType: 'sum',
              // },
              {
                column: 'move_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                column: 'move_cost',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'product_cost',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'stock_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'location_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              // {
              //   column: 'stock_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   dataType: 'number',
              //   summaryType: 'sum',
              //   alignByColumn: true,
              //   showInGroupFooter: false,
              // },
              {
                column: 'assign_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'actualstock_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'receive_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'receive_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              // {
              //   column: 'receive_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   dataType: 'number',
              //   summaryType: 'sum',
              //   alignByColumn: true,
              //   showInGroupFooter: false,
              // },
              {
                column: 'forward_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'forward_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              // {
              //   column: 'forward_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   dataType: 'number',
              //   summaryType: 'sum',
              //   alignByColumn: true,
              //   showInGroupFooter: false,
              // },
              {
                column: 'adjust_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'adjust_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              // {
              //   column: 'adjust_cost',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   dataType: 'number',
              //   summaryType: 'sum',
              //   alignByColumn: true,
              //   showInGroupFooter: false,
              // },
              {
                column: 'move_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'move_cost',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
      {isShowPopupDetail?.isShow && (
        <PopupDetail
          visible={isShowPopupDetail?.isShow}
          data={isShowPopupDetail?.data}
          onHiding={() => {
            setIsShowPopupDetail({ isShow: false, data: null });
          }}
        />
      )}
    </>
  );
}

export default TableView;
