/**
 * Update date: 06-06-2023
 * Screen 2061.4
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useEffect, useState } from 'react';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const RegistrationDirectDeliveryContext = createContext({
  directDeliveryData: {},
});

RegistrationDirectDeliveryContext.displayName =
  'RegistrationDirectDeliveryContext';

export const useRegistrationDirectDelivery = () => {
  const context = useContext(RegistrationDirectDeliveryContext);
  return context;
};

export default function RegistrationDirectDelivery() {
  const [directDeliveryData, setCenterReceivingData] = useState({});
  const [isSubmitFired, setIsSubmitFired] = useState(false);
  const [resData, setResData] = useState<any>(null);

  // Get data
  const [
    { data: directDeliveryRes, loading: directDeliveryLoading },
    refetchData,
  ] = useAxios(
    {
      url: 'ecommerce/direct-delivery/list',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (resData?.data) {
      const data = resData?.data?.list
        ?.sort((a, b) => a?.lineId - b?.lineId)
        ?.map((o, i) => ({
          ...o,
          ID: i + 1,
          status: 'normal',
        }));

      setCenterReceivingData({
        ...resData?.data,
        list: data,
      });
    } else {
      setCenterReceivingData({});
    }
  }, [resData]);

  useEffect(() => {
    if (directDeliveryRes?.status != 200) {
      return;
    }
    setResData(directDeliveryRes);
  }, [directDeliveryRes]);

  const value: any = {
    directDeliveryData,
    setCenterReceivingData,
    refetchData,
    isSubmitFired,
    setIsSubmitFired,
    resData,
    setResData,
  };
  return (
    <RegistrationDirectDeliveryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={directDeliveryLoading} />
        <List />
      </React.Suspense>
    </RegistrationDirectDeliveryContext.Provider>
  );
}
