/**
 * Update date: 17-05-2023
 * Screen 2011.7
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext, useEffect } from 'react';
import useDictionary from 'hooks/useDictionary';
import { useProductType } from 'hooks/useProductType';

const defaultSearchParams = {
  productNames: '',
  productDisplay: '',
  dataList: {
    mall: [],
    largeCategory: [],
    mediumCategory: [],
    smallCategory: [],
    subCategory: [],
    productGroup: [],
    vendorIds: [],
    productMakers: [],
    productTypes: [],
  },
  mall: '',
  largeCategory: '',
  mediumCategory: '',
  smallCategory: '',
  subCategory: '',
  productGroup: '',
  vendorIds: '',
  productMakers: '',
  productTypes: '',
  tradeType: '0',
};

const List = React.lazy(() => import('./List'));
const MallImages = React.lazy(() => import('./MallImage'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2011_7_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2011_7 = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({});
  // stored data grid after search
  const [data, setData] = useState([]);
  // stored data form search
  const [formSearchData, setFormSearchData] = useState<any>({
    ...defaultSearchParams,
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    id: null,
  });
  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productTypes: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
  };
  /**
   *
   * @returns render content
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'content':
        return <MallImages />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingProductTpPermission} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2011_7;
