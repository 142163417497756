/**
 * Update date: 17-05-2023
 * Screen 2011.5
 */
import React, { useState, useRef, useEffect } from 'react';
import { uniqBy } from 'lodash';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import { useProductMaster } from '..';

const FormSearch = () => {
  const context: any = useProductMaster();
  const { t, productTpPermission, refetchData } = context;
  const formRef: any = useRef(null);
  const [formStore, setFormStore] = useState<any>({
    lgCategory: [],
    mdCategory: [],
    smCategory: [],
    subCategory: [],
    productGroup: [],
    product: [],
    maker: [],
    vendor: [],
  });
  const [formData, setFormData] = useState<any>({
    lgCategory: '',
    mdCategory: '',
    smCategory: '',
    subCategory: '',
    product: '',
    maker: '',
    productGroup: '',
    use: '',
    vendor: '',
    keyword: '',
    optionUse: 1,
    tradeType: '',
  });

  const [isShowPopupCommon, setIsShowPopupCommon] = useState(false);
  const [typeCommon, setTypeCommon] = useState('');
  const [isShowPopupProductGroup, setIsShowPopupProductGroup] = useState(false);
  const [isShowPopupProduct, setIsShowPopupProduct] = useState(false);
  const [isShowPopupVendor, setIsShowPopupVendor] = useState(false);

  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'VD':
        name = 'vendor';
        break;
      case 'MK':
        name = 'maker';
        break;
      case 'PT':
        name = 'product';
        break;
      default:
        break;
    }
    let newData: any =
      type === 'LC' ||
      type === 'MC' ||
      type === 'SC' ||
      type === 'UC' ||
      type === 'PT' ||
      type === 'MK'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.code !== data?.code,
          )
        : type === 'PG'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productGroupId !== data?.productGroupId,
          )
        : [...formStore?.[name]].filter(
            (filter: any) => filter?.vendorId !== data?.vendorId,
          );
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
  };
  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PT':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'VD':
        name = 'vendor';
        break;
      case 'MK':
        name = 'maker';
        break;
      default:
        break;
    }
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: [] }));
  };

  /**
   * on show popup product
   *
   * @param {*} type
   */
  const handleShowPopupProduct = type => {
    setIsShowPopupProduct(true);
    setTypeCommon(type);
  };

  /**
   * on show popup common
   *
   * @param {*} type
   */
  const handleShowPopupCommon = type => {
    setIsShowPopupCommon(true);
    setTypeCommon(type);
  };

  /**
   * on show popup product group
   *
   * @param {*} type
   */
  const handleShowPopupProductGroup = type => {
    setIsShowPopupProductGroup(true);
    setTypeCommon(type);
  };

  /**
   * on show popup vendor
   *
   * @param {*} type
   */
  const handleShowPopupVendor = type => {
    setIsShowPopupVendor(true);
    setTypeCommon(type);
  };

  /**
   * on close popup
   *
   */
  const closePopup = () => {
    setTypeCommon('');
    setIsShowPopupCommon(false);
    setIsShowPopupProduct(false);
    setIsShowPopupProductGroup(false);
    setIsShowPopupVendor(false);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          items={[
            {
              cssClass: 'default-input-language',
              label: { text: t('Product Type') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('PT');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.product?.[0]),
                      visible: !!formStore?.product?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.product?.[1]),
                      visible: !!formStore?.product?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => handleCleanAll('PT'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('PT'),
                    },
                  },
                ],
              },
            },
            {
              cssClass: 'default-input-language',
              label: { text: t('Maker') },
              dataField: 'maker',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('MK');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.maker?.[0]?.name,
                      onClick: () => handleRemove('MK', formStore?.maker?.[0]),
                      visible: !!formStore?.maker?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.maker?.[1]?.name,
                      onClick: () => handleRemove('MK', formStore?.maker?.[1]),
                      visible: !!formStore?.maker?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.maker?.length - 2} ${t('more')}`,
                      visible: formStore?.maker?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.maker?.length,
                      onClick: () => handleCleanAll('MK'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('MK'),
                    },
                  },
                ],
              },
            },
            {
              cssClass: 'default-input-language',
              label: { text: t('Product Keyword') },
              dataField: 'keyword',
              editorType: 'dxTextBox',
            },
            {
              cssClass: 'default-input-language',
              label: { text: t('Vendor') },
              dataField: 'vendor',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupVendor('VD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.vendor?.[0]?.vendorName,
                      onClick: () => handleRemove('VD', formStore?.vendor?.[0]),
                      visible: !!formStore?.vendor?.[0]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.vendor?.[1]?.vendorName,
                      onClick: () => handleRemove('VD', formStore?.vendor?.[1]),
                      visible: !!formStore?.vendor?.[1]?.vendorId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.vendor?.length - 2} ${t('more')}`,
                      visible: formStore?.vendor?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.vendor?.length,
                      onClick: () => handleCleanAll('VD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupVendor('VD'),
                    },
                  },
                ],
              },
            },
            {
              label: {
                text: t('Option use'),
              },
              dataField: 'optionUse',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: 1,
                items: [
                  { label: t('All'), value: 0 },
                  { label: t('Use'), value: 1 },
                  { label: t('Not use'), value: 2 },
                ],
              },
            },
            {
              label: { text: t('Trade Type') },
              dataField: 'tradeType',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'name',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  {
                    name: t('All'),
                    value: '',
                  },
                  {
                    name: t('Traded'),
                    value: '0',
                  },
                  {
                    name: t('Non Traded'),
                    value: '1',
                  },
                ],
              },
            },
          ]}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          ref={formRef}
          items={[
            {
              cssClass: 'default-input-language',
              label: { text: t('Large Category') },
              dataField: 'lgCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('LC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[0]),
                      visible: !!formStore?.lgCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[1]),
                      visible: !!formStore?.lgCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.lgCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.lgCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.lgCategory?.length,
                      onClick: () => handleCleanAll('LC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('LC'),
                    },
                  },
                ],
              },
            },
            {
              cssClass: 'default-input-language',
              label: { text: t('Medium Category') },
              dataField: 'mdCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('MC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[0]),
                      visible: !!formStore?.mdCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[1]),
                      visible: !!formStore?.mdCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mdCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mdCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mdCategory?.length,
                      onClick: () => handleCleanAll('MC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('MC'),
                    },
                  },
                ],
              },
            },
            {
              cssClass: 'default-input-language',
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupProductGroup('PG');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[0]),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[1]),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => handleCleanAll('PG'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupProductGroup('PG'),
                    },
                  },
                ],
              },
            },
            {
              cssClass: 'default-input-language',
              label: { text: t('Small Category') },
              dataField: 'smCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('SC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[0]),
                      visible: !!formStore?.smCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[1]),
                      visible: !!formStore?.smCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smCategory?.length,
                      onClick: () => handleCleanAll('SC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('SC'),
                    },
                  },
                ],
              },
            },
            {
              cssClass: 'default-input-language',
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  handleShowPopupCommon('UC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[0]),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[1]),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => handleCleanAll('UC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => handleShowPopupCommon('UC'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };

  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    let name = '';
    switch (typeCommon) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PT':
        name = 'product';
        break;
      case 'MK':
        name = 'maker';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'VD':
        name = 'vendor';
        break;
      default:
        break;
    }
    let newData: any =
      typeCommon === 'LC' ||
      typeCommon === 'MC' ||
      typeCommon === 'SC' ||
      typeCommon === 'MK' ||
      typeCommon === 'PT' ||
      typeCommon === 'UC'
        ? uniqBy([...formStore?.[name]].concat(data), 'code')
        : typeCommon === 'PG'
        ? uniqBy([...formStore?.[name]].concat(data), 'productGroupId')
        : uniqBy([...formStore?.[name]].concat(data), 'vendorId');

    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
    closePopup();
  };

  /**
   * on sumbit
   *
   * @return {*}
   */
  const onSubmit = () => {
    //API 's parameter go changed here
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      largeCategories: formStore.lgCategory.map((map: any) => map?.code),
      mediumCategories: formStore.mdCategory.map((map: any) => map?.code),
      smallCategories: formStore.smCategory.map((map: any) => map?.code),
      subCategories: formStore.subCategory.map((map: any) => map?.code),
      productGroupIds: formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      makerCds: formStore.maker.map((map: any) => map?.code),
      productTypes: formStore.product.map((map: any) => map?.code),
      vendorIds: formStore.vendor.map((map: any) => map?.vendorId),
      keyword: formData.keyword,
      optionUse: formData?.optionUse,
      tradeType: formData?.tradeType,
    };
    refetchData({
      data,
    });
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormStore(preState => ({
      ...preState,
      product: productTpPermission,
    }));
    setFormData(preState => ({
      ...preState,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {isShowPopupCommon && (
        <PopupSelectCommonCode
          visible={isShowPopupCommon}
          text={
            typeCommon === 'LC'
              ? formData.lgCategory
              : typeCommon === 'MC'
              ? formData.mdCategory
              : typeCommon === 'SC'
              ? formData.smCategory
              : typeCommon === 'UC'
              ? formData.subCategory
              : typeCommon === 'MK'
              ? formData.maker
              : typeCommon === 'PT'
              ? formData.product
              : ''
          }
          onHiding={() => {
            closePopup();
          }}
          codeType={typeCommon}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
      {/* <PopupSelectProduct
        visible={isShowPopupProduct}
        text={formData.product}
        onHiding={() => {
          closePopup();
        }}
        mode="multiple"
        onSubmit={handleSubmitPopup}
      /> */}
      {isShowPopupProductGroup && (
        <PopupSelectProductGroup
          visible={isShowPopupProductGroup}
          text={formData.productGroup}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
      {isShowPopupVendor && (
        <PopupSelectVendor
          visible={isShowPopupVendor}
          text={formData.vendor}
          onHiding={() => {
            closePopup();
          }}
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
    </div>
  );
};

export default FormSearch;
