/**
 * Update date: 31-05-2023
 * Screen 2046.7
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useAxios from 'axios-hooks';
import useFormatTime from 'hooks/useFormatTime';
import React, { forwardRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useCenterReturn } from '..';
import FormSearch from '../FormSearch';
import useDictionary from 'hooks/useDictionary';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
function ListCenter(props, ref) {
  const context: any = useCenterReturn();
  const { setModeView, store, deselectAll } = context || {};
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { DateFormat } = useFormatTime();
  const selectedKeys = ref?.current?.instance?.option().selectedRowKeys;
  const { t }: any = useDictionary({
    programId: '2046.7',
  });
  const [{}, refetchDelete] = useAxios(
    {
      url: '/warehouse/return-control',
      method: 'DELETE',
    },
    { manual: true },
  );

  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    const { returnControlId } = record?.data || {};
    return (
      <div
        onClick={() => {
          setModeView({ type: 'edit', id: returnControlId });
        }}
        className="cell-link"
      >
        {t('Edit')}
      </div>
    );
  };

  const columns: any = [
    {
      dataField: 'returnControlId',
      caption: t('ID'),
      alignment: 'left',
      fixedPosition: 'left',
      fixed: true,
    },
    {
      dataField: 'returnControlName',
      caption: t('Return Control'),
      alignment: 'left',
      fixedPosition: 'left',
      fixed: true,
    },
    {
      dataField: 'regularReturnTf',
      caption: t('Regular Return'),
      alignment: 'center',
      editorType: 'dxSwitch',
      trueText: t('Use'),
      falseText: t('Not Use'),
    },
    {
      caption: t('Sales Date'),
      alignment: 'center',
      items: [
        {
          caption: t('From'),
          dataField: 'salesStartDate',
          dataType: 'date',
          format: DateFormat,
        },
        {
          caption: t('To'),
          dataField: 'salesEndDate',
          dataType: 'date',
          format: DateFormat,
        },
      ],
    },
    {
      caption: t('Return Start Date'),
      alignment: 'center',
      items: [
        {
          caption: t('From'),
          dataField: 'returnStartDate',
          dataType: 'date',
          format: DateFormat,
        },
        {
          caption: t('To'),
          dataField: 'returnEndDate',
          dataType: 'date',
          format: DateFormat,
        },
      ],
    },
    {
      dataField: 'use',
      caption: t('Use'),
      alignment: 'center',
      editorType: 'dxSwitch',
      trueText: t('Use'),
      falseText: t('Not Use'),
    },
    {
      caption: t('Action'),
      fixedPosition: 'right',
      fixed: true,
      cellRender: renderAction,
    },
  ];

  /**
   * on delete
   *
   */
  const onDelete = async () => {
    const res = await refetchDelete({ data: selectedKeys });
    notification({
      res,
    });
    if (res?.data?.status === '200') {
      selectedKeys?.forEach(returnControlId => {
        store
          .remove(returnControlId)
          .done(() => {})
          .fail(() => {});
      });
      deselectAll();
      setShowModalDelete(false);
    }
  };

  return (
    <div>
      <BreadCrumbPremium />
      <FormSearch />
      <DataGridFull
        ref={ref}
        dataSource={store}
        columns={columns}
        storageKey="2046.7"
        options={{
          key: 'returnControlId',
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Create New'),
                  onClick: () => setModeView({ type: 'create' }),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  // disabled: !selectedKeys?.length,
                  onClick: () => setShowModalDelete(!showModalDelete),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
          paging: {
            pageSize: 20,
          },
        }}
      />
      <PopupConfirmDelete
        visible={showModalDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onDelete}
        onHiding={() => setShowModalDelete(!showModalDelete)}
      />
    </div>
  );
}

export default forwardRef(ListCenter);
