// Update date: 10-05-2023
// Screen 1041.1

import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import ImageUpload from 'app/components/Templates/ImageUpload';
import { iconC, iconE, iconR, iconJ } from 'images';
import useAxios from 'axios-hooks';
import { Button, Form, Template } from 'devextreme-react';
import { forwardRef, useEffect, useState } from 'react';
import { notification } from 'utils/notification';
import { useRegisterProgram } from '..';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import ImageUploadPremium from 'app/components/Templates/ImageUploadPremium';

const defaultFormData = {
  orders: 0,
  parentId: '',
  programIcon: '',
  programId: '',
  programIdNew: '',
  programName: '',
  programTheme: '',
  programType: '',
  programUrl: '',
  adminOnly: false,
  pdaTf: false,
  rfidPdaUseTf: false,
  useTf: true,
};

function FormCreate(props, ref) {
  const context: any = useRegisterProgram();
  const { themePro }: any = useApp();
  const { treeList, selected, onDelete, onUpdate } = context;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [formData, setFormData] = useState<any>({
    ...defaultFormData,
  });
  const [viewFormUpload, setViewFormUpload] = useState<any>();
  const { t }: any = useDictionary({
    programId: '1041.1',
  });

  const [{}, refetchCreateUpdate] = useAxios(
    {
      url: '/core/program',
      method: 'PUT',
    },
    { manual: true },
  );
  const [{}, refetchDelete] = useAxios(
    {
      url: '/core/program',
      method: 'DELETE',
    },
    { manual: true },
  );
  const contentName = () => {
    if (selected?.type) {
      const { data } = selected;
      switch (selected?.type) {
        case 'edit':
        case 'create':
          return `${selected?.type === 'edit' ? t('Edit') : t('Create')} ${
            data?.level === 3 ? t('Program') : t('Menu')
          } ${selected?.type === 'create' ? ' for' : ''} ${data?.programName}`;

        default:
          return '';
      }
    }
  };
  // on submit
  const handleSubmit = async () => {
    const isValid = ref?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      programIdOld: selected?.type === 'edit' ? formData.programIdOld : null,
      programIdNew: formData.programId,
      programName: formData.programName,
      programType: formData.programType || '',
      programUrl: formData.programUrl || '',
      adminOnly: formData.adminOnly,
      programTheme: formData.programTheme || '',
      programIcon: formData.programIcon || '',
      parentId: formData.parentId || '0',
      orders: formData.orders,
      pdaTf: formData.pdaTf,
      rfidPdaUseTf: formData.rfidPdaUseTf,
      useTf: formData.useTf,
    };
    const res = await refetchCreateUpdate({
      data,
    });
    notification({ res });
    if (res?.data?.status === '201') {
      const { data } = res?.data;
      const newData = {
        ...selected?.data,
        id: data.programId,
        level:
          selected?.type === 'edit'
            ? selected?.data?.level
            : selected?.data?.level + 1,
        expanded: false,
        programId: data.programId,
        programName: data.programName,
        programType: data.programType || '',
        programUrl: data.programUrl || '',
        adminOnly: data.adminOnly,
        programTheme: data.programTheme || '',
        programIcon: data.programIcon || '',
        parentId: data.parentId || '0',
        orders: data.orders,
        pdaTf: data.pdaTf,
        rfidPdaUseTf: data.rfidPdaUseTf,
        useTf: data.useTf,
      };
      if (newData?.level === 1) {
        delete newData.parentId;
      }
      const programIdOld =
        selected?.type === 'edit' ? formData.programIdOld : null;
      onUpdate(selected?.type, newData, programIdOld);
    }
  };
  // on delete
  const handleDelete = async () => {
    const isChild = treeList.some(
      (o: any) => o.parentId === selected?.data?.programId,
    );
    if (isChild) {
      setShowModalDelete(false);
      return notification({
        message: t('This item has children! Can not delete'),
        type: 'error',
      });
    }
    const res = await refetchDelete({
      url: `/core/program/${selected?.data?.programId}`,
    });
    notification({ res });
    if (res?.data?.status === '200') {
      setShowModalDelete(false);
      onDelete(selected?.data?.programId);
    }
  };
  // generate form upload
  const generateFormUpload = pdaTf => {
    setViewFormUpload(pdaTf);
  };

  const onFieldDataChanged = e => {
    if (e?.dataField === 'pdaTf') {
      generateFormUpload(e?.value);
    }
  };

  const onChangeValue = value => {
    ref?.current?.instance?.updateData('programIcon', value);
  };
  // form core menu
  const FormCoreMenu = () => {
    return (
      <Form
        ref={ref}
        labelLocation="top"
        showColonAfterLabel={false}
        formData={formData}
        items={[
          {
            itemType: 'group',
            items: [
              {
                dataField: 'programId',
                editorType: 'dxTextBox',
                label: {
                  text: t('Program ID'),
                },
                isRequired: true,
              },
              {
                dataField: 'programName',
                editorType: 'dxTextBox',
                label: {
                  text: t('Program Name'),
                },
                isRequired: true,
              },
              {
                dataField: 'programTheme',
                editorType: 'dxColorBox',
                label: {
                  text: t('Select Theme'),
                },
                editorOptions: {
                  editAlphaChannel: true,
                },
              },
            ],
          },
          {
            itemType: 'group',
            items: [
              {
                label: {
                  text: ' ',
                },
                dataField: 'programIcon',
                template: 'ImageUpload',
              },
            ],
          },
        ]}
        colCount={2}
      >
        <Template
          name="ImageUpload"
          component={() =>
            themePro ? (
              <ImageUploadPremium
                value={formData?.programIcon}
                onChangeValue={onChangeValue}
              />
            ) : (
              <ImageUpload
                value={formData?.programIcon}
                onChangeValue={onChangeValue}
              />
            )
          }
        />
      </Form>
    );
  };
  // form sub menu
  const FormSubMenu = () => {
    return (
      <Form
        ref={ref}
        labelLocation="top"
        showColonAfterLabel={false}
        formData={formData}
        items={[
          {
            itemType: 'group',
            items: [
              {
                dataField: 'programId',
                editorType: 'dxTextBox',
                label: {
                  text: t('Program ID'),
                },
                isRequired: true,
              },
              {
                dataField: 'programName',
                editorType: 'dxTextBox',
                label: {
                  text: t('Program Name'),
                },
                isRequired: true,
              },
            ],
          },
          {
            itemType: 'group',
            items: [
              {
                label: {
                  text: ' ',
                },
                dataField: 'programIcon',
                template: 'ImageUpload',
              },
            ],
          },
        ]}
        colCount={2}
      >
        <Template
          name="ImageUpload"
          component={() =>
            themePro ? (
              <ImageUploadPremium
                value={formData?.programIcon}
                onChangeValue={onChangeValue}
              />
            ) : (
              <ImageUpload
                value={formData?.programIcon}
                onChangeValue={onChangeValue}
              />
            )
          }
        />
      </Form>
    );
  };
  // form program
  const FormProgram = () => {
    return (
      <Form
        ref={ref}
        labelLocation="top"
        showColonAfterLabel={false}
        formData={formData}
        onFieldDataChanged={onFieldDataChanged}
        items={[
          {
            itemType: 'group',
            items: [
              {
                dataField: 'programId',
                editorType: 'dxTextBox',
                label: {
                  text: t('Program ID'),
                },
                isRequired: true,
              },
              {
                dataField: 'programName',
                editorType: 'dxTextBox',
                label: {
                  text: t('Program Name'),
                },
                isRequired: true,
              },
              {
                dataField: 'programType',
                editorType: 'dxSelectBox',
                label: {
                  text: t('Program Type'),
                },
                isRequired: true,
                editorOptions: {
                  displayExpr: 'text',
                  valueExpr: 'value',
                  items: [
                    {
                      text: t('Entry'),
                      value: 'E',
                      iconUrl: iconE,
                    },
                    {
                      text: t('Report'),
                      value: 'R',
                      iconUrl: iconR,
                    },
                    {
                      text: t('Code'),
                      value: 'C',
                      iconUrl: iconC,
                    },
                    {
                      text: t('Job Batch'),
                      value: 'J',
                      iconUrl: iconJ,
                    },
                  ],
                },
              },
              {
                dataField: 'programUrl',
                editorType: 'dxTextBox',
                label: {
                  text: t('Program URL'),
                },
                isRequired: true,
              },
              {
                dataField: 'useTf',
                editorType: 'dxSwitch',
                label: {
                  text: t('Use'),
                },
              },
              {
                dataField: 'adminOnly',
                editorType: 'dxSwitch',
                label: {
                  text: t('System Admin Use Only'),
                },
              },
              {
                dataField: 'pdaTf',
                editorType: 'dxSwitch',
                label: {
                  text: t('PDA'),
                },
              },
              {
                dataField: 'rfidPdaUseTf',
                editorType: 'dxSwitch',
                label: {
                  text: t('RFID PDA'),
                },
              },
            ],
          },
          {
            itemType: 'group',
            visible: viewFormUpload,
            items: [
              {
                label: {
                  text: ' ',
                },
                dataField: 'programIcon',
                template: 'ImageUpload',
              },
            ],
          },
        ]}
        colCount={2}
      >
        <Template
          name="ImageUpload"
          component={() =>
            themePro ? (
              <ImageUploadPremium
                value={formData?.programIcon}
                onChangeValue={onChangeValue}
              />
            ) : (
              <ImageUpload
                value={formData?.programIcon}
                onChangeValue={onChangeValue}
              />
            )
          }
        />
      </Form>
    );
  };
  const renderContent = () => {
    const level =
      selected?.type === 'edit'
        ? selected?.data?.level
        : selected?.data?.level + 1;
    switch (level) {
      case 1:
        return <FormCoreMenu />;
      case 2:
        return <FormSubMenu />;
      case 3:
        return <FormProgram />;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (selected?.data) {
      const { type } = selected;
      switch (type) {
        case 'edit':
          setFormData({
            ...selected?.data,
            programIdOld: selected?.data.programId,
          });
          generateFormUpload(selected?.data?.pdaTf);
          break;
        case 'create':
          setFormData({
            ...defaultFormData,
            parentId: selected?.data?.programId,
            programIcon: '',
          });
          break;
        default:
          break;
      }
    }
  }, [selected?.data, selected?.type]);

  return (
    <div
      style={
        themePro
          ? {
              padding: 20,
              backgroundColor: '#fff',
              borderRadius: 6,
              width: 'calc(100% - 420px)',
            }
          : { padding: 20, flexGrow: 1 }
      }
    >
      {selected?.type && (
        <>
          {themePro ? (
            <div className="my-page-caption">
              <div>
                <div className="my-page-caption-title">{contentName()}</div>
              </div>
              <div>
                <Button
                  icon="check"
                  stylingMode="contained"
                  type="default"
                  text={t('Save')}
                  onClick={() => handleSubmit()}
                  style={{ marginRight: 5 }}
                />
                <Button
                  icon="trash"
                  stylingMode="contained"
                  text={t('Delete')}
                  onClick={() => setShowModalDelete(true)}
                />
              </div>
            </div>
          ) : (
            <h4>{contentName()}</h4>
          )}
          <br />
          {renderContent()}
          <br />
          {!themePro && (
            <div style={{ textAlign: 'center' }}>
              <Button
                text={t('Save')}
                type="normal"
                stylingMode="contained"
                style={{ marginRight: 10 }}
                onClick={() => handleSubmit()}
              />
              <Button
                text={t('Delete')}
                type="normal"
                stylingMode="contained"
                onClick={() => setShowModalDelete(true)}
              />
            </div>
          )}
        </>
      )}
      <PopupConfirmDelete
        visible={showModalDelete}
        title={themePro ? t('Delete program') : ''}
        content={`${t('Do you want to delete {0}?')}`.replace(
          '{0}',
          selected?.data?.programName,
        )}
        onOk={handleDelete}
        onHiding={() => setShowModalDelete(!showModalDelete)}
      />
    </div>
  );
}

export default forwardRef(FormCreate);
