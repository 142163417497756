import { SUCCESS_STATUS_1 } from './../constant/index';
import notify from 'devextreme/ui/notify';
interface PropTypes {
  res?: any;
  message?: string;
  type?: 'success' | 'warning' | 'error' | undefined;
  displayTime?: number;
}
const notification = ({
  res,
  message,
  type,
  displayTime = 3000,
}: PropTypes) => {
  if (!res && !message) return;
  let messNoti: any = '';
  let typeNoti = type;
  if (!type) {
    if (SUCCESS_STATUS_1.includes(+(res?.status || -999))) {
      typeNoti = SUCCESS_STATUS_1.includes(+res?.data?.status)
        ? 'success'
        : 'error';
      if (res?.data?.status == '400') {
        const dataError = res?.data?.error || {};
        const keyError = Object.keys(dataError);
        if (keyError?.length) {
          messNoti = keyError
            .map(key => `(${key}) ${dataError[key]}`)
            .join('\n');
        } else {
          messNoti = res?.data?.message;
        }
      } else {
        messNoti = res?.data?.message;
      }
    } else {
      typeNoti = SUCCESS_STATUS_1.includes(+(res?.status || -999))
        ? 'success'
        : 'error';
    }
  }

  return notify(
    {
      message: message || messNoti || res?.message,
      position: 'top right',
      width: 400,
    },
    typeNoti,
    displayTime,
  );
};

export { notification };
