/**
 * Update date: 15-05-2023
 * Screen 1043.3
 */
import React, { useState, memo } from 'react';
import Form from 'devextreme-react/form';
import { isEqual } from 'lodash';
import Collapse from 'app/components/Collapse';
import { useApp } from 'app';
interface IFormSearch {
  params?: any;
  setParams?: any;
  refetch?: any;
  t: any;
}

const FormSearch = ({ params, setParams, refetch, t }: IFormSearch) => {
  const { themePro }: any = useApp();
  const [formData] = useState({
    zoneNm: params?.zoneNm,
    zoneUseTf: params?.zoneUseTf,
  });

  const onSubmit = () => refetch();

  /**
   * on field data changed
   *
   */
  const onFieldDataChanged = () => {
    const newParams = { ...params, ...formData };
    if (!isEqual(params, newParams)) {
      setParams && setParams(newParams);
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*} 
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          onFieldDataChanged={onFieldDataChanged}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Zone') },
              dataField: 'zoneNm',
            },
            {
              label: { text: t('Use') },
              dataField: 'zoneUseTf',
              editorType: 'dxTagBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                items: [
                  {
                    label: t('Use'),
                    value: true,
                  },
                  {
                    label: t('Not Use'),
                    value: false,
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={themePro ? 2 : 3}
        />
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
    </div>
  );
};

export default memo(FormSearch);
