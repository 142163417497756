/**
 * Update date: 07-06-2023
 * Screen 2081.B
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import FormCreateDeposit from './FormCreateDeposit';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

const FormStoreDeposit = React.lazy(() => import('./FormStoreDeposit'));
const ListStoreDeposit = React.lazy(() => import('./ListStoreDeposit'));
const RegisterDeposit = React.lazy(() => import('./RegisterDeposit'));
/**
 * create context
 */
const ModuleContext = createContext({
  searchParams: {},
  listStoreDeposit: [] || null,
  setSearchParams: () => {},
});

ModuleContext.displayName = 'RegistrationStoreDeposit';

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const RegistrationStoreDeposit = () => {
  const { t }: any = useDictionary({
    programId: '2081.B',
  });
  const [dataLocal, setDataLocal] = useLocalStorage('2081.B_dataList', {});
  const [searchParams, setSearchParams] = useState({
    fromDate: new Date(),
    endDate: new Date(),
    storeIds: [],
    storeGroupIds: [],
  });
  const [listStoreDeposit, setListStoreDeposit] = useState([]);
  const [storedReceivableCd, setStoredReceivableCd] = useState<any>([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [
    { data: resApiListStoreDeposit, loading: loadingApiListStoreDeposit },
    apiListStoreDeposit,
  ] = useAxios(
    {
      url: '/user/store-deposit',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    t,
    setModeView,
    modeView,
    searchParams,
    setSearchParams,
    listStoreDeposit,
    apiListStoreDeposit,
    dataLocal,
    setDataLocal,
    storedReceivableCd,
    setStoredReceivableCd,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListStoreDeposit />;
      case 'list-detail':
        return <FormStoreDeposit />;
      case 'create':
      case 'detail':
        return <FormCreateDeposit />;
      case 'deposit':
        return <RegisterDeposit />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiListStoreDeposit?.data) {
      setListStoreDeposit(resApiListStoreDeposit?.data);
    }
  }, [resApiListStoreDeposit]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiListStoreDeposit} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default RegistrationStoreDeposit;
