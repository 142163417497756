/**
 * Update date: 25-05-2023
 * Screen 2042.2
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { useRef } from 'react';
import { useInquiresAbout } from '..';
const exportFileName =
  'SMDC_2042.2 Inquires about the return schedule_' +
  moment().format('YYYYMMDD');

function ListInventory(props) {
  const { themePro }: any = useApp();
  const context: any = useInquiresAbout();
  const { store } = context;
  const dataGridRef: any = useRef(null);
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, DoubleFormat, QtyFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '2042.2',
  });
  /**
   * render status
   *
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    return e?.value === '0' ? 'Pending' : e?.value === '1' ? 'Fixed' : '';
  };

  const columns: any = [
    {
      caption: t('Takeout Due'),
      alignment: 'center',
      items: [
        {
          dataField: 'billId',
          caption: t('Bill ID'),
          showWhenGrouped: true,
        },
        {
          dataField: 'date',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorName',
          caption: t('Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'zoneName',
          caption: t('Zone'),
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueStateName',
          caption: t('Takeout due State'),
          showWhenGrouped: true,
        },
        {
          dataField: 'status',
          caption: t('Status'),
          showWhenGrouped: true,
          cellRender: renderStatus,
        },
        {
          dataField: 'takeoutBillId',
          caption: t('Takeout Bill ID'),
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'productCode',
          caption: t('Product code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'productName',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'marketableSize',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        {
          dataField: 'optionCode',
          caption: t('Option Code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'optionName',
          caption: t('Option'),
          showWhenGrouped: true,
          alignment: themePro ? 'left' : 'center',
        },
        {
          dataField: 'quantityPerPack',
          caption: t('Quantity Per Pack'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Takeout Due Summary'),
      alignment: 'center',
      columns: [
        {
          dataField: 'purchaseUnitPrice',
          caption: t('Purchase Unit Price'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueQuantity',
          caption: t('Qty'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueAmount',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueSupplyAmount',
          caption: t('Supply Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueVat',
          caption: t('VAT'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueBottle',
          caption: t('Bottle'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueContainer',
          caption: t('Container'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutDueGTotal',
          caption: t('G.Total'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Takeout Summary'),
      alignment: 'center',
      columns: [
        {
          dataField: 'takeoutQuantity',
          caption: t('Qty'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutAmount',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutSupplyAmount',
          caption: t('Supply Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutVat',
          caption: t('VAT'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutBottle',
          caption: t('Bottle'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutContainer',
          caption: t('Container'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'takeoutGTotal',
          caption: t('G.Total'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      dataField: 'billNote',
      caption: t('Bill Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'lineNote',
      caption: t('Line Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'taxRate',
      caption: t('Tax Rate'),
      format: AmountFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'bottleId',
      caption: t('Bottle ID'),
      showWhenGrouped: true,
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'bottleUnitPrice',
      caption: t('Bottle Unit Price'),
      format: AmountFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerId',
      caption: t('Container ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerUnitPrice',
      caption: t('Container Unit Price'),
      format: AmountFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'makerName',
      caption: t('Maker'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'originName',
      caption: t('Origin'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'defaultVendorName',
      caption: t('Default Vendor'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'largeCategoryName',
      caption: t('Large category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'mediumCategoryName',
      caption: t('Medium category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'smallCategoryName',
      caption: t('Small category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'subCategoryName',
      caption: t('Sub category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerDateTime',
      caption: t('Register Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerUserName',
      caption: t('Register User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerProgramId',
      caption: t('Register Program ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyDateTime',
      caption: t('Modify Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyUserName',
      caption: t('Modify User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyProgramId',
      caption: t('Modify Program ID'),
      showWhenGrouped: true,
      visible: false,
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={store}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          key: 'id',
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'takeoutDueQuantity',
                column: 'takeoutDueQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutDueAmount',
                column: 'takeoutDueAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutDueSupplyAmount',
                column: 'takeoutDueSupplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutDueVat',
                column: 'takeoutDueVat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutDueBottle',
                column: 'takeoutDueBottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutDueContainer',
                column: 'takeoutDueContainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutDueGTotal',
                column: 'takeoutDueGTotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutQuantity',
                column: 'takeoutQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutAmount',
                column: 'takeoutAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutSupplyAmount',
                column: 'takeoutSupplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutVat',
                column: 'takeoutVat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutBottle',
                column: 'takeoutBottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutContainer',
                column: 'takeoutContainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'takeoutGTotal',
                column: 'takeoutGTotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'takeoutDueQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutDueAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutDueSupplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutDueVat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutDueBottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutDueContainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutDueGTotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutSupplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutVat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutBottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutContainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'takeoutGTotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
    </>
  );
}

export default ListInventory;
