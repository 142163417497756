import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';

const FormSearch = React.lazy(() => import('./FormSearch'));
const List = React.lazy(() => import('./List'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2087_2_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Monthly Sales Detail Inquiry
 *
 * @return {*}
 */
const M2087_2 = () => {
  const { t }: any = useDictionary({ programId: '2087.2' });

  const [listData, setListData] = useState<any>({
    summaryData: [],
    byVendorData: [],
    byStoreData: [],
    byCategoryData: [],
    byTimeData: [],
    byPOSData: [],
    byAmountData: [],
  });

  // Values share with child component
  const value: any = {
    t,
    listData,
    setListData,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <FormSearch />
        <List />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2087_2;
