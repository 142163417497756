/**
 * Update date: 26-05-2023
 * Screen 2042.4
 */
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';
import ArrayStore from 'devextreme/data/array_store';
import useLocalStorage from 'hooks/useLocalStorage';
import { dateFormatStr } from 'utils/format';

const ConfirmProcessing = React.lazy(() => import('./ConfirmProcessing'));
const ListProcessing = React.lazy(() => import('./ListProcessing'));
/**
 * create context
 */
const ProcessingContext = createContext({
  params: {},
  dataTakeoutDue: [] || null,
  setParams: () => {},
  onUpdateListTakeoutDue: () => {},
});

ProcessingContext.displayName = 'ProcessingContext';

export const useProcessing = () => {
  const context = useContext(ProcessingContext);
  return context;
};

const ProcessingTakenOut = () => {
  const [dataLocal, setDataLocal] = useLocalStorage('2042.4_dataList', {});
  const [params, setParams] = useState<any>({
    dateFrom: new Date(),
    dateTo: new Date(),
    dataList: {
      vendor: [],
      vendorType: [],
      takeoutDueStates: [],
      zone: [],
    },
    vendor: '',
    vendorType: '',
    takeoutDueStates: '',
    zone: '',
  });
  const [dataTakeoutDue, setDataTakeoutDue] = useState([]);
  const dataGridRef: any = useRef(null);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const { t }: any = useDictionary({
    programId: '2042.4',
  });

  const [
    { data: resTakeoutDue, loading: loadingTakeoutDue },
    refetchTakeoutDue,
  ] = useAxios(
    {
      url: '/warehouse/takeout-due',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: '/warehouse/takeout-due',
      method: 'PUT',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: '/warehouse/takeout-due/delete-takeout',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ loading: loadingPdf }, executeFetchPdf] = useAxios(
    {
      url: `/warehouse/takeout-due/print`,
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setParams(prev => ({
      ...prev,
      dataList: {
        ...prev.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  const store: any = new ArrayStore({
    data: dataTakeoutDue,
    key: ['takeoutDueId', 'type'],
  });

  /**
   * on update list take out due
   *
   * @param {*} record
   */
  const onUpdateListTakeoutDue = () => {
    const fixFormData = {
      dateFrom: dateFormatStr(params?.dateFrom),
      dateTo: dateFormatStr(params?.dateTo),
      vendorIds: params?.dataList?.vendor?.map(o => o?.vendorId),
      takeoutDueStates: params?.dataList?.takeoutDueStates?.map(o => o?.code),
      vendorTypes: params?.dataList?.vendorType?.map(o => o?.code),
    };
    refetchTakeoutDue({
      data: { ...fixFormData },
    });
  };

  const value: any = {
    store,
    t,
    setModeView,
    modeView,
    params,
    setParams,
    dataTakeoutDue,
    refetchTakeoutDue,
    onUpdateListTakeoutDue,
    executeFetchPdf,
    refetchUpdate,
    refetchDelete,
    setDataLocal,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListProcessing ref={dataGridRef} />;
      case 'edit':
      case 'view':
        return <ConfirmProcessing />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resTakeoutDue?.data) {
      const dataMapping = (resTakeoutDue?.data || []).map(o => {
        o.takeoutDueStatusNm =
          o?.takeoutDueStatus === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setDataTakeoutDue(dataMapping);
    }
  }, [resTakeoutDue]);

  const loadingAll =
    loadingTakeoutDue || loadingPdf || loadingUpdate || loadingDelete;

  return (
    <ProcessingContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ProcessingContext.Provider>
  );
};

export default ProcessingTakenOut;
