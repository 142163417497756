/**
 * Update date: 06-06-2023
 * Screen 2064.6
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { forwardRef } from 'react';
import { useStatusCarryIntoOfDirectDeliveryEmptyBottles } from '..';
import FormSearch from '../FormSearch';
import moment from 'moment';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
function ListDirectDeliveryEmptyBottle(props, ref) {
  const context: any = useStatusCarryIntoOfDirectDeliveryEmptyBottles();
  const { store } = context || {};
  const { DateFormat, DateTimeFormat } = useFormatTime();

  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '2064.6',
  });

  /**
   * render send
   *
   * @param {*} e
   * @return {*}
   */
  const renderSend = e => {
    return (
      <div>{e?.value === '0' ? '' : e?.value === '1' ? t('Sent') : ''}</div>
    );
  };

  /**
   * on export excel
   *
   */
  const onExportExcel = () => {
    ref?.current?.instance?.exportToExcel();
  };

  const exportFileName = `SMDC_20646 Status carry into of direct delivery empty bottles (PC)_${moment().format(
    'YYYYMMDD',
  )}`;

  const columns: any = [
    {
      caption: t('Collect'),
      alignment: 'center',
      items: [
        {
          dataField: 'bill',
          caption: t('Bill ID'),
          alignment: 'left',
          fixed: true,
        },
        {
          dataField: 'date',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'left',
        },
        {
          dataField: 'receivableCdName',
          caption: t('Receivable Group'),
        },
        {
          dataField: 'storeNm',
          caption: t('Store'),
          alignment: 'left',
        },
        {
          dataField: 'statusNm',
          caption: t('Status'),
          alignment: 'left',
        },
        {
          dataField: 'send',
          caption: t('Send'),
          cellRender: renderSend,
          alignment: 'left',
        },
      ],
    },
    {
      caption: t('Container'),
      alignment: 'center',
      items: [
        {
          dataField: 'containerCd',
          caption: t('Container Code'),
          alignment: 'left',
        },
        {
          dataField: 'containerNm',
          caption: t('Container Name'),
          alignment: 'left',
        },
        {
          dataField: 'containerTp',
          caption: t('Container Type'),
          alignment: 'left',
        },
        {
          dataField: 'containerKind',
          caption: t('Container Kind'),
          alignment: 'left',
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      items: [
        {
          dataField: 'unitPrice',
          caption: t('Unit Price'),
          alignment: 'right',
          format: DoubleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'takeBackRate',
          caption: t('Takeback Price'),
          alignment: 'right',
          format: DoubleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'takeBackQty',
          caption: t('Qty'),
          alignment: 'right',
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'takeBackAmount',
          caption: t('Sum Amount'),
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          caption: t('Container Amount'),
          dataField: 'containerAmount',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          caption: t('Commission Amount'),
          dataField: 'commissionAmount',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          caption: t('Commission Supply'),
          dataField: 'commissionSupply',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          caption: t('Commission VAT'),
          dataField: 'commissionVat',
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      dataField: 'containerId',
      caption: t('Container ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'registerDateTime',
      caption: t('Register DateTime'),
      format: DateTimeFormat,
      dataType: 'date',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'registerUserName',
      caption: t('Register User'),
      alignment: 'left',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'registerProgramId',
      caption: t('Register Program ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifyDatetime',
      caption: t('Modify Date&Time'),
      format: DateTimeFormat,
      dataType: 'date',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifyUserName',
      caption: t('Modify User'),
      alignment: 'left',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifyProgramId',
      caption: t('Modify Program ID'),
      visible: false,
      showWhenGrouped: true,
    },
  ];

  const loadingAll = false;

  return (
    <div>
      <LoadPanel visible={loadingAll} />
      <FormSearch />
      <DataGridFull
        storageKeyInquiry
        ref={ref}
        dataSource={store}
        columns={columns}
        options={{
          key: 'bill',
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          onContextMenuPreparing: (e: any) => {
            if (e.target === 'headerPanel') {
              if (!e.items) e.items = [];
              if (ref?.current?.instance?.option()?.groupPanel?.visible) {
                e.items.push(
                  {
                    text: t('Expand All'),
                    onItemClick: () => {
                      e.component.expandAll();
                    },
                  },
                  {
                    text: t('Collapse All'),
                    onItemClick: () => {
                      e.component.collapseAll();
                    },
                  },
                );
              }
            }
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !store?._array?.length,
                  onClick: () => onExportExcel(),
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                name: 'takeBackQty',
                column: 'takeBackQty',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'takeBackAmount',
                column: 'takeBackAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                column: 'containerAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'commissionAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'commissionSupply',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'commissionVat',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'takeBackQty',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'takeBackAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'containerAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'commissionAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'commissionSupply',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'commissionVat',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
          export: {
            fileName: exportFileName,
          },
          selection: {
            mode: 'single',
          },
        }}
      />
    </div>
  );
}

export default forwardRef(ListDirectDeliveryEmptyBottle);
