/*
 * 2082.4 Staff/Daytime Work/Off I/F
 * @since 2022/11/24
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';
import moment from 'moment';
import React, { createContext, useContext, useState, useEffect } from 'react';

const List = React.lazy(() => import('./List'));

// Initialize context
const AccountPayableAdjReg = createContext({});
AccountPayableAdjReg.displayName = 'AccountPayableAdjReg';
export const useAccountPayableAdjReg = () => {
  const context = useContext(AccountPayableAdjReg);
  return context;
};

/**
 * Staff/Daytime Work/Off I/F
 *
 * @export
 * @return {*}
 */
export default function AccountPayableAdjustmentReg() {
  // Initialize state
  const [modeView, setModeView] = useState<any>({
    type: 'list',
  });
  const [listData, setListData] = useState([]);
  const [dataLocal, setDataLocal] = useLocalStorage('2082.4_dataList', {});
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      vendor: [],
      deductionType: [],
    },
    vendor: '',
    deductionType: '',
  });
  const [formDataCreate, setFormDataCreate] = useState<any>({
    id: null,
    date: null,
    dataList: {
      vendor: [],
      deductionType: [],
    },
    vendor: '',
    deductionType: '',
    deduction: null,
    note: '',
  });
  // Get multi language
  const { t }: any = useDictionary({
    programId: '2082.4',
  });

  // Api get data master component
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'user/vendor-deduction',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  // Api get data detail component
  const [{ data: dataDetail, loading: loadingDetail }, refetchDetail] =
    useAxios(
      {
        url: '',
        method: 'GET',
      },
      { manual: true, autoCancel: true },
    );
  // Api Create or update data
  const [{ loading: loadingSave }, refetchSave] = useAxios(
    {
      url: 'user/vendor-deduction',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );
  // Api delete data
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: 'user/vendor-deduction',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: listData,
    key: ['lineId', 'deductionDate'],
  });

  /**
   * On search data for master gird
   *
   */
  const onSearch = () => {
    const searchParams = {
      startDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      vendorIds: formData?.dataList.vendor.map((o: any) => o?.vendorId),
      deductionTypes: formData?.dataList.deductionType.map((o: any) => o?.code),
    };
    refetchData({
      data: {
        ...searchParams,
      },
    });
  };

  /**
   * On create data
   *
   */
  const onCreate = () => {
    setModeView({ type: 'create' });
    setFormDataCreate(preState => ({
      ...preState,
      date: new Date(),
      dataList: {
        ...preState.dataList,
        deductionType: dataLocal?.deductionType,
      },
    }));
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    return <List />;
  };

  // Set data to master state
  useEffect(() => {
    if (!loadingList && dataList?.data) {
      setListData(dataList?.data);
    }
  }, [loadingList, dataList?.data]);

  // set data to detail state
  useEffect(() => {
    if (!loadingDetail && dataDetail?.data) {
      setFormDataCreate({
        id: dataDetail?.data?.lineId,
        date: dataDetail?.data?.deductionDate,
        dataList: {
          vendor: dataDetail?.data?.vendorId
            ? [
                {
                  vendorName: dataDetail?.data?.vendorName,
                  vendorId: dataDetail?.data?.vendorId,
                },
              ]
            : [],
          deductionType: dataDetail?.data?.deductionTypeCode
            ? [
                {
                  name: dataDetail?.data?.deductionTypeName,
                  code: dataDetail?.data?.deductionTypeCode,
                },
              ]
            : [],
        },
        vendor: '',
        deductionType: '',
        deduction: dataDetail?.data?.deductionAmount,
        note: dataDetail?.data?.deductionNote,
      });
    }
  }, [loadingDetail, dataDetail?.data]);

  // Values share with child component
  const value: any = {
    loadingDetail,
    listData,
    setListData,
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    refetchDetail,
    refetchSave,
    refetchDelete,
    formData,
    onSearch,
    setFormData,
    formDataCreate,
    setFormDataCreate,
    onCreate,
    setDataLocal,
  };

  const loadingAll = loadingList || loadingSave || loadingDelete;

  return (
    <AccountPayableAdjReg.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </AccountPayableAdjReg.Provider>
  );
}
