import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

const addImage = (url, workbook, worksheet, excelCell, resolve) => {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      //if complete
      if (xhr.status !== 200) {
        console.error('could not add image to excel cell');
        resolve();
      }
    }
  };
  xhr.open('GET', url);
  xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0');
  xhr.responseType = 'blob';
  xhr.onload = function () {
    var reader = new FileReader();
    reader.readAsDataURL(xhr.response);
    reader.onloadend = function () {
      var base64data = reader.result;
      const image = workbook.addImage({
        base64: base64data,
        extension: 'png',
      });
      worksheet.getRow(excelCell.row).height = 50;
      worksheet.addImage(image, {
        tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
        br: { col: excelCell.col, row: excelCell.row },
      });
      resolve();
    };
  };
  xhr.onerror = function () {
    console.error('could not add image to excel cell');
    resolve();
  };
  xhr.send();
};

type ExportProps = {
  e: any;
  t: any;
  numberToCurrency: (value: string, format: any) => string;
  QtyFormat: any;
  AmountFormat: any;
  orderNumber: string;
};

export function exportToExcel({
  e,
  t,
  numberToCurrency,
  QtyFormat,
  AmountFormat,
  orderNumber,
}: ExportProps) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  e.component.beginUpdate();
  e.component.columnOption('Option', 'minWidth', 220);
  e.component.columnOption('Option1', 'minWidth', 100);
  e.component.columnOption('Option2', 'minWidth', 100);
  e.component.columnOption('Option3', 'minWidth', 100);
  e.component.columnOption('Option4', 'minWidth', 100);
  e.component.columnOption('Option5', 'minWidth', 100);
  e.component.columnOption('Option6', 'minWidth', 100);
  e.component.columnOption('Option7', 'minWidth', 100);
  e.component.columnOption('Option8', 'minWidth', 100);
  e.component.columnOption('product_nm', 'minWidth', 130);

  e.component.columnOption(
    'Option',
    'caption',
    `${t('Order Item Code')}
${t('Picking Allocation')}
${t('Number Invoice')}`,
  );
  e.component.columnOption(
    'Option1',
    'caption',
    `${t('Option')}
${t('Quantity Per Pack')}`,
  );
  e.component.columnOption(
    'Option2',
    'caption',
    `${t('Order Quantity')}
${t('Shipping Quantity')}`,
  );
  e.component.columnOption(
    'Option3',
    'caption',
    `${t('Order Unit Price')}
${t('Supply Unit Price')}`,
  );
  e.component.columnOption(
    'Option4',
    'caption',
    `${t('Order Amount')}
${t('Shipping Amount')}`,
  );
  e.component.columnOption(
    'Option5',
    'caption',
    `${t('Order Bottle')}
${t('Shipping Bottle')}`,
  );
  e.component.columnOption(
    'Option6',
    'caption',
    `${t('Order Container')}
${t('Shipping Container')}`,
  );
  e.component.columnOption(
    'Option7',
    'caption',
    `${t('Order Shipping Fee')}
${t('Shipping Fee')}`,
  );
  e.component.columnOption(
    'Option8',
    'caption',
    `${t('Order Total')}
${t('Shipping Total')}`,
  );
  e.component.endUpdate();
  var PromiseArray: any = [];

  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    customizeCell: async function (option: any) {
      const { gridCell, excelCell }: any = option;

      if (
        gridCell.column.dataField === 'product_option_img' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = undefined;
        PromiseArray.push(
          new Promise((resolve, reject) => {
            addImage(gridCell.value, workbook, worksheet, excelCell, resolve);
          }),
        );
      } else if (
        gridCell.column.dataField === 'Option' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${gridCell?.data?.order_item_code}
`,
            },
            {
              text: '[',
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${gridCell?.data?.way_bill}`,
            },
            {
              text: `]
`,
            },
            {
              text: '[',
            },
            {
              font: { color: { argb: '2e90fa' } },
              text: `${gridCell?.data?.invoice_no}`,
            },
            {
              text: ']',
            },
          ],
        };
      } else if (
        gridCell.column.dataField === 'Option1' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = `${gridCell?.data?.option_nm}
${numberToCurrency(gridCell?.data?.quantity_per_pack, QtyFormat)}`;
      } else if (
        gridCell.column.dataField === 'Option2' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${numberToCurrency(
                gridCell?.data?.order_quantity,
                QtyFormat,
              )}
`,
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${numberToCurrency(
                gridCell?.data?.shipping_quantity,
                QtyFormat,
              )}`,
            },
          ],
        };
      } else if (
        gridCell.column.dataField === 'Option3' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${numberToCurrency(
                gridCell?.data?.order_unit_price,
                AmountFormat,
              )}
`,
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${numberToCurrency(
                gridCell?.data?.forward_supply_unit_price,
                AmountFormat,
              )}`,
            },
          ],
        };
      } else if (
        gridCell.column.dataField === 'Option4' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${numberToCurrency(
                gridCell?.data?.order_amount,
                AmountFormat,
              )}
`,
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${numberToCurrency(
                gridCell?.data?.forward_shipping_amount,
                AmountFormat,
              )}`,
            },
          ],
        };
      } else if (
        gridCell.column.dataField === 'Option5' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${numberToCurrency(
                gridCell?.data?.order_bottle,
                AmountFormat,
              )}
`,
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${numberToCurrency(
                gridCell?.data?.forward_shipping_bottle,
                AmountFormat,
              )}`,
            },
          ],
        };
      } else if (
        gridCell.column.dataField === 'Option6' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${numberToCurrency(
                gridCell?.data?.order_container,
                AmountFormat,
              )}
`,
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${numberToCurrency(
                gridCell?.data?.forward_shipping_container,
                AmountFormat,
              )}`,
            },
          ],
        };
      } else if (
        gridCell.column.dataField === 'Option7' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${numberToCurrency(
                gridCell?.data?.order_shipping_fee,
                AmountFormat,
              )}
`,
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${numberToCurrency(
                gridCell?.data?.forward_shipping_fee,
                AmountFormat,
              )}`,
            },
          ],
        };
      } else if (
        gridCell.column.dataField === 'Option8' &&
        gridCell.rowType === 'data'
      ) {
        excelCell.value = {
          richText: [
            {
              text: `${numberToCurrency(
                gridCell?.data?.order_total,
                AmountFormat,
              )}
`,
            },
            {
              font: { color: { argb: 'f04438' } },
              text: `${numberToCurrency(
                gridCell?.data?.forward_total,
                AmountFormat,
              )}`,
            },
          ],
        };
      }
    },
  }).then(function () {
    Promise.all(PromiseArray).then(() => {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `OrderNumber-${orderNumber}.xlsx`,
        );
      });
    });
  });
  e.cancel = true;
}
