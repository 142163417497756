/**
 * Update date: 13-06-2023
 * Popup product image drag drop component
 */
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import { Button, Draggable, Popup, ScrollView } from 'devextreme-react';
import { isMobile } from 'react-device-detect';
import './ProductImageDragDrop.scss';
import { cloneDeep } from 'lodash';
import { useEffect, useState, memo } from 'react';
import useAxios from 'axios-hooks';
import { notification } from 'utils/notification';
import PopupPreviewImg from '../PopupPreviewImg';

interface IProductImageDrop {
  fieldName: string; // column name that can update image
  url: string;
}
interface IPopupProductImageDragDrop {
  productId?: number | null;
  visible?: boolean;
  onHiding?: (e?: string) => void;
  onOk?: (e) => void;
  title?: string;
  dragImages?: Array<string>;
  dropImages?: Array<IProductImageDrop>;
}

const imageGroup = 'imageGroup';
const initImages: Array<IProductImageDrop> = [
  { fieldName: 'product_img', url: '' },
  { fieldName: 'product_add_img_url_one', url: '' },
  { fieldName: 'product_add_img_url_two', url: '' },
  { fieldName: 'product_add_img_url_three', url: '' },
  { fieldName: 'product_add_img_url_four', url: '' },
  { fieldName: 'product_add_img_url_five', url: '' },
  { fieldName: 'product_contents_url_one', url: '' },
  { fieldName: 'product_contents_url_two', url: '' },
  { fieldName: 'product_contents_url_three', url: '' },
  { fieldName: 'product_contents_url_four', url: '' },
  { fieldName: 'product_contents_url_five', url: '' },
];

const PopupProductImageDragDrop = ({
  productId = null,
  visible,
  onHiding,
  onOk,
  dragImages = [],
  dropImages = [],
}: IPopupProductImageDragDrop) => {
  const { t } = useDictionary({});
  const [productImages, setProductImages] = useState<Array<IProductImageDrop>>(
    productId
      ? cloneDeep(initImages)
      : cloneDeep(dropImages?.length ? dropImages : initImages),
  );
  const [popupPreview, setPopupPreview] = useState<any>({
    visible: false,
    url: '',
  });
  const [{ loading: loadingSave }, fetchData] = useAxios(
    {
      url: `/product/product/update/image`,
      method: 'PUT',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: resImage, loading }] = useAxios(
    {
      url: `/product/product/image/${productId}`,
      method: 'GET',
    },
    { manual: productId ? false : true, autoCancel: true, useCache: false },
  );

  useEffect(() => {
    if (!resImage?.data) return;
    const dataImages = resImage?.data;
    setProductImages(prev => {
      return prev?.map(o => {
        return { ...o, url: dataImages[o.fieldName] || '' };
      });
    });
  }, [resImage]);

  const saveData = async e => {
    const productImagesClone = cloneDeep(productImages);
    const indexOf = productImagesClone.findIndex(
      o => o?.fieldName === e?.toData?.fieldName,
    );
    if (indexOf === -1) return;
    const requestData = { ...productImages[indexOf], url: e?.fromData };
    //hold and not save when productId not exist
    if (!productId) {
      productImagesClone.splice(indexOf, 1, requestData);
      setProductImages(productImagesClone);
      return;
    }

    const res = await fetchData({
      data: { productId: productId, productImages: [requestData] },
    });

    if (res?.data?.status === '200') {
      productImagesClone.splice(indexOf, 1, requestData);
      setProductImages(productImagesClone);
    } else {
      notification({
        res,
      });
    }
  };

  /**
   * on remove data
   *
   * @param {string} fieldName
   * @return {*}
   */
  const removeData = async (fieldName: string) => {
    const productImagesClone = cloneDeep(productImages);
    const indexOf = productImagesClone.findIndex(
      o => o?.fieldName === fieldName,
    );
    if (indexOf === -1) return;
    const requestData = { ...productImages[indexOf], url: '' };
    //hold and not save when productId not exist
    if (!productId) {
      productImagesClone.splice(indexOf, 1, requestData);
      setProductImages(productImagesClone);
      return;
    }

    const res = await fetchData({
      data: { productId: productId, productImages: [requestData] },
    });

    if (res?.data?.status === '200') {
      productImagesClone.splice(indexOf, 1, requestData);
      setProductImages(productImagesClone);
    } else {
      notification({
        res,
      });
    }
  };

  const onListDragStart = e => {
    e.cancel = true;
  };

  const onItemDragStart = e => {
    e.itemData = e.fromData;
  };

  const onItemDragEnd = e => {
    if (!e?.toData?.fieldName) {
      return;
    }
    e.cancel = true;
    saveData(e);
  };

  /**
   * render prev trash content
   *
   * @param {string} fieldName
   * @param {string} url
   * @return {*}
   */
  const prevTrashContent = (fieldName: string, url: string) => {
    return (
      <div className="preview-trash-image">
        {url && (
          <>
            <i
              className="fa fa-eye"
              title={t('Preview')}
              onClick={() =>
                setPopupPreview({
                  visible: true,
                  url: url,
                })
              }
            ></i>
            <i
              onClick={() => removeData(fieldName)}
              className="fa fa-trash"
              title={t('Remove')}
            ></i>
          </>
        )}
      </div>
    );
  };
  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 10px' }}>
          <LoadPanel visible={loading || loadingSave} />
          <div className="modal-popup-header">
            <span className="title-page">{t('Product Image')}</span>
            <div>
              {!productId && (
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={() => onOk?.([...productImages])}
                />
              )}

              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={() => onHiding?.(productImages?.[0]?.url)}
              />
            </div>
          </div>
          <div className="popup-product-image-content">
            <div className="content-left">
              <div>
                <h1>{t('Product Image')}</h1>
                <Draggable
                  className="dx-drop-list product-drop-list"
                  data="productImages"
                  group={imageGroup}
                >
                  {productImages.slice(0, 1).map(o => (
                    <Draggable
                      key={`img-drop-${o.fieldName}`}
                      className="dx-drop-content"
                      clone={true}
                      group={imageGroup}
                      data={o}
                    >
                      <div className="custome-view-image">
                        {prevTrashContent(o.fieldName, o.url)}
                        {o.url && (
                          <img
                            className={'image-view'}
                            src={o.url || ''}
                            alt=""
                          />
                        )}
                      </div>
                    </Draggable>
                  ))}
                </Draggable>
              </div>
              <div>
                <h1>{t('Add Image')}</h1>
                <Draggable
                  className="dx-drop-list"
                  data="productImages"
                  group={imageGroup}
                >
                  {productImages.slice(1, 6).map(o => (
                    <Draggable
                      key={`img-drop-${o.fieldName}`}
                      className="dx-drop-content"
                      clone={true}
                      group={imageGroup}
                      data={o}
                    >
                      <div className="custome-view-image">
                        {prevTrashContent(o.fieldName, o.url)}
                        {o.url && (
                          <img
                            className={'image-view'}
                            src={o.url || ''}
                            alt=""
                          />
                        )}
                      </div>
                    </Draggable>
                  ))}
                </Draggable>
              </div>
              <div>
                <h1>{t('Content Image')}</h1>
                <Draggable
                  className="dx-drop-list"
                  data="productImages"
                  group={imageGroup}
                >
                  {productImages.slice(6).map(o => (
                    <Draggable
                      key={`img-drop-${o.fieldName}`}
                      className="dx-drop-content"
                      clone={true}
                      group={imageGroup}
                      data={o}
                    >
                      <div className="custome-view-image">
                        {prevTrashContent(o.fieldName, o.url)}
                        {o.url && (
                          <img
                            className={'image-view'}
                            src={o.url || ''}
                            alt=""
                          />
                        )}
                      </div>
                    </Draggable>
                  ))}
                </Draggable>
              </div>
            </div>
            <div className="content-right">
              <ScrollView>
                <Draggable
                  className="dx-drag-list"
                  data="dragImages"
                  group={imageGroup}
                  onDragStart={onListDragStart}
                >
                  {dragImages
                    ?.filter(o => !!o)
                    ?.map((o, index) => (
                      <Draggable
                        key={`img-drag-${index}`}
                        className="dx-draggable-content"
                        clone={true}
                        group={imageGroup}
                        data={o}
                        onDragStart={onItemDragStart}
                        onDragEnd={onItemDragEnd}
                        dragRender={() => (
                          <div className="drag-image">
                            <img
                              className={'image-view'}
                              src={o}
                              alt=""
                              width={90}
                              height={90}
                            />
                          </div>
                        )}
                      >
                        <div
                          className="custome-view-image"
                          style={{
                            maxWidth: '168px',
                            maxHeight: '168px',
                            height: 'calc(100% - 5px)',
                            aspectRatio: `${1 / 1}`,
                          }}
                        >
                          <div className="preview-trash-image">
                            <i
                              className="fa fa-eye"
                              title={t('Preview')}
                              onClick={() =>
                                setPopupPreview({
                                  visible: true,
                                  url: o,
                                })
                              }
                            ></i>
                          </div>
                          {o && <img className={'image-view'} src={o} alt="" />}
                        </div>
                      </Draggable>
                    ))}
                </Draggable>
              </ScrollView>
            </div>
          </div>
        </div>
      </ScrollView>
    );
  };

  return (
    <>
      <Popup
        className="modal-content-popup"
        visible={visible}
        onHiding={() => onHiding?.(productImages?.[0]?.url)}
        contentRender={content}
        height={isMobile ? '80vh' : '100vh'}
        width={isMobile ? '96%' : '75vw'}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
      {popupPreview?.visible && (
        <PopupPreviewImg
          visible={popupPreview?.visible}
          url={popupPreview?.url || ''}
          isOrigin={true}
          onHiding={() =>
            setPopupPreview({
              visible: false,
              url: '',
            })
          }
        />
      )}
    </>
  );
};

export default memo(PopupProductImageDragDrop);
