/**
 * Update date: 15-05-2023
 * Screen 1043.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useAxios from 'axios-hooks';
import { Button, Form, Popup, ScrollView, Switch } from 'devextreme-react';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { useEffect, useRef, useState, memo } from 'react';
import { notification } from 'utils/notification';
import { useCenterZone } from '.';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import moment from 'moment';
import { iconExcel } from 'images';
import PopupFileUpload from './PopupFileUpload';
interface FormZoneProps {
  id: any;
  handleClose: any;
}

const keyGenLocationCd = ['floorCd', 'lineCd', 'rowCd', 'columnCd', 'cellCd'];

function FormZone(props: FormZoneProps) {
  const { themePro }: any = useApp();
  const { id, handleClose } = props;
  const formRef: any = useRef(null);
  const gridRef: any = useRef(null);
  const centerZoneContext: any = useCenterZone();
  const { DateFormat } = useFormatTime();
  const { IntegerFormat } = useFormat();
  const { t, setListLocation, refresh, storeLocation } = centerZoneContext;
  const [formData, setFormData] = useState({
    zoneCd: '',
    zoneId: null,
    zoneNm: '',
    zoneNt: '',
    zoneOrder: '',
    zoneStockInterlockTf: false,
    zoneUseTf: true,
  });
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [defaultLocal, setDefaultLocal] = useState<any>(null);
  const [isOpenModalImport, setIsOpenModalImport] = useState(false);
  const selectedKeys = gridRef?.current?.instance?.option().selectedRowKeys;

  const [{ data, loading }, executeData] = useAxios(
    {
      url: `/core/zone-info/${id}`,
      method: 'GET',
    },
    { manual: id ? false : true, useCache: false },
  );

  const [{ loading: loadingPut }, executePut] = useAxios(
    {
      url: '/core/zone-info',
      method: 'PUT',
    },
    { manual: true },
  );

  const [{ data: dataLoadType }] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
      data: { codeType: 'LT' },
    },
    { manual: false, autoCancel: true },
  );

  const [
    { data: dataDownload, loading: loadingDownload, error: errorDownload },
    executeDownload,
  ] = useAxios(
    {
      url: '/core/zone-info/download-file',
      responseType: 'blob',
    },
    { manual: true },
  );

  const [{ loading: loadingUpload }, executeUpload] = useAxios(
    {
      url: '/core/zone-info/import-excel',
      method: 'POST',
    },
    { manual: true },
  );
  /**
   * download file
   */
  useEffect(() => {
    if (dataDownload && !loadingDownload) {
      const filename = 'CenterZoneTemplate.xlsx';
      const blob = new Blob([dataDownload]);
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    } else if (errorDownload) {
      notification({
        message: t('Can not download file'),
        type: 'error',
      });
    }
  }, [dataDownload, loadingDownload, errorDownload]);
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      const locations = [...(data?.data.locationResponse || [])].map(
        (o, index) => ({
          ...o,
          ID: index + 1,
          locationCdOld: o.locationCd,
        }),
      );
      delete data?.data.locationResponse;
      setFormData(data?.data);
      setListLocation(locations);
    }
  }, [data]);

  /**
   * on upload file
   *
   * @param {*} file
   * @return {*}
   */
  const onUpload = async file => {
    if (!id) {
      return notification({
        message: t('Please select one Zone!'),
        type: 'warning',
      });
    }
    if (!file || file?.size == 0) {
      return notification({
        message: t('Please upload file'),
        type: 'warning',
      });
    }
    const formData = new FormData();
    formData.append('file', file[0]);
    const res = await executeUpload({
      data: formData,
      params: {
        zoneId: id,
      },
    });
    notification({ res });
    if (res.data.status == 201) {
      setIsOpenModalImport(false);
      executeData();
    } else if (res.data.status == 426) {
      const binary_string = window.atob(res.data.data);
      const len = binary_string.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      const byteArray = new Uint8Array(bytes.buffer);
      const downloadLink = window.document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: 'application/octet-stream' }),
      );
      downloadLink.download = '1043.3-CenterZoneTemplate-Fail.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    if (
      storeLocation?._array?.some(
        o => o?.status === 'delete' && o?.locationDefaultTf,
      )
    ) {
      return notification({
        message: t('Location default can not deleted!'),
        type: 'error',
      });
    }
    const locationRequest = storeLocation?._array
      ?.filter(o => o?.status === 'add' || o?.status === 'edit')
      .map(o => ({
        ...o,
        locationDistributionPeriodDm: o.locationDistributionPeriodDm
          ? moment(o.locationDistributionPeriodDm).format('YYYY-MM-DD HH:mm:ss')
          : null,
      }));
    const locationIdsDelete = storeLocation?._array
      ?.filter(o => o?.status === 'delete' && o?.locationId)
      .map(o => o.locationId);

    const dataReq: any = {
      ...formData,
      locationRequest,
      locationIdsDelete,
    };
    const res = await executePut({ data: dataReq });
    notification({
      res,
    });
    if (res?.data?.status === '201') {
      refresh();
      handleClose();
    }
  };

  /**
   * on validate delete
   *
   * @return {*}
   */
  const validateDelete = () => {
    const selectedRowsData =
      gridRef?.current?.instance?.getSelectedRowsData() || [];
    if (selectedRowsData.length === 0) {
      return notification({
        message: t('You must be select at least one record. Please try again!'),
        type: 'error',
      });
    }
    setShowConfirmDelete(true);
  };

  /**
   * on remove
   *
   */
  const onRemove = () => {
    const selectedData = gridRef?.current?.instance?.getSelectedRowsData();
    selectedData.forEach(e => {
      if (e?.locationDefaultTf) {
        return;
      }
      const newData = {
        ...e,
        status: 'delete',
      };
      storeLocation.update(e?.ID, newData).fail(() => {});
    });
    gridRef?.current?.instance?.deselectAll();
    setShowConfirmDelete(false);
  };

  /**
   * render switch active
   *
   * @param {*} cell
   * @return {*}
   */
  const switchActiveState = cell => {
    return (
      <Switch
        defaultValue={cell.value}
        onValueChanged={e => handleActiveStates(cell, e)}
        readOnly={cell.value || cell?.row?.data?.status === 'delete'}
      />
    );
  };

  /**
   * on active state
   *
   * @param {*} cell
   * @param {*} e
   */
  const handleActiveStates = (cell, e) => {
    if (!e?.previousValue) {
      setDefaultLocal(cell?.data?.ID);
      setShowDialogConfirm(true);
    } else {
      cell.setValue(e.value ? true : false);
    }
  };

  /**
   * on init new row
   *
   * @param {*} e
   */
  const onInitNewRow = e => {
    e.data = {
      ID: storeLocation?._array?.length + 1,
      ...e.data,
      locationDefaultTf: false,
      status: 'add',
      locationRouteOrder: 1,
      locationOperation: 'Operation',
      locationLoadTpCd: 'R',
      locationPriority: 1,
    };
  };

  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowSaved = e => {
    const findMain = (e?.changes || []).find(o => o?.key);
    if (findMain) {
      let dataUpdate;
      if (findMain?.type === 'update') {
        dataUpdate = {
          ...findMain?.data,
          status: 'edit',
        };
      } else if (findMain?.type === 'remove') {
        dataUpdate = {
          ...findMain?.data,
          status: 'delete',
        };
      }
      if (findMain?.type === 'update' || findMain?.type === 'remove') {
        storeLocation
          .update(findMain.data.ID, dataUpdate)
          .done(() => {})
          .fail(() => {});
      } else if (findMain?.type === 'insert') {
        storeLocation.remove(findMain?.key).done(() => {
          storeLocation?._array?.unshift(findMain?.data);
          gridRef?.current?.instance?.refresh();
        });
      }
    }
    gridRef?.current?.instance?.refresh();
  };

  const onCellChanged = e => {
    const zoneCode = formData.zoneCd;
    const arr = {
      floorCd: e?.component?.cellValue(e?.row?.rowIndex, 'floorCd') || '',
      lineCd: e?.component?.cellValue(e?.row?.rowIndex, 'lineCd') || '',
      rowCd: e?.component?.cellValue(e?.row?.rowIndex, 'rowCd') || '',
      columnCd: e?.component?.cellValue(e?.row?.rowIndex, 'columnCd') || '',
      cellCd: e?.component?.cellValue(e?.row?.rowIndex, 'cellCd') || '',
    };
    const result = Object.values(arr)
      .filter(o => o !== '')
      .join('-');
    const locationCd = `${zoneCode ? zoneCode + '-' : ''}${result}`;
    e?.component?.cellValue(e?.row?.rowIndex, 'locationCd', locationCd);
    e?.component?.cellValue(e?.row?.rowIndex, 'locationNm', locationCd);
  };

  const renderStatus = (record: any) => {
    switch (record?.value) {
      case 'add':
        return '+';
      case 'edit':
        return 'V';
      case 'delete':
        return '-';
      default:
        return null;
    }
  };

  const columns: any = [
    {
      dataField: 'status',
      caption: t('Status'),
      width: 90,
      fixed: true,
      alignment: 'center',
      allowEditing: false,
      cellRender: renderStatus,
    },
    {
      dataField: 'floorCd',
      caption: t('Floor'),
      alignment: 'center',
      allowEditing: true,
      width: 90,
      validationRules: [
        {
          type: 'stringLength',
          max: 5,
          message: t('The maximum allowed characters are 5'),
        },
      ],
    },
    {
      dataField: 'lineCd',
      caption: t('Line'),
      alignment: 'center',
      allowEditing: true,
      width: 90,
      validationRules: [
        {
          type: 'stringLength',
          max: 5,
          message: t('The maximum allowed characters are 5'),
        },
      ],
    },
    {
      dataField: 'rowCd',
      caption: t('Row'),
      alignment: 'center',
      allowEditing: true,
      width: 90,
      validationRules: [
        {
          type: 'stringLength',
          max: 5,
          message: t('The maximum allowed characters are 5'),
        },
      ],
    },
    {
      dataField: 'columnCd',
      caption: t('Column'),
      alignment: 'center',
      allowEditing: true,
      width: 90,
      validationRules: [
        {
          type: 'stringLength',
          max: 5,
          message: t('The maximum allowed characters are 5'),
        },
      ],
    },
    {
      dataField: 'cellCd',
      caption: t('Cell'),
      dataType: 'number',
      alignment: 'center',
      format: IntegerFormat,
      allowEditing: true,
      width: 80,
      editorOptions: {
        min: 0,
        step: 0,
      },
      validationRules: [{ type: 'required' }],
    },
    {
      dataField: 'locationCd',
      caption: t('Location'),
      alignment: 'center',
      allowEditing: false,
    },
    {
      dataField: 'locationNm',
      caption: t('Name'),
      allowEditing: true,
      validationRules: [
        {
          type: 'stringLength',
          max: 100,
          message: t('The maximum allowed characters are 100'),
        },
        { type: 'required' },
      ],
    },
    {
      dataField: 'locationRouteOrder',
      caption: t('Route Order'),
      dataType: 'number',
      format: IntegerFormat,
      allowEditing: true,
    },
    {
      dataField: 'locationLoadTpCd',
      caption: t('Load Type'),
      allowEditing: true,
      editorType: 'dxSelectBox',
      editorOptions: {
        placeholder: '',
        displayExpr: 'name',
        valueExpr: 'code',
        items: dataLoadType?.data,
      },
      lookup: {
        dataSource: dataLoadType?.data,
        displayExpr: 'name',
        valueExpr: 'code',
      },
      validationRules: [{ type: 'required' }],
    },
    {
      dataField: 'locationOperation',
      caption: t('Operation'),
      allowEditing: true,
      editorType: 'dxSelectBox',
      validationRules: [{ type: 'required' }],
      editorOptions: {
        placeholder: '',
        displayExpr: 'label',
        valueExpr: 'value',
        items: [
          {
            label: t('Operation'),
            value: 'Operation',
          },
          {
            label: t('Hole'),
            value: 'Hole',
          },
        ],
      },
    },
    {
      dataField: 'locationPriority',
      caption: t('Priority'),
      dataType: 'number',
      format: IntegerFormat,
      allowEditing: true,
      validationRules: [{ type: 'required' }],
    },
    {
      dataField: 'locationDistributionPeriodDm',
      caption: t('Distribution Period'),
      dataType: 'date',
      format: DateFormat,
      allowEditing: true,
    },
    {
      dataField: 'locationDefaultTf',
      caption: t('Default'),
      showEditorAlways: true,
      allowEditing: true,
      editCellRender: switchActiveState,
    },
    {
      dataField: 'locationNt',
      caption: t('Note'),
      allowEditing: true,
    },
    {
      dataField: 'action',
      caption: t('Action'),
      type: 'buttons',
      allowEditing: false,
    },
  ];

  return (
    <Popup
      visible={true}
      dragEnabled={false}
      showCloseButton={true}
      showTitle={true}
      title={t('Zone')}
      maxHeight={'100vh'}
      height={themePro ? '100vh' : '90vh'}
      onHidden={handleClose}
      animation={{ show: undefined }}
      className={themePro ? 'modal-content-popup' : 'location-view'}
    >
      <ScrollView width="100%" height={themePro ? '100%' : 'calc(100% - 40px)'}>
        <LoadPanel
          visible={loading || loadingPut || loadingDownload || loadingUpload}
        />
        {themePro && (
          <div className="modal-popup-header">
            <span className="title-page">{t('Zone')}</span>
            <div>
              <Button
                stylingMode="contained"
                type="default"
                text={t('OK')}
                icon="check"
                style={{ marginRight: 5 }}
                onClick={() => onSubmit()}
              />
              <Button
                stylingMode="contained"
                text={t('Cancel')}
                icon="close"
                onClick={() => handleClose()}
              />
            </div>
          </div>
        )}
        <Form
          className="bg-white p-20"
          formData={formData}
          items={[
            {
              label: { text: t('Id') },
              dataField: 'zoneId',
              editorOptions: {
                readOnly: true,
                disabled: true,
              },
            },
            {
              label: { text: t('Zone') },
              dataField: 'zoneNm',
              validationRules: [
                {
                  type: 'stringLength',
                  max: 100,
                  message: t('The maximum allowed characters are 100'),
                },
                {
                  type: 'required',
                  message: t('Zone is required'),
                },
              ],
            },
            {
              label: { text: t('Zone code') },
              dataField: 'zoneCd',
              validationRules: [
                {
                  type: 'stringLength',
                  max: 100,
                  message: t('The maximum allowed characters are 100'),
                },
                {
                  type: 'required',
                  message: t('Zone code is required'),
                },
              ],
            },
            {
              label: { text: t('Order') },
              dataField: 'zoneOrder',
              validationRules: [
                {
                  type: 'numeric',
                  message: t('Allow entering the numeric only'),
                },
                {
                  type: 'required',
                  message: t('Order is required'),
                },
              ],
            }, {
              label: { text: t('API URL') },
              dataField: 'url',
            },
            {
              itemType: 'group',
              colSpan: 2,
              colCount: 2,
              items: [
                {
                  label: { text: t('Stock interlock') },
                  dataField: 'zoneStockInterlockTf',
                  editorType: 'dxSwitch',
                },
                {
                  label: { text: t('Use') },
                  dataField: 'zoneUseTf',
                  editorType: 'dxSwitch',
                },
              ],
            },
            {
              label: { text: t('Note') },
              dataField: 'zoneNt',
              colSpan: 3,
              editorType: 'dxTextArea',
              validationRules: [
                {
                  type: 'stringLength',
                  max: 500,
                  message: t('The maximum allowed characters are 500'),
                },
              ],
            },
          ]}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          ref={formRef}
        />
        <br />
        <DataGridFull
          ref={gridRef}
          dataSource={storeLocation}
          columns={columns}
          options={{
            columnAutoWidth: true,
            reshapeOnPush: true,
            editing: {
              mode: 'row',
              allowUpdating: true,
              allowDeleting: false,
              allowAdding: true,
              startEditAction: 'click',
              selectTextOnEditStart: true,
            },
            height: themePro ? 'calc(108vh - 460px)' : 500,
            filterPanel: {
              visible: false,
            },
            selection: {
              mode: 'multiple',
              selectAllMode: 'allPages',
            },
            onInitNewRow: onInitNewRow,
            onSaved: onRowSaved,
            onEditorPreparing: function (e) {
              const indexOf = keyGenLocationCd.indexOf(e?.dataField);
              const existingOnValueChanged = e.editorOptions.onValueChanged;
              e.editorOptions.onValueChanged = evalue => {
                if (e?.type === 'selection') return;
                e.setValue(evalue.value);
                onCellChanged(e);
                if (existingOnValueChanged) {
                  existingOnValueChanged(evalue);
                }
              };
              e.editorOptions.onKeyDown = ekey => {
                if (ekey?.event?.keyCode === 13 || ekey?.event?.keyCode === 9) {
                  ekey.event.preventDefault();
                  if (
                    e?.dataField === keyGenLocationCd[indexOf] &&
                    indexOf !== -1
                  ) {
                    const dataGrid = gridRef?.current?.instance;
                    const nextElement = dataGrid?.getCellElement(
                      e?.row?.rowIndex,
                      keyGenLocationCd[indexOf + 1],
                    );
                    if (nextElement) {
                      ekey?.event?.stopPropagation();
                      dataGrid?.focus(nextElement);
                      nextElement.click();
                    } else {
                      const next = dataGrid?.getCellElement(
                        e?.row?.rowIndex,
                        'locationNm',
                      );
                      ekey?.event?.stopPropagation();
                      dataGrid?.focus(next);
                      next.click();
                    }
                    onCellChanged(e);
                  }
                }
              };
            },
            onToolbarPreparing: (e: any) => {
              const toolbarItems = e?.toolbarOptions?.items;
              e.toolbarOptions.items = toolbarItems.filter(
                item =>
                  item.name === 'addRowButton' ||
                  item.name === 'columnChooserButton' ||
                  item.name === 'searchPanel',
              );
              toolbarItems?.forEach(item => {
                if (item.name === 'addRowButton') {
                  item.showText = 'always';
                }
              });
              e.toolbarOptions.items.unshift(
                {
                  location: 'before',
                  template: 'totalCount',
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'trash',
                    text: t('Delete'),
                    onClick: () => validateDelete(),
                  },
                },
                {
                  locateInMenu: 'auto',
                  location: 'before',
                  widget: 'dxButton',
                  options: {
                    visible: !!id,
                    icon: iconExcel,
                    text: t('Excel Location Import'),
                    onClick: () => setIsOpenModalImport(true),
                  },
                },
              );
            },
          }}
        />
      </ScrollView>
      {!themePro && (
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <Button
            text={t('Ok')}
            icon="check"
            onClick={() => onSubmit()}
            style={{ marginRight: 10 }}
          />
          <Button
            text={t('Cancel')}
            icon="close"
            onClick={() => handleClose()}
          />
        </div>
      )}
      <PopupConfirm
        visible={showDialogConfirm}
        content={t('Are you sure you want set default location?')}
        onOk={() => {
          storeLocation?._array
            ?.filter(e => e?.locationDefaultTf || e?.ID == defaultLocal)
            ?.forEach(e => {
              storeLocation.update(e?.ID, {
                locationDefaultTf: e?.ID == defaultLocal,
                status:
                  e?.status !== 'add' && e?.status !== 'delete'
                    ? 'edit'
                    : e?.status,
              });
            });
          setShowDialogConfirm(false);
          setDefaultLocal(null);
          gridRef?.current?.instance?.refresh();
        }}
        onHiding={() => setShowDialogConfirm(false)}
      />
      <PopupConfirmDelete
        visible={showConfirmDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onRemove}
        onHiding={() => setShowConfirmDelete(false)}
      />
      {isOpenModalImport && (
        <PopupFileUpload
          visible={isOpenModalImport}
          onDownLoad={executeDownload}
          onHiding={() => setIsOpenModalImport(false)}
          onOk={onUpload}
        />
      )}
    </Popup>
  );
}

export default memo(FormZone);
