/**
 * Update date: 23-05-2023
 * Screen 2023.1
 */
import useAxios from 'axios-hooks';
import { notification } from 'utils/notification';
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useOrderRegistration } from '..';
import FormAddProduct from './FormAddProduct';
import ArrayStore from 'devextreme/data/array_store';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';
import MenuSound from 'app/components/MenuSound';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { dateFormatStr, dateTimeRequestFormat } from 'utils/format';
import { toNumberAmount, toNumberRoundUpDown } from 'hooks/toNumberAmount';
import PopupConfirmWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmWithRef';

/**
 * get amount
 *
 * @param {*} record
 * @return {*}
 */
const getAmount = record => {
  const {
    purchaseUnitPrice = 0,
    ctOrderQuantity = 0,
    taxRate = 0,
    purchaseIncvat,
  } = record || {};
  if (purchaseIncvat) {
    return toNumberAmount(purchaseUnitPrice * ctOrderQuantity, true);
  }
  return toNumberRoundUpDown(
    purchaseUnitPrice * ctOrderQuantity * (1 + taxRate * 0.01),
  );
};

/**
 * get supply amount
 *
 * @param {*} record
 * @return {*}
 */
const getSupplyAmount = record => {
  const {
    purchaseUnitPrice = 0,
    ctOrderQuantity = 0,
    taxRate = 0,
    purchaseIncvat,
  } = record || {};
  if (purchaseIncvat) {
    return toNumberAmount(getAmount(record) / (1 + taxRate * 0.01));
  }
  return toNumberRoundUpDown(purchaseUnitPrice * ctOrderQuantity);
};

/**
 * get vat
 *
 * @param {*} record
 * @return {*}
 */
const getVAT = record => {
  return toNumberAmount(getAmount(record) - getSupplyAmount(record));
};

/**
 * get bottle
 *
 * @param {*} record
 * @return {*}
 */
const getBottle = record => {
  const {
    quantityPerPack = 0,
    ctOrderQuantity = 0,
    bottleUnitPrice = 0,
  } = record || {};
  return toNumberAmount(bottleUnitPrice * quantityPerPack * ctOrderQuantity);
};

/**
 * get container
 *
 * @param {*} record
 * @return {*}
 */
const getContainer = record => {
  const { containerUnitPrice = 0, ctOrderQuantity = 0 } = record || {};
  return toNumberAmount(containerUnitPrice * ctOrderQuantity);
};

/**
 * get gtotal
 *
 * @param {*} record
 * @return {*}
 */
const getGTotal = record => {
  return toNumberAmount(
    getAmount(record) + getBottle(record) + getContainer(record),
  );
};

const FormGetOrderInfo = React.lazy(() => import('./FormGetOrderInfo'));
const TableContent = React.lazy(() => import('./TableContent'));
/**
 * create context
 */
const FormCreateContext = createContext({});
export const useFormCreate = () => {
  const context = useContext(FormCreateContext);
  return context;
};

function FormCreate() {
  const appContext: any = useApp();
  const { s } = appContext || {};
  const context: any = useOrderRegistration();
  const { t, modeView, setModeView, onUpdateListOrder } = context || {};
  const id = modeView?.id;
  const formSearchRef: any = useRef(null);
  const formAddProductRef: any = useRef(null);
  const tableContentRef: any = useRef(null);
  const confirmRef: any = useRef(null);
  const [dataTable, setDataTable] = useState<any>([]);
  const [focusItem, setFocusItem] = useState<any>(1);

  const store: any = new ArrayStore({
    data: dataTable,
    key: 'ID',
  });
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, useCache: false, autoCancel: true },
  );

  const [{ data: dataDetail, loading: loadingDetail }, refetchDataDetail] =
    useAxios(
      {
        url: `/warehouse/order-registration/${id}`,
        method: 'GET',
      },
      { manual: true, useCache: false, autoCancel: true },
    );

  const [{}, addHistoryBarcode] = useAxios(
    {
      url: '/warehouse/barcode-history',
      method: 'PUT',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * fetch data
   */
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const res = await refetchDataDetail({
          url: `/warehouse/order-registration/${id}`,
          method: 'GET',
        });
        const data = res?.data?.data?.ctOrderDetailResponses
          ?.sort((a, b) => (a?.lineId - b?.lineId >= 0 ? 1 : -1))
          ?.map((o, i) => ({
            ...o,
            ID: i + 1,
            status: 'normal',
            purchaseUnitPriceOld: o?.purchaseUnitPrice || 0,
          }))
          ?.sort((a, b) => (b?.ID - a?.ID >= 0 ? 1 : -1));
        setDataTable(data);
      }
    };
    fetchData();
  }, [id]);

  /**
   * on add order
   *
   * @param {*} e
   */
  const onAddOrder = e => {
    setFocusItem(e?.focusItem || 1);
    if (e?.classify === '1') {
      onAddContainer(e);
    } else {
      onAddProduct(e);
    }
  };

  /**
   * on add product
   *
   * @param {*} e
   */
  const onAddProduct = e => {
    const l = [...dataTable];
    const existOrder = l
      ?.filter((o: any) => o?.status !== 'delete')
      ?.some(
        (o: any) => o?.optionId == e?.optionId && o?.productId == e?.productId,
      );
    if (existOrder) {
      const data = l?.find(
        o => o?.optionId == e?.optionId && o?.productId === e?.productId,
      );
      const newData = {
        ...data,
        status: data?.status === 'add' ? 'add' : 'edit',
        ctOrderQuantity: +data?.ctOrderQuantity + 1,
      };
      newData.supplyAmount = getSupplyAmount(newData);
      newData.vatAmount = getVAT(newData);
      newData.ctOrderAmount = getAmount(newData);
      newData.bottleAmount = getBottle(newData);
      newData.containerAmount = getContainer(newData);
      newData.totalAmount = getGTotal(newData);
      store
        .update(data?.ID, newData)
        .done(() => s(newData?.ctOrderQuantity))
        .fail(() => s('error'));
    } else {
      const newData = {
        ...e,
        status: 'add',
        ID: dataTable.length + 1,
        ctOrderQuantity: 1,
        optionNm: e.option,
        purchaseUnitPriceOld: e?.purchaseUnitPrice || 0,
      };
      newData.supplyAmount = getSupplyAmount(newData);
      newData.vatAmount = getVAT(newData);
      newData.ctOrderAmount = getAmount(newData);
      newData.bottleAmount = getBottle(newData);
      newData.containerAmount = getContainer(newData);
      newData.totalAmount = getGTotal(newData);
      dataTable.unshift(newData);
      s(1);
      // store
      //   .insert(newData)
      //   .done(() => s(1))
      //   .fail(() => s('error'));
    }
    tableContentRef?.current?.dataGridRef?.current?.instance?.refresh();
  };

  /**
   * on add container
   *
   * @param {*} e
   */
  const onAddContainer = e => {
    const l = [...dataTable];
    const existOrder = l
      ?.filter((o: any) => o?.status !== 'delete')
      ?.some((o: any) => o?.containerId == e?.containerId && !o?.productId);
    if (existOrder) {
      const data = l?.find(o => o?.containerId == e?.containerId);
      const newData = {
        ...data,
        status: data?.status === 'add' ? 'add' : 'edit',
        ctOrderQuantity: +data?.ctOrderQuantity + 1,
      };
      newData.supplyAmount = getSupplyAmount(newData);
      newData.vatAmount = getVAT(newData);
      newData.ctOrderAmount = getAmount(newData);
      newData.bottleAmount = getBottle(newData);
      newData.containerAmount = getContainer(newData);
      newData.totalAmount = getGTotal(newData);
      store
        .update(data?.ID, newData)
        .done(() => s(newData?.ctOrderQuantity))
        .fail(() => s('error'));
    } else {
      const newData = {
        ...e,
        status: 'add',
        ID: dataTable.length + 1,
        ctOrderQuantity: 1,
        optionNm: '*',
      };
      newData.supplyAmount = getSupplyAmount(newData);
      newData.vatAmount = getVAT(newData);
      newData.ctOrderAmount = getAmount(newData);
      newData.bottleAmount = getBottle(newData);
      newData.containerAmount = getContainer(newData);
      newData.totalAmount = getGTotal(newData);
      dataTable.unshift(newData);
      s(1);
      // store
      //   .insert(newData)
      //   .done(() => s(1))
      //   .fail(() => s('error'));
    }
    tableContentRef?.current?.dataGridRef?.current?.instance?.refresh();
  };

  /**
   * on add history barcode
   *
   * @param {*} billId
   */
  const onAddHistoryBarcode = async billId => {
    const barcodes =
      formAddProductRef?.current?.instance?.option()?.formData?.barcodes || [];
    if (barcodes?.length) {
      await addHistoryBarcode({
        data: {
          billId,
          type: 8,
          barcodeDetails: barcodes,
        },
      });
    }
  };

  /**
   * on save order
   *
   */
  const onSaveOrder = async () => {
    const currentOrder =
      tableContentRef?.current?.dataGridRef?.current?.instance?.getDataSource()
        ?._store?._array || [];
    const isValid = formSearchRef?.current?.instance?.validate()?.isValid;
    if (isValid) {
      const formSearchData =
        formSearchRef?.current?.instance?.option()?.formData;
      const newOrder = [...currentOrder]
        ?.filter(o => o?.status !== 'delete')
        ?.map((o: any) => {
          delete o.status;
          return o;
        })
        ?.sort((a, b) => (a?.ID - b?.ID >= 0 ? 1 : -1));
      const newData = {
        ctOrderId: formSearchData?.ctOrderId || 0,
        ctOrderDt: dateTimeRequestFormat(formSearchData?.ctOrderDt),
        vendorId: formSearchData?.formStore?.vendor?.vendorId || 0,
        ctOrderState: formSearchData?.formStore?.orderState?.code || '',
        currency: formSearchData?.formStore?.currency?.code || '',
        orderErId: formSearchData?.formStore?.orderEr?.userId || null,
        deliverDueDate: dateTimeRequestFormat(formSearchData?.deliverDueDate),
        ctOrderMstNote: formSearchData?.ctOrderMstNote,
        ctOrderDetRequests: newOrder,
      };
      const res = await refetchData({
        url: '/warehouse/order-registration',
        method: 'PUT',
        data: newData,
      });
      notification({
        res,
      });
      if (res?.data?.status == 200) {
        deselectAll();
        if (res?.data?.data?.ctOrderId) {
          const type = newData?.ctOrderId ? 'edit' : 'add';
          onUpdateListOrder(res?.data?.data, type);
          await onAddHistoryBarcode(res?.data?.data?.ctOrderId);
        }
        setModeView({ type: 'list' });
      }
    }
  };

  /**
   * on deselect all
   *
   */
  const deselectAll = () => {
    tableContentRef?.current?.dataGridRef?.current?.instance?.deselectAll();
  };

  /**
   * check Purchase Price Change
   *
   */
  const checkPurchasePriceChange = () => {
    const lengthChangePrice = store?._array?.filter(
      o =>
        o?.status !== 'delete' &&
        o?.purchaseUnitPrice !== o?.purchaseUnitPriceOld,
    )?.length;
    if (lengthChangePrice) {
      confirmRef?.current?.onOpen?.();
    }
  };

  /**
   * confirm Purchase Price Change
   * if no => convert to old price
   *
   * @param {boolean} isOk
   */
  const confirmPurchasePriceChange = (isOk: boolean) => {
    const dataChangeprice = (store?._array || [])?.filter(
      o =>
        o?.status !== 'delete' &&
        o?.purchaseUnitPrice !== o?.purchaseUnitPriceOld,
    );
    if (isOk) {
      dataChangeprice.forEach(o => {
        const newData = {
          ...o,
          purchaseUnitPriceOld: o?.purchaseUnitPrice,
        };
        store
          .update(o?.ID, newData)
          .done(() => {})
          .fail(() => {});
      });
    } else {
      dataChangeprice.forEach(o => {
        const newData = {
          ...o,
          purchaseUnitPrice: o?.purchaseUnitPriceOld,
        };
        newData.supplyAmount = getSupplyAmount(newData);
        newData.vatAmount = getVAT(newData);
        newData.ctOrderAmount = getAmount(newData);
        newData.bottleAmount = getBottle(newData);
        newData.containerAmount = getContainer(newData);
        newData.totalAmount = getGTotal(newData);
        store
          .update(o?.ID, newData)
          .done(() => {})
          .fail(() => {});
      });
    }
    tableContentRef?.current?.dataGridRef?.current?.instance?.refresh();
  };

  /**
   * on download template
   *
   * @return {*}
   */
  const onDownloadTemplate = async () => {
    try {
      const res = await refetchData({
        url: '/warehouse/order-registration/download-template-file',
        method: 'GET',
        responseType: 'blob',
      });
      if (res?.status === 200) {
        const filename = '2023.1-Order Registration-Template.xlsx';
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        notification({ res });
      }
    } catch (error) {
      notification({
        message: t('Can not download file'),
        type: 'error',
      });
    }
  };

  const onUploadExcelValidate = () => {
    return formSearchRef?.current?.instance?.validate()?.isValid;
  };
  /**
   * on upload
   *
   * @return {*}
   */
  const onUploadExcel = async file => {
    const isValid = formSearchRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const formSearchData = formSearchRef?.current?.instance?.option()?.formData;
    const dataRequest: any = {
      ctOrderDt: dateFormatStr(formSearchData?.ctOrderDt),
      vendorId: formSearchData?.formStore?.vendor?.vendorId || 0,
      ctOrderState: formSearchData?.formStore?.orderState?.code || '',
      currency: formSearchData?.formStore?.currency?.code || '',
      orderErId: formSearchData?.formStore?.orderEr?.userId || null,
      deliverDueDate: dateFormatStr(formSearchData?.deliverDueDate),
      ctOrderMstNote: formSearchData?.ctOrderMstNote,
    };
    const formData = new FormData();
    formData.append('file', file);
    const res = await refetchData({
      url: '/warehouse/order-registration/upload-excel',
      method: 'POST',
      data: formData,
      params: dataRequest,
    });
    if (res.data.status == '200') {
      const { totalRow, rowSuccess, excelEncode, fileName, ctOrder } =
        res?.data?.data || {};
      notification({
        message: `${t('{0} out of {1} products are uploaded successfully')}`
          .replace('{0}', rowSuccess)
          .replace('{1}', totalRow),
        type: rowSuccess === totalRow ? 'success' : 'warning',
      });
      if (excelEncode) {
        const binary_string = window.atob(excelEncode);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        const byteArray = new Uint8Array(bytes.buffer);
        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' }),
        );
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      if (ctOrder?.ctOrderId) {
        onUpdateListOrder(ctOrder, 'add');
        setModeView({ type: 'edit', id: ctOrder?.ctOrderId });
      }
    } else {
      notification({ res });
    }
    return true;
  };

  const value: any = {
    id,
    store,
    dataDetail: dataDetail?.data,
    onSaveOrder,
    onAddOrder,
    formSearchRef,
    checkPurchasePriceChange,
    focusItem,
    onDownloadTemplate,
    onUploadExcel,
    onUploadExcelValidate,
  };

  const loadingAll = loadingDetail || loading;

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingAll} />
      <FormCreateContext.Provider value={value}>
        <BreadCrumbPremium
          onSubmit={() => tableContentRef?.current?.onSave()}
          onCancel={() => tableContentRef?.current?.onClose()}
        />
        <div className="body-padding-white">
          <FormGetOrderInfo ref={formSearchRef} />
          <FormAddProduct ref={formAddProductRef} />
        </div>
        <br />
        <TableContent ref={tableContentRef} />
        <MenuSound />
        <PopupConfirmWithRef
          ref={confirmRef}
          content={t('Do you want to change purchase unit price later?')}
          onOk={() => confirmPurchasePriceChange(true)}
          onHiding={() => confirmPurchasePriceChange(false)}
        />
      </FormCreateContext.Provider>
    </React.Suspense>
  );
}

export default FormCreate;
