/**
 * Update date: 23-05-2023
 * Screen 2024.3
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import LoadPanel from 'app/components/LoadPanel';
import PopupSelectAllocationOrder from 'app/components/PopupCommon/PopupSelectAllocationOrder';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import PopupSelectZone from 'app/components/PopupCommon/PopupSelectZone';
import useAxios from 'axios-hooks';
import Form from 'devextreme-react/form';
import useFormatTime from 'hooks/useFormatTime';
import useLocalStorage from 'hooks/useLocalStorage';
import { cloneDeep, uniqBy } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';
interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
  },
  mediumCategory: {
    codeType: 'MC',
  },
  smallCategory: {
    codeType: 'SC',
  },
  subCategory: {
    codeType: 'UC',
  },
  productType: {
    codeType: 'PT',
  },
};

const FormSearch = ({
  t,
  isResearch,
  setIsResearch,
  storeSearchForm,
  setStoreSearchForm,
  setDeliverData,
  setDeliverTotal,
  productTpPermission,
}) => {
  const [dataLocal, setDataLocal] = useLocalStorage('2024.3_total', {});
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);
  const [showPopupByType, setShowPopupByType] = useState('');
  const [formData, setFormData] = useState<any>({
    fromDate: Date(),
    endDate: Date(),
    dataList: {
      zone: [],
      allocation: [],
      store: [],
      vendor: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      productGroup: [],
      product: [],
      productType: [],
    },
    zone: '',
    allocation: '',
    vendor: '',
    store: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    productGroup: '',
    product: '',
    productType: '',
  });
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, useCache: false },
  );

  useEffect(() => {
    if (!Object.keys(dataLocal || {})?.length) return;
    setFormData(prev => ({
      ...prev,
      dataList: {
        ...prev.dataList,
        zone: dataLocal?.zone || [],
      },
    }));
  }, [dataLocal]);

  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productTypes: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  useEffect(() => {
    if (!isResearch) return;
    const onSearch = async () => {
      const res = await refetchData({
        url: `/warehouse/consigned-delivery`,
        method: 'POST',
        data: storeSearchForm,
      });
      if (res?.data?.status === '200') {
        const { details, totals } = res?.data?.data || {};
        setDeliverData(details || []);
        setDeliverTotal(
          (totals || []).map((o, idx) => ({ ...o, ID: idx + 1 })),
        );
      } else {
        notification({ res });
      }
    };
    onSearch();
    setIsResearch(false);
  }, [isResearch]);
  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) {
      collapseRef?.current?.onCollappse();
      return;
    }
    const allocateSeqs: Array<number> = [];
    const allocateDates: Array<any> = [];
    formData.dataList?.allocation?.forEach((allocate: any) => {
      allocateSeqs.push(allocate.allocate_seq || 0);
      if (allocate?.allocate_dm_str) {
        allocateDates.push(dateFormatStr(allocate.allocate_dm_str));
      }
    });
    const requestData = {
      startDate: dateFormatStr(formData.fromDate),
      endDate: dateFormatStr(formData.endDate),
      allocateSeqs: Array.from(new Set(allocateSeqs)),
      allocateDates: Array.from(new Set(allocateDates)),
      zoneIds: formData?.dataList?.zone?.map((o: any) => o?.zoneId),
      vendorIds: formData?.dataList?.vendor?.map((o: any) => o?.vendorId),
      storeIds: formData?.dataList?.store?.map((map: any) => map?.storeNo),
      largeCategoryCodes: formData?.dataList?.largeCategory?.map(
        (o: any) => o?.code,
      ),
      mediumCategoryCodes: formData?.dataList?.mediumCategory?.map(
        (o: any) => o?.code,
      ),
      smallCategoryCodes: formData?.dataList?.smallCategory?.map(
        (o: any) => o?.code,
      ),
      subCategoryCodes: formData?.dataList?.subCategory?.map(
        (o: any) => o?.code,
      ),
      productGroupIds: formData?.dataList?.productGroup?.map(
        (o: any) => o?.productGroupId,
      ),
      productIds: formData?.dataList?.product?.map((o: any) => o?.productId),
      productTypes: formData?.dataList?.productType?.map((o: any) => o?.code),
    };
    const res = await refetchData({
      url: `/warehouse/consigned-delivery`,
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const { details, totals } = res?.data?.data || {};
      setStoreSearchForm(requestData);
      setDeliverData(details || []);
      setDeliverTotal((totals || []).map((o, idx) => ({ ...o, ID: idx + 1 })));
    } else {
      notification({ res });
    }
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
    if (storedType === 'zone') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
    if (storedType === 'zone') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    const paramsAllowCation = `fromDate=${dateFormatStr(
      formData?.fromDate,
    )}&endDate=${dateFormatStr(formData?.endDate)}`;
    switch (popupType) {
      case 'allocation':
        return (
          <PopupSelectAllocationOrder
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('allocation', o, 'way_bill')}
            params={paramsAllowCation}
          />
        );
      case 'zone':
        return (
          <PopupSelectZone
            text={formData?.[popupType]}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('zone', o)}
          />
        );
      case 'store':
        return (
          <PopupSelectStore
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore('store', data, 'storeNo')}
          />
        );
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={popupType === 'product'}
            text={formData?.product}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore('product', data, 'productId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data =>
              updateStore('productGroup', data, 'productGroupId')
            }
          />
        );

      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
      case 'productType':
        return (
          <PopupSelectCommonCode
            visible={showPopupByType === popupType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
    formRef?.current?.instance?.updateData('fromDueDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDueDate', toDateValue);
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        setFormData(preState => ({ ...preState, fromDate: e?.value }));
        if (formData.endDate && e?.value && e?.value > formData.endDate)
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        setFormData(preState => ({ ...preState, endDate: e?.value }));
        if (formData.fromDate && e?.value && e?.value < formData.fromDate)
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          onFieldDataChanged={onFieldDataChanged}
          colCount={3}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            generateDxTextBox('Zone', 'zone', 'zoneName', { isRequired: true }),
            generateDxTextBox('Vendor', 'vendor', 'vendorName'),
            generateDxTextBox('Store', 'store', 'storeNm'),
            generateDxTextBox('Allocation Order', 'allocation', 'allocate_seq'),
          ]}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          items={[
            generateDxTextBox('Large Category', 'largeCategory', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
            generateDxTextBox('Small Category', 'smallCategory', 'name'),
            generateDxTextBox('Sub Category', 'subCategory', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            generateDxTextBox('Product', 'product', 'productNm'),
            generateDxTextBox('Product Type', 'productType', 'name', {
              readOnly: formData?.isReadOnlyProductTp,
            }),
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit} ref={collapseRef}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      <LoadPanel visible={loading} />
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default memo(FormSearch);
