/**
 * Update date: 05-06-2023
 * Screen 2053.2
 */
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import { useReceiveForward } from '..';
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import Form from 'devextreme-react/form';

interface formData {
  productGroup: string;
}

const initialFormData: formData = {
  productGroup: '',
};

interface IPopupPG {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit: (selected: any) => void;
}

const PopupPG = ({ visible, onHiding, text, onSubmit }: IPopupPG) => {
  const { themePro }: any = useApp();
  const [formData, setFormData] = useState<formData>({ ...initialFormData });
  const [tabCurrent, setTabCurrent] = useState('');
  const formRef: any = useRef(null);
  const gridRef: any = useRef(null);
  const context: any = useReceiveForward();
  const {
    dataGroup,
    dataGridRef,
    refetchPG,
    refetchDetail,
    dataDetail,
    setDataDetail,
    setToShow,
  } = context;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [saveData, setSaveData] = useState<any>();
  const [appenedIds, setAppenedIds] = useState<any>();
  const { t } = useDictionary({
    programId: '2053.1',
  });

  /**
   * on change tab panel
   *
   * @param {*} title
   */
  const onChangeTabPanel = title => {
    setTabCurrent(title);
  };

  const columns: any = [
    {
      dataField: 'productGroupId',
      caption: t('ID'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'productGroupNm',
      caption: t('Product Group'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      caption: t('Use'),
      dataField: 'useTf',
      editorType: 'dxSwitch',
      alignment: 'center',
    },
  ];
  /**
   * fetch data
   */
  const fetchDetail = () => {
    const selectedPrdGrpId =
      gridRef?.current?.instance?.getSelectedRowsData()?.[0]?.productGroupId;
    refetchDetail({
      data: {
        productGroupId: selectedPrdGrpId,
      },
    });
    setSaveData('update');
    setMessage('Confirm to update changes?');
    setShowConfirm(true);
  };
  /**
   * fetch data
   */
  useEffect(() => {
    setDataDetail(dataDetail);
    const selectPrdRows = dataGridRef?.current?.instance
      ?.option?.()
      ?.selectedRowKeys.map((o: any) => o?.product_id);
    const filteredPrdRows = selectPrdRows.filter(
      val => !dataDetail.includes(val),
    );
    setAppenedIds(dataDetail.concat(filteredPrdRows));
  }, [dataDetail, setDataDetail]);

  /**
   * on update group
   *
   * @param {*} type
   */
  const updateGroup = type => {
    const selectedPrdGrpNm =
      gridRef?.current?.instance?.getSelectedRowsData()?.[0]?.productGroupNm;
    const selectedPrdGrpId =
      gridRef?.current?.instance?.getSelectedRowsData()?.[0]?.productGroupId;

    const selectPrdRows = dataGridRef?.current?.instance
      ?.option?.()
      ?.selectedRowKeys.map((o: any) => o?.product_id);
    const newPrdGrp = formData.productGroup;
    const useTf = true;

    let data;

    switch (type) {
      case 'update':
        data = {
          productIds: appenedIds,
          productGroupId: selectedPrdGrpId,
          productGroupNm: selectedPrdGrpNm,
          useTf: useTf,
        };
        break;
      case 'create':
        data = {
          productIds: selectPrdRows,
          productGroupNm: newPrdGrp,
          useTf: useTf,
        };
        break;
      default:
        break;
    }

    setShowConfirm(true);
    saveUpdateFunc(data);
  };

  /**
   * on save
   *
   * @param {*} data
   */
  const saveUpdateFunc = (data: any) => {
    refetchPG({ data });
    setToShow(true);
    setShowConfirm(false);
    dataGridRef?.current?.instance?.deselectAll();
  };

  const handleSave = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) {
      return;
    }

    setSaveData('create');
    setMessage('Confirm to create a new product group?');
    setShowConfirm(true);
  };

  /**
   * render content
   *
   */
  const content = () => (
    <>
      {themePro && (
        <div className="modal-popup-header">
          <span className="title-page">{t('Product Group')}</span>
          <div>
            <Button
              stylingMode="contained"
              text={t('Close')}
              icon="close"
              onClick={onHiding}
            />
          </div>
        </div>
      )}
      <TabPanel
        defaultSelectedIndex={0}
        swipeEnabled={false}
        onSelectionChanged={(e: any) => {
          onChangeTabPanel(e?.addedItems[0].title);
        }}
        className="theme-premiun-dx-multiview-wrapper-0"
      >
        <TabItem title={t('Product Group')}>
          <div style={themePro ? {} : { padding: '20px' }}>
            <DataGridFull
              ref={gridRef}
              dataSource={dataGroup}
              columns={columns}
              options={{
                columnAutoWidth: true,
                scrolling: {
                  columnRenderingMode: 'virtual',
                },
                selection: {
                  mode: 'single',
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'onClick',
                },
                height: themePro ? 'calc(100vh - 200px)' : 'auto',
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift({
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                      text: t('Select'),
                      onClick: () => fetchDetail(),
                    },
                  });
                },
              }}
            />
          </div>
        </TabItem>
        <TabItem title={t('New')}>
          <div
            style={
              themePro
                ? {}
                : {
                    marginBottom: 20,
                    paddingTop: 20,
                    paddingLeft: 30,
                  }
            }
          >
            <div className="body-padding-white">
              <Form
                ref={formRef}
                formData={formData}
                colCount={themePro ? 1 : 2}
                labelLocation="left"
                items={[
                  {
                    label: {
                      text: t('Product Group'),
                      location: themePro ? 'top' : 'left',
                    },
                    dataField: 'productGroup',
                    editorType: 'dxTextBox',
                    isRequired: true,
                  },
                ]}
              />
              <div
                style={
                  themePro
                    ? { marginTop: 20 }
                    : {
                        marginTop: 20,
                        paddingLeft: 115,
                      }
                }
              >
                <Button
                  text={t('Save')}
                  type="default"
                  stylingMode="contained"
                  onClick={() => handleSave()}
                />
              </div>
            </div>
          </div>
        </TabItem>
      </TabPanel>
    </>
  );

  return (
    <React.Fragment>
      <Popup
        className="modal-content-popup"
        visible={visible}
        onHiding={onHiding}
        title={t(text || t('Product Group'))}
        contentRender={content}
        width={'60%'}
        height={themePro ? '100vh' : '80%'}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
      <PopupConfirm
        visible={showConfirm}
        content={message}
        onOk={() => updateGroup(saveData)}
        onHiding={() => setShowConfirm(false)}
      />
    </React.Fragment>
  );
};

export default PopupPG;
