/**
 * Update date: 19-05-2023
 * Screen 2013.7
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState, useRef } from 'react';
import { notification } from 'utils/notification';
import { groupBy } from 'lodash';
import useFormat from 'hooks/useFormat';

const List = React.lazy(() => import('./List'));
  /**
   * create context
   */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2013_7_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const marginRateKey = 'margin_rate_';
const shippingRateKey = 'shipping_rate_';

const M2013_7 = () => {
  const { t }: any = useDictionary({ programId: '2013_7' });
  const { DoubleFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const [data, setData] = useState<any>([]);
  const [shippingTypes, setShippingTypes] = useState<any>([]);
  const [columnRender, setColumnRender] = useState<any>(null);
  const [columnNameEdit, setColumnNameEdit] = useState<any>([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    useYn: '1',
    marginYn: '0',
    dataList: {
      marginGroup: [],
      store: [],
      storeType: [],
      storeGroup: [],
      unitPriceGroup: [],
    },
    marginGroup: '',
    store: '',
    storeType: '',
    storeGroup: '',
    unitPriceGroup: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    zone: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'store_id',
  });

  /**
   * on mapping data store
   *
   * @param {*} data
   */
  const mappingDataStore = data => {
    const shippingTpList = data?.shippingTypes || [];
    const storeGroup = groupBy(data?.storeList || [], 'store_id');
    const keys = Object.keys(storeGroup);
    const storeList: any = [];
    const columnMagin: any = [];
    const columnShipping: any = [];
    const columnMaginEdit: any = [];
    const columnShippingEdit: any = [];

    keys.forEach((key, index) => {
      const itemsOfkey = storeGroup[key];
      const store = { ...itemsOfkey[0] };
      shippingTpList.forEach(s => {
        const itemFind = itemsOfkey.find(o => o?.shipment_tp == s?.code_cd);
        if (itemFind) {
          store[marginRateKey + s?.code_cd] = itemFind?.margin_rate || 0;
          store[shippingRateKey + s?.code_cd] = itemFind?.shipping_rate || 0;
        } else {
          store[marginRateKey + s?.code_cd] = 0;
          store[shippingRateKey + s?.code_cd] = 0;
        }
        if (index === 0) {
          columnMaginEdit.push(marginRateKey + s?.code_cd);
          columnShippingEdit.push(shippingRateKey + s?.code_cd);
          columnMagin.push({
            dataField: marginRateKey + s?.code_cd,
            caption: s.code_nm + '(%)',
            dataType: 'number',
            format: DoubleFormat,
            showWhenGrouped: false,
            allowGrouping: false,
            allowReordering: false,
            allowEditing: true,
            cssClass: 'gridcell-editing',
          });
          columnShipping.push({
            dataField: shippingRateKey + s?.code_cd,
            caption: s.code_nm + '(%)',
            dataType: 'number',
            format: DoubleFormat,
            showWhenGrouped: false,
            allowGrouping: false,
            allowReordering: false,
            allowEditing: true,
            cssClass: 'gridcell-editing',
          });
        }
      });
      storeList.push(store);
    });
    setColumnNameEdit([...columnMaginEdit, ...columnShippingEdit]);
    setColumnRender({ columnMagin, columnShipping });
    setShippingTypes(shippingTpList);
    setData(storeList);
  };

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      marginGroupCode: formSearchData?.dataList?.marginGroup?.[0].code,
      useYn: formSearchData?.useYn || '',
      marginYn: formSearchData?.marginYn || '',
      storeIds: formSearchData.dataList?.store?.map((o: any) => o?.storeNo),
      storeTypes: formSearchData?.dataList.storeType.map((o: any) => o?.code),
      storeGroupIds: formSearchData.dataList?.storeGroup?.map(
        (o: any) => o?.storeGroupId,
      ),
      unitPriceGroupCodes: formSearchData.dataList?.unitPriceGroup?.map(
        (o: any) => o?.code,
      ),
    };

    const res = await refetchData({
      url: '/product/store-margin-group',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      mappingDataStore(res?.data?.data);
    } else {
      notification({ res });
    }
  };

  /**
   * on save
   *
   */
  const onSave = async () => {
    const marginGroupCode = formSearchData?.dataList?.marginGroup?.[0].code;
    const dataEdit: any = [];
    store?._array
      ?.filter(o => o?.status === 'edit')
      ?.forEach(o => {
        shippingTypes?.forEach(s => {
          dataEdit.push({
            store_id: o?.store_id,
            margin_group: o?.margin_group || marginGroupCode,
            shipment_tp: s?.code_cd,
            margin_rate: o[marginRateKey + s?.code_cd] || 0,
            shipping_rate: o[shippingRateKey + s?.code_cd] || 0,
          });
        });
      });

    const dataDelete = store?._array
      .filter(o => o?.status === 'delete' && o?.margin_group)
      .map(o => ({
        store_id: o?.store_id,
        margin_group: o?.margin_group,
      }));

    const res = await refetchData({
      url: 'product/store-margin-group',
      method: 'PUT',
      data: { saveData: dataEdit, deleteData: dataDelete },
    });
    notification({ res });
    if (res?.data?.status === '201') {
      onSubmit();
      dataGridRef?.current?.instance?.deselectAll();
    }
  };

  const value: any = {
    t,
    store,
    setData,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    onSave,
    columnRender,
    columnNameEdit,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List ref={dataGridRef} />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2013_7;
