/**
 * Create date: 2023-06-12
 * Screen 2082.A
 */
// import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import React, { useState, forwardRef } from 'react';
import { cloneDeep, uniqBy } from 'lodash';
import Collapse from 'app/components/Collapse';
import { useModuleContext } from '..';
import useFormatTime from 'hooks/useFormatTime';
import DateOptions from 'app/components/DateOptions';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

// const listTypeCommon = {
//   productTypes: {
//     codeType: 'PT',
//   },
// };

/**
 * Form Search Component
 *
 * @param {IFormSearchProps} { onSearch }
 * @return {*}
 */
const FormSearch = (props, ref) => {
  const [showPopupByType, setShowPopupByType] = useState('');
  const context: any = useModuleContext();
  const { t, formData, setFormData, onSearch } = context;
  const { DateFormat } = useFormatTime();

  /**
   *  Remote data input
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
  };

  /**
   * Update data input from popup
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
  };

  /**
   * Generate select box
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {number} [numberShowMore=2]
   * @param {IOptionItem} [option]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * Render list popup
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      // case 'productTypes':
      //   return (
      //     <PopupSelectCommonCode
      //       visible={!!listTypeCommon?.[popupType]?.codeType}
      //       text={formData?.[popupType]}
      //       codeType={listTypeCommon?.[popupType]?.codeType}
      //       mode="multiple"
      //       onHiding={() => setShowPopupByType('')}
      //       onSubmit={data => updateStore(popupType, data, 'code')}
      //     />
      //   );
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Form field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (formData?.endDate && e?.value && e?.value > formData.endDate) {
          setFormData(preState => ({
            ...preState,
            fromDate: e?.value,
            endDate: null,
          }));
        }
        break;
      case 'endDate':
        if (formData?.fromDate && e?.value && e?.value < formData?.fromDate) {
          setFormData(preState => ({
            ...preState,
            endDate: e?.value,
            fromDate: null,
          }));
        }
        break;
      default:
        break;
    }
    if (!e?.value) {
      setFormData(preState => ({
        ...preState,
        [e?.dataField]: e?.value,
      }));
    }
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, endDateValue) => {
    if (!fromDateValue || !endDateValue) return;
    ref?.current?.instance?.updateData('fromDate', fromDateValue);
    ref?.current?.instance?.updateData('endDate', endDateValue);
  };

  /**
   * Search content
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, endDate) => onChangeDate(fromDate, endDate)}
        />
        <Form
          ref={ref}
          formData={formData}
          showColonAfterLabel={false}
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              label: { text: t('To Date') },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
            generateDxTextBox('Vendor', 'vendor', 'vendorName', { colSpan: 2 }),
          ]}
          labelLocation="top"
        />
      </>
    );
  };

  /**
   * Advanced Search content
   *
   * @param {*} { name }
   * @return {*}
   */
  // const AdvancedSearch = ({ name }) => {
  //   return (
  //     <Form
  //       formData={formData}
  //       showColonAfterLabel={false}
  //       items={[
  //         generateDxTextBox('Vendor', 'vendor', 'vendorName'),
  //         generateDxTextBox('Product Type', 'productTypes', 'name', {
  //           readOnly: formData?.isReadOnlyProductTp,
  //         }),
  //       ]}
  //       labelLocation="top"
  //       colCount={2}
  //     ></Form>
  //   );
  // };
  return (
    <div>
      <Collapse onSubmit={onSearch}>
        <Search name={t('Search')} />
        {/* <AdvancedSearch name={t('Advanced Search')} /> */}
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};
export default forwardRef(FormSearch);
