export const tabLabelERP = {
  1: 'Membership registration and login',
  2: 'Register basic information of center',
  3: 'Product master and product price',
  4: 'Center order and supplier order and delivery',
  5: 'Center receive and supplier carry out',
  6: 'Location load/exit and zone move',
  7: 'Product order and picking, forwarding',
  8: 'Product receiving/forwarding and cost, inventory management',
};

// flow chart tab 1
export const registAndLoginERP = {
  '1022.1': '/login',
  '1022.2': '/signup',
  '1031.4': '/',
  '1041.1': '/104/1041/1041.1',
  '1043.1': '/104/1043/1043.1',
};
export const registAndLoginERPLabel = {
  user: 'User',
  userSub: '(Center, SCM User, Shipping)',
  userEmail: 'User Email',
  centerOperator: 'Center Operator',
  systemOperator: 'System Operator',
  sendAuthentication: 'Send authentication message',
  normalSendingAuthen: 'Send authentization message successfully',
  userAuthenUnavailable: 'User authentization fail',
  inquiryDisapprovedStatus: 'Search with "Unapproved" status',
  inquiryApprovedStatus: 'Search with "Approved" status',
  ProgramPermissionUser: 'Program authentication settings by user',
  searchAvailablePrograms: 'Search programs which can be operated',
};

// flow chart tab 2
export const registerInforCenterERP = {
  '1042.1': '/104/1042/1042.1',
  '1042.5': '/104/1042/1042.5',
  '1042.4': '/104/1042/1042.4',
  '1042.6': '/104/1042/1042.6',
  '1042.2': '/104/1042/1042.2',
  '1046.A': '/104/1046/1046.A',
  '1043.1': '/104/1043/1043.1',
  '1043.3': '/104/1043/1043.3',
  '1044.1': '/104/1044/1044.1',
  '1044.2': '/104/1044/1044.2',
  '1044.5': '/104/1044/1044.5',
  '1044.3': '/104/1044/1044.3',
  '1044.4': '/104/1044/1044.4',
};

// flow chart tab 3
export const productMasterAndPriceERP = {
  '2011.1': '/201/2011/2011.1',
  '2011.3': '/201/2011/2011.3',
  '2011.4': '/201/2011/2011.4',
  '2011.5': '/201/2011/2011.5',
  '2012.3': '/201/2012/2012.3',
  '2012.4': '/201/2012/2012.4',
  '2012.5': '/201/2012/2012.5',
  '2013.1': '/201/2013/2013.1',
  '2013.3': '/201/2013/2013.3',
  '2013.4': '/201/2013/2013.4',
  '2013.5': '/201/2013/2013.5',
  '2014.1': '/201/2014/2014.1',
  '2014.2': '/201/2014/2014.2',
  '2016.1': '/201/2016/2016.1',
  '2016.2': '/201/2016/2016.2',
  '2017.1': '/201/2017/2017.1',
  '2017.2': '/201/2017/2017.2',
  '2046.5': '/204/2046/2046.5',
  '2046.6': '/204/2046/2046.6',
  '2081.7': '/208/2081/2081.7',
};

// flow chart tab 4
export const centerAndSupplierOrderAndDeliveryERP = {
  '2023.1': '/202/2023/2023.1',
  '2023.2': '/202/2023/2023.2',
  '2032.1': '/203/2032/2032.1',
  '2032.2': '/203/2032/2032.2',
  '2032.3': '/203/2032/2032.3',
  '2032.5': '/203/2032/2032.5',
  '2031.1': '/203/2031/2031.1',
  '2031.2': '/203/2031/2031.2',
  '2033.1': '/203/2033/2033.1',
  '2033.3': '/203/2033/2033.3',
  '2041.1': '/204/2041/2041.1',
  '2044.5': '/204/2044/2044.5',
  '2053.1': '/205/2053/2053.1',
  '2053.3': '/205/2053/2053.3',
};

// flow chart tab 5
export const centerWarehouseAndSupplierExportERP = {
  '2032.3': '/203/2032/2032.3',
  '2041.1': '/204/2041/2041.1',
  '2041.2': '/204/2041/2041.2',
  '2041.3': '/204/2041/2041.3',
  '2041.4': '/204/2041/2041.4',
  '2041.5': '/204/2041/2041.5',
  '2041.6': '/204/2041/2041.6',
  '2041.7': '/204/2041/2041.7',
  '2041.8': '/204/2041/2041.8',
  '2041.9': '/204/2041/2041.9',
  '2041.A': '/204/2041/2041.A',
  '2041.B': '/204/2041/2041.B',
  '2041.C': '/204/2041/2041.C',
  '2042.1': '/204/2042/2042.1',
  '2042.2': '/204/2042/2042.2',
  '2042.4': '/204/2042/2042.4',
  '2042.5': '/204/2042/2042.5',
};

// flow chart tab 6
export const locationLoadingExportingAndZoneERP = {
  '2041.4': '/204/2041/2041.4',
  '2041.5': '/204/2041/2041.5',
  '2041.C': '/204/2041/2041.C',
  '2046.6': '/204/2046/2046.6',
  '2046.9': '/204/2046/2046.9',
  '2048.1': '/204/2048/2048.1',
  '2048.2': '/204/2048/2048.2',
  '2051.1': '/205/2051/2051.1',
  '2052.1': '/205/2052/2052.1',
  '2052.2': '/205/2052/2052.2',
  '2052.3': '/205/2052/2052.3',
  '2052.5': '/205/2052/2052.5',
};

// flow chart tab 7
export const productOrderAndPickingForwardingERP = {
  '1046.A': '/104/1046/1046.A',
  '2024.1': '/202/2024/2024.1',
  '2024.3': '/202/2024/2024.3',
  '2033.1': '/203/2033/2033.1',
  '2033.3': '/203/2033/2033.3',
  '2043.1': '/204/2043/2043.1',
  '2043.2': '/204/2043/2043.2',
  '2043.3': '/204/2043/2043.3',
  '2043.4': '/204/2043/2043.4',
  '2043.6': '/204/2043/2043.6',
  '2043.7': '/204/2043/2043.7',
  '2044.2': '/204/2044/2044.2',
  '2044.3': '/204/2044/2044.3',
  '2044.4': '/204/2044/2044.4',
  '2044.5': '/204/2044/2044.5',
  '2046.1': '/204/2046/2046.1',
  '2046.2': '/204/2046/2046.2',
  '2046.3': '/204/2046/2046.3',
  '2047.1': '/204/2047/2047.1',
  '2047.2': '/204/2047/2047.2',
  '2061.1': '/206/2061/2061.1',
  '2061.4': '/206/2061/2061.4',
  '2061.5': '/206/2061/2061.5',
};

// flow chart tab 8
export const productReceivingForwardingInventoryERP = {
  '2051.4': '/205/2051/2051.4',
  '2051.6': '/205/2051/2051.6',
  '2041.5': '/204/2041/2041.5',
  '2041.C': '/204/2041/2041.C',
  '2046.6': '/204/2046/2046.6',
  '2046.5': '/204/2046/2046.5',
  '2048.1': '/204/2048/2048.1',
  '2054.9': '/205/2054/2054.9',
  '2042.4': '/204/2042/2042.4',
  '2046.1': '/204/2046/2046.1',
  '2046.4': '/204/2046/2046.4',
  '2055.1': '/205/2055/2055.1',
  '2051.1': '/205/2051/2051.1',
  '2051.2': '/205/2051/2051.2',
  '2051.3': '/205/2051/2051.3',
};
