/**
 * Update date: 08-06-2023
 * Screen 2081.K
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import moment from 'moment';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import FormSearch from './FormSearch/FormSearch';
import { TabPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/tab-panel';

const components = {
  Order: React.lazy(() => import('./Order')),
  Receive: React.lazy(() => import('./Receive')),
  Takeout: React.lazy(() => import('./Takeout')),
  Forward: React.lazy(() => import('./Forward')),
  'Other Forward': React.lazy(() => import('./OtherForward')),
  'Zone Move': React.lazy(() => import('./ZoneMove')),
  Return: React.lazy(() => import('./Return')),
  Disposal: React.lazy(() => import('./Disposal')),
  'Empty Bottle Takeback': React.lazy(() => import('./EmptyBottleTakeback')),
  'Empty Bottle Takeout': React.lazy(() => import('./EmptyBottleTakeout')),
};

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'CancelFixByOperation';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const CancelFixByOperation = () => {
  const { t }: any = useDictionary({ programId: '2081.K' });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tab, setTab] = useState('Order');
  const [dataTab, setDataTab] = useState<any>({
    dataTabOrder: null,
    dataTabReceive: null,
    dataTabTakeout: null,
    dataTabForward: null,
    dataTabOtherForward: null,
    dataTabZoneMove: null,
    dataTabReturn: null,
    dataTabDisposal: null,
    dataTabEmptyBottleTakeback: null,
    dataTabEmptyBottleTakeout: null,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    enDate: new Date(),
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const executeAction = async e => {
    const res = await refetchData({
      url: `/warehouse/cancel-fix-by-operation`,
      method: 'PUT',
      data: e,
    });
    return res;
  };

  /**
   * on select index
   *
   * @param {*} e
   */
  const onSelectedIndexChange = e => {
    setSelectedIndex(e);
  };

  /**
   * handle tab change
   *
   * @param {*} tab
   */
  const handleTabChange = tab => {
    setTab(tab.text.replace(/ /g, ''));
  };

  /**
   * on submit
   *
   */
  const onSubmitSearch = async () => {
    const requestData = {
      fromDate: formSearchData?.fromDate
        ? moment(formSearchData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formSearchData?.enDate
        ? moment(formSearchData?.enDate).format('YYYY-MM-DD')
        : null,
      tab: tab,
    };

    const res = await refetchData({
      url: '/warehouse/cancel-fix-by-operation',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      setDataTab({
        ...dataTab,
        [`dataTab${tab}`]: res?.data?.data,
      });
    } else {
      notification({
        res,
      });
    }
  };

  const value: any = {
    t,
    modeView,
    setModeView,
    refetchData,
    dataTab,
    setDataTab,
    tab,
    formSearchData,
    setFormSearchData,
    onSubmitSearch,
    executeAction,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    return (
      <>
        <BreadCrumbPremium />
        <FormSearch />
        <TabPanel
          activeStateEnabled
          deferRendering
          repaintChangesOnly
          showNavButtons
          defaultSelectedIndex={0}
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
          onSelectedItemChange={handleTabChange}
          className="bg-white"
          swipeEnabled={false}
        >
          {Object.keys(components).map(name => (
            <Item key={name.replace(/ /g, '')} title={t(name)} text={name}>
              {React.createElement(components[name], {
                [`data${name.replace(/ /g, '')}`]:
                  dataTab?.[`dataTab${name.replace(/ /g, '')}`],
              })}
            </Item>
          ))}
        </TabPanel>
      </>
    );
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default CancelFixByOperation;
