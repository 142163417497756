/*
 * 2087.6 Sale Summary By Store
 * @since 2022/11/28
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import React, { createContext, useContext, useState, useEffect } from 'react';
import View from './View';

// lazy loading component
const List = React.lazy(() => import('./List'));

// Create context
const SaleSummaryByStoreContext = createContext({});
SaleSummaryByStoreContext.displayName = 'SaleSummaryByStoreContext';

export const useSaleSummaryByStoreContext = () => {
  const context = useContext(SaleSummaryByStoreContext);
  return context;
};

/**
 * Sales Summary by Store ( Detail )
 *
 * @export
 * @return {*}
 */
export default function SaleSummaryByStore() {
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
  });
  const { AmountFormat }: any = useFormat();
  const [listData, setListData] = useState([]);
  const [listDataDetail, setListDataDetail] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      store: [],
      storeGroup: [],
      receivableCd: [],
    },
    store: '',
    storeGroup: '',
    receivableCd: '',
    searchBy: 0,
  });
  // Get multi language
  const { t }: any = useDictionary({
    programId: '2087.6',
  });

  // Get data master
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'warehouse/sale-summary',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  // api get data detail
  const [{ data: dataDetail, loading: loadingDetail }, refetchDetail] =
    useAxios(
      {
        url: '',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  const store: any = new ArrayStore({
    data: listData,
    // key: [],
  });
  const storeView: any = new ArrayStore({
    data: listDataDetail,
    // key: [],
  });

  /**
   * Search data
   *
   */
  const onSearch = () => {
    const searchParams = {
      startDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : '',
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : '',
      storeGroupIds: formData.dataList.storeGroup.map(
        (o: any) => o.storeGroupId,
      ),
      storeIds: formData.dataList.store.map((o: any) => o.storeNo),
      receiveManagementGroups: formData.dataList.receivableCd.map(
        (o: any) => o.code,
      ),
      mode: formData?.searchBy,
    };
    refetchData({
      data: {
        ...searchParams,
      },
    });
  };

  /**
   * Render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <View />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!loadingList && dataList?.data) {
      setListData(dataList?.data);
    }
  }, [loadingList, dataList?.data]);

  useEffect(() => {
    if (!loadingDetail && dataDetail?.data) {
      setListDataDetail(dataDetail?.data);
    }
  }, [loadingDetail, dataDetail?.data]);

  // Values share with child component
  const value: any = {
    storeView,
    AmountFormat,
    listData,
    setListData,
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    refetchDetail,
    formData,
    onSearch,
    setFormData,
    setListDataDetail,
  };

  const loadingAll = loadingList || loadingDetail;

  return (
    <SaleSummaryByStoreContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </SaleSummaryByStoreContext.Provider>
  );
}
