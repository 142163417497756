/**
 * Update date: 09-06-2023
 * Mobile component
 */
import { Drawer } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import { useLocation } from 'react-router-dom';
import './styles.scss';

const MenuPDA = React.lazy(() => import('../MenuPda'));

export default function MobileMenu() {
  const [opened, setOpened] = useState(false);
  const pathname = useLocation().pathname;

  /**
   * render content
   *
   * @return {*}
   */
  const DrawerContent = () => {
    if (!opened) return null;
    return <MenuPDA />;
  };

  useEffect(() => {
    if (!!opened) {
      setOpened(false);
    }
  }, [pathname]);

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <Drawer visible={opened} closeOnOutsideClick={true}>
        {DrawerContent()}
      </Drawer>
      <div
        style={{
          position: 'absolute',
          top: 80,
          right: 0,
          zIndex: 100000,
        }}
      >
        <div
          className="side-menu"
          onClick={() => {
            setOpened(!opened);
          }}
        />
      </div>
    </React.Suspense>
  );
}
