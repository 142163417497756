import { fork, call, takeLatest, put } from '@redux-saga/core/effects';
import { centerRegistrationActions } from './centerRegistrationSlice';
import {
  registerCenterInfo as registerCenterInfoApi,
  fetchCenterInfo as fetchCenterInfoApi,
} from '../../../api/api';

function* registerCenterInfo(actions) {
  try {
    yield call(registerCenterInfoApi, actions.payload.data);
    actions.payload.onSuccess();
  } catch (error: any) {
    actions.payload.onError();
  }
}

function* fetchCenterInfo(actions) {
  try {
    const result = yield call(fetchCenterInfoApi, {});
    yield put(
      centerRegistrationActions.fetchCenterInfoSuccess(result.data.data),
    );
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {}
}

function* watchRegisterCenterInfo() {
  yield takeLatest(
    centerRegistrationActions.registerCenterInfo.type,
    registerCenterInfo,
  );
}

function* watchFetchCenterInfo() {
  yield takeLatest(
    centerRegistrationActions.fetchCenterInfo.type,
    fetchCenterInfo,
  );
}

export function* centerRegistration() {
  yield fork(watchRegisterCenterInfo);
  yield fork(watchFetchCenterInfo);
}
