/**
 * Update date: 23-05-2023
 * Screen 2024.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { memo, useRef } from 'react';

function DeliverDetail({ t, storeDetail }) {
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, DoubleFormat, QtyFormat } = useFormat();
  const dataGridRef: any = useRef(null);

  const columns: any = [
    {
      caption: t('Order'),
      alignment: 'center',
      items: [
        {
          dataField: 'allocate_seq',
          caption: t('Allocate Seq'),
          showWhenGrouped: true,
        },
        {
          dataField: 'ordbillid',
          caption: t('Bill ID'),
          showWhenGrouped: true,
        },
        {
          dataField: 'ordbilldate',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'ordvendernm',
          caption: t('Vendor'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'storenm',
          caption: t('Store'),
          showWhenGrouped: true,
        },
        {
          dataField: 'ordduedate',
          caption: t('Send Due Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'ordnumber',
          caption: t('Order Number'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'ordprogress',
          caption: t('Progress'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'way_bill',
          caption: t('Waybill'),
          showWhenGrouped: true,
        },
        {
          dataField: 'invoice_no',
          caption: t('Invoice No'),
          showWhenGrouped: true,
        },
        {
          dataField: 'delivery_agency_nm',
          caption: t('Shipping Company'),
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'prdcode',
          caption: t('Product code'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'prdname',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'prdmartablesz',
          caption: t('Marketable Size'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'prdoptioncd',
          caption: t('Option Code'),
          showWhenGrouped: true,
          visible: false,
          alignment: 'left',
        },
        {
          dataField: 'prdoptionnm',
          caption: t('Option'),
          showWhenGrouped: true,
          alignment: 'left',
        },
        {
          dataField: 'qtyperpack',
          caption: t('Quantity Per Pack'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      columns: [
        {
          dataField: 'cntunitprice',
          caption: t('Purchase Unit Price'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntqty',
          caption: t('Qty'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntea',
          caption: t('Ea'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntamount',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntsupplyamt',
          caption: t('Supply Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntvat',
          caption: t('VAT'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntbottle',
          caption: t('Bottle'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntcontainer',
          caption: t('Container'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cntgtotal',
          caption: t('G.Total'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      dataField: 'billnote',
      caption: t('Bill Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'linenote',
      caption: t('Line Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'bottleid',
      caption: t('Bottle ID'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'bottleunitprice',
      caption: t('Bottle Unit Price'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'bottlenm',
      caption: t('Container ID'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerunitprice',
      caption: t('Container Unit Price'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'currency',
      caption: t('Currency'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'mkr',
      caption: t('Maker'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'originName',
      caption: t('Origin'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'defvendornm',
      caption: t('Default Vendor'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'lgcate',
      caption: t('Large category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'mdcate',
      caption: t('Medium category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'smcate',
      caption: t('Small category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'subcate',
      caption: t('Sub category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerdm',
      caption: t('Register Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerusername',
      caption: t('Register User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerprogramnm',
      caption: t('Register Program ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifydm',
      caption: t('Modify Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyusername',
      caption: t('Modify User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyprogramnm',
      caption: t('Modify Program ID'),
      showWhenGrouped: true,
      visible: false,
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName = `2024.3 Shipping Request Details (Product)_${moment().format(
    'YYYYMMDD',
  )}`;

  return (
    <>
      <DataGridFull
        storageKeyInquiry={'detail_v1'}
        columns={columns}
        ref={dataGridRef}
        dataSource={storeDetail}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !storeDetail?._array?.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'cntqty',
                column: 'cntqty',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntamount',
                column: 'cntamount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntsupplyamt',
                column: 'cntsupplyamt',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntvat',
                column: 'cntvat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntbottle',
                column: 'cntbottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntcontainer',
                column: 'cntcontainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'cntgtotal',
                column: 'cntgtotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'cntea',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntqty',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntamount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntsupplyamt',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntvat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntbottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntcontainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'cntgtotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
    </>
  );
}

export default memo(DeliverDetail);
