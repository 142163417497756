/**
 * Update date: 31-05-2023
 * Screen 2051.B
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import { TRADE_TYPE } from 'constant';
import useDictionary from 'hooks/useDictionary';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2051_BContext';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2051_B = () => {
  const { t }: any = useDictionary({ programId: '2051.B' });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  const [params, setParams] = useState({
    inStock: null,
    startDuration: new Date(),
    endDuration: new Date(),
    dataList: {
      largeCategory: [],
      subCategory: [],
      mediumCategory: [],
      smallCategory: [],
      product: [],
      productGroup: [],
      productType: [],
    },
    largeCategory: '',
    subCategory: '',
    mediumCategory: '',
    smallCategory: '',
    product: '',
    productGroup: '',
    productType: '',
  });

  const [{ data, loading }, refetchStockCountDMA] = useAxios(
    {
      url: '/warehouse/instock/count-inquire-dma',
      method: 'POST',
    },
    { manual: true },
  );
  const store: any = new ArrayStore({
    data: data?.data?.map((o, idx) => ({
      ...o,
      id: idx + 1,
      tradeTypeName: t(
        TRADE_TYPE?.find(t => t?.value === o?.tradeType)?.text || '',
      ),
    })),
    key: 'id',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    store,
    params,
    setParams,
    refetchStockCountDMA,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading || loadingProductTpPermission} />
        <List />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2051_B;
