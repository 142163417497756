import { DataGridFull } from 'app/components/DataGrid';
import { useRef } from 'react';
import { useModuleContext } from '.';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
/**
 *  Data grid component
 *
 * @return {*}
 */
const List = () => {
  // Get context value
  const context: any = useModuleContext();
  const { t, store } = context || {};
  const dataGridRef: any = useRef(null);
  const { AmountFormat,QtyFormat }: any = useFormat();
  // Render column grid layout
  const columns = [
    {
      dataField: 'vendor_cd',
      caption: t('Vendor Code'),
      alignment: 'left',
      groupIndex: 0,
    },
    {
      dataField: 'vendor_nm',
      caption: t('Vendor Name'),
      alignment: 'left',
    },
    {
      dataField: 'receive_dt',
      caption: t('Trade date'),
      alignment: 'left',
    },
    {
      dataField: 'type_nm',
      caption: t('Trade Type'),
      alignment: 'left',
    },
    {
      dataField: 'product_cd',
      caption: t('Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'product_nm',
      caption: t('Product Name'),
      alignment: 'left',
    },
    {
      dataField: 'marketable_size',
      caption: t('Marketable Size'),
      alignment: 'left',
    },
    {
      dataField: 'option_nm',
      caption: t('Option Name'),
      alignment: 'left',
    },
    {
      dataField: 'receive_qt',
      caption: t('Qty'),
      dataType: 'number',
      format: QtyFormat,
    },
    {
      dataField: 'purchase_unit_price',
      caption: t('Unit Price'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'supply_amount',
      caption: t('Supply Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'vat_amount',
      caption: t('VAT'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'purchase_amount',
      caption: t('Purchase Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'payment_amount',
      caption: t('Payment Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'debit_amount',
      caption: t('Outstanding Balance'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'note',
      caption: t('Note'),
      alignment: 'left',
    },
  ];
  const onExportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  // File name export grid data
  const exportFileName = `SMDC_2082.G  Vendor Product Purchase Summary Table_${moment().format(
    'YYYYMMDD',
  )}`;
  return (
    <div>
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        columns={columns}
        fixedLeft={0}
        fixedRight={0}
        options={{
          columnAutoWidth: true,
          groupPanel: { visible: true },
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          export: { fileName: exportFileName },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !store?._array?.length,
                  onClick: () => onExportExcel(),
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                column: 'receive_qt',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'vat_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'purchase_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'payment_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'receive_qt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'purchase_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'payment_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
          },
        }}
      />
    </div>
  );
};

export default List;
