/**
 * Update date: 25-05-2023
 * Screen 2041.9
 */
import React, { useState, createContext, useContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import FormSearch from './FormSearch';
import List from './List';
import useDictionary from 'hooks/useDictionary';
/**
 * create context
 */
const ProductForwardingStandardPriceRegistrationContext = createContext({
  params: {},
  productForwardingData: [],
  setParams: () => {},
});

ProductForwardingStandardPriceRegistrationContext.displayName =
  'ProductForwardingStandardPriceRegistrationContext';

export const useProductForwardingStandardPrice = () => {
  const context = useContext(ProductForwardingStandardPriceRegistrationContext);
  return context;
};

export default function ExpirationDateRegistration() {
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      vendor: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      product: [],
      productGroup: [],
    },
    fromDate: new Date(),
    toDate: new Date(),
    vendor: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    register: '',
  });
  const [productForwardingData, setProductForwardingData] = useState([]);
  const { t }: any = useDictionary({ programId: '2041.9' });

  const dataStore: any = new ArrayStore({
    data: productForwardingData,
    key: ['line_id', 'receive_bill'],
  });

  const [{ data, loading: dataLoading }, refetchData] = useAxios(
    {
      method: 'POST',
      url: '/warehouse/expiration-date-regis',
    },
    { manual: true, autoCancel: true },
  );
  const [{ loading: loadSaving }, refetchSaving] = useAxios(
    {
      method: 'PUT',
      url: '/warehouse/expiration-date-regis',
    },
    { manual: true, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (data && !dataLoading) {
      setProductForwardingData(
        (data?.data || [])?.map((o, i) => ({
          ...o,
          ID: i + 1,
          status: 'normal',
          old_expiration_end_dt: o?.expiration_end_dt,
        })),
      );
    }
  }, [data, dataLoading]);

  const value: any = {
    formSearchData,
    setFormSearchData,
    dataStore,
    setProductForwardingData,
    refetchData,
    refetchSaving,
    t,
  };

  return (
    <ProductForwardingStandardPriceRegistrationContext.Provider value={value}>
      <>
        <LoadPanel visible={dataLoading || loadSaving} />
        <FormSearch />
        <List />
      </>
    </ProductForwardingStandardPriceRegistrationContext.Provider>
  );
}
