/**
 * Update date: 15-05-2023
 * Screen 1044.4
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, { createContext, useContext } from 'react';
import FormSearch from './FormSearch';
import ListReportStore from './ListReportVendor';

/**
 * create context
 */
const ReportStoreContext = createContext({});

ReportStoreContext.displayName = 'ReportStoreContext';

export const useReportStore = () => {
  const context = useContext(ReportStoreContext);
  return context;
};

export default function ReportStore() {
  // Get data vendor
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: '/user/store/report',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );
  const store: any = new ArrayStore({
    data: data?.data,
    key: 'storeId',
  });

  const value: any = {
    store,
    refetchData,
  };

  return (
    <ReportStoreContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        <FormSearch />
        <ListReportStore />
      </React.Suspense>
    </ReportStoreContext.Provider>
  );
}
