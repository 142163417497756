/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, CheckBox, Form, SelectBox, Tooltip } from 'devextreme-react';
import moment from 'moment';
import { useImperativeHandle, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { usePopupOrder } from '..';
import ShippingTracking from '../ShippingTracking';
import WayBill from '../WayBill';
import AddProduct from './AddProduct';
import { exportToExcel } from './export-excel';
import { sendPost } from 'api/api';
import ExchangeProduct from './ExchangeProduct';
import PopupConfirmWithRef from '../../PopupConfirmWithRef/PopupConfirmWithRef';
import PopupEditOrderPrice from './PopupEditOrderPrice';
import PopupEditOrderQty from './PopupEditOrderQty';
import PopupConfirmDeleteWithRef from '../../PopupConfirmWithRef/PopupConfirmDeleteWithRef';

// const mallCodeEnableAddBtn = ['AOS_O', 'AOS_E', 'AOS_R', 'AOS_C'];

export default function Products() {
  const {
    t,
    storeProduct,
    DateFormat,
    AmountFormat,
    statusSelect,
    formInformation,
    productRef,
    QtyFormat,
    numberToCurrency,
    refechtData,
  }: any = usePopupOrder();
  const dataGridRef: any = useRef(null);
  const formRef: any = useRef(null);
  const confirmRecollectionRef: any = useRef(null);
  const changePriceRef: any = useRef(null);
  const changeQtyRef: any = useRef(null);
  const confirmDeleteRef: any = useRef(null);
  const [productDelete, setProductDelete] = useState<any>({
    order_id: null,
    item_id: null,
  });
  const [product, setProduct] = useState({
    visible: false,
  });
  const [exchangeProduct, setExchangeProduct] = useState<any>({
    visible: false,
    data: null,
    product: null,
  });
  const [shippingTracking, setShippingTracking] = useState<any>({
    visible: false,
  });
  const [wayBill, setWayBill] = useState<any>({
    visible: false,
  });
  const productGridRef: any = useRef(null);
  useImperativeHandle(productRef, () => ({
    onSaveProduct,
  }));

  const [{ loading }, refetchData] = useAxios(
    {},
    { useCache: false, manual: true },
  );

  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: `/ecommerce/order-popup/product`,
      method: 'POST',
    },
    { useCache: false, manual: true },
  );

  /**
   * on add product
   *
   * @param {*} data
   */
  const handleAddProduct = async (data: any, isExchangeproduct: boolean) => {
    const url = isExchangeproduct
      ? `/ecommerce/order-popup/exchange-product`
      : `/ecommerce/order-popup/product`;
    const dataReq: any = {
      order_id: formInformation?.order_id,
      option_id: data?.option_id,
      option_cd: data?.option_cd,
      custom_variant_code: `${data?.product_id}${data?.option_id}`,
      product_cd: data?.product_cd,
      product_id: data?.product_id,
      supply_unit_price: data?.supply_unit_price,
      product_nm: data?.product_nm,
      quantity_per_pack: data?.quantity_per_pack,
      shipping_qty: data?.shipping_qty,
      product_tp: data?.product_tp,
      order_status: data?.order_status,
      variant_code: `${data?.product_id}${data?.option_id}`,
      bottle_unit_price: data?.bottle_unit_price,
      container_unit_price: data?.container_unit_price,
      bottle_id: data?.bottle_id,
      option_container_id: data?.option_container_id,

      shipping_method: data?.shipping_method,
      shipping_rate: data?.shipping_rate,
      shipping_fee: data?.shipping_fee,
      add_shipping_price: data?.add_shipping_price,
      add_shipping_amount: data?.add_shipping_amount,
      margin_rate: data?.margin_rate,
      bottle_amount: data?.bottle_amount,
      container_amount: data?.container_amount,
      total: data?.total,
      pay_unit_price: data?.pay_unit_price,
      mall_sell_unit_price: data?.mall_sell_unit_price,
    };
    if (isExchangeproduct) {
      dataReq.old_item_id = exchangeProduct?.product?.item_id;
    }
    const res: any = await refetchData({
      url: url,
      method: 'PUT',
      data: dataReq,
    });
    notification({ res });
    if (res?.data?.status === '201') {
      storeProduct
        ?.insert({
          ...res?.data?.data,
          id_no: 'no-' + storeProduct._array?.length,
        })
        .done(() => {
          productGridRef?.current?.instance?.refresh();
        });
      if (isExchangeproduct) {
        setExchangeProduct({ visible: false });
      } else {
        setProduct({ visible: false });
      }
    }
  };

  /**
   * on save
   *
   * @return {*}
   */
  const onSaveProduct = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid || !formInformation?.order_id) return;
    const res: any = await refetchUpdate({
      data: {
        order_id: formInformation?.order_id,
        date: `${moment(formInformation.productDate).format(
          'YYYY-MM-DD',
        )} ${moment(formInformation.productTime).format('HH:mm:ss')}`,
        orderList: storeProduct._array?.map(o => ({
          item_id: o.item_id,
          status: o.status,
        })),
      },
    });
    notification({ res });
  };

  const onRecollectionOrderData = async () => {
    const data = {
      mallCd: formInformation?.mall_cd,
      orderNumber: formInformation?.mall_order_id,
    };
    const res = await refetchData({
      url: 'ecommerce/order-popup/send-recollection',
      method: 'POST',
      data: data,
    });
    notification({ res });
    if (res?.data?.status === '200') {
      refechtData?.();
    }
  };

  /**
   * on save status
   *
   * @return {*}
   */
  const onSaveStatus = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid || !formInformation?.order_id) return;
    const res: any = await refetchUpdate({
      data: {
        order_id: formInformation?.order_id,
        orderList: storeProduct._array?.map(o => ({
          item_id: o.item_id,
          status: o.status,
        })),
      },
    });
    notification({ res });
  };

  const onDeleteProduct = async () => {
    const res: any = await refetchData({
      url: `/ecommerce/order-popup/delete-order-product`,
      method: 'DELETE',
      data: { ...productDelete },
    });
    notification({ res });
    if (res?.data?.status === '200') {
      refechtData?.();
    }
  };

  /**
   * render image
   *
   * @param {*} record
   * @return {*}
   */
  const renderProductImage = record => {
    const { product_option_img, id_no } = record?.data || {};
    return product_option_img ? (
      <div
        className="product-image-grid-custom cursor-pointer"
        id={`product-image-hover-${id_no}`}
        style={{ maxHeight: 75 }}
      >
        <img
          id={`product-image-hover-${id_no}`}
          style={{ marginTop: 5 }}
          src={product_option_img}
          width={70}
          height={70}
          alt=""
        />
        <Tooltip
          target={`#product-image-hover-${id_no}`}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          position="right"
        >
          <img src={product_option_img} width={300} height={300} alt="" />
        </Tooltip>
      </div>
    ) : null;
  };

  /**
   * on export
   *
   * @param {*} e
   */
  const onExporting = e => {
    try {
      exportToExcel({
        e,
        t,
        numberToCurrency,
        QtyFormat,
        AmountFormat,
        orderNumber: formInformation?.mall_order_id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * alter period
   *
   * @param {*} cell
   * @return {*}
   */
  const cellRenderSelectBox = record => {
    const { status, id_no, status_name, order_quantity, is_sendplan_forward } =
      record?.data || {};
    const currentStatusList = statusSelect.filter(
      o => o.code == status || o.code == 'C40',
    );
    return (
      <>
        {is_sendplan_forward ? (
          status_name
        ) : (
          <SelectBox
            items={currentStatusList}
            defaultValue={status}
            displayExpr="name"
            valueExpr="code"
            onValueChanged={e => {
              if (e) {
                const statusName = currentStatusList?.find?.(
                  o => o.code === e?.value,
                )?.name;
                storeProduct
                  ?.update(id_no, { status: e?.value, status_name: statusName })
                  .fail(() => {});
              }
            }}
          />
        )}

        {status == 'E20' ? (
          <div style={{ marginTop: 5 }}>
            <Button
              className="dx-button-padding-5"
              icon="refresh"
              text={t('Exchange Product')}
              onClick={() =>
                setExchangeProduct({
                  visible: true,
                  data: order_quantity,
                  product: record?.data,
                })
              }
            />
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  /**
   * update delivery on hold and priority allocation
   */
  const onUpdateHoldAndPriority = (
    id_no,
    order_id,
    item_id,
    value,
    updateHold = true,
  ) => {
    const url = updateHold
      ? '/ecommerce/order-popup/update-hold'
      : '/ecommerce/order-popup/update-priority';
    sendPost(url, { orderId: order_id, itemId: item_id, value }).then(res => {
      if (res?.data?.status == 200) {
        storeProduct?.update(id_no, {
          [updateHold ? 'out_hold' : 'priority_allocation']: value,
        });
      }
      notification({ res });
      dataGridRef?.current?.instance?.refresh();
    });
  };

  /**
   * render delivery on hold and priority allocation
   *
   * @param {*} record
   * @return {*}
   */
  const cellRenderHoldPriority = (record: any) => {
    const {
      id_no,
      order_id,
      item_id,
      out_hold = false,
      priority_allocation = false,
    } = record?.data || {};
    return (
      <div style={{ display: 'grid', alignItems: 'center', gap: 10 }}>
        <CheckBox
          defaultValue={out_hold}
          text={t('Delivery On Hold')}
          onValueChanged={e => {
            const value = e?.value || false;
            onUpdateHoldAndPriority(id_no, order_id, item_id, value, true);
          }}
        />
        <CheckBox
          defaultValue={priority_allocation}
          text={t('Priority Allocation')}
          onValueChanged={e => {
            const value = e?.value || false;
            onUpdateHoldAndPriority(id_no, order_id, item_id, value, false);
          }}
        />
      </div>
    );
  };

  const onCancelStockAllocation = async () => {
    let selectedKeys = storeProduct?._array?.map(o => o?.way_bill);
    const res = await refetchData({
      url: 'ecommerce/order-location',
      method: 'DELETE',
      data: selectedKeys,
    });

    if (res?.data?.status === '200') {
      notification({
        message: t('Stock allocation canceled'),
        type: 'success',
      });
      refechtData();
    } else {
      notification({
        res,
      });
    }
  };

  return (
    <div className="body-padding-white">
      <LoadPanel visible={loading || loadingUpdate} />
      <div style={{ position: 'relative', marginBottom: 10 }}>
        <div className="dx-form-group-caption">{t('Products')}</div>
        <div style={{ position: 'absolute', top: -5, right: 0 }}>
          <Button
            disabled={!formInformation?.order_modify_tf}
            stylingMode="contained"
            text={t('Add Product')}
            icon="plus"
            onClick={() => setProduct({ visible: true })}
          />
        </div>
      </div>
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ paddingRight: 5 }}>
            {t('Request Delivery time :')}
          </span>
          <Form
            labelLocation="top"
            showColonAfterLabel={false}
            formData={formInformation}
            ref={formRef}
            className="group-padding-small"
            colCount={2}
            width={250}
            items={[
              {
                label: { visible: false },
                dataField: 'productDate',
                editorType: 'dxDateBox',
                editorOptions: {
                  type: 'date',
                  displayFormat: DateFormat,
                  min: new Date(),
                },
                isRequired: true,
              },
              {
                label: { visible: false },
                dataField: 'productTime',
                editorType: 'dxDateBox',
                editorOptions: {
                  type: 'time',
                },
                isRequired: true,
              },
            ]}
          />
          <Button
            style={{ marginLeft: 4 }}
            stylingMode="contained"
            icon="check"
            type="default"
            onClick={() => onSaveProduct()}
            text={t('Change Request Delivery Time')}
          />
          <Button
            style={{ marginLeft: 4 }}
            stylingMode="contained"
            disabled={
              !storeProduct?._array?.every((o: any) => o?.status === 'N20')
            }
            icon="clear"
            type={
              storeProduct?._array?.every((o: any) => o?.status === 'N20')
                ? 'default'
                : 'normal'
            }
            onClick={() => onCancelStockAllocation()}
            text={t('Cancel Stock Allocation')}
          />
          <Button
            style={{ marginLeft: 4 }}
            stylingMode="contained"
            icon="refresh"
            type="default"
            onClick={() => refechtData()}
            text={t('Refresh')}
          />
          <Button
            style={{ marginLeft: 4 }}
            stylingMode="contained"
            icon="pulldown"
            type={formInformation?.is_recollection ? 'default' : 'normal'}
            onClick={() => {
              if (!formInformation?.is_recollection) return;
              confirmRecollectionRef?.current?.onOpen?.();
            }}
            text={t('Re-collection Of Order Data')}
            disabled={!formInformation?.is_recollection}
          />
        </div>
        <Button
          stylingMode="contained"
          icon="xlsxfile"
          onClick={() => exportExcel()}
          text={t('Export to Excel')}
        />
      </div>
      <DataGridFull
        storageKey="M2043_0_Product_Master_v2"
        dataSource={storeProduct}
        ref={dataGridRef}
        fixedLeft={1}
        fixedRight={1}
        options={{
          onExporting: onExporting,
          columnAutoWidth: true,
          columnMinWidth: 90,
          height: 'calc(100vh - 350px)',
          searchPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false,
          },
          filterPanel: {
            visible: false,
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          scrolling: {
            mode: 'infinite',
          },
          editing: {
            mode: 'cell',
            allowUpdating: true,
            selectTextOnEditStart: true,
          },
          onEditorPreparing: (e: any) => {
            if (['status'].includes(e.dataField)) {
              e.editorOptions.onValueChanged = async orgs => {
                storeProduct?.update(e?.row?.data?.id_no, {
                  [e.dataField]: orgs.value,
                });
              };
            }
          },
        }}
        columns={[
          {
            dataField: 'status',
            alignment: 'left',
            allowEditing: false,
            allowSorting: false,
            headerCellRender: () => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ paddingRight: 10, wordBreak: 'keep-all' }}>
                    {t('Status')}
                  </span>
                  <Button
                    className="dx-button-padding-5"
                    type="default"
                    icon="check"
                    onClick={() => onSaveStatus()}
                    text={t('Change Status')}
                  />
                </div>
              );
            },
            cellRender: cellRenderSelectBox,
          },
          {
            dataField: 'status_name',
            caption: t('Delivery Status'),
            alignment: 'left',
            allowEditing: false,
          },
          {
            dataField: 'Option',
            alignment: 'left',
            allowEditing: false,
            width: 220,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Item Code')}
                  <br />
                  {t('Picking Allocation')}
                  <br />
                  {t('Number Invoice')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  {e.data.order_item_code}
                  <br />[
                  <span
                    style={{ color: '#F04438', cursor: 'pointer' }}
                    onClick={() =>
                      setWayBill({
                        waybill: e.data.way_bill,
                        visible: true,
                      })
                    }
                  >
                    {e.data.way_bill}
                  </span>
                  ]
                  <br />[
                  <span
                    style={{ color: '#2E90FA', cursor: 'pointer' }}
                    onClick={() =>
                      setShippingTracking({
                        visible: true,
                        invoiceNo: e.data.invoice_no,
                      })
                    }
                  >
                    {e.data.invoice_no}
                  </span>
                  ]
                  {e?.data?.invoice_no && e?.data?.delivery_tracking_url && (
                    <>
                      <br />
                      <span
                        style={{ color: '#2E90FA', cursor: 'pointer' }}
                        onClick={() => {
                          const trackingUrl =
                            e?.data?.delivery_tracking_url.replace(
                              '{0}',
                              e?.data?.invoice_no,
                            );
                          window.open(trackingUrl, '_blank');
                        }}
                      >
                        {e?.data?.delivery_agency_nm}
                      </span>
                    </>
                  )}
                </div>
              );
            },
          },
          {
            dataField: 'product_cd',
            caption: t('Product Code'),
            alignment: 'left',
            allowEditing: false,
          },
          {
            dataField: 'product_option_img',
            caption: t('Product Image'),
            allowEditing: false,
            alignment: 'center',
            cellRender: renderProductImage,
          },
          {
            dataField: 'product_nm',
            caption: t('Product Name'),
            alignment: 'left',
            allowEditing: false,
          },
          {
            dataField: 'delivery_hold_priority',
            caption: t('Delivery On Hold/Priority Allocation'),
            alignment: 'left',
            allowEditing: false,
            allowSorting: false,
            cellRender: cellRenderHoldPriority,
            minWidth: 150,
          },
          {
            dataField: 'marketable_size',
            caption: t('Marketable Size'),
            alignment: 'left',
            allowEditing: false,
          },
          {
            dataField: 'Option1',
            alignment: 'left',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Option')}
                  <br />
                  {t('Quantity Per Pack')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  {e.data.option_nm}
                  <br />
                  {numberToCurrency(e.data.quantity_per_pack, QtyFormat)}
                </div>
              );
            },
          },
          {
            dataField: 'Option2',
            alignment: 'right',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Quantity')}
                  <br />
                  {t('Shipping Quantity')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {formInformation?.order_modify_tf ? (
                      <Button
                        icon="edit"
                        stylingMode="text"
                        onClick={() => changeQtyRef?.current?.onOpen?.(e.data)}
                        className="btn-edit-in-cell"
                      />
                    ) : (
                      <span></span>
                    )}
                    <span>
                      {numberToCurrency(e.data.order_quantity, QtyFormat)}
                    </span>
                  </div>
                  <span style={{ color: '#F04438', cursor: 'pointer' }}>
                    {numberToCurrency(e.data.shipping_quantity, QtyFormat)}
                  </span>
                </div>
              );
            },
            // width: 160,
          },
          {
            dataField: 'Option3',
            alignment: 'right',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Unit Price')}
                  <br />
                  {t('Supply Unit Price')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {formInformation?.order_modify_tf ? (
                      <Button
                        icon="edit"
                        stylingMode="text"
                        onClick={() =>
                          changePriceRef?.current?.onOpen?.(e.data)
                        }
                        className="btn-edit-in-cell"
                      />
                    ) : (
                      <span></span>
                    )}
                    <span>
                      {numberToCurrency(e.data.order_unit_price, AmountFormat)}
                    </span>
                  </div>
                  <span style={{ color: '#F04438', cursor: 'pointer' }}>
                    {numberToCurrency(
                      e.data.forward_supply_unit_price,
                      AmountFormat,
                    )}
                  </span>
                </div>
              );
            },
          },
          {
            dataField: 'Option4',
            alignment: 'right',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Amount')}
                  <br />
                  {t('Shipping Amount')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  {numberToCurrency(e.data.order_amount, AmountFormat)}
                  <br />
                  <span style={{ color: '#F04438', cursor: 'pointer' }}>
                    {numberToCurrency(
                      e.data.forward_shipping_amount,
                      AmountFormat,
                    )}
                  </span>
                </div>
              );
            },
          },
          {
            dataField: 'Option5',
            alignment: 'right',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Bottle')}
                  <br />
                  {t('Shipping Bottle')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  {numberToCurrency(e.data.order_bottle, AmountFormat)}
                  <br />
                  <span style={{ color: '#F04438', cursor: 'pointer' }}>
                    {numberToCurrency(
                      e.data.forward_shipping_bottle,
                      AmountFormat,
                    )}
                  </span>
                </div>
              );
            },
          },
          {
            dataField: 'Option6',
            alignment: 'right',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Container')}
                  <br />
                  {t('Shipping Container')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  {numberToCurrency(e.data.order_container, AmountFormat)}
                  <br />
                  <span style={{ color: '#F04438', cursor: 'pointer' }}>
                    {numberToCurrency(
                      e.data.forward_shipping_container,
                      AmountFormat,
                    )}
                  </span>
                </div>
              );
            },
          },
          {
            dataField: 'Option7',
            alignment: 'right',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Shipping Fee')}
                  <br />
                  {t('Shipping Fee')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  {numberToCurrency(e.data.order_shipping_fee, AmountFormat)}
                  <br />
                  <span style={{ color: '#F04438', cursor: 'pointer' }}>
                    {numberToCurrency(
                      e.data.forward_shipping_fee,
                      AmountFormat,
                    )}
                  </span>
                </div>
              );
            },
          },
          {
            dataField: 'Option8',
            alignment: 'right',
            allowEditing: false,
            headerCellRender: () => {
              return (
                <div>
                  {t('Order Total')}
                  <br />
                  {t('Shipping Total')}
                </div>
              );
            },
            cellRender: e => {
              return (
                <div>
                  {numberToCurrency(e.data.order_total, AmountFormat)}
                  <br />
                  <span style={{ color: '#F04438', cursor: 'pointer' }}>
                    {numberToCurrency(e.data.forward_total, AmountFormat)}
                  </span>
                </div>
              );
            },
          },
          {
            dataField: 'Option9',
            alignment: 'center',
            allowEditing: false,
            caption: t('Action'),
            cellRender: record => {
              const { order_id, item_id, is_sendplan_forward } =
                record?.data || {};
              return (
                <div
                  className={
                    formInformation?.order_modify_tf && !is_sendplan_forward
                      ? 'cell-link'
                      : ''
                  }
                  onClick={() => {
                    if (
                      !formInformation?.order_modify_tf ||
                      is_sendplan_forward
                    )
                      return;
                    setProductDelete({
                      order_id: order_id,
                      item_id: item_id,
                    });
                    confirmDeleteRef?.current?.onOpen?.();
                  }}
                >
                  {t('Delete')}
                </div>
              );
            },
          },
        ]}
      />
      {product?.visible && (
        <AddProduct
          onOk={data => handleAddProduct(data, false)}
          onClose={() => setProduct({ visible: false })}
        />
      )}
      {exchangeProduct?.visible && (
        <ExchangeProduct
          qty={exchangeProduct?.data || 0}
          currentPayUnitPrice={exchangeProduct?.product?.order_unit_price || 0}
          onOk={data => handleAddProduct(data, true)}
          onClose={() => setExchangeProduct({ visible: false })}
        />
      )}
      {shippingTracking?.visible && (
        <ShippingTracking
          invoiceNo={shippingTracking?.invoiceNo}
          onClose={() => setShippingTracking({ visible: false })}
        />
      )}
      {wayBill?.visible && (
        <WayBill
          waybill={wayBill?.waybill || ''}
          onClose={() => setWayBill({ visible: false })}
        />
      )}
      <PopupConfirmWithRef
        ref={confirmRecollectionRef}
        content={'Do you want to re-collection of order data?'}
        onOk={onRecollectionOrderData}
        isTranslate
      />
      <PopupConfirmDeleteWithRef
        ref={confirmDeleteRef}
        onOk={onDeleteProduct}
      />

      <PopupEditOrderPrice ref={changePriceRef} onOk={() => refechtData?.()} />
      <PopupEditOrderQty ref={changeQtyRef} onOk={() => refechtData?.()} />
    </div>
  );
}
