import useAxios from 'axios-hooks';
import { router } from 'constant/router';
import { Button, Form } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authActions, selectIsLoggedIn } from 'store/features/auth/authSlice';
import { centerRegistrationActions } from 'store/features/centerRegistration/centerRegistrationSlice';
import { mainMenuAction } from 'store/features/mainMenu/mainMenuSlice';
import { notification } from 'utils/notification';
import './styles.scss';
import { locale, loadMessages } from 'devextreme/localization';
import { useApp } from 'app';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import Favicon from 'react-favicon';
import { LANG_DEFAULT } from 'constant';

const eye = 'fas fa-eye';
const eyeSlash = 'fas fa-eye-slash';
export function Login() {
  const { setVoca, setFirstRender, setMyPageApp, themePro }: any = useApp();
  const [, setMyPagePreference] = useLocalStorage('myPagePreference', {});
  const centerInfo = JSON.parse(sessionStorage.getItem('centerInfo') || '{}');
  const [centerData, setCenterData] = useState<any>({});
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  // const layoutContext: any = useLayout();
  const history = useHistory();
  const { t }: any = useDictionary({});

  const [formData] = useState<any>({
    email: '',
    password: '',
  });
  const [viewPassword, setViewPassword] = useState({
    password: false,
    conPassword: false,
  });
  const formRef: any = useRef(null);

  const [{}, fetchMyPageData] = useAxios(
    {
      url: 'user/my-page',
      method: 'GET',
    },
    {
      manual: true,
    },
  );

  const [{}, fetchFavicon] = useAxios(
    {
      url: 'core/center-info/logo-favicon',
      method: 'GET',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const [{ loading: loadingMultiLanguage }, fetchDictionary] = useAxios(
    {
      url: '/core/multi-language',
      method: 'POST',
    },
    { manual: true },
  );

  useEffect(() => {
    dispatch(mainMenuAction.clearMenuData());
  }, [dispatch]);

  const fetchMainMenuSuccess = data => {
    history.push(router.home);
  };

  const fetchCenterInfoSuccess = data => {
    window.sessionStorage.setItem('centerInfo', JSON.stringify(data));
  };

  const loginSuccess = async () => {
    // if (layoutContext.collapse) {
    //   layoutContext.toggleSidebar();
    // }
    dispatch(mainMenuAction.fetchMenuData({ onSuccess: fetchMainMenuSuccess }));
    dispatch(
      centerRegistrationActions.fetchCenterInfo({
        onSuccess: fetchCenterInfoSuccess,
      }),
    );
    // Get my page data
    setTimeout(
      async () => {
        const myPagePreferenceLocal = JSON.parse(
          localStorage.getItem('myPagePreference') || '{}',
        );
        const res = await fetchMyPageData();
        setMyPagePreference({
          ...res?.data,
          themePro: myPagePreferenceLocal?.themePro,
        }); // Save setting to local storage
        getDictionary(res?.data?.language || LANG_DEFAULT);
        setMyPageApp(res?.data);
        setPageSetting(res?.data);
      },
      isMobile ? 1000 : 200,
    );
  };

  const handleSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const dataLogin = {
      ...formData,
      username: formData.email,
    };
    const params = {
      dataLogin,
      loginSuccess,
      loginFail,
    };
    dispatch(authActions.setDefaultMessage());
    dispatch(authActions.login(params));
  };

  const loginFail = () => {
    notification({
      message: 'Update Success',
      type: 'success',
    });
  };

  const getDictionary = async (lang: string) => {
    const res = await fetchDictionary({
      data: {
        type: 'ui',
        languageCode: lang,
      },
    });
    if (res?.data?.status === '200') {
      const messages = [...res?.data?.data].filter(
        o => o?.languageCode === lang,
      );
      let l: any = {};
      messages?.forEach((o, i) => {
        if (o?.translation || o?.base) {
          l[`${o?.id}-${o?.base}`] = o?.translation || o?.autoTranslation;
        }
      });
      loadMessages({
        [lang]: l,
      });
      setVoca(l);
      setFirstRender(false);
    }
  };

  const setPageSetting = (data: any) => {
    document.body.style.fontFamily = `${data?.fontName}, sans-serif`;
    // Set theme and font size
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `themes/dx.${data?.theme || 'light'}.${
      data?.fontSize || 'medium'
    }.css`;
    document.head.appendChild(link);
    locale(data?.language || LANG_DEFAULT);
  };

  useEffect(() => {
    return function cleanup() {
      dispatch(authActions.setDefaultMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const { faviconUrl } = centerInfo || {};
      const res = await fetchFavicon({});
      window.sessionStorage.setItem(
        'centerInfo',
        JSON.stringify(res?.data?.data),
      );
      setCenterData(res?.data?.data || {});
      // setImageLogin(res?.data?.data?.loginLogoUrl);
      if (faviconUrl !== res?.data?.data?.faviconUrl) {
        window.location.reload();
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn]);

  return (
    <>
      <Helmet>
        <title>{centerData?.centerName}</title>
      </Helmet>
      <Favicon url={centerData?.faviconUrl || ''} />
      <div className={`login ${themePro && 'login-theme-premium'}`}>
        <div className="content">
          {themePro ? (
            <div className="theme-premium">
              <div className="smdc">{centerData?.centerName || ' '}</div>
              <img
                src={centerData?.loginLogoUrl}
                style={{
                  maxWidth: '100%',
                  width: 'auto',
                  maxHeight: 250,
                  height: 'auto',
                }}
                alt="imagelogin"
              />
              <div className="welcome-back">{t('Welcome back')}</div>
              <span>{t('Welcome back! Please enter your details.')}</span>
            </div>
          ) : (
            <>
              <h3 className="title" style={{ marginBottom: 10 }}>
                {t('Login')}
              </h3>
              <div className="form-login" style={{ textAlign: 'center' }}>
                <img
                  src={centerData?.loginLogoUrl}
                  style={{
                    maxWidth: '100%',
                    width: 'auto',
                    maxHeight: 250,
                    height: 'auto',
                  }}
                  alt="imagelogin"
                />
              </div>
            </>
          )}
          <Form
            formData={formData}
            ref={formRef}
            showColonAfterLabel={false}
            className="form-login"
            scrollingEnabled={false}
            focusStateEnabled={false}
            labelLocation="top"
            items={[
              {
                dataField: 'email',
                editorType: 'dxTextBox',
                label: {
                  text: t('E-mail'),
                },
                editorOptions: {
                  mode: 'email',
                  onEnterKey: () => handleSubmit(),
                },
                isRequired: true,
                validationRules: [
                  {
                    type: 'email',
                  },
                ],
              },
              {
                dataField: 'password',
                editorType: 'dxTextBox',
                label: {
                  text: t('Password'),
                },
                editorOptions: {
                  onEnterKey: () => handleSubmit(),
                  mode: viewPassword?.password ? 'text' : 'password',
                  buttons: [
                    {
                      name: 'view',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: viewPassword?.password ? eye : eyeSlash,
                        onClick: () =>
                          setViewPassword(preState => ({
                            ...preState,
                            password: !preState.password,
                          })),
                      },
                    },
                  ],
                },
                isRequired: true,
              },
              {
                itemType: themePro ? 'button' : 'empty',
                buttonOptions: {
                  text: t('Login'),
                  type: 'default',
                  onClick: function () {
                    handleSubmit();
                  },
                },
              },
            ]}
          />
          {!themePro && (
            <div className="btn-submit">
              <Button
                text={t('Login')}
                type="default"
                stylingMode="contained"
                onClick={() => handleSubmit()}
                className="btn-login"
              />
            </div>
          )}
          {themePro && (
            <>
              <div className="create-account-signup">
                <span>{t('Don’t have an account?')}</span>
                <span onClick={() => history.push('/signup')}>
                  {' '}
                  {t('Sign Up')}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="footer">
          <div>{t('Small and Medium-sized distribution center in Korea')}</div>
          <div>{t('2021 All rights reserved. made by Doosoun-CNI')}</div>
        </div>
      </div>
    </>
  );
}
