/**
 * Update date: 23-05-2023
 * Screen 2021.3
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';

const List = React.lazy(() => import('./List'));
const ViewAndOrder = React.lazy(() => import('./ViewAndOrder'));

const defaultParams = {
  fromDate: new Date(),
  endDate: new Date(),
  subject: '',
  dataList: {
    vendor: [],
    manager: [],
  },
  vendor: '',
  manager: '',
};
  /**
   * create context
   */
const RegularOrderComfirmContext = createContext({});

RegularOrderComfirmContext.displayName = 'RegularOrderQuantityComfirmContext';

export const useRegularOrderComfirm = () => {
  const context = useContext(RegularOrderComfirmContext);
  return context;
};

const RegularOrderQuantityComfirm = () => {
  const { t }: any = useDictionary({
    programId: '2021.3',
  });
  const [formSearchData, setFormSearchData] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    regularOrderId: '',
  });

  const [{ data: resData, loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: '/warehouse/regular-order',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (resData?.data) {
      setData(resData?.data);
    }
  }, [resData]);

  const value: any = {
    t,
    formSearchData,
    setFormSearchData,
    data,
    refetchData,
    modeView,
    setModeView,
    setData,
  };

  /**
   * render content
   *
   * @return {*} 
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'order':
      case 'view':
        return <ViewAndOrder />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <RegularOrderComfirmContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </RegularOrderComfirmContext.Provider>
  );
};

export default RegularOrderQuantityComfirm;
