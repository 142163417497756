import { createSlice } from '@reduxjs/toolkit';

export interface menuState {
  listProgramHistory: Array<object>;
  listProgramBookMark: Array<object>;
  listProgramSearch: Array<object>;
  bookMarkActive: number;
  historyActive: number;
  searchActive: number;
  subMenuFixedActive: number;
}
const initialState: menuState = {
  listProgramHistory: [],
  listProgramBookMark: [],
  listProgramSearch: [],
  bookMarkActive: 0,
  historyActive: 0,
  searchActive: 0,
  subMenuFixedActive: -1,
};

const mainMenuFixedSlice = createSlice({
  name: 'mainMenuFixed',
  initialState,
  reducers: {
    setSearchListProgram(state, action) {
      state.listProgramSearch = action.payload;
    },
    fetchProgramBookMark(state, actions) {},
    fetchProgramHistory(state, actions) {},
    setListProgramSearch(state, action) {
      state.listProgramSearch = action.payload;
    },
    setListProgramBookMark(state, action) {
      state.listProgramBookMark = action.payload;
    },
    setListProgramHistory(state, action) {
      state.listProgramHistory = action.payload;
    },
    changeBookMarkActive(state, action) {
      state.bookMarkActive = action.payload;
    },
    changeSubMenuFixedActive(state, action) {
      state.subMenuFixedActive = action.payload;
    },
    changeHistoryActive(state, action) {
      state.historyActive = action.payload;
    },
    changeSearchActive(state, actions) {
      state.searchActive = actions.payload;
    },
    bookMarkUrl(state, action) {},
    removeProgramHistory(state, action) {},
    addProgramHistory(state, action) {},
    reOrderBookMark(state, actions) {},
  },
});
//Action
export const mainMenuFixedAction = mainMenuFixedSlice.actions;

//Selectors
export const selectListProgramHistory = (state: any) =>
  state.mainMenuFixed.listProgramHistory;
export const selectListProgramBookMark = (state: any) =>
  state.mainMenuFixed.listProgramBookMark;
export const selectListProgramSearch = (state: any) =>
  state.mainMenuFixed.listProgramSearch;
export const selectBookMarkActive = (state: any) =>
  state.mainMenuFixed.bookMarkActive;
export const selectSubMenuFixedActive = (state: any) =>
  state.mainMenuFixed.subMenuFixedActive;
export const selectSearchActive = (state: any) =>
  state.mainMenuFixed.searchActive;

const mainMenuFixedReducer = mainMenuFixedSlice.reducer;

export default mainMenuFixedReducer;
