/**
 * Update date: 09-06-2023
 * Popup select user center component
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';
interface IPopupSelectUserCenter {
  visible?: boolean;
  onHiding?: () => void;
  onSubmit?: void | any;
  mode?: 'single' | 'multiple';
  title?: string;
}

function PopupSelectUserCenter({
  visible,
  onHiding,
  onSubmit,
  mode = 'single',
  title,
}: IPopupSelectUserCenter) {
  const { themePro }: any = useApp();
  const dataRef: any = useRef(null);
  const { t } = useDictionary({});

  const [{ data: resData, loading }] = useAxios(
    {
      url: '/user/user/center',
      method: 'GET',
    },
    {
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on ok
   *
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!mode || mode === 'single') {
      const d = selectedRowKeys?.[0];
      onSubmit(d);
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  const loadingAll = loading;

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t(title)}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <DataGridFull
            ref={dataRef}
            dataSource={resData?.data}
            columns={[
              {
                dataField: 'userId',
                caption: t('ID'),
                alignment: 'left',
              },
              {
                dataField: 'name',
                caption: t('User'),
              },
              {
                dataField: 'email',
                caption: t('Email'),
              },
              {
                dataField: 'mobile',
                caption: t('Mobile No'),
              },
              {
                dataField: 'phone',
                caption: t('Phone No'),
              },
            ]}
            options={{
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode,
              },
              height: themePro
                ? 'calc(100vh - 150px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={title || t('User Center')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
    />
  );
}

export default PopupSelectUserCenter;
