/**
 * Update date: 31-05-2023
 * Screen 2052.3
 */
import React, { useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import Formsearch from './FormSearch';
import TableView from './TableView';
import LoadPanel from 'app/components/LoadPanel';
/**
 * create context
 */
const locationContext = React.createContext({
  refetchTable: () => {},
  refetchSubTable: () => {},
});
locationContext.displayName = 'locationContext';

export const useLocation = () => {
  const context = useContext(locationContext);
  return context;
};

function InventoryInqiry() {
  const [tableData, setTableData] = useState<any>();

  const [{ data: resData, loading: loadingLocation }, refetchTable] = useAxios(
    {
      url: '/product/inventory-product-location',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (resData?.data) {
      setTableData(resData?.data);
    }
  }, [resData]);

  const value: any = {
    tableData,
    refetchTable,
  };

  return (
    <>
      <locationContext.Provider value={value}>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel visible={loadingLocation} />
          <Formsearch />
          <TableView />
        </React.Suspense>
      </locationContext.Provider>
    </>
  );
}

export default InventoryInqiry;
