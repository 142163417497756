/**
 * Update date: 06-06-2023
 * Screen 2061.5
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import useProfileInfo from 'hooks/useProfileInfo';
import React, { createContext, useContext, useState, useEffect } from 'react';

const List = React.lazy(() => import('./List'));
const Products = React.lazy(() => import('./View'));
/**
 * create context
 */
const DirectShippingPriorityPCContext = createContext({});

DirectShippingPriorityPCContext.displayName = 'DirectShippingPriorityPCContext';

export const useDirectShippingPriorityPC = () => {
  return useContext(DirectShippingPriorityPCContext);
};

const DirectShippingPriorityPC = () => {
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    item: null,
  });
  const userInfo = useProfileInfo();
  const isDelivery = userInfo?.roleId === 4;

  const [formSearchData, setFormSearchData] = useState<any>({
    date: new Date(),
    delivery: false,
    sendUser: '',
  });
  const [sendUserData, setSendUserData] = useState<any>([]);

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: resData, loading: loadingSendUser }, fetchSendUser] = useAxios(
    {
      url: '/user/user',
      method: 'POST',
      data: { roleType: 'Delivery' },
    },
    { manual: true, useCache: false, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!resData?.data) {
      return;
    }
    setSendUserData(resData?.data);
  }, [resData]);

  const value: any = {
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    sendUserData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <Products />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (!isDelivery) {
      fetchSendUser();
    } else {
      setSendUserData([{ name: userInfo?.name, userId: userInfo?.userId }]);
    }
  }, [isDelivery, fetchSendUser]);

  return (
    <DirectShippingPriorityPCContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingSendUser} />
        {renderContent()}
      </React.Suspense>
    </DirectShippingPriorityPCContext.Provider>
  );
};

export default DirectShippingPriorityPC;
