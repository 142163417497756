/**
 * Update date: 31-05-2023
 * Screen 2052.A
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext, useEffect } from 'react';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import ArrayStore from 'devextreme/data/array_store';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2052_A_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2052_A = () => {
  const { t }: any = useDictionary({});
  const [data, setData] = useState([]);
  const [eslTemp, setEslTemp] = useState<any>([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      zone: [],
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      productGroup: [],
      product: [],
      marker: [],
      loadType: [],
    },
    zone: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    productGroup: '',
    product: '',
    marker: '',
    loadType: '',
    assignedLocation: false,
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    zone: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: eslTempData, loading: eslTempLoading }] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
      data: { codeName: '', codeType: 'EL' },
    },
    {
      manual: false,
      useCache: false,
      autoCancel: true,
    },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'location_id',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!eslTempData?.data?.length) return;
    const dataMapping = eslTempData?.data?.map(o => ({
      code: o?.code,
      name: o?.name,
    }));
    setEslTemp(dataMapping);
  }, [eslTempData]);

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const zoneId = formSearchData?.dataList?.zone?.[0]?.zoneId || null;
    if (!zoneId) return;
    const requestData = {
      zoneId: zoneId,
      assignedLocation: formSearchData?.assignedLocation || false,
      largeCategoryCodes: formSearchData?.dataList?.largeCategory?.map(
        (o: any) => o?.code,
      ),
      mediumCategoryCodes: formSearchData?.dataList?.mediumCategory?.map(
        (o: any) => o?.code,
      ),
      smallCategoryCodes: formSearchData?.dataList?.smallCategory?.map(
        (o: any) => o?.code,
      ),
      subCategoryCodes: formSearchData?.dataList?.subCategory?.map(
        (o: any) => o?.code,
      ),
      makers: formSearchData?.dataList?.maker?.map((o: any) => o?.code),
      loadTypes: formSearchData?.dataList?.loadType?.map((o: any) => o?.code),
      productGroupIds: formSearchData?.dataList?.productGroup?.map(
        (o: any) => o?.productGroupId,
      ),
      productIds: formSearchData?.dataList?.product?.map(
        (o: any) => o?.productId,
      ),
    };

    const res = await refetchData({
      url: '/warehouse/product-location',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      setData(res?.data?.data || []);
    } else {
      notification({
        res,
      });
    }
  };

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    store,
    eslTemp,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || eslTempLoading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2052_A;
