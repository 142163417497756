function useFormatTime() {
  const DateFormat = 'yyyy-MM-dd';
  const MonthFormat = 'yyyy-MM';
  const TimeFormat = 'HH:mm:ss';
  const DateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
  const DateTimeCompactFormat = 'yyyy-MM-dd HH:mm';

  return {
    MonthFormat,
    DateFormat,
    TimeFormat,
    DateTimeFormat,
    DateTimeCompactFormat,
  };
}

export default useFormatTime;
