/**
 * Update date: 08-06-2023
 * My page component
 */
import React, { useState, useRef } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import { Button, Form } from 'devextreme-react';
import useAxios from 'axios-hooks';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/features/auth/authSlice';
import { notification } from 'utils/notification';
import { useMyPage } from '..';
import { CONSTANT } from 'constant';
import { passwordSecurityDto } from './password-security-dto';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import IconEye from 'images/icon_eye.svg';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import { useLayout } from 'app/components/Layout';

const initialFormData = passwordSecurityDto;

const PasswordSecurity = () => {
  const { setShowOtherPage }: any = useLayout();
  const { passwordRegexp } = CONSTANT;
  const myPageContext: any = useMyPage();
  const { themePro }: any = useApp();
  const { setModeView } = myPageContext || {};
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    ...initialFormData,
  });
  const formRef: any = useRef(null);
  const dispatch = useDispatch();

  const { t }: any = useDictionary({
    programId: '1031.7',
  });

  const [{ loading: changePassLoading }, changePassword] = useAxios(
    {
      url: 'user/my-page/change-password',
      method: 'PUT',
    },
    {
      manual: true,
    },
  );

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;

    if (isValid) {
      const res = await changePassword({ data: { ...formData } });
      notification({
        res,
      });
      if (res?.data?.status == '201') {
        setShowOtherPage('');
        dispatch(authActions.logout());
      }
    }
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    setModeView(preState => ({ ...preState, type: 'list' }));
  };

  return (
    <>
      <LoadPanel visible={changePassLoading} />
      {themePro && (
        <div className="my-page-caption">
          <div>
            <div className="my-page-caption-title">
              {t('Password & Security')}
            </div>
            <div className="my-page-caption-des">
              {t('Set password and security.')}
            </div>
          </div>
          <Button
            icon="check"
            stylingMode="contained"
            type="default"
            text={t('Save')}
            onClick={onSubmit}
          />
        </div>
      )}
      <Form
        ref={formRef}
        formData={formData}
        colCount={1}
        labelLocation="top"
        showColonAfterLabel={false}
        scrollingEnabled={true}
        focusStateEnabled={true}
        hoverStateEnabled={true}
        items={[
          {
            caption: themePro ? '' : t('Password and Security'),
            itemType: 'group',
            colCount: 2,
            items: [
              {
                itemType: 'group',
                items: [
                  {
                    label: {
                      text: t('Two-step Verification'),
                    },
                    dataField: 'twoStepVerify',
                    editorType: 'dxSwitch',
                    editorOptions: {
                      disabled: true,
                    },
                  },
                  {
                    label: {
                      text: t('Old Password'),
                    },
                    dataField: 'oldPassword',
                    editorOptions: {
                      mode: showOldPassword ? 'text' : 'password',
                      buttons: [
                        {
                          name: 'password',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            icon: IconEye,
                            onClick: () =>
                              setShowOldPassword(preState => !preState),
                          },
                        },
                      ],
                    },
                    helpText: t(
                      'For security reasons, you must verify your existing password before you may set a new password',
                    ),
                    validationRules: [
                      {
                        type: 'required',
                        message: t('Old Password is required'),
                      },
                    ],
                  },
                  {
                    label: {
                      text: t('New Password'),
                    },
                    editorOptions: {
                      mode: showPassword ? 'text' : 'password',
                      buttons: [
                        {
                          name: 'password',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            icon: IconEye,
                            onClick: () =>
                              setShowPassword(preState => !preState),
                          },
                        },
                      ],
                    },
                    dataField: 'newPassword',
                    validationRules: [
                      {
                        type: 'required',
                        message: t('New Password is required'),
                      },
                      {
                        type: 'pattern',
                        pattern: passwordRegexp,
                        message: t(
                          'Password must contain at least 8 characters including number, letter, special character',
                        ),
                      },
                    ],
                  },
                  {
                    label: {
                      text: t('Confirm Password'),
                    },
                    dataField: 'confirmPassword',
                    editorOptions: {
                      mode: showConfirmPassword ? 'text' : 'password',
                      buttons: [
                        {
                          name: 'confirm-password',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            icon: IconEye,
                            onClick: () =>
                              setShowConfirmPassword(prevState => !prevState),
                          },
                        },
                      ],
                    },
                    editorType: 'dxTextBox',
                    validationRules: [
                      {
                        type: 'required',
                        message: t('Confirm Password is required'),
                      },
                      {
                        type: 'compare',
                        message: t(
                          '"New Password" and "Confirm Password" do not match',
                        ),
                        comparisonTarget: () => {
                          return formData?.newPassword;
                        },
                      },
                      {
                        type: 'pattern',
                        pattern: passwordRegexp,
                        message: t(
                          'Password must contain at least 8 characters including number, letter, special character',
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ]}
      ></Form>
      {!themePro && (
        <div className="my-page-btn-footer">
          <Button icon="save" text={t('Save')} onClick={onSubmit} />
          <Button
            icon="close"
            text={t('Cancel')}
            onClick={() => setShowPopupConfirm(true)}
          />
        </div>
      )}

      {showPopupConfirm && (
        <PopupConfirm
          title={t('Confirm Cancel')}
          visible={showPopupConfirm}
          content={t('Are you sure to cancel all the change?')}
          onOk={onCancel}
          onHiding={() => setShowPopupConfirm(false)}
        />
      )}
    </>
  );
};

export default PasswordSecurity;
