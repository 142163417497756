/**
 * Update date: 25-01-2024
 * Screen 1042.7
 */
import React, { createContext, useContext, useState } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import { NotFoundPage } from 'app/components/NotFoundPage';
import CreateOrUpdate from './CreateOrUpdate';
import List from './List';

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'ESLDesignSetting';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const ESLDesignSetting = () => {
  const { t }: any = useDictionary({ programId: '1042.7' });
  const [eslDesignList, setESLDesignList] = useState<any>([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <CreateOrUpdate />;
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    t,
    modeView,
    setModeView,
    refetchData,
    eslDesignList,
    setESLDesignList,
    loadingFetchData,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default ESLDesignSetting;
