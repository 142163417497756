/**
 * Update date: 2023-06-23
 * Screen 2046.D
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';

const List = React.lazy(() => import('./List'));
const ForwardReturnPopup = React.lazy(() => import('./ForwardReturnPopup'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2046_D_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Send Delivery Tracking
 *
 * @return {*}
 */
const M2046_D = () => {
  const [dataLocal, setDataLocal] = useLocalStorage('2046.D_mst_dataList', {});
  // Initialize state
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    taskType: 0,
    dataList: {
      storeId: [],
      receivableCd: [],
      zone: [],
    },
    storeId: '',
    receivableCd: '',
    zone: '',
  });

  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setFormData(prev => ({
      ...prev,
      dataList: {
        ...prev.dataList,
        zone: dataLocal?.zone || [],
      },
    }));
  }, [dataLocal]);

  // Values share with child component
  const value: any = {
    data,
    setData,
    formData,
    setFormData,
    modeView,
    setModeView,
    setDataLocal,
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <ForwardReturnPopup />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2046_D;
