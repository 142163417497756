/**
 * Update date: 15-05-2023
 * Screen 1046.A
 */
import React, { useState, useContext, createContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));

/**
 * create context
 */
const MallLinkedInfoManagementContext = createContext({});

MallLinkedInfoManagementContext.displayName = 'MallLinkedInfoManagementContext';

export const useMallLinkedInfoManagement = () => {
  const context = useContext(MallLinkedInfoManagementContext);
  return context;
};

const MallLinkedInformationManagement = () => {
  const { t }: any = useDictionary({});
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    mallInfoId: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    t,
    data,
    setData,
    refetchData,
    modeView,
    setModeView,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <MallLinkedInfoManagementContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </MallLinkedInfoManagementContext.Provider>
  );
};

export default MallLinkedInformationManagement;
