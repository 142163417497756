/**
 * Update date: 08-06-2023
 * Datagrid full component
 */

import { Template } from 'devextreme-react/core/template';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { dxDataGridColumn } from 'devextreme/ui/data_grid';
import useDictionary from 'hooks/useDictionary';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import PopupProductDetail from '../PopupCommon/PopupProductDetail';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useWrap } from '../WrapContent/WrapContent';
import { CURRENT_USER } from 'constant';
import { loadMessages } from 'devextreme/localization';
import { cloneDeep } from 'lodash';

export interface DataGridProp {
  dataSource?: any;
  columns?: dxDataGridColumn | any;
  options?: any;
  ref?: any;
  storageKey?: string;
  isShowZeroNumber?: boolean;
  fixedRight?: number;
  fixedLeft?: number;
  storageKeyInquiry?: boolean | string;
  isStorageFilter?: boolean;
  customClass?: string;
}

const productKeys: any = [
  'productName',
  'productNm',
  'prdname',
  'product_nm',
  'productCode',
  'productCd',
  'prdcode',
  'product_cd',
  'prdcd',
  'productCdName',
  'prdnm',
  'productnm',
  'productcd',
  'productname',
  'productcode',
  'product_name',
  'product_code',
];

const DataGridFull = (
  {
    dataSource,
    columns,
    options,
    storageKey,
    isShowZeroNumber = false,
    fixedRight = 3,
    fixedLeft = 3,
    storageKeyInquiry = false,
    isStorageFilter = true,
    customClass = '',
  }: DataGridProp,
  ref,
) => {
  useSelector((state: any) => {
    if (!state?.nav?.value) {
      ref?.current?.instance?.refresh?.();
    }
  });
  const { t } = useDictionary({});
  const { dataPage }: any = useWrap();
  const popupRef: any = useRef();
  const [storageName, setStorageName] = useState('');
  const totalCount = useCallback(() => {
    const count = dataSource?.length || dataSource?._array?.length || 0;
    if (!count) return '';
    return (
      <>
        <span className="data-grid-result">{t('Results')}</span>
        <span className="data-grid-result-data">{count}</span>
      </>
    );
  }, [dataSource]);

  window['dataGrid'] = ref;
  window['store'] = ref;

  const customColumns = useMemo(() => {
    const columnIndexs = columns?.map((o, i) => ({
      ...o,
      index: i,
    }));
    const columnVisible = columnIndexs
      ?.filter(
        o =>
          (o?.visible || o?.visible === undefined) &&
          !o?.columns?.length &&
          !o?.items?.length,
      )
      ?.map((o, i, array) =>
        i < fixedLeft
          ? { ...o, fixedPosition: 'left', fixed: true }
          : i + fixedRight + 1 > array?.length
          ? { ...o, fixedPosition: 'right', fixed: true }
          : { ...o, fixed: false },
      );
    const columnGroup = columnIndexs?.filter(o => o?.columns || o?.items);
    const columnHidden = columnIndexs?.filter(
      o => o?.visible === false && !o?.columns?.length && !o?.items?.length,
    );
    return [...columnVisible, ...columnGroup, ...columnHidden].sort(
      (a, b) => a?.index - b?.index,
    );
  }, [columns]);

  const customizeColumns = columns => {
    return;
    // columns.forEach(function (col) {
    //   if (col?.dataType === 'number') {
    //     col.calculateDisplayValue = function (rowData) {
    //       if (rowData[col.dataField] === 0) {
    //         return '';
    //       }
    //       return rowData[col.dataField];
    //     };
    //   }
    // });
  };

  // On cell Prepared
  const onCellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataType === 'number' && e.value < 0) {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.allowEditing === true) {
      e.cellElement.bgColor = '#f0f0f0';
    }
    if (
      e.rowType === 'data' &&
      e.column.dataType === 'number' &&
      e?.value === 0 &&
      (e.isEditing === false || e.column.allowEditing === false) &&
      !isShowZeroNumber
    ) {
      e.cellElement.innerHTML = '';
    }
  };
  // Customize cell product
  const cellRenderProduct = (e: any) => {
    const { productId, product_id, productid, classify } = e?.row?.data;
    return (
      <div
        onDoubleClick={event => {
          if (classify === '1' || !classify) {
            return;
          }
          popupRef.current?.onShowPopup(
            productId || product_id || productid || undefined,
          );
          event.stopPropagation();
        }}
        style={
          classify === '0'
            ? { color: '#51b9af' }
            : classify === '1'
            ? {}
            : { color: 'red' }
        }
      >
        {e?.text}
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      loadMessages({
        en: {
          Cancel: 'Close',
        },
        ko: {
          Cancel: '닫기',
        },
      });
    }, 1000);
  }, []);

  useEffect(() => {
    const userName =
      JSON.parse(localStorage.getItem(CURRENT_USER) || '{}')?.name || '';
    if (storageKeyInquiry && dataPage) {
      if (typeof storageKeyInquiry === 'boolean') {
        setStorageName(`storage_inquiry_${userName}_${dataPage?.programId}`);
      } else if (typeof storageKeyInquiry === 'string') {
        setStorageName(
          `storage_inquiry_${userName}_${dataPage?.programId}_${storageKeyInquiry}`,
        );
      }
    }
  }, [storageKeyInquiry, dataPage]);

  return (
    <>
      <DataGrid
        ref={ref}
        dataSource={dataSource || []}
        columnChooser={{
          enabled: true,
          mode: 'select',
        }}
        className={`${storageName && 'storageKeyInquiry'} ${customClass || ''}`}
        columnAutoWidth={options?.columnAutoWidth || true}
        wordWrapEnabled={true}
        columnFixing={{
          enabled: true,
        }}
        filterPanel={{
          visible: true,
        }}
        headerFilter={{
          visible: true,
          allowSearch: true,
        }}
        filterRow={{
          visible: true,
        }}
        allowColumnResizing={true}
        activeStateEnabled={true}
        scrolling={{
          mode: 'standard',
          scrollByContent: true,
          scrollByThumb: true,
          showScrollbar: 'onHover',
        }}
        hoverStateEnabled={true}
        highlightChanges={true}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        pager={
          {
            allowedPageSizes: [10, 20, 50, 100],
            displayMode: 'compact',
            showNavigationButtons: true,
            showPageSizeSelector: true,
            showInfo: true,
          }
          // options?.groupPanel?.visible
          //   ? {
          //       allowedPageSizes: [10, 20, 50, 100],
          //       displayMode: 'compact',
          //       showNavigationButtons: true,
          //       visible: true,
          //       showPageSizeSelector: true,
          //       showInfo: true,
          //     }
          //   : {
          //       allowedPageSizes: [10, 20, 50, 100],
          //       displayMode: 'compact',
          //       showNavigationButtons: true,
          //       showPageSizeSelector: true,
          //       showInfo: true,
          //     }
        }
        stateStoring={{
          customSave(gridState) {
            if (!storageName) return;
            let state = cloneDeep(gridState);
            state.selectedRowKeys = [];
            if (!isStorageFilter) {
              state.filterValue = [];
            }
            localStorage.setItem(storageName, JSON.stringify(state));
          },
          customLoad() {
            if (!storageName) return {};
            const state = localStorage.getItem(storageName) || '{}';
            return state ? JSON.parse(state) : {};
          },
          enabled: !!storageName || !!storageKey,
          type: storageName ? 'custom' : 'sessionStorage',
          storageKey: storageName || storageKey,
          savingTimeout: 200,
        }}
        loadPanel={{
          enabled: false,
        }}
        searchPanel={{
          visible: true,
          highlightSearchText: true,
          highlightCaseSensitive: true,
        }}
        grouping={{
          contextMenuEnabled: options?.groupPanel?.visible,
        }}
        customizeColumns={customizeColumns}
        onCellPrepared={onCellPrepared}
        // onCellDblClick={e => {
        //   if (
        //     productKeys.includes(e?.column?.dataField || '') &&
        //     e?.rowType === 'data'
        //   ) {
        //     setShowPopupProductDetail({
        //       visible: true,
        //       productId: e?.row?.data?.productId || undefined,
        //     });
        //   }
        // }}
        onContentReady={(e: any) => {
          var columnChooserView = e.component.getView('columnChooserView');
          columnChooserView?.render();
          columnChooserView?._popupContainer?.option('position', {
            of: e.element,
            my: 'top right',
            at: 'top right',
            offset: '0 57',
          });
        }}
        onContextMenuPreparing={(e: any) => {
          if (e.target === 'headerPanel') {
            if (!e.items) e.items = [];
            if (options?.groupPanel?.visible) {
              e.items.push(
                {
                  text: t('Expand All'),
                  onItemClick: () => {
                    e.component.expandAll();
                  },
                },
                {
                  text: t('Collapse All'),
                  onItemClick: () => {
                    e.component.collapseAll();
                  },
                },
              );
            }
          }
        }}
        {...options}
        // config to select all single page
        selection={
          options?.selection
            ? {
                ...options?.selection,
                selectAllMode: options?.selection?.isAllPages
                  ? 'allPages'
                  : 'page',
              }
            : {}
        }
        // height={'78vh'}
      >
        {/* {(columns || []).map(o => (
        <Column key={Math.random()} {...o}>
          {o?.items && o?.items.map(v => <Column key={Math.random()} {...v} />)}
        </Column>
      ))} */}
        {(customColumns || []).map(({ columns, items, ...o }) => {
          if (productKeys.includes(o?.dataField)) {
            return (
              <Column
                key={Math.random()}
                {...o}
                cellRender={
                  options?.hiddenDetailProduct
                    ? undefined
                    : o?.cellRender || cellRenderProduct
                }
              >
                {(columns || items) &&
                  (columns || items)?.map(v => {
                    if (productKeys.includes(v?.dataField)) {
                      return (
                        <Column
                          key={Math.random()}
                          {...v}
                          cellRender={
                            options?.hiddenDetailProduct
                              ? undefined
                              : o?.cellRender || cellRenderProduct
                          }
                        />
                      );
                    } else {
                      return <Column key={Math.random()} {...v} />;
                    }
                  })}
              </Column>
            );
          } else {
            return (
              <Column key={Math.random()} {...o}>
                {(columns || items) &&
                  (columns || items)?.map(v => {
                    if (productKeys.includes(v?.dataField)) {
                      return (
                        <Column
                          key={Math.random()}
                          {...v}
                          cellRender={
                            options?.hiddenDetailProduct
                              ? undefined
                              : o?.cellRender || cellRenderProduct
                          }
                        />
                      );
                    } else {
                      return <Column key={Math.random()} {...v} />;
                    }
                  })}
              </Column>
            );
          }
        })}
        <Template name="totalCount" render={totalCount} />
      </DataGrid>
      <PopupProductDetail ref={popupRef} />
    </>
  );
};

export default forwardRef(DataGridFull);
