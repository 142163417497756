/**
 * Update date: 26-05-2023
 * Screen 2043.6
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useModuleContext } from '..';
const TableContent = React.lazy(() => import('./TableContent'));
/**
 * create context
 */
const FormProductContext = createContext({});
export const useFormProduct = () => {
  return useContext(FormProductContext);
};

function FormProduct() {
  const { modeView, storedReceivableCds }: any = useModuleContext();
  const orderId = modeView?.orderId;

  const [listProduct, setListProduct] = useState<any>([]);

  const refTableContent: any = useRef(null);

  const [
    { data: resApiListProduct, loading: loadingApiListProduct },
    apiListProduct,
  ] = useAxios(
    {
      url: `/ecommerce/orders/product/${orderId}`,
      method: 'GET',
      params: storedReceivableCds?.length
        ? {
            storedReceivableCds: storedReceivableCds.reduce(
              (f, s) => `${f},${s}`,
            ),
          }
        : {},
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const value: any = {
    listProduct,
    orderDetail: resApiListProduct?.data,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiListProduct?.data) {
      const data = resApiListProduct?.data?.map((o, i) => ({
        ...o,
        ID: i + 1,
      }));
      setListProduct(data);
    }
  }, [resApiListProduct]);

  useEffect(() => {
    if (orderId) {
      apiListProduct();
    }
  }, [orderId]);

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingApiListProduct} />
      <FormProductContext.Provider value={value}>
        <TableContent ref={refTableContent} />
      </FormProductContext.Provider>
    </React.Suspense>
  );
}

export default FormProduct;
