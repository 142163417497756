/**
 * Update date: 26-01-2024
 * Popup matching code component
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';
interface IPopupMatchingCode {
  visible?: boolean;
  onHiding?: () => void;
  title?: string;
  designId?: number;
  productQty?: number;
}

function PopupMatchingCode({
  visible,
  onHiding,
  title,
  designId = 0,
  productQty = 0,
}: Readonly<IPopupMatchingCode>) {
  const dataRef: any = useRef(null);
  const { t } = useDictionary({});
  const [resData, setResData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([
    {
      caption: t('Product 1'),
      alignment: 'center',
      columns: [
        {
          dataField: 'matchingCd',
          caption: t('Matching code'),
          allowEditing: false,
        },
        {
          dataField: 'matchingCdNm',
          caption: t('Matching code Name'),
          allowEditing: false,
        },
        {
          dataField: 'eslCd',
          caption: t('ESL code'),
          cssClass: 'gridcell-editing',
          editorType: 'dxTextBox',
        },
      ],
    },
  ]);

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, useCache: false, autoCancel: true },
  );

  useEffect(() => {
    if (!designId || productQty < 1) return;
    const getEslMatchingCode = async () => {
      const res = await refetchData({
        url: `/core/esl-design-setting/matching-code/${designId}/${productQty}`,
        method: 'GET',
      });
      if (res?.data?.status != '200') {
        notification({ res });
      }
      const matchingCodeEC = res?.data?.data?.matchingCodeEC || [];
      const matchingData = res?.data?.data?.matchingData || [];
      const columnRender: Array<any> = [];
      const mappingCodeData = matchingCodeEC.map((c, codeIdx) => {
        const rowData: any = { ID: codeIdx + 1 };
        for (let index = 1; index <= productQty; index++) {
          const eslCd =
            matchingData.find(
              m => m.productSeq == index && m.matchingCd == c.code,
            )?.eslCd || '';
          rowData[`code_${index}`] = c.code;
          rowData[`name_${index}`] = c.name;
          rowData[`esl_${index}`] = eslCd;
        }
        return rowData;
      });
      for (let index = 1; index <= productQty; index++) {
        columnRender.push({
          caption: `${t('Product')} ${index}`,
          alignment: 'center',
          columns: [
            {
              dataField: `code_${index}`,
              caption: t('Matching code'),
              allowEditing: false,
              allowSorting: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              dataField: `name_${index}`,
              caption: t('Matching code Name'),
              allowEditing: false,
              allowSorting: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              dataField: `esl_${index}`,
              caption: t('ESL code'),
              cssClass: 'gridcell-editing',
              editorType: 'dxTextBox',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
          ],
        });
      }
      setColumns(columnRender);
      setResData(mappingCodeData);
    };
    getEslMatchingCode();
  }, []);

  /**
   * on ok
   *
   */
  const onOk = async () => {
    const dataSaveMapping: Array<any> = [];
    resData.forEach((item: any) => {
      for (let i = 1; i <= productQty; i++) {
        const newObj = {
          matchingCd: item[`code_${i}`],
          eslCd: item[`esl_${i}`],
          productSeq: i,
        };
        dataSaveMapping.push(newObj);
      }
    });
    const dataRequest: any = {
      eslDesignId: designId,
      productQty: productQty,
      dataRequest: dataSaveMapping.sort((a, b) => a.productSeq - b.productSeq),
    };

    const res = await refetchData({
      url: '/core/esl-design-setting/matching-code',
      method: 'POST',
      data: dataRequest,
    });
    if (res?.data?.status === '200') {
      notification({ res });
      onHiding?.();
    } else {
      notification({ res });
    }
  };

  const autoInputNextCell = (e: any) => {
    if (e?.parentType === 'dataRow') {
      e.editorOptions.onKeyDown = function (ekey) {
        if (ekey?.event?.key === 'Enter' || ekey?.event?.keyCode === 13) {
          const dataGrid = dataRef.current.instance;
          const columns = dataGrid.getVisibleColumns();
          const currentColumnIndex = columns.findIndex(
            col => col.dataField === e.dataField,
          );
          const nextRowIndex = e.row.rowIndex + 1;
          let nextElem;
          if (nextRowIndex < dataGrid.totalCount()) {
            nextElem = dataGrid.getCellElement(
              nextRowIndex,
              columns[currentColumnIndex].dataField,
            );
          } else {
            const nextColumnIndex = (currentColumnIndex + 3) % columns.length;
            nextElem = dataGrid.getCellElement(
              0,
              columns[nextColumnIndex].dataField,
            );
          }
          if (!nextElem) return;
          ekey?.event?.stopPropagation();
          dataGrid?.focus(nextElem);
          nextElem?.click();
        }
      };
    }
  };

  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading} />
          <div className="modal-popup-header">
            <span className="title-page">
              {title} - {t('Matching Code')}
            </span>
            <div>
              <Button
                stylingMode="contained"
                type="default"
                text={t('Save')}
                icon="check"
                style={{ marginRight: 5 }}
                width={91}
                onClick={onOk}
              />
              <Button
                stylingMode="contained"
                text={t('Cancel')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
          <DataGridFull
            ref={dataRef}
            dataSource={resData}
            columns={columns}
            options={{
              paging: { enabled: false },
              filterRow: { visible: false },
              filterPanel: { visible: false },
              searchPanel: { visible: false },
              columnChooser: { enabled: false },
              allowColumnReordering: false,
              height: isMobile ? 'calc(80vh - 200px)' : 'calc(100vh - 120px)',
              columnAutoWidth: true,
              editing: {
                mode: 'cell',
                allowUpdating: true,
                selectTextOnEditStart: true,
              },
              onEditorPreparing: autoInputNextCell,
              onRowValidating: e => {
                Object.entries(e.newData).forEach(([key, value]) => {
                  const isDuplicate = resData.some(
                    row =>
                      row[key] === (value as string).trim() && row[key] !== '',
                  );
                  if (isDuplicate) {
                    e.isValid = false;
                    e.errorText = t('Duplicated value');
                  } else {
                    e.newData[key] = (value as string).trim();
                  }
                });
              },
            }}
          />
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={title}
      contentRender={content}
      height={isMobile ? '80vh' : '100vh'}
      width={
        isMobile
          ? '96%'
          : productQty == 3
          ? '80vw'
          : productQty == 2
          ? '65vw'
          : '50vw'
      }
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupMatchingCode;
