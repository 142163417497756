/**
 * Update date: 17-05-2023
 * Screen 2011.5
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import ProductMasterTable from './TableView';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
import { useProductType } from 'hooks/useProductType';
import useDictionary from 'hooks/useDictionary';
import { TRADE_TYPE } from 'constant';

/**
 * create context
 */
const ProductMaster = createContext({
  refetchData: () => {},
  loadingData: false,
  productData: [],
});

ProductMaster.displayName = 'ProductMaster';

export const useProductMaster = () => {
  const context = useContext(ProductMaster);
  return context;
};

function ProductMasterInquiry() {
  const { t }: any = useDictionary({
    programId: '2011.5',
  });
  const [productData, setProductData] = useState([]);
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [{ data, loading: loadingProductData }, refetchData] = useAxios(
    {
      url: '/product/product-master-inquiry',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    const resData = data?.data;
    if (!data?.data) return;
    const dataMapping = resData.map(o => {
      const type = TRADE_TYPE?.find(f => f.value == o?.trade)?.text;
      o.tradeTypeName = t(type);
      o.stockAllocationTypeName =
        o?.stock_allocation_tp === '0'
          ? t('Stock high')
          : o?.stock_allocation_tp === '1'
          ? t('Stock low')
          : '';
      o.taxTypeName =
        o?.tax_tp === '0'
          ? t('Taxation')
          : o?.tax_tp === '1'
          ? t('Tax free')
          : o?.tax_tp === '2'
          ? t('Tax zero')
          : '';
      o.package_tp_nm =
        o?.package_tp === '0'
          ? t('Single product package')
          : o?.package_tp === '1'
          ? t('Combined product package')
          : '';
      return o;
    });
    setProductData(dataMapping);
  }, [data]);

  const value: any = {
    t,
    productTpPermission,
    refetchData,
    productData,
    loadingProductData,
  };

  return (
    <>
      <ProductMaster.Provider value={value}>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel
            visible={loadingProductData || loadingProductTpPermission}
          />
          <FormSearch />
          <ProductMasterTable />
        </React.Suspense>
      </ProductMaster.Provider>
    </>
  );
}

export default ProductMasterInquiry;
