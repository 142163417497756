/**
 * Update date: 29-05-2023
 * Screen 2044.7
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useFormat from 'hooks/useFormat';
import React, { useRef } from 'react';
import { useStoreContext } from './';

interface IDetailTable {
  visible?: boolean;
  onHiding?: () => void;
}

const DetailTable = ({ visible, onHiding }: IDetailTable) => {
  const { themePro }: any = useApp();
  const gridRef: any = useRef(null);
  const context: any = useStoreContext();
  const { t, detail } = context;
  const { QtyFormat, AmountFormat } = useFormat();

  const columns: any = [
    {
      dataField: 'storeId',
      caption: t('Store Id'),
      alignment: themePro ? 'left' : 'center',
      visible: false,
    },
    {
      dataField: 'storeNm',
      caption: t('Store'),
    },
    {
      dataField: 'productId',
      caption: t('Product Id'),
      alignment: themePro ? 'left' : 'center',
      visible: false,
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'productNm',
      caption: t('Product'),
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'optionId',
      caption: t('Option Id'),
      alignment: themePro ? 'left' : 'center',
      visible: false,
    },
    {
      dataField: 'optionCd',
      caption: t('Option Code'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
    },
    {
      dataField: 'barcodeId',
      caption: t('Barcode Id'),
      alignment: themePro ? 'left' : 'center',
      visible: false,
    },
    {
      dataField: 'barcode',
      caption: t('Barcode'),
      alignment: themePro ? 'left' : 'center',
    },
    {
      dataField: 'quantity',
      caption: t('Quantity'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'amount',
      caption: t('Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
  ];

  /**
   * render content
   *
   */
  const content = () => (
    <ScrollView height="100%">
      <div style={{ padding: '0 15px' }}>
        {themePro && (
          <div className="modal-popup-header">
            <span className="title-page">{t('Order Detail Info')}</span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
        )}
        <DataGridFull
          storageKeyInquiry="detail"
          ref={gridRef}
          dataSource={detail}
          columns={columns}
          options={{
            columnAutoWidth: true,
            scrolling: {
              columnRenderingMode: 'virtual',
            },
            selection: {
              mode: 'single',
              selectAllMode: 'allPages',
              showCheckBoxesMode: 'onClick',
            },
            height: themePro ? 'calc(100vh - 150px)' : 'auto',
            onToolbarPreparing: (e: any) => {},
            summary: {
              totalItems: [
                {
                  column: 'quantity',
                  summaryType: 'sum',
                  valueFormat: QtyFormat,
                  displayFormat: '{0}',
                  dataType: 'number',
                },
                {
                  column: 'amount',
                  summaryType: 'sum',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  dataType: 'number',
                },
              ],
            },
          }}
        />
      </div>
    </ScrollView>
  );

  return (
    <React.Fragment>
      <Popup
        className="modal-content-popup"
        visible={visible}
        onHiding={onHiding}
        title={t('Order Detail Info')}
        contentRender={content}
        width={'60%'}
        height={themePro ? '100vh' : '100%'}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
    </React.Fragment>
  );
};

export default DetailTable;
