
/**
 * Update date: 17-05-2023
 * Screen 2011.6
 */
import React, { createContext, useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ProductExpirationTable from './TableView';
import FormSearch from './FormSearch';
import useDictionary from 'hooks/useDictionary';
import { useProductType } from 'hooks/useProductType';
import useLocalStorage from 'hooks/useLocalStorage';

/**
 * create context
 */
const storeContext = createContext({});

storeContext.displayName = 'productDetail';
export const useStoreContext = () => {
  const context = useContext(storeContext);
  return context;
};

function ProductExpirationDate() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({
    programId: '2011.6',
  });
  const [dataLocal, setDataLocal] = useLocalStorage('2011.6_dataList', {});
  const [productData, setProductData] = useState<any>([]);
  const [formStore, setFormStore] = useState<any>({
    largeCategory: [],
    mediumCategory: [],
    smallCategory: [],
    subCategory: [],
    productTypes: [],
    productMakers: [],
    productGroup: [],
    product: [],
    vendor: [],
  });
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    productTypes: '',
    productMakers: '',
    productGroup: '',
    product: '',
    vendor: '',
    tradeType: '0',
  });

  useEffect(() => {
    if (!dataLocal) return;
    setFormData(prev => ({
      ...prev,
      ...dataLocal,
    }));
  }, [dataLocal]);


  const [{ data, loading: loading }, refetchData] = useAxios(
    {
      url: '/product/product-expiration',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const [{ data: updateData, loading: loadingUpdate }, fetchUpdate] = useAxios(
    {
      url: '/product/product-expiration',
      method: 'PUT',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  /**
   * on search
   *
   * @param {*} data
   */
  const onSearch = async data => {
    const res: any = await refetchData({
      data,
    });
  };

  const value: any = {
    refetchData,
    fetchUpdate,
    productData,
    updateData,
    onSearch,
    formData,
    setFormData,
    formStore,
    setFormStore,
    dataLocal,
    setDataLocal,
  };

  /**
   * fetch data
   */
  useEffect(() => {
    const refineData = data?.data?.map((o: any) => {
      if (
        o?.periodTp === null ||
        o?.periodTp === '' ||
        o?.periodTp === undefined
      ) {
        return { ...o, periodTp: '1' };
      }
      return o;
    });
    setProductData(refineData);
  }, [data]);

  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormData(preState => ({
      ...preState,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
    setFormStore(preState => ({
      ...preState,
      productTypes: productTpPermission,
    }));
  }, [productTpPermission]);

  return (
    <storeContext.Provider value={value}>
      <LoadPanel
        visible={loading || loadingUpdate || loadingProductTpPermission}
      />
      <FormSearch onSearch={onSearch} />
      <ProductExpirationTable />
    </storeContext.Provider>
  );
}

export default ProductExpirationDate;
