/**
 * Update date: 12-05-2023
 * Screen 1042.3
 */

import { useApp } from 'app';
import { Button } from 'devextreme-react';
import TreeView from 'devextreme-react/tree-view';
import useDictionary from 'hooks/useDictionary';
import { forwardRef } from 'react';
import { notification } from 'utils/notification';
import { useCategoryRegistration } from '..';

function TreeViewList(props, ref) {
  const { themePro }: any = useApp();
  const rootContext: any = useCategoryRegistration();
  const {
    treeList,
    setTreeList,
    setSelected,
    isCreatedCategory,
    setIsCreatedCategory,
  } = rootContext;

  const { t }: any = useDictionary({});

  /**
   * add category
   *
   * @param {string} type
   * @param {*} e
   * @return {*}
   */
  const addCategory = (type: string, e: any) => {
    if (isCreatedCategory) {
      return notification({
        message: t(
          'There is an unsaved category. Please save to create more category!',
        ),
        type: 'error',
      });
    }
    ref?.current?.instance?.unselectAll();
    const newItem = {
      id: '',
      code: '',
      parentId: type === 'childCategory' ? `${e?.codeType}_${e?.code}` : null,
      parentCd: type === 'childCategory' ? e?.code : '',
      codeType: 'LC',
      name: `Large Category ${treeList?.length !== 0 ? treeList?.length : ''}`,
      use: true,
      note: '',
      isCreate: true,
    };
    switch (e?.codeType) {
      case 'LC':
        newItem.name = 'Medium Category';
        newItem.codeType = 'MC';
        break;
      case 'MC':
        newItem.name = 'Small Category';
        newItem.codeType = 'SC';
        break;
      case 'SC':
        newItem.name = 'Sub Category';
        newItem.codeType = 'UC';
        break;
      default:
        break;
    }
    newItem.id = `${newItem?.codeType}_`;

    setTreeList(preState => {
      preState.push(newItem);
      return [...preState];
    });

    setIsCreatedCategory(true);
    setSelected({ type: 'create', data: newItem });
    setTimeout(() => {
      ref?.current?.instance?.expandItem(e);
      ref?.current?.instance?.selectItem(newItem);
      ref?.current?.instance?.scrollToItem(newItem);
    }, 500);
  };

  /**
   * on mapping data children
   *
   * @param {*} data
   * @return {*}
   */
  const onMappingDataChildren = data => {
    const dataMaping: Array<any> = [];
    if (data?.length === 0) {
      return dataMaping;
    }
    data.forEach(o => {
      if (o?.itemData?.isCreate) return;

      dataMaping.push({
        code: o?.itemData?.code,
        codeType: o?.itemData?.codeType,
      });
      const dataChildren: any = onMappingDataChildren(o.children);
      dataMaping.push(...dataChildren);
    });
    return dataMaping;
  };

  /**
   * on selected category
   *
   * @param {*} e
   */
  const onSelectedCategory = e => {
    const categoryData = { ...e?.itemData, children: [] };
    const dataChildrenCode = onMappingDataChildren(e?.node?.children);
    categoryData.children = dataChildrenCode;
    setSelected({
      type: categoryData?.isCreate ? 'create' : 'edit',
      data: categoryData,
    });
  };

  /**
   * render tree list
   *
   * @param {*} e
   * @return {*}
   */
  const renderTreeList = e => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '36px',
          height: 'auto',
          paddingLeft: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {e.name}
        </div>
        {e?.codeType !== 'UC' && (
          <Button
            className="program-registration-button"
            icon="plus"
            type="normal"
            stylingMode={themePro ? 'contained' : 'text'}
            onClick={(event: any) => {
              addCategory('childCategory', e);
              event?.event?.stopPropagation();
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div
      style={
        themePro
          ? {
              padding: 20,
              backgroundColor: '#fff',
              borderRadius: 6,
              width: 400,
            }
          : { borderRight: '1px solid #ccc', padding: 20 }
      }
    >
      <Button
        text={t('Add Large Category')}
        type="normal"
        stylingMode="contained"
        style={themePro ? { margin: 0 } : { marginBottom: 20 }}
        onClick={() => addCategory('newCategory', {})}
      />
      {themePro && (
        <div
          style={{
            paddingTop: 10,
            borderBottom: '1px solid #ccc',
            marginBottom: 10,
          }}
        />
      )}
      <TreeView
        selectionMode="single"
        expandNodesRecursive={false}
        dataStructure="plain"
        ref={ref}
        items={treeList}
        itemRender={renderTreeList}
        width={350}
        height={650}
        displayExpr="name"
        onItemClick={onSelectedCategory}
      />
    </div>
  );
}

export default forwardRef(TreeViewList);
