/**
 * Update date: 07-06-2023
 * Screen 2081.5
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import { useProductType } from 'hooks/useProductType';
import { createContext, useContext, useState, useEffect } from 'react';
import FormSearch from './FormSearch';
import List from './List';
/**
 * create context
 */
const DepositCheckByOrderContext = createContext({});

DepositCheckByOrderContext.displayName = 'DepositCheckByOrderContext';

export const useDepositCheckByOrder = () => {
  const context = useContext(DepositCheckByOrderContext);
  return context;
};

export default function DepositCheckByOrder() {
  const { t }: any = useDictionary({ programId: '2081.5' });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    deposit: '',
    invoice: '',
    isReadOnlyProductTp: false,
    dataList: {
      mall: [],
      productType: [],
      store: [],
      sendUser: [],
    },
    mall: '',
    productType: '',
    store: '',
    sendUser: '',
  });
  const [{ data, loading: dataLoading }, refetchData] = useAxios(
    {
      method: 'POST',
      url: '/ecommerce/deposit-check-order',
    },
    { manual: true, autoCancel: true },
  );
  const [{ loading: loadSaving }, refetchSaving] = useAxios(
    {
      method: 'PUT',
      url: '/ecommerce/deposit-check-order/deposit',
    },
    { manual: true, autoCancel: true },
  );

  const dataStore: any = new ArrayStore({
    data: data?.data?.map(o => {
      o.tracking_tp_nm = t(o?.tracking_tp_nm);
      o.payment_status = t(o?.payment_status);
      return o;
    }),
    key: ['order_id', 'invoice_no'],
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    formSearchData,
    setFormSearchData,
    dataStore,
    refetchData,
    refetchSaving,
    t,
  };

  return (
    <DepositCheckByOrderContext.Provider value={value}>
      <>
        <LoadPanel
          visible={dataLoading || loadSaving || loadingProductTpPermission}
        />
        <FormSearch />
        <List />
      </>
    </DepositCheckByOrderContext.Provider>
  );
}
