/**
 * Update date: 15-05-2023
 * Screen 1044.1
 */
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import ListVendor from './ListVendor';
import FormCreate from './FormCreate';

/**
 * create context
 */
const RegisteringVendorContext = createContext({});

RegisteringVendorContext.displayName = 'RegisteringVendorContext';

export const useRegisteringVendor = () => {
  const context = useContext(RegisteringVendorContext);
  return context;
};
const defaultParams = {
  vendorName: '',
  vendorType: '',
  use: [true],
  vendorRepresentative: '',
  orderByName: 'vendorId',
  orderBy: false,
};
export default function RegisteringVendor() {
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    ...defaultParams,
    formStore: {
      vendorType: [],
    },
  });
  const [listData, setListData] = useState<any>([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: 'user/vendor',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: listData,
    key: 'vendorId',
  });

  /**
   * on update data
   *
   * @param {*} { data, type }
   */
  const onUpdateData = ({ data, type }) => {
    if (type === 'create') {
      setListData(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
      dataGridRef?.current?.instance?.refresh();
    } else {
      store
        .update(data?.vendorId, data)
        .done(() => {})
        .fail(() => {});
    }
    setModeView({ type: 'list' });
  };

  const value: any = {
    store,
    params,
    setParams,
    setModeView,
    modeView,
    onUpdateData,
    refetchData,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListVendor ref={dataGridRef} />;
      case 'create':
      case 'edit':
        return <FormCreate />;
      default:
        return <NotFoundPage />;
    }
  };
  useEffect(() => {
    if (data?.data) {
      setListData(data?.data);
    }
  }, [data, loading]);
  const loadingAll = loading;

  return (
    <RegisteringVendorContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </RegisteringVendorContext.Provider>
  );
}
