/**
 * Update date: 12-05-2023
 * Screen 1043.1
 */

import React, { useState, useContext, createContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ListRegistration from './ListUserRegistration';
import FormCreate from './FormCreate';
import ArrayStore from 'devextreme/data/array_store';

/**
 * create context
 */
const UserRegistrationContext = createContext({});

UserRegistrationContext.displayName = 'UserRegistrationContext';

export const useRegistration = () => {
  const context = useContext(UserRegistrationContext);
  return context;
};

const UserRegistration = () => {
  const [itemSelected, setItemSelected] = useState('0');
  const [formSearchData] = useState({ userName: '' });
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [{ loading }, refetchData] = useAxios(
    {
      url: 'user/user',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );
  const [listData, setListData] = useState({
    '0': [], //Center Operation
    '1': [], //Vendor
    '2': [], //Store
    '3': [], //Delivery
  });

  const store: any = new ArrayStore({
    data: listData?.[itemSelected],
    key: 'userId',
  });
  /**
   * on update data
   * @param {*} record
   */
  const onUpdateData = (record: any) => {
    const data = [...listData[itemSelected]];
    const existItem = data?.some(
      (o: any) => o?.userId === record?.data?.userId,
    );
    let l: any = [];
    if (existItem) {
      l = [...data]?.map((o: any) => {
        if (o?.userId == record?.data?.userId) return record?.data;
        return o;
      });
    } else {
      l = [...data];
      l.unshift(record?.data);
    }
    setListData(preState => ({
      ...preState,
      [itemSelected]: l,
    }));
    if (record?.type === 'list') {
      setModeView({
        type: 'list',
      });
    }
  };

  const value: any = {
    setModeView,
    modeView,
    onUpdateData,
    listData,
    setListData,
    store,
    refetchData,
    itemSelected,
    setItemSelected,
    formSearchData,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListRegistration />;
      case 'create':
      case 'edit':
        return <FormCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  const loadingAll = loading;

  return (
    <UserRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </UserRegistrationContext.Provider>
  );
};

export default UserRegistration;
