/**
 * Update date: 09-06-2023
 * Not auth menu component
 */
import React, { useContext } from 'react';
import { NOT_AUTH_SIDEBAR } from 'constant/router';
import { NavLink } from 'react-router-dom';
import { LayoutContext } from '../Layout';
import useDictionary from 'hooks/useDictionary';
const classes: any = {
  icon: {
    marginRight: 10,
    marginLeft: 10,
    width: 15,
    height: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    display: 'block !important',
    flex: 1,
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const NotAuthMenu = () => {
  const layOutContext = useContext(LayoutContext);
  const { t } = useDictionary({});

  return (
    <ul className="tabs">
      {NOT_AUTH_SIDEBAR.map((item, index) => (
        <NavLink
          to={item.to}
          className="tab-link custom-color-white"
          activeClassName="custom-color-primary custom-bg-white"
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
          }}
          data-tab={`tab-${index}`}
          title={t(item.nameMenu)}
          key={item.to}
        >
          <i className={`${item.icon}`} style={classes.icon} />
          {!layOutContext.collapse && (
            <span style={classes.name}>{t(item.nameMenu)}</span>
          )}
        </NavLink>
      ))}
    </ul>
  );
};

export default NotAuthMenu;
