/**
 * Update date: 23-05-2023
 * Screen 2024.1
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useProductType } from 'hooks/useProductType';

const List = React.lazy(() => import('./List'));
const Product = React.lazy(() => import('./Product'));
const Detail = React.lazy(() => import('./Detail'));
const CancelShippingRequest = React.lazy(
  () => import('./CancelShippingRequest'),
);

/**
 * create context
 */
const ConsignmentShippingRequestContext = createContext({});

ConsignmentShippingRequestContext.displayName =
  'ConsignmentShippingRequestContext';

export const useConsignmentShippingRequest = () => {
  const context = useContext(ConsignmentShippingRequestContext);
  return context;
};

const ConsignmentShippingRequest = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [moduleData, setModuleData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    orderId: '',
    isCancelShippingRequest: false,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      mall: [],
      productType: [],
      storeGroup: [],
      store: [],
      vendor: [],
    },
    mallCds: [],
    storeGroupIds: [],
    storeIds: [],
    productTypes: [],
    vendorIds: [],
  });

  const [formInfoData, setFormInfoData] = useState<any>({
    sendDue: new Date(),
    ctOrderDate: new Date(),
    note: '',
    dataList: {
      sendUser: [],
      zone: [],
    },
    sendUser: '',
    zone: '',
  });

  const [dataCancelShippingRequest, setDataCancelShippingRequest] = useState(
    [],
  );
  const [formSearchCancel, setFormSearchCancel] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      sendUser: [],
      vendor: [],
    },
    userIds: [],
    vendorIds: [],
    waybill: '',
  });
  const store: any = new ArrayStore({
    data: moduleData,
    key: 'orderId',
  });
  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    productTpPermission,
    store,
    moduleData,
    setModuleData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    formInfoData,
    setFormInfoData,
    //cancel stock
    dataCancelShippingRequest,
    setDataCancelShippingRequest,
    formSearchCancel,
    setFormSearchCancel,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'product':
        return <Product />;
      case 'detail':
        return <Detail />;
      case 'cancelShippingRequest':
        return <CancelShippingRequest />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ConsignmentShippingRequestContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingProductTpPermission} />
        {renderContent()}
      </React.Suspense>
    </ConsignmentShippingRequestContext.Provider>
  );
};

export default ConsignmentShippingRequest;
