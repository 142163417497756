/**
 * Update date: 31-05-2023
 * Screen 2046.6
 */
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

const ListForced = React.lazy(() => import('./ListForced'));
const CreateForced = React.lazy(() => import('./CreateForced'));

/**
 * create context
 */
const ForcedCenterReturnContext = createContext({
  params: {},
  setParams: () => {},
  refetchForced: () => {},
  store: {} || null,
  loadingForced: false,
  modeView: {},
  setModeView: () => {},
});

ForcedCenterReturnContext.displayName = 'ForcedCenterReturnContext';

export const useForcedCenter = () => {
  const context = useContext(ForcedCenterReturnContext);
  return context;
};

function ForcedCenterReturn(props) {
  const [dataLocal, setDataLocal] = useLocalStorage('2046.6_mst_dataList', {});
  const { t }: any = useDictionary({
    programId: '2046.6',
  });
  const [params, setParams] = useState({
    store: '',
    returnState: '',
    shipmentType: '',
    fromDate: new Date(),
    toDate: new Date(),
    keyword: '',
    formStore: {
      store: [],
      returnState: [],
      shipmentType: [],
      zone: [],
    },
    zone: '',
  });
  const [modeView, setModeView] = useState({ type: 'list' });
  const [listDataForced, setListDataForced] = useState<any>([]);
  const dataGridRef: any = useRef(null);
  const [{ data, loading }, refetchForced] = useAxios(
    {
      url: '/warehouse/return',
      method: 'POST',
      data: { ...params },
    },
    { manual: true },
  );
  const store: any = new ArrayStore({
    data: listDataForced,
    key: 'returnId',
  });

  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setParams(prev => ({
      ...prev,
      formStore: {
        ...prev.formStore,
        zone: dataLocal?.zone || [],
      },
    }));
  }, [dataLocal]);

  useEffect(() => {
    if (data?.data) {
      const dataMapping = (data?.data || []).map(o => {
        o.returnStatusNm = o?.returnStatus === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setListDataForced(dataMapping);
    }
  }, [data]);

  /**
   * on update
   *
   * @param {*} data
   * @param {*} type
   */
  const onUpdateListForced = (data, type) => {
    if (data) {
      data.returnStatusNm =
        data?.returnStatus === '0' ? t('Pending') : t('Fixed');
    }
    if (type === 'add') {
      setListDataForced(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
    } else {
      store
        .update(data?.returnId, data)
        .done(() => {})
        .fail(() => {});
    }
    dataGridRef?.current?.instance?.refresh();
  };
  /**
   * on delete
   *
   * @param {number[]} ids
   */
  const onDeleteListForced = (ids: number[]) => {
    const list = listDataForced.filter(
      (filter: any) => !ids.some(some => some === filter?.returnId),
    );
    setListDataForced(list);
  };

  const value: any = {
    t,
    store,
    params,
    setParams,
    refetchForced,
    modeView,
    setModeView,
    onUpdateListForced,
    onDeleteListForced,
    setDataLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListForced ref={dataGridRef} />;
      case 'create':
      case 'edit':
      case 'view':
        return <CreateForced />;
      default:
        return <NotFoundPage />;
    }
  };
  const loadingAll = loading;
  return (
    <ForcedCenterReturnContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ForcedCenterReturnContext.Provider>
  );
}

export default ForcedCenterReturn;
