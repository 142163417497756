/**
 * Update date: 12-06-2023
 * Popup select inventory due diligence component
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView, TextBox } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import React, { useState, useRef, useEffect } from 'react';
import useFormatTime from 'hooks/useFormatTime';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';
import { notification } from 'utils/notification';
interface IPopupSelectInventoryDueDiligence54BProps {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit?: void | any;
  mode?: 'single' | 'multiple';
  title?: string;
  bodyData?: {
    isRegular?: boolean | null;
  };
  notSelectApproved?: boolean;
}

function PopupSelectInventoryDueDiligence54B({
  visible,
  onHiding,
  text,
  onSubmit,
  mode = 'single',
  title,
  bodyData = {},
  notSelectApproved = false,
}: IPopupSelectInventoryDueDiligence54BProps) {
  const { themePro }: any = useApp();
  const { t } = useDictionary({});
  const [subject, setSubject] = useState(text);
  const dataRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  const [data, setData] = useState<any>([]);

  const [{ loading }, fetchData] = useAxios(
    {
      url: '/warehouse/m2054_b/search-plan',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  /**
   * on search
   *
   */
  const onSearch = async () => {
    const res = await fetchData({
      data: { ...bodyData, subject },
    });
    if (res?.data?.status === '200') {
      setData(res?.data?.data || []);
      if (mode === 'single') {
        setTimeout(() => {
          const gridInstance = dataRef?.current?.instance;
          gridInstance?.clearSelection?.();
          gridInstance?.selectRows(gridInstance?.getKeyByRowIndex?.(0));
          gridInstance?.focus?.();
        }, 600);
      }
    } else {
      notification({ res });
    }
  };

  /**
   * on ok
   *
   * @return {*}
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys || [];
    if (!selectedRowKeys.length) {
      onHiding && onHiding();
      return;
    }
    if (!mode || mode === 'single') {
      const d = selectedRowKeys?.[0];
      if (notSelectApproved && d?.approved) {
        return notification({
          type: 'error',
          message: t('You cannot select an approved record'),
        });
      }
      onSubmit(d);
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  useEffect(() => {
    onSearch();
  }, []);

  const loadingAll = loading;

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Inventory Due Diligence')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }
            }
          >
            <TextBox
              width={themePro ? 400 : '40vw'}
              defaultValue={subject}
              onValueChanged={e => setSubject(e?.value)}
              onEnterKey={onSearch}
              style={{ marginRight: 10 }}
            />
            <Button icon="search" text={t('Search')} onClick={onSearch} />
          </div>
          <DataGridFull
            ref={dataRef}
            dataSource={data}
            columns={[
              {
                dataField: 'splanid',
                caption: t('ID'),
                alignment: 'left',
              },
              {
                dataField: 'splanno',
                caption: t('Diligence No'),
              },
              {
                dataField: 'splansubject',
                caption: t('Subject'),
              },
              {
                dataField: 'approved',
                caption: t('Approved'),
                dataType: 'boolean',
              },
              {
                dataField: 'splanstartdt',
                caption: t('Start date'),
                alignment: themePro ? 'left' : 'center',
                dataType: 'date',
                format: DateFormat,
              },
              {
                dataField: 'splanenddt',
                caption: t('End date'),
                alignment: themePro ? 'left' : 'center',
                dataType: 'date',
                format: DateFormat,
              },
              {
                dataField: 'splanadjustdt',
                caption: t('Adjust Date'),
                alignment: 'left',
                dataType: 'date',
                format: DateFormat,
              },
            ]}
            options={{
              onRowDblClick: onOk,
              onKeyDown: e => {
                const keyCode = e?.event?.keyCode;
                if (keyCode === 38 || keyCode === 40) {
                  if (mode !== 'single') return;
                  const selKey = e?.component?.getSelectedRowKeys();
                  if (selKey?.length) {
                    const currentKey = selKey[0];
                    let index =
                      e?.component?.getRowIndexByKey?.(currentKey) || 0;
                    if (keyCode === 38) {
                      index--;
                      if (index >= 0) {
                        e?.component?.selectRowsByIndexes([index]);
                      }
                    } else if (
                      keyCode === 40 &&
                      index + 1 !== e?.component?.getVisibleRows?.()?.length
                    ) {
                      index++;
                      e?.component?.selectRowsByIndexes([index]);
                    }
                    e?.component?.navigateToRow(
                      e?.component.getKeyByRowIndex(index),
                    );
                  }
                  e?.event?.preventDefault();
                  e?.event?.stopPropagation();
                } else if (keyCode === 13) {
                  onOk();
                  e?.event?.preventDefault();
                  e?.event?.stopPropagation();
                }
              },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: { mode },
              height: themePro
                ? 'calc(100vh - 250px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={title || t('Inventory Due Diligence')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '65vw'}
    />
  );
}

export default PopupSelectInventoryDueDiligence54B;
