/**
 * Update date: 13-06-2023
 * Popup history barcode component
 */
import React, { useState, useEffect, useRef } from 'react';
import { Button, ScrollView } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import useAxios from 'axios-hooks';
import { DataGridFull } from '../DataGrid';
import useFormatTime from 'hooks/useFormatTime';
import useDictionary from 'hooks/useDictionary';
import { isMobile } from 'react-device-detect';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';
interface IPopupHistoryBarcode {
  billId?: string;
  type?: number;
  dataSource?: any[];
  visible?: boolean;
  onHiding?: () => void;
}
const PopupHistoryBarcode = ({
  billId,
  type,
  dataSource,
  visible,
  onHiding,
}: IPopupHistoryBarcode) => {
  const { themePro }: any = useApp();
  const { DateTimeFormat } = useFormatTime();
  const { t } = useDictionary({});
  const [barcodes, setBarcodes] = useState<any>([]);
  const dataGridRef: any = useRef(null);
  const [{ data: resHistoryBarcode, loading }, fetchHistoryBarcode] = useAxios(
    {
      url: '/warehouse/barcode-history',
      method: 'POST',
    },
    {
      manual: true,
      autoCancel: true,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (billId) {
      fetchHistoryBarcode({
        params: { billId, type },
      });
    }
  }, [billId]);

  useEffect(() => {
    let l = (resHistoryBarcode?.data || []).concat(dataSource);
    setBarcodes(l.reverse());
  }, [dataSource, resHistoryBarcode]);

  /**
   * on get index
   *
   * @param {*} e
   * @return {*}
   */
  const getIndex = e => {
    const pageIndex = dataGridRef?.current?.instance?.pageIndex?.() || 0;
    const pageSize = dataGridRef?.current?.instance?.pageSize?.() || 0;
    return pageIndex * pageSize + e?.rowIndex + 1;
  };

  const loadingAll = loading;

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      closeOnOutsideClick={true}
      showTitle={true}
      title={t('Scan Barcode History')}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
    >
      <ScrollView width="100%" height="100%">
        <LoadPanel visible={loadingAll} />
        {themePro && (
          <div className="modal-popup-header">
            <span className="title-page">{t('Scan Barcode History')}</span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Cancel')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
        )}
        <DataGridFull
          ref={dataGridRef}
          dataSource={barcodes}
          columns={[
            {
              caption: t('Numbers'),
              width: 70,
              allowSearch: false,
              cellRender: getIndex,
            },
            {
              dataField: 'scanBarcode',
              caption: t('Barcode'),
            },
            {
              dataField: 'scanDm',
              dataType: 'date',
              format: DateTimeFormat,
              caption: t('Date & Time'),
            },
          ]}
          options={{
            height: themePro
              ? 'calc(100vh - 130px)'
              : isMobile
              ? 'calc(80vh - 130px)'
              : 'calc(70vh - 130px)',
          }}
        />
        {!themePro && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              gap: 20,
            }}
          >
            <Button icon="close" text={t('Cancel')} onClick={onHiding} />
          </div>
        )}
      </ScrollView>
    </Popup>
  );
};

export default PopupHistoryBarcode;
