/**
 * Update date: 31-05-2023
 * Screen 2051.6
 */
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import React, { useState, useRef, memo } from 'react';
import { uniqBy } from 'lodash';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import Collapse from 'app/components/Collapse';
import useFormatTime from 'hooks/useFormatTime';
import PopupSelectZone from 'app/components/PopupCommon/PopupSelectZone';
import useDictionary from 'hooks/useDictionary';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';
import { dateTimeRequestFormat } from 'utils/format';
import useCenterInfo from 'hooks/useCenterInfo';

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
    title: 'Search Large Category',
  },
  mediumCategory: {
    codeType: 'MC',
    title: 'Search Medium Category',
  },
  smallCategory: {
    codeType: 'SC',
    title: 'Search Small Category',
  },
  subCategory: {
    codeType: 'UC',
    title: 'Search Sub Category',
  },
};

const FormSearch = ({ setInventoryData }) => {
  const { productCostTp } = useCenterInfo();
  const { MonthFormat, DateFormat } = useFormatTime();
  const { t } = useDictionary({});
  const [formStore, setFormStore] = useState<any>({
    largeCategory: [],
    mediumCategory: [],
    smallCategory: [],
    subCategory: [],
    product: [],
    productGroup: [],
    zone: [],
  });
  const [formData, setFormData] = useState<any>({
    date: new Date(),
    zone: '',
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
  });
  const [showPopupByType, setShowPopupByType] = useState('');
  const formRef: any = useRef(null);

  const [{ loading }, refetchData] = useAxios(
    {
      url: 'warehouse/costStatus',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const data = {
      ...formData,
      largeCategoryCodes: formStore.largeCategory.map((o: any) => o?.code),
      mediumCategoryCodes: formStore.mediumCategory.map((o: any) => o?.code),
      smallCategoryCodes: formStore.smallCategory.map((o: any) => o?.code),
      subCategoryCodes: formStore.subCategory.map((o: any) => o?.code),
      productIds: formStore.product.map((o: any) => o?.productId),
      productGroupIds: formStore.productGroup.map(
        (o: any) => o?.productGroupId,
      ),
      zoneIds: formStore.zone.map((o: any) => o?.zoneId),
      date: dateTimeRequestFormat(formData?.date),
    };
    const res = await refetchData({
      data: data,
    });
    if (res?.data?.status == '200') {
      setInventoryData(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  /**
   * on remove store
   *
   * @param {*} storeType
   * @param {*} index
   */
  const removeStore = (storeType, index) => {
    let newStore;
    if (index === -1) {
      setFormStore({ ...formStore, [storeType]: [] });
    } else {
      const l = [...formStore[storeType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setFormStore({ ...formStore, [storeType]: newStore });
    }
  };

  /**
   * on update store
   *
   * @param {*} storeType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storeType, data, key: any = false) => {
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...formStore?.[storeType], ...data], key);
    }
    const newStore = { ...formStore, [storeType]: filterData };
    setFormData({ ...formData, [storeType]: '' });
    setFormStore(newStore);
    setShowPopupByType('');
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'Zone':
        return (
          <PopupSelectZone
            text={formData?.zone}
            mode="multiple"
            visible={popupType === 'Zone'}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore('zone', data, 'zoneId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'productGroup'}
            text={formData?.productGroup}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data =>
              updateStore('productGroup', data, 'productGroupId')
            }
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={popupType === 'product'}
            text={formData?.product}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore('product', data, 'productId')}
          />
        );
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <Form
        ref={formRef}
        formData={formData}
        showColonAfterLabel={false}
        items={[
          {
            label: { text: t('Current Date') },
            dataField: 'date',
            editorType: 'dxDateBox',
            editorOptions: {
              displayFormat: productCostTp == '1' ? MonthFormat : DateFormat,
              calendarOptions: {
                maxZoomLevel: productCostTp == '1' ? 'year' : 'month',
                minZoomLevel: 'century',
              },
            },
          },
          {
            label: { text: t('Zone') },
            dataField: 'zone',
            editorType: 'dxTextBox',
            editorOptions: {
              onEnterKey: () => setShowPopupByType('Zone'),
              buttons: [
                {
                  name: 'data1',
                  location: 'before',
                  options: {
                    icon: 'close',
                    text: formStore?.zone?.[0]?.zoneName,
                    onClick: () => removeStore('zone', 0),
                    visible: !!formStore?.zone?.[0],
                    rtlEnabled: true,
                  },
                },
                {
                  name: 'data2',
                  location: 'before',
                  options: {
                    icon: 'close',
                    text: formStore?.zone?.[1]?.zoneName,
                    onClick: () => removeStore('zone', 1),
                    visible: !!formStore?.zone?.[1],
                    rtlEnabled: true,
                  },
                },
                {
                  name: 'storeMore',
                  location: 'before',
                  options: {
                    text: `+ ${formStore?.zone?.length - 2} ${t('more')}`,
                    visible: formStore?.zone?.length > 2,
                  },
                },
                {
                  name: 'clearAll',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'close',
                    visible: !!formStore?.zone?.length,
                    onClick: () => removeStore('zone', -1),
                  },
                },
                {
                  name: 'search',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: 'search',
                    onClick: () => setShowPopupByType('Zone'),
                  },
                },
              ],
            },
          },
        ]}
        labelLocation="top"
        colCount={2}
      />
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'largeCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('largeCategory'),
                showClearButton: true,
                buttons: [
                  {
                    name: 'category1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.largeCategory?.[0]?.name,
                      onClick: () => removeStore('largeCategory', 0),
                      visible: !!formStore?.largeCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'category2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.largeCategory?.[1]?.name,
                      onClick: () => removeStore('largeCategory', 1),
                      visible: !!formStore?.largeCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'categoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.largeCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.largeCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.largeCategory?.length,
                      onClick: () => removeStore('largeCategory', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('largeCategory'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smallCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('smallCategory'),
                showClearButton: true,
                buttons: [
                  {
                    name: 'category1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smallCategory?.[0]?.name,
                      onClick: () => removeStore('smallCategory', 0),
                      visible: !!formStore?.smallCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'category2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smallCategory?.[1]?.name,
                      onClick: () => removeStore('smallCategory', 1),
                      visible: !!formStore?.smallCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'categoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smallCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smallCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smallCategory?.length,
                      onClick: () => removeStore('smallCategory', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('smallCategory'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mediumCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('mediumCategory'),
                showClearButton: true,
                buttons: [
                  {
                    name: 'category1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mediumCategory?.[0]?.name,
                      onClick: () => removeStore('mediumCategory', 0),
                      visible: !!formStore?.mediumCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'category2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mediumCategory?.[1]?.name,
                      onClick: () => removeStore('mediumCategory', 1),
                      visible: !!formStore?.mediumCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'categoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mediumCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mediumCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mediumCategory?.length,
                      onClick: () => removeStore('mediumCategory', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('mediumCategory'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('subCategory'),
                showClearButton: true,
                buttons: [
                  {
                    name: 'category1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () => removeStore('subCategory', 0),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'category2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () => removeStore('subCategory', 1),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'categoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => removeStore('subCategory', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('subCategory'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('productGroup'),
                showClearButton: true,
                buttons: [
                  {
                    name: 'category1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () => removeStore('productGroup', 0),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'category2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () => removeStore('productGroup', 1),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'categoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => removeStore('productGroup', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('productGroup'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('product'),
                showClearButton: true,
                buttons: [
                  {
                    name: 'category1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.productNm,
                      onClick: () => removeStore('product', 0),
                      visible: !!formStore?.product?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'category2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.productNm,
                      onClick: () => removeStore('product', 1),
                      visible: !!formStore?.product?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'categoryMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => removeStore('product', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('product'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        />
      </>
    );
  };

  return (
    <div>
      <LoadPanel visible={loading} />
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default memo(FormSearch);
