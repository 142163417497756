/**
 * Update date: 2023-10-09
 * Screen 1044.8
 */
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { DataGridFull } from 'app/components/DataGrid';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useModuleContext } from '..';
import FormSearch from './FormSearch';
import PopupStoreInterlockSetting from '../StoreInterlockSetting';

function ListStore() {
  const context: any = useModuleContext();
  const { t, store } = context;
  const dataGridRef: any = useRef(null);
  const [showInterlockSetting, setShowInterlockSetting] = useState<{
    isShow: boolean;
    data: any;
  }>({
    isShow: false,
    data: null,
  });
  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = record => {
    const { store_id, store_nm, store_channel_count } = record?.data || {};
    return (
      <div
        onClick={() => {
          setShowInterlockSetting({
            isShow: true,
            data: { store_id, store_nm },
          });
        }}
        className="cell-link"
      >
        {t('Interlock Setting')}
        <span style={{ color: '#333333' }}>{` (${store_channel_count})`}</span>
      </div>
    );
  };
  /**
   * export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const columns = [
    {
      dataField: 'store_id',
      caption: t('ID'),
      alignment: 'left',
    },
    {
      dataField: 'store_tp_nm',
      caption: t('Type'),
    },
    {
      dataField: 'store_cd',
      caption: t('Store Code'),
      alignment: 'left',
    },
    {
      dataField: 'store_nm',
      caption: t('Store'),
    },
    {
      dataField: 'bussiness_n',
      caption: t('Business Number'),
    },
    {
      dataField: 'unit_price_group_nm',
      caption: t('Supply Unit Price Group'),
    },
    {
      dataField: 'representative',
      caption: t('Representative'),
    },
    {
      dataField: 'mobile',
      caption: t('Mobile No'),
    },
    {
      dataField: 'phone',
      caption: t('Phone No'),
    },
    {
      dataField: 'use_tf',
      caption: t('Use'),
      alignment: 'center',
      editorType: 'dxSwitch',
      trueText: t('Use'),
      falseText: t('Not Use'),
    },
    {
      caption: t('Action'),
      alignment: 'left',
      cellRender: renderAction,
    },
  ];

  return (
    <>
      <BreadCrumbPremium />
      <FormSearch />
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        fixedRight={1}
        columns={columns}
        options={{
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  disabled: !store?._array?.length,
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName:
              'SMDC_1044.8 Store interlock setting_' +
              moment().format('YYYYMMDD'),
          },
        }}
      />
      {showInterlockSetting.isShow && (
        <PopupStoreInterlockSetting
          visible={showInterlockSetting.isShow}
          onDone={data => {
            if (data != null && data !== undefined) {
              store?.update?.(showInterlockSetting?.data?.store_id, {
                store_channel_count: data,
              });
            }
            setShowInterlockSetting({ isShow: false, data: null });
          }}
          onHiding={() =>
            setShowInterlockSetting({ isShow: false, data: null })
          }
          data={showInterlockSetting.data}
        />
      )}
    </>
  );
}

export default ListStore;
