/**
 * Update date: 15-05-2023
 * Screen 1044.2
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useDictionary from 'hooks/useDictionary';
import { memo, useRef, useState } from 'react';
import PopupAddStoreShippingMethod from './PopupAddStoreShippingMethod';

function AddStoreShippingMethod(props) {
  const { t }: any = useDictionary({
    programId: '1044.2',
  });
  const { value, onChangeValue } = props?.data?.editorOptions || {};
  const dataGridRef: any = useRef(null);
  const selectedKeys = dataGridRef?.current?.instance?.option().selectedRowKeys;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);

  //   const [{ loading: loadingDelete }, refetchDelete] = useAxios(
  //     {
  //       url: 'user/store',
  //       method: 'DELETE',
  //     },
  //     { manual: true, autoCancel: true },
  //   );

  /**
   * on delete
   *
   */
  const onDelete = async () => {
    const selectedData: any =
      dataGridRef?.current?.instance?.getSelectedRowsData();
    onChangeValue(selectedData, true);
    setShowModalDelete(false);
  };

  return (
    <>
      <DataGridFull
        ref={dataGridRef}
        dataSource={value}
        columns={[
          {
            dataField: 'receivableGroup',
            caption: t('Receivable Group Code'),
          },
          {
            dataField: 'receivableGroupName',
            caption: t('Receivable Group Name'),
          },
          {
            dataField: 'shippingMethod',
            caption: t('Shipping Method Code'),
          },
          {
            dataField: 'shippingMethodName',
            caption: t('Shipping Method Name'),
          },
        ]}
        options={{
          columnAutoWidth: true,
          filterRow: {
            visible: false,
          },
          filterPanel: {
            visible: false,
          },
          searchPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false,
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Add'),
                  onClick: () => setShowModalAdd(true),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  onClick: () => setShowModalDelete(!showModalDelete),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
        }}
      />
      {showModalDelete && (
        <PopupConfirmDelete
          visible={showModalDelete}
          content={`${t('Do you want to delete {0} items?')}`.replace(
            '{0}',
            selectedKeys?.length,
          )}
          onOk={onDelete}
          onHiding={() => setShowModalDelete(!showModalDelete)}
        />
      )}
      {showModalAdd && (
        <PopupAddStoreShippingMethod
          data={value || []}
          visible={showModalAdd}
          onSubmit={onChangeValue}
          onHiding={() => setShowModalAdd(false)}
        />
      )}
      <br />
    </>
  );
}

export default memo(AddStoreShippingMethod);
