/**
 * Update date: 23-05-2023
 * Screen 2032.1
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import { useApp } from 'app';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate/index'));
/**
 * create context
 */
const RegistrationOfScheduledDeliveryThroughBarcodeScanContext = createContext(
  {},
);

RegistrationOfScheduledDeliveryThroughBarcodeScanContext.displayName =
  'RegistrationOfScheduledDeliveryThroughBarcodeScanContext';

export const useRegistrationOfScheduledDeliveryThroughBarcodeScan = () => {
  return useContext(RegistrationOfScheduledDeliveryThroughBarcodeScanContext);
};

const RegistrationOfScheduledDeliveryThroughBarcodeScan = () => {
  const appContext: any = useApp();
  const { s } = appContext || {};
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    orderId: null,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    vendorId: '',
    vendorName: '',
    dataList: {
      orderState: [],
    },
    orderState: '',
    fromDate: new Date(),
    endDate: new Date(),
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    s,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
      case 'edit':
      case 'create':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <RegistrationOfScheduledDeliveryThroughBarcodeScanContext.Provider
      value={value}
    >
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </RegistrationOfScheduledDeliveryThroughBarcodeScanContext.Provider>
  );
};

export default RegistrationOfScheduledDeliveryThroughBarcodeScan;
