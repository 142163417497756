import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { createSlice } from '@reduxjs/toolkit';

// interface centerZone {}

// const initialState: centerZone = {};

export interface CenterZoneState {
  showViewLocation: boolean;
  showModalZone: boolean;
  showModalLocation: boolean;
  currentLocation: any;
  zoneInfo: any;
  listLocation: any[];
  arrayLocationDelete: string[];
  arrayLocationPageDelete: number[];
  refreshCenterZone: boolean;
}

const initialState: CenterZoneState = {
  showViewLocation: false,
  showModalZone: false,
  showModalLocation: false,
  currentLocation: null,
  zoneInfo: null,
  listLocation: [],
  arrayLocationDelete: [],
  arrayLocationPageDelete: [],
  refreshCenterZone: false,
};

const centerZoneSlice = createSlice({
  name: 'centerZone',
  initialState,
  reducers: {
    //ViewLocation
    openViewLocation(state, action: PayloadAction<number | null>) {
      state.showViewLocation = true;
    },
    closeViewLocation(state) {
      state.showViewLocation = false;
      state.listLocation = [];
      state.zoneInfo = null;
    },
    //ModalZone
    openModalZone(state, action: PayloadAction<number | null>) {
      state.showModalZone = true;
    },
    closeModalZone(state) {
      state.showModalZone = false;
      state.listLocation = [];
      state.zoneInfo = null;
    },
    //ModalLocation
    openModalLocation(state, action: PayloadAction<any>) {
      state.showModalLocation = true;
      state.currentLocation = action.payload;
    },
    closeModalLocation(state) {
      state.showModalLocation = false;
      state.currentLocation = null;
    },
    //Zone
    updateZoneInfo(state, action: PayloadAction<any>) {
      state.zoneInfo = action.payload;
    },
    //ListLocation
    addListLocation(state, action: PayloadAction<any>) {
      state.listLocation = state.listLocation.concat(action.payload);
    },
    setDefaultListLocation(state, action: PayloadAction<string>) {
      state.listLocation = state.listLocation.map((map: any) =>
        map.locationCd === action.payload
          ? { ...map, locationDefaultTf: true }
          : map.locationDefaultTf
          ? { ...map, locationDefaultTf: false }
          : map,
      );
    },
    addLocationToList(state, action: PayloadAction<any>) {
      if (action.payload.locationDefaultTf) {
        state.listLocation = state.listLocation
          .map((map: any) => ({
            ...map,
            locationDefaultTf: false,
          }))
          .concat(action.payload);
      } else {
        state.listLocation = state.listLocation.concat(action.payload);
      }
    },
    updateCurrentLocation(state, action: PayloadAction<any>) {
      const { locationDefaultTf, index } = action.payload;
      if (locationDefaultTf) {
        state.listLocation = state.listLocation.map((map: any, i) =>
          i === index
            ? action.payload
            : {
                ...map,
                locationDefaultTf: false,
              },
        );
      } else {
        state.listLocation = state.listLocation.map((map: any, i) =>
          i === index ? action.payload : { ...map },
        );
      }
    },
    removeItemListLocation(state, action: PayloadAction<string[]>) {
      state.listLocation = state.listLocation.filter((filter: any) =>
        action.payload.every((every: any) => every !== filter.locationCd),
      );
    },
    //Excel
    downloadFileExample(state, actions) {},
    uploadFile(state, actions) {},
    //LocationCheckBox
    updatePageLocationCheckbox(state, action: PayloadAction<number[]>) {
      state.arrayLocationPageDelete = action.payload;
    },
    updateArrayLocationCheckbox(state, action: PayloadAction<string[]>) {
      state.arrayLocationDelete = Array.from(new Set(action.payload));
    },
    refreshLocationCheckbox(state) {
      state.arrayLocationDelete = [];
      state.arrayLocationPageDelete = [];
    },
    //RefreshCenterZone
    refreshCenterZone(state) {
      state.refreshCenterZone = true;
    },
    refreshCenterZoneSuccess(state) {
      state.refreshCenterZone = false;
    },
  },
});

//Action
export const centerZoneActions = centerZoneSlice.actions;

//Selectors
const viewLocation = (state: any) => state.centerZone.showViewLocation;
const modalZone = (state: any) => state.centerZone.showModalZone;
const modalLocation = (state: any) => state.centerZone.showModalLocation;
const currentLocation = (state: any) => state.centerZone.currentLocation;
const listLocation = (state: any) => state.centerZone.listLocation;
const zoneInfo = (state: any) => state.centerZone.zoneInfo;
const arrayLocationDelete = (state: any) =>
  state.centerZone.arrayLocationDelete;
const arrayLocationPageDelete = (state: any) =>
  state.centerZone.arrayLocationPageDelete;
const refreshCenterZone = (state: any) => state.centerZone.refreshCenterZone;

export const centerZoneSelect = {
  viewLocation,
  modalZone,
  modalLocation,
  currentLocation,
  listLocation,
  zoneInfo,
  arrayLocationDelete,
  arrayLocationPageDelete,
  refreshCenterZone,
};

const centerZoneReducer = centerZoneSlice.reducer;

export default centerZoneReducer;
