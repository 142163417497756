/*
 * 2087.7 Account Receivable Inquiry By Store
 * @since 2022/11/29
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import React, { createContext, useContext, useState, useEffect } from 'react';

const List = React.lazy(() => import('./List'));

/**
 * Create context
 */
const AccountReceivableInquiryByStoreContext = createContext({});

AccountReceivableInquiryByStoreContext.displayName =
  'AccountReceivableInquiryByStoreContext';

/**
 * Create use context
 */
export const useAccountReceivableInquiryByStoreContext = () => {
  const context = useContext(AccountReceivableInquiryByStoreContext);
  return context;
};

/**
 * Accounts receivable inquiry by store (period)
 */
export default function AccountReceivableInquiryByStore() {
  const { t }: any = useDictionary({ programId: '2087.7' });
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
  });
  const { AmountFormat }: any = useFormat();
  const [listData, setListData] = useState([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      store: [],
      storeGroup: [],
      receivableCd: [],
    },
    store: '',
    storeGroup: '',
    receivableCd: '',
  });

  /**
   * Call api get data list
   */
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'user/account-payable-inquiry/by-store',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: listData,
    // key: [],
  });

  /**
   * Search data
   */
  const onSearch = () => {
    const searchParams = {
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : '',
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : '',
      storeGroupIds: formData.dataList.storeGroup.map(
        (o: any) => o.storeGroupId,
      ),
      storeIds: formData.dataList.store.map((o: any) => o.storeNo),
      receiveManagementGroups: formData.dataList.receivableCd.map(
        (o: any) => o.code,
      ),
    };
    refetchData({
      data: {
        ...searchParams,
      },
    });
  };

  /**
   * render content page
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        break;
    }
  };

  // set data to state when call api success
  useEffect(() => {
    if (!loadingList && dataList?.data) {
      setListData(dataList?.data);
    }
  }, [loadingList, dataList?.data]);

  // Values share with child component
  const value: any = {
    AmountFormat,
    listData,
    setListData,
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    formData,
    onSearch,
    setFormData,
  };

  const loadingAll = loadingList;

  return (
    <AccountReceivableInquiryByStoreContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </AccountReceivableInquiryByStoreContext.Provider>
  );
}
