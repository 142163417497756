import {
  iconC,
  iconE,
  iconR,
  iconJ,
  flagGB,
  flagKR,
  flagVN,
  flagJP,
  flagCN,
  iconE_P,
  iconR_P,
  iconC_P,
  iconJ_P,
} from 'images';

export const HOST_CLOUD = process.env.REACT_APP_HOST_CLOUD;
export const BASIC_KEY = process.env.REACT_APP_BASIC_KEY;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID!;

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const EXPIRES_IN = 'expires_in';
export const TYPE_TOKEN = 'Bearer';
export const USER_REGISTRATION = 'user_registration';
export const TIME_VERIFICATION_PAGE = '30'; //minute
export const TIME_RESEND = '3'; //minute
export const ROLE_ID_COMPANY = '2,3';
export const CURRENT_USER = 'current_user';
export const ORDER_LENGTH = 9;
export const LANG_DEFAULT = 'ko';

const passwordRegexp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const inputPhoneRegExp = /^[0-9-]+$/;

const specialCharacterReg = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;

const onlyLetterReg = /^[a-zA-Z\s]*$/;

const koreaLetters = /[\u3131-\uD79D]/giu;

export const widthResize = 768;
export const heightChartTab3 = 210;

const router = {
  login: '/login',
  sign_up: '/signup',
  system_management: 'system-management',
  register_program: '/register-program',
  user_info: '/user-info',
  user_register: '/user-register',
  center_operator: '/center-operator',
  demo: '/demo',
};

export const GRANT_TYPE = {
  password: 'password',
  refresh_token: 'refresh_token',
};

const listPhone = [
  { label: '+(82)', value: '+(82)' },
  { label: '+(84)', value: '+(84)' },
  { label: '', value: '' },
];

const listCountry = [
  { label: 'England', value: 'England' },
  { label: 'Korea', value: 'Korea' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Japan', value: 'Japan' },
  { label: 'China', value: 'China' },
];

const divisionTypes = [
  { label: 'None', value: '0' },
  { label: '1 round up', value: '2' },
  { label: '1 round', value: '1' },
  { label: '1 round down', value: '3' },
  { label: '10 round up', value: '5' },
  { label: '10 round down', value: '6' },
  { label: '10 round', value: '4' },
  { label: '100 round up', value: '8' },
  { label: '100 round', value: '7' },
  { label: '100 round down', value: '9' },
];

const amountPoints = [
  { value: '0', label: 0 },
  { value: '1', label: 1 },
  { value: '2', label: 2 },
];

export const ROW_STATUS = {
  NORMAL: null,
  INSERTED: 0,
  UPDATED: 1,
  DELETED: 2,
};

export const CONSTANT = {
  router: router,
  routerLogged: [
    router.system_management,
    router.register_program,
    router.user_info,
    router.user_register,
    router.center_operator,
  ],
  routerNotLoggedIn: [router.login, router.sign_up],
  listPhone,
  listCountry,
  passwordRegexp,
  phoneRegExp,
  emailRegExp,
  inputPhoneRegExp,
  specialCharacterReg,
  onlyLetterReg,
  koreaLetters,
  divisionTypes,
  amountPoints,
};

export const ERROR_STATUS = [400, 500, 426, 422];

export const ERORR_UNAUTHORIZED = 401;

export const SUCCESS_STATUS = 200;

export const SUCCESS_STATUS_UPDATE = 201;

export const SUCCESS_STATUS_1 = [200, 201];

export const acceptFileImportCenterZone =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const WIDTH_SIDEBAR_COLLAPSE = '55px';

export const WIDTH_SIDEBAR_EXPAND = '200px';

export const WIDTH_SUBMENU = '265px';

export const WIDTH_SUBMENU_LEVEL_2 = '560px';

export const HEIGHT_LOGO = '60px';

export const HEIGHT_FIXED_MENU = '150px';

export const PROGRAM_TYPES = [
  {
    text: 'Entry',
    value: 'E',
    iconUrl: iconE,
  },
  {
    text: 'Report',
    value: 'R',
    iconUrl: iconR,
  },
  {
    text: 'Code',
    value: 'C',
    iconUrl: iconC,
  },
  {
    text: 'Job Batch',
    value: 'J',
    iconUrl: iconJ,
  },
];
export const PROGRAM_TYPES_PREMIUM = [
  {
    text: 'Entry',
    value: 'E',
    iconUrl: iconE_P,
  },
  {
    text: 'Report',
    value: 'R',
    iconUrl: iconR_P,
  },
  {
    text: 'Code',
    value: 'C',
    iconUrl: iconC_P,
  },
  {
    text: 'Job Batch',
    value: 'J',
    iconUrl: iconJ_P,
  },
];

export const TRADE_TYPE = [
  { value: '0', text: 'Traded' },
  { value: '1', text: 'Non-traded' },
  { value: '2', text: 'Discontinued' },
];

export const TAX_TYPE = [
  { value: '0', text: 'Taxation' },
  { value: '1', text: 'Tax free' },
  { value: '2', text: 'Tax zero' },
];

export const TAKEOUT_STATUS = [
  { value: '0', text: 'Pending' },
  { value: '1', text: 'Fixed' },
];

export const APPROVAL = [
  {
    label: 'Not Approval',
    value: '0',
  },
  {
    label: 'Approval',
    value: '1',
  },
  {
    label: 'Withdraw',
    value: '2',
  },
];

export const MEMBER_APPROVALS = [
  {
    label: 'Not Approved',
    value: '0',
  },
  {
    label: 'Approved',
    value: '1',
  },
  {
    label: 'Withdraw',
    value: '2',
  },
];

export const MEMBER_PROCESS_STATUS = [
  {
    label: 'Withdraw Process',
    value: '1',
  },
  {
    label: 'Approval Process',
    value: '0',
  },
];

export const SPECIAL_DEAL_PRODUCT_TYPES = [
  { value: 0, label: 'Purchase, Sale' },
  { value: 1, label: 'Purchase' },
  { value: 2, label: 'Sale' },
  { value: 3, label: 'Deal Expiration' },
];

export const ROLE_TYPE = [
  { id: 1, name: 'Center Operator', value: 'Center User' },
  { id: 2, name: 'Store', value: 'Market Owner' },
  { id: 3, name: 'Vendor', value: 'Supplier' },
  { id: 4, name: 'Delivery Driver', value: 'Delivery' },
];

//https://flagicons.lipis.dev/
export const LIST_LANGUAGE = [
  {
    name: 'English',
    value: 'en',
    icon: flagGB,
  },
  {
    name: '한국어',
    value: 'ko',
    icon: flagKR,
  },
  {
    name: 'Tiếng Việt',
    value: 'vi',
    icon: flagVN,
  },
  {
    name: '日本語',
    value: 'ja',
    icon: flagJP,
  },
  {
    name: '中国人',
    value: 'zh',
    icon: flagCN,
  },
];

export const listNotViewTitle = [
  '1042.1',
  '1042.5',
  '1042.7',
  '1043.1',
  '1044.1',
  '1044.2',
  '1044.5',
  '1044.6',
  '1044.7',
  '1044.8',
  '1047.1',
  '1046.A',
  '2011.A',
  '2011.1',
  '2011.3',
  '2011.4',
  '2011.9',
  '2012.3',
  '2012.4',
  '2013.4',
  '2014.2',
  '2016.1',
  '2016.4',
  '2021.1',
  '2021.3',
  '2023.1',
  '2023.4',
  '2023.5',
  '2024.1',
  '2024.5',
  '2032.1',
  '2032.3',
  '2042.1',
  '2042.4',
  '2042.6',
  '2041.5',
  '2041.C',
  '2041.6',
  '2041.A',
  '2043.1',
  '2043.4',
  '2046.1',
  '2046.4',
  '2046.5',
  '2046.6',
  '2046.7',
  '2046.B',
  '2046.C',
  '2046.D',
  '2047.1',
  '2047.2',
  '2048.1',
  '2051.4',
  '2051.9',
  '2052.A',
  '2054.1',
  '2054.B',
  '2043.6',
  '2055.1',
  '2064.5',
  '2064.7',
  '2064.9',
  '2081.1',
  '2081.2',
  '2081.3',
  '2081.B',
  '2081.C',
  '2081.7',
  '2081.E',
  '2081.F',
  '2081.H',
  '2081.I',
  '2081.K',
  '2044.2',
  '2044.3',
  '2044.8',
  '2083.3',
  '2052.1',
  '2052.2',
  '2054.3',
  '2012.5',
  '2033.1',
  '2044.4',
  '2044.5',
  '2083.1',
  '1043.7',
  '1043.8',
  '2061.5',
  '2082.1',
  '2082.2',
  '2082.3',
  '2045.1',
  '2082.5',
  '2082.6',
  '2087.6',
  '2086.1',
  '2086.2',
  '2086.3',
  '2086.4',
  '2082.8',
  '2082.9',
  '2016.5',
  '2044.9',
  '2082.B',
  '2087.B',
  '2016.7',
  '2016.9',
  '2011.B',
  '2044.A',
  '2044.B',
  '2044.C',
  '1044.9',
];
