/**
 * Update date: 31-05-2023
 * Screen 2047.1
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import { useProductType } from 'hooks/useProductType';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));
  /**
   * create context
   */
const RegistrationOfRegularDeliveryProductsContext = createContext({});

RegistrationOfRegularDeliveryProductsContext.displayName =
  'RegistrationOfRegularDeliveryProductsContext';

export const useRegistrationOfRegularDeliveryProducts = () => {
  return useContext(RegistrationOfRegularDeliveryProductsContext);
};

const RegistrationOfRegularDeliveryProducts = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    store: null,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      storeGroup: [],
      store: [],
    },
    storeGroup: '',
    store: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    productTpPermission,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <RegistrationOfRegularDeliveryProductsContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingProductTpPermission} />
        {renderContent()}
      </React.Suspense>
    </RegistrationOfRegularDeliveryProductsContext.Provider>
  );
};

export default RegistrationOfRegularDeliveryProducts;
