/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup } from 'devextreme-react';
import moment from 'moment';
import { usePopupOrder } from '.';
import TableSingle from './TableSingle';

interface IShippingTracking {
  onClose: () => void;
  invoiceNo?: any;
}

function ShippingTracking({ onClose, invoiceNo }: IShippingTracking) {
  const [{ loading, data }] = useAxios(
    {
      url: `/ecommerce/order-popup/shipping-tracking-invoice`,
      method: 'GET',
      params: {
        invoiceNo: invoiceNo,
      },
    },
    { useCache: false },
  );
  const { t }: any = usePopupOrder();

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <>
        <LoadPanel visible={loading} />
        <div className="modal-popup-header">
          <span
            className="title-page"
            style={{ fontSize: 18, margin: '0 0 5px' }}
          >
            {t('Shipping Tracking')}
          </span>
          <div style={{ marginBottom: 10 }}>
            <Button stylingMode="contained" icon="close" onClick={onClose} />
          </div>
        </div>
        <TableSingle
          columns={[
            {
              label: t('Status'),
              dataField: 'status',
            },
            {
              label: t('Detail'),
              dataField: 'detail',
            },
          ]}
          dataSource={data?.data
            ?.sort((a, b) => (a?.tracking_dm > b?.tracking_dm ? 1 : -1))
            .map(o => ({
              ...o,
              status: o?.status ? t(o?.status) : '',
              detail: `${
                o?.tracking_dm
                  ? moment(o?.tracking_dm).format('YYYY-MM-DD HH:mm')
                  : 'yyyy-MM-dd HH:mm'
              } ${t('Worker')} (${o?.usernm})`,
            }))}
        />
      </>
    );
  };

  return (
    <Popup
      visible={true}
      contentRender={content}
      width="auto"
      height="auto"
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default ShippingTracking;
