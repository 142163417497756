/**
 * Update date: 18-05-2023
 * Screen 2012.5
 */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { useProductType } from 'hooks/useProductType';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ManageProductInterestContext = createContext({});

ManageProductInterestContext.displayName = 'ManageProductInterestContext';

export const useManageProductInterest = () => {
  const context = useContext(ManageProductInterestContext);
  return context;
};

export default function ManageProductInterest() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [productInterestData, setProductInterestData] = useState<any>([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      storeGroup: [],
      store: [],
    },
    storeGroup: '',
    store: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const dataGridRef: any = useRef(null);

  const dataStore: any = new ArrayStore({
    data: productInterestData,
    key: 'storeId',
  });

  // Get data
  const [{ data: productRes, loading: productLoading }, refetchData] = useAxios(
    {
      url: 'product/store-interest',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const [{ loading: productDeleteLoading }, productDelete] = useAxios(
    {
      url: 'product/store-interest',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );
  /**
   * render content
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List ref={dataGridRef} />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * on update data
   *
   * @param {*} data
   * @param {*} type
   */
  const onUpdateData = (data, type) => {
    const existItem = dataStore?._array?.some(
      (o: any) => o?.storeId === data?.storeId,
    );
    if (existItem) {
      dataStore
        .update(data?.storeId, data)
        .done(() => {})
        .fail(() => {});
    } else {
      setProductInterestData(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
    }
    dataGridRef?.current?.instance?.refresh();
  };

  const value: any = {
    productTpPermission,
    dataStore,
    formSearchData,
    setFormSearchData,
    modeView,
    setModeView,
    refetchData,
    onUpdateData,
    productDelete,
  };

  useEffect(() => {
    if (productRes?.data) {
      setProductInterestData(productRes?.data);
    }
  }, [productRes]);

  const loadingAll =
    productLoading || productDeleteLoading || loadingProductTpPermission;

  return (
    <ManageProductInterestContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ManageProductInterestContext.Provider>
  );
}
