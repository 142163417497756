/**
 * Update date: 07-06-2023
 * Screen 2081.9
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ListMemberBondBalanceInquiry from './ListMemberBondBalanceInquiry';
import useDictionary from 'hooks/useDictionary';
const MemberBondBalanceInquiryDetail = React.lazy(
  () => import('./MemberBondBalanceInquiryDetail'),
);
/**
 * create context
 */
const MemberBondBalanceInquiryContext = createContext({
  paramsMember: {},
  setParamsMember: () => {},
  refetchReceive: () => {},
  listDataMember: {} || null,
  modeView: {},
  setModeView: () => {},
});

MemberBondBalanceInquiry.displayName = 'MemberBondBalanceInquiryContext';

export const useMemberBondBalanceInquiry = () => {
  return useContext(MemberBondBalanceInquiryContext);
};

function MemberBondBalanceInquiry() {
  const { t } = useDictionary({ programId: '2081.9' });
  const dataGridMemberRef: any = useRef(null);
  const dataGridMemberPopupRef: any = useRef(null);
  const [formSearchData, setFormSearchData] = useState({
    representative: '',
    dataList: {
      store: [],
      storeType: [],
      storeGroup: [],
      receivableCd: [],
    },
    store: '',
    storeType: '',
    storeGroup: '',
    receivableCd: '',
  });
  const [paramsMemberDetail, setParamsMemberDetail] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    storeId: '',
    dataList: {
      receivableCd: [],
    },
    receivableCd: '',
  });
  const [modeView, setModeView] = useState({ type: 'list' });
  const [listDataMember, setListDataMember] = useState<any>([]);
  const [listDataMemberDetail, setListDataMemberDetail] = useState<any>([]);

  const storeMember: any = new ArrayStore({
    data: listDataMember,
    key: 'storeId',
  });

  const storeMemberDetail: any = new ArrayStore({
    data: listDataMemberDetail,
    key: 'dayDt',
  });

  const [
    { data: dataMember, loading: loadingListDataMember },
    refetchReceiveMember,
  ] = useAxios(
    {
      url: '/product/member',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const [
    { data: dataMemberDetail, loading: loadingListDataMemberDetail },
    refetchReceiveMemberDetail,
  ] = useAxios(
    {
      url: '/product/member/popup',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
      case 'view':
        return (
          <>
            <ListMemberBondBalanceInquiry ref={dataGridMemberRef} />;
            <MemberBondBalanceInquiryDetail ref={dataGridMemberPopupRef} />
          </>
        );
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    t,
    formSearchData,
    setFormSearchData,
    refetchReceiveMember,
    storeMember,
    paramsMemberDetail,
    setParamsMemberDetail,
    refetchReceiveMemberDetail,
    storeMemberDetail,
    modeView,
    setModeView,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (dataMember?.data) setListDataMember(dataMember?.data);
  }, [dataMember]);

  useEffect(() => {
    if (dataMemberDetail?.data) setListDataMemberDetail(dataMemberDetail?.data);
  }, [dataMemberDetail]);

  const loadingAll = loadingListDataMember || loadingListDataMemberDetail;

  return (
    <MemberBondBalanceInquiryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </MemberBondBalanceInquiryContext.Provider>
  );
}

export default MemberBondBalanceInquiry;
