/**
 * Update date: 2023-10-09
 * Screen 1044.8
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';
interface IPopupStoreInterlockSetting {
  visible: boolean;
  onHiding: () => void;
  onDone?: (data?: any) => void;
  data: any;
}

const columnStoreInterlockSettingEdit = ['interlock_cd', 'interlock_nm'];

const PopupStoreInterlockSetting = ({
  visible,
  onHiding,
  onDone,
  data = {},
}: IPopupStoreInterlockSetting) => {
  const { t }: any = useDictionary({ programId: '1044.8' });
  const dataGridRef: any = useRef(null);
  const [dataInterlock, setDataInterlock] = useState<any>([]);
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );
  useEffect(() => {
    if (!data?.store_id) return;
    const getDetail = async () => {
      const res = await refetchData({
        url: `/user/m1044_8/${data?.store_id}`,
        method: 'GET',
      });
      if (res?.data?.status === '200') {
        setDataInterlock(res?.data?.data || []);
      } else {
        notification({ res });
      }
    };
    getDetail();
  }, [data]);

  const onSave = async () => {
    const resData = {
      storeId: data?.store_id,
      interlockRequest: (dataInterlock || [])
        .filter(
          o => (o?.interlock_cd || '').trim() || (o?.interlock_nm || '').trim(),
        )
        .map(o => ({
          mallInfoId: o?.mall_info_id,
          interlockCd: (o?.interlock_cd || '').trim(),
          interlockNm: (o?.interlock_nm || '').trim(),
        })),
    };
    const res = await refetchData({
      url: '/user/m1044_8',
      method: 'PUT',
      data: resData,
    });
    notification({ res });
    if (res?.data?.status == '201') {
      onDone?.(resData?.interlockRequest?.length);
    }
  };

  // generate colums grid
  const columns = [
    {
      dataField: 'mall_info_id',
      caption: t('Mall ID'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'mall_cd',
      caption: t('Mall Code'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'mall_nm',
      caption: t('Mall Name'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'use_yn',
      caption: t('Use'),
      dataType: 'boolean',
      editorType: 'dxSwitch',
      allowEditing: false,
    },
    {
      dataField: 'interlock_cd',
      caption: t('Interlock Code'),
      alignment: 'left',
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'interlock_nm',
      caption: t('Interlock Name'),
      alignment: 'left',
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
  ];

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <LoadPanel visible={loading} />
        <div style={{ padding: '0 5px' }}>
          <div className="modal-popup-header">
            <span className="title-page">{data?.store_nm}</span>
            <div>
              <Button
                stylingMode="contained"
                type="default"
                text={t('Save')}
                icon="check"
                style={{ marginRight: 5 }}
                onClick={onSave}
              />
              <Button
                stylingMode="contained"
                text={t('Cancel')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
          <div
            style={{
              borderBottom: '1px solid #d5d9da',
              paddingBottom: 10,
              fontWeight: 600,
              fontSize: 18,
              color: '#333333',
              background: '#ffffff',
              padding: 10,
            }}
          >
            {t('Interlock Setting')}
          </div>
          <DataGridFull
            ref={dataGridRef}
            dataSource={dataInterlock}
            columns={columns}
            options={{
              columnAutoWidth: true,
              height: isMobile ? 'calc(80vh - 200px)' : 'calc(100vh - 200px)',
              editing: {
                mode: 'cell',
                allowUpdating: true,
                selectTextOnEditStart: true,
              },
              onEditorPreparing: function (e) {
                const indexOf = columnStoreInterlockSettingEdit.indexOf(
                  e?.dataField,
                );
                if (e?.parentType === 'dataRow' && indexOf !== -1) {
                  e.editorOptions.onKeyDown = function (ekey) {
                    if (ekey?.event?.keyCode == 13) {
                      const dataGrid = dataGridRef?.current?.instance;
                      let nextElement: any = null;
                      if (
                        columnStoreInterlockSettingEdit.length - 1 ===
                        indexOf
                      ) {
                        nextElement = dataGrid?.getCellElement(
                          e?.row?.rowIndex + 1,
                          columnStoreInterlockSettingEdit[0],
                        );
                      } else {
                        nextElement = dataGrid?.getCellElement(
                          e?.row?.rowIndex,
                          columnStoreInterlockSettingEdit[indexOf + 1],
                        );
                      }
                      if (nextElement) {
                        ekey?.event?.stopPropagation();
                        dataGrid?.focus(nextElement);
                        nextElement?.click();
                      }
                    }
                  };
                }
              },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
            }}
          />
        </div>
      </ScrollView>
    );
  };
  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={data?.store_nm}
      contentRender={content}
      height={isMobile ? '80vh' : '100vh'}
      width={isMobile ? '96%' : '60vw'}
    />
  );
};

export default PopupStoreInterlockSetting;
