/**
 * Update date: 14-06-2023
 * Template barcode component
 */
import useAxios from 'axios-hooks';
import { TextBox } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PopupHistoryBarcode from '../PopupCommon/PopupHistoryBarcode';
import PopupScanBarcode from '../PopupCommon/PopupScanBarcode';
import LoadPanel from 'app/components/LoadPanel';
import { useIndexedDB } from 'react-indexed-db';
import { useApp } from 'app';

const BarcodeTemplate = (props, ref) => {
  const {
    url,
    onEnter,
    billId,
    onSetBarcode,
    isValidParams,
    params = {},
    typeBarCode = 2,
    disableViewHistory = undefined,
    disableInput = false,
    barcodes,
    isFocus = false,
    readOnly = false,
  } = props;
  const { add, getAll, deleteRecord } = useIndexedDB('barcodes');
  const appContext: any = useApp();
  const { s } = appContext || {};
  const [showModalBarcode, setShowModalBarcode] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [historyBarcode, setHistoryBarcode] = useState([]);
  const [errorBarcode, setErrorBarcode] = useState('');
  const textBoxRef: any = useRef(null);

  const [showPopupScanBarcode, setShowPopupScanBarcode] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const { t }: any = useDictionary({});

  const [{ loading: loadingBarcode }, fetchProductByBarcode] = useAxios(
    {
      url: url,
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * on add barcode history
   *
   * @param {*} e
   * @return {*}
   */
  const addBarcodeHistory = e => {
    if (!e || e === '') return;
    let l: any = [...historyBarcode] || [];
    let scanDm = new Date().getTime();
    l.push({ scanBarcode: e, scanDm });
    setHistoryBarcode(l);
    onSetBarcode?.(l);
  };

  /**
   * on check barcode
   *
   * @param {*} [data]
   * @return {*}
   */
  const onCheckBarcode = async (data?: any) => {
    if (isValidParams?.() !== undefined && !isValidParams?.()) return;
    if (!barcode && !data) {
      setErrorBarcode(t('Please input barcode!'));
      return;
    }
    add({
      barcode: data || barcode,
    }).then(() => {
      setBarcode('');
      getAll()
        .then((q: any) => {
          q?.length &&
            q.forEach(async o => {
              if (!requesting) {
                const barcodeData = o?.barcode;
                await deleteRecord(o?.id);
                await onReuquestBarcodeData(barcodeData);
              }
            });
        })
        .catch(() => {});
      if (errorBarcode !== '') {
        setErrorBarcode('');
      }
    });
  };

  /**
   * on request barcode
   *
   * @param {*} [data]
   */
  const onReuquestBarcodeData = async (data?: any) => {
    setRequesting(true);
    const newBarcode = data || barcode;
    const res: any = await fetchProductByBarcode({
      params: Object.assign(params, { barcode: newBarcode }),
    });
    newBarcode && addBarcodeHistory(newBarcode);
    if (res?.data?.status == 200) {
      const dataEnter = { ...res?.data?.data, barcode: newBarcode };
      onEnter(dataEnter);
      (ref || textBoxRef)?.current?.instance?.focus();
    } else {
      if (res?.data?.status == '404') {
        s('missing_barcode');
      } else {
        s('error');
      }
      setErrorBarcode(res?.data?.message);
    }
    (ref || textBoxRef)?.current?.instance?.focus();
    setRequesting(false);
  };

  useEffect(() => {
    if (isFocus) {
      (ref || textBoxRef)?.current?.instance?.focus();
    }
  }, [isFocus, ref]);

  // useEffect(() => {
  // }, [getAll]);

  return (
    <>
      <LoadPanel visible={loadingBarcode} />
      {!disableInput && (
        <TextBox
          ref={ref ? ref : textBoxRef}
          value={barcode}
          onValueChanged={e => setBarcode(e?.value)}
          onEnterKey={() => onCheckBarcode()}
          readOnly={readOnly}
          buttons={[
            {
              name: 'search',
              location: 'after',
              options: {
                stylingMode: 'text',
                icon: 'photo',
                onClick: () => setShowPopupScanBarcode(!showPopupScanBarcode),
              },
            },
          ]}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <span
          className="cell-description"
          onClick={() => setShowModalBarcode(!showModalBarcode)}
        >
          {disableViewHistory ? null : <p> {t('View barcode scanned')} </p>}
          {errorBarcode && <p className="cell-error-text"> {errorBarcode} </p>}
        </span>
        <span />
        <span />
      </div>

      {showModalBarcode && (
        <PopupHistoryBarcode
          visible={showModalBarcode}
          onHiding={() => setShowModalBarcode(!showModalBarcode)}
          dataSource={historyBarcode}
          billId={billId}
          type={typeBarCode}
        />
      )}
      {showPopupScanBarcode && (
        <PopupScanBarcode
          visible={showPopupScanBarcode}
          onHiding={() => setShowPopupScanBarcode(false)}
          error={errorBarcode}
          onSubmit={async e => {
            if (e) {
              await onCheckBarcode(e);
            }
          }}
        />
      )}
    </>
  );
};
export default forwardRef(BarcodeTemplate);
