/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Use consistent styling
// import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { store } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';
import { DBConfig } from '../src/models/DBConfig';
import { initDB } from 'react-indexed-db';

import dxDateBox from 'devextreme/ui/date_box';
import { RecoilRoot } from 'recoil';
// Set default options globally for DateBox
dxDateBox.defaultOptions({
  options: {
    useMaskBehavior: true,
  },
});

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

initDB(DBConfig);

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
