/**
 * Update date: 31-05-2023
 * Screen 2051.1
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { WAREHOUSE_INSTOCK_COUNT_URL } from 'constant/apiUrl';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import { TRADE_TYPE } from 'constant';
import useDictionary from 'hooks/useDictionary';

const ListInventoryCount = React.lazy(() => import('./ListInventoryCount'));

const defaultParamsInstock = {
  largeCategory: '',
  subCategory: '',
  mediumCategory: '',
  smallCategory: '',
  product: '',
  productGroup: '',
  productType: '',
  inStock: null,
};
/**
 * create context
 */
const InstockContext = createContext({});

InstockContext.displayName = 'InstockContext';

export const useInstock = () => {
  const context = useContext(InstockContext);
  return context;
};

const InventoryCount = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({
    programId: '2051.1',
  });
  const [params, setParams] = useState({
    ...defaultParamsInstock,
    startDuration: new Date(),
    endDuration: new Date(),
    formStore: {
      largeCategory: [],
      subCategory: [],
      mediumCategory: [],
      smallCategory: [],
      product: [],
      productGroup: [],
      productType: [],
    },
  });

  const [{ data, loading }, refetchStockCount] = useAxios(
    {
      url: WAREHOUSE_INSTOCK_COUNT_URL,
      method: 'POST',
    },
    { manual: true },
  );
  const store: any = new ArrayStore({
    data: data?.data?.map((o, i) => ({
      ...o,
      id: i,
      tradeTypeName: t(
        TRADE_TYPE?.find(t => t?.value === o?.tradeType)?.text || '',
      ),
    })),
    key: 'id',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      formStore: {
        ...preState?.formStore,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    productTpPermission,
    store,
    setModeView,
    modeView,
    params,
    setParams,
    refetchStockCount,
  };

  const loadingAll = loading || loadingProductTpPermission;
  return (
    <InstockContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <ListInventoryCount />
      </React.Suspense>
    </InstockContext.Provider>
  );
};

export default InventoryCount;
