/*
 * 2082.2 Employee/Daily Salary Registration
 * @since 2022/10/12
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';

const Payment = React.lazy(() => import('./Payment'));
const List = React.lazy(() => import('./List'));

// Initialize context
const PurchaseSettleAndWithdrawalRegisContext = createContext({});
PurchaseSettleAndWithdrawalRegisContext.displayName =
  'PurchaseSettleAndWithdrawalRegisContext';
export const usePurchaseSettleAndWithdrawalRegis = () => {
  const context = useContext(PurchaseSettleAndWithdrawalRegisContext);
  return context;
};

/**
 * Employee/Daily Salary Registration
 *
 * @export
 * @return {*}
 */
export default function PurchaseSettleAndWithdrawalRegisRegistration() {
  // Initialize state
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
  });

  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      vendor: [],
    },
    vendor: '',
  });
  // Get multi language
  const { t }: any = useDictionary({
    programId: '2082.2',
  });

  // Api get master grid data
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'warehouse/purchase-settle',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  // Api get detail data
  const [{ data: dataDetail, loading: loadingDetail }, refetchDetail] =
    useAxios(
      {
        url: 'warehouse/vendor-payment/detail/by',
        method: 'GET',
      },
      { manual: true, autoCancel: true },
    );

  // Api create or update data
  const [{ loading: loadingSave }, refetchSave] = useAxios(
    {
      url: 'warehouse/vendor-payment',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );

  // Api delete data
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: 'warehouse/vendor-payment',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: dataList?.data || [],
  });

  const storeDetail: any = new ArrayStore({
    data: dataDetail?.data?.vendor_payments || [],
    key: ['line_id', 'payment_dt'],
  });

  /**
   * On search data for master grid
   *
   */
  const onSearch = () => {
    const searchParams = {
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      vendorIds: formData?.dataList.vendor.map((o: any) => o?.vendorId),
    };
    refetchData({
      data: {
        ...searchParams,
      },
    });
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'payment':
        return <Payment />;
      default:
        return <NotFoundPage />;
    }
  };

  // Values share with child component
  const value: any = {
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    refetchDetail,
    refetchSave,
    storeDetail,
    refetchDelete,
    formData,
    dataDetail: dataDetail?.data || {},
    onSearch,
    setFormData,
  };

  const loadingAll =
    loadingList || loadingDetail || loadingSave || loadingDelete;

  return (
    <PurchaseSettleAndWithdrawalRegisContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </PurchaseSettleAndWithdrawalRegisContext.Provider>
  );
}
