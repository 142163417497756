/**
 * Update date: 31-05-2023
 * Screen 2051.2
 */
import React, { useRef } from 'react';
import { useInventoryReceiving } from '..';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import useFormat from 'hooks/useFormat';
const exportFileName =
  'SMDC_2051.2 Inventory Receiving and forwarding by product_' +
  moment().format('YYYYMMDD');

function ListInventory(props) {
  const context: any = useInventoryReceiving();
  const { store, loadingInventory } = context;
  const dataRef: any = useRef(null);
  const { AmountFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '2051.2',
  });
  const columns: any = [
    {
      caption: t('Product Category'),
      alignment: 'center',
      visible: false,
      columns: [
        {
          dataField: 'largeCategory',
          caption: t('Large Category'),
          showWhenGrouped: true,
        },
        {
          dataField: 'mediumCategory',
          caption: t('Medium Category'),
          showWhenGrouped: true,
        },
        {
          dataField: 'smallCategory',
          caption: t('Small Category'),
          showWhenGrouped: true,
        },
        {
          dataField: 'subCategory',
          caption: t('Sub Category'),
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'productType',
          caption: t('Product Type'),
          showWhenGrouped: true,
        },
        {
          dataField: 'productCode',
          caption: t('Product code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'productName',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'productMaker',
          caption: t('Maker'),
          showWhenGrouped: true,
        },
        {
          dataField: 'vendor',
          caption: t('Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'marketAbleSize',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Classsification'),
      alignment: 'center',
      columns: [
        {
          dataField: 'date',
          caption: t('Date'),
          alignment: 'left',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Receiving'),
      alignment: 'center',
      columns: [
        {
          dataField: 'receivingEA',
          caption: t('Qty(EA)'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'receivingPrice',
          caption: t('Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Take Out'),
      alignment: 'center',
      columns: [
        {
          dataField: 'takeOutEA',
          caption: t('Qty(EA)'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'takeOutPrice',
          caption: t('Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      columns: [
        {
          dataField: 'forwardEA',
          caption: t('Qty(EA)'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'forwardPrice',
          caption: t('Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Return'),
      alignment: 'center',
      columns: [
        {
          dataField: 'returnEA',
          caption: t('Qty(EA)'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'returnPrice',
          caption: t('Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Adjustment'),
      alignment: 'center',
      columns: [
        {
          dataField: 'adjustmentEA',
          caption: t('Qty(EA)'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'adjustmentPrice',
          caption: t('Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('In Stock'),
      alignment: 'center',
      columns: [
        {
          dataField: 'inStockEA',
          caption: t('Qty(EA)'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'inStockPrice',
          caption: t('Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
  ];
  const exportExcel = () => {
    dataRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <LoadPanel visible={loadingInventory} />
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataRef}
        dataSource={store}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          key: 'id',
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'receivingEA',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receivingPrice',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'takeOutEA',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'takeOutPrice',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'forwardEA',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'forwardPrice',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'returnEA',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'returnPrice',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'adjustmentEA',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'adjustmentPrice',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'inStockEA',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'inStockPrice',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default ListInventory;
