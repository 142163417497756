/**
 * Update date: 25-05-2023
 * Screen 2041.8
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import TableList from './TableList';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
/**
 * create context
 */
const receivingLoad = createContext({
  dataSource: {},
  refetchData: () => {},
});

receivingLoad.displayName = 'receivingLoad';
export const useReceivingLoad = () => {
  const context = useContext(receivingLoad);
  return context;
};

function ReceiveLoadInquiry() {
  const [dataSource, setDataSource] = useState<any>([]);

  const [{ data, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/ecommerce/location-move-process',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const value: any = {
    dataSource,
    refetchData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setDataSource(data?.data);
    }
  }, [data]);
  return (
    <>
      <receivingLoad.Provider value={value}>
        <LoadPanel visible={loadingData} />
        <FormSearch />
        <TableList />
      </receivingLoad.Provider>
    </>
  );
}

export default ReceiveLoadInquiry;
