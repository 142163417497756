/**
 * Update date: 23-05-2023
 * Screen 2032.2
 */
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { useRef } from 'react';
import { useInquiresOfScheduled } from '..';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';

const exportFileName =
  'SMDC_2023.2 Inquiry of scheduled delivery through barcode scan_' +
  moment().format('YYYYMMDD');

function ListInventory(props) {
  const { themePro }: any = useApp();
  const context: any = useInquiresOfScheduled();
  const { store } = context;
  const dataGridRef: any = useRef(null);
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '2032.2',
  });
  /**
   * render status
   *
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    return e?.value === '0' ? 'Pending' : e?.value === '1' ? 'Fixed' : '';
  };

  const columns: any = [
    {
      caption: t('Order'),
      alignment: 'center',
      items: [
        {
          dataField: 'billId',
          caption: t('Bill ID'),
          showWhenGrouped: true,
        },
        {
          dataField: 'date',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'vendorName',
          caption: t('Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'stateName',
          caption: t('State'),
          showWhenGrouped: true,
        },
        {
          dataField: 'status',
          caption: t('Status'),
          showWhenGrouped: true,
          cellRender: renderStatus,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'productCode',
          caption: t('Product code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'productName',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'marketableSize',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        {
          dataField: 'optionCode',
          caption: t('Option Code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'optionName',
          caption: t('Option'),
          showWhenGrouped: true,
          alignment: themePro ? 'left' : 'center',
        },
        {
          dataField: 'quantityPerPack',
          caption: t('Quantity Per Pack'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      columns: [
        {
          dataField: 'purchaseUnitPrice',
          caption: t('Purchase Unit Price'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderQuantity',
          caption: t('Qty'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderEa',
          caption: t('EA'),
          dataType: 'number',
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderAmount',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderSupplyAmount',
          caption: t('Supply Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderVat',
          caption: t('VAT'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderBottle',
          caption: t('Bottle'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderContainer',
          caption: t('Container'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'orderGTotal',
          caption: t('G.Total'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      dataField: 'billNote',
      caption: t('Bill Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'lineNote',
      caption: t('Line Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'bottleId',
      caption: t('Bottle ID'),
      showWhenGrouped: true,
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'bottleUnitPrice',
      caption: t('Bottle Unit Price'),
      format: AmountFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerId',
      caption: t('Container ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'containerUnitPrice',
      caption: t('Container Unit Price'),
      format: AmountFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'ordererName',
      caption: t('Orderer'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'currencyName',
      caption: t('Currency'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'makerName',
      caption: t('Maker'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'originName',
      caption: t('Origin'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'defaultVendorName',
      caption: t('Default Vendor'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'largeCategoryName',
      caption: t('Large category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'mediumCategoryName',
      caption: t('Medium category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'smallCategoryName',
      caption: t('Small category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'subCategoryName',
      caption: t('Sub category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerDateTime',
      caption: t('Register Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerUserName',
      caption: t('Register User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerProgramId',
      caption: t('Register Program ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyDateTime',
      caption: t('Modify Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyUserName',
      caption: t('Modify User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyProgramId',
      caption: t('Modify Program ID'),
      showWhenGrouped: true,
      visible: false,
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={store}
        options={{
          groupPanel: {
            visible: !isMobile,
          },
          columnAutoWidth: true,
          key: 'id',
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                locateInMenu: 'auto',
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'orderQuantity',
                column: 'orderQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'orderEa',
                column: 'orderEa',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'orderAmount',
                column: 'orderAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'orderSupplyAmount',
                column: 'orderSupplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'orderVat',
                column: 'orderVat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'orderBottle',
                column: 'orderBottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'orderContainer',
                column: 'orderContainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'orderGTotal',
                column: 'orderGTotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'orderEa',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'orderAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'orderSupplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'orderVat',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'orderBottle',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'orderContainer',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'orderGTotal',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
    </>
  );
}

export default ListInventory;
