import { createSlice } from '@reduxjs/toolkit';

export interface CenterRegistrationState {
  data: {};
}
const initialState: CenterRegistrationState = {
  data: {},
};

const centerRegistrationSlice = createSlice({
  name: 'centerRegistration',
  initialState,
  reducers: {
    registerCenterInfo(state, actions) {},
    fetchCenterInfo(state, actions) {},
    fetchCenterInfoSuccess(state, actions) {
      state.data = actions.payload;
    },
  },
});
//Action
export const centerRegistrationActions = centerRegistrationSlice.actions;

//Selectors
export const centerInfo = (state: any) => state.centerRegistration.data;

const centerRegistrationReducer = centerRegistrationSlice.reducer;

export default centerRegistrationReducer;
