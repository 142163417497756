/**
 * Update date: 22-05-2023
 * Screen 2017.3
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ListProductMaster from './List';
import { useProductType } from 'hooks/useProductType';

const defaultParams = {
  mallInfoId: '',
  startChangeDate: new Date(),
  endChangeDate: new Date(),
  largeCategories: [],
  mediumCategories: [],
  smallCategories: [],
  subCategories: [],
  productGroupIds: [],
  productIds: [],
  status: '',
};
  /**
   * create context
   */
const ProductMasterContext = createContext({
  params: {},
  mallProductData: {} || null,
  setParams: () => {},
});

ProductMasterContext.displayName = 'ProductMasterContext';

export const useProductMaster = () => {
  const context = useContext(ProductMasterContext);
  return context;
};

export default function ProductMaster() {
  const [params, setParams] = useState(defaultParams);
  const [productMasterData, setProductMasterData] = useState({});
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  // Get data product master
  const [{ data: mallProductRes, loading: mallProductLoading }, refetchData] =
    useAxios(
      {
        url: '/ecommerce/mall-stock',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  const [{ loading: mallProducUpdatetLoading }, updateProductMaster] = useAxios(
    {
      url: '/ecommerce/mall-stock/save',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    const dataResponse = mallProductRes?.data || {};
    dataResponse.selectedList = (dataResponse.selectedList || []).map(item => {
      item.productInterlock = !!item.productInterlock;
      item.stockInterlock = !!item.stockInterlock;
      return item;
    });
    dataResponse.unSelectedList = (dataResponse.unSelectedList || []).map(
      item => {
        item.productInterlock = !!item.productInterlock;
        item.stockInterlock = !!item.stockInterlock;
        return item;
      },
    );
    setProductMasterData(dataResponse);
  }, [mallProductRes]);

  const value: any = {
    params,
    setParams,
    productMasterData,
    refetchData,
    updateProductMaster,
    productTpPermission,
  };

  const loadingAll =
    mallProductLoading ||
    mallProducUpdatetLoading ||
    loadingProductTpPermission;

  return (
    <ProductMasterContext.Provider value={value}>
      <>
        <LoadPanel visible={loadingAll} />
        <ListProductMaster />
      </>
    </ProductMasterContext.Provider>
  );
}
