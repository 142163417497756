/**
 * Update date: 31-05-2023
 * Screen 2052.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';

interface IPopup2088_1Detail {
  onHiding?: () => void;
}

function PopupDetail({ onHiding }: IPopup2088_1Detail, ref) {
  const { t }: any = useDictionary({ programId: '2088.1' });
  const dataGridRef: any = useRef(null);
  const { DateFormat } = useFormatTime();
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();
  const [detailData, setDetailData] = useState<any>([]);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = async (data: any) => {
    if (!data) return;
    setShowPopupConfirm(true);
    const res = await refetchData({
      url: `/account/m2088_1/detail-forward/${data}`,
      method: 'GET',
    });
    if (res?.data?.status === '200') {
      const resMapping = (res?.data?.data || []).map(o => ({
        ...o,
        taskNm: t(o?.taskNm),
      }));
      setDetailData(resMapping);
    } else {
      notification({ res });
    }
  };

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const onClickNo = () => {
    onHiding?.();
    setShowPopupConfirm(false);
    setDetailData([]);
  };

  const columns: any = [
    {
      dataField: 'taskNm',
      caption: t('Task Type'),
    },
    {
      dataField: 'billId',
      caption: t('Bill ID'),
    },
    {
      dataField: 'date',
      caption: t('Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'storeNm',
      caption: t('Store'),
    },
    {
      dataField: 'zoneNm',
      caption: t('Zone'),
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
    },
    {
      dataField: 'productNm',
      caption: t('Product Name'),
    },
    {
      dataField: 'optionCd',
      caption: t('Option Code'),
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
    },
    {
      dataField: 'supplyUnitPrice',
      caption: t('Supply Unit Price'),
      format: DoubleFormat,
      dataType: 'number',
    },
    {
      dataField: 'quantity',
      caption: t('Qty'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'ea',
      caption: t('EA'),
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'standardUnitPrice',
      caption: t('Supply Standard Unit Price'),
      format: DoubleFormat,
      dataType: 'number',
    },
    {
      dataField: 'marginRate',
      caption: t('Margin Rate'),
      format: DoubleFormat,
      dataType: 'number',
    },
    {
      dataField: 'dcUnitPrice',
      caption: t('Discount Unit Price'),
      format: DoubleFormat,
      dataType: 'number',
    },
    {
      dataField: 'discountAmount',
      caption: t('Discount Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'amount',
      caption: t('Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'supplyAmount',
      caption: t('Supply Amount'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'vat',
      caption: t('VAT'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'bottle',
      caption: t('Bottle'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'container',
      caption: t('Container'),
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'shippingFee',
      caption: t('Shipping Fee'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'addShippingAmount',
      caption: t('Add Shipping Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'totalAmount',
      caption: t('G.Total'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'optionProperty01',
      caption: t('Internal Product ID'),
      visible: false,
    },
    {
      dataField: 'optionProperty02',
      caption: t('Internal Product Name'),
      visible: false,
    },
    {
      dataField: 'optionProperty03',
      caption: t('Internal Option ID'),
      visible: false,
    },
    {
      dataField: 'optionProperty04',
      caption: t('Internal Option Name'),
      visible: false,
    },
    {
      dataField: 'item01',
      caption: t('Additional Information ID'),
      visible: false,
    },
    {
      dataField: 'item04',
      caption: t('ESL Product Name'),
      visible: false,
    },
    {
      dataField: 'item05',
      caption: t('ESL Comment'),
      visible: false,
    },
    {
      dataField: 'item06',
      caption: t('ESL Bedge'),
      visible: false,
    },
  ];

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading} />
          <div className="modal-popup-header">
            <span className="title-page">{t('Forward Detail')}</span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={onClickNo}
              />
            </div>
          </div>
          <DataGridFull
            ref={dataGridRef}
            dataSource={detailData}
            columns={columns}
            fixedLeft={2}
            fixedRight={1}
            options={{
              height: isMobile ? 'calc(80vh - 200px)' : 'calc(100vh - 140px)',
              columnAutoWidth: true,
              export: {
                fileName: `2088.1-Forward Detail_${moment().format(
                  'YYYYMMDD',
                )}`,
              },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                      icon: 'xlsxfile',
                      text: t('Export Excel'),
                      disabled: !detailData?.length,
                      onClick: () => exportExcel(),
                    },
                  },
                );
              },
              summary: {
                totalItems: [
                  {
                    column: 'quantity',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'ea',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'discountAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'amount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'supplyAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'vat',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'bottle',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'container',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'shippingFee',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'addShippingAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                  {
                    column: 'totalAmount',
                    summaryType: 'sum',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                  },
                ],
              },
            }}
          />
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      title={''}
      className="modal-content-popup"
      visible={showPopupConfirm}
      contentRender={content}
      height={'100vh'}
      width={isMobile ? '96%' : '85vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default memo(forwardRef(PopupDetail));
