import { stringToDate, compareDate } from 'utils/format';
import * as XLSX from 'xlsx';

export const readerExcel = (file: any, callback: Function): any => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  reader.onload = () => {
    let paymentStartDt: any = null;
    let paymentEndDt: any = null;
    const workbook = XLSX.read(reader.result, {
      type: 'binary',
    });
    const dataExcel = XLSX.utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]],
      {
        header: 1,
        raw: false,
        defval: '',
        blankrows: false,
      },
    );
    if (dataExcel?.length <= 1) {
      callback({ paymentStartDt, paymentEndDt });
      return;
    }
    for (let index = 1; index < dataExcel.length; index++) {
      const row: any = dataExcel[index];
      const cellData = row?.[0] || null;
      const pgPaymentDt = stringToDate(cellData);
      if (!pgPaymentDt) continue;
      if (!paymentStartDt || compareDate(paymentStartDt, pgPaymentDt) === 1) {
        paymentStartDt = pgPaymentDt;
      }
      if (!paymentEndDt || compareDate(paymentEndDt, pgPaymentDt) === -1) {
        paymentEndDt = pgPaymentDt;
      }
    }
    callback({ paymentStartDt, paymentEndDt });
  };
};
