/**
 * Update date: 2023-09-22
 * Screen 2013.8
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2013_8_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2013_8 = () => {
  const { t }: any = useDictionary({ programId: '2013.8' });
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2013_8;
