/**
 * Update date: 2023-26-12
 * Screen 2044.A
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

const List = React.lazy(() => import('./List'));
const CancelStockAllocation = React.lazy(
  () => import('./CancelStockAllocation'),
);
const Product = React.lazy(() => import('./Product'));
const Detail = React.lazy(() => import('./Detail'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2044_A_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2044_A = () => {
  const [dataListLocal, setDataListLocal] = useLocalStorage(
    '2044.A_dataList',
    {},
  );
  const [dataInfoLocal, setDataInfoLocal] = useLocalStorage(
    '2044.A_data_info',
    {},
  );
  const { t }: any = useDictionary({ programId: '2044.A' });
  const [deliveryModal, setDeliveryModal] = useState<number>(0);
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    wayBill: '',
    orderId: '',
    productType: '',
    isCancelStock: false,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    fromTime: new Date(),
    endTime: new Date(),
    fromDeliveryDate: null,
    endDeliveryDate: null,
    fromIsCheck: false,
    endIsCheck: false,
    addressFilterType: '0',
    dataList: {
      mall: [],
      zone: [],
      storeGroup: [],
      store: [],
      receivableCd: [],
      addressFilter: [],
      productType: [],
      productGroup: [],
    },
    mall: '',
    zone: '',
    storeGroup: '',
    store: '',
    receivableCd: '',
    addressFilter: '',
    productType: '',
    productGroup: '',
    searchKeyWordIn: '1',
    keyWord: '',
    priOrDel: '',
  });

  const [formInfoData, setFormInfoData] = useState<any>({
    sendDue: new Date(),
    waybillRule: '2',
    note: '',
    dataList: {
      sendUser: [],
    },
    sendUser: '',
  });

  // cancel stage
  const [dataCancelStock, setDataCancelStock] = useState([]);
  const [formSearchCancel, setFormSearchCancel] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    waybill: '',
    dataList: {
      zone: [],
      shippingMethod: [],
      sendUser: [],
      allocation: [],
    },
    zone: '',
    shippingMethod: '',
    sendUser: '',
    allocation: '',
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    setFormSearchData(preState => ({
      ...preState,
      // fromIsCheck: dataListLocal?.fromIsCheck || false,
      // endIsCheck: dataListLocal?.endIsCheck || false,
      dataList: {
        ...preState?.dataList,
        mall: dataListLocal?.mall || [],
        receivableCd: dataListLocal?.receivableCd || [],
        zone: dataListLocal?.zone || [],
        productType: dataListLocal?.productType || [],
      },
    }));
  }, [dataListLocal]);

  useEffect(() => {
    setFormInfoData(preState => ({
      ...preState,
      waybillRule: dataInfoLocal?.waybillRule || preState?.waybillRule,
    }));
  }, [dataInfoLocal]);

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    formInfoData,
    setFormInfoData,
    //cancel stock
    dataCancelStock,
    setDataCancelStock,
    formSearchCancel,
    setFormSearchCancel,
    setDataListLocal,
    deliveryModal,
    setDeliveryModal,
    setDataInfoLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'cancelStock':
        return <CancelStockAllocation />;
      case 'product':
        return <Product />;
      case 'detail':
        return <Detail />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2044_A;
