/**
 * Update date: 07-06-2023
 * Screen 2081.9
 */
import React, { useRef, useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from '../../../../components/Collapse';
import { useMemberBondBalanceInquiry } from '../..';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import { cloneDeep, uniqBy } from 'lodash';
import PopupSelectStoreGroup from 'app/components/PopupCommon/PopupSelectStoreGroup';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  storeType: 'ST',
  receivableCd: 'RC',
};

const FormSearch = () => {
  const context: any = useMemberBondBalanceInquiry();
  const { t, formSearchData, setFormSearchData, refetchReceiveMember } =
    context;
  const [showPopupByType, setShowPopupByType] = useState('');
  const formRef: any = useRef(null);

  /**
   * on submit
   *
   */
  const onSubmit = () => {
    const dataRequest = {
      storeGroupIds: formSearchData.dataList?.storeGroup?.map(
        (o: any) => o?.storeGroupId,
      ),
      storeIds: formSearchData.dataList?.store?.map((o: any) => o?.storeNo),
      storeTypes: formSearchData?.dataList?.storeType.map((o: any) => o?.code),
      receivableCds: formSearchData.dataList?.receivableCd?.map(
        (o: any) => o?.code,
      ),
      representative: formSearchData?.representative || '',
    };

    refetchReceiveMember({
      data: dataRequest,
    });
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    const newData = {
      ...formSearchData,
      dataList: dataList,
    };
    setFormSearchData(newData);
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formSearchData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormSearchData(newData);
    setShowPopupByType('');
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'storeType':
      case 'receivableCd':
        return (
          <PopupSelectCommonCode
            text={formSearchData?.[popupType]}
            mode="multiple"
            codeType={listTypeCommon?.[popupType]}
            visible={!!listTypeCommon?.[popupType]}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      case 'store':
        return (
          <PopupSelectStore
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('store', o, 'storeNo')}
          />
        );
      case 'storeGroup':
        return (
          <PopupSelectStoreGroup
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('storeGroup', o, 'storeGroupId')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formSearchData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formSearchData}
          showColonAfterLabel={false}
          items={[
            generateDxTextBox('Store Type', 'storeType', 'name'),
            generateDxTextBox('Store', 'store', 'storeNm'),
            generateDxTextBox('Store Group', 'storeGroup', 'storeGroupName'),
            generateDxTextBox('Receivable Group', 'receivableCd', 'name'),
            {
              dataField: 'representative',
              editorType: 'dxTextBox',
              label: {
                text: t('Representative'),
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        />
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
