// Update date: 10-05-2023
// Screen 1041.1

import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FormCreate from './FormCreate';
import TreeViewList from './TreeViewList';
import { useApp } from 'app';

// Create context
const RegisterProgramContext = createContext({
  refetchData: () => {},
  loadingData: false,
  dataInventory: [],
});

RegisterProgramContext.displayName = 'RegisterProgramContext';

export const useRegisterProgram = () => {
  const context = useContext(RegisterProgramContext);
  return context;
};

function RegisterProgramPage(props) {
  const treeViewProgramRef: any = useRef(null);
  const { themePro }: any = useApp();
  const formRef: any = useRef(null);
  const [treeList, setTreeList] = useState<any>([]);
  const [selected, setSelected] = useState<any>({
    type: '',
    data: null,
  });

  const [{ loading }, refetchData] = useAxios(
    {
      url: '/core/program',
      method: 'GET',
    },
    { manual: true },
  );
  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: '/core/program/update_order',
      method: 'POST',
    },
    { manual: true },
  );
  // Update data when submit
  const onUpdate = (type, data, programIdOld) => {
    if (!type) return;
    let l: any = [];

    if (type === 'edit') {
      l = [...treeList]?.map((o: any) => {
        if (o?.programId === programIdOld) return data;
        return o;
      });
    } else {
      l = [...treeList];
      l.push(data);
    }
    setTreeList(l);
    setSelected({ type: '', data: null });
  };
  // Delete program
  const onDelete = programId => {
    setTreeList(preState =>
      preState.filter((f: any) => f?.programId !== programId),
    );
    setSelected({ type: '', data: null });
  };
  // format data
  const formatData = data => {
    return data
      .map(({ parentId, ...o }) => {
        if (o?.level === 1) return { ...o, isDirectory: true };
        else if (o?.level === 2)
          return { ...o, isDirectory: true, parentId: parentId };
        else {
          return { ...o, isDirectory: false, parentId: parentId };
        }
      })
      .sort((a, b) => {
        if (a?.level === b?.level) {
          return a?.orders - b?.orders;
        }
        return a?.level - b?.level;
      });
  };

  const fetchData = useCallback(async () => {
    const res = await refetchData();
    if (res?.data?.status === '200') {
      const { data } = res?.data;
      const dataFormat = formatData(data);
      setTreeList(dataFormat);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [refetchData]);

  const value: any = {
    treeList,
    setTreeList,
    selected,
    setSelected,
    onDelete,
    onUpdate,
    refetchData,
    refetchUpdate,
    formatData,
  };
  return (
    <>
      <LoadPanel visible={loading || loadingUpdate} />
      <RegisterProgramContext.Provider value={value}>
        <div
          style={
            themePro
              ? { display: 'flex', gap: 20 }
              : { display: 'flex', border: '1px solid #ccc' }
          }
        >
          <TreeViewList ref={treeViewProgramRef} />
          <FormCreate ref={formRef} />
        </div>
      </RegisterProgramContext.Provider>
    </>
  );
}

export default RegisterProgramPage;
