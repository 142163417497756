/**
 * Update date: 2024-05-18
 * Screen 2052.C
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import InventoryInquiryLocationTable from './TableView';
/**
 * create context
 */
const ModuleContext = createContext({
  refetchData: () => {},
  loadingData: false,
  inventoryData: [],
});
ModuleContext.displayName = 'M2052_C_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

function M2052_C() {
  const [inventoryData, setInventoryData] = useState([]);
  const [{ data, loading: loadingInventoryData }, refetchData] = useAxios(
    {
      url: '/product/InventoryInquiryLocation/for-linkmom',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );

  const value: any = {
    refetchData,
    inventoryData,
    loadingInventoryData,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setInventoryData(data?.data);
    }
  }, [data]);

  return (
    <>
      <ModuleContext.Provider value={value}>
        <FormSearch />
        <InventoryInquiryLocationTable />
      </ModuleContext.Provider>
    </>
  );
}

export default M2052_C;
