/**
 * Update date: 14-06-2023
 * Popup product detail component
 */
import LoadPanel from 'app/components/LoadPanel';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import useAxios from 'axios-hooks';
import { Button } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useState } from 'react';
import { notification } from 'utils/notification';
import { useProductDetail } from '../..';
import TableMultiple from '../../TableMultiple';
import useFormat from 'hooks/useFormat';

const ViewTop = () => {
  const { setProductData, productId, visible }: any = useProductDetail();
  const { QtyFormat }: any = useFormat();
  const [dataTable, setDataTable] = useState<any>({});
  const [dataSource, setDataSource] = useState<any>([]);
  const [updateMakerPopup, setUpdateMakerPopup] = useState<any>({
    isShow: false,
    data: null,
  });
  const { t } = useDictionary({});
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: '/product/product/product-unit-popup',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  const [{ loading: loadingMaker }, refetchUpdateMaker] = useAxios(
    {
      url: '/product/product/update/product-maker',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  /**
   * on update
   *
   * @param {*} data
   */
  const onUpdateMaker = (data: any) => {
    setUpdateMakerPopup({ isShow: true, data: data });
  };

  /**
   * on submit
   *
   * @param {*} data
   */
  const onSubmitUpdateMaker = async data => {
    const productId = updateMakerPopup?.data?.product_id;
    const dataRequest = {
      productId: productId,
      maker: data?.code,
    };
    const res = await refetchUpdateMaker({
      data: dataRequest,
    });

    notification({ res });
    if (res?.data?.status == '201') {
      setDataSource(prev => {
        const findData = prev?.find(o => o?.product_id === productId);
        if (findData) {
          findData.product_maker = data.code;
          findData.product_maker_nm = data.name;
        }
        return [...prev];
      });
    }
    setUpdateMakerPopup({ isShow: false, data: null });
  };

  const columnInventory: any = [
    {
      caption: t('Inventory turnover rates'),
      columns: [
        {
          label: t('Current Stock'),
          dataField: 'current_stock_ea',
          format: QtyFormat,
          width: 125,
        },
        {
          label: t('Stock'),
          dataField: 'stock_ea',
          format: QtyFormat,
          width: 125,
        },
        {
          label: t('Weekly'),
          dataField: 'forward7_rt',
          // format: QtyFormat,
          width: 125,
          type: 'cursor-visible',
        },
        {
          label: t('Monthly'),
          dataField: 'forward30_rt',
          // format: QtyFormat,
          width: 125,
          type: 'cursor-visible',
        },
        {
          label: t('Yearly'),
          dataField: 'forward365_rt',
          // format: QtyFormat,
          width: 125,
          type: 'cursor-visible',
        },
      ],
    },
  ];

  const columnForward: any = [
    {
      caption: t('Forward date'),
      columns: [
        {
          label: t('Earliest'),
          dataField: 'forward_first_dt',
          type: 'date',
          width: 125,
        },
        {
          label: t('Latest'),
          dataField: 'forward_last_dt',
          type: 'date',
          width: 125,
        },
      ],
    },
  ];

  const columnCompany: any = [
    {
      caption: t('Company information'),
      columns: [
        {
          label: t('Manufacturing company'),
          dataField: 'product_maker_nm',
          width: 125,
          cellRender: (data: any, index: number) => {
            const { product_maker_nm } = data || {};
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{product_maker_nm}</span>
                <Button
                  icon="edit"
                  stylingMode="text"
                  onClick={() => onUpdateMaker(data)}
                />
              </div>
            );
          },
        },
        {
          label: t('Supplying company'),
          dataField: 'vendor_nm_default',
          width: 125,
        },
      ],
    },
  ];
  const columnReceive: any = [
    {
      caption: t('Receive date'),
      columns: [
        {
          label: t('Earliest'),
          dataField: 'receive_first_dt',
          type: 'date',
          width: 125,
        },
        {
          label: t('Latest'),
          dataField: 'receive_last_dt',
          type: 'date',
          width: 125,
        },
      ],
    },
  ];

  const resetData = () => {
    setDataTable({});
    setDataSource([]);
  };

  useEffect(() => {
    if (productId) {
      refetchData({
        data: { type: '1', productId: productId },
      });
    } else if (!visible) {
      resetData();
    }
    if (visible && !productId) {
      console.log('ProductId not found');
    }
  }, [visible, productId]);

  useEffect(() => {
    if (data?.data) {
      setDataTable(data?.data[0]);
      setDataSource(data?.data[0] ? [data?.data[0]] : []);
      setProductData(data?.data[0]);
    }
  }, [data]);

  return (
    <>
      <LoadPanel visible={loading || loadingMaker} />
      <div
        style={{
          display: 'grid',
          gridGap: 20,
          gridTemplateColumns: 'repeat(5, 1fr)',
        }}
      >
        <table className="table-custom-popupproduct-column">
          <tbody>
            <tr>
              <td>{t('Product Name')}</td>
              <td>
                <span>{dataTable?.product_nm}</span>
              </td>
            </tr>
            <tr>
              <td>{t('Product Code')}</td>
              <td>
                <span>{dataTable?.product_cd}</span>
              </td>
            </tr>
            <tr>
              <td>{t('Marketable Size')}</td>
              <td>{dataTable?.marketable_size}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <TableMultiple columns={columnInventory} dataSource={dataSource} />
        </div>
        <div>
          <TableMultiple columns={columnCompany} dataSource={dataSource} main />
        </div>
        <div>
          <TableMultiple columns={columnReceive} dataSource={dataSource} />
        </div>
        <div>
          <TableMultiple columns={columnForward} dataSource={dataSource} />
        </div>
        {updateMakerPopup?.isShow && (
          <PopupSelectCommonCode
            visible={updateMakerPopup?.isShow}
            text={''}
            codeType={'MK'}
            mode="single"
            onHiding={() => setUpdateMakerPopup({ isShow: false, data: null })}
            onSubmit={data => onSubmitUpdateMaker(data)}
          />
        )}
      </div>
    </>
  );
};

export default ViewTop;
