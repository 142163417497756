/**
 * Create date: 2024-06-20
 * Screen 2082.C
 */

import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import LoadPanel from 'app/components/LoadPanel';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import useAxios from 'axios-hooks';
import Form from 'devextreme-react/form';
import useFormatTime from 'hooks/useFormatTime';
import { cloneDeep, uniqBy } from 'lodash';
import { memo, useRef, useState } from 'react';
import { dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';
import { useModuleContext } from '.';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  receivableCd: {
    codeType: 'RC',
  },
};

const FormSearch = () => {
  // const [dataLocal, setDataLocal] = useLocalStorage('2082.C_dataList', {});
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);
  const context: any = useModuleContext();
  const { t, setDataList } = context || {};
  const { DateFormat } = useFormatTime();
  const [showPopupByType, setShowPopupByType] = useState('');
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      vendor: [],
      receivableCd: [],
    },
    vendor: '',
    receivableCd: '',
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmitSearch = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) {
      collapseRef?.current?.onCollappse();
      return;
    }
    const requestData = {
      fromDate: dateFormatStr(formSearchData?.fromDate),
      endDate: dateFormatStr(formSearchData?.endDate),
      vendorIds: formSearchData?.dataList?.vendor?.map(o => o?.vendorId) || [],
      receivableCds: formSearchData?.dataList?.receivableCd?.map(
        (o: any) => o.code,
      ),
    };

    const res = await refetchData({
      url: `user/m2082_g`,
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const data = res?.data?.data.map((e, index) => {
        if (e.type_cd != 1) {
          return {
            ...e,
            id: index + 1,
            type_nm: t(e.type_nm),
            receive_dt: dateFormatStr(e.receive_dt),
          };
        } else {
          return {
            id: index + 1,
            type_nm: t(e.type_nm),
            debit_amount: e.debit_amount,
            vendor_nm: e.vendor_nm,
            vendor_cd: e.vendor_cd,
            receive_dt: t('The Past') + ' - ' + dateFormatStr(e.receive_dt),
          };
        }
      });
      setDataList(data);
    } else {
      notification({ res });
    }
  };
  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    const newData = {
      ...formSearchData,
      dataList: dataList,
    };
    setFormSearchData(newData);
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formSearchData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormSearchData(newData);
    setShowPopupByType('');
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formSearchData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 1) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  /**
   * render get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'vendorId')}
          />
        );
      case 'receivableCd':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formSearchData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };

  /**
   * on field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (
          formSearchData.endDate &&
          e?.value &&
          e?.value > formSearchData.endDate
        )
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        if (
          formSearchData.fromDate &&
          e?.value &&
          e?.value < formSearchData.fromDate
        )
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = name => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          formData={formSearchData}
          ref={formRef}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
          items={[
            {
              label: {
                text: t('From Date'),
              },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formSearchData.fromDate && formSearchData.endDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('From Date is required'),
                },
              ],
            },
            {
              label: {
                text: t('End Date'),
              },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formSearchData.endDate && formSearchData.fromDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('End Date is required'),
                },
              ],
            },
            generateDxTextBox('Vendor', 'vendor', 'vendorName'),
            generateDxTextBox('Receivable Group', 'receivableCd', 'name'),
          ]}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmitSearch} ref={collapseRef}>
        <Search name={t('Search')} />
      </Collapse>
      <LoadPanel visible={loadingFetchData} />
      {getPopupByType(showPopupByType)}
    </div>
  );
};
export default memo(FormSearch);
