/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import moment from 'moment';

const TableSingle = ({ columns, dataSource }) => {
  const onFormat = (data, format) => {
    if (!data && data !== 0) return '';
    return parseFloat(data).toFixed(format?.precision);
  };
  const numberToCurrency = x => {
    if (!x && x !== 0) return '';
    return x.toString().replace(/\B(?!\.\d)(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <table className="table-custom-popupproduct">
      <tbody>
        <tr>
          {columns?.map((o, i) => (
            <th
              key={i}
              colSpan={o?.columns?.length || 1}
              style={{
                color: o?.color || 'inhert',
                whiteSpace: 'pre-wrap',
                position: 'sticky',
                top: 0,
                backgroundColor: '#E4E7EC',
                textAlign: o?.alignment || 'left',
                minWidth: 150,
              }}
            >
              {o?.caption || o?.label}
            </th>
          ))}
        </tr>
        {dataSource?.map((o, i) => (
          <tr key={i}>
            {columns?.map((f, j) => (
              <td key={j} style={{ textAlign: o?.alignment || 'left' }}>
                {f?.format
                  ? numberToCurrency(onFormat(o?.[f.dataField], f?.format))
                  : f.type === 'datetime'
                  ? o?.[f.dataField]
                    ? moment(o?.[f.dataField]).format('YYYY-MM-DD HH:mm')
                    : ''
                  : o?.[f.dataField]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableSingle;
