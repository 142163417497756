/**
 * Update date: 23-05-2023
 * Screen 2031.2
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import SpecialDealTable from './TableView';
import FormSearch from './FormSearch';
import useProfileInfo from 'hooks/useProfileInfo';
import { useProductType } from 'hooks/useProductType';
/**
 * create context
 */
const orderDetail = createContext({
  refetchData: () => {},
  loadingData: false,
  dealData: [],
});
orderDetail.displayName = 'orderDetail';
export const useOrderDetail = () => {
  const context = useContext(orderDetail);
  return context;
};

function OrderDetailCheck() {
  const { vendorName, vendorId } = useProfileInfo();
  const [orderData, setOrderData] = useState([]);
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  const [{ data, loading: loadingOrder }, refetchData] = useAxios(
    {
      url: '/warehouse/order-detail',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const value: any = {
    refetchData,
    orderData,
    loadingOrder,
    vendorName,
    vendorId,
    productTpPermission,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setOrderData(data?.data);
    }
  }, [data]);

  const loadingAll = loadingOrder || loadingProductTpPermission;

  return (
    <>
      <orderDetail.Provider value={value}>
        <LoadPanel visible={loadingAll} />
        <FormSearch />
        <SpecialDealTable />
      </orderDetail.Provider>
    </>
  );
}

export default OrderDetailCheck;
