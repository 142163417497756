/**
 * Update date: 14-06-2023
 * Template image upload component
 */
import useAxios from 'axios-hooks';
import { Button } from 'devextreme-react';
import FileUploader from 'devextreme-react/file-uploader';
import useDictionary from 'hooks/useDictionary';
import React, { useEffect, useState } from 'react';
import LoadPanel from '../LoadPanel';

const allowedFileExtensions = ['.jpeg', '.png', '.jpg'];

interface IPropsImageUpload {
  value?: string;
  onChangeValue?: any;
  alignItems?: string;
  width?: string;
  id?: string;
  accept?: string;
  allowedFile?: any[];
}

const ImageUpload = ({
  value,
  onChangeValue,
  alignItems = 'center',
  width = 'auto',
  id = 'dropzone-external-upload',
  accept = '.jpeg, .png, .jpg',
  allowedFile = [],
}: IPropsImageUpload) => {
  const [imageSource, setImageSource] = useState(value);
  const [isDropZoneActive, setIsDropZoneActive] = useState(false);
  const { t } = useDictionary({});
  const [{ loading }, executePut] = useAxios(
    {
      url: '/user/upload/image',
      method: 'POST',
    },
    { manual: true },
  );
  /**
   * on drop enter
   *
   * @param {*} e
   */
  const onDropZoneEnter = e => {
    if (e.dropZoneElement.id === id) {
      setIsDropZoneActive(false);
    }
  };

  /**
   * on drop leave
   *
   * @param {*} e
   */
  const onDropZoneLeave = e => {
    if (e.dropZoneElement.id === id) {
      setIsDropZoneActive(false);
    }
  };

  /**
   * on upload
   *
   */
  const onUploadStarted = () => {
    setImageSource('');
  };

  /**
   * on clear image
   *
   */
  const onClearImage = () => {
    setImageSource('');
    onChangeValue('');
  };

  /**
   * on value change
   *
   * @param {*} e
   * @return {*}
   */
  const onValueChanged = async (e: any) => {
    if (!e) return;
    const formData = new FormData();
    formData.append('file', e.value[0]);
    const res = await executePut({
      data: formData,
    });
    setImageSource(res?.data?.data || '');
    onChangeValue(res?.data?.data || '');
  };

  useEffect(() => {
    setImageSource(value);
  }, [value]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: alignItems,
      }}
      className="uploadImg"
    >
      <LoadPanel visible={loading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          width: width,
        }}
      >
        <div
          id={id}
          className={`flex-box ${
            isDropZoneActive
              ? 'dx-theme-accent-as-border-color dropzone-active'
              : 'dx-theme-border-color'
          }`}
          style={{
            flex: '0 0 100%',
            backgroundColor: 'rgba(183,183,183, 0.1',
            borderWidth: 2,
            padding: 10,
            borderStyle: 'dashed',
            width: 200,
            height: 200,
          }}
        >
          {imageSource ? (
            <img id="dropzone-image" src={imageSource} alt="" />
          ) : (
            <div id="dropzone-text" className="flex-box">
              <span>{t('No Image')}</span>
            </div>
          )}
        </div>
        <Button
          text={t('Clear')}
          className="btn-cancel-custom"
          onClick={onClearImage}
        />
        <FileUploader
          id="file-uploader"
          // dialogTrigger={`#${id}`}
          dropZone={`#${id}`}
          multiple={false}
          allowedFileExtensions={allowedFile || allowedFileExtensions}
          accept={accept}
          uploadMode="useButtons"
          visible={true}
          onDropZoneEnter={onDropZoneEnter}
          onDropZoneLeave={onDropZoneLeave}
          onUploadStarted={onUploadStarted}
          onValueChanged={onValueChanged}
          labelText=""
          selectButtonText={t('File')}
          maxFileSize={5 * 1024 * 1024}
          elementAttr={{
            class: 'form-upload-custom',
          }}
        />
      </div>
    </div>
  );
};

export default ImageUpload;
