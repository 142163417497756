/*
 * 2081.J Member Monthly Dues Billing Inquiry
 * since 14/12/2022
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useDictionary from 'hooks/useDictionary';
import ArrayStore from 'devextreme/data/array_store';
import useLocalStorage from 'hooks/useLocalStorage';
const ListDepositDeduction = React.lazy(() => import('./ListDepositDeduction'));

// Initialize context
const ModuleContext = createContext({
  searchParams: {},
  listDepositDeduction: [] || null,
  setSearchParams: () => {},
  onUpdatelistDepositDeduction: () => {},
});
ModuleContext.displayName = 'MemberMonthlyDuesBillingInquiry';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Member Monthly Dues Billing Inquiry
 *
 * @return {*}
 */
const MemberMonthlyDuesBillingInquiry = () => {
  // Get multi language
  const { t }: any = useDictionary({
    programId: '2081.J',
  });
  // Get data Local Storage
  const [dataLocal, setDataLocal] = useLocalStorage('2081.J_dataList', {});
  // Initialize state
  const [listDepositDeduction, setlistDepositDeduction] = useState([]);
  const [modeView] = useState({
    type: 'list',
  });

  // Api get data
  const [{ data: resApiList, loading: loadingApiList }, refetchData] = useAxios(
    {
      url: 'user/m2081_j',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: listDepositDeduction,
  });

  // Values share with child component
  const value: any = {
    t,
    refetchData,
    store,
    dataLocal,
    setDataLocal,
  };

  /**
   *  Render content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListDepositDeduction />;
      default:
        return <NotFoundPage />;
    }
  };

  // Set data  from api to state
  useEffect(() => {
    if (resApiList?.data) {
      setlistDepositDeduction(resApiList?.data);
    }
  }, [resApiList]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiList} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default MemberMonthlyDuesBillingInquiry;
