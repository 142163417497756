/**
 * Update date: 14-06-2023
 * Wrap content component
 */
import { listNotViewTitle } from 'constant';
import { router } from 'constant/router';
import { ScrollView } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import useDictionary from 'hooks/useDictionary';
import { iconGuideline } from 'images';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import iconBookmarkP from '../../../images/themePro/icon_bookmark.svg';
import iconUnBookmarkP from '../../../images/themePro/icon_unbookmark.svg';
import { LayoutContext } from '../Layout';
import './style.scss';
WrapContent.defaultProps = {
  isShowBorder: true,
  isShowTitle: true,
};
const WrapContext = createContext({});

WrapContext.displayName = 'WrapContext';

export const useWrap = () => {
  const context = useContext(WrapContext);
  return context;
};

export default function WrapContent(props) {
  const layoutContext: any = useContext(LayoutContext);
  const location: any = useLocation();
  const { programId3 } = location.state || {};
  const { pathname } = location || {};
  const [dataPage, setDataPage] = useState<any>(null);
  // const [top, setTop] = useState(0);
  let top = 0;
  const scrollRef: any = useRef(null);
  const { t } = useDictionary({});

  /**
   * on bookmark
   *
   */
  const handleBookmark = () => {
    if (location.pathname.includes(router.book_mark)) {
      layoutContext.updateBookMark({
        programId: dataPage?.programId,
        bookMark: !dataPage?.bookMark,
        reload: true,
        onSuccess: () => {
          setDataPage(preState => ({
            ...preState,
            bookMark: !preState.bookMark,
          }));
        },
      });
    } else {
      layoutContext.updateBookMark({
        programId: dataPage?.programId,
        bookMark: !dataPage?.bookMark,
        reload: false,
        onSuccess: () => {
          setDataPage(preState => ({
            ...preState,
            bookMark: !preState.bookMark,
          }));
        },
      });
    }
  };

  /**
   * on scroll
   *
   * @param {*} e
   */
  const onScroll = e => {
    if (e?.reachedTop === true) {
      scrollRef.current.instance.scrollTo(top > 140 ? top : 0);
    } else {
      top = scrollRef.current.instance.scrollTop();
    }
  };

  /**
   * Custome set scroll to top
   *
   * @param {number} [position=0]
   */
  const setScrollTo = (position = 0) => {
    top = position;
    scrollRef.current.instance.scrollTo(top);
  };

  useEffect(() => {
    let programId = programId3;
    if (!programId3) {
      programId = pathname?.split('/')?.[3] || null;
    }
    const data: any = layoutContext.fullMenu.find(
      (e: any) => e.programId === programId,
    );
    setDataPage(data ? data : null);
  }, [programId3, layoutContext.fullMenu, pathname]);

  const value: any = {
    dataPage,
    setDataPage,
    setScrollTo,
  };
  return (
    <WrapContext.Provider value={value}>
      <main className="main-design">
        <ScrollView
          ref={scrollRef}
          onScroll={onScroll}
          useNative={true}
          height={'100%'}
        >
          <div>
            {dataPage && (
              <div className="header-main-design">
                <div>
                  <span className="bread-cumb-design">
                    {dataPage?.text || ''}
                  </span>
                  <span>
                    <Button
                      className="button-bookmark"
                      icon={
                        dataPage?.bookMark ? iconBookmarkP : iconUnBookmarkP
                      }
                      text={t('Bookmark')}
                      onClick={() => handleBookmark()}
                    />
                    {dataPage?.programUrl !== '*' && (
                      <Button
                        className="button-guideline"
                        icon={iconGuideline}
                        text={t('Guideline')}
                        onClick={() =>
                          window.open(dataPage?.programUrl, '_blank')
                        }
                      />
                    )}
                  </span>
                  {!listNotViewTitle.includes(dataPage?.programId) && (
                    <div className="title-page">{dataPage?.programName}</div>
                  )}
                </div>
              </div>
            )}
            <div>{props.children}</div>
          </div>
        </ScrollView>
      </main>
    </WrapContext.Provider>
  );
}
