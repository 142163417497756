import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import { toNumberAmount } from 'hooks/toNumberAmount';
import useFormat from 'hooks/useFormat';
import { cloneDeep } from 'lodash';
import React, { forwardRef, useState } from 'react';
import { dateFormatStr, dateTimeRequestFormat } from 'utils/format';
import { notification } from 'utils/notification';
import { dafaultInfo2043_4, useModuleContext } from '..';
import '../styles.scss';

/**
 * get amount
 *
 * @param {*} record
 * @return {*}
 */
const getAmount = record => {
  const { supplyUnitPrice = 0, receiveQuantity = 0 } = record || {};
  return toNumberAmount(supplyUnitPrice * receiveQuantity, true);
};
/**
 * get supply amount
 *
 * @param {*} record
 * @return {*}
 */
const getSupplyAmount = record => {
  const { taxRate } = record || {};
  return toNumberAmount(getAmount(record) / (1 + taxRate / 100));
};
/**
 * get vat
 *
 * @param {*} record
 * @return {*}
 */
const getVAT = record => {
  return toNumberAmount(getAmount(record)) - getSupplyAmount(record);
};
/**
 * get bottle
 *
 * @param {*} record
 * @return {*}
 */
const getBottle = record => {
  const {
    quantityPerPack = 0,
    receiveQuantity = 0,
    bottleUnitPrice = 0,
  } = record || {};
  return toNumberAmount(bottleUnitPrice * quantityPerPack * receiveQuantity);
};
/**
 * get container
 *
 * @param {*} record
 * @return {*}
 */
const getContainer = record => {
  const { containerUnitPrice = 0, receiveQuantity = 0 } = record || {};
  return toNumberAmount(containerUnitPrice * receiveQuantity);
};

/**
 * get shipping fee
 *
 * @param {*} record
 * @return {*}
 */
const getShippingFee = record => {
  const { shippingRate = 0 } = record || {};
  return toNumberAmount(
    ((getAmount(record) + getBottle(record) + getContainer(record)) *
      shippingRate) /
      100,
  );
};

/**
 * get add shipping amount
 *
 * @param {*} record
 * @return {*}
 */
const getAddShippingAmount = (record, shippingMethod) => {
  const { addShippingPrice = 0, receiveQuantity = 0 } = record || {};
  if (shippingMethod == '2' || shippingMethod == '3') {
    return toNumberAmount(addShippingPrice * receiveQuantity);
  }
  return 0;
};

/**
 * get gtotal
 *
 * @param {*} record
 * @return {*}
 */
const getGTotal = (record, shippingMethod) => {
  return toNumberAmount(
    getAmount(record) +
      getBottle(record) +
      getContainer(record) +
      getShippingFee(record) +
      getAddShippingAmount(record, shippingMethod),
  );
};

function TableView(props, dataGridRef) {
  const { AmountFormat, QtyFormat } = useFormat();
  const context: any = useModuleContext();
  const {
    t,
    formInfo,
    setFormInfo,
    store,
    formRef,
    setProducts,
    refetchData,
    setModeView,
  } = context;
  const shippingMethod = formInfo?.dataList?.shipmethod[0]?.code || '';
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  /**
   * on cancel
   *
   * @return {*}
   */
  const handleCancel = () => {
    if (!(store?._array || []).length) return;
    setMessage(t('Confirm to remove all the rows?'));
    setShowCancel(true);
  };

  /**
   * on delete
   *
   * @return {*}
   */
  const handleDelete = () => {
    const list = dataGridRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!list?.length) {
      return notification({
        message: t('You must be select at least one record. Please try again!'),
        type: 'error',
      });
    }
    setMessage(t('Confirm to remove the selected rows?'));
    setShowDelete(true);
  };

  /**
   * on save
   *
   */
  const onSave = async () => {
    let selectedData;
    if (formInfo?.oldOrderId) {
      selectedData =
        dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
    } else {
      selectedData = dataGridRef?.current?.instance?.getSelectedRowsData();
    }
    const params = selectedData.map((o: any) => {
      const sku = o?.productSku;

      let data = {
        product_id: o?.productId,
        product_cd: o?.productCode,
        product_nm: o?.productName,
        product_tp: o?.productType,
        option_nm: o?.option,
        option_cd: o?.optionCode,
        option_id: o?.optionId,

        container_id: o?.containerId,
        bottle_id: o?.bottleId,
        container_unit_price: o?.containerUnitPrice,
        bottle_unit_price: o?.bottleUnitPrice,
        quantity: o?.receiveQuantity,
        quantity_per_pack: o?.quantityPerPack,
        product_price: o?.supplyUnitPrice,
        add_shipping_price: o?.addShippingPrice,
        pay_unit_price: o?.supplyUnitPrice,
        shipping_rate: o?.shippingRate,
        mall_sell_unit_price: o?.mallSellUnitPrice,
        margin_rate: o?.marginRate,
        payment_amount: getGTotal(o, shippingMethod),
        variant_code: sku,
        shipping_message: o?.note,
      };
      return data;
    });

    const dataReq = {
      mallCode: formInfo?.mallCode,
      orderDate: dateTimeRequestFormat(formInfo?.ordDate),
      deliveryRequestDm: dateFormatStr(formInfo?.deliveryRequestDm),
      storeId: formInfo?.dataList?.store[0]?.storeNo,
      shippingMethod: shippingMethod,
      receiver: formInfo?.receiver,
      phoneNo: formInfo?.phoneNo,
      mobileNo: formInfo?.mobileNo,
      postCd: formInfo?.postCode,
      address: formInfo?.basicAdd,
      address2: formInfo?.detailAdd,
      orderNumber: (formInfo?.orderNumber || '').trim(),
      shippingMessage: formInfo?.shippingMessage,
      requestList: params,
    };

    const res = await refetchData({
      url: 'ecommerce/phone-orders',
      method: 'PUT',
      data: dataReq,
    });

    notification({ res });
    if (res?.data?.status == '200') {
      const selectedDataLength = selectedData.length - 1;
      selectedData
        .map(o => ({ productId: o?.productId, optionId: o?.optionId }))
        .forEach((key, idx) => {
          store.remove(key).fail(() => {});
          if (selectedDataLength === idx) {
            store.load();
            if (formInfo?.oldOrderId) {
              setFormInfo(
                cloneDeep({
                  ...dafaultInfo2043_4,
                  ordDate: new Date(),
                  deliveryRequestDm: new Date(),
                }),
              );
            }
          }
        });
    }
    setShowConfirm(false);
  };

  /**
   * on order
   *
   * @return {*}
   */
  const handleOrder = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    let selectedData;
    if (formInfo?.oldOrderId) {
      selectedData =
        dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
    } else {
      selectedData = dataGridRef?.current?.instance?.getSelectedRowsData();
    }
    if (!selectedData?.length) {
      return notification({
        message: t('You must be select at least one record. Please try again!'),
        type: 'error',
      });
    }
    setMessage(t('Confirm to save the selected row(s)?'));
    setShowConfirm(true);
  };

  const onCancel = () => {
    setProducts([]);
    setShowCancel(false);
  };

  const onNewOrder = () => {
    setProducts([]);
    setFormInfo(prev => ({
      ...prev,
      receiver: '',
      phoneNo: '',
      mobileNo: '',
      postCode: '',
      basicAdd: '',
      detailAdd: '',
      supplyUnitPriceGroup: '',
      shipment_tp: '',
      orderNumber: null,
      shippingMessage: null,
      dataList: {
        mallCode: [],
        store: [],
        shipmethod: prev?.dataList?.shipmethod || [],
      },
      shipmethod: '',
      store: '',
      mallCode: '',
    }));
  };

  /**
   * on delete
   *
   */
  const onDelete = () => {
    const list = dataGridRef?.current?.instance?.option?.()?.selectedRowKeys;
    list?.forEach(record => {
      store
        .remove(record)
        .done(() => store.load())
        .fail(() => {});
    });
    setShowDelete(false);
  };

  const columns: any = [
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      allowEditing: false,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      allowEditing: false,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      allowEditing: false,
    },
    {
      dataField: 'option',
      caption: t('Option'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      format: QtyFormat,
      allowEditing: false,
    },
    {
      dataField: 'taxRate',
      caption: t('Tax Rate'),
      allowEditing: false,
    },
    {
      dataField: 'shippingRate',
      caption: t('Shipping Rate'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'supplyUnitPrice',
      caption: t('Supply Unit Price'),
      dataType: 'number',
      format: AmountFormat,
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'availableStock',
      caption: t('Available Stock'),
      dataType: 'number',
      format: QtyFormat,
      allowEditing: false,
      cellRender: e => {
        return e.data.availableStock === 0 ? '0' : e.data.availableStock;
      },
    },
    {
      dataField: 'receiveQuantity',
      caption: t('Qty'),
      dataType: 'number',
      format: QtyFormat,
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'receiveAmount',
      caption: t('Amount'),
      dataType: 'number',
      format: AmountFormat,
      calculateCellValue: getAmount,
      allowEditing: false,
    },
    {
      dataField: 'supplyAmount',
      caption: t('Supply Amount'),
      dataType: 'number',
      format: AmountFormat,
      calculateCellValue: getSupplyAmount,
      allowEditing: false,
    },
    {
      dataField: 'vatAmount',
      caption: t('VAT'),
      dataType: 'number',
      format: AmountFormat,
      calculateCellValue: getVAT,
      allowEditing: false,
    },
    {
      dataField: 'bottleUnitPrice',
      caption: t('Bottle'),
      dataType: 'number',
      format: AmountFormat,
      calculateCellValue: getBottle,
      allowEditing: false,
    },
    {
      dataField: 'containerUnitPrice',
      caption: t('Container'),
      dataType: 'number',
      format: AmountFormat,
      calculateCellValue: getContainer,
      allowEditing: false,
    },
    {
      dataField: 'shippingFee',
      caption: t('Shipping Fee'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
      calculateCellValue: getShippingFee,
    },
    {
      dataField: 'addShippingAmount',
      caption: t('Add Shipping Amount'),
      allowEditing: false,
      format: AmountFormat,
      dataType: 'number',
      calculateCellValue: record =>
        getAddShippingAmount(record, shippingMethod),
    },
    {
      dataField: 'g-total',
      caption: t('G.Total'),
      dataType: 'number',
      format: AmountFormat,
      calculateCellValue: record => getGTotal(record, shippingMethod),
      allowEditing: false,
    },
    {
      dataField: 'note',
      caption: t('Note'),
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'dealName',
      caption: t('Deal Name'),
      cssClass: 'gridcell-editing',
    },
  ];

  return (
    <React.Fragment>
      <div>
        <DataGridFull
          ref={dataGridRef}
          dataSource={store}
          columns={columns}
          fixedLeft={2}
          options={{
            keyExpr: ['productId', 'optionId'],
            onRowUpdating: e => {
              if (e.newData.receiveQuantity > e.oldData.receiveQuantity) {
                e?.component.deselectRows([e.key]);
              }
            },
            onEditorPreparing: (e: any) => {
              if (
                e?.parentType == 'dataRow' &&
                e?.dataField == 'supplyUnitPrice'
              ) {
                if (e?.row?.data?.classify == '1') {
                  e.editorOptions.disabled = true;
                }
              }
              // if (e?.parentType == 'dataRow' && e?.type == 'selection') {
              //   const disabled =
              //     e?.row?.data?.receiveQuantity >
              //       e?.row?.data?.availableStock ||
              //     e?.row?.data?.availableStock === 0;
              //   if (e?.row?.data?.classify === '0' && disabled) {
              //     e.editorOptions.disabled = true;
              //   }
              // }
            },
            // onSelectionChanged: (e: any) => {
            //   let selectedRows = e.selectedRowsData;
            //   const disabled = o => {
            //     return (
            //       o?.receiveQuantity > o?.availableStock ||
            //       o?.availableStock === 0
            //     );
            //   };
            //   const ids = selectedRows
            //     ?.filter(o => o?.classify === '0' && disabled(o))
            //     ?.map(o => ({
            //       optionId: o?.optionId,
            //       productId: o?.productId,
            //     }));
            //   if (ids?.length) {
            //     e?.component.deselectRows(ids);
            //   }
            // },
            selection: {
              mode: 'multiple',
              selectAllMode: 'allPages',
            },
            groupPanel: {
              visible: true,
            },
            columnAutoWidth: true,
            scrolling: {
              columnRenderingMode: 'virtual',
            },
            onToolbarPreparing: (e: any) => {
              e.toolbarOptions.items.unshift(
                {
                  location: 'before',
                  template: 'totalCount',
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    text: t('New Order'),
                    onClick: onNewOrder,
                  },
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    text: t('Retrieve Order'),
                    onClick: () => {
                      setModeView({ type: 'retrieve' });
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'check',
                    text: t('Order'),
                    onClick: handleOrder,
                  },
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'trash',
                    text: t('Delete Line'),
                    onClick: handleDelete,
                  },
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'close',
                    text: t('Cancel'),
                    onClick: handleCancel,
                  },
                },
              );
            },
            summary: {
              totalItems: [
                {
                  column: 'receiveQuantity',
                  valueFormat: QtyFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'receiveAmount',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'supplyAmount',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'vatAmount',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'bottleAmount',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'containerAmount',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'bottleUnitPrice',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'containerUnitPrice',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
                {
                  column: 'shippingFee',
                  summaryType: 'sum',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  dataType: 'number',
                },
                {
                  column: 'addShippingAmount',
                  summaryType: 'sum',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  dataType: 'number',
                },
                {
                  column: 'g-total',
                  valueFormat: AmountFormat,
                  displayFormat: '{0}',
                  summaryType: 'sum',
                  alignByColumn: true,
                  showInGroupFooter: false,
                },
              ],
            },
            editing: {
              mode: 'cell',
              allowUpdating: true,
              startEditAction: 'click',
              selectTextOnEditStart: true,
              refreshmode: 'reshape',
            },
          }}
        />
        <PopupConfirm
          visible={showConfirm}
          content={message}
          onOk={onSave}
          onHiding={() => setShowConfirm(false)}
        />
        <PopupConfirm
          visible={showCancel}
          content={message}
          onOk={onCancel}
          onHiding={() => setShowCancel(false)}
        />
        <PopupConfirm
          visible={showDelete}
          content={message}
          onOk={onDelete}
          onHiding={() => setShowDelete(false)}
        />
      </div>
    </React.Fragment>
  );
}

export default forwardRef(TableView);
