/**
 * Update date: 12-12-2023
 * Screen 2013.9
 */
import { useMemo, useRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useModuleContext } from '..';

function StoreProductListTable() {
  const { QtyFormat, DoubleFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useModuleContext();
  const { t, storeProductListData, storeProductListOptionData } = context;

  const productOptionNameList = useMemo(() => {
    return storeProductListOptionData?.length
      ? storeProductListOptionData.map(item => {
          return {
            name: item,
            visible: true,
          };
        })
      : [{ name: t('Product Option Name'), visible: true }];
  }, [storeProductListOptionData, t]);

  const columns = useMemo(() => {
    const columnsBase = [
      {
        caption: t('Product'),
        alignment: 'center',
        fixed: true,
        fixedPosition: 'left',
        visibleIndex: 0,
        items: [
          {
            dataField: 'product_id',
            caption: t('ID'),
            alignment: 'left',
            showWhenGrouped: true,
            fixed: true,
          },
          {
            dataField: 'product_tp_nm',
            caption: t('Product Type'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'product_option_img',
            caption: t('Product Image'),
            alignment: 'center',
            cellRender: record => {
              const { product_option_img } = record?.data || {};
              return product_option_img ? (
                <div className="product-image-grid-custom">
                  <img src={product_option_img} width={26} height={26} alt="" />
                </div>
              ) : null;
            },
            visible: false,
          },
          {
            dataField: 'product_cd',
            caption: t('Product Code'),
            alignment: 'center',
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'product_nm',
            caption: t('Product Name'),
            showWhenGrouped: true,
          },
          {
            dataField: 'tax_nm',
            caption: t('Tax Name'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'maker',
            caption: t('Maker'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'marketable_size',
            caption: t('Marketable Size'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'tradeTypeName',
            caption: t('Trade Type'),
            showWhenGrouped: true,
            allowFiltering: true,
            visible: false,
          },
          {
            dataField: 'taxTypeName',
            caption: t('Tax Type'),
            showWhenGrouped: true,
            allowFiltering: true,
            visible: false,
          },
          {
            dataField: 'tax_rate',
            caption: t('Tax Rate'),
            dataType: 'number',
            format: DoubleFormat,
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'vendor_nm_default',
            caption: t('Default Vendor'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'order_target_tp',
            caption: t('Order Target Type'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'bottle_nm',
            caption: t('Bottle'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'safety_stock',
            caption: t('Safety Stock'),
            dataType: 'number',
            format: QtyFormat,
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'min_order',
            caption: t('Min Order'),
            dataType: 'number',
            format: QtyFormat,
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'max_order',
            caption: t('Maximum Order'),
            dataType: 'number',
            format: QtyFormat,
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'origin',
            caption: t('Origin'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'product_order_tf',
            caption: t('Order'),
            editorType: 'dxSwitch',
            dataType: 'boolean',
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'product_return_tf',
            caption: t('Return'),
            editorType: 'dxSwitch',
            dataType: 'boolean',
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'product_online_tf',
            caption: t('Online'),
            editorType: 'dxSwitch',
            dataType: 'boolean',
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'largecategory',
            caption: t('Large Category'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'mediumcategory',
            caption: t('Medium Category'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'smallcategory',
            caption: t('Small Category'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'subcategory',
            caption: t('Sub Category'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'stockAllocationTypeName',
            caption: t('Stock Allocation'),
            showWhenGrouped: true,
            visible: false,
          },
        ],
      },
      ...productOptionNameList.map(item => ({
        dataField: item.name,
        caption: item.name,
        visible: item.visible,
        visibleIndex: 1,
        alignment: 'center',
        isBand: true,
        columns: [
          {
            dataField: `${item.name}_barcode`,
            caption: t('Barcode'),
            alignment: 'center',
            showWhenGrouped: true,
          },
          {
            dataField: `${item.name}_quantity_per_pack`,
            caption: t('QPP'),
            alignment: 'center',
            format: QtyFormat,
            showWhenGrouped: true,
          },
          {
            dataField: `${item.name}_product_sku`,
            caption: t('SKU'),
            alignment: 'center',
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: `${item.name}_forward_unit_price`,
            caption: t('Forward Unit Price'),
            alignment: 'center',
            format: DoubleFormat,
            showWhenGrouped: true,
          },
          {
            dataField: `${item.name}_empty_bottle_unit_price`,
            caption: t('Empty Bottle Unit Price'),
            alignment: 'center',
            format: DoubleFormat,
            showWhenGrouped: true,
          },
          {
            dataField: `${item.name}_container_unit_price`,
            caption: t('Container Unit Price'),
            alignment: 'center',
            format: DoubleFormat,
            showWhenGrouped: true,
          },
          {
            dataField: `${item.name}_total_amount`,
            caption: t('Total Amount'),
            alignment: 'center',
            format: DoubleFormat,
            showWhenGrouped: true,
          },
        ],
      })),
      {
        dataField: 'note',
        caption: t('Note'),
        alignment: 'center',
        fixed: true,
        fixedPosition: 'right',
        visibleIndex: productOptionNameList.length + 1,
        showWhenGrouped: true,
      },
    ];
    return columnsBase;
  }, [DoubleFormat, QtyFormat, productOptionNameList, t]);

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const exportFileName =
    'SMDC_2013.9 StoreProductListInquiry_' + moment().format('YYYYMMDD');

  const handlePageChanged = visibleRows => {
    if (!visibleRows.length) return;
    if (dataGridRef.current) {
      const dataGridInstance = dataGridRef.current.instance;
      const productOptionNm = new Set();

      visibleRows.forEach((row: any) => {
        Object.keys(row.data).forEach(key => {
          if (
            key.includes('_barcode') ||
            key.includes('_quantity_per_pack') ||
            key.includes('_forward_unit_price')
          ) {
            productOptionNm.add(key.split('_')[0]);
          }
        });
      });
      if (productOptionNm.size > 0) {
        dataGridInstance.option('columns').forEach(column => {
          if (column.isBand && productOptionNm.has(column.dataField)) {
            dataGridInstance.columnOption(column.dataField, 'visible', true);
          } else if (column.isBand) {
            dataGridInstance.columnOption(column.dataField, 'visible', false);
          }
        });
      }
    }
  };

  return (
    <DataGridFull
      columns={columns}
      ref={dataGridRef}
      dataSource={storeProductListData}
      storageKeyInquiry="2011_C_inquiry"
      isStorageFilter={false}
      options={{
        groupPanel: {
          visible: true,
        },
        columnAutoWidth: true,
        onToolbarPreparing: (e: any) => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'totalCount',
            },
            {
              location: 'before',
              widget: 'dxButton',
              options: {
                icon: 'xlsxfile',
                text: t('Export to Excel'),
                disabled: !storeProductListData?.length,
                onClick: () => exportExcel(),
              },
            },
          );
        },
        // onOptionChanged: (e: any) => {
        //   if (e.name === 'paging') {
        //     setTimeout(() => {
        //       handlePageChanged(e.component.getVisibleRows());
        //     }, 500);
        //   }
        // },
        // onContentReady: e => {
        //   setTimeout(() => {
        //     handlePageChanged(e.component.getVisibleRows());
        //   }, 500);
        // },
        export: {
          fileName: exportFileName,
        },
        summary: {
          totalItems: [{}],
          groupItems: [
            {
              summaryType: 'count',
              displayFormat: '{0}',
            },
          ],
        },
      }}
    />
  );
}

export default StoreProductListTable;
