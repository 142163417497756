/**
 * Update date: 14-06-2023
 * Popup add product component
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Form, Popup, ScrollView, TextBox } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';
import PopupInputProductQuantityScan from './PopupInputProductQuantityScan';
import { useProductType } from 'hooks/useProductType';
import PopupSelectCommonCode from './PopupSelectCommonCode';
import { cloneDeep, uniqBy } from 'lodash';
import { useWrap } from '../WrapContent/WrapContent';
import useLocalStorage from 'hooks/useLocalStorage';
interface IPopupAddProduct {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit?: void | any;
  url?: string;
  mode?: 'single' | 'multiple';
  isPurchase?: boolean;
  dataBody?: {
    optionCtOrderTf?: boolean;
    salesStartDate?: string | null;
    salesEndDate?: string | null;
    vendorId?: number | null;
    inDate?: string | null;
    keySearch?: string | null;
    orderId?: string | null;
    storeId?: any;
    shippingMethod?: any;
    orderDate?: any;
  };
  isAddQty?: boolean;
  columns?: any;
  keyWord?: string;
  isSearchTradeType?: boolean;
}
interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const array_code = ['2043.4'];

function PopupAddProduct({
  visible,
  onHiding,
  text,
  onSubmit,
  url,
  mode = 'single',
  dataBody = {},
  isPurchase = false,
  isAddQty = false,
  columns,
  keyWord,
  isSearchTradeType = true,
}: IPopupAddProduct) {
  const { themePro }: any = useApp();
  const { dataPage }: any = useWrap();
  const dataRef: any = useRef(null);
  const { DateTimeFormat } = useFormatTime();
  const { t } = useDictionary({});
  const formRef: any = useRef(null);
  const [data, setData] = useState();
  const [showModalInputQty, setShowModalInputQty] = useState({
    isShow: false,
    data: null,
  });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [showPopupByType, setShowPopupByType] = useState('');
  const [formData, setFormData] = useState<any>({
    productName: '',
    tradeType: '',
    dataList: {
      productType: [],
    },
    productType: '',
  });
  const [dataLocal, setDataLocal] = useLocalStorage(
    'search_product_dataList',
    {},
  );
  const [isSearch, setIsSearch] = useState(true);

  const [{ loading }, fetchProductsByName] = useAxios(
    {
      url: url ? url : '/warehouse/receive/product-option-true',
      method: 'GET',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const [{ loading: loadingPurchaseUnitPrice }, purchaseUnitPrice] = useAxios(
    {
      url: '/warehouse/receive/fun-vendor-purchase-unit-price',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on search
   *
   * @param {string} [name]
   * @param {*} [productType]
   */
  const onSearch = async (
    name?: string,
    productType?: any,
    tradeType?: string,
  ) => {
    const res = await fetchProductsByName({
      params: {
        ...dataBody,
        productName: formData.productName || name,
        keySearch: formData.productName || name,
        tradeType: isSearchTradeType ? tradeType || formData?.tradeType : '',
        productType:
          productType || formData?.dataList?.productType?.map(o => o?.code),
      },
      paramsSerializer: function paramsSerializer(params) {
        return Object.entries(
          Object.assign(
            {},
            Object.fromEntries(
              Object.entries(params).filter(([_, v]) => v != null),
            ),
          ),
        )
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      },
    });
    if (res?.data?.status == 200) {
      const dataMapping = (res?.data?.data || []).map(o => {
        o.makerName = o?.makerName || o?.maker;
        return o;
      });
      setData(dataMapping);
      if (mode === 'single') {
        setTimeout(() => {
          const gridInstance = dataRef?.current?.instance;
          gridInstance?.clearSelection?.();
          gridInstance?.selectRows?.(gridInstance?.getKeyByRowIndex?.(0));
          gridInstance?.focus?.();
        }, 800);
      }
    }
  };

  /**
   * on mapping data
   *
   * @param {Array<any>} selectedRow
   */
  const mappingDataOk = (selectedRow: Array<any>) => {
    // url is dynamic, therefore response maybe not includes containerUnitPrice property;
    // add containerUnitPrice property, if it's not exist. example: 2046.6
    const selectedMapping = selectedRow.map(o => {
      if (
        o?.containerUnitPrice === null ||
        o?.containerUnitPrice === undefined
      ) {
        o.containerUnitPrice = o?.optionContainerUnitPrice || 0;
      }
      if (isPurchase) {
        o.taxRate = o?.purchaseTaxRate || 0;
      }
      return o;
    });
    onSubmit(selectedMapping);
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onOk = async () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys || [];
    if (mode == 'single' && isAddQty && selectedRowKeys.length > 0) {
      const dataSelect = { ...selectedRowKeys[0] };
      const res = await purchaseUnitPrice({
        data: {
          inDate: dataBody?.inDate,
          inVendorId: dataSelect?.vendorId,
          inProductId: dataSelect?.productId,
          inOptionId: dataSelect?.optionId,
        },
      });
      dataSelect.purchaseUnitPrice = res?.data?.data || 0;
      setShowModalInputQty({
        isShow: true,
        data: dataSelect,
      });
      return;
    }
    mappingDataOk(selectedRowKeys);
  };

  // useEffect(() => {
  //   setFormData(preState => ({
  //     ...preState,
  //     productName: text || '',
  //   }));
  //   onSearch(text || '');
  // }, []);

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'productType':
        return (
          <PopupSelectCommonCode
            visible={showPopupByType === popupType}
            text={formData?.[popupType]}
            codeType={'PT'}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    if (
      storedType === 'productType' &&
      array_code?.includes(dataPage?.programId)
    ) {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
    setFormData(newData);
    setShowPopupByType('');
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingProductTpPermission && isSearch) {
      setIsSearch(false);
      if (array_code?.includes(dataPage?.programId)) {
        if (productTpPermission?.length > 0) {
          setFormData(preState => ({
            ...preState,
            productName: text || '',
            dataList: {
              productType: productTpPermission,
            },
            isReadOnlyProductTp: productTpPermission?.length > 0,
          }));
          onSearch(
            text || '',
            productTpPermission?.map(o => o?.code),
            dataLocal?.tradeType || '',
          );
        } else {
          if (!dataLocal) return;
          setFormData(prev => ({
            ...prev,
            productName: text || '',
            tradeType: dataLocal?.tradeType || '',
            dataList: {
              productType: dataLocal?.productType || [],
            },
          }));
          onSearch(
            text || '',
            (dataLocal?.productType || [])?.map(o => o?.code),
            dataLocal?.tradeType || '',
          );
        }
      } else {
        setFormData(preState => ({
          ...preState,
          productName: text || '',
          dataList: {
            productType: productTpPermission,
          },
          isReadOnlyProductTp: productTpPermission?.length > 0,
        }));
        onSearch(
          text || '',
          productTpPermission?.map(o => o?.code),
        );
      }
    }
  }, [loadingProductTpPermission, dataLocal, isSearch]);

  const loadingAll = loading || loadingPurchaseUnitPrice;

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll || loadingProductTpPermission} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Search for Product')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <Form
            formData={formData}
            showColonAfterLabel={false}
            onFieldDataChanged={e => {
              if (e?.dataField === 'tradeType') {
                setDataLocal(preState => ({
                  ...preState,
                  tradeType: e?.value,
                }));
              }
            }}
            className="body-padding-white mb-10"
            ref={formRef}
            items={[
              {
                label: { text: t('Keyword') },
                dataField: 'productName',
                editorType: 'dxTextBox',
                editorOptions: {
                  onEnterKey: () => {
                    onSearch();
                  },
                },
              },
              generateDxTextBox('Product Type', 'productType', 'name', {
                colSpan: 1,
                readOnly: formData?.isReadOnlyProductTp,
              }),
              isSearchTradeType
                ? {
                    itemType: 'group',
                    colCount: 2,
                    items: [
                      {
                        label: { text: t('Trade Type') },
                        dataField: 'tradeType',
                        editorType: 'dxSelectBox',
                        editorOptions: {
                          displayExpr: 'name',
                          valueExpr: 'value',
                          defaultValue: '',
                          items: [
                            {
                              name: t('All'),
                              value: '',
                            },
                            {
                              name: t('Traded'),
                              value: '0',
                            },
                            {
                              name: t('Non Traded'),
                              value: '1',
                            },
                          ],
                        },
                      },
                      {
                        itemType: 'button',
                        horizontalAlignment: 'left',
                        verticalAlignment: 'bottom',
                        buttonOptions: {
                          text: t('Search'),
                          icon: 'search',
                          onClick: () => onSearch(),
                        },
                      },
                    ],
                  }
                : {
                    itemType: 'button',
                    horizontalAlignment: 'left',
                    verticalAlignment: 'bottom',
                    buttonOptions: {
                      text: t('Search'),
                      icon: 'search',
                      onClick: () => onSearch(),
                    },
                  },
            ]}
            labelLocation="top"
            colCount={3}
          ></Form>
          <DataGridFull
            storageKeyInquiry={array_code?.includes(dataPage?.programId)}
            ref={dataRef}
            dataSource={data}
            fixedLeft={1}
            columns={
              columns || [
                {
                  dataField: 'productId',
                  caption: t('Product Id'),
                },
                {
                  dataField: 'productTypeName',
                  caption: t('Product Type'),
                  allowSearch: false,
                },
                {
                  dataField: 'tradeTp',
                  caption: t('Trade Type'),
                  cellRender: e => {
                    switch (e?.value) {
                      case '0':
                        return <span>{t('Traded')}</span>;
                      case '1':
                        return <span>{t('Non Traded')}</span>;
                      case '2':
                        return <span>{t('Discontinued')}</span>;
                      default:
                        return '';
                    }
                  },
                  visible: isSearchTradeType,
                },
                {
                  dataField: 'productCode',
                  caption: t('Product Code'),
                  allowSearch: false,
                },
                {
                  dataField: 'latestPurchaseUnitPrice',
                  caption: t('Last Purchase Unit Price'),
                },
                {
                  dataField: 'maker',
                  caption: t('Maker'),
                },
                {
                  dataField: 'vendorId',
                  caption: t('VendorId'),
                  visible: false,
                },
                {
                  dataField: 'vendorName',
                  caption: t('Vendor'),
                },
                {
                  dataField: 'productName',
                  caption: t('Product'),
                },
                {
                  dataField: 'marketableSize',
                  caption: t('Marketablesize'),
                },
                {
                  dataField: 'optionCode',
                  caption: t('Option Code'),
                },
                {
                  dataField: 'option',
                  caption: t('Option'),
                },
                {
                  dataField: 'quantityPerPack',
                  caption: t('Quantity Per Pack'),
                },
                {
                  dataField: 'regisDateTime',
                  caption: t('Regist Date Time'),
                  dataType: 'date',
                  format: DateTimeFormat,
                },
              ]
            }
            options={{
              hiddenDetailProduct: true,
              onRowDblClick: onOk,
              onKeyDown: e => {
                const keyCode = e?.event?.keyCode;
                if (keyCode === 38 || keyCode === 40) {
                  if (mode !== 'single') return;
                  const selKey = e?.component?.getSelectedRowKeys();
                  if (selKey?.length) {
                    const currentKey = selKey[0];
                    let index =
                      e?.component?.getRowIndexByKey?.(currentKey) || 0;
                    if (keyCode === 38) {
                      index--;
                      if (index >= 0) {
                        e?.component?.selectRowsByIndexes([index]);
                      }
                    } else if (
                      keyCode === 40 &&
                      index + 1 !== e?.component?.getVisibleRows?.()?.length
                    ) {
                      index++;
                      e?.component?.selectRowsByIndexes([index]);
                    }
                    e?.component?.navigateToRow(
                      e?.component.getKeyByRowIndex(index),
                    );
                  }
                  e?.event?.preventDefault();
                  e?.event?.stopPropagation();
                } else if (keyCode === 13) {
                  onOk();
                  e?.event?.preventDefault();
                  e?.event?.stopPropagation();
                }
              },
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode: mode,
              },
              height: themePro
                ? 'calc(100vh - 220px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
          {getPopupByType(showPopupByType)}
        </div>
        {showModalInputQty.isShow && showModalInputQty?.data && (
          <PopupInputProductQuantityScan
            visible={showModalInputQty.isShow}
            data={showModalInputQty.data}
            onHiding={() => setShowModalInputQty({ isShow: false, data: null })}
            onSubmit={data => {
              mappingDataOk([data]);
              setShowModalInputQty({ isShow: false, data: null });
            }}
          />
        )}
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Search for the Product')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={themePro ? '80vw' : isMobile ? '96%' : '60vw'}
    />
  );
}

export default PopupAddProduct;
