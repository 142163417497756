/**
 * Update date: 18-05-2023
 * Screen 2013.5
 */
import { DataGridFull } from 'app/components/DataGrid';
import { SPECIAL_DEAL_PRODUCT_TYPES } from 'constant';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { useRef } from 'react';
import { useSpecialDeal } from '..';

function SpecialDealTable(props) {
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, DoubleFormat, QtyFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useSpecialDeal();
  const { dealData, t } = context;

  const columns: any = [
    {
      caption: t('Special Deal'),
      alignment: 'center',
      items: [
        {
          dataField: 'product_deal_nm',
          caption: t('Special Deal'),
          showWhenGrouped: true,
        },
        {
          dataField: 'purchase_str_dt',
          caption: t('Purchase Start Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'purchase_end_dt',
          caption: t('Purchase End Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'sale_str_dt',
          caption: t('Sale Start Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'sale_end_dt',
          caption: t('Sale End Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'use_tf',
          caption: t('Use'),
          alignment: 'center',
          editorType: 'dxSwitch',
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'product_cd',
          caption: t('Product code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'product_nm',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'marketableSize',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        // {
        //   dataField: 'option_cd',
        //   caption: t('Option Code'),
        //   showWhenGrouped: true,
        //   visible: false,
        // },
        {
          dataField: 'option_nm',
          caption: t('Option'),
          showWhenGrouped: true,
          alignment: 'center',
        },
        {
          dataField: 'quantity_per_pack',
          caption: t('Quantity Per Pack'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      columns: [
        {
          dataField: 'purchase_unit_price',
          caption: t('Purchase Unit Price'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'dealPurchaseUnitPrice',
          caption: t('Deal Purchase Unit Price'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'purchaseDiscountRate',
          caption: t('Purchase Discount Rate'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'supply_unit_price',
          caption: t('Supply Unit Price'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'dealSupplyUnitPrice',
          caption: t('Deal Supply Unit Price'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyDiscountRate',
          caption: t('Supply Discount Rate'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'profitRate',
          caption: t('Profit Rate'),
          dataType: 'number',
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'product_deal_qt',
          caption: t('Maximum Purchase Quantity At One Time'),
          dataType: 'number',
          showWhenGrouped: true,
          format: QtyFormat,
        },
        {
          dataField: 'product_deal_tp',
          caption: t('Apply Type'),
          showWhenGrouped: true,
          customizeText: e =>
            t(
              `${
                SPECIAL_DEAL_PRODUCT_TYPES.find(item => e.value == item.value)
                  ?.label || ''
              }`,
            ),
        },
      ],
    },
    {
      dataField: 'product_deal_nt',
      caption: t('Deal Note'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'makerName',
      caption: t('Maker'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'originName',
      caption: t('Origin'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'defaultVendorName',
      caption: t('Default Vendor'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'largeCategoryName',
      caption: t('Large category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'mediumCategoryName',
      caption: t('Medium category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'smallCategoryName',
      caption: t('Small category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'subCategoryName',
      caption: t('Sub category'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerDateTime',
      caption: t('Register Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerUserName',
      caption: t('Register User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'registerProgramId',
      caption: t('Register Program ID'),
      alignment: 'left',
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyDateTime',
      caption: t('Modify Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyUserName',
      caption: t('Modify User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modifyProgramId',
      caption: t('Modify Program ID'),
      showWhenGrouped: true,
      visible: false,
    },
  ];
  /**
   * export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName =
    'SMDC_2013.5 ProductSpecialDealsInquiry_' + moment().format('YYYYMMDD');

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={dealData}
        storageKeyInquiry
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !dealData.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default SpecialDealTable;
