/**
 * Update date: 16-05-2023
 * Screen 2011.2
 */
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { useProductBarcode } from '..';
import printJS from 'print-js';
import { notification } from 'utils/notification';
import { useApp } from 'app';
import { objectTraps } from 'immer/dist/internal';

function TableList(props) {
  const { themePro }: any = useApp();
  const { QtyFormat, AmountFormat } = useFormat();
  const dataGridRef = useRef<any>(null);
  const context: any = useProductBarcode();
  const { store, refetchBarcode } = context;
  const [barcodeType, setBarcodeType] = useState(1);
  const refInstance = dataGridRef?.current?.instance;
  const barcode = 'barcode' + barcodeType;

  const { t }: any = useDictionary({
    programId: '2011.2',
  });

  const columns: any = [
    {
      dataField: 'print_count',
      caption: t('Print Count'),
      alignment: themePro ? 'left' : 'center',
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'productId',
      caption: t('Product Id'),
      alignment: themePro ? 'left' : 'center',
      cssClass: 'gridcell-editing',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
      alignment: themePro ? 'left' : 'center',
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'productNm',
      caption: t('Product Name'),
      alignment: 'left',
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'optionId',
      caption: t('Option Id'),
      visible: false,
      alignment: themePro ? 'left' : 'center',
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
      alignment: themePro ? 'left' : 'center',
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'supplyUnitPrice',
      caption: t('Supply Unit Price'),
      dataType: 'number',
      format: AmountFormat,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity per pack'),
      dataType: 'number',
      format: QtyFormat,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'codeType1',
      caption: t('Barcode1 Type'),
      cssClass: 'gridcell-editing',
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'barcode1',
      caption: t('Barcode1'),
      alignment: themePro ? 'left' : 'center',
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'codeType2',
      caption: t('Barcode2 Type'),
      cssClass: 'gridcell-editing',
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'barcode2',
      caption: t('Barcode2'),
      alignment: themePro ? 'left' : 'center',
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'codeType3',
      caption: t('Barcode3 Type'),
      cssClass: 'gridcell-editing',
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'barcode3',
      caption: t('Barcode3'),
      alignment: themePro ? 'left' : 'center',
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'maker',
      caption: t('Maker'),
      visible: false,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'origin',
      caption: t('Origin'),
      visible: false,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'vendorNmDefault',
      caption: t('Default Vendor'),
      visible: false,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'largeCategory',
      caption: t('Large Category'),
      visible: false,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'mediumCategory',
      caption: t('Medium Category'),
      visible: false,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'smallCategory',
      caption: t('Small Category'),
      visible: false,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
    {
      dataField: 'subCategory',
      caption: t('Sub Category'),
      visible: false,
      cssClass: 'gridcell-editing',
      allowEditing: false,
    },
  ];

  /**
   * export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * print barcode
   *
   * @return {*}
   */
  const printBarcode = async () => {
    // const barcode = 'barcode' + barcodeType;
    const selectedRows = dataGridRef?.current?.instance
      ?.getSelectedRowsData()
      ?.filter(
        (o: any) =>
          o?.[barcode] !== null &&
          o?.[barcode] !== undefined &&
          o?.[barcode] !== '',
      );
    if (selectedRows?.length === 0) {
      notification({
        message: 'No rows selected.',
        type: 'error',
      });
      return;
    }
    const barcodePrintRequests = selectedRows?.map((o: any) => [
      {
        prdIds: o?.productId,
        optionIds: o?.optionId,
        printCount: o?.print_count,
        // printCount: o?.print_count ? o?.print_count : 1,
      },
    ]);
    const newAry = barcodePrintRequests?.map((o: any) => o?.[0]);
    const data = {
      barcodeType: barcodeType,
      barcodePrintRequests: newAry,
    };
    try {
      const res = await refetchBarcode({
        data,
      });
      const arrayData: any = new Uint8Array(res.data);
      const blob = new Blob([arrayData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      printJS(url);
      // deselectClear();
    } catch (e) {
      notification({ type: 'error', message: t('Print Barcode Error.') });
    }
  };

  const exportFileName =
    'SMDC_2011.2 Product Barcode Print_' + moment().format('YYYYMMDD');

  const onValueChange = e => {
    setBarcodeType(e?.value);
  };

  useEffect(() => {
    setBarcodeType(barcodeType);
  }, [barcodeType, setBarcodeType]);

  const deselectClear = () => {
    refInstance?.deselectAll();
    store?._array?.map((o: any) => {
      store
        ?.update(
          { productId: o?.productId, optionId: o?.optionId },
          { print_count: '' },
        )
        .done(() => {})
        .fail(() => {});
    });
  };

  /**
   * on row updated
   *
   * @param {*} e
   */
  const onRowUpdated = e => {
    const selectRows = [...refInstance?.getSelectedRowsData(), ...[e?.data]];
    const uniqueSelectRows = Array.from(new Set(selectRows));
    const filterSelectRows = uniqueSelectRows?.filter(
      (o: any) =>
        o?.print_count !== '0' &&
        o?.print_count !== 0 &&
        o?.print_count !== '' &&
        o?.print_count !== null &&
        o?.print_count !== undefined &&
        o?.[barcode] !== null &&
        o?.[barcode] !== undefined,
    );
    e?.component?.selectRows(filterSelectRows);
  };

  const onselectionchange = e => {
    const dataRows = refInstance?.getDataSource()?._items;
    const selectedRows = refInstance?.getSelectedRowsData();
    let object;

    // const barcode = 'barcode' + barcodeType;
    const obj = selectedRows?.filter((o: any) => {
      if (
        o?.productId === e?.currentSelectedRowKeys?.[0]?.productId &&
        o?.optionId === e?.currentSelectedRowKeys?.[0]?.optionId &&
        (o?.[barcode] === null ||
          o?.[barcode] === undefined ||
          o?.[barcode] === '')
      ) {
        return o;
      }
    });

    if (obj?.length > 0) {
      notification({
        message: t('No available barcode.'),
        type: 'error',
      });
      return;
    }

    e?.currentSelectedRowKeys?.map((o: any) => {
      const obj = selectedRows?.filter((oo: any) => {
        if (o?.productId === oo?.productId && o?.optionId === oo?.optionId) {
          return oo;
        }
      });

      if (
        obj?.[0]?.print_count === null ||
        obj?.[0]?.print_count === undefined ||
        obj?.[0]?.print_count === '0' ||
        obj?.[0]?.print_count === 0 ||
        obj?.[0]?.print_count === ''
      ) {
        object = { ...obj?.[0], print_count: 1 };
      } else {
        object = { ...obj?.[0] };
      }
      store
        .update(
          { productId: o?.productId, optionId: o?.optionId },
          { print_count: object?.print_count },
        )
        .done(() => {})
        .fail(() => {});
    });

    e?.currentDeselectedRowKeys?.map((o: any) => {
      const obj = dataRows?.filter((oo: any) => {
        if (o?.productId === oo?.productId && o?.optionId === oo?.optionId) {
          return oo;
        }
      });
      const object = { ...obj?.[0], print_count: null };
      store
        .update(
          { productId: object?.productId, optionId: object?.optionId },
          { print_count: object?.print_count },
        )
        .done(() => {})
        .fail(() => {});
    });

    refInstance?.refresh();
  };
  /**
   * render content
   */

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={store}
        options={{
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onRowUpdated: onRowUpdated,
          onSelectionChanged: onselectionchange,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: '',
                  onClick: () => exportExcel(),
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  displayExpr: 'text',
                  valueExpr: 'value',
                  dataSource: [
                    { text: t('Barcode1'), value: 1 },
                    { text: t('Barcode2'), value: 2 },
                    { text: t('Barcode3'), value: 3 },
                  ],
                  value: barcodeType ? barcodeType : 1,
                  onValueChanged: data => onValueChange(data),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'print',
                  text: t('Print Barcode'),
                  disabled: '',
                  onClick: () => printBarcode(),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
          export: {
            fileName: exportFileName,
          },
          editing: {
            mode: 'cell',
            allowUpdating: true,
            startEditAction: 'click',
            selectTextOnEditStart: true,
            refreshmode: 'reshape',
          },
        }}
      />
    </>
  );
}

export default TableList;
