/**
 * Update date: 2023-08-02
 * Screen 2054.B
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2054_B_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2054_B = () => {
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    dataList: {
      dueDiligence: [],
      productMakers: [],
      vendor: [],
    },
    dueDiligence: '',
    productMakers: '',
    vendor: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2054_B;
