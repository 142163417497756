/**
 * Update date: 12-05-2023
 * Screen 1042.6
 */

import React, { useRef, useContext, useEffect } from 'react';
import 'jspdf-autotable';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
import { DataGridFull } from 'app/components/DataGrid';
import useFormatTime from '../../../hooks/useFormatTime';
import useFormat from '../../../hooks/useFormat';
import moment from 'moment';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
import { iconExcel } from 'images';

// create context
export const CodeContext = React.createContext({
  refetchData: () => {},
});
CodeContext.displayName = 'CodeContext';
export const useCodeContext = () => {
  const context = useContext(CodeContext);
  return context;
};

export default function CommonCodeInquiry() {
  const { themePro }: any = useApp();
  const { DateTimeFormat } = useFormatTime();
  const { IntegerFormat } = useFormat();
  const dataGridRef: any = useRef(null);

  const { t }: any = useDictionary({
    programId: '1042.6',
  });

  const [{ data, loading: loadingCommonCode }, refetchData] = useAxios(
    {
      url: '/core/common-code-inquiry',
      method: 'POST',
    },
    {
      manual: true,
    },
  );

  const columns: any = [
    {
      dataField: 'task_nm',
      caption: t('Task'),
      dataType: 'string',
      showWhenGrouped: true,
    },
    {
      dataField: 'code_type',
      caption: t('Code Type'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'code_type_nm',
      caption: t('Code Type Name'),
      showWhenGrouped: true,
    },
    {
      dataField: 'code_cd',
      caption: t('Code'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'code_nm',
      caption: t('Code Name'),
      showWhenGrouped: true,
    },
    {
      dataField: 'sort',
      caption: t('Sort'),
      dataType: 'number',
      format: IntegerFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'sys_tf',
      editorType: 'dxSwitch',
      caption: t('System'),
      dataType: 'boolean',
      showWhenGrouped: true,
    },
    {
      dataField: 'use_tf',
      editorType: 'dxSwitch',
      caption: t('Use'),
      dataType: 'boolean',
      showWhenGrouped: true,
      trueText: t('Use'),
      falseText: t('Not Use'),
    },
    {
      dataField: 'code_nt',
      caption: t('Note'),
      showWhenGrouped: true,
    },
    {
      dataField: 'modify_dm',
      caption: t('Modify Datetime'),
      dataType: 'datetime',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modify_program_cd',
      caption: t('Modify Program'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'modify_user_id',
      caption: t('Modify User'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'regist_dm',
      caption: t('Register Datetime'),
      dataType: 'datetime',
      format: DateTimeFormat,
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'register_program_cd',
      caption: t('Register Program'),
      showWhenGrouped: true,
      visible: false,
    },
    {
      dataField: 'register_user_id',
      caption: t('Register User'),
      showWhenGrouped: true,
      visible: false,
    },
  ];

  const value: any = {
    refetchData,
  };

  /**
   * export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const exportFileName =
    'SMDC_1042.6 Common Code Inquiry' + moment().format('YYYYMMDD');

  const loadingAll = loadingCommonCode;
  /**
   * render
   */
  return (
    <CodeContext.Provider value={value}>
      <>
        <LoadPanel visible={loadingAll} />
        <FormSearch />
        <DataGridFull
          columns={columns}
          ref={dataGridRef}
          dataSource={data?.data}
          options={{
            groupPanel: {
              visible: true,
            },
            columnAutoWidth: true,
            scrolling: {
              columnRenderingMode: 'virtual',
            },
            onToolbarPreparing: (e: any) => {
              e.toolbarOptions.items.unshift(
                {
                  location: 'before',
                  template: 'totalCount',
                },
                {
                  location: 'before',
                  widget: 'dxButton',
                  options: {
                    icon: themePro ? iconExcel : 'xlsxfile',
                    text: t('Export Excel'),
                    disabled: !data?.data,
                    onClick: () => exportExcel(),
                  },
                },
              );
            },
            export: {
              fileName: exportFileName,
            },
            summary: {
              totalItems: [{}],
              groupItems: [
                {
                  summaryType: 'count',
                  displayFormat: '{0}',
                },
              ],
            },
          }}
        />
      </>
    </CodeContext.Provider>
  );
}
