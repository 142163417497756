/**
 * Update date: 06-06-2023
 * Screen 2064.8
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import EmptyBottleTable from './TableView';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
/**
 * create context
 */
const EmptyBottle = createContext({
  refetchData: () => {},
  bottleData: [],
});
EmptyBottle.displayName = 'EmptyBottle';
export const useEmptyBottle = () => {
  const context = useContext(EmptyBottle);
  return context;
};

function EmptyBottleInquiry() {
  const { t }: any = useDictionary({ programId: '2064.8' });
  const [bottleData, setBottleData] = useState([]);
  const [{ data, loading: loadingInquiry }, refetchData] = useAxios(
    {
      url: '/warehouse/return-container/generality',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );

  const value: any = {
    refetchData,
    bottleData,
    loadingInquiry,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      const dataMapping = (data?.data || []).map(o => {
        o.statusNm = o?.status === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setBottleData(dataMapping);
    }
  }, [data]);
  return (
    <>
      <EmptyBottle.Provider value={value}>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel visible={loadingInquiry} />
          <FormSearch />
          <EmptyBottleTable />
        </React.Suspense>
      </EmptyBottle.Provider>
    </>
  );
}

export default EmptyBottleInquiry;
