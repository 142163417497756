/**
 * Update date: 29-05-2023
 * Screen 2046.4
 */
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import { WAREHOUSE_FORWARD_URL } from 'constant/apiUrl';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

const CreateForward = React.lazy(() => import('./CreateForward'));
const ListForward = React.lazy(() => import('./ListForward'));
/**
 * create context
 */
const ForwardContext = createContext({
  vendor: {},
  commonCodeData: {},
  params: {},
  dataForward: [] || null,
  setParams: () => {},
});

ForwardContext.displayName = 'ForwardContext';

export const useForward = () => {
  const context = useContext(ForwardContext);
  return context;
};

const Forward = () => {
  const [dataLocal, setDataLocal] = useLocalStorage('2046.4_mst_dataList', {});
  const [dataCreate, setDataCreate] = useLocalStorage(
    '2046.4_mst_dataFormCreate',
    {},
  );
  const { t }: any = useDictionary({ programId: '2046.4' });
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    store: '',
    forwardState: '',
    shipmentType: '',
    dateFrom: new Date(),
    dateTo: new Date(),
    formStore: {
      store: [],
      forwardState: [],
      shipmentType: [],
      zone: [],
    },
    zone: '',
  });
  const [dataForward, setDataForward] = useState<any>([]);

  const [{ data: forwardRes, loading: loadingForward }, refetchForward] =
    useAxios(
      {
        url: WAREHOUSE_FORWARD_URL,
        method: 'POST',
      },
      { manual: true },
    );

  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const store: any = new ArrayStore({
    data: dataForward,
    key: 'forwardId',
  });

  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setParams(prev => ({
      ...prev,
      formStore: {
        ...prev.formStore,
        zone: dataLocal?.zone || [],
      },
    }));
  }, [dataLocal]);

  /**
   * fetch data
   */
  useEffect(() => {
    if (forwardRes?.data) {
      const dataMapping = (forwardRes?.data || []).map(o => {
        o.forwardStatusNm =
          o?.forwardStatus === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setDataForward(dataMapping);
    }
  }, [forwardRes]);

  /**
   * on update list forward
   *
   * @param {*} data
   * @param {*} type
   */
  const onUpdateListForward = (data, type) => {
    if (data) {
      data.forwardStatusNm =
        data?.forwardStatus === '0' ? t('Pending') : t('Fixed');
    }
    if (type === 'add') {
      setDataForward(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
    } else {
      store
        .update(data?.forwardId, data)
        .done(() => {})
        .fail(() => {});
    }
    dataGridRef?.current?.instance?.refresh();
  };

  const value: any = {
    t,
    store,
    setModeView,
    modeView,
    params,
    setParams,
    refetchForward,
    onUpdateListForward,
    setDataLocal,
    dataCreate,
    setDataCreate,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListForward ref={dataGridRef} />;
      case 'create':
      case 'edit':
      case 'view':
        return <CreateForward />;
      default:
        return <NotFoundPage />;
    }
  };

  const loadingAll = loadingForward;
  return (
    <ForwardContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ForwardContext.Provider>
  );
};

export default Forward;
