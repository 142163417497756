/**
 * Update date: 14-06-2023
 * Popup product detail component
 */
import moment from 'moment';
import { useRef } from 'react';
import { formatNumber } from 'devextreme/localization';

const TableMultipleEditor = ({ columns, dataSource, scroll = false }) => {
  const tdRef: any = useRef(null);
  const inputRefs: any = useRef([]);
  let columnSeconds: any = [];
  columns?.map(o => {
    o?.columns?.map(f => {
      columnSeconds.push({ ...f });
    });
  });

  const arrayKey = ['.', 'Delete', 'Backspace'];

  /**
   * on update data
   *
   * @param {*} e
   * @param {*} i
   * @param {*} f
   */
  const handleUpdateData = (e, i, f) => {
    try {
      inputRefs.current = inputRefs.current?.filter(o => !!o);
      const data = onFormat(e?.target?.value, f?.format);
      if (dataSource[i]['quantity_per_pack'] === 1) {
        for (let index = 0; index < inputRefs.current.length; index++) {
          if (index !== i) {
            dataSource[index][f?.dataField] = data
              ? parseFloat(data) * dataSource[index]?.quantity_per_pack
              : null;
            const currency = data
              ? formatNumber(
                  parseFloat(data) * dataSource[index]?.quantity_per_pack,
                  f?.format,
                )
              : null;
            inputRefs.current[index].value = currency;
          }
        }
      }
      dataSource[i][f?.dataField] = data ? parseFloat(data) : null;
      const currency = data ? formatNumber(parseFloat(data), f?.format) : null;
      inputRefs.current[i].value = currency;
    } catch (error) {
      console.log(error);
    }
    e?.preventDefault();
  };

  const onFormat = (data, format) => {
    if (!data && data !== 0) return '';
    return parseFloat(data).toFixed(format?.precision);
  };

  return (
    <table className="table-custom-popupproduct">
      <tbody>
        <tr ref={tdRef}>
          {columns?.map((o, i) => (
            <th
              key={i}
              colSpan={o?.columns?.length || 1}
              style={{
                color: o?.color || 'inhert',
                whiteSpace: 'pre-wrap',
                backgroundColor: '#E4E7EC',
                position: scroll ? 'sticky' : 'static',
                top: 0,
              }}
            >
              {o?.caption || o?.label}
            </th>
          ))}
        </tr>
        <>
          <tr
            style={{
              position: scroll ? 'sticky' : 'static',
              top: tdRef?.current?.clientHeight + 2 || 0,
            }}
          >
            {columnSeconds?.map((o, i) => (
              <th
                style={{
                  backgroundColor: '#E4E7EC',
                  whiteSpace: 'inherit',
                  width: o?.width || 'auto',
                }}
                key={i}
              >
                <span>{o?.label}</span>
              </th>
            ))}
          </tr>
          {dataSource?.map((o, i) => (
            <tr key={i}>
              {columnSeconds?.map((f, j) =>
                f?.editing ? (
                  <td key={j} aria-details="editing">
                    <input
                      style={{ width: '100%' }}
                      ref={e => {
                        inputRefs.current[i] = e;
                      }}
                      defaultValue={o?.[f?.dataField] || undefined}
                      key={o?.[f?.dataField] || undefined}
                      onClick={(e: any) => {
                        try {
                          if (!e?.target?.value) return;
                          inputRefs.current[i].value = parseFloat(
                            e?.target?.value.replaceAll(',', ''),
                          ).toFixed(f?.format?.precision);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      onKeyDown={(e: any) => {
                        const { keyCode, key, ctrlKey, shiftKey } = e;
                        if (key === 'Enter') {
                          return e?.target?.blur();
                        } else if (
                          (keyCode >= 48 && keyCode <= 57 && !shiftKey) ||
                          (keyCode >= 96 && keyCode <= 105) ||
                          (keyCode === 65 && ctrlKey) ||
                          arrayKey.includes(key)
                        ) {
                          return;
                        }
                        e?.preventDefault();
                      }}
                      onBlur={e => {
                        handleUpdateData(e, i, f);
                      }}
                    />
                  </td>
                ) : (
                  <td key={j}>
                    <span>
                      {f?.format
                        ? formatNumber(parseFloat(o?.[f.dataField]), f?.format)
                        : f?.type === 'date'
                        ? o?.[f.dataField]
                          ? moment(o?.[f.dataField]).format('YYYY-MM-DD')
                          : ''
                        : o?.[f.dataField]}
                    </span>
                  </td>
                ),
              )}
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
};

export default TableMultipleEditor;
