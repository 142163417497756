/**
 * Update date: 18-05-2023
 * Screen 2013.3
 */
import { createContext, useContext, useState } from 'react';
import FormSearch from './FormSearch';
import TableList from './TableList';


/**
 * create context
 */
const ForwardPrice = createContext({
  dataSource: {},
  refetchData: () => {},
});

ForwardPrice.displayName = 'ForwardPrice';

export const useForwardPrice = () => {
  const context = useContext(ForwardPrice);
  return context;
};

function ProductForwardPriceInquiry() {
  const [dataSource, setDataSource] = useState<any>();
  const value: any = {
    dataSource,
    setDataSource,
  };

  return (
    <>
      <ForwardPrice.Provider value={value}>
        <FormSearch />
        <TableList />
      </ForwardPrice.Provider>
    </>
  );
}

export default ProductForwardPriceInquiry;
