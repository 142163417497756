import useAxios from 'axios-hooks';
import { CONSTANT } from 'constant';
import LoadPanel from 'app/components/LoadPanel';
import { Button, Form } from 'devextreme-react';
import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import tokenService from 'services/auth.service';
import { notification } from 'utils/notification';
import { valid } from 'utils/validation';
import { restrictKeyboardPhone } from 'utils/common/restrict-keyboard-phone';
import { useApp } from 'app';
import { useSignUp } from '..';
import { locale } from 'devextreme/localization';

const eye = 'fas fa-eye';
const eyeSlash = 'fas fa-eye-slash';
const roleType = [2, 3];

function FormCreate(props) {
  const context: any = useSignUp();
  const { t, centerName } = context;
  const formRef: any = useRef(null);
  const { listPhone, listCountry } = CONSTANT;
  const { themePro, setReloadLanguage }: any = useApp();
  const [defaulLang, setdefaulLang] = useState(
    JSON.parse(localStorage.getItem('myPagePreference') ?? '{}')?.language,
  );
  const { regexPassword } = valid;
  const history = useHistory();
  const [filedCompany, setFiledCompany] = useState<any>({
    itemType: 'empty',
    visible: false,
  });
  const [formData] = useState<any>({
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
    phone: '',
    country: '',
    roleType: '',
    company: '',
  });
  const [viewPassword, setViewPassword] = useState({
    password: false,
    conPassword: false,
  });
  const [{ data: dataRoleType, loading: loadingRoleType }] = useAxios(
    {
      url: 'iam/role/public',
      method: 'GET',
    },
    { useCache: false },
  );
  const [{ loading: loadingSignUp }, refetchSignUp] = useAxios(
    {
      url: 'user/sign-up',
      method: 'POST',
    },
    { manual: true },
  );

  const handleSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const data = {
      ...formData,
      phone: formData?.phoneCode + formData?.phone,
    };

    delete data.confirmPassword;
    delete data.phoneCode;

    const country = {
      Vietnam: ['vi', 'arial'],
      Japan: ['ja', 'HANDotum'],
      China: ['zh', 'HANDotum'],
      Korea: ['ko', 'Nanum Gothic'],
      England: ['en', 'arial'],
    };
    const myPagePreferenceLocal = JSON.parse(
      localStorage.getItem('myPagePreference') ?? '{}',
    );
    localStorage.setItem(
      'myPagePreference',
      JSON.stringify({
        ...myPagePreferenceLocal,
        language: country[data?.country][0],
        fontName: country[data?.country][1],
      }),
    );
    locale(country[data?.country][0]);
    setdefaulLang(country[data?.country][0]);
    document.documentElement.lang = country[data?.country][0];
    setReloadLanguage(true);

    const res = await refetchSignUp({
      data,
    });
    notification({ res });
    history.push({
      pathname: history.location.pathname,
      search: '?verification-code',
    });
    if (res?.data?.status === '201') {
      tokenService.updateLocalUserRegistration({ email: data.email });
      history.push({
        pathname: history.location.pathname,
        search: '?verification-code',
      });
    } else if (res?.data?.status === '427') {
      tokenService.updateLocalUserRegistration({ email: data.email });
      history.push({
        pathname: history.location.pathname,
        search: '?resend-code',
      });
    }
  };
  const onFieldDataChanged = e => {
    if (e?.dataField === 'roleId') {
      setFiledCompany(
        roleType?.includes(e?.value)
          ? {
              dataField: 'company',
              editorType: 'dxTextBox',
              label: {
                text: t('Company Name'),
              },
              isRequired: true,
              validationRules: [
                {
                  type: 'stringLength',
                  max: 100,
                },
              ],
            }
          : { itemType: 'empty', visible: false },
      );
    }
  };

  const passwordRequired: any = o => {
    return {
      type: 'custom',
      validationCallback: (e: any) => {
        const { value } = e;
        if (!value) {
          return false;
        } else if (o && !value) {
          return false;
        } else {
          return true;
        }
      },
      message: t('Password is required'),
    };
  };
  const passwordMatch: any = {
    type: 'custom',
    validationCallback: (e: any) => {
      if (!e?.value) return true;
      const { value } = e;
      if (formData?.password === value) return true;
      return false;
    },
    message: t('The password does not match'),
  };

  const passwordValid = {
    type: 'custom',
    validationCallback: (e: any) => {
      const { value } = e;
      if (!value) return true;
      return regexPassword(value);
    },
    message: t(
      'The password must contain at least 8 characters including number, letter, special character',
    ),
  };
  useEffect(() => {
    if (dataRoleType?.data)
      dataRoleType?.data.forEach(item => {
        item.name = t(item.name);
      });
  }, [dataRoleType]);

  return (
    <div>
      <LoadPanel visible={loadingRoleType || loadingSignUp} />
      <div className="title">{t('Sign Up')}</div>
      {themePro && (
        <div className="description">
          {t('Welcome to {0} WMS System').replace('{0}', centerName)}
        </div>
      )}
      <br />
      <Form
        formData={formData}
        ref={formRef}
        showColonAfterLabel={false}
        onFieldDataChanged={onFieldDataChanged}
        items={[
          {
            dataField: 'email',
            editorType: 'dxTextBox',
            label: {
              text: t('E-mail'),
            },
            editorOptions: {
              mode: 'email',
            },
            isRequired: true,
            validationRules: [
              {
                type: 'email',
              },
            ],
          },
          {
            dataField: 'name',
            editorType: 'dxTextBox',
            label: {
              text: t('User Name'),
            },
            validationRules: [
              {
                type: 'stringLength',
                max: 100,
              },
            ],
          },
          {
            itemType: themePro ? 'group' : 'empty',
            colCount: 2,
            items: [
              {
                dataField: 'password',
                editorType: 'dxTextBox',
                label: {
                  text: t('Password'),
                },
                editorOptions: {
                  mode: viewPassword?.password ? 'text' : 'password',
                  buttons: [
                    {
                      name: 'view',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: viewPassword?.password ? eye : eyeSlash,
                        onClick: () =>
                          setViewPassword(preState => ({
                            ...preState,
                            password: !preState.password,
                          })),
                      },
                    },
                  ],
                },
                validationRules: [
                  passwordRequired(formData?.confirmPassword),
                  passwordValid,
                ],
                isRequired: true,
              },
              {
                dataField: 'confirmPassword',
                editorType: 'dxTextBox',
                label: {
                  text: t('Confirm Password'),
                },
                editorOptions: {
                  mode: viewPassword?.conPassword ? 'text' : 'password',
                  buttons: [
                    {
                      name: 'view',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        icon: viewPassword?.conPassword ? eye : eyeSlash,
                        onClick: () =>
                          setViewPassword(preState => ({
                            ...preState,
                            conPassword: !preState.conPassword,
                          })),
                      },
                    },
                  ],
                },
                validationRules: [
                  passwordRequired(formData?.password),
                  passwordValid,
                  passwordMatch,
                ],
                isRequired: true,
              },
            ],
          },
          {
            itemType: themePro ? 'empty' : 'simple',
            dataField: 'password',
            editorType: 'dxTextBox',
            label: {
              text: t('Password'),
            },
            editorOptions: {
              mode: viewPassword?.password ? 'text' : 'password',
              buttons: [
                {
                  name: 'view',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: viewPassword?.password ? eye : eyeSlash,
                    onClick: () =>
                      setViewPassword(preState => ({
                        ...preState,
                        password: !preState.password,
                      })),
                  },
                },
              ],
            },
            validationRules: [
              passwordRequired(formData?.confirmPassword),
              passwordValid,
            ],
            isRequired: true,
          },
          {
            itemType: themePro ? 'empty' : 'simple',
            dataField: 'confirmPassword',
            editorType: 'dxTextBox',
            label: {
              text: t('Confirm Password'),
            },
            editorOptions: {
              mode: viewPassword?.conPassword ? 'text' : 'password',
              buttons: [
                {
                  name: 'view',
                  location: 'after',
                  options: {
                    stylingMode: 'text',
                    icon: viewPassword?.conPassword ? eye : eyeSlash,
                    onClick: () =>
                      setViewPassword(preState => ({
                        ...preState,
                        conPassword: !preState.conPassword,
                      })),
                  },
                },
              ],
            },
            validationRules: [
              passwordRequired(formData?.password),
              passwordValid,
              passwordMatch,
            ],
            isRequired: true,
          },
          {
            itemType: 'group',
            colCount: 3,
            items: [
              {
                dataField: 'phoneCode',
                editorType: 'dxSelectBox',
                label: {
                  text: t('Phone No'),
                },
                editorOptions: {
                  displayExpr: 'label',
                  valueExpr: 'value',
                  items: listPhone,
                },
                isRequired: true,
              },
              {
                dataField: 'phone',
                editorType: 'dxTextBox',
                colSpan: 2,
                label: {
                  text: t('Phone Number'),
                },
                isRequired: true,
                editorOptions: {
                  onKeyDown: restrictKeyboardPhone,
                },
                // validationRules: [
                //   {
                //     type: 'pattern',
                //     pattern: /^[0-9,(),+,-]+$/,
                //     message: t(
                //       'The phone number only contain number, (), +, - characters',
                //     ),
                //   },
                // ],
              },
            ],
          },
          {
            itemType: 'group',
            colCount: 2,
            items: [
              {
                dataField: 'country',
                editorType: 'dxSelectBox',
                label: {
                  text: t('Country Name'),
                },
                editorOptions: {
                  displayExpr: 'label',
                  valueExpr: 'value',
                  items: listCountry,
                },
                isRequired: true,
              },
              {
                dataField: 'roleId',
                editorType: 'dxSelectBox',
                label: {
                  text: t('Role Type'),
                },
                editorOptions: {
                  displayExpr: 'name',
                  valueExpr: 'roleId',
                  items: dataRoleType?.data,
                },
                isRequired: true,
              },
            ],
          },
          filedCompany,
          {
            itemType: themePro ? 'button' : 'empty',
            buttonOptions: {
              text: t('Sign Up'),
              type: 'default',
              onClick: function () {
                handleSubmit();
              },
            },
          },
        ]}
        labelLocation="top"
        colCount={1}
      />
      {!themePro && (
        <div style={{ textAlign: 'center', margin: '20px 0 50px' }}>
          <Button
            text={t('Sign Up')}
            type="default"
            stylingMode="contained"
            width={200}
            onClick={() => handleSubmit()}
          />
        </div>
      )}
      {themePro && (
        <>
          <br />
          <div className="create-account-signup">
            <span>{t('Already have an account?')}</span>
            <span onClick={() => history.push('/login')}> {t('Login')}</span>
          </div>
        </>
      )}
    </div>
  );
}

export default FormCreate;
