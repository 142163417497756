/**
 * Update date: 31-05-2023
 * Screen 2051.8
 */
import React, { useState, useRef, useEffect } from 'react';
import { uniqBy } from 'lodash';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import useDictionary from 'hooks/useDictionary';
import { useInventoryContext } from '../index';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';

const FormSearch = () => {
  const { t }: any = useDictionary({
    programId: '2051.8',
  });

  const { refetchData, productTpPermission }: any = useInventoryContext();

  const formRef = useRef<any>(null);
  const [formStore, setFormStore] = useState<any>({
    lgCategory: [],
    mdCategory: [],
    smCategory: [],
    subCategory: [],
    productGroup: [],
    product: [],
    productType: [],
  });
  const [formData, setFormData] = useState<any>({
    lgCategory: '',
    mdCategory: '',
    smCategory: '',
    subCategory: '',
    productGroup: '',
    product: '',
    receiveDate: new Date(),
    productType: '',
  });
  const [typeCommon, setTypeCommon] = useState('');
  const { DateFormat } = useFormatTime();

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'LC':
      case 'MC':
      case 'SC':
      case 'UC':
      case 'PT':
        return (
          <PopupSelectCommonCode
            visible={
              popupType === 'LC' ||
              popupType === 'MC' ||
              popupType === 'SC' ||
              popupType === 'UC' ||
              popupType === 'PT'
            }
            text={
              popupType === 'LC'
                ? formData.lgCategory
                : popupType === 'MC'
                ? formData.mdCategory
                : popupType === 'SC'
                ? formData.smCategory
                : popupType === 'UC'
                ? formData.subCategory
                : popupType === 'PT'
                ? formData.productType
                : ''
            }
            onHiding={() => {
              setTypeCommon('');
            }}
            codeType={popupType}
            mode="multiple"
            onSubmit={data => {
              if (popupType === 'LC') {
                setFormData({ ...formData, lgCategory: '' });
                setFormStore({ ...formStore, lgCategory: data });
              } else if (popupType === 'MC') {
                setFormData({ ...formData, mdCategory: '' });
                setFormStore({ ...formStore, mdCategory: data });
              } else if (popupType === 'SC') {
                setFormData({ ...formData, smCategory: '' });
                setFormStore({ ...formStore, smCategory: data });
              } else if (popupType === 'UC') {
                setFormData({ ...formData, subCategory: '' });
                setFormStore({ ...formStore, subCategory: data });
              } else if (popupType === 'PT') {
                setFormData({ ...formData, productType: '' });
                setFormStore({ ...formStore, productType: data });
              }

              setTypeCommon('');
            }}
          />
        );
      case 'PG':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'PG'}
            text={formData.productGroup}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, productGroup: '' });
              setFormStore({ ...formStore, productGroup: data });
              setTypeCommon('');
            }}
          />
        );
      case 'PD':
        return (
          <PopupSelectProduct
            visible={popupType === 'PD'}
            text={formData.product}
            onHiding={() => {
              setTypeCommon('');
            }}
            mode="multiple"
            onSubmit={data => {
              setFormData({ ...formData, product: '' });
              setFormStore({ ...formStore, product: data });
              setTypeCommon('');
            }}
          />
        );
    }
  };

  /**
   * on remove
   *
   * @param {*} type
   * @param {*} data
   */
  const handleRemove = (type, data) => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'PT':
        name = 'productType';
        break;
      default:
        break;
    }
    let newData: any =
      type === 'LC' || type === 'MC' || type === 'SC' || type === 'UC' || 'PT'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.code !== data?.code,
          )
        : type === 'PD'
        ? [...formStore?.[name]].filter(
            (filter: any) => filter?.productId !== data?.productId,
          )
        : [...formStore?.[name]].filter(
            (filter: any) => filter?.productGroupId !== data?.productGroupId,
          );
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: newData }));
  };

  /**
   * on clean all
   *
   * @param {*} type
   */
  const handleCleanAll = type => {
    let name = '';
    switch (type) {
      case 'LC':
        name = 'lgCategory';
        break;
      case 'MC':
        name = 'mdCategory';
        break;
      case 'SC':
        name = 'smCategory';
        break;
      case 'UC':
        name = 'subCategory';
        break;
      case 'PD':
        name = 'product';
        break;
      case 'PG':
        name = 'productGroup';
        break;
      case 'PT':
        name = 'productType';
        break;
      default:
        break;
    }
    setFormData(preState => ({ ...preState, [name]: '' }));
    setFormStore(preState => ({ ...preState, [name]: [] }));
  };

  /**
   * on field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'receiveDate':
        setFormData(preState => ({ ...preState, receiveDate: e?.value }));
        break;
      default:
        break;
    }
  };

  /**
   * render search
   *
   * @param {*} name
   * @return {*}
   */
  const Search = name => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          onFieldDataChanged={onFieldDataChanged}
          colCount={3}
          items={[
            {
              dataField: 'receiveDate',
              editorType: 'dxDateBox',
              colSpan: 1,
              label: {
                text: t('Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.receiveDate,
            },
          ]}
        />
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} name
   * @return {*}
   */
  const AdvancedSearch = name => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={4}
          items={[
            {
              label: { text: t('Large Category') },
              dataField: 'lgCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('LC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[0]),
                      visible: !!formStore?.lgCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.lgCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('LC', formStore?.lgCategory?.[1]),
                      visible: !!formStore?.lgCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.lgCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.lgCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.lgCategory?.length,
                      onClick: () => handleCleanAll('LC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('LC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Medium Category') },
              dataField: 'mdCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('MC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[0]),
                      visible: !!formStore?.mdCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.mdCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('MC', formStore?.mdCategory?.[1]),
                      visible: !!formStore?.mdCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.mdCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.mdCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.mdCategory?.length,
                      onClick: () => handleCleanAll('MC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('MC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Small Category') },
              dataField: 'smCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('SC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[0]),
                      visible: !!formStore?.smCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.smCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('SC', formStore?.smCategory?.[1]),
                      visible: !!formStore?.smCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.smCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.smCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.smCategory?.length,
                      onClick: () => handleCleanAll('SC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('SC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Sub Category') },
              dataField: 'subCategory',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('UC');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[0]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[0]),
                      visible: !!formStore?.subCategory?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.subCategory?.[1]?.name,
                      onClick: () =>
                        handleRemove('UC', formStore?.subCategory?.[1]),
                      visible: !!formStore?.subCategory?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.subCategory?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.subCategory?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.subCategory?.length,
                      onClick: () => handleCleanAll('UC'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('UC'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Group') },
              dataField: 'productGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('PG');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[0]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[0]),
                      visible: !!formStore?.productGroup?.[0]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productGroup?.[1]?.productGroupNm,
                      onClick: () =>
                        handleRemove('PG', formStore?.productGroup?.[1]),
                      visible: !!formStore?.productGroup?.[1]?.productGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productGroup?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productGroup?.length,
                      onClick: () => handleCleanAll('PG'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PG'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product') },
              dataField: 'product',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setTypeCommon('PD');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[0]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[0]),
                      visible: !!formStore?.product?.[0]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.product?.[1]?.productNm,
                      onClick: () =>
                        handleRemove('PD', formStore?.product?.[1]),
                      visible: !!formStore?.product?.[1]?.productId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.product?.length - 2} ${t('more')}`,
                      visible: formStore?.product?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.product?.length,
                      onClick: () => handleCleanAll('PD'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PD'),
                    },
                  },
                ],
              },
            },
            {
              label: { text: t('Product Type') },
              dataField: 'productType',
              editorType: 'dxTextBox',
              editorOptions: {
                readOnly: formData?.isReadOnlyProductTp,
                onEnterKey: () => {
                  setTypeCommon('PT');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productType?.[0]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.productType?.[0]),
                      visible: !!formStore?.productType?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formStore?.productType?.[1]?.name,
                      onClick: () =>
                        handleRemove('PT', formStore?.productType?.[1]),
                      visible: !!formStore?.productType?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${formStore?.productType?.length - 2} ${t(
                        'more',
                      )}`,
                      visible: formStore?.productType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formStore?.productType?.length,
                      onClick: () => handleCleanAll('PT'),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setTypeCommon('PT'),
                    },
                  },
                ],
              },
            },
          ]}
        />
      </>
    );
  };

  /**
   * on submit
   *
   */
  const onSubmit = () => {
    const data = {
      largeCategories: formStore.lgCategory.map((map: any) => map?.code),
      mediumCategories: formStore.mdCategory.map((map: any) => map?.code),
      smallCategories: formStore.smCategory.map((map: any) => map?.code),
      subCategories: formStore.subCategory.map((map: any) => map?.code),
      productIds: formStore.product.map((map: any) => map?.productId),
      productGroupIds: formStore.productGroup.map(
        (map: any) => map?.productGroupId,
      ),
      receiveDate: formData.receiveDate
        ? moment(formData.receiveDate).format('YYYY-MM-DD')
        : null,
      productTypes: formStore.productType.map((map: any) => map?.code),
    };
    refetchData({
      data,
    });
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormStore(preState => ({
      ...preState,
      productType: productTpPermission,
    }));
    setFormData(preState => ({
      ...preState,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(typeCommon)}
    </div>
  );
};

export default FormSearch;
