/**
 * Update date: 13-06-2023
 * Popup select common code type component
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView, TextBox } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';
interface IPopupSelectSpecialDeal {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit?: void | any;
  mode?: 'single' | 'multiple';
  title?: string;
}

function PopupSelectCommonCodeType({
  visible,
  onHiding,
  text,
  onSubmit,
  mode = 'single',
  title,
}: IPopupSelectSpecialDeal) {
  const { themePro }: any = useApp();
  const [dealName, setDealName] = useState(text);
  const dataRef: any = useRef(null);
  const { DateTimeFormat } = useFormatTime();
  const { t } = useDictionary({});

  const [{ data: resData, loading }, fetchData] = useAxios(
    {
      url: '/core/common-code/group',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * on search
   *
   */
  const onSearch = () => {
    fetchData();
  };

  /**
   * on ok
   *
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!mode || mode === 'single') {
      const d = selectedRowKeys?.[0];
      onSubmit(d);
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  useEffect(() => {
    onSearch();
  }, []);

  const loadingAll = loading;

  /**
   * render content
   *
   * @return {*} 
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Common Code Type')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }
            }
          >
            <TextBox
              width={themePro ? 400 : '40vw'}
              defaultValue={dealName}
              onValueChanged={e => setDealName(e?.value)}
              onEnterKey={onSearch}
              style={{ marginRight: 10 }}
            />
            <Button icon="search" text={t('Search')} onClick={onSearch} />
          </div>
          <DataGridFull
            ref={dataRef}
            dataSource={resData?.data}
            columns={[
              {
                dataField: 'taskId',
                caption: t('task_id'),
                alignment: 'center',
              },
              {
                dataField: 'codeType',
                caption: t('code_type'),
                alignment: 'center',
                format: DateTimeFormat,
              },
              {
                dataField: 'codeTypeName',
                caption: t('code_type_nm'),
                alignment: 'center',
                format: DateTimeFormat,
              },
            ]}
            options={{
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode,
              },
              height: themePro
                ? 'calc(100vh - 200px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={t('Common Code Type')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
    />
  );
}

export default PopupSelectCommonCodeType;
