/**
 * Update date: 15-05-2023
 * Screen 1044.5
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));

const defaultParams = {
  storeIds: [],
  storeGroupName: '',
};

/**
 * create context
 */
const StoreGroupRegistrationContext = createContext({
  params: {},
  storeGroupData: [],
  setParams: () => {},
});

StoreGroupRegistrationContext.displayName = 'StoreGroupRegistrationContext';

export const useStoreGroupRegistration = () => {
  const context = useContext(StoreGroupRegistrationContext);
  return context;
};

export default function StoreGroupRegistration() {
  const [params, setParams] = useState(defaultParams);
  const [storeGroupData, setStoreGroupData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  // Get data store group
  const [{ data: storeGroupRes, loading: storeGroupLoading }, refetchData] =
    useAxios(
      {
        url: 'user/store-group',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  const [
    { data: storeGroupDelRes, loading: storeGroupDelLoading },
    storeGroupDelete,
  ] = useAxios(
    {
      url: 'user/store-group',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * on update data
   *
   * @param {*} record
   */
  const onUpdateData = record => {
    const existItem = storeGroupData?.some(
      (o: any) => o?.storeGroupId === record?.storeGroupId,
    );
    let l: any = [];
    if (existItem) {
      l = [...storeGroupData]?.map((o: any) => {
        if (o?.storeGroupId === record?.storeGroupId) return record;
        return o;
      });
    } else {
      l = [...storeGroupData];
      l.unshift(record);
    }
    setStoreGroupData(l);
  };

  const value: any = {
    params,
    setParams,
    modeView,
    setModeView,
    storeGroupData,
    refetchData,
    onUpdateData,
    storeGroupDelete,
  };

  useEffect(() => {
    if (storeGroupRes?.data) {
      setStoreGroupData(storeGroupRes?.data);
    }
  }, [storeGroupRes]);

  const loadingAll = storeGroupLoading || storeGroupDelLoading;

  return (
    <StoreGroupRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </StoreGroupRegistrationContext.Provider>
  );
}
