/**
 * Update date: 08-06-2023
 * Screen 2081.H
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import moment from 'moment';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2081_H_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2081_H = () => {
  const { t }: any = useDictionary({});
  // stored data grid after search
  const [data, setData] = useState([]);
  // stored data form search
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    enDate: new Date(),
    dataList: {
      billingType: [],
      receivableCd: [],
    },
    billingType: '',
    receivableCd: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on submit
   *
   */
  const onSubmitSearch = async () => {
    const requestData = {
      fromDate: formSearchData?.fromDate
        ? moment(formSearchData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formSearchData?.enDate
        ? moment(formSearchData?.enDate).format('YYYY-MM-DD')
        : null,
      billingTypes:
        formSearchData?.dataList?.billingType?.map(o => o.code) || [],
      receivableCds:
        formSearchData?.dataList.receivableCd.map(o => o?.code) || [],
    };

    const res = await refetchData({
      url: 'user/store-billing',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      setData(res?.data?.data || []);
    } else {
      notification({
        res,
      });
    }
  };

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmitSearch,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2081_H;
