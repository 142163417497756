/**
 * Update date: 14-06-2023
 * Template editor html component
 */
import React, { useState } from 'react';
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  // ImageUpload,
  Item,
} from 'devextreme-react/html-editor';
import { Resizable } from 'devextreme-react';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
const fontCustoms = [
  'Cafe24Behappy',
  'Cafe24Classictype',
  'Cafe24Dangdanghae',
  'Cafe24Danjunghae',
  'Cafe24Decomilk',
  'Cafe24Decoline',
  'Cafe24Decoschool',
  'Cafe24Decoshadow',
  'Cafe24Lovingu',
  'Cafe24Dongdong',
  'Cafe24Decozoo',
  'Cafe24Oneprettynight',
  'Cafe24Ohsquareair',
  'Cafe24Ohsquare',
  'Cafe24Ssukssuk',
  'Cafe24Simplehae',
  'Cafe24Shiningstar',
  'Cafe24Syongsyong',
  'Cafe24Ssurround',
  'Cafe24SsurroundAir',
  'Cafe24Decobox',
  'NanumPen',
  'NanumMyeongjoYetHangul',
  'NanumMyeongjoEco',
  'NanumMyeongjo',
  'NanumGothicEco',
  'NanumGothic',
  'NanumBrush',
  'NanumBarunGothicYetHangul',
  'NanumBarunGothic',
];
const headerValues = [false, 1, 2, 3, 4, 5];

const HtmlEditorTemplate = (props: any) => {
  const { data } = props;
  const { editorOptions } = data;
  const { value, onChangeValue, height, title } = editorOptions;
  const [heightResize, setHeightResize] = useState<any>(height || 300);

  return (
    <div
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px',
      }}
    >
      {title && (
        <div
          style={{
            width: '100%',
            fontWeight: 'bold',
            height: 45,
            border: ' 1px solid #ddd',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 5,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          {title}
        </div>
      )}
      <Resizable
        width={'100%'}
        maxHeight={1000}
        onHeightChange={value => {
          setHeightResize(value);
        }}
        style={{}}
      >
        <HtmlEditor
          height={heightResize}
          defaultValue={value}
          onValueChange={(e: any) => onChangeValue(e)}
        >
          <MediaResizing enabled={true} />
          {/* <ImageUpload tabs={['file', 'url']} fileUploadMode="base64" /> */}
          <Toolbar multiline={true}>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item name="size" acceptedValues={sizeValues} />
            <Item
              name="font"
              acceptedValues={[...fontValues, ...fontCustoms]}
            />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item name="header" acceptedValues={headerValues} />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            <Item name="link" />
            <Item name="image" />
            <Item name="separator" />
            <Item name="clear" />
            <Item name="codeBlock" />
            <Item name="blockquote" />
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
          </Toolbar>
        </HtmlEditor>
      </Resizable>
    </div>
  );
};

export default HtmlEditorTemplate;
