/**
 * Update date: 12-05-2023
 * Screen 1042.4
 */

import React, { useState, useRef, useEffect } from 'react';
import 'jspdf-autotable';
import useAxios from 'axios-hooks';
import { Button, TreeView } from 'devextreme-react';
import { uniqBy } from 'lodash';
import TableCommonCode from './TableCommonCode';
import PopupRegisteringCommonCode from './RegisteringCommonCode';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';

/**
 * convert tree menu
 *
 * @param {*} data
 * @return {*}
 */
const coverTreeMenu = data => {
  if (!data) return [];
  const l = [...data] || [];
  const l1 = uniqBy(data, 'taskId').map((o: any) => ({
    id: o?.taskId,
    text: o?.taskName,
  }));
  let l2: any = [];
  l1.forEach((o: any) => {
    l.filter(o1 => o1?.taskId === o?.id)?.forEach((o2: any) => {
      let item = {
        ...o2,
        parentId: o?.id,
        id: o?.id + o2?.codeType,
        text: `${o2?.codeTypeName} (${o2?.codeType})`,
      };
      l2.push(item);
    });
  });
  return uniqBy(l1.concat(l2), 'id');
};

export default function CommonCodeRegistration() {
  const { themePro }: any = useApp();
  const [codeTypeSelected, setCodeTypeSelected] = useState('');
  const treeViewRef: any = useRef(null);
  const [treeMenu, setTreeMenu] = useState<any>([]);
  const [coreMenu, setCoreMenu] = useState<any>(null);
  const [visiblePopupAdd, setVisiblePopupAdd] = useState(false);

  const { t } = useDictionary({
    programId: '1042.4',
  });

  const [{ data: resGroup, loading: loadingGroup }] = useAxios(
    {
      url: '/core/common-code/group',
    },
    { useCache: false },
  );
  const [{ loading: loadingUpdate }, refetchUpdate] = useAxios(
    {
      url: '/core/common-code-type',
      method: 'PUT',
    },
    { manual: true },
  );

  /**
   * on submit
   *
   * @param {*} data
   */
  const handleSubmit = async data => {
    const res: any = await refetchUpdate({
      data: {
        ...data,
        codeTypeOld: coreMenu?.code || '',
      },
    }).catch(e => {
      notification({
        message: e?.response?.data?.error,
        type: 'error',
      });
    });
    if (res?.data?.status === '201') {
      if (res?.data?.data?.codeTypeOld) {
        setTreeMenu(preState =>
          preState.map(o => {
            if (o?.codeType === res?.data?.data?.codeTypeOld) {
              const { data } = res?.data;
              return {
                ...o,
                codeType: data?.codeType,
                codeTypeName: data?.codeTypeNm,
                id: o?.taskId + data?.codeType,
                text: `${data?.codeTypeNm} (${data?.codeType})`,
              };
            } else {
              return o;
            }
          }),
        );
      } else {
        const { data } = res?.data;
        setTreeMenu(preState =>
          preState.concat({
            parentId: data?.taskId,
            codeType: data?.codeType,
            taskId: data?.taskId,
            taskName: data?.taskName,
            codeTypeName: data?.codeTypeNm,
            id: data?.taskId + data?.codeType,
            text: `${data?.codeTypeNm} (${data?.codeType})`,
          }),
        );
      }
      setVisiblePopupAdd(false);
    }
    notification({ res });
  };

  /**
   * render tree list
   *
   * @param {*} e
   * @return {*}
   */
  const renderTreeList = e => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={() => {
          if (e?.parentId) {
            setCodeTypeSelected(e?.codeType);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span>{e.text}</span>
        </div>
        <Button
          className="program-registration-button"
          icon={e?.parentId ? 'edit' : 'plus'}
          type="normal"
          stylingMode={themePro ? 'contained' : 'text'}
          onClick={(event: any) => {
            setVisiblePopupAdd(true);
            if (e?.parentId) {
              setCoreMenu({
                code: e?.codeType,
                name: e?.codeTypeName,
                taskId: e?.taskId,
                taskName: e?.taskName,
              });
            } else {
              setCoreMenu({
                taskId: e?.id,
                taskName: e?.text,
              });
            }
            event?.event.stopPropagation();
          }}
        />
      </div>
    );
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resGroup?.data?.length) {
      setTreeMenu(() =>
        coverTreeMenu(
          resGroup?.data?.filter(
            o => !['LC', 'MC', 'SC', 'UC'].includes(o?.codeType),
          ),
        ),
      );
    }
  }, [resGroup]);

  const loadingAll = loadingGroup || loadingUpdate;
  /**
   * render
   */
  return (
    <>
      <LoadPanel visible={loadingAll} />
      <div
        style={
          themePro
            ? { display: 'flex', gap: 20 }
            : { display: 'flex', border: '1px solid #ccc' }
        }
      >
        <div
          style={
            themePro
              ? {
                  padding: 20,
                  backgroundColor: '#fff',
                  borderRadius: 6,
                  width: 400,
                }
              : { borderRight: '1px solid #ccc', padding: 20 }
          }
        >
          <TreeView
            expandNodesRecursive={false}
            dataStructure="plain"
            ref={treeViewRef}
            items={treeMenu}
            itemRender={renderTreeList}
            scrollDirection="both"
            width={350}
            height={700}
            displayExpr="programName"
            searchEnabled={true}
            searchMode={'contains'}
            searchExpr={['text', 'codeType']}
          />
        </div>
        <div
          style={
            themePro
              ? {
                  padding: 20,
                  backgroundColor: '#fff',
                  borderRadius: 6,
                  width: 'calc(100% - 420px)',
                }
              : {}
          }
        >
          <TableCommonCode codeType={codeTypeSelected} />
        </div>
      </div>
      {visiblePopupAdd && (
        <PopupRegisteringCommonCode
          visible={visiblePopupAdd}
          onSubmit={handleSubmit}
          text={t('' || 'Add Core Menu')}
          coreMenu={coreMenu}
          onHiding={() => setVisiblePopupAdd(false)}
        />
      )}
    </>
  );
}
