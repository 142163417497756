/**
 * Update date: 06-06-2023
 * Screen 2055.2
 */
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { useRef } from 'react';
import { useInquiresOfScheduled } from '..';
const exportFileName =
  'SMDC_2055.2 Inquiry of Defective/Disposal_' + moment().format('YYYYMMDD');

function ListInventory(props) {
  const context: any = useInquiresOfScheduled();
  const { store } = context;
  const dataGridRef: any = useRef(null);
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, DoubleFormat, GetExcelFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '2055.2',
  });
  /**
   * render status
   *
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    return e?.value === '0' ? 'Pending' : e?.value === '1' ? 'Fixed' : '';
  };

  const columns: any = [
    {
      caption: t('Disposal'),
      alignment: 'center',
      items: [
        {
          dataField: 'disposalBill',
          caption: t('Bill ID'),
        },
        {
          dataField: 'disposalDt',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'left',
        },
        {
          dataField: 'zoneNm',
          caption: t('Zone'),
        },
        {
          dataField: 'locationNm',
          caption: t('Location'),
        },
        {
          dataField: 'disposalReason',
          caption: t('Reason'),
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      items: [
        {
          dataField: 'disposalStatus',
          caption: t('Status'),
          customizeText: renderStatus,
        },
        {
          dataField: 'productCd',
          caption: t('Product code'),
        },
        {
          dataField: 'productNm',
          caption: t('Product Name'),
        },
        {
          dataField: 'marketableSize',
          caption: t('Marketable Size'),
        },
        {
          dataField: 'productCost',
          caption: t('Product Cost'),
          dataType: 'number',
          format: DoubleFormat,
        },
        {
          dataField: 'disposalEa',
          caption: t('Ea'),
          dataType: 'number',
          format: AmountFormat,
        },
        {
          dataField: 'costAmount',
          caption: t('Cost Amount'),
          dataType: 'number',
          format: AmountFormat,
        },
      ],
    },
    {
      dataField: 'disposalMstNt',
      caption: t('Bill Note'),
      visible: false,
    },
    {
      dataField: 'disposalDetNt',
      caption: t('Line Note'),
      visible: false,
    },
    {
      dataField: 'productMaker',
      caption: t('Maker'),
      visible: false,
    },
    {
      dataField: 'productOrigin',
      caption: t('Origin'),
      visible: false,
    },
    {
      dataField: 'vendorNmDefault',
      caption: t('Default Vendor'),
      visible: false,
    },
    {
      dataField: 'largeCategory',
      caption: t('Large category'),
      visible: false,
    },
    {
      dataField: 'mediumCategory',
      caption: t('Medium category'),
      visible: false,
    },
    {
      dataField: 'smallCategory',
      caption: t('Small category'),
      visible: false,
    },
    {
      dataField: 'subCategory',
      caption: t('Sub category'),
      visible: false,
    },
    {
      dataField: 'registDm',
      caption: t('Register Datetime'),
      dataType: 'date',
      format: DateTimeFormat,
      visible: false,
    },
    {
      dataField: 'registerUser',
      caption: t('Register User'),
      visible: false,
    },
    {
      dataField: 'registerProgramCd',
      caption: t('Register Program ID'),
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'modifyDm',
      caption: t('Modify Datetime'),
      dataType: 'date',
      format: DateTimeFormat,
      visible: false,
    },
    {
      dataField: 'modifyUser',
      caption: t('Modify User'),
      visible: false,
    },
    {
      dataField: 'modifyProgramCd',
      caption: t('Modify Program ID'),
      visible: false,
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={store}
        options={{
          columnAutoWidth: true,
          key: 'disposalId',
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'disposalEa',
                column: 'disposalEa',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
              {
                name: 'costAmount',
                column: 'costAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default ListInventory;
