/**
 * Update date: 17-05-2023
 * Screen 2011.6
 */
import React, { useRef, useState, useEffect } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import moment from 'moment';
import { useStoreContext } from '..';
import useDictionary from 'hooks/useDictionary';
import ArrayStore from 'devextreme/data/array_store';
import { SelectBox } from 'devextreme-react';
import { notification } from 'utils/notification';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useFormatTime from 'hooks/useFormatTime';

function ProductExpirationTable() {
  const dataGridRef: any = useRef(null);
  const context: any = useStoreContext();
  const {
    productData,
    fetchUpdate,
    updateData,
    onSearch,
    formData,
    formStore,
  } = context;
  const [showNoti, setShowNoti] = useState<boolean>(false);
  const store: any = new ArrayStore({
    data: productData || [],
    key: 'productId',
  });
  const [showPopupConfirmSave, setShowPopupConfirmSave] = useState(false);
  const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState(false);
  const { DateTimeFormat } = useFormatTime();

  const { t }: any = useDictionary({
    programId: '2011.6',
  });

  /**
   * render status
   *
   * @param {*} record
   * @return {*}
   */
  const renderStatus = (record: any) => {
    switch (record?.value) {
      case 'add':
        return '+';
      case 'edit':
        return 'V';
      case 'delete':
        return '-';
      default:
        return null;
    }
  };

  /**
   * on changed
   *
   * @param {*} cell
   * @param {*} data
   */
  const handleChanged = (cell, data) => {
    cell.setValue(data?.value);
    store
      ?.update(cell?.key, { status: 'edit' })
      .done(() => {})
      .fail(() => {});
    dataGridRef?.current?.instance?.refresh();
  };

  /**
   * alter period
   *
   * @param {*} cell
   * @return {*}
   */
  const alterPeriodTp = cell => {
    return (
      <SelectBox
        items={[
          { label: t('Nothing'), value: '0' },
          { label: t('Day'), value: '1' },
          { label: t('Month'), value: '2' },
        ]}
        displayExpr="label"
        valueExpr="value"
        onValueChanged={e => handleChanged(cell, e)}
      />
    );
  };
  /**
   * alter order arget
   *
   * @param {*} cell
   * @return {*}
   */
  const alterOrderTarget = cell => {
    return (
      <SelectBox
        items={[
          { label: t('Center'), value: '0' },
          { label: t('Vendor'), value: '1' },
          { label: t('Centor, Vendor'), value: '2' },
        ]}
        defaultValue={cell?.data?.orderTargetTp}
        displayExpr="label"
        valueExpr="value"
        onValueChanged={e => {
          if (e) {
            store
              ?.update(cell?.data?.productId, {
                orderTargetTp: e?.value,
                status: 'edit',
              })
              .fail(() => {});
            dataGridRef?.current?.instance?.refresh();
          }
        }}
      />
    );
  };

  /**
   * render display valu
   *
   * @param {*} data
   * @return {*}
   */
  const displayVal = data => {
    return data?.value === '0'
      ? 'Nothing'
      : data?.value === '1'
      ? 'Day'
      : 'Month';
  };
  const displayOrderTarget = data => {
    return data?.value === '0'
      ? 'Center'
      : data?.value === '1'
      ? 'Vendor'
      : 'Centor, Vendor';
  };
  const columns: any = [
    {
      dataField: 'status',
      caption: t('Status'),
      alignment: 'center',
      allowEditing: false,
      cellRender: renderStatus,
      width: 60,
      fixedPosition: 'left',
      fixed: true,
      allowHeaderFiltering: false,
      allowFiltering: false,
    },
    {
      dataField: 'productId',
      caption: t('Product Id'),
      alignment: 'center',
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
      allowEditing: false,
    },
    {
      dataField: 'productNm',
      caption: t('Product'),
      allowEditing: false,
    },
    {
      dataField: 'productTp',
      caption: t('Product Type Code'),
      alignment: 'center',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'productTpNm',
      caption: t('Product Type'),
      allowEditing: false,
    },
    {
      dataField: 'productMaker',
      caption: t('Maker ID'),
      alignment: 'center',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'productMakerNm',
      caption: t('Maker'),
      allowEditing: false,
    },
    {
      dataField: 'orderTargetTp',
      caption: t('Order Target'),
      allowEditing: false,
      allowSorting: false,
      width: 200,
      cellRender: alterOrderTarget,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      allowEditing: false,
    },
    {
      dataField: 'periodTp',
      caption: t('Period Type'),
      editCellRender: alterPeriodTp,
      customizeText: displayVal,
    },
    {
      dataField: 'sellBy',
      caption: t('Sell By'),
      allowEditing: true,
    },
    {
      dataField: 'useBy',
      caption: t('Use By'),
      allowEditing: true,
    },
    {
      dataField: 'bestBy',
      caption: t('Best By'),
      allowEditing: true,
    },
    {
      dataField: 'periodUseTf',
      caption: t('Validity Period Use'),
      editorType: 'dxSwitch',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'vendorIdDefault',
      caption: t('Default Vendor'),
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'vendorNmDefault',
      caption: t('Default Vendor Name'),
      allowEditing: false,
      visible: false,
    },
    {
      dataField: 'prdRegistDt',
      caption: t('Product Registration Date'),
      dataType: 'date',
      format: DateTimeFormat,
      allowEditing: false,
      alignment: 'center',
    },
    {
      dataField: 'prdModifyDt',
      caption: t('Product Modification Date'),
      dataType: 'date',
      format: DateTimeFormat,
      allowEditing: false,
      alignment: 'center',
    },
  ];

  const exportFileName = 'SMDC_2011.6 ' + moment().format('YYYYMMDD');

  /**
   * export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * on delete
   *
   */
  const handleDelete = () => {
    const selectedRowKeys =
      dataGridRef?.current?.instance?.getSelectedRowKeys();

    const data = {
      deleteIds: selectedRowKeys,
      productExpirationUpdateRequests: null,
    };

    fetchUpdate({
      data,
    });

    setShowNoti(true);
    setShowPopupConfirmDelete(!showPopupConfirmDelete);
  };

  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowUpdated = e => {
    store.update(e?.key, { status: 'edit' });
  };

  useEffect(() => {
    if (!showNoti) {
      return;
    }
    if (updateData?.status === '200') {
      notification({
        message: t('Data updated successfully.'),
        type: 'success',
      });
      dataGridRef?.current?.instance?.deselectAll();
      store?._array?.map((o: any) => {
        store?.update(o?.productId, { status: 'normal' });
        refreshData();
      });
      setShowNoti(false);
      dataGridRef?.current?.instance?.refresh();
    } else {
      notification({
        message: t('Data updated failed.'),
        type: 'error',
      });
      setShowNoti(false);
    }
  }, [updateData]);

  /**
   * on save
   *
   */
  const handleSave = () => {
    if (dataGridRef?.current?.instance?.hasEditData?.()) {
      dataGridRef?.current?.instance?.saveEditData?.();
    }

    const dataRows = store?._array;
    // const deleteRows = dataRows?.filter((o: any) => {
    //   if (o?.status === 'delete') {
    //     return o?.productId;
    //   }
    // });
    // const deleteIds = deleteRows?.map(o => o?.productId);

    const updateRows = dataRows?.filter((o: any) => {
      if (o?.status === 'edit') {
        return o;
      }
    });
    const productExpirationUpdateRequests = updateRows?.map((o: any) => {
      return {
        productId: o?.productId,
        productCd: o?.productCd,
        bestBy: o?.bestBy,
        marketableSize: o?.marketableSize,
        periodTp: o?.periodTp,
        productMaker: o?.productMaker,
        productMakerNm: o?.productMakerNm,
        productNm: o?.productNm,
        productTp: o?.productTp,
        productTpNm: o?.productTpNm,
        sellBy: o?.sellBy,
        useBy: o?.useBy,
        orderTargetTp: o?.orderTargetTp,
      };
    });

    const data = {
      deleteIds: null,
      productExpirationUpdateRequests: productExpirationUpdateRequests,
    };

    fetchUpdate({
      data,
    });

    setShowNoti(true);
    setShowPopupConfirmSave(!showPopupConfirmSave);
  };

  /**
   * refresh data
   *
   */
  const refreshData = () => {
    const searchParams = {
      largeCategoryCodes:
        formStore.largeCategory === ''
          ? []
          : formStore.largeCategory?.map(o => o?.code),
      mediumCategoryCodes:
        formStore.mediumCategory === ''
          ? []
          : formStore.mediumCategory?.map(o => o?.code),
      smallCategoryCodes:
        formStore.smallCategory === ''
          ? []
          : formStore.smallCategory?.map(o => o?.code),
      subCategoryCodes:
        formStore.subCategory === ''
          ? []
          : formStore.subCategory?.map(o => o?.code),
      productType:
        formStore.productTypes === ''
          ? []
          : formStore.productTypes?.map(o => o?.code),
      makerId:
        formStore.productMakers === ''
          ? []
          : formStore.productMakers?.map(o => o?.code),
      productGroupIds:
        formStore.productGroup === ''
          ? []
          : formStore.productGroup?.map(o => o?.productGroupId),
      productIds:
        formStore.product === ''
          ? []
          : formStore.product?.map(o => o?.productId),
      vendorIds:
        formStore.vendor === '' ? [] : formStore.vendor?.map(o => o?.vendorId),
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      timezone: -(new Date().getTimezoneOffset() / 60),
      tradeType: formData?.tradeType,
    };
    onSearch(searchParams);
  };

  // const handleCancel = () => {
  //   dataGridRef?.current?.instance?.deselectAll();
  //   store?._array?.map((o: any) => {
  //     store?.update(o?.productId, { status: 'normal' });
  //   });
  //   setShowPopupConfirm(!showPopupConfirm);
  //   refreshData();
  // };

  /**
   * show popup confirm
   *
   */
  const onSave = () => {
    setShowPopupConfirmSave(!showPopupConfirmSave);
  };
  /**
   * show popup confirm delete
   *
   */
  const onRemove = () => {
    setShowPopupConfirmDelete(!showPopupConfirmDelete);
  };

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={store}
        options={{
          key: 'productId',
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
          },
          columnAutoWidth: true,
          onRowUpdated: onRowUpdated,
          onSelectionChanged: onselectionchange,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: '',
                  onClick: exportExcel,
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'save',
                  text: t('Save'),
                  onClick: onSave,
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  onClick: onRemove,
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          editing: {
            mode: 'cell',
            allowUpdating: true,
            startEditAction: 'click',
            selectTextOnEditStart: true,
            refreshmode: 'reshape',
          },
        }}
      />
      {showPopupConfirmSave && (
        <PopupConfirm
          title={t('Confirm Saving')}
          visible={showPopupConfirmSave}
          content={t('Are you sure to save all changes?')}
          onOk={handleSave}
          onHiding={() => setShowPopupConfirmSave(!showPopupConfirmSave)}
        />
      )}
      {showPopupConfirmDelete && (
        <PopupConfirmDelete
          visible={showPopupConfirmDelete}
          content={t(`Do you want to selected items?`)}
          onOk={handleDelete}
          onHiding={() => setShowPopupConfirmDelete(!showPopupConfirmDelete)}
        />
      )}
    </>
  );
}

export default ProductExpirationTable;
