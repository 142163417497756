/**
 * Update date: 25-05-2023
 * Screen 2042.2
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, { createContext, useContext, useState, useEffect } from 'react';
import FormSearch from './FormSearch';
import ListInventory from './ListInvetory';
import useLocalStorage from 'hooks/useLocalStorage';

const defaultParams = {
  largeCategories: '',
  mediumCategories: '',
  smallCategories: '',
  subCategories: '',
  product: '',
  productGroup: '',
  vendor: '',
  vendorType: '',
  status: '0',
};
/**
 * create context
 */
const InquiresAbout = createContext({});
InquiresAbout.displayName = 'InquiresAbout';

export const useInquiresAbout = () => {
  const context = useContext(InquiresAbout);
  return context;
};

function InquiresAboutTheReturnSchedule() {
  const [dataLocal, setDataLocal] = useLocalStorage('2042.2_dataList', {});
  const [params, setParams] = useState({
    ...defaultParams,
    fromDate: new Date(),
    endDate: new Date(),
    formStore: {
      largeCategories: [],
      mediumCategories: [],
      smallCategories: [],
      subCategories: [],
      product: [],
      productGroup: [],
      vendor: [],
      vendorType: [],
    },
  });
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: '/warehouse/takeout-due/inquire',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );
  const store: any = new ArrayStore({
    data: data?.data?.map((o, i) => ({
      ...o,
      id: i + 1,
    })),
    key: 'id',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setParams(prev => ({
      ...prev,
      formStore: {
        ...prev.formStore,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  const value: any = {
    store,
    refetchData,
    params,
    setParams,
    setDataLocal,
  };

  const loadingAll = loading;
  return (
    <InquiresAbout.Provider value={value}>
      <LoadPanel visible={loadingAll} />
      <FormSearch />
      <ListInventory />
    </InquiresAbout.Provider>
  );
}

export default InquiresAboutTheReturnSchedule;
