/**
 * Update date: 06-06-2023
 * Screen 2081.1
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { API_URL_USER_MEMBER_LIST } from 'constant/apiUrl';

const MemberEdit = React.lazy(() => import('./MemberEdit'));
const ListMember = React.lazy(() => import('./ListMember'));

const defaultSearchParams = {
  keyword: '',
  approvals: [],
};

export const RegistrationMemberContext = createContext({
  setModeView: any => {},
  modeView: '',
  params: {},
  setParams: any => {},
  listMembers: [],
  fetchMember: any => {},
});

RegistrationMemberContext.displayName = 'RegistrationMemberContext';

export const useRegistrationMember = () => {
  const context = useContext(RegistrationMemberContext);
  return context;
};

const RegistrationMember = () => {
  // state defined for SEARCHFORM
  const [params, setParams] = useState(defaultSearchParams);
  // state defined for LIST
  const [listMembers, setListMembers] = useState([]);
  // state defined for switch view
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  // api search
  const [{ data: resMembers, loading: loadingMembers }, fetchMember] = useAxios(
    {
      url: API_URL_USER_MEMBER_LIST,
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  // Context value
  const value: any = {
    setModeView,
    modeView,
    params,
    setParams,
    listMembers,
    fetchMember,
  };

  // render dynamic view child
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListMember />;
      case 'edit':
      case 'create':
        return <MemberEdit />;
      default:
        return <NotFoundPage />;
    }
  };

  // api callback set listMembers
  useEffect(() => {
    if (resMembers?.data) {
      setListMembers(resMembers?.data);
    }
  }, [resMembers]);

  const loadingAll = loadingMembers;

  return (
    <RegistrationMemberContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </RegistrationMemberContext.Provider>
  );
};

export default RegistrationMember;
