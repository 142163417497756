/**
 * Update date: 31-05-2023
 * Screen 2051.A
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import useDictionary from 'hooks/useDictionary';
import { TRADE_TYPE } from 'constant';

const ListInventoryCount = React.lazy(() => import('./ListInventoryCount'));

const ModuleContext = createContext({});
ModuleContext.displayName = 'InventoryCountInquiryByProduct';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2051_A = () => {
  const { t }: any = useDictionary({ programId: '2051.A' });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [params, setParams] = useState({
    startDuration: new Date(),
    endDuration: new Date(),
    isReadOnlyProductTp: false,
    dataList: {
      largeCategory: [],
      subCategory: [],
      mediumCategory: [],
      smallCategory: [],
      product: [],
      productGroup: [],
      productType: [],
    },
    largeCategory: '',
    subCategory: '',
    mediumCategory: '',
    smallCategory: '',
    product: '',
    productGroup: '',
    productType: '',
  });
  const [{ data, loading }, refetchStockCount] = useAxios(
    {
      url: '/warehouse/inv-count',
      method: 'POST',
    },
    { manual: true },
  );

  const store: any = new ArrayStore({
    data: data?.data?.map((o, i) => {
      const {
        purchase_unit_price = 0,
        stock_ea = 0,
        tax_rate = 0,
        purchase_incvat,
      } = o;
      let supplyUnitPrice = purchase_unit_price;
      if (purchase_incvat) {
        supplyUnitPrice = purchase_unit_price / (1 + tax_rate * 0.01);
      }
      const purchase_amount = supplyUnitPrice * stock_ea;
      return {
        ...o,
        id: i + 1,
        supply_unit_price: supplyUnitPrice,
        purchase_amount: purchase_amount,
        difference_amount: o.stock_amount - purchase_amount,
        tradeTypeName: t(
          TRADE_TYPE?.find(t => t?.value === o?.trade_type)?.text || '',
        ),
      };
    }),
    key: 'id',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    t,
    productTpPermission,
    store,
    setModeView,
    modeView,
    params,
    setParams,
    refetchStockCount,
  };

  const loadingAll = loading || loadingProductTpPermission;
  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <ListInventoryCount />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2051_A;
