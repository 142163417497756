/**
 * Create date: 2024-09-09
 * Update date: 2024-09-09
 * Screen 1041.2
 */

import LoadPanel from 'app/components/LoadPanel';
import PopupConfirmDeleteWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmDeleteWithRef';
import PopupConfirmWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmWithRef';
import useAxios from 'axios-hooks';
import { Button, Form } from 'devextreme-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import { dataBookMark, useModuleContext } from '..';

function FormCreate(props, ref) {
  const context: any = useModuleContext();
  const { t, selected, setSelected, setTreeList, mapDataToTree } = context;
  const saveConfirmRef: any = useRef(null);
  const confirmDeleteRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({
    programId: null,
    programName: '',
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  useEffect(() => {
    if (selected?.type) {
      const { type } = selected;
      switch (type) {
        case 'edit':
          setFormData({
            ...selected?.data,
          });
          break;
        case 'create':
          setFormData({
            groupId: null,
            groupName: '',
          });
          break;
        default:
          break;
      }
    }
  }, [selected]);

  const contentName = () => {
    if (selected?.type) {
      switch (selected?.type) {
        case 'edit':
          return `${t('Edit')} ${selected?.data?.programName}`;
        case 'create':
          return `${t('Create Group')}`;
        default:
          return '';
      }
    }
  };

  const validateSave = () => {
    const isValid = ref?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    saveConfirmRef?.current?.onOpen?.();
  };

  // on submit
  const handleSubmit = async () => {
    const dataRequest = {
      bookmarkId: selected?.type === 'edit' ? formData.programId : null,
      bookmarkName: formData.programName,
    };
    const res = await refetchData({
      url: '/user/m1041_2/save-group',
      method: 'PUT',
      data: dataRequest,
    });
    notification({ res });
    if (res?.data?.status === '201') {
      const { data } = res?.data;
      const newData = {
        ...(selected?.data || {}),
        id: `${data?.id?.bookmarkId}`,
        programId: `${data?.id?.bookmarkId}`,
        programName: data.bookmarkName,
        parentId: dataBookMark.isGroup,
        sortNo: data.sortNo,
        level: 1,
      };
      setTreeList((prev: any) => {
        const index = prev.findIndex(
          (item: any) =>
            item?.programId === newData?.programId &&
            item?.parentId === dataBookMark.isGroup,
        );
        if (index === -1) {
          prev.push(newData);
        } else {
          prev.splice(index, 1, newData);
        }
        return [...prev];
      });
      setSelected({ type: 'edit', data: newData });
    }
  };

  // on delete
  const handleDelete = async () => {
    const bookmarkId = selected?.data?.programId;
    const res = await refetchData({
      url: `/user/m1041_2/group/${bookmarkId}`,
      method: 'DELETE',
    });
    notification({ res });
    if (res?.data?.status === '200') {
      mapDataToTree?.(res?.data?.data);
      setSelected(null);
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 6,
        width: '100%',
      }}
    >
      {selected?.type && (
        <>
          <div className="my-page-caption">
            <div>
              <div className="my-page-caption-title">{contentName()}</div>
            </div>
            <div>
              <Button
                icon="check"
                stylingMode="contained"
                type="default"
                text={t('Save')}
                onClick={validateSave}
                style={{ marginRight: 5 }}
              />
              {selected?.type === 'edit' && (
                <Button
                  icon="trash"
                  stylingMode="contained"
                  text={t('Delete')}
                  onClick={() => confirmDeleteRef?.current?.onOpen?.()}
                />
              )}
            </div>
          </div>
          <br />
          <Form
            ref={ref}
            labelLocation="top"
            showColonAfterLabel={false}
            formData={formData}
            items={[
              {
                label: { text: t('Bookmark Group ID') },
                dataField: 'programId',
                editorType: 'dxTextBox',
                isRequired: selected?.type === 'edit',
                editorOptions: {
                  disabled: true,
                },
              },
              {
                label: { text: t('Bookmark Group Name') },
                dataField: 'programName',
                editorType: 'dxTextBox',
                isRequired: true,
              },
            ]}
            colCount={1}
          />
        </>
      )}
      <LoadPanel visible={loading} />
      <PopupConfirmWithRef
        ref={saveConfirmRef}
        content={'Do you want to save?'}
        isTranslate
        onOk={handleSubmit}
      />
      <PopupConfirmDeleteWithRef ref={confirmDeleteRef} onOk={handleDelete} />
    </div>
  );
}

export default forwardRef(FormCreate);
