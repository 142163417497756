/**
 * Update date: 17-05-2023
 * Screen 2011.4
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FormCreate from './FormCreate';
import ListCenter from './ListCenter';
import { useProductType } from 'hooks/useProductType';

/**
 * create context
 */
const ContainerRegistrationContext = createContext({
  params: {},
  setParams: () => {},
  refetchData: () => {},
  listData: {} || null,
  modeView: {},
  setModeView: () => {},
});

ContainerRegistrationContext.displayName = 'ContainerRegistrationContext';

export const useContainerRegistration = () => {
  const context = useContext(ContainerRegistrationContext);
  return context;
};

function ContainerRegistration() {
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    productType: '',
    containerName: '',
    orderByName: 'container_id',
    orderBy: false,
    use: true,
    formStore: {
      productType: [],
    },
  });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [modeView, setModeView] = useState({ type: 'list' });
  const [listData, setListData] = useState<any>([]);
  const store: any = new ArrayStore({
    data: listData,
    key: 'containerId',
  });
  const [{ data, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/product/container',
      method: 'POST',
    },
    { manual: true },
  );
  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListCenter ref={dataGridRef} />;
      case 'create':
      case 'edit':
        return <FormCreate />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * deselect all
   *
   */
  const deselectAll = () => {
    dataGridRef?.current?.instance?.deselectAll();
  };
  /**
   * on add
   *
   * @param {*} data
   */
  const onAdd = data => {
    setListData(preState => {
      preState.splice(0, 0, data);
      return preState;
    });
    dataGridRef?.current?.instance?.refresh();
  };
  /**
   * on update
   *
   * @param {*} data
   */
  const onUpdate = data => {
    store
      .update(data?.containerId, data)
      .done(() => {})
      .fail(() => {});
  };

  const value: any = {
    params,
    setParams,
    refetchData,
    store,
    deselectAll,
    modeView,
    setModeView,
    onAdd,
    onUpdate,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setListData(data?.data);
    }
  }, [data]);
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      formStore: {
        ...preState?.formStore,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);
  const loadingAll = loadingData || loadingProductTpPermission;
  return (
    <ContainerRegistrationContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ContainerRegistrationContext.Provider>
  );
}

export default ContainerRegistration;
