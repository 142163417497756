import { fork, call, takeLatest, put } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  registerProgramActions,
  RegisterProgramPayLoad,
} from './registerProgramSlice';
import { menuProgramActions } from './menuProgramSlice';
import {
  registerProgramId as registerProgramIdApi,
  fetchMenuProgram as fetchMenuProgramApi,
  deleteProgram as deleteProgramApi,
} from '../../../api/api';

function* registerProgramId(actions: PayloadAction<RegisterProgramPayLoad>) {
  try {
    yield call(registerProgramIdApi, actions.payload.data);
    yield put(registerProgramActions.registerProgramIdSuccess());
    actions.payload.onSuccess();
  } catch (error: any) {
    yield put(registerProgramActions.registerProgramIdFail());
    actions.payload.onError(error);
  }
}

function* fetchMenuProgram(actions) {
  try {
    const result = yield call(fetchMenuProgramApi, {});
    yield put(menuProgramActions.getMenuProgramSuccess(result.data.data));
    actions.payload.onSuccess(result.data.data);
  } catch (error) {
    yield put(menuProgramActions.getMenuProgramError());
  }
}

function* deleteProgram(actions) {
  try {
    yield call(deleteProgramApi, actions.payload.id);
    actions.payload.onSuccess();
  } catch (error) {
    actions.payload.onError();
  }
}

function* watchRegisterProgramId() {
  yield takeLatest(
    registerProgramActions.registerProgramId.type,
    registerProgramId,
  );
}

function* watchFetchMenuProgram() {
  yield takeLatest(menuProgramActions.getMenuProgram.type, fetchMenuProgram);
}

function* watchDeleteProgram() {
  yield takeLatest(registerProgramActions.deleteProgram.type, deleteProgram);
}

export function* registerProgramSaga() {
  yield fork(watchRegisterProgramId);
  yield fork(watchFetchMenuProgram);
  yield fork(watchDeleteProgram);
}
