/**
 * Update date: 14-06-2023
 * Template file upload component
 */
import { Button } from 'devextreme-react';
import FileUploader from 'devextreme-react/file-uploader';
import useDictionary from 'hooks/useDictionary';
import React, { useEffect, useState } from 'react';

interface ITempleFileUpload {
  allowedFileExtensions: any[];
  accept: string;
  mode?: 'single' | 'multiple';
  onSelectedFile: any;
  fileName?: string;
  height?: string | number;
}

const FileUpload = (
  {
    allowedFileExtensions,
    accept,
    mode = 'single',
    onSelectedFile,
    fileName,
    height = 200,
  }: ITempleFileUpload,
  props,
) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const { t } = useDictionary({});
  /**
   * on clear file
   *
   */
  const onClearFile = () => {
    setSelectedFile(null);
  };
  /**
   * on value change
   *
   * @param {*} e
   */
  const onValueChanged = async (e: any) => {
    setSelectedFile(e?.value);
  };
  useEffect(() => {
    onSelectedFile(selectedFile);
  }, [selectedFile]);
  return (
    <div>
      <div
        id="dropzone-external"
        className="flex-box dx-theme-border-color"
        style={{ width: '100%', height: height }}
      >
        <div id="dropzone-text" className="flex-box">
          <span>
            {t('Drag & Drop some files here, or click to select files')}
          </span>
          <span>{t('Document must be {0}').replace('{0}', accept)}</span>
        </div>
      </div>
      {selectedFile && (
        <div style={{ textAlign: 'left', width: '100%' }}>
          <br />
          <div>
            <Button
              icon="close"
              stylingMode="text"
              type="default"
              onClick={onClearFile}
            />
            <span>
              {fileName
                ? fileName
                : `${selectedFile[0]?.name} - ${selectedFile[0]?.size} ${t(
                    'bytes',
                  )}`}
            </span>
          </div>
        </div>
      )}
      <FileUploader
        id="file-uploader"
        dialogTrigger="#dropzone-external"
        dropZone="#dropzone-external"
        multiple={mode === 'single' ? false : true}
        allowedFileExtensions={allowedFileExtensions}
        accept={accept}
        uploadMode="useButtons"
        visible={false}
        onValueChanged={onValueChanged}
        labelText=""
        selectButtonText={t('File')}
        maxFileSize={5 * 1024 * 1024}
        elementAttr={{
          class: 'form-upload-custom',
        }}
      />
    </div>
  );
};

export default FileUpload;
