import React, { useState, useContext, createContext, useEffect } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

const ListInventory = React.lazy(() => import('./ListInventory'));

const defaultParamsInstock = {
  zone: '',
  largeCategory: '',
  subCategory: '',
  mediumCategory: '',
  smallCategory: '',
  product: '',
  productGroup: '',
  productType: '',
  inStock: null,
  isReadOnlyProductTp: false,
};

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2051_D';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Inventory count inquiry by product ( Option )

 *
 * @return {*} 
 */
const M2051_D = () => {
  // create state
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [data, setData] = useState<any>([]);

  const [params, setParams] = useState({
    ...defaultParamsInstock,
    baseDate: new Date(),
    dataList: {
      zone: [],
      largeCategory: [],
      subCategory: [],
      mediumCategory: [],
      smallCategory: [],
      product: [],
      productGroup: [],
      productType: [],
    },
  });

  const store: any = new ArrayStore({
    data: data,
    key: 'id',
  });

  // Set product type of user to state
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  // Values share with child component
  const value: any = {
    productTpPermission,
    store,
    params,
    setParams,
    setData
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingProductTpPermission} />
        <ListInventory />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2051_D;
