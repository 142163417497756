/**
 * Update date: 12-05-2023
 * Screen 1043.8
 */

import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useRef, useEffect } from 'react';
import useDictionary from 'hooks/useDictionary';
import { Button, Form, Template } from 'devextreme-react';
import { notification } from 'utils/notification';
import InputFileImage from 'app/components/InputFileImage/InputFileImage';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

const defaultSearchParams = {
  mall_cd: '',
  seq_no: 1,
  image_url: '',
  landing_url: '',
  post_from_dt: '',
  post_to_dt: '',
  post_from_tm: null,
  post_to_tm: null,
  order_no: 1,
  use_tf: false,
};

const M1043_8 = () => {
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({});
  const { QtyFormat } = useFormat();
  const { DateFormat } = useFormatTime();
  const refsForm = useRef<any>([]);
  const [formData, setFormData] = useState<any>([{ ...defaultSearchParams }]);
  const [formDataStored, setFormDataStored] = useState<any>(
    cloneDeep([{ ...defaultSearchParams }]),
  );

  const [showPopupConfirmSave, setShowPopupConfirmSave] = useState(false);
  const [showPopupConfirmCancel, setShowPopupConfirmCancel] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: resData, loading: loadingGetData }] = useAxios(
    {
      url: `product/m1043_8/${'AOS'}`,
      method: 'GET',
    },
    { manual: false, autoCancel: true, useCache: false },
  );

  useEffect(() => {
    if (!resData?.data || !resData?.data?.length) return;
    const dataMapping = resData?.data?.map(banner => {
      banner.post_from_tm = banner?.post_from_tm
        ? moment(banner?.post_from_tm, 'HH:mm:ss')
        : null;
      banner.post_to_tm = banner?.post_to_tm
        ? moment(banner?.post_to_tm, 'HH:mm:ss')
        : null;
      return banner;
    });
    setFormData(dataMapping);
    setFormDataStored(cloneDeep(dataMapping));
  }, [resData]);
  /**
   * validate
   * @return {*}
   */
  const validateSave = () => {
    const currentForm = refsForm?.current || [];
    let isNotValid = false;
    currentForm.forEach(f => {
      if (f && !f?.instance?.validate()?.isValid) {
        isNotValid = true;
      }
    });
    if (isNotValid) {
      return notification({
        message: t('Please enter all information'),
        type: 'error',
      });
    }
    setShowPopupConfirmSave(true);
  };
  /**
   * on save
   */
  const onSave = async () => {
    const dataRequest = {
      mall_cd: 'AOS',
      details_Request: formData.map(o => {
        return {
          ...o,
          post_from_dt: moment(o?.post_from_dt).format('YYYY-MM-DD'),
          post_to_dt: moment(o?.post_to_dt).format('YYYY-MM-DD'),
          post_from_tm: moment(o?.post_from_tm).format('HH:mm'),
          post_to_tm: moment(o?.post_to_tm).format('HH:mm'),
        };
      }),
    };
    const res = await refetchData({
      url: 'product/m1043_8',
      method: 'PUT',
      data: dataRequest,
    });
    notification({ res });
    if (res?.data?.status === '201') {
      const dataMapping = res?.data?.data?.map(o => {
        o.post_from_tm = o?.post_from_tm
          ? moment(o?.post_from_tm, 'HH:mm:ss')
          : null;
        o.post_to_tm = o?.post_to_tm ? moment(o?.post_to_tm, 'HH:mm:ss') : null;
        return o;
      });
      setFormData(dataMapping);
      setFormDataStored(cloneDeep(dataMapping));
    }
    setShowPopupConfirmSave(false);
  };
  /**
   * on cancel
   */
  const onCancel = () => {
    setFormData(cloneDeep(formDataStored));
    setShowPopupConfirmCancel(false);
  };
  /**
   * on select file
   * @param {*} index
   * @param {*} file
   * @return {*}
   */
  const onSelectedFile = async (index, file) => {
    if (!file) {
      formData.splice(index, 1, {
        ...formData[index],
        image_url: '',
      });
      setFormData([...formData]);
      return;
    }
    const formRequest = new FormData();
    formRequest.append('file', file);
    const res: any = await refetchData({
      url: '/user/upload/image',
      method: 'POST',
      data: formRequest,
    });
    formData.splice(index, 1, {
      ...formData[index],
      image_url: res?.data?.data || '',
    });
    setFormData([...formData]);
  };
  /**
   * on field data changed
   * @param {*} e
   * @param {*} index
   */
  const onFieldDataChanged = (e, index) => {
    switch (e?.dataField) {
      case 'post_from_dt':
        const post_to_dt = formData[index].post_to_dt;
        if (post_to_dt && e?.value && e?.value > post_to_dt) {
          refsForm?.current?.[index]?.instance?.updateData('post_to_dt', null);
        }
        break;
      case 'post_to_dt':
        const post_from_dt = formData[index].post_from_dt;
        if (post_from_dt && e?.value && e?.value < post_from_dt) {
          refsForm?.current?.[index]?.instance?.updateData(
            'post_from_dt',
            null,
          );
        }
        break;
      case 'post_from_tm':
        const post_to_tm = formData[index].post_to_tm;
        if (post_to_tm && e?.value && e?.value > post_to_tm) {
          refsForm?.current?.[index]?.instance?.updateData('post_to_tm', null);
        }
        break;
      case 'post_to_tm':
        const post_from_tm = formData[index].post_from_tm;
        if (post_from_tm && e?.value && e?.value < post_from_tm) {
          refsForm?.current?.[index]?.instance?.updateData(
            'post_from_tm',
            null,
          );
        }
        break;
      default:
        break;
    }
  };
  /**
   * add banner
   */
  const addBanner = () => {
    const maxSeqNo = formData?.length
      ? Math.max(...formData.map(o => o.seq_no || 0)) + 1
      : 1;
    const lengthData = (formData?.length || 0) + 1;
    setFormData([
      ...formData,
      { ...defaultSearchParams, seq_no: maxSeqNo, order_no: lengthData },
    ]);
  };
  /**
   * delete banner
   * @return {*}
   */
  const deleteBanner = () => {
    if (deleteIndex == null) return;
    formData.splice(deleteIndex, 1);
    setFormData([...formData]);
    setShowConfirmDelete(false);
  };
  /**
   * render
   */
  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingfetchData || loadingGetData} />
      {themePro ? (
        <BreadCrumbPremium
          onSubmit={() => validateSave()}
          onCancel={() => setShowPopupConfirmCancel(true)}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <h3>{t('AOS Banner Registration')}</h3>
          <div style={{ display: 'flex', gap: 15 }}>
            <Button
              icon={'close'}
              text={t('Cancel')}
              onClick={() => setShowPopupConfirmCancel(true)}
            />
            <Button
              icon={'save'}
              text={t('Save')}
              onClick={() => validateSave()}
            />
          </div>
        </div>
      )}
      {formData?.map((dataBinding, index) => (
        <div
          key={`banner_${index}`}
          style={
            themePro
              ? {}
              : {
                  border: '1px solid #ddd',
                  borderRadius: 5,
                  padding: '15px 15px 0 15px',
                  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px',
                  marginBottom: 20,
                }
          }
        >
          <Form
            ref={el => (refsForm.current[index] = el)}
            showColonAfterLabel={false}
            labelLocation="top"
            colCount={4}
            formData={dataBinding}
            onFieldDataChanged={e => onFieldDataChanged(e, index)}
            className="body-padding-white mb-10"
            items={[
              {
                label: { text: ' ' },
                itemType: 'simple',
                template: `InputBannerImage${index}`,
              },
              {
                itemType: 'group',
                colSpan: 3,
                colCount: 1,
                items: [
                  {
                    label: {
                      text: t('Url'),
                    },
                    dataField: 'image_url',
                    editorType: 'dxTextBox',
                    isRequired: true,
                  },
                  {
                    label: {
                      text: t('Landing Url'),
                    },
                    dataField: 'landing_url',
                    editorType: 'dxTextBox',
                    isRequired: true,
                  },
                  {
                    itemType: 'group',
                    colSpan: 1,
                    colCount: 4,
                    items: [
                      {
                        label: {
                          text: t('Post Start Date'),
                        },
                        dataField: 'post_from_dt',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          displayFormat: DateFormat,
                        },
                      },
                      {
                        label: {
                          text: t('Post Close Date'),
                        },
                        dataField: 'post_to_dt',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          displayFormat: DateFormat,
                        },
                      },
                      {
                        label: {
                          text: t('Post Start Time'),
                        },
                        dataField: 'post_from_tm',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          type: 'time',
                        },
                      },
                      {
                        label: {
                          text: t('Post Close Time'),
                        },
                        dataField: 'post_to_tm',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          type: 'time',
                        },
                      },
                    ],
                  },
                  {
                    itemType: 'group',
                    colSpan: 1,
                    colCount: 2,
                    items: [
                      {
                        label: { text: t('Display Order') },
                        dataField: 'order_no',
                        editorType: 'dxNumberBox',
                        isRequired: true,
                        editorOptions: {
                          format: QtyFormat,
                          min: 1,
                        },
                      },
                      {
                        itemType: 'group',
                        colCount: 2,
                        colSpan: 1,
                        items: [
                          {
                            label: { text: t('Use') },
                            dataField: 'use_tf',
                            editorType: 'dxSwitch',
                          },
                          {
                            label: { text: ' ' },
                            itemType: 'simple',
                            template: `delete_banner`,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]}
          >
            <Template
              name={`InputBannerImage${index}`}
              component={() => (
                <InputFileImage
                  value={dataBinding?.image_url}
                  onChangeValue={data => onSelectedFile(index, data)}
                  id={`InputBannerImage${index}`}
                  isPrevew={true}
                  minWidth={150}
                  minHeight={150}
                  maxWidth={250}
                  maxHeight={250}
                  isShowOrigin={true}
                  t={t}
                />
              )}
            />
            <Template
              name={'delete_banner'}
              component={() => (
                <i
                  onClick={() => {
                    setDeleteIndex(index);
                    setShowConfirmDelete(true);
                  }}
                  className="dx-icon dx-icon-trash"
                  title={t('Delete')}
                  style={{
                    float: 'right',
                    color: '#d9534f',
                    fontSize: 25,
                    cursor: 'pointer',
                  }}
                ></i>
              )}
            />
          </Form>
        </div>
      ))}
      {themePro ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {formData?.length > 2 && (
            <div>
              <Button
                icon={'check'}
                text={t('Save')}
                onClick={() => validateSave()}
                type="default"
                style={{ marginRight: 10 }}
              />
              <Button
                icon={'close'}
                text={t('Cancel')}
                onClick={() => setShowPopupConfirmCancel(true)}
                style={{ marginRight: 10 }}
              />
            </div>
          )}
          <Button
            text={t('Add')}
            icon="add"
            type="default"
            onClick={addBanner}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}
        >
          <div></div>
          {formData?.length > 1 && (
            <div style={{ display: 'flex', gap: 15 }}>
              <Button
                icon={'close'}
                text={t('Cancel')}
                onClick={() => setShowPopupConfirmCancel(true)}
              />
              <Button
                icon={'save'}
                text={t('Save')}
                onClick={() => validateSave()}
              />
            </div>
          )}

          <Button
            text={t('Add')}
            icon="add"
            type="default"
            onClick={addBanner}
          />
        </div>
      )}

      <PopupConfirm
        visible={showPopupConfirmSave}
        content={t('Do you want to save?')}
        onOk={onSave}
        onHiding={() => setShowPopupConfirmSave(false)}
      />
      <PopupConfirm
        visible={showPopupConfirmCancel}
        content={t('Do you want to cancel all changed?')}
        onOk={onCancel}
        onHiding={() => setShowPopupConfirmCancel(false)}
      />
      <PopupConfirmDelete
        visible={showConfirmDelete}
        content={t('Do you want to delete the banner?')}
        onOk={deleteBanner}
        onHiding={() => setShowConfirmDelete(false)}
      />
    </React.Suspense>
  );
};

export default M1043_8;
