/**
 * Update date: 17-05-2023
 * Screen 2013.1
 */
import React, { useState, useRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import moment from 'moment';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { useProductForwardingStandardPrice } from '..';
import { ROW_STATUS } from 'constant';
import { notification } from 'utils/notification';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import { useApp } from 'app';

const exportFileName = `SMDC_2013.1 Product forwarding standard price registration_${moment().format(
  'YYYYMMDD',
)}`;

function List() {
  const { themePro }: any = useApp();
  const rootContext: any = useProductForwardingStandardPrice();
  const { formSearchData, refetchData, dataStore, refetchSaving, t } =
    rootContext || {};
  const [showPopupSaveConfirm, setShowPopupSaveConfirm] =
    useState<boolean>(false);

  const dataGridRef: any = useRef(null);
  const dataGridInstance: any = dataGridRef?.current?.instance;

  const { DateFormat } = useFormatTime();
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();

  /**
   * render status
   *
   * @param {*} record
   * @return {*}
   */
  const renderStatus = (record: any) => {
    switch (record?.value) {
      case ROW_STATUS.INSERTED:
        return '+';
      case ROW_STATUS.UPDATED:
        return 'V';
      case ROW_STATUS.DELETED:
        return '-';
      default:
        return null;
    }
  };

  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowUpdating = e => {
    if (e?.newData) {
      dataStore.update(
        {
          mallCd: e?.oldData?.mallCd,
          productId: e?.oldData?.productId,
          optionId: e?.oldData?.optionId,
        },
        {
          status: ROW_STATUS.UPDATED,
        },
      );
    }
  };

  /**
   * on confirm
   *
   * @return {*}
   */
  const onConfirm = () => {
    if (dataStore?._array?.length === 0) {
      return notification({
        message: t('You need input data into the grid!'),
        type: 'error',
      });
    }
    const dataGridInstance = dataGridRef?.current?.instance;
    if (dataGridInstance?.hasEditData?.()) {
      dataGridInstance?.saveEditData?.().then(() => {
        setShowPopupSaveConfirm(!showPopupSaveConfirm);
      });
    }
    setShowPopupSaveConfirm(!showPopupSaveConfirm);
  };

  /**
   * on save
   *
   */
  const onSaving = async () => {
    const data =
      dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
    const res = await refetchSaving({
      data: {
        data,
      },
    });

    notification({
      res,
    });
    if (res?.data?.status === '200') {
      const searchParams = {
        fromDate: formSearchData?.fromDate
          ? moment(formSearchData?.fromDate).format('YYYY-MM-DD')
          : null,
        toDate: formSearchData?.toDate
          ? moment(formSearchData?.toDate).format('YYYY-MM-DD')
          : null,
        vendorIds: formSearchData.dataList.vendor.map((o: any) => o?.vendorId),
        largeCategoryCodes: formSearchData.dataList.largeCategory.map(
          (o: any) => o?.code,
        ),
        mediumCategoryCodes: formSearchData.dataList.mediumCategory.map(
          (o: any) => o?.code,
        ),
        smallCategoryCodes: formSearchData.dataList.smallCategory.map(
          (o: any) => o?.code,
        ),
        subCategoryCodes: formSearchData.dataList.subCategory.map(
          (o: any) => o?.code,
        ),
        productIds: formSearchData.dataList.product.map(
          (o: any) => o?.productId,
        ),
        productGroupIds: formSearchData.dataList.productGroup.map(
          (o: any) => o?.productGroupId,
        ),
      };
      refetchData({
        data: searchParams,
      });
    }
    setShowPopupSaveConfirm(false);
  };

  const columns: any = [
    {
      caption: t('Receiving'),
      alignment: 'center',
      items: [
        {
          dataField: 'status',
          caption: t('Status'),
          alignment: 'center',
          cellRender: renderStatus,
          width: 70,
          fixedPosition: 'left',
          fixed: true,
          allowHeaderFiltering: false,
          allowFiltering: false,
          allowEditing: false,
        },
        {
          dataField: 'receiveBill',
          caption: t('Bill ID'),
          allowEditing: false,
          fixedPosition: 'left',
          fixed: true,
        },
        {
          dataField: 'receiveDt',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          allowEditing: false,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      items: [
        {
          dataField: 'vendorNm',
          caption: t('Vendor'),
          allowEditing: false,
        },
        {
          dataField: 'productCd',
          caption: t('Product Code'),
          allowEditing: false,
        },
        {
          dataField: 'productNm',
          caption: t('Product Name'),
          allowEditing: false,
        },
        {
          dataField: 'marketableSize',
          caption: t('Marketable Size'),
          allowEditing: false,
        },
        {
          dataField: 'optionCd',
          caption: t('Option Code'),
          allowEditing: false,
        },
        {
          dataField: 'optionNm',
          caption: t('Option'),
          allowEditing: false,
        },
        {
          dataField: 'quantityPerPack',
          caption: t('Quantity Per Pack'),
          format: QtyFormat,
          dataType: 'number',
          allowEditing: false,
        },
        {
          dataField: 'purchaseUnitPrice',
          caption: t('Purchase Unit Price'),
          format: DoubleFormat,
          dataType: 'number',
          allowEditing: false,
        },
        {
          dataField: 'receiveQt',
          caption: t('Qty'),
          format: QtyFormat,
          dataType: 'number',
          allowEditing: false,
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      items: [
        {
          dataField: 'supplyUnitPrice',
          caption: t('Supply Unit Price'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
        },
        {
          dataField: 'standardUnitPrice',
          caption: t('Standard Price'),
          format: AmountFormat,
          dataType: 'number',
          cssClass: 'gridcell-editing',
          editorOptions: {
            step: 0,
          },
          allowEditing: true,
        },
      ],
    },
  ];

  /**
   * export excel
   *
   */
  const onExportExcel = () => {
    dataGridInstance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={dataStore}
        options={{
          keyExpr: ['lineId'],
          onRowUpdating: onRowUpdating,
          editing: {
            mode: 'cell',
            allowUpdating: true,
            selectTextOnEditStart: true,
          },
          selection: {
            mode: 'single',
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                locateInMenu: 'auto',
                location: themePro ? 'before' : 'after',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => onExportExcel(),
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'save',
                  text: t('Save'),
                  onClick: () => onConfirm(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'receiveQt',
                column: 'receiveQt',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
          },
        }}
      />
      {showPopupSaveConfirm && (
        <PopupConfirm
          title={t('Confirm Save')}
          visible={showPopupSaveConfirm}
          content={t('Are you sure to save?')}
          onOk={onSaving}
          onHiding={() => setShowPopupSaveConfirm(false)}
        />
      )}
    </>
  );
}

export default List;
