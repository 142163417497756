/**
 * Update date: 22-05-2023
 * Screen 2016.5
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import ArrayStore from 'devextreme/data/array_store';
import { dateFormatStr } from 'utils/format';
import useFormat from 'hooks/useFormat';

const List = React.lazy(() => import('./List'));
const CreateEditView = React.lazy(() => import('./CreateEditView'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2016_5_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2016_5 = () => {
  const { t }: any = useDictionary({ programId: '2016.5' });
  const [data, setData] = useState([]);
  const { AmountFormat } = useFormat();
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    setpackunpackTp: '',
    dataList: {
      vendor: [],
    },
    vendor: '',
    productName: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'setpackunpack_id',
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      fromDate: dateFormatStr(formSearchData?.fromDate),
      endDate: dateFormatStr(formSearchData?.endDate),
      vendorIds: formSearchData?.dataList.vendor.map((o: any) => o?.vendorId),
      productName: formSearchData?.productName,
      setpackunpackTp: formSearchData?.setpackunpackTp,
    };

    const res = await refetchData({
      url: '/warehouse/product-set-pack-unpack',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const dataMapping = (res?.data?.data || []).map(o => {
        o.setpackunpack_status_nm =
          o?.setpackunpack_status === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setData(dataMapping);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    setData,
    modeView,
    AmountFormat,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    store,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <CreateEditView />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2016_5;
