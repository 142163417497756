/**
 * Update date: 08-06-2023
 * My page component
 */
import { DataGridFull } from 'app/components/DataGrid';
import { LayoutContext } from 'app/components/Layout';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useState, useRef, useContext } from 'react';

const LocalStorage = () => {
  const { t }: any = useDictionary({
    programId: '1031.7',
  });
  const dataGridRef: any = useRef(null);
  const [listData, setListData] = useState([]);
  const [showComfirmDelete, setShowComfirmDelete] = useState<boolean>(false);
  const store: any = new ArrayStore({
    data: listData,
    key: 'name',
  });

  const layoutContext: any = useContext(LayoutContext);
  const { fullMenu } = layoutContext;


  const onDelete = () => {
    const selectAll = store?._array;
    selectAll?.forEach(o => {
      localStorage.removeItem(o?.name);
    });
    setListData([]);
    setShowComfirmDelete(false);
  };

  useEffect(() => {
    const getAllLocalStorageKeys = () => {
      return Object.keys(localStorage);
    };

    const allLocalStorageKeys: any = getAllLocalStorageKeys();

    setListData(
      allLocalStorageKeys
        ?.filter((o: string) => o.includes('storage_inquiry'))
        ?.map((o: string) => {
          const dataSplit: any = o?.split('_');
          return {
            name: o,
            code: dataSplit?.[3],
            user: dataSplit?.[2],
            program:
              (fullMenu || [])?.find(o => o?.programId === dataSplit?.[3])
                ?.programName || '',
          };
        }),
    );
  }, []);

  return (
    <>
      <div className="my-page-caption">
        <div>
          <div className="my-page-caption-title">{t('List Setting Grid')}</div>
        </div>
      </div>
      <DataGridFull
        storageKeyInquiry
        dataSource={store}
        ref={dataGridRef}
        columns={[
          {
            dataField: 'name',
            caption: t('Name'),
          },
          {
            dataField: 'code',
            caption: t('Code'),
            width: 150,
          },
          {
            dataField: 'program',
            caption: t('Program Name'),
          },
          {
            dataField: 'user',
            caption: t('User'),
          },
          {
            dataField: '',
            caption: t('Action'),
            width: 120,
            cellRender: e => {
              return (
                <div
                  onClick={() => {
                    localStorage.removeItem(e?.data?.name);
                    store?.remove(e?.data?.name).done(() => {
                      dataGridRef?.current?.instance?.refresh();
                    });
                  }}
                  className="cell-link"
                  style={{
                    cursor: 'pointer',
                    color: '#00BFDF',
                  }}
                >
                  {t('Delete')}
                </div>
              );
            },
          },
        ]}
        options={{
          columnAutoWidth: true,
          height: 'calc(100vh - 310px)',
          columnChooser: {
            enabled: false,
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete All'),
                  disabled: !store?._array?.length,
                  onClick: () => setShowComfirmDelete(true),
                },
              },
            );
          },
        }}
      />
      {showComfirmDelete && (
        <PopupConfirmDelete
          visible={showComfirmDelete}
          content={t(`Do you want to delete all`)}
          onOk={onDelete}
          onHiding={() => setShowComfirmDelete(false)}
        />
      )}
    </>
  );
};

export default LocalStorage;
