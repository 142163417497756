/**
 * Update date: 24-05-2023
 * Screen 2041.C
 */
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import useLocalStorage from 'hooks/useLocalStorage';
import useDictionary from 'hooks/useDictionary';

const CreateOrder = React.lazy(() => import('./CreateOrder'));
const ListOrder = React.lazy(() => import('./ListOrder'));

const defaultParamsReceive = {
  vendor: '',
  receiveStates: '',
  receivableCd: '',
  vendorType: '',
  zone: '',
};
/**
 * create context
 */
const OrderContext = createContext({
  params: {},
  dataReceive: [] || null,
  setParams: () => {},
  onUpdateListOrder: () => {},
});

OrderContext.displayName = 'OrderContext';

export const useOrder = () => {
  const context = useContext(OrderContext);
  return context;
};

const Order = () => {
  const { t }: any = useDictionary({ programId: '2041.C' });
  const [dataLocal, setDataLocal] = useLocalStorage('2041.C_dataList', {});
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    ...defaultParamsReceive,
    fromDate: new Date(),
    toDate: new Date(),
    formStore: {
      vendor: [],
      receiveStates: [],
      receivableCd: [],
      vendorType: [],
      zone: [],
    },
  });
  const [dataReceive, setDataReceive] = useState<any>([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ loading: loadingPdf }, executeFetchPdf] = useAxios(
    {
      url: `/warehouse/receive/order-report/print`,
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const [{ data: resReceive, loading: loadingReceive }, refetchReceive] =
    useAxios(
      {
        url: '/warehouse/receive',
        method: 'POST',
      },
      { manual: true, autoCancel: true, useCache: false },
    );

  const store: any = new ArrayStore({
    data: dataReceive,
    key: 'receiveId',
  });
  /**
   * on update list order
   *
   * @param {*} data
   * @param {*} type
   */
  const onUpdateListOrder = (data, type) => {
    if (data) {
      data.receiveStatusNm =
        data?.receiveStatus === '0' ? t('Pending') : t('Fixed');
    }
    if (type === 'add') {
      setDataReceive(preState => {
        preState.splice(0, 0, data);
        return preState;
      });
    } else {
      store
        .update(data?.receiveId, data)
        .done(() => {})
        .fail(() => {});
    }
    dataGridRef?.current?.instance?.refresh();
  };

  const value: any = {
    store,
    setModeView,
    modeView,
    params,
    setParams,
    refetchReceive,
    executeFetchPdf,
    onUpdateListOrder,
    dataLocal,
    setDataLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListOrder ref={dataGridRef} />;
      case 'create':
      case 'edit':
      case 'view':
        return <CreateOrder />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resReceive?.data) {
      const dataMapping = (resReceive?.data || []).map(o => {
        o.receiveStatusNm =
          o?.receiveStatus === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setDataReceive(dataMapping);
    }
  }, [resReceive]);

  const loadingAll = loadingReceive || loadingPdf;

  return (
    <OrderContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </OrderContext.Provider>
  );
};

export default Order;
