/**
 * Update date: 29-05-2023
 * Screen 2044.5
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const Reverse = React.lazy(() => import('./Reverse'));
const Detail = React.lazy(() => import('./Detail'));
const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const RegisterReverseShippingContext = createContext({
  reverseShippingListData: [],
});

RegisterReverseShippingContext.displayName = 'RegisterReverseShippingContext';

export const useRegisterReverseShipping = () => {
  const context = useContext(RegisterReverseShippingContext);
  return context;
};

export default function RegisterReverseShipping() {
  const [reverseShippingListData, setReverseShippingListData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    reverseShippingDetail: {},
  });
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    store: '',
    storeGroup: '',
    wayBill: '',
    sendUser: '',
    zone: '',
    location: '',
    complateType: '1',
    formStore: {
      store: [],
      storeGroup: [],
      sendUser: [],
      zone: [],
    },
  });

  // Get data reverse shipping
  const [
    { data: reverseShippingRes, loading: reverseShippingLoading },
    refetchData,
  ] = useAxios(
    {
      url: 'ecommerce/reverse-shipping',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'reverse':
        return <Reverse />;
      case 'detail':
        return <Detail />;
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    modeView,
    setModeView,
    formData,
    setFormData,
    reverseShippingListData,
    setReverseShippingListData,
    refetchData,
  };

  useEffect(() => {
    if (reverseShippingRes?.data) {
      setReverseShippingListData(reverseShippingRes?.data);
    }
  }, [reverseShippingRes]);

  const loadingAll = reverseShippingLoading;

  return (
    <RegisterReverseShippingContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </RegisterReverseShippingContext.Provider>
  );
}
