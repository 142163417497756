/**
 * Update date: 2024-03-29
 * Screen 2043.8
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2043_8_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2043_8 = () => {
  const { t }: any = useDictionary({ programId: '2043.8' });
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const store: any = new ArrayStore({
    data: data,
    key: ['orderId', 'itemId'],
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  const formData = {
    searchKeywordIn: '1',
    keyword: '',
    fromDate: new Date(),
    endDate: new Date(),
    fromTime: new Date(),
    endTime: new Date(),
    fromIsCheck: true,
    endIsCheck: true,
    fromDeliveryDate: null,
    endDeliveryDate: null,
    // default status
    N10: true,
    N20: true,
    N30: true,
    N40: true,
    C40: true,
    return: true,
    exchange: true,
    addressFilterType: '0',
    dataList: {
      mall: [],
      storeGroup: [],
      addressFilter: [],
    },
    mall: '',
    storeGroup: '',
    addressFilter: '',
  };
  const [formSearchData, setFormSearchData] = useState<any>(formData);

  const value: any = {
    t,
    store,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2043_8;
