/**
 * Update date: 05-06-2023
 * Screen 2054.6
 */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import InventoryTable from './TableView';
import useDictionary from 'hooks/useDictionary';
import LoadPanel from 'app/components/LoadPanel';
/**
 * create context
 */
const InverntoryWarehouseContext = createContext({
  tableData: {},
  setLocationData: () => {},
  setZoneData: () => {},
  setCenterData: () => {},
  refetchData: () => {},
  refetchZone: () => {},
  refetchCenter: () => {},
  refetchDelete: () => {},
});

InverntoryWarehouseContext.displayName = 'InquiryInventoryDetailWarehouse';

export const useInventoryContext = () => {
  return useContext(InverntoryWarehouseContext);
};

function InquiryInverntoryDetailWarehouse() {
  const formRef: any = useRef(null);
  const [locationData, setLocationData] = useState<any>([]);
  const [zoneData, setZoneData] = useState<any>([]);
  const [centerData, setCenterData] = useState<any>([]);
  const [viewData, setViewData] = useState<any>([]);

  const { t } = useDictionary({ programId: '2054.6' });

  const [{ data: fetchedData, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/warehouse/inventory-warehouse/location',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  const [{ data: fetchedZone, loading: loadingZone }, refetchZone] = useAxios(
    {
      url: '/warehouse/inventory-warehouse/zone',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  const [{ data: fetchedCenter, loading: loadingCenter }, refetchCenter] =
    useAxios(
      {
        url: '/warehouse/inventory-warehouse/center',
        method: 'POST',
      },
      { manual: true, autoCancel: true, useCache: false },
    );
  const [{ data: fetchedView, loading: loadingView }, refetchView] = useAxios(
    {
      url: '/warehouse/inventory-warehouse/view-detail',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ loading: deleteLoading }, refetchDelete] = useAxios(
    {
      url: '/warehouse/inventory-warehouse',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingData) {
      if (fetchedData) {
        setLocationData(fetchedData?.data);
      }
    }
  }, [fetchedData, loadingData]);

  useEffect(() => {
    if (!loadingZone) {
      if (fetchedZone) {
        setZoneData(fetchedZone?.data);
      }
    }
  }, [fetchedZone, loadingZone]);

  useEffect(() => {
    if (!loadingCenter) {
      if (fetchedCenter) {
        setCenterData(fetchedCenter?.data);
      }
    }
  }, [fetchedCenter, loadingCenter]);

  useEffect(() => {
    if (!loadingView) {
      if (fetchedView) {
        setViewData(fetchedView?.data);
      }
    }
  }, [fetchedView, loadingView]);

  const value: any = {
    t,
    locationData,
    zoneData,
    centerData,
    setLocationData,
    setZoneData,
    setCenterData,
    refetchData,
    refetchZone,
    refetchCenter,
    refetchDelete,
    viewData,
    setViewData,
    refetchView,
    formRef,
  };

  return (
    <>
      <InverntoryWarehouseContext.Provider value={value}>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel visible={loadingData || deleteLoading || loadingView} />
          <FormSearch ref={formRef} />
          <InventoryTable />
        </React.Suspense>
      </InverntoryWarehouseContext.Provider>
    </>
  );
}

export default InquiryInverntoryDetailWarehouse;
