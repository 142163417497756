/**
 * Update date: 25-05-2023
 * Screen 2041.7
 */
import { DataGridFull } from 'app/components/DataGrid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import React, { useCallback, useRef } from 'react';
import { useReceiving } from '..';

function ListReceiving(props) {
  const context: any = useReceiving();
  const { store, t } = context;
  const dataGridRef: any = useRef(null);
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { QtyFormat, AmountFormat, DoubleFormat, GetExcelFormat } = useFormat();

  const columns: any = [
    {
      dataField: 'taskTypeName',
      caption: t('Task Type'),
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveBill',
      caption: t('Bill ID'),
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveDate',
      caption: t('Date'),
      dataType: 'date',
      format: DateFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'vendorName',
      caption: t('Vendor'),
      showWhenGrouped: true,
    },
    {
      dataField: 'zoneName',
      caption: t('Zone'),
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveTakeoutTpNm',
      caption: t('Receive Data Owner'),
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveStateName',
      caption: t('State'),
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveStatusName',
      caption: t('Status'),
      showWhenGrouped: true,
    },
    {
      dataField: 'locationNm',
      caption: t('Location'),
      alignment: 'left',
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      showWhenGrouped: true,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      showWhenGrouped: true,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      showWhenGrouped: true,
    },
    {
      dataField: 'optionCode',
      caption: t('Option Code'),
      showWhenGrouped: true,
    },
    {
      dataField: 'option',
      caption: t('Option'),
      showWhenGrouped: true,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      showWhenGrouped: true,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'purchaseUnitPrice',
      caption: t('Purchase Unit Price'),
      format: DoubleFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveQuantity',
      caption: t('Quantity'),
      format: QtyFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveEa',
      caption: t('EA'),
      format: QtyFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'receiveAmount',
      caption: t('Amount'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'supplyAmount',
      caption: t('Supply Amount'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'vatAmount',
      caption: t('VAT'),
      showWhenGrouped: true,
      format: AmountFormat,
      dataType: 'number',
    },
    {
      dataField: 'bottleAmount',
      caption: t('Bottle'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'containerAmount',
      caption: t('Container'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'totalAmount',
      caption: t('G. Total'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'masterNote',
      caption: t('Bill Note'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'note',
      caption: t('Line Note'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: '',
      caption: t('Receive Due Bill ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'bottleId',
      caption: t('Bottle ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'bottleUnitPrice',
      caption: t('Bottle Unit Price'),
      format: AmountFormat,
      dataType: 'number',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'optionContainerId',
      caption: t('Container ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'optionContainerUnitPrice',
      caption: t('Container Unit Price'),
      format: AmountFormat,
      dataType: 'number',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'markerName',
      caption: t('Maker'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'originName',
      caption: t('Origin'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'defaultVendor',
      caption: t('Default Vendor'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'largeCategory',
      caption: t('Large category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'mediumCategory',
      caption: t('Medium category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'smallCategory',
      caption: t('Small category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'subCategory',
      caption: t('Sub category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'createdDate',
      caption: t('Register Date'),
      dataType: 'datetime',
      format: DateTimeFormat,
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'registerUser',
      caption: t('Register User'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'registerProgram',
      caption: t('Register Program ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifiedDate',
      caption: t('Modify Date'),
      dataType: 'datetime',
      format: DateTimeFormat,
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifyUser',
      caption: t('Modify User'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifyProgramCd',
      caption: t('Modify Program ID'),
      visible: false,
      showWhenGrouped: true,
    },
  ];

  // Execute export excel
  const onExporting = useCallback(() => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const dataGrid = dataGridRef?.current.instance;
    exportDataGrid({
      component: dataGrid,
      worksheet: worksheet,
      customizeCell: function (option: any) {
        const { gridCell, excelCell } = option;
        if (gridCell.column.dataType === 'number') {
          if (gridCell.rowType === 'group' && gridCell.groupSummaryItems) {
            excelCell.value = gridCell.groupSummaryItems[0].value;
            excelCell.numFmt = GetExcelFormat(gridCell.column.format);
          }
          if (
            gridCell.rowType === 'groupFooter' ||
            gridCell.rowType === 'totalFooter'
          ) {
            excelCell.value = gridCell.value;
            excelCell.numFmt = GetExcelFormat(gridCell.column.format);
          }
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `SMDC_2041.7_Receiving Inquiry Amount_${moment().format(
            'YYYYMMDD',
          )}.xlsx`,
        );
      });
    });
  }, []);

  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  return (
    <DataGridFull
      columns={columns}
      ref={dataGridRef}
      dataSource={store}
      storageKeyInquiry
      options={{
        onCellPrepared: e => {
          if (e.rowType === 'data' && e?.data?.taskType === 2) {
            e.cellElement.style.color = 'red';
            e.cellElement.value = 'red';
          }
        },
        onExporting: onExporting,
        groupPanel: {
          visible: true,
        },
        key: 'lineId',
        columnAutoWidth: true,
        onToolbarPreparing: (e: any) => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'totalCount',
            },
            {
              location: 'before',
              widget: 'dxButton',
              options: {
                icon: 'xlsxfile',
                text: t('Export to Excel'),
                onClick: () => exportExcel(),
              },
            },
          );
        },
        summary: {
          totalItems: [
            {
              name: 'receiveQuantity',
              column: 'receiveQuantity',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
            {
              name: 'receiveEa',
              column: 'receiveEa',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
            {
              name: 'SumAmount',
              column: 'receiveAmount',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
            {
              name: 'SupplyAmount',
              column: 'supplyAmount',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
            {
              name: 'VatAmount',
              column: 'vatAmount',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
            {
              name: 'Bottle',
              column: 'bottleAmount',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
            {
              name: 'Container',
              column: 'containerAmount',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
            {
              name: 'TotalAmount',
              column: 'totalAmount',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
              summaryType: 'sum',
            },
          ],
          groupItems: [
            {
              summaryType: 'count',
              displayFormat: '{0}',
            },
            {
              column: 'receiveEa',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
            {
              column: 'receiveQuantity',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: QtyFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
            {
              column: 'receiveAmount',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
            {
              column: 'supplyAmount',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
            {
              column: 'vatAmount',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
            {
              column: 'bottleAmount',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
            {
              column: 'containerAmount',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
            {
              column: 'totalAmount',
              summaryType: 'sum',
              alignByColumn: true,
              showInGroupFooter: false,
              valueFormat: AmountFormat,
              displayFormat: '{0}',
              dataType: 'number',
            },
          ],
        },
      }}
    />
  );
}

export default ListReceiving;
