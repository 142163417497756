import React from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import { useCenterReturn } from '..';
import useDictionary from 'hooks/useDictionary';
const FormSearch = () => {
  const context: any = useCenterReturn();
  const { params, refetchData } = context;
  const { t }: any = useDictionary({
    programId: '2046.7',
  });
  /**
   * on submit
   *
   */
  const onSubmit = () => {
    refetchData({
      data: params,
    });
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              label: {
                text: t('Return Control'),
              },
              dataField: 'returnControlName',
              editorOptions: {
                onEnterKey: onSubmit,
              },
            },
          ]}
          labelLocation="top"
          colCount={4}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
    </div>
  );
};

export default FormSearch;
