import moment from 'moment';

interface DateFormat {
  date: any;
  type?: 'date' | 'datetime';
}

const getNumberFormat = () => {
  return '#,###';
};

const getSumFormat = amountPoint => {
  if (!amountPoint) return '#,###';
  let str = new Array(amountPoint).fill('#').join('');
  return `#,###.${str}`;
};

const getSumTotalFormat = amountPoint => {
  if (!amountPoint) return '#,##0';
  const str = new Array(amountPoint).fill('#').join('');
  return `#,##0.${str}`;
};

const dateFormat = ({ date, type = 'date' }: DateFormat) => {
  const isDate = moment(date).isValid();
  if (!isDate) return '';
  return moment(date)
    .utc(true)
    .format(`YYYY-MM-DD${type === 'datetime' ? ' HH:mm:ss' : ''}`);
};

// convert date time to string that it not change current date
const dateFormatStr = (date, format = 'YYYY-MM-DD') => {
  if (!date) return null;
  const isDate = moment(date).isValid();
  if (!isDate) return null;
  return moment(date).utc(true).format(format);
};

// convert date time to string that it minus time zone
const dateFormatStrUTC = (date, format = 'YYYY-MM-DD') => {
  if (!date) return null;
  const isDate = moment(date).isValid();
  if (!isDate) return null;
  return moment(date).utc(false).format(format);
};

// set time zone != 0 to = 0 that it is not change the current date time
const dateTimeRequestFormat = date => {
  if (!date) return null;
  const isDate = moment(date).isValid();
  if (!isDate) return null;
  return moment(date).utc(true);
};

// convert date time to date time that time zone +0
const dateTimeFormatTimeZoneUTC = date => {
  if (!date) return null;
  const isDate = moment(date).isValid();
  if (!isDate) return null;
  return moment(date).utc(false);
};

// convert string to date
const stringToDate = dateStr => {
  if (!dateStr) return null;
  const isDate = moment(dateStr).isValid();
  if (!isDate) return null;
  return moment(dateStr, 'YYYY-MM-DD');
};

const compareDate = (
  date1: Date | moment.Moment | null,
  date2: Date | moment.Moment | null,
) => {
  if (!date1 && !date2) return 0;
  if (!date1 && date2) return -1;
  if (date1 && !date2) return 1;
  const date1Str = moment(date1).format('YYYYMMDD');
  const date2Str = moment(date2).format('YYYYMMDD');

  if (date1Str > date2Str) return 1;
  if (date1Str === date2Str) return 0;
  if (date1Str < date2Str) return -1;
};

export {
  getNumberFormat,
  getSumFormat,
  getSumTotalFormat,
  dateFormat,
  dateTimeRequestFormat,
  dateFormatStr,
  dateFormatStrUTC,
  dateTimeFormatTimeZoneUTC,
  stringToDate,
  compareDate,
};
