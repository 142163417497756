/**
 * Update date: 23-05-2023
 * Screen 2032.5
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { useProductType } from 'hooks/useProductType';

const List = React.lazy(() => import('./List'));

const defaultParams = {};
/**
 * create context
 */
const PrintDeliveryStatementContext = createContext({});

PrintDeliveryStatementContext.displayName = 'RegularOrderContext';

export const usePrintDeliveryStatement = () => {
  const context = useContext(PrintDeliveryStatementContext);
  return context;
};

const PrintDeliveryStatement = () => {
  const [params, setParams] = useState({
    ...defaultParams,
    fromDate: new Date(),
    endDate: new Date(),
  });
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  const [{ data: resData, loading: loadingfetchData }, refetchData] = useAxios(
    {
      url: '/warehouse/delivery-statement',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    modeView,
    setModeView,
    params,
    setParams,
    data,
    refetchData,
    productTpPermission,
  };

  /**
   * render content
   *
   * @return {*} 
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  useEffect(() => {
    if (resData?.data) {
      setData(resData?.data);
    }
  }, [resData]);

  const loadingAll = loadingfetchData || loadingProductTpPermission;

  return (
    <PrintDeliveryStatementContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </PrintDeliveryStatementContext.Provider>
  );
};

export default PrintDeliveryStatement;
