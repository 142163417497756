/**
 * Update date: 12-05-2023
 * Screen 1042.4
 */

import React, { useState, useRef, useEffect } from 'react';
import Form from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';

interface FormData {
  code: string;
  name: String;
  note: String;
  use: Boolean;
  system: Boolean;
  orders: null | number;
}

const initialFormData: FormData = {
  code: '',
  name: '',
  note: '',
  use: true,
  system: false,
  orders: 0,
};

interface IPopupRegisteringCommonCode {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  coreMenu?: any;
  onSubmit: (selected: any) => void;
}

const PopupRegisteringCommonCode = ({
  visible,
  onHiding,
  text,
  coreMenu = null,
  onSubmit,
}: IPopupRegisteringCommonCode) => {
  const { themePro }: any = useApp();
  const [FormData, setFormData] = useState<FormData>({ ...initialFormData });
  const formRef: any = useRef(null);
  const { t } = useDictionary({
    programId: '1042.4',
  });

  /**
   * on create
   *
   */
  const onCreateSave = () => {
    onCreate();
  };
  const onCreate = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid) {
      const data = !coreMenu
        ? {
            code: FormData.code,
            name: FormData.name,
            use: FormData.use,
            note: FormData.note,
            system: false,
            orders: 0,
          }
        : {
            codeType: FormData.code,
            codeTypeNm: FormData.name,
            taskId: coreMenu?.taskId,
            taskName: coreMenu?.taskName,
          };
      onSubmit(data);
    }
  };

  /**
   * fetch data
   */
  useEffect(() => {
    if (coreMenu) {
      setFormData({ ...coreMenu });
    }
  }, [coreMenu]);
  /**
   * render content
   */
  const content = () => (
    <div>
      {themePro && (
        <div className="my-page-change-email-header">
          <span className="title-page">{t('Common Code Registration')}</span>
          <div>
            <Button
              stylingMode="contained"
              type="default"
              text={t('Save')}
              icon="check"
              style={{ marginRight: 5 }}
              onClick={onCreateSave}
            />
            <Button
              stylingMode="contained"
              text={t('Cancel')}
              icon="close"
              onClick={onHiding}
            />
          </div>
        </div>
      )}
      <form action="" onSubmit={onCreate}>
        <Form
          formData={FormData}
          showColonAfterLabel={false}
          labelLocation="top"
          ref={formRef}
          className="bg-white p-20"
          items={[
            {
              dataField: 'code',
              editorType: 'dxTextBox',
              label: {
                text: t('Code'),
              },
              isRequired: true,
            },
            {
              dataField: 'name',
              editorType: 'dxTextBox',
              label: {
                text: t('Code Name'),
              },
              isRequired: true,
            },
            {
              dataField: 'use',
              editorType: 'dxSwitch',
              label: {
                text: t('Use'),
              },
              visible: !coreMenu,
            },
            {
              dataField: 'note',
              editorType: 'dxTextArea',
              label: {
                text: t('Note'),
              },
              visible: !coreMenu,
            },
          ]}
        ></Form>
        {!themePro && (
          <div
            style={{
              paddingTop: '30px',
              alignContent: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            className="button-wrap-content"
          >
            <div style={{ marginRight: '50px' }}>
              <Button
                width={120}
                text={t('Save')}
                icon="save"
                onClick={onCreateSave}
              />
            </div>
            <Button
              width={120}
              text={t('Cancel')}
              icon="remove"
              type="normal"
              onClick={onHiding}
            />
          </div>
        )}
      </form>
    </div>
  );
  return (
    <Popup
      visible={visible}
      className="modal-content-popup"
      onHiding={onHiding}
      contentRender={content}
      title={themePro ? '' : t(text || t('Common Code Registration'))}
      height={themePro ? '100vh' : 'auto'}
      width={themePro ? 700 : '50vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
};
export default PopupRegisteringCommonCode;
