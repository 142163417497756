/**
 * Update date: 2023-06-07
 * Screen 2053.2
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Form, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';

interface IChangeProductMinOrder {
  onOk: any;
  onClose: () => void;
  data: any;
  qtyFormat: any;
}

/**
 *  Update Product Min Order
 *
 * @param {IChangeProductMinOrder} {
 *   onOk,
 *   onClose,
 *   data,
 *   qtyFormat
 * }
 * @return {*}
 */
function ChangeProductMinOrder({
  onOk,
  onClose,
  data,
  qtyFormat,
}: IChangeProductMinOrder) {
  const { t }: any = useDictionary({ programId: '2053.2' });
  const formRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({
    minOrder: 0,
  });

  const [{ loading }, refetchData] = useAxios(
    {
      url: '/product/product/update/product-min-order',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * fetch data
   */
  useEffect(() => {
    if (!data) return;
    setFormData({ minOrder: data?.min_order || 0 });
    setTimeout(function () {
      formRef?.current?.instance?.getEditor('minOrder')?.focus?.();
    }, 600);
  }, [data]);

  const updateMinOrder = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const res = await refetchData({
      data: { productId: data?.product_id, minOrder: formData?.minOrder },
    });
    notification({ res });
    if (res?.data?.status === '201') {
      onOk(formData?.minOrder);
    }
  };

  const content = () => {
    return (
      <div>
        <div
          style={{
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: 500,
            marginBottom: 10,
          }}
        >
          {t('Input Minimum Order')}!
        </div>
        <Form
          labelLocation="top"
          showColonAfterLabel={false}
          ref={formRef}
          formData={formData}
          items={[
            {
              label: { text: t('Minimum Order') },
              dataField: 'minOrder',
              editorType: 'dxNumberBox',
              isRequired: true,
              editorOptions: {
                format: qtyFormat,
                step: 0,
                min: 0,
                onEnterKey: () => updateMinOrder(),
                onFocusIn: e => {
                  const inputEl = e?.element?.querySelector?.(
                    '.dx-texteditor-input',
                  );
                  inputEl.selectionStart = 0;
                  inputEl.selectionEnd = inputEl?.value?.length;
                },
              },
            },
            {
              itemType: 'group',
              colSpan: 2,
              colCount: 2,
              items: [
                {
                  itemType: 'button',
                  buttonOptions: {
                    text: t('Cancel'),
                    type: 'normal',
                    width: '100%',
                    onClick: onClose,
                  },
                },
                {
                  itemType: 'button',
                  buttonOptions: {
                    text: t('Save'),
                    type: 'default',
                    width: '100%',
                    onClick: () => updateMinOrder(),
                  },
                },
              ],
            },
          ]}
        />
        <LoadPanel visible={loading} />
      </div>
    );
  };

  return (
    <Popup
      visible={true}
      contentRender={content}
      height={190}
      width={330}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default ChangeProductMinOrder;
