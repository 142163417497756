/**
 * Update date: 19-05-2023
 * Screen 2014.2
 */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import InventoryTable from './TableView';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const Register = React.lazy(() => import('./Register'));
/**
 * create context
 */
const HUBBarcodeRegistrationContext = createContext({
  dataList: {},
  refetchData: () => {},
});

HUBBarcodeRegistrationContext.displayName = 'HUBBarcodeRegistrationContext';

export const useStandardBarcodeContext = () => {
  return useContext(HUBBarcodeRegistrationContext);
};

function HUBStandardBarcodeRegistration() {
  const [dataList, setDataList] = useState<any>([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    standardProductCode: '',
  });

  const dataGridRef: any = useRef(null);

  const barcodeListStore: any = new ArrayStore({
    data: dataList,
    key: 'standardProductCode',
  });

  const [{ data: fetchedData, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/product/hub-barcode-registration',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <InventoryTable ref={dataGridRef} />;
      case 'register':
        return <Register />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * on update data
   *
   * @param {*} standardProductCode
   */
  const onUpdateData = standardProductCode => {
    barcodeListStore
      .remove(standardProductCode)
      .done(() => {})
      .fail(() => {});

    dataGridRef?.current?.instance?.refresh();
  };

  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingData) {
      if (fetchedData) {
        setDataList(fetchedData?.data);
      }
    }
  }, [fetchedData, loadingData]);

  const value: any = {
    barcodeListStore,
    modeView,
    setModeView,
    refetchData,
    onUpdateData,
  };

  return (
    <>
      <HUBBarcodeRegistrationContext.Provider value={value}>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel visible={loadingData} />
          {renderContent()}
        </React.Suspense>
      </HUBBarcodeRegistrationContext.Provider>
    </>
  );
}

export default HUBStandardBarcodeRegistration;
