/**
 * Update date: 08-06-2023
 * My page component
 */
import LoadPanel from 'app/components/LoadPanel';
import { Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';
import AccountDetail from './AccountDetail';
import PasswordSecurity from './PasswordSecurity';
import Preference from './Preference';
import SettingList from './SettingList/index';
import './style.scss';

interface PopupSelectMultiProps {
  visible?: boolean;
  onHiding?: () => void;
}
/**
 * create context
 */
const MyPageContext = createContext({
  modeView: '',
});

MyPageContext.displayName = 'MyPageContext';

export const useMyPage = () => {
  const context = useContext(MyPageContext);
  return context;
};

const MyPagePopup = (props: PopupSelectMultiProps) => {
  const { t }: any = useDictionary({
    programId: '1031.7',
  });
  const { visible, onHiding } = props;
  const [modeView, setModeView] = useState({
    type: 'list',
    editFormType: '',
  });

  /**
   * render edit form
   *
   * @return {*}
   */
  const renderEditForm = () => {
    switch (modeView?.editFormType) {
      case 'accountDetail':
        return <AccountDetail />;
      case 'preference':
        return <Preference />;
      case 'password':
        return <PasswordSecurity />;
      default:
        break;
    }
    setModeView(preState => ({ ...preState, type: 'list' }));
  };

  const value: any = {
    setModeView,
    modeView,
    onHiding,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <>
        <div className="my-page-content-wrap">
          {/* <LoadPanel visible={loading} /> */}
          {/* <div className="last-login-txt">Last login: </div> */}
          <div>
            {modeView?.type === 'list' && <SettingList />}
            {modeView?.type === 'edit' && renderEditForm()}
          </div>
        </div>
      </>
    );
  };

  return (
    <MyPageContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <Popup
          visible={visible}
          className="my-page-popup"
          onHiding={onHiding}
          title={t('My Page')}
          contentRender={content}
          height="80vh"
          width="60vw"
          position={{
            boundaryOffset: { x: undefined, y: 50 },
          }}
        />
      </React.Suspense>
    </MyPageContext.Provider>
  );
};

export default MyPagePopup;
