/*
 * 2082.3 Vendor withdrawal registration
 * @since 2022/10/20
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));
const Withdrawal = React.lazy(() => import('./Withdrawal'));

// Create context
const VendorWithDrawalRegisContext = createContext({});
VendorWithDrawalRegisContext.displayName = 'VendorWithDrawalRegisContext';
export const useVendorWithDrawalRegis = () => {
  const context = useContext(VendorWithDrawalRegisContext);
  return context;
};

/**
 * Employee/Daily Work/Off-Work Registration
 *
 * @export
 * @return {*}
 */
export default function VendorWithDrawalRegis() {
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
  });

  const [listData, setListData] = useState([]);
  const [detailData, setDetailData] = useState(null);

  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      vendor: [],
      vendorType: [],
    },
    vendor: '',
    vendorType: '',
  });

  const { t }: any = useDictionary({
    programId: '2082.3',
  });

  // Api Get data
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'warehouse/vendor-withdrawal',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  //Api create or update
  const [{ loading: loadingCreate }, refetchCreate] = useAxios(
    {
      url: 'warehouse/vendor-withdrawal',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );
  // Api delete
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: 'warehouse/vendor-withdrawal',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );
  // Api get detail
  const [{ data: dataDetail, loading: loadingDetail }, refetchDetail] =
    useAxios(
      {
        url: 'warehouse/vendor-withdrawal/detail',
        method: 'GET',
      },
      { manual: true, autoCancel: true },
    );

  const store: any = new ArrayStore({
    data: listData || [],
    key: ['line_id', 'payment_dt'],
  });

  /**
   * On search data
   *
   */
  const onSearch = () => {
    const searchParams = {
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      vendorIds: formData?.dataList.vendor.map((o: any) => o?.vendorId),
      vendorTypes: formData?.dataList.vendorType.map((o: any) => o?.code),
    };
    refetchData({
      data: {
        ...searchParams,
      },
    });
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <Create />;
      case 'withdrawal':
        return <Withdrawal />;
      default:
        return <NotFoundPage />;
    }
  };

  useEffect(() => {
    if (dataList?.data && !loadingList) {
      setListData(dataList?.data);
    }
  }, [dataList, loadingList]);
  useEffect(() => {
    if (dataDetail?.data && !loadingDetail) {
      setDetailData(dataDetail?.data);
    }
  }, [dataDetail, loadingDetail]);

  // Values share with child component
  const value: any = {
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    refetchCreate,
    refetchDelete,
    refetchDetail,
    formData,
    onSearch,
    setFormData,
    detailData,
    setDetailData,
    setListData,
  };

  const loadingAll =
    loadingList || loadingCreate || loadingDelete || loadingDetail;

  return (
    <VendorWithDrawalRegisContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </VendorWithDrawalRegisContext.Provider>
  );
}
