/**
 * Update date: 23-05-2023
 * Screen 2031.1
 */
import React, { useRef, useState, useEffect } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useFormatTime from 'hooks/useFormatTime';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useOrderDetail } from '..';
import useDictionary from 'hooks/useDictionary';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import ArrayStore from 'devextreme/data/array_store';
import { notification } from 'utils/notification';
import { useApp } from 'app';

function OrderReceiptTable() {
  const { themePro }: any = useApp();
  const { DateFormat } = useFormatTime();
  const { AmountFormat, QtyFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useOrderDetail();
  const {
    orderData,
    setSelectedRow,
    refetchUpdate,
    setShowPopup,
    updateData,
    loadingUpate,
  } = context;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [dataParam, setDataParam] = useState<any>([]);
  const [btnSingle, setBtnSingle] = useState<boolean>(false);
  const [isUpdate, setIsUPdate] = useState<boolean>(false);

  const store: any = new ArrayStore({
    data: orderData,
    key: 'ctOrderBill',
  });

  const { t }: any = useDictionary({ programId: '2031.1' });

  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    return (
      <div>
        <span
          className="cell-link"
          onClick={() => handleTakeover(record?.data)}
        >
          {t('Take Over')}
        </span>
        <span>{' | '}</span>
        <span className="cell-link" onClick={() => handleView(record?.data)}>
          {t('View')}
        </span>
      </div>
    );
  };

  /**
   * fetch data
   *
   * @param {*} record
   */
  const handleView = (record: any) => {
    const data = {
      ctOrderBill: record?.ctOrderBill,
      ctOrderDt: record?.ctOrderDt,
      vendorId: record?.vendorId,
      ctOrderState: record?.ctOrderState,
      currencyTp: record?.currencyTp,
      storeNm: record?.storeNm,
      deliverDueDate: record?.deliverDueDate,
      ctOrderMstNt: record?.ctOrderMstNt,
      vendorNm: record?.vendorNm,
    };
    setShowPopup(true);
    setSelectedRow(data);
  };

  /**
   * on take over
   *
   * @param {*} record
   */
  const handleTakeover = (record: any) => {
    setBtnSingle(false);
    setDataParam(record);
    setMessage(t('Confirm to mark the selected record(s) as taken over?'));
    setShowConfirm(true);
  };

  /**
   * on multi take over
   *
   * @param {*} e
   * @return {*}
   */
  const handleMultiTakeOver = e => {
    const affectedData: any =
      dataGridRef?.current?.instance?.option?.()?.selectedRowKeys; //billId

    if (!(affectedData.length >= 1)) {
      return;
    }

    setBtnSingle(true);
    setDataParam(affectedData);
    setMessage(t('Confirm to mark the selected record(s) as taken over?'));
    setShowConfirm(true);
  };

  /**
   * on update
   *
   */
  const onUpdate = () => {
    if (btnSingle) {
      const data = {
        billIds: dataParam,
      };
      refetchUpdate({
        data,
      });
    } else {
      const data = {
        billIds: [dataParam?.ctOrderBill] || [],
      };
      refetchUpdate({
        data,
      });
    }
    setIsUPdate(true);
    setShowConfirm(false);
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (isUpdate) {
      if (updateData && !loadingUpate) {
        if (updateData?.status == '201') {
          notification({
            res: updateData,
            message: t('Update Successfully!'),
            type: 'success',
          });
          if (btnSingle) {
            dataParam?.forEach(updatedOrd => {
              store
                .remove(updatedOrd)
                .done(() => store.load())
                .fail(() => {});
            });
          } else {
            store
              .remove(dataParam?.ctOrderBill)
              .done(() => store.load())
              .fail(() => {});
          }
        } else {
          notification({
            res: updateData,
            message: t(updateData.message),
            type: 'error',
          });
        }
      }
      setIsUPdate(false);
    }
  }, [updateData, loadingUpate]);

  /**
   * render order type
   *
   * @param {*} e
   * @return {*}
   */
  const renderOrderType = e => {
    return t(e?.value);
  };

  const columns: any = [
    {
      dataField: 'ctOrderId',
      caption: t('ID'),
      showWhenGrouped: true,
      alignment: 'left',
    },
    {
      dataField: 'ctOrderBill',
      caption: t('Bill ID'),
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderDt',
      caption: t('Date'),
      dataType: 'date',
      format: DateFormat,
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderTp',
      caption: t('Order Type'),
      showWhenGrouped: true,
      alignment: 'left',
      cellRender: renderOrderType,
    },
    {
      dataField: 'storeNm',
      caption: t('Store'),
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderState',
      caption: t('Order State'),
      alignment: 'left',
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderEa',
      caption: t('Sum(EA)'),
      dataType: 'number',
      format: QtyFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderAmount',
      caption: t('Sum Amount'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'supplyAmount',
      caption: t('Supply Amount'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'vatAmount',
      caption: t('VAT'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'bottleAmount',
      caption: t('Bottle'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'containerAmount',
      caption: t('Container'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'totalAmount',
      caption: t('G.Total'),
      dataType: 'number',
      format: AmountFormat,
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderMstNt',
      caption: t('Bill Note'),
      showWhenGrouped: true,
    },
    {
      caption: t('Action'),
      alignment: themePro ? 'left' : 'center',
      fixedPosition: 'right',
      fixed: true,
      cellRender: renderAction,
    },
  ];

  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName =
    'SMDC_2031.1 Order Inquiry Receipt Report ' + moment().format('YYYYMMDD');

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={store}
        options={{
          key: 'ordbillid',
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
          },
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: themePro ? 'file' : 'xlsxfile',
                  text: t('Take Over'),
                  disabled: !orderData,
                  onClick: handleMultiTakeOver,
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                column: 'ctOrderEa',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'ctOrderAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'supplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'vatAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'bottleAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'containerAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'totalAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'ctOrderEa',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'ctOrderAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'supplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'vatAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'bottleAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'containerAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'totalAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
      <PopupConfirm
        visible={showConfirm}
        content={message}
        onOk={onUpdate}
        onHiding={() => setShowConfirm(false)}
      />
    </>
  );
}

export default OrderReceiptTable;
