/**
 * Update date: 19-05-2023
 * Screen 2014.2
 */
import React, { forwardRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import { useStandardBarcodeContext } from '../index';
import FormSearch from '../FormSearch';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { useApp } from 'app';
import moment from 'moment';

function InventoryTable(props, ref) {
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({});

  const { barcodeListStore, setModeView }: any = useStandardBarcodeContext();

  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    const { standardProductCode } = record?.data || {};
    return (
      <div
        onClick={() => {
          setModeView({ type: 'register', standardProductCode });
        }}
        className="cell-link"
      >
        {t('Register')}
      </div>
    );
  };

  const columns: any = [
    {
      dataField: 'standardProductCode',
      caption: t('Standard Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'productName',
      caption: t('Product'),
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
    },
    {
      dataField: 'brandCodeName',
      caption: t('Brand Name'),
    },
    {
      dataField: 'typeCodeName',
      caption: t('Type'),
    },
    {
      dataField: 'alcoholTypeCodeName',
      caption: t('Alcohol Type'),
    },
    {
      dataField: 'originCodeName',
      caption: t('Origin'),
    },
    {
      dataField: 'bottleCodeName',
      caption: t('Bottle'),
    },
    {
      dataField: 'category',
      caption: t('Category'),
    },
    {
      caption: t('Action'),
      alignment: themePro ? 'left' : 'center',
      fixedPosition: 'right',
      fixed: true,
      cellRender: renderAction,
    },
  ];

  /**
   * export excel
   *
   */
  const exportExcel = () => {
    ref?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <BreadCrumbPremium />
      <FormSearch />
      <DataGridFull
        ref={ref}
        dataSource={barcodeListStore}
        columns={columns}
        options={{
          hiddenDetailProduct: true,
          keyExpr: 'standardProductCode',
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          groupPanel: {
            visible: true,
          },
          export: {
            fileName: `SMDC_20417_2046.9 Center Forwarding Return Inquiry_${moment().format(
              'YYYYMMDD',
            )}`,
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !barcodeListStore?._array?.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
        }}
      />
    </>
  );
}

export default forwardRef(InventoryTable);
