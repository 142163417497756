/**
 * Update date: 2023-10-09
 * Screen 1044.8
 */
import React, { useRef, useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { useModuleContext } from '..';
import { cloneDeep, uniqBy } from 'lodash';
import { notification } from 'utils/notification';
import PopupSelectStoreGroup from 'app/components/PopupCommon/PopupSelectStoreGroup';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  storeType: {
    codeType: 'ST',
  },
};

const FormSearch = () => {
  const context: any = useModuleContext();
  const { t, params, setParams, setListData, refetchData } = context;
  const formRef: any = useRef(null);
  const [showPopupByType, setShowPopupByType] = useState('');

  /**
   * Onsubmit form search
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const dataRequest = {
      storeName: params?.storeName,
      use: params?.use,
      representative: params?.representative,
      storeType: params?.dataList?.storeType?.map?.((o: any) => o?.code),
      storeGroupIds: params?.dataList?.storeGroup?.map?.(
        (o: any) => o.storeGroupId,
      ),
    };
    const res = await refetchData({
      url: '/user/m1044_8',
      method: 'POST',
      data: dataRequest,
    });
    if (res?.data?.status === '200') {
      setListData(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(params?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    const newData = {
      ...params,
      dataList: dataList,
    };
    setParams(newData);
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(params?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...params,
      dataList: dataList,
      [storedType]: '',
    };
    setParams(newData);
    setShowPopupByType('');
  };
  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'storeType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={params?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      case 'storeGroup':
        return (
          <PopupSelectStoreGroup
            text={params?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('storeGroup', o, 'storeGroupId')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = params?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          ref={formRef}
          items={[
            {
              label: {
                text: t('Store Name'),
              },
              dataField: 'storeName',
              editorType: 'dxTextBox',
            },
            generateDxTextBox('Store Type', 'storeType', 'name'),
            generateDxTextBox('Store Group', 'storeGroup', 'storeGroupName'),
            {
              label: { text: t('Use') },
              dataField: 'use',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                items: [
                  { label: t('All'), value: null },
                  { label: t('Use'), value: true },
                  { label: t('Not Use'), value: false },
                ],
              },
            },
            {
              label: {
                text: t('Representative'),
              },
              dataField: 'representative',
              editorType: 'dxTextBox',
            },
          ]}
          labelLocation="top"
          colCount={3}
        />
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
