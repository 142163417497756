/**
 * Update date: 28-08-2023
 * Popup product detail component
 */
import { DataGridFull } from 'app/components/DataGrid';
import { Button } from 'devextreme-react';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { useEffect, useRef, useState } from 'react';
import { useProductDetail } from '..';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import moment from 'moment';

function PurchaseInformation() {
  const { t, data, dateFormatStr, productId }: any = useProductDetail();
  const [selected, setSelected] = useState('3days');
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();
  const [listData, setListData] = useState([]);
  const dataGridRef: any = useRef(null);

  const onDateSelected = async (option: any) => {
    setSelected(option);
    let startDate = '';
    let endDate = '';
    switch (option) {
      case '3days':
        startDate = dateFormatStr(moment(new Date()).subtract(3, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '7days':
        startDate = dateFormatStr(moment(new Date()).subtract(7, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '30days':
        startDate = dateFormatStr(moment(new Date()).subtract(30, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '90days':
        startDate = dateFormatStr(moment(new Date()).subtract(90, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      case '180days':
        startDate = dateFormatStr(moment(new Date()).subtract(180, 'days'));
        endDate = dateFormatStr(new Date());
        break;
      default:
        break;
    }
    const res = await refetch({
      data: {
        productId: productId,
        startDate,
        endDate,
        type: '1',
      },
    });
    setListData(
      (res?.data?.data?.purchase || [])?.map(o =>
        o?.task_type === 2
          ? {
              ...o,
              quantity_per_pack: o?.quantity_per_pack * -1,
              purchase_unit_price: o?.purchase_unit_price * -1,
              receive_qt: o?.receive_qt * -1,
              receive_ea: o?.receive_ea * -1,
              receive_amount: o?.receive_amount * -1,
              supply_amount: o?.supply_amount * -1,
              vat_amount: o?.vat_amount * -1,
              bottle_amount: o?.bottle_amount * -1,
              container_amount: o?.container_amount * -1,
              total_amount: o?.total_amount * -1,
              bottle_unit_price: o?.bottle_unit_price * -1,
              option_container_unit_price: o?.option_container_unit_price * -1,
            }
          : o,
      ),
    );
  };

  const [{ loading }, refetch] = useAxios(
    {
      url: 'product/product/popup-purchase-sale-event',
      method: 'POST',
    },
    {
      manual: true,
      autoCancel: true,
      useCache: false,
    },
  );

  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  useEffect(() => {
    if (data?.data) {
      setListData(
        (data?.data?.purchase || [])?.map(o =>
          o?.task_type === 2
            ? {
                ...o,
                quantity_per_pack: o?.quantity_per_pack * -1,
                purchase_unit_price: o?.purchase_unit_price * -1,
                receive_qt: o?.receive_qt * -1,
                receive_ea: o?.receive_ea * -1,
                receive_amount: o?.receive_amount * -1,
                supply_amount: o?.supply_amount * -1,
                vat_amount: o?.vat_amount * -1,
                bottle_amount: o?.bottle_amount * -1,
                container_amount: o?.container_amount * -1,
                total_amount: o?.total_amount * -1,
                bottle_unit_price: o?.bottle_unit_price * -1,
                option_container_unit_price:
                  o?.option_container_unit_price * -1,
              }
            : o,
        ),
      );
    }
  }, [data?.data]);
  return (
    <>
      <LoadPanel visible={loading} />
      <div className="date-options date-option-premiums">
        <Button
          text={t('Last 3 days')}
          onClick={() => onDateSelected('3days')}
          className={`${
            selected === '3days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 7 days')}
          onClick={() => onDateSelected('7days')}
          className={`${
            selected === '7days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 30 days')}
          onClick={() => onDateSelected('30days')}
          className={`${
            selected === '30days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 90 days')}
          onClick={() => onDateSelected('90days')}
          className={`${
            selected === '90days' && 'popup-product-selected-button'
          }`}
        />
        <Button
          text={t('Last 180 days')}
          onClick={() => onDateSelected('180days')}
          className={`${
            selected === '180days' && 'popup-product-selected-button'
          }`}
        />
      </div>
      <DataGridFull
        storageKeyInquiry="purchase_information"
        ref={dataGridRef}
        dataSource={listData}
        options={{
          columnAutoWidth: true,
          height: 'calc(100vh - 260px)',
          onCellPrepared: e => {
            if (e.rowType === 'data' && e?.data?.task_type === 2) {
              e.cellElement.style.color = 'red';
              e.cellElement.value = 'red';
            }
          },
          groupPanel: {
            visible: true,
          },
          export: {
            fileName: 'Purchase Information',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export to Excel'),
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                name: 'receive_qt',
                column: 'receive_qt',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'receive_ea',
                column: 'receive_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'SumAmount',
                column: 'receive_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'SupplyAmount',
                column: 'supply_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'VatAmount',
                column: 'vat_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'Bottle',
                column: 'bottle_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'Container',
                column: 'container_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'TotalAmount',
                column: 'total_amount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'receive_ea',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receive_qt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receive_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supply_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'bottle_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'container_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'total_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
        columns={[
          {
            dataField: 'task_type_nm',
            caption: t('Task Type'),
            alignment: 'left',
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_bill',
            caption: t('Bill ID'),
            alignment: 'left',
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_dt',
            caption: t('Date'),
            dataType: 'date',
            format: DateFormat,
            showWhenGrouped: true,
          },
          {
            dataField: 'vendor_nm',
            caption: t('Vendor'),
            showWhenGrouped: true,
          },
          {
            dataField: 'zone_nm',
            caption: t('Zone'),
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_takeout_tp_nm',
            caption: t('Receive Data Owner'),
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_state_nm',
            caption: t('State'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'receive_status',
            caption: t('Status'),
            showWhenGrouped: true,
            cellRender: record => {
              const { receive_status } = record?.data || {};
              return (
                <span>
                  {receive_status === '0' ? t('Pending') : t('Fixed')}
                </span>
              );
            },
            visible: false,
          },
          {
            dataField: 'receive_state_nm',
            caption: t('Location'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'product_cd',
            caption: t('Product Code'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'product_nm',
            caption: t('Product Name'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'marketable_size',
            caption: t('Marketable Size'),
            showWhenGrouped: true,
            visible: false,
          },
          {
            dataField: 'option_cd',
            caption: t('Option Code'),
            showWhenGrouped: true,
          },
          {
            dataField: 'option_nm',
            caption: t('Option'),
            showWhenGrouped: true,
          },
          {
            dataField: 'quantity_per_pack',
            caption: t('Quantity Per Pack'),
            showWhenGrouped: true,
            format: QtyFormat,
            dataType: 'number',
          },
          {
            dataField: 'purchase_unit_price',
            caption: t('Purchase Unit Price'),
            format: DoubleFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_qt',
            caption: t('Quantity'),
            format: QtyFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_ea',
            caption: t('EA'),
            format: QtyFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_amount',
            caption: t('Amount'),
            format: AmountFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'supply_amount',
            caption: t('Supply Amount'),
            format: AmountFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'vat_amount',
            caption: t('VAT'),
            showWhenGrouped: true,
            format: AmountFormat,
            dataType: 'number',
          },
          {
            dataField: 'bottle_amount',
            caption: t('Bottle'),
            format: AmountFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'container_amount',
            caption: t('Container'),
            format: AmountFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'total_amount',
            caption: t('G. Total'),
            format: AmountFormat,
            dataType: 'number',
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_mst_nt',
            caption: t('Bill Note'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'receive_det_nt',
            caption: t('Line Note'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'bottle_id',
            caption: t('Bottle ID'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'bottle_unit_price',
            caption: t('Bottle Unit Price'),
            format: AmountFormat,
            dataType: 'number',
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'option_container_id',
            caption: t('Container ID'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'option_container_unit_price',
            caption: t('Container Unit Price'),
            format: AmountFormat,
            dataType: 'number',
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'marker',
            caption: t('Maker'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'origin',
            caption: t('Origin'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'default_vendor',
            caption: t('Default Vendor'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'large_category',
            caption: t('Large category'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'medium_category',
            caption: t('Medium category'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'small_category',
            caption: t('Small category'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'sub_category',
            caption: t('Sub category'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'regist_dm',
            caption: t('Register Date'),
            dataType: 'datetime',
            format: DateTimeFormat,
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'register_user',
            caption: t('Register User'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'register_program_cd',
            caption: t('Register Program ID'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'modify_dm',
            caption: t('Modify Date'),
            dataType: 'datetime',
            format: DateTimeFormat,
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'modify_user',
            caption: t('Modify User'),
            visible: false,
            showWhenGrouped: true,
          },
          {
            dataField: 'modify_program_cd',
            caption: t('Modify Program ID'),
            visible: false,
            showWhenGrouped: true,
          },
        ]}
      />
    </>
  );
}

export default PurchaseInformation;
