/**
 * Update date: 14-06-2023
 * Popup product detail component
 */
import useAxios from 'axios-hooks';
import { Chart, ScrollView } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import { useEffect, useState } from 'react';
import { useProductDetail } from '../..';
import TableSingle from '../../TableSingle';

const ViewCenter = () => {
  const { productData, productId, visible }: any = useProductDetail();
  const [dataSource, setDataSource] = useState<any>([]);
  const [dataSourceChart, setDataSourceChart] = useState<any>([]);
  const { t } = useDictionary({});
  const { AmountFormat }: any = useFormat();
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: '/product/product/product-unit-popup',
      method: 'POST',
    },
    { manual: true },
  );
  const [{ data: dataChart, loading: loadingChart }, refetchDataChart] =
    useAxios(
      {
        url: '/product/product/product-unit-popup',
        method: 'POST',
      },
      { manual: true },
    );
  const sources: any = [
    { value: 'stock_ea', name: t('Inventory') },
    { value: 'receive_ea', name: t('Receiving') },
    { value: 'forward_ea', name: t('Forward') },
  ];
  const columns: any = [
    {
      label: t('Month'),
      dataField: 'monthYear',
    },
    {
      label: t('Receiving'),
      dataField: 'receive_ea',
      format: AmountFormat,
    },
    {
      label: t('Takeout'),
      dataField: 'takeout_ea',
      color: '#D92D20',
      format: AmountFormat,
    },
    {
      label: t('Forward'),
      dataField: 'forward_ea',
      format: AmountFormat,
    },
    {
      label: t('Carry in'),
      dataField: 'return_ea',
      color: '#D92D20',
      format: AmountFormat,
    },
    {
      label: t('Adjust'),
      dataField: 'adjust_ea',
      color: '#1570EF',
      format: AmountFormat,
    },
    {
      label: t('Inventory'),
      dataField: 'stock_ea',
      format: AmountFormat,
    },
  ];

  const resetData = () => {
    setDataSource([]);
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (visible && productId) {
      refetchData({
        data: { type: '2', productId: productId },
      });
      refetchDataChart({
        data: { type: '4', productId: productId },
      });
    } else if (!visible) {
      resetData();
    }
  }, [visible, productId]);

  useEffect(() => {
    if (data?.data) {
      setDataSource(
        data?.data?.map(o => ({
          ...o,
          monthYear: `${o?.months}/${o?.years}`,
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    setDataSourceChart(
      dataChart?.data.map(o => {
        o.weekNumber = `${o?.weeks}W`;
        return o;
      }),
    );
  }, [dataChart]);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '3fr 7fr',
        gridGap: 20,
        marginTop: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 360,
          border: '1px solid #D0D5DD',
          borderRadius: 8,
          padding: 10,
        }}
      >
        {productData?.product_img ? (
          <img
            src={productData?.product_img}
            alt=""
            style={{
              maxWidth: '100%',
              width: 'auto',
              maxHeight: '100%',
              height: 'auto',
            }}
          />
        ) : (
          <span>{t('No Image')}</span>
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            textAlign: 'right',
            position: 'absolute',
            top: 15,
            right: 0,
            zIndex: 9,
          }}
        >
          <span>{`${t('Last update')}: ${productData?.regist_dm}`}</span>
        </div>
        <TabPanel className="custom-tabpanel-border-none" swipeEnabled={false}>
          <TabItem title={t('Data')}>
            <ScrollView width="100%" height={298}>
              <TableSingle columns={columns} dataSource={dataSource} />
            </ScrollView>
          </TabItem>
          <TabItem title={t('Chart Monthly')}>
            <Chart
              palette={'Dark Violet'}
              dataSource={productId ? dataSource || [] : []}
              commonSeriesSettings={{
                argumentField: 'monthYear',
              }}
              argumentAxis={{
                discreteAxisDivisionMode: 'crossLabels',
              }}
              series={sources.map(item => ({
                key: item.value,
                valueField: item.value,
                name: item.name,
                dashStyle: item.value === 'receive_ea' ? 'dash' : 'solid',
                type:
                  item.value === 'stock_ea'
                    ? 'bar'
                    : item.value === 'receive_ea'
                    ? 'spline'
                    : 'spline',
                point: {
                  visible: false,
                },
              }))}
              legend={{
                verticalAlignment: 'top',
                horizontalAlignment: 'center',
                itemTextPosition: 'right',
              }}
              size={{
                height: 300,
              }}
              tooltip={{
                enabled: true,
                zIndex: 10000,
              }}
            />
          </TabItem>
          <TabItem title={t('Chart Weekly')}>
            <Chart
              palette={'Dark Violet'}
              dataSource={dataSourceChart}
              commonSeriesSettings={{
                argumentField: 'weekNumber',
              }}
              argumentAxis={{
                discreteAxisDivisionMode: 'crossLabels',
              }}
              series={sources.map(item => ({
                key: item.value,
                valueField: item.value,
                name: item.name,
                dashStyle: item.value === 'receive_ea' ? 'dash' : 'solid',
                // color:
                //   item.value === 'receive_ea'
                //     ? '#0ccded'
                //     : item.value === 'forward_ea'
                //     ? '#5cb85c'
                //     : '#9a4eef',
                type:
                  item.value === 'stock_ea'
                    ? 'bar'
                    : item.value === 'receive_ea'
                    ? 'spline'
                    : 'spline',
                point: {
                  visible: false,
                },
              }))}
              legend={{
                verticalAlignment: 'top',
                horizontalAlignment: 'center',
                itemTextPosition: 'right',
              }}
              size={{
                height: 300,
              }}
              tooltip={{
                enabled: true,
                zIndex: 10000,
              }}
            />
          </TabItem>
        </TabPanel>
      </div>
    </div>
  );
};

export default ViewCenter;
