/**
 * Update date: 23-05-2023
 * Screen 2023.2
 */
import { Form, Popup, Button } from 'devextreme-react';
import { useRef, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { notification } from 'utils/notification';
import './styles.scss';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';
interface IDatProps {
  setShowModalSendMail?: any;
  toMail?: string;
  billId?: string;
  orderId?: number;
  centerName?: string;
}

const SendMail = ({
  setShowModalSendMail,
  toMail,
  billId,
  orderId,
}: IDatProps) => {
  const { themePro }: any = useApp();
  const formRef = useRef<any>(null);

  const currentLanguage = JSON.parse(
    localStorage.getItem('myPagePreference') || '{}',
  );

  const [formData, setFormData] = useState<any>({
    to: toMail,
    subject: `【 Order ID - ${orderId} 】Order Sheet  ${billId}`,
    fileName: `${billId}`,
    orderId: orderId,
    lang: currentLanguage?.language,
    cc: '',
    note: '',
  });
  const { t }: any = useDictionary({
    programId: '2023.2',
  });
  const [usersInfo, setUsersInfo] = useState<any>([]);

  /**
   * on close
   *
   */
  const handleClose = () => {
    setShowModalSendMail(false);
  };

  const [{ data: dataSendMail, loading: loadingSendMail }, executeSendMail] =
    useAxios(
      {
        url: '/warehouse/order-registration/send-mail',
        method: 'POST',
      },
      {
        manual: true,
        useCache: false,
        autoCancel: true,
      },
    );

  const [{ loading: loadingPdfOrder }, executeFetchPdfOrder] = useAxios(
    {
      url: `/warehouse/order-registration/report`,
      method: 'POST',
      data: [orderId],
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on attachment
   *
   */
  const openAttachmentFile = async () => {
    const res = await executeFetchPdfOrder();
    const arrayData: any = new Uint8Array(res.data);
    const blob = new Blob([arrayData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  /**
   * on send mail
   *
   * @return {*}
   */
  const onSendMail = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;

    try {
      const data = formData;
      let { cc } = data;
      const ccStr = cc.toString();
      data.cc = ccStr;
      let { fileName } = data;
      fileName = fileName.replace('.pdf', '');
      data.fileName = fileName;
      const res = await executeSendMail({
        data,
      });
      notification({ type: 'success', message: t('Send email sucessfully') });
      setShowModalSendMail(false);
    } catch (e) {
      notification({
        type: 'error',
        message: t('Send email unsucessfully! Please check again'),
      });
    }
  };

  const [{ data: dataUsers, loading: loadingUsers }, executeGetUsers] =
    useAxios(
      {
        url: '/user/user',
        method: 'POST',
        data: { userNames: '' },
      },
      {
        useCache: false,
        autoCancel: true,
      },
    );
  /**
   * fetch data
   */
  useEffect(() => {
    if (dataUsers && !loadingUsers) {
      const { data } = dataUsers;
      setUsersInfo(data);
    }
  }, [dataUsers, loadingUsers]);

  const loadingAll = loadingUsers || loadingPdfOrder || loadingSendMail;

  return (
    <>
      <LoadPanel visible={loadingAll} />
      <Popup
        className="modal-content-popup location-view"
        visible={true}
        dragEnabled={false}
        showCloseButton={false}
        showTitle={true}
        title={t('Send E-mail')}
        maxHeight={themePro ? 'auto' : '80vh'}
        height={themePro ? '100vh' : 'auto'}
        maxWidth={'50vw'}
        width={themePro ? 600 : 'auto'}
        onHidden={handleClose}
        animation={{ show: undefined }}
      >
        <div className="body-padding-white">
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{t('Send Email')}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('Send')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onSendMail}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={handleClose}
                />
              </div>
            </div>
          )}
          <Form
            ref={formRef}
            formData={formData}
            items={[
              {
                dataField: 'to',
                editorType: 'dxTextBox',
                label: {
                  text: t('To'),
                },
                editorOptions: {
                  readOnly: true,
                },
                isRequired: true,
              },
              {
                dataField: 'cc',
                editorType: 'dxTagBox',
                label: {
                  text: t('Cc'),
                },
                editorOptions: {
                  searchEnabled: true,
                  multiline: true,
                  showSelectionControls: true,
                  maxDisplayedTags: 4,
                  items: usersInfo,
                  displayExpr: 'email',
                  valueExpr: 'email',
                },
              },
              {
                dataField: 'subject',
                editorType: 'dxTextBox',
                label: {
                  text: t('Subject'),
                },
                isRequired: true,
              },
              {
                dataField: 'note',
                editorType: 'dxTextArea',
                editorOptions: {
                  height: 100,
                },
                label: {
                  text: t('Content'),
                },
              },
            ]}
          />
          <span className="file-name cell-link" onClick={openAttachmentFile}>
            {formData.fileName + '.pdf'}
          </span>
          {!themePro && (
            <div className="btn-group">
              <Button
                className="btn-send"
                text={t('Send')}
                icon={t('check')}
                onClick={onSendMail}
              />
              <Button text={t('Cancel')} icon="close" onClick={handleClose} />
            </div>
          )}
        </div>
      </Popup>
    </>
  );
};
export default SendMail;
