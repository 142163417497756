/**
 * Update date: 29-05-2023
 * Screen 2044.7
 */
import React, { createContext, useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import OrderReceiptTable from './TableView';
import FormSearch from './FormSearch';
import useDictionary from 'hooks/useDictionary';
import DetailTable from './DetailTable';
import useProfileInfo from 'hooks/useProfileInfo';
/**
 * create context
 */
const storeContext = createContext({});

storeContext.displayName = 'orderDetail';
export const useStoreContext = () => {
  const context = useContext(storeContext);
  return context;
};

function PrintPicklistStatement() {
  const [orderData, setOrderData] = useState<any>([]);
  const [detail, setDetail] = useState<any>([]);
  const [viewDetail, setViewDetail] = useState<boolean>(false);
  const { t }: any = useDictionary({
    programId: '2044.7',
  });
  const { userId, name, roleType }: any = useProfileInfo();

  const [subFormData, setSubFormData] = useState<any>({
    userId: userId,
    name: name,
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (roleType === 'Delivery') {
      setSubFormData({
        userId: userId,
        name: name,
      });
    }
  }, [userId, name, roleType]);

  const [{ data: dataOrder, loading: loading }, refetchOrder] = useAxios(
    {
      url: '/ecommerce/print-pick-list',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const [{ data: detailData, loading: loadingDetail }, fetchDetail] = useAxios(
    {},
    {
      manual: true,
      useCache: false,
    },
  );

  const [{ loading: loadingReport }, fetchReport] = useAxios(
    {},
    {
      manual: true,
    },
  );

  const [{ data: updateData, loading: loadingUpdate }, updatePrintInfo] =
    useAxios(
      {
        url: '/ecommerce/print-pick-list',
        method: 'PUT',
      },
      {
        manual: true,
      },
    );

  const value: any = {
    t,
    refetchOrder,
    orderData,
    fetchDetail,
    fetchReport,
    detail,
    viewDetail,
    setViewDetail,
    subFormData,
    setSubFormData,
    updatePrintInfo,
  };

  useEffect(() => {
    setOrderData(dataOrder?.data);
  }, [dataOrder]);

  useEffect(() => {
    setDetail(detailData?.data);
  }, [detailData]);

  /**
   * on search
   *
   * @param {*} data
   */
  const onSearch = async data => {
    const res: any = await refetchOrder({
      data,
    });
  };

  return (
    <storeContext.Provider value={value}>
      <LoadPanel
        visible={loading || loadingReport || loadingUpdate || loadingDetail}
      />
      <FormSearch onSearch={onSearch} />
      <OrderReceiptTable />
      {DetailTable && (
        <DetailTable
          visible={viewDetail}
          onHiding={() => {
            setViewDetail(false);
          }}
        />
      )}
    </storeContext.Provider>
  );
}

export default PrintPicklistStatement;
