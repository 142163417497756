/**
 * Update date: 08-06-2023
 * Bread crumb component
 */
import { Button } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { CSSProperties } from 'styled-components';
import { useWrap } from '../WrapContent/WrapContent';

export interface IButtonRender {
  itemType?: 'button' | 'empty';
  title: string;
  type?: 'back' | 'danger' | 'default' | 'normal' | 'success' | undefined;
  icon?: string;
  style?: CSSProperties;
  width?: number | string;
  action?: Function | null;
}

interface IBreadCrumbPremium {
  onSubmit?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  onSaveAndCreate?: () => void;
  onClear?: () => void;
  buttons?: Array<IButtonRender>;
  buttonsFirst?: boolean;
  title?: string;
  style?: CSSProperties;
  labelSubmit?: string;
}

export const BreadCrumbPremium = ({
  onSubmit,
  onCancel,
  onClose,
  onSaveAndCreate,
  onClear,
  title,
  style,
  buttons = [],
  buttonsFirst = false,
  labelSubmit = '',
}: IBreadCrumbPremium) => {
  const { dataPage }: any = useWrap();
  const { t } = useDictionary({});
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div className="title-page" style={style}>
        {title ?? dataPage?.programName ?? 'Bread Crumb'}
      </div>
      <div>
        {buttonsFirst &&
          buttons
            ?.filter(o => o.itemType !== 'empty')
            ?.map((o, index) => (
              <Button
                text={t(o.title)}
                type={o?.type || 'default'}
                icon={o?.icon || ''}
                style={o?.style || { marginRight: 10 }}
                width={o?.width || 'auto'}
                onClick={() => o?.action?.()}
                key={`button_render_${index}`}
              />
            ))}
        {onSubmit && (
          <Button
            text={t(`${labelSubmit ? labelSubmit : 'Save'}`)}
            type="default"
            icon="check"
            onClick={onSubmit}
            style={{ marginRight: 10 }}
            width={100}
          />
        )}
        {onSaveAndCreate && (
          <Button
            text={t('Create & Save')}
            type="default"
            icon="check"
            onClick={onSaveAndCreate}
            style={{ marginRight: 10 }}
            width="auto"
          />
        )}
        {onClear && (
          <Button
            text={t('Clear')}
            icon="edit"
            onClick={onClear}
            style={{ marginRight: 10 }}
            width={100}
          />
        )}
        {onCancel && (
          <Button
            text={t('Cancel')}
            icon="close"
            onClick={onCancel}
            width={100}
          />
        )}
        {onClose && (
          <Button
            text={t('Close')}
            icon="close"
            onClick={onClose}
            width={100}
          />
        )}
        {!buttonsFirst &&
          buttons
            ?.filter(o => o.itemType !== 'empty')
            ?.map((o, index) => (
              <Button
                text={t(o.title)}
                type={o?.type || 'default'}
                icon={o?.icon || ''}
                style={o?.style || { marginLeft: 10 }}
                width={o?.width || 'auto'}
                onClick={() => o?.action?.()}
                key={`button_render_${index}`}
              />
            ))}
      </div>
    </div>
  );
};
