/**
 * Update date: 26-05-2023
 * Screen 2043.2
 */
import useDictionary from 'hooks/useDictionary';
import FormContent from './FormContent';

const MallIntegrationTab = () => {
  const { t }: any = useDictionary({ programId: '2043.2' });

  return (
    <div>
      <FormContent t={t} formIndex={1} />
      <br />
      <FormContent t={t} formIndex={2} />
    </div>
  );
};
export default MallIntegrationTab;
