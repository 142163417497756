/**
 * Update date: 31-05-2023
 * Screen 2051.8
 */
import React, { useRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useDictionary from 'hooks/useDictionary';
import { useInventoryContext } from '../index';
import moment from 'moment';
import useFormat from 'hooks/useFormat';
import { useApp } from 'app';

const exportFileName =
  'SMDC_2051.8 Inventory Age Analysis Table by Product_' +
  moment().format('YYYYMMDD');

function InventoryTable(props) {
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({
    programId: '2051.8',
  });

  const { tableData }: any = useInventoryContext();
  const gridRef = useRef<any>(null);
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();

  const columns: any = [
    {
      dataField: 'productId',
      caption: t('ID'),
      alignment: themePro ? 'left' : 'center',
      allowEditing: false,
    },
    {
      dataField: 'largeCategory',
      caption: t('Large category'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'mediumCategory',
      caption: t('Medium category'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'smallCategory',
      caption: t('Small category'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'subCategory',
      caption: t('Sub category'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      alignment: themePro ? 'left' : 'center',
      allowEditing: false,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      allowEditing: false,
    },
    {
      dataField: 'productMaker',
      caption: t('Maker'),
      allowEditing: false,
    },
    {
      dataField: 'marketAbleSize',
      caption: t('Marketable Size'),
      alignment: themePro ? 'left' : 'center',
      allowEditing: false,
    },
    {
      dataField: 'productCost',
      caption: t('Product Cost'),
      format: DoubleFormat,
      allowEditing: false,
    },
    {
      dataField: 'stockEa',
      caption: t('Stock'),
      format: QtyFormat,
      allowEditing: false,
    },
    {
      dataField: 'stockAmount',
      caption: t('Stock Cost'),
      dataType: 'number',
      format: AmountFormat,
      allowEditing: false,
    },
    {
      caption: t('1 day - 30 days'),
      alignment: 'center',
      columns: [
        {
          dataField: 'oneMonthEa',
          caption: t('Quantity'),
          dataType: 'number',
          format: QtyFormat,
          allowEditing: false,
        },
        {
          dataField: 'oneMonthAmt',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          allowEditing: false,
        },
      ],
    },
    {
      caption: t('31 days - 60 days'),
      alignment: 'center',
      columns: [
        {
          dataField: 'twoMonthEa',
          caption: t('Quantity'),
          dataType: 'number',
          format: QtyFormat,
          allowEditing: false,
        },
        {
          dataField: 'twoMonthAmt',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          allowEditing: false,
        },
      ],
    },
    {
      caption: t('61 days - 90 days'),
      alignment: 'center',
      columns: [
        {
          dataField: 'threeMonthEa',
          caption: t('Quantity'),
          dataType: 'number',
          format: QtyFormat,
          allowEditing: false,
        },
        {
          dataField: 'threeMonthAmt',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          allowEditing: false,
        },
      ],
    },
    {
      caption: t('91 days - 120 days'),
      alignment: 'center',
      columns: [
        {
          dataField: 'fourMonthEa',
          caption: t('Quantity'),
          dataType: 'number',
          format: QtyFormat,
          allowEditing: false,
        },
        {
          dataField: 'fourMonthAmt',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          allowEditing: false,
        },
      ],
    },
    {
      caption: t('121 days - 150 days'),
      alignment: 'center',
      columns: [
        {
          dataField: 'fiveMonthEa',
          caption: t('Quantity'),
          dataType: 'number',
          format: QtyFormat,
          allowEditing: false,
        },
        {
          dataField: 'fiveMonthAmt',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          allowEditing: false,
        },
      ],
    },
    {
      caption: t('more than 150 days'),
      alignment: 'center',
      columns: [
        {
          dataField: 'sixMonthEa',
          caption: t('Quantity'),
          dataType: 'number',
          format: QtyFormat,
          allowEditing: false,
        },
        {
          dataField: 'sixMonthAmt',
          caption: t('Amount'),
          dataType: 'number',
          format: AmountFormat,
          allowEditing: false,
        },
      ],
    },
    {
      dataField: 'taxName',
      caption: t('Tax Name'),
      alignment: 'left',
      allowEditing: false,
      visible: false,
    },
  ];

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    gridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        ref={gridRef}
        dataSource={tableData}
        columns={columns}
        fixedRight={0}
        options={{
          key: ['productId'],
          columnAutoWidth: true,
          export: {
            fileName: exportFileName,
          },
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !tableData?.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          groupPanel: {
            visible: true,
          },
          summary: {
            totalItems: [
              {
                column: 'stockEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'stockAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'oneMonthEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'oneMonthAmt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'twoMonthEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'twoMonthAmt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'threeMonthEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'threeMonthAmt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fourMonthEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fourMonthAmt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fiveMonthEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fiveMonthAmt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'sixMonthEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'sixMonthAmt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'oneMonthEa',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'oneMonthAmt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'twoMonthEa',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'twoMonthAmt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'threeMonthEa',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'threeMonthAmt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fourMonthEa',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fourMonthAmt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fiveMonthEa',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'fiveMonthAmt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'sixMonthEa',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'sixMonthAmt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </>
  );
}

export default InventoryTable;
