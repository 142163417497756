/**
 * Create date: 16-08-2023
 * Screen 2016.8
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { createContext, useContext, useState } from 'react';
import { dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2016_8_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2016_8 = () => {
  const { t }: any = useDictionary({ programId: '2016.8' });
  const [data, setData] = useState([]);
  const { AmountFormat } = useFormat();
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    costFlag: '1',
    isDisplay: true,
    setName: '',
    dataList: {
      mall: [],
      product: [],
      productGroup: [],
      largeCategoryCodes: [],
      mediumCategoryCodes: [],
      smallCategoryCodes: [],
      subCategoryCodes: [],
    },
    mall: '',
    product: '',
    productGroup: '',
    largeCategoryCodes: '',
    mediumCategoryCodes: '',
    smallCategoryCodes: '',
    subCategoryCodes: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  const [{}, refetchUpdate] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: ['collect_id', 'collect_option_id', 'line_no'],
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      fromDate: dateFormatStr(formSearchData?.fromDate),
      endDate: dateFormatStr(formSearchData?.endDate),
      setName: formSearchData?.setName,
      costFlag: formSearchData?.costFlag,
      isDisplay: formSearchData?.isDisplay,
      mallCode: formSearchData?.dataList?.mall?.map(o => o?.mallCode)[0] || '',
      largeCategoryCodes: formSearchData.dataList.largeCategoryCodes.map(
        (o: any) => o?.code,
      ),
      mediumCategoryCodes: formSearchData.dataList.mediumCategoryCodes.map(
        (o: any) => o?.code,
      ),
      smallCategoryCodes: formSearchData.dataList.smallCategoryCodes.map(
        (o: any) => o?.code,
      ),
      subCategoryCodes: formSearchData.dataList.subCategoryCodes.map(
        (o: any) => o?.code,
      ),
      productIds: formSearchData.dataList.product.map((o: any) => o?.productId),
      productGroupIds: formSearchData.dataList.productGroup.map(
        (o: any) => o?.productGroupId,
      ),
    };
    const res = await refetchData({
      url: '/warehouse/collection-option-inquiry',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const dataMapping = (res?.data?.data || []).map(o => ({
        ...o,
        reserve_status: t(o?.reserve_status),
      }));
      setData(dataMapping);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    setData,
    modeView,
    AmountFormat,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    store,
    DateFormat,
    DateTimeFormat,
    refetchUpdate,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2016_8;
