/**
 * Update date: 25-05-2023
 * Screen 2041.8
 */
import React, {
  useRef,
} from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useFormatTime from 'hooks/useFormatTime';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useReceivingLoad } from '..';
import useDictionary from 'hooks/useDictionary';

function TableList(props) {
  const { DateFormat } = useFormatTime();
  const { AmountFormat, QtyFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useReceivingLoad();
  const { dataSource } = context;

  const { t }: any = useDictionary({
    programId: '2041.8',
  });

  const columns: any = [
    {
      caption: t('Loading'),
      alignment: 'center',
      items: [
        {
          dataField: 'billId',
          caption: t('Bill ID'),
        },
        {
          dataField: 'moveDate',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
        },
        {
          dataField: 'vendorNm',
          caption: t('Vendor'),
          alignment: 'left',
        },
        {
          dataField: 'fromZone',
          caption: t('From Zone'),
          alignment: 'left',
          visible: false,
        },
        {
          dataField: 'toZone',
          caption: t('Zone'),
          alignment: 'left',
        },
        {
          dataField: 'fromLocation',
          caption: t('From Location'),
          alignment: 'left',
          visible: false,
        },
        {
          dataField: 'toLocation',
          caption: t('Location'),
          alignment: 'left',
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      items: [
        {
          dataField: 'productCd',
          caption: t('Product Code'),
        },
        {
          dataField: 'productNm',
          caption: t('Product Name'),
          alignment: 'left',
        },
        {
          dataField: 'marketableSz',
          caption: t('Marketable Size'),
          alignment: 'left',
        },
        {
          dataField: 'optionCd',
          caption: t('Option Code'),
          visible: false,
        },
        {
          dataField: 'optionNm',
          caption: t('Option'),
        },
        {
          dataField: 'quantityPerPack',
          caption: t('Quantity Per Pack'),
        },
        {
          dataField: 'moveQuantity',
          caption: t('Qty'),
          format: QtyFormat,
          dataType: 'number',
        },
        {
          dataField: 'exceptQty',
          caption: t('Except Qty'),
          format: QtyFormat,
          dataType: 'number',
          visible: false,
        },
      ],
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName =
    'SMDC_2041.8 Receiving Load Inquiry ' + moment().format('YYYYMMDD');

  return (
    <>
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={dataSource}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: '',
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                column: 'moveQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                column: 'exceptQty',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'moveQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'exceptQty',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
    </>
  );
}

export default TableList;
