/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { useRef, useState, memo } from 'react';
import { notification } from 'utils/notification';
import ShippingTracking from '../ShippingTracking';
import PopupConfirm from '../../PopupConfirm';
import PopupCheckTaxName from './PopupCheckTaxName';
import PopupViewTaxInvoice from './PopupViewTaxInvoice';
import useFormatTime from 'hooks/useFormatTime';
import useFormat from 'hooks/useFormat';
import useDictionary from 'hooks/useDictionary';

const ShippingRequest = ({ storeShipping, storeTaxInvoice }) => {
  const { t } = useDictionary({ programId: '2043.0' });
  const { DateFormat } = useFormatTime();
  const { AmountFormat } = useFormat();
  const shippingGridRef: any = useRef(null);
  const sendTaxGridRef: any = useRef(null);
  const [shippingTracking, setShippingTracking] = useState<any>({
    visible: false,
  });
  const [taxInvoiceInssuance, setTaxInvoiceInssuance] = useState<any>({
    visible: false,
    data: null,
  });
  const [checkTaxName, setCheckTaxName] = useState<any>({
    visible: false,
    data: null,
  });
  const [viewTaxInvoice, setViewTaxInvoice] = useState<any>({
    visible: false,
    data: null,
  });

  const [{ loading: loadingSendTax }, refetchSendTax] = useAxios(
    {},
    { manual: true, useCache: false, autoCancel: true },
  );

  /**
   * on send
   *
   * @return {*} 
   */
  const onSendInvoice = async () => {
    const dataSend = taxInvoiceInssuance?.data;
    if (!dataSend) return;
    const requestData = {
      storeId: dataSend?.store_id,
      invoice: dataSend?.invoice_no,
      taxType: dataSend?.tax_tp,
      receivableCd: dataSend?.receivable_cd,
      orderDate: dataSend?.order_date,
    };
    const res: any = await refetchSendTax({
      url: '/user/m2043_0/send-tax-invoice',
      method: 'PUT',
      data: requestData,
    });
    notification({ res });
    if (res?.data?.status === '201') {
      const { store_taxbill_id, taxbill_dt, store_taxbill_cd } =
        res?.data?.data?.[0] || {};
      storeTaxInvoice
        ?.update(
          {
            invoice_no: dataSend?.invoice_no,
            receivable_cd: dataSend?.receivable_cd,
            tax_tp: dataSend?.tax_tp,
          },
          {
            store_taxbill_id: store_taxbill_id,
            store_taxbill_cd: store_taxbill_cd,
            taxbill_dt: taxbill_dt,
          },
        )
        ?.done(() => setTaxInvoiceInssuance({ visible: false, data: null }));
    } else {
      setTaxInvoiceInssuance({ visible: false, data: null });
    }
  };

  return (
    <>
      <LoadPanel visible={loadingSendTax} />
      <div className="body-padding-white mb-10">
        <div>
          <div className="dx-form-group-caption">
            {t('Shipping Information')}
          </div>
        </div>
        <DataGridFull
          ref={shippingGridRef}
          dataSource={storeShipping}
          options={{
            searchPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false,
            },
            filterPanel: {
              visible: false,
            },
            scrolling: {
              mode: 'infinite',
            },
            className: 'datagrid-relative-header',
          }}
          columns={[
            {
              dataField: '',
              dataType: 'date',
              alignment: 'left',
              headerCellRender: () => {
                return (
                  <div>
                    {t('Shipping Date Time')}
                    <br />
                    {t('Deposit Date Time')}
                  </div>
                );
              },
              cellRender: e => {
                return (
                  <div>
                    {e?.data?.shipping_dm
                      ? moment(e?.data?.shipping_dm).format('YYYY-MM-DD HH:mm')
                      : '-'}
                    <br />
                    {e?.data?.deposit_dm
                      ? moment(e?.data?.deposit_dm).format('YYYY-MM-DD HH:mm')
                      : '-'}
                  </div>
                );
              },
            },
            {
              dataField: 'shipping_method_name',
              caption: t('Shipping Method'),
              alignment: 'left',
            },
            {
              dataField: 'invoice_no',
              caption: t('Invoice'),
              alignment: 'left',
              cellRender: e => {
                return (
                  <span
                    style={{ color: 'blueviolet', cursor: 'pointer' }}
                    onClick={() =>
                      setShippingTracking({
                        visible: true,
                        invoiceNo: e?.value,
                      })
                    }
                  >
                    {e?.value}
                  </span>
                );
              },
            },
            {
              dataField: 'tax_tp_nm',
              caption: t('Tax Type'),
              alignment: 'left',
              cellRender: e => t(e?.value),
            },
            {
              dataField: 'receivable_cd_nm',
              caption: t('Receivable Group'),
            },
            {
              dataField: 'delivery_amount',
              caption: t('Sum Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'supply_amount',
              caption: t('Supply Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'tax_amount',
              caption: t('VAT'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'forward_status',
              caption: t('Status'),
              width: 85,
              cellRender: e => {
                return (
                  <div>
                    {e?.value === '0'
                      ? t('Pending')
                      : e?.value === '1'
                      ? t('Fixed')
                      : ''}
                  </div>
                );
              },
            },
            {
              dataField: 'inssuance',
              caption: t('Tax Invoice Inssuance'),
              alignment: 'left',
              cellRender: (record: any) => {
                const { addSend, id_no, store_taxbill_id, forward_status } =
                  record?.data || {};
                const isDisable =
                  addSend || !!store_taxbill_id || forward_status !== '1';

                return (
                  <span
                    className={isDisable ? '' : 'cell-link'}
                    onClick={() => {
                      if (isDisable) return;
                      const newStored = [
                        { ...record?.data },
                        ...(storeTaxInvoice?._array || []),
                      ];
                      const lengthNewStored = newStored?.length || 0;
                      storeTaxInvoice?.clear();
                      newStored.forEach((o, index) => {
                        storeTaxInvoice?.insert(o).done(() => {
                          if (index === lengthNewStored - 1) {
                            storeShipping
                              ?.update(id_no, { addSend: true })
                              .done(() =>
                                shippingGridRef?.current?.instance?.refresh(),
                              );
                            sendTaxGridRef?.current?.instance?.refresh();
                          }
                        });
                      });
                    }}
                  >
                    {t('Add Request Issuance')}
                  </span>
                );
              },
            },
          ]}
        />
      </div>
      <div className="body-padding-white">
        <div>
          <div className="dx-form-group-caption">
            {t('Request tax invoice')}
          </div>
        </div>
        <DataGridFull
          ref={sendTaxGridRef}
          dataSource={storeTaxInvoice}
          fixedRight={1}
          fixedLeft={3}
          options={{
            searchPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false,
            },
            filterPanel: {
              visible: false,
            },
            scrolling: {
              mode: 'infinite',
            },
            className: 'datagrid-relative-header',
          }}
          columns={[
            {
              dataField: 'taxbill_dt',
              caption: t('Date'),
              format: DateFormat,
              dataType: 'date',
              alignment: 'left',
            },
            {
              dataField: 'store_taxbill_cd',
              caption: t('Bill ID'),
              alignment: 'left',
            },
            {
              dataField: 'invoice_no',
              caption: t('Invoice'),
              alignment: 'left',
            },
            {
              dataField: 'store_cd',
              caption: t('Store Code'),
              alignment: 'left',
            },
            {
              dataField: 'store_nm',
              caption: t('Store Name'),
              alignment: 'left',
            },
            {
              dataField: 'bussiness_n',
              caption: t('Business No'),
              alignment: 'left',
            },
            {
              dataField: 'tax_tp_nm',
              caption: t('Tax Type'),
              alignment: 'left',
              cellRender: e => t(e?.value),
            },
            {
              dataField: 'supply_amount',
              caption: t('Supply Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'tax_amount',
              caption: t('VAT Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: 'delivery_amount',
              caption: t('Total Amount'),
              format: AmountFormat,
              dataType: 'number',
            },
            {
              dataField: '',
              caption: t('Action'),
              alignment: 'left',
              cellRender: e => {
                const { receivable_cd, tax_tp, store_taxbill_id } =
                  e?.data || {};
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {store_taxbill_id && (
                      <span
                        className="cell-link"
                        onClick={() => {
                          setViewTaxInvoice({
                            visible: true,
                            data: store_taxbill_id,
                          });
                        }}
                      >
                        {t('View')}
                      </span>
                    )}
                    {!store_taxbill_id &&
                      tax_tp === '0' &&
                      receivable_cd === '2' && (
                        <span
                          className="cell-link"
                          style={{ color: '#7F56D9' }}
                          onClick={() =>
                            setCheckTaxName({ visible: true, data: e?.data })
                          }
                        >
                          {t('Check Tax Name')}
                        </span>
                      )}
                    {!store_taxbill_id && (
                      <span
                        className="cell-link"
                        onClick={() =>
                          setTaxInvoiceInssuance({
                            visible: true,
                            data: e?.data,
                          })
                        }
                      >
                        {t('Request Issuance')}
                      </span>
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
      {shippingTracking?.visible && (
        <ShippingTracking
          invoiceNo={shippingTracking?.invoiceNo || ''}
          onClose={() =>
            setShippingTracking({
              visible: false,
            })
          }
        />
      )}
      {taxInvoiceInssuance?.visible && (
        <PopupConfirm
          visible={taxInvoiceInssuance?.visible}
          content={`${t(
            'Do you want to request issuance for invoice {0}?',
          )}`.replace('{0}', taxInvoiceInssuance?.data?.invoice_no)}
          onOk={onSendInvoice}
          onHiding={() =>
            setTaxInvoiceInssuance({
              visible: false,
              data: null,
            })
          }
        />
      )}
      {checkTaxName?.visible && (
        <PopupCheckTaxName
          visible={checkTaxName?.visible}
          data={{
            storeId: checkTaxName?.data?.store_id,
            invoice: checkTaxName?.data?.invoice_no,
            taxType: checkTaxName?.data?.tax_tp,
            receivableCd: checkTaxName?.data?.receivable_cd,
            orderDate: checkTaxName?.data?.order_date,
          }}
          onHiding={() => {
            setCheckTaxName({ visible: false, data: null });
          }}
        />
      )}
      {viewTaxInvoice?.visible && (
        <PopupViewTaxInvoice
          visible={viewTaxInvoice?.visible}
          data={viewTaxInvoice?.data}
          onHiding={() => {
            setViewTaxInvoice({ visible: false, data: null });
          }}
        />
      )}
    </>
  );
};

export default memo(ShippingRequest);
