/**
 * Update date: 23-05-2023
 * Screen 2023.1
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { forwardRef, useState } from 'react';
import { notification } from 'utils/notification';
import { useOrderRegistration } from '..';
import FormSearch from '../FormSearch';
import SendMail from '../SendMail';
import printJS from 'print-js';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

function ListOrder(props, ref) {
  const context: any = useOrderRegistration();
  const { setModeView, store, deselectAll, onUpdateListOrder, t } =
    context || {};
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const selectedKeys = ref?.current?.instance?.option().selectedRowKeys;
  const { DateFormat } = useFormatTime();
  const [keyToFixed, setKeyToFixed] = useState(null);
  const [showModalSendMail, setShowModalSendMail] = useState(false);
  const [toMail, setToMail] = useState<string>('');
  const [billId, setBillId] = useState<string>('');
  const [orderId, setOrderId] = useState<number>(0);
  const { AmountFormat, QtyFormat } = useFormat();
  const [{}, refetchDelete] = useAxios(
    {
      url: '/warehouse/order-registration',
      method: 'DELETE',
    },
    { manual: true },
  );
  const [{ loading }, refetchUpdate] = useAxios(
    {
      method: 'PUT',
    },
    {
      manual: true,
    },
  );
  /**
   * on confirm
   *
   * @return {*}
   */
  const handleConfirm = async () => {
    if (!keyToFixed) {
      return notification({
        message: 'ID not found',
        type: 'error',
      });
    }
    const res = await refetchUpdate({
      url: `/warehouse/order-registration/${keyToFixed}`,
    });
    notification({ res });
    if (res?.status == 200) {
      if (res?.data?.status === '200') {
        setShowModalConfirm(false);
        setKeyToFixed(null);
        onUpdateListOrder(res?.data?.data);
      }
    }
  };

  /**
   * on validate delete
   *
   * @return {*}
   */
  const validateDelete = () => {
    const selectedRowsData =
      ref?.current?.instance?.getSelectedRowsData() || [];
    if (!selectedRowsData?.length) {
      return notification({
        message: t('You must be select at least one record. Please try again!'),
        type: 'error',
      });
    }
    if (selectedRowsData.some(item => item.ctOrderStatus == '1')) {
      return notification({
        message: t('You can not delete fixed record. Please try again!'),
        type: 'error',
      });
    }
    setShowModalDelete(true);
  };

  /**
   * on delete
   *
   */
  const onDelete = async () => {
    const res = await refetchDelete({ data: selectedKeys });
    notification({
      res,
    });
    if (res?.data?.status === '200') {
      selectedKeys?.forEach(ctOrderId => {
        store
          .remove(ctOrderId)
          .done(() => {})
          .fail(() => {});
      });
      deselectAll();
      setShowModalDelete(false);
    }
  };
  /**
   * render status
   *
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    const { ctOrderStatus, ctOrderStatusNm, ctOrderId } = e?.data || {};
    return (
      <div
        onClick={() => {
          if (ctOrderStatus !== '0') return;
          setKeyToFixed(ctOrderId);
          setShowModalConfirm(true);
        }}
        className={ctOrderStatus === '0' ? 'cell-link' : ''}
      >
        {ctOrderStatusNm}
      </div>
    );
  };
  /**
   * render action
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    const { ctOrderStatus, ctOrderId } = record?.data || {};
    const type = ctOrderStatus === '0' ? 'edit' : 'view';
    return (
      <div
        onClick={() => setModeView({ type: type, id: ctOrderId })}
        className="cell-link"
      >
        {type === 'edit' ? t('Edit') : t('View')}
      </div>
    );
  };

  const [{ loading: loadingPdfOrder }, executeFetchPdfOrder] = useAxios(
    {
      url: `/warehouse/order-registration/report`,
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on print order
   *
   * @return {*}
   */
  const onPrintOrder = async () => {
    const orderIdsChoose = ref?.current?.instance?.option().selectedRowKeys;
    if (orderIdsChoose.length === 0) {
      notification({
        type: 'error',
        message: t('You must be select at least one bill. Please try again!'),
      });
      return;
    }

    try {
      const res = await executeFetchPdfOrder({
        data: orderIdsChoose,
      });
      const arrayData: any = new Uint8Array(res.data);
      const blob = new Blob([arrayData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      printJS(url);
    } catch (e) {
      notification({ type: 'error', message: t('Get Order Bill Fail') });
    }
  };

  /**
   * on send mail
   *
   * @return {*}
   */
  const onSendMail = async () => {
    const orderIdsChoose = ref?.current?.instance?.option().selectedRowKeys;
    if (orderIdsChoose.length !== 1) {
      notification({
        type: 'error',
        message: t('You just can select one bill. Please try again!'),
      });
      setShowModalSendMail(false);
      deselectAll();
      return;
    }
    const orderIdChoose = orderIdsChoose[0];
    setOrderId(orderIdChoose);

    const billIdChoose = store?._array
      ?.filter(o => o?.ctOrderId === orderIdChoose)
      ?.map(o => o?.ctOrderBill)[0];
    setBillId(billIdChoose);

    const vendorMailChoose = store?._array
      ?.filter(o => o?.ctOrderId === orderIdChoose)
      ?.map(o => o?.vendorEmail)[0];

    setToMail(vendorMailChoose);

    setShowModalSendMail(true);
  };

  const columns: any = [
    {
      dataField: 'ctOrderId',
      caption: t('ID'),
      alignment: 'center',
      fixed: true,
    },
    {
      dataField: 'ctOrderBill',
      caption: t('Bill ID'),
      alignment: 'left',
      fixed: true,
    },
    {
      dataField: 'ctOrderDt',
      caption: t('Date'),
      dataType: 'date',
      format: DateFormat,
      alignment: 'left',
    },
    {
      dataField: 'vendorName',
      caption: t('Vendor'),
      alignment: 'left',
    },
    {
      dataField: 'ctOrderStateName',
      caption: t('Order State'),
      alignment: 'left',
    },
    {
      dataField: 'sumEa',
      caption: t('Sum(EA)'),
      dataType: 'number',
      format: QtyFormat,
    },
    {
      dataField: 'sumAmount',
      caption: t('Sum Amount'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'supplyAmount',
      caption: t('Supply Amount'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'vat',
      caption: t('Purchase VAT'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'bottle',
      caption: t('Bottle'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'container',
      caption: t('Container'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'gtotal',
      caption: t('G.Total'),
      dataType: 'number',
      format: AmountFormat,
    },
    {
      dataField: 'ctOrderMstNote',
      caption: t('Note'),
    },
    {
      dataField: 'ctOrderStatusNm',
      caption: t('Status'),
      cellRender: renderStatus,
      fixed: true,
      fixedPosition: 'right',
    },
    {
      caption: t('Action'),
      cellRender: renderAction,
      fixed: true,
      fixedPosition: 'right',
    },
  ];

  return (
    <div>
      <LoadPanel visible={loading || loadingPdfOrder} />
      <BreadCrumbPremium />
      <FormSearch />
      <DataGridFull
        ref={ref}
        dataSource={store}
        columns={columns}
        storageKey="2023.1_mst"
        options={{
          key: 'ctOrderId',
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Create New'),
                  onClick: () => setModeView({ type: 'create' }),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'trash',
                  text: t('Delete'),
                  // disabled: !selectedKeys?.length,
                  onClick: () => validateDelete(),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'email',
                  text: t('Send Email'),
                  onClick: () => onSendMail(),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'print',
                  text: t('Print Order Report'),
                  onClick: () => onPrintOrder(),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
          paging: {
            pageSize: 20,
          },
          summary: {
            totalItems: [
              {
                column: 'sumEa',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'sumAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'bottle',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'gtotal',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
      <PopupConfirmDelete
        visible={showModalDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length,
        )}
        onOk={onDelete}
        onHiding={() => setShowModalDelete(!showModalDelete)}
      />
      <PopupConfirm
        visible={showModalConfirm}
        content={t('Are you sure you want to change status to Fixed?')}
        onOk={handleConfirm}
        onHiding={() => {
          setShowModalConfirm(false);
          setKeyToFixed(null);
        }}
      />
      {showModalSendMail && (
        <SendMail
          setShowModalSendMail={setShowModalSendMail}
          toMail={toMail}
          billId={billId}
          orderId={orderId}
        />
      )}
    </div>
  );
}

export default forwardRef(ListOrder);
