/**
 * Update date: 08-06-2023
 * Datagrid component
 */
import { Template } from 'devextreme-react';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';
import { dxDataGridColumn } from 'devextreme/ui/data_grid';
import useDictionary from 'hooks/useDictionary';
import React, { forwardRef, useCallback } from 'react';


export interface IDataGridSimpleProps {
  dataSource?: any;
  columns?: dxDataGridColumn | any;
  options?: any;
}
const DataGridSimple = (
  { dataSource, columns, options }: IDataGridSimpleProps,
  ref,
) => {
  const { t } = useDictionary({});
  const totalCount = useCallback(() => {
    const count = dataSource?.length || dataSource?._array?.length || 0;
    if (!count) return '';
    return `${count} ${count > 1 ? t('items') : t('item')}`;
  }, [dataSource]);
  return (
    <DataGrid
      ref={ref}
      dataSource={dataSource || []}
      pager={{
        allowedPageSizes: [10, 20, 50, 100],
        displayMode: 'compact',
        showNavigationButtons: true,
        visible: true,
        showPageSizeSelector: true,
        showInfo: true,
      }}
      {...options}
    >
      {(columns || []).map((o, i) => (
        <Column key={i} {...o} />
      ))}
      <Template name="totalCount" render={totalCount} />
    </DataGrid>
  );
};

export default forwardRef(DataGridSimple);
