/**
 * Update date: 31-05-2023
 * Screen 2051.3
 */
import React, { useState, useEffect, useContext, createContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import useDictionary from 'hooks/useDictionary';

const ListInventory = React.lazy(() => import('./ListInventory'));
/**
 * create context
 */
const InventoryReceivingForwardingHistoryByProductContext = createContext({});

export const useInventory = () => {
  const context = useContext(
    InventoryReceivingForwardingHistoryByProductContext,
  );
  return context;
};

export default function InventoryReceivingForwardingHistoryByProduct() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({
    programId: '2051.3',
  });
  const [params, setParams] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    product: '',
    productType: '',
    zone: '',
    formStore: {
      product: [],
      productType: [],
      zone: [],
    },
  });
  const [{ data, loading }, refetchInventoryData] = useAxios(
    {
      url: 'warehouse/inventoryHistory',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );
  const store: any = new ArrayStore({
    data: data?.data?.map((o, i) => ({
      ...o,
      id: i,
    })),
    key: 'id',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      formStore: {
        ...preState?.formStore,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const loadingAll = loading || loadingProductTpPermission;

  const value: any = {
    t,
    store,
    params,
    setParams,
    refetchInventoryData,
  };

  return (
    <InventoryReceivingForwardingHistoryByProductContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <ListInventory />
      </React.Suspense>
    </InventoryReceivingForwardingHistoryByProductContext.Provider>
  );
}
