import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {
  selectCurrentUser,
  selectIsLoggedIn,
} from 'store/features/auth/authSlice';
import { router } from 'constant/router';
import { useHistory } from 'react-router';

interface IPrivateRoute {
  path?: string;
  component?: any;
  children?: any;
  exact?: boolean;
  roles?: number[];
}

export default function PrivateRoute({
  component: Component,
  roles,
  ...rest
}: IPrivateRoute) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const currentUser = useSelector(selectCurrentUser);
  const history = useHistory();
  const permissionRoute = (roles || []).includes(currentUser?.type);

  if (!isLoggedIn) {
    history.push(router.login);
    return null;
  }
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (permissionRoute) return <Component />;
        return (
          <Redirect
            to={{
              pathname: router.not_found_page,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
