/**
 * Update date: 13-06-2023
 * Popup preview image component
 */
import { Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React from 'react';
import { isMobile } from 'react-device-detect';
interface IPopupPreviewImg {
  visible?: boolean;
  onHiding?: () => void;
  title?: string;
  url?: string;
  isOrigin?: boolean;
}
function PopupPreviewImg({
  visible,
  onHiding,
  title,
  url,
  isOrigin = false,
}: IPopupPreviewImg) {
  const { t } = useDictionary({});
  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <div
        style={{
          padding: '0 15px',
          height: 'inherit',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: isOrigin ? 'auto' : '100%',
            height: isOrigin ? 'auto' : '100%',
          }}
          src={url}
          alt=""
        />
      </div>
    );
  };
  return (
    <Popup
      visible={visible}
      closeOnOutsideClick={true}
      showCloseButton={true}
      onHiding={onHiding}
      title={title || t('Preview')}
      contentRender={content}
      height={isMobile ? '80vh' : isOrigin ? '85vh' : '80vh'}
      width={isMobile ? '96%' : isOrigin ? '70vw' : '80vw'}
    />
  );
}

export default PopupPreviewImg;
