/**
 * Update date: 15-06-2023
 * Screen 2087.9
 */
import React, { useRef, memo, useMemo } from 'react';
import moment from 'moment';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';

// File name export data grid
const exportFileName = 'SMDC_2087.9 by month_' + moment().format('YYYYMMDD');

/**
 * Grid by month component
 *
 * @param {*} { data, t }
 * @return {*}
 */
function GridByMonth({ data, t }) {
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();
  const dataGridRef: any = useRef(null);

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const columns = useMemo(() => {
    const columnbase = [
      {
        dataField: 'date',
        caption: t('Sales Month'),
        width: 100,
      },
      {
        dataField: 'total_amount',
        caption: t('Total Sales Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'sales_amount',
        caption: t('Sales Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'supply_amount',
        caption: t('Supply Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'vat_amount',
        caption: t('VAT'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'container_amount',
        caption: t('Container Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'taxation_amount',
        caption: t('Taxation'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'taxfree_amount',
        caption: t('Tax Free'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'taxzero_amount',
        caption: t('Tax Zero'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'bill_count',
        caption: t('Bill Count'),
        format: QtyFormat,
        dataType: 'number',
      },
      {
        dataField: 'bill_unit_price',
        caption: t('Bill Unit Price'),
        format: DoubleFormat,
        dataType: 'number',
      },
      {
        dataField: 'sales_cost',
        caption: t('Sales Cost'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'sales_cost_vat',
        caption: t('Sales Cost (VAT)'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'profit_rate',
        caption: t('Profit Rate'),
        format: DoubleFormat,
        dataType: 'number',
      },
      {
        dataField: 'profit_amount',
        caption: t('Profit Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'local_amount',
        caption: t('Direct Supply Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
      {
        dataField: 'consignvendor_amount',
        caption: t('Rent Supply Amount'),
        format: AmountFormat,
        dataType: 'number',
      },
    ];
    const totalItems: any = [];
    const productTypes = data?.productTypes || [];
    if (productTypes.length) {
      productTypes.forEach(pt => {
        columnbase.push({
          dataField: `tp${pt.code}_amount`,
          caption: pt.name,
          format: AmountFormat,
          dataType: 'number',
        });
        totalItems.push({
          column: `tp${pt.code}_amount`,
          summaryType: 'sum',
          valueFormat: AmountFormat,
          displayFormat: '{0}',
          dataType: 'number',
        });
      });
    }
    return {
      columns: columnbase,
      totalItems: totalItems,
    };
  }, [data]);

  return (
    <DataGridFull
      storageKeyInquiry="month"
      columns={columns.columns}
      ref={dataGridRef}
      dataSource={data?.data || []}
      fixedLeft={1}
      fixedRight={0}
      options={{
        columnAutoWidth: true,
        onToolbarPreparing: (e: any) => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'totalCount',
            },
            {
              location: 'before',
              widget: 'dxButton',
              options: {
                disabled: !data?.data?.length,
                icon: 'xlsxfile',
                text: t('Export to Excel'),
                onClick: () => exportExcel(),
              },
            },
          );
        },
        export: {
          fileName: exportFileName,
        },
        summary: {
          totalItems: [
            {
              column: 'total_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'sales_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'supply_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'vat_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'container_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'taxation_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'taxfree_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'taxzero_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'bill_count',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: QtyFormat,
              displayFormat: '{0}',
            },
            {
              column: 'sales_cost',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'sales_cost_vat',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'profit_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'local_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            {
              column: 'consignvendor_amount',
              dataType: 'number',
              summaryType: 'sum',
              valueFormat: AmountFormat,
              displayFormat: '{0}',
            },
            ...columns.totalItems,
          ],
        },
      }}
    />
  );
}

export default memo(GridByMonth);
