/**
 * Update date:2023-08-02
 * Screen 2044.2t
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';

const List = React.lazy(() => import('./List'));
const Product = React.lazy(() => import('./Product'));
const Detail = React.lazy(() => import('./Detail'));
const CancelStockAllocation = React.lazy(
  () => import('./CancelStockAllocation'),
);

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2044_2t_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2044_2T = () => {
  const { t }: any = useDictionary({ programId: '2044.2t' });
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    orderId: '',
    productType: '',
    isCancelStock: false,
  });
  const [dataListLocal, setDataListLocal] = useLocalStorage(
    '2044.2t_dataList',
    {},
  );
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    fromDeliveryDate: null,
    endDeliveryDate: null,
    fromDeliveryTime: null,
    endDeliveryTime: null,
    dataList: {
      shippingMethod: [],
      mall: [],
      productType: [],
      storeGroup: [],
      store: [],
      receivableCd: [],
    },
    shippingMethod: '',
    mall: '',
    productType: '',
    storeGroup: '',
    store: '',
    receivableCd: '',
  });

  const [formInfoData, setFormInfoData] = useState<any>({
    sendDue: new Date(),
    waybillRule: '2',
    note: '',
    dataList: {
      sendUser: [],
    },
    sendUser: '',
  });

  const [dataCancelStock, setDataCancelStock] = useState([]);
  const [formSearchCancel, setFormSearchCancel] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    waybill: '',
    dataList: {
      shippingMethod: [],
      sendUser: [],
      allocation: [],
    },
    shippingMethod: '',
    sendUser: '',
    allocation: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        mall: dataListLocal?.mall || [],
        receivableCd: dataListLocal?.receivableCd || [],
        shippingMethod: dataListLocal?.shippingMethod || [],
      },
    }));
  }, [dataListLocal]);

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    formInfoData,
    setFormInfoData,
    //cancel stock
    dataCancelStock,
    setDataCancelStock,
    formSearchCancel,
    setFormSearchCancel,
    dataListLocal,
    setDataListLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'product':
        return <Product />;
      case 'detail':
        return <Detail />;
      case 'cancelStock':
        return <CancelStockAllocation />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2044_2T;
