import { all } from 'redux-saga/effects';
import { authSaga } from './features/auth/authSaga';
import { registerProgramSaga } from './features/registerProgram/registerProgramSaga';
import { centerRegistration } from './features/centerRegistration/centerRegistrationSaga';
import { centerOperatorSaga } from './features/centerOperator/centerOperatorSaga';
import { mainMenuSaga } from './features/mainMenu/mainMenuSaga';
import { mainMenuFixedSaga } from './features/mainMenu/mainMenuFixedSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    registerProgramSaga(),
    centerRegistration(),
    centerOperatorSaga(),
    mainMenuSaga(),
    mainMenuFixedSaga(),
  ]);
}
