/**
 * Update date: 08-06-2023
 * My page component
 */
import { Button, ScrollView } from 'devextreme-react';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/features/auth/authSlice';
import * as languageList from 'utils/language.json';
import * as fontList from 'utils/font.json';
import { avatarDefault } from 'images';
import { useMyPage } from '..';
import useDictionary from 'hooks/useDictionary';
import { locale } from 'devextreme/localization';
import { useApp } from 'app';
import { LANG_DEFAULT } from 'constant';

function SettingList() {
  const appContext: any = useApp();
  const { myPageApp } = appContext || {};
  const myPageContext: any = useMyPage();
  const { setModeView, onHiding } = myPageContext || {};
  const [myPageLanguage, setMyPageLanguage] = useState<string>('');
  const [myPageFont, setMyPageFont] = useState<string>('');
  const dispatch = useDispatch();

  const { t }: any = useDictionary({
    programId: '1031.7',
  });

  /**
   * on open new tab
   *
   */
  const onOpenNewTab = () => {
    const currentPath = window.location.origin;
    window.open(currentPath, '_blank');
  };

  /**
   * on edit
   *
   * @param {string} editType
   */
  const onEdit = (editType: string) => {
    setModeView({ type: 'edit', editFormType: editType });
  };

  /**
   * on logout
   *
   */
  const onLogout = () => {
    const lang =
      JSON.parse(localStorage.getItem('myPagePreference') || '{}')?.language ||
      LANG_DEFAULT;
    locale(lang);
    dispatch(authActions.logout());
  };

  useEffect(() => {
    if (!myPageApp) return;

    const language = languageList?.list.find(
      item => item.value == myPageApp?.language,
    ) || { label: '' };
    setMyPageLanguage(language?.label);
    locale(myPageApp?.language || LANG_DEFAULT);
    const font = fontList?.list.find(
      item => item.value == myPageApp?.fontName,
    ) || { label: '' };
    setMyPageFont(font?.label);
  }, [myPageApp]);

  return (
    <>
      <ScrollView width="100%" height="calc(80vh - 180px)">
        <div className="my-page-content">
          {/* Account Detail */}
          <div className="my-page-content-card">
            <div
              onClick={() => onEdit('accountDetail')}
              className="title dx-form-group-caption"
            >
              {t('Account Detail')}
            </div>
            <div className="">
              {t('Edit contact information and address, etc.')}
            </div>
            <div className="image-wrap">
              <img
                className="avatar"
                src={myPageApp?.avatar || avatarDefault}
                alt="avatar"
              />
              <div className="">{myPageApp?.name || ''}</div>
            </div>
            <Button
              className="edit-btn"
              icon="edit"
              text={t('Edit')}
              onClick={() => onEdit('accountDetail')}
            />
          </div>
          {/* Preference */}
          <div className="my-page-content-card">
            <div
              onClick={() => onEdit('preference')}
              className="title dx-form-group-caption"
            >
              {t('Preference')}
            </div>
            <div className="">{t('Change the time, language, font, etc.')}</div>
            <div className="">
              {t('Language')}:&nbsp;{myPageLanguage}
            </div>
            <div className="">
              {t('Font Type')}:&nbsp;{myPageFont}
            </div>
            <Button
              className="edit-btn"
              icon="edit"
              text={t('Edit')}
              onClick={() => onEdit('preference')}
            />
          </div>
          {/* Security */}
          <div className="my-page-content-card">
            <div
              onClick={() => onEdit('password')}
              className="title dx-form-group-caption"
            >
              {t('Password and Security')}
            </div>
            <div className="">{t('Set password and security.')}</div>

            <Button
              className="edit-btn"
              icon="edit"
              text={t('Edit')}
              onClick={() => onEdit('password')}
            />
          </div>
          {/* Open new tab */}
          <div className="my-page-content-card">
            <div onClick={onOpenNewTab} className="title dx-form-group-caption">
              {t('Duplicate Page')}
            </div>
            <div className="">{t('Execute additional programs.')}</div>

            <Button
              className="open-new-tab-btn"
              text={t('Open new tab')}
              onClick={onOpenNewTab}
            />
          </div>
        </div>
      </ScrollView>
      <div className="my-page-btn-footer">
        <Button icon="close" text={t('Close')} onClick={onHiding} />
        <Button icon="arrowright" text={t('Logout')} onClick={onLogout} />
      </div>
    </>
  );
}

export default SettingList;
