/**
 * Update date: 08-06-2023
 * My page component
 */
import { Button } from 'devextreme-react';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import useAxios from 'axios-hooks';
import './style.scss';
import useDictionary from 'hooks/useDictionary';

const allowedFileExtensions = ['.jpeg', '.png', '.jpg'];

const UploadImage = props => {
  const { value, onChangeValue } = props?.data?.editorOptions || {};
  const [imageSource, setImageSource] = useState(value);
  const [isDropZoneActive, setIsDropZoneActive] = useState(false);

  const { t }: any = useDictionary({
    programId: '1031.7',
  });

  const [{ data, loading }, executePut] = useAxios(
    {
      url: '/user/upload/image',
      method: 'POST',
    },
    { manual: true },
  );

  /**
   * on upload
   *
   * @param {*} e
   */
  const onUploaded = e => {
    const file = e.file;
    const fileReader: any = new FileReader();
    fileReader.onload = () => {
      setIsDropZoneActive(false);
      setImageSource(fileReader?.result);
    };
    fileReader.readAsDataURL(file);
  };
  /**
   * on drop enter
   *
   * @param {*} e
   */
  const onDropZoneEnter = e => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      setIsDropZoneActive(true);
    }
  };

  /**
   * on drop leave
   *
   * @param {*} e
   */
  const onDropZoneLeave = e => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      setIsDropZoneActive(false);
    }
  };

  const onUploadStarted = () => {
    setImageSource('');
  };

  const onClearImage = () => {
    setImageSource('');
  };

  const onValueChanged = (e: any) => {
    if (!e) return;
    const formData = new FormData();
    formData.append('file', e.value[0]);
    executePut({
      data: formData,
    });
  };

  useEffect(() => {
    if (data?.status == 200) {
      setImageSource(data?.data);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (value !== imageSource) {
      onChangeValue(imageSource);
    }
  }, [value, imageSource]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      className="uploadImg"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          id="dropzone-external"
          className={`flex-box ${
            isDropZoneActive
              ? 'dx-theme-accent-as-border-color dropzone-active'
              : 'dx-theme-border-color'
          }`}
        >
          {imageSource ? (
            <img id="dropzone-image" src={imageSource} alt="" />
          ) : (
            <div id="dropzone-text" className="flex-box">
              <span>{t('No Image')}</span>
            </div>
          )}
        </div>
        <Button
          // icon="clear"
          text={t('Clear')}
          className="btn-cancel-custom"
          onClick={onClearImage}
        />
        <FileUploader
          id="file-uploader"
          // dialogTrigger="#dropzone-external"
          dropZone="#dropzone-external"
          multiple={false}
          allowedFileExtensions={allowedFileExtensions}
          accept=".jpeg, .png, .jpg"
          uploadMode="useButtons"
          visible={true}
          onDropZoneEnter={onDropZoneEnter}
          onDropZoneLeave={onDropZoneLeave}
          onUploaded={onUploaded}
          onUploadStarted={onUploadStarted}
          onValueChanged={onValueChanged}
          labelText=""
          selectButtonText={t('File')}
          maxFileSize={5 * 1024 * 1024}
          elementAttr={{
            class: 'form-upload-custom',
          }}
        />
      </div>
    </div>
  );
};

export default UploadImage;
