/**
 * Update date: 31-05-2023
 * Screen 2046.7
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FormCreate from './FormCreate';
import ListCenter from './ListCenter';
/**
 * create context
 */
const CenterReturnContext = createContext({
  params: {},
  setParams: () => {},
  refetchData: () => {},
  listData: {} || null,
  modeView: {},
  setModeView: () => {},
});

CenterReturnContext.displayName = 'CenterReturnContext';

export const useCenterReturn = () => {
  const context = useContext(CenterReturnContext);
  return context;
};

function CenterReturnControlRegis() {
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState({
    returnControlName: '',
  });
  const [modeView, setModeView] = useState({ type: 'list' });
  const [listData, setListData] = useState<any>([]);

  const store: any = new ArrayStore({
    data: listData,
    key: 'returnControlId',
  });

  const [{ data, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/warehouse/return-control',
      method: 'POST',
    },
    { manual: true },
  );

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListCenter ref={dataGridRef} />;
      case 'create':
      case 'edit':
        return <FormCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * deselect all
   *
   */
  const deselectAll = () => {
    dataGridRef?.current?.instance?.deselectAll();
  };

  /**
   * on add
   *
   * @param {*} data
   */
  const onAdd = data => {
    setListData(preState => {
      preState.splice(0, 0, data);
      return preState;
    });
    dataGridRef?.current?.instance?.refresh();
  };

  /**
   * on update
   *
   * @param {*} data
   */
  const onUpdate = data => {
    store
      .update(data?.returnControlId, data)
      .done(() => {})
      .fail(() => {});
  };

  const value: any = {
    params,
    setParams,
    refetchData,
    store,
    deselectAll,
    modeView,
    setModeView,
    onAdd,
    onUpdate,
  };
  useEffect(() => {
    if (data?.data) {
      setListData(data?.data);
    }
  }, [data]);
  const loadingAll = loadingData;
  return (
    <CenterReturnContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </CenterReturnContext.Provider>
  );
}

export default CenterReturnControlRegis;
