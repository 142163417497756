/**
 * Update date: 13-06-2023
 * Popup scan barcode component
 */
import React, { useState } from 'react';
import { Button } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import { Popup } from 'devextreme-react/popup';
import useDictionary from 'hooks/useDictionary';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { isMobile } from 'react-device-detect';
import { useApp } from 'app';

interface IPopupScanBarcode {
  title?: string;
  visible?: boolean;
  onHiding?: () => void;
  onSubmit?: any;
  error?: any;
}
const PopupScanBarcode = ({
  title,
  visible,
  onHiding,
  onSubmit,
  error,
}: IPopupScanBarcode) => {
  const { themePro }: any = useApp();
  const { t } = useDictionary({});
  const [barcode, setBarcode] = useState('');

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      closeOnOutsideClick={true}
      showTitle={true}
      title={title || t('Scan barcode')}
      height={themePro ? 'auto' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
    >
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {themePro && (
          <div className="modal-popup-header">
            <span className="title-page">{title || t('Scan barcode')}</span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={onHiding}
              />
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f2f2f2',
          }}
        >
          <BarcodeScannerComponent
            delay={200}
            width={400}
            height={400}
            onUpdate={(err, result: any) => {
              if (result?.text) {
                setBarcode(result?.text);
                onSubmit?.(result?.text);
              } else {
                setBarcode('');
              }
            }}
          />
        </div>
        <h3
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
            margin: 20,
          }}
        >
          {barcode}
          {!barcode && error && <p className="cell-error-text"> {error} </p>}
        </h3>
        {!themePro && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <Button icon="close" text={t('Close')} onClick={onHiding} />
          </div>
        )}
      </React.Suspense>
    </Popup>
  );
};

export default PopupScanBarcode;
