/**
 * Create date: 2024-3-4
 * Screen 2044.C
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';
const CreateForm = React.lazy(() => import('./CreateForm'));
const List = React.lazy(() => import('./List'));

// const Product = React.lazy(() => import('./Product'));
// const Detail = React.lazy(() => import('./Detail'));

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2044_C_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2044_C = () => {
  const [dataListLocal, setDataListLocal] = useLocalStorage(
    '2044.C_dataList',
    {},
  );
  const { t }: any = useDictionary({ programId: '2044.C' });
  const [deliveryModal, setDeliveryModal] = useState<number>(0);
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    wayBill: '',
    orderId: '',
    productType: '',
    isCancelStock: false,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    filterName: [],
  });
  const [formSearch, setFormSearch] = useState<any>({
    filterName: [],
  });
  const [ListOrder, setListOrder] = useState([]);
  const [listF, setListF] = useState([]);
  const [dataCancelStock, setDataCancelStock] = useState([]);
  const [infoData, setInfoData] = useState<any>({
    filterName: '',
    address: '',
    startZipPostcode: '',
    endZipPostcode: '',
    addShippingAmount: '0',
    note: '',
  });
  const [formSearchCancel, setFormSearchCancel] = useState<any>({
    filterName: [],
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        filterName: dataListLocal?.filterName || [],
      },
    }));
  }, [dataListLocal]);

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    setListOrder,
    ListOrder,
    formSearchData,
    setFormSearchData,
    formSearch,
    setFormSearch,
    dataCancelStock,
    setDataCancelStock,
    formSearchCancel,
    setFormSearchCancel,
    setDataListLocal,
    deliveryModal,
    setDeliveryModal,
    listF,
    setListF,
    infoData,
    setInfoData,
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {};
  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
      case 'create':
      case 'edit':
        return <CreateForm />;
      default:
        return <NotFoundPage />;
    }
  };
  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2044_C;
