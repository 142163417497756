/**
 * Create date: 2024-06-20
 * Screen 2082.E
 */

import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import PopupConfirmWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmWithRef';
import useAxios from 'axios-hooks';
import { toNumberAmount } from 'hooks/toNumberAmount';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { memo, useRef } from 'react';
import { notification } from 'utils/notification';

const getProductSaleAmount = record => {
  const { totalSaleAmount = 0, discountAmount = 0 } = record || {};
  return toNumberAmount(totalSaleAmount - discountAmount);
};

const getSaleCommissionAmount = record => {
  const { suppCommision = 0 } = record || {};
  return toNumberAmount(getProductSaleAmount(record) * (suppCommision * 0.01));
};

const getReceiveAmount = record => {
  return toNumberAmount(
    getProductSaleAmount(record) - getSaleCommissionAmount(record),
  );
};
const getSupplyAmount = record => {
  const { taxRate = 0, purchaseIncvat } = record || {};
  if (purchaseIncvat) {
    return toNumberAmount(getReceiveAmount(record) / (1 + taxRate * 0.01));
  }
  return toNumberAmount(getReceiveAmount(record) * (1 - taxRate * 0.01));
};
const getVatAmount = record => {
  return toNumberAmount(getReceiveAmount(record) - getSupplyAmount(record));
};

const m2082_EColumnEdit = ['discountAmount', 'suppCommision'];

const DetailTab = ({ t, store, formSearchRef }) => {
  const dataGridRef: any = useRef(null);
  const confirmRef: any = useRef(null);
  const { QtyFormat, AmountFormat } = useFormat();
  const { DateFormat } = useFormatTime();
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on validate save
   *
   * @return {*}
   */
  const validateSave = () => {
    const dataGridInstance: any = dataGridRef?.current?.instance;
    if (dataGridInstance?.hasEditData?.()) {
      dataGridInstance?.saveEditData?.().then(() => {
        if (!dataGridInstance?.hasEditData?.()) {
          const dataSource =
            dataGridRef?.current?.instance?.getDataSource()?._store?._array ||
            [];
          const editData = dataSource?.filter(item => item?.status === 'edit');
          if (!editData?.length) {
            return notification({
              message: t(
                'You must be select at least one record. Please try again!',
              ),
              type: 'error',
            });
          }
          const content = `${t(
            'Are you sure to save the {0} records?',
          )}`.replace('{0}', editData?.length);
          confirmRef?.current?.onOpen?.(content);
        }
      });
    } else {
      const dataSource =
        dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
      const editData = dataSource?.filter(item => item?.status === 'edit');
      if (!editData?.length) {
        return notification({
          message: t(
            'You must be select at least one record. Please try again!',
          ),
          type: 'error',
        });
      }
      const content = `${t('Are you sure to save the {0} records?')}`.replace(
        '{0}',
        editData?.length,
      );
      confirmRef?.current?.onOpen?.(content);
    }
  };

  const onEditDetail = async () => {
    const dataSource =
      dataGridRef?.current?.instance?.getDataSource()?._store?._array || [];
    const editData = dataSource
      ?.filter(item => item?.status === 'edit')
      .map(o => ({
        rowId: o?.rowId,
        lineId: o?.lineId,
        billType: o?.billType,
        commissionRate: o?.suppCommision,
        discountAmount: o?.discountAmount,
      }));

    const res = await refetchData({
      url: `/user/m2082_e/detail`,
      method: 'PUT',
      data: { editData: editData },
    });
    notification({ res });
    if (res?.data?.status === '201') {
      formSearchRef?.current?.onSearch?.();
    }
  };
  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowUpdating = e => {
    if (e.newData) {
      const data = {
        ...e?.oldData,
        ...e.newData,
        status: 'edit',
      };
      const keysEdit = Object.keys(e.newData);
      if (
        keysEdit.includes('discountAmount') ||
        keysEdit.includes('suppCommision')
      ) {
        data.productSaleAmount = getProductSaleAmount(data);
        data.saleCommisionAmount = getSaleCommissionAmount(data);
        data.receiveAmount = getReceiveAmount(data);
        data.supplyAmount = getSupplyAmount(data);
        data.vatAmount = getVatAmount(data);
      }
      store
        .update(e?.key, { ...data })
        .done(() => {})
        .fail(() => {});
    }
  };

  const columns = [
    {
      dataField: 'status',
      caption: t('Status'),
      allowEditing: false,
      width: 90,
      cellRender: (record: any) => {
        switch (record?.value) {
          case 'edit':
            return 'V';
          default:
            return null;
        }
      },
    },
    {
      dataField: 'vendorNm',
      caption: t('Vendor'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'manufacturerNm',
      caption: t('Manufacturer'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'zoneNm',
      caption: t('Zone'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'billTypeNm',
      caption: t('Task Type'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'billDate',
      caption: t('Date'),
      dataType: 'date',
      format: DateFormat,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'billId',
      caption: t('Bill ID'),
      allowEditing: false,
    },
    {
      dataField: 'productCd',
      caption: t('Product Code'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'productNm',
      caption: t('Product Name'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'optionCd',
      caption: t('Option Code'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('QPP'),
      alignment: 'right',
      format: QtyFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'taxTpNm',
      caption: t('Taxation Classification'),
      allowEditing: false,
    },
    {
      dataField: 'saleQt',
      caption: t('Sale Qty'),
      alignment: 'right',
      format: QtyFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'totalSaleAmount',
      caption: t('Total Sales'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'discountAmount',
      caption: t('Brand Coupon Discount Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: true,
      editorOptions: {
        step: 0,
        min: 0,
      },
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'productSaleAmount',
      caption: t('Product Sales Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'suppCommision',
      caption: t('Commission (Margin)'),
      alignment: 'right',
      format: '##0.##',
      dataType: 'number',
      allowEditing: true,
      editorOptions: {
        step: 0,
        min: 0,
        max: 100,
      },
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'saleCommisionAmount',
      caption: t('Sales Commission Amount'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'receiveAmount',
      caption: t('Scheduled Payment Amount(Including VAT)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'supplyAmount',
      caption: t('Scheduled Payment Amount (Supply Price)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'vatAmount',
      caption: t('Scheduled Payment Amount (VAT)'),
      alignment: 'right',
      format: AmountFormat,
      dataType: 'number',
      allowEditing: false,
    },
  ];

  /**
   * Export excel gridview
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        columns={columns}
        fixedLeft={3}
        fixedRight={0}
        isShowZeroNumber
        options={{
          onRowUpdating: onRowUpdating,
          columnAutoWidth: true,
          groupPanel: { visible: true },
          editing: {
            mode: 'cell',
            allowUpdating: true,
            selectTextOnEditStart: true,
          },
          export: {
            fileName: `M2082.E: Report on Specific Purchase and Sales (Detail)_${moment().format(
              'YYYYMMDD',
            )}`,
          },
          onEditorPreparing: function (e) {
            const indexOf = m2082_EColumnEdit.indexOf(e?.dataField);
            if (e?.parentType === 'dataRow' && indexOf !== -1) {
              e.editorOptions.onKeyDown = function (eKey) {
                if (eKey?.event?.keyCode === 13) {
                  const dataGrid = dataGridRef?.current?.instance;
                  let nextElement: any = null;
                  if (m2082_EColumnEdit.length - 1 === indexOf) {
                    nextElement = dataGrid?.getCellElement(
                      e?.row?.rowIndex + 1,
                      m2082_EColumnEdit[0],
                    );
                  } else {
                    nextElement = dataGrid?.getCellElement(
                      e?.row?.rowIndex,
                      m2082_EColumnEdit[indexOf + 1],
                    );
                  }
                  if (nextElement) {
                    eKey?.event?.stopPropagation();
                    dataGrid?.focus(nextElement);
                    nextElement?.click();
                  }
                }
              };
            }
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export To Excel'),
                  onClick: () => exportExcel(),
                  disabled: !store?._array?.length,
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'save',
                  text: t('Save'),
                  onClick: validateSave,
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                column: 'saleQt',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalSaleAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'productSaleAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'saleCommisionAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receiveAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vatAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'saleQt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalSaleAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'productSaleAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'saleCommisionAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'receiveAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vatAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
      <PopupConfirmWithRef ref={confirmRef} onOk={onEditDetail} />
      <LoadPanel visible={loading} />
    </>
  );
};

export default memo(DetailTab);
