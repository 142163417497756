import React, { createContext, useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import SalesStatisticTable from './TableView';
import FormSearch from './FormSearch';
import { useProductType } from 'hooks/useProductType';

// Create context
const saleContext = createContext({});
saleContext.displayName = 'saleStatistics';
export const useStoreContext = () => {
  const context = useContext(saleContext);
  return context;
};

/**
 * Sales Statistics by Memeber
 *
 * @return {*}
 */
function SalesStatisticsbyMember() {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();

  const [salesData, setSalesData] = useState<any>([]);
  const [tapName, setTapName] = useState<any>('');
  const [prdData, setPrdData] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    storeGroup: '',
    store: '',
    unitPrice: '',
    productTypes: '',
  });
  const [formStore, setFormStore] = useState<any>({
    storeGroup: [],
    store: [],
    unitPrice: [],
    productTypes: [],
    receivableCd: [],
  });

  // Fetch api get data tab store
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: 'ecommerce/sale-statistics',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  // Fetch api get data tab Product
  const [{ data: dataPrd, loading: loadingPrd }, refetchDataPrd] = useAxios(
    {
      url: 'ecommerce/sale-statistics/product',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  // get product type of user
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormData(preState => ({
      ...preState,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
    setFormStore(preState => ({
      ...preState,
      productTypes: productTpPermission,
    }));
  }, [productTpPermission]);

  // Set data to state
  useEffect(() => {
    if (data?.status === '200') {
      setSalesData(data?.data);
    }
  }, [data]);

  // Set data to state
  useEffect(() => {
    if (dataPrd?.status === '200') {
      setPrdData(dataPrd?.data);
    }
  }, [dataPrd]);

  /**
   * Search data
   *
   * @param {*} data
   */
  const onSearch = async data => {
    await refetchData({
      data,
    });
  };

  // Values share with child component
  const value: any = {
    onSearch,
    formData,
    setFormData,
    formStore,
    setFormStore,
    salesData,
    refetchDataPrd,
    prdData,
    tapName,
    setTapName,
  };

  return (
    <saleContext.Provider value={value}>
      <LoadPanel
        visible={loading || loadingPrd || loadingProductTpPermission}
      />
      <FormSearch onSearch={onSearch} />
      <SalesStatisticTable />
    </saleContext.Provider>
  );
}

export default SalesStatisticsbyMember;
