/**
 * Update date: 13-06-2023
 * Popup select allocation order component
 */
import { useApp } from 'app';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView, TextBox } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

interface IPopupSelectAllocationOrderProps {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit?: void | any;
  mode?: 'single' | 'multiple';
  title?: string;
  params?: string;
}

function PopupSelectAllocationOrder({
  visible,
  onHiding,
  text,
  onSubmit,
  mode = 'single',
  title,
  params,
}: IPopupSelectAllocationOrderProps) {
  const { themePro }: any = useApp();
  const [allocation, setAllocation] = useState(text || '');
  const dataRef: any = useRef(null);
  const { t } = useDictionary({});
  const { QtyFormat } = useFormat();

  const [{ data: resData, loading }, fetchData] = useAxios(
    { method: 'GET' },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on search
   *
   */
  const onSearch = () => {
    fetchData({
      url: `ecommerce/order-location/popup-allocate?allocateSeq=${allocation}&${params}`,
    });
  };

  /**
   * on ok
   *
   */
  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!mode || mode === 'single') {
      const d = selectedRowKeys?.[0];
      onSubmit(d);
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  useEffect(() => {
    onSearch();
  }, []);

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">
                {title || t('Allocation Order')}
              </span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <div
            style={
              themePro
                ? {
                    padding: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }
            }
          >
            <TextBox
              width={themePro ? 400 : '40vw'}
              defaultValue={allocation}
              onValueChanged={e => setAllocation(e?.value)}
              onEnterKey={onSearch}
              showClearButton
              style={{ marginRight: 10 }}
            />
            <Button icon="search" text={t('Search')} onClick={onSearch} />
          </div>
          <DataGridFull
            ref={dataRef}
            dataSource={resData?.data}
            columns={[
              {
                dataField: 'allocate_seq',
                caption: t('Allocate Sequence'),
                alignment: themePro ? 'left' : 'center',
                format: QtyFormat,
                dataType: 'number',
              },
              {
                dataField: 'register_user_nm',
                caption: t('Registrant Name'),
                alignment: themePro ? 'left' : 'center',
              },
              {
                dataField: 'zone_nm',
                caption: t('Zone'),
                alignment: themePro ? 'left' : 'center',
              },
              {
                dataField: 'allocate_dm_str',
                caption: t('Date'),
                alignment: themePro ? 'left' : 'center',
              },
            ]}
            options={{
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: { mode },
              height: themePro
                ? 'calc(100vh - 250px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={title || t('Allocation Order')}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '55vw'}
    />
  );
}

export default PopupSelectAllocationOrder;
