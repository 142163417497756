/**
 * Update date: 06-06-2023
 * Screen 2064.6
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ListDirectDeliveryEmptyBottle from './ListDirectDeliveryEmptyBottle';
import useDictionary from 'hooks/useDictionary';
/**
 * create context
 */
const StatusCarryIntoOfDirectDeliveryEmptyBottlesContext = createContext({
  params: {},
  setParams: () => {},
  refetchReceive: () => {},
  listData: {} || null,
  modeView: {},
  setModeView: () => {},
});

StatusCarryIntoOfDirectDeliveryEmptyBottles.displayName =
  'StatusCarryIntoOfDirectDeliveryEmptyBottlesContext';

export const useStatusCarryIntoOfDirectDeliveryEmptyBottles = () => {
  return useContext(StatusCarryIntoOfDirectDeliveryEmptyBottlesContext);
};

function StatusCarryIntoOfDirectDeliveryEmptyBottles() {
  const { t }: any = useDictionary({ programId: '2064.6' });
  const dataGridRef: any = useRef(null);
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      store: [],
      receivableCd: [],
    },
    store: '',
    receivableCd: '',
  });
  const [modeView, setModeView] = useState({ type: 'list' });
  const [listData, setListData] = useState<any>([]);
  const store: any = new ArrayStore({
    data: listData,
  });
  const [{ data, loading: loadingListData }, refetchReceive] = useAxios(
    {
      url: '/warehouse/empty-container/generality',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      const dataMapping = (data?.data || []).map(o => {
        o.statusNm = o?.status === '0' ? t('Pending') : t('Fixed');
        return o;
      });
      setListData(dataMapping);
    }
  }, [data]);

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListDirectDeliveryEmptyBottle ref={dataGridRef} />;
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    formSearchData,
    setFormSearchData,
    refetchReceive,
    store,
    modeView,
    setModeView,
  };

  const loadingAll = loadingListData;
  return (
    <StatusCarryIntoOfDirectDeliveryEmptyBottlesContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </StatusCarryIntoOfDirectDeliveryEmptyBottlesContext.Provider>
  );
}

export default StatusCarryIntoOfDirectDeliveryEmptyBottles;
