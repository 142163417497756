/**
 * Update date: 23-05-2023
 * Screen 2023.3
 */
import React, { useState, useEffect, useContext, createContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { useProductType } from 'hooks/useProductType';

const ListReceiving = React.lazy(() => import('./List'));
/**
 * create context
 */
const ReceivingAggregationComparedToOrdersContext = createContext({});

ReceivingAggregationComparedToOrdersContext.displayName =
  'ReceivingAggregationComparedToOrdersContext';

export const useReceiving = () => {
  const context = useContext(ReceivingAggregationComparedToOrdersContext);
  return context;
};

export default function ReceivingAggregationComparedToOrders() {
  const [params, setParams] = useState({
    fromDate: new Date(),
    endDate: new Date(),
    vendorIds: [],
    status: '',
    productIds: [],
    largeCategories: [],
    mediumCategories: [],
    smallCategories: [],
    subCategories: [],
    productGroupIds: [],
  });
  const [dataReceiving, setDataReceiving] = useState([]);
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  // Get data receiving
  const [
    { data: resDataReceiving, loading: loadingReceivingData },
    refetchReceivingData,
  ] = useAxios(
    {
      url: 'ecommerce/receive-and-order',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const loadingAll = loadingReceivingData || loadingProductTpPermission;

  const value: any = {
    params,
    setParams,
    refetchReceivingData,
    dataReceiving,
    productTpPermission,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resDataReceiving?.data) {
      setDataReceiving(resDataReceiving?.data);
    }
  }, [resDataReceiving]);

  return (
    <ReceivingAggregationComparedToOrdersContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <ListReceiving />
      </React.Suspense>
    </ReceivingAggregationComparedToOrdersContext.Provider>
  );
}
