/**
 * Update date: 17-05-2023
 * Screen 2011.4
 */
import React, { useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import { useContainerRegistration } from '..';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { uniqBy } from 'lodash';
import useDictionary from 'hooks/useDictionary';

const FormSearch = () => {
  const { t }: any = useDictionary({ programId: '2011.4' });
  const context: any = useContainerRegistration();
  const { params, setParams, refetchData } = context;
  const [isShowPopup, setIsShowPopup] = useState(false);

  /**
   * on submit
   *
   */
  const onSubmit = () => {
    const data = {
      ...params,
      productType: params?.formStore.productType.map(o => o?.code),
    };
    delete data.formStore;
    refetchData({
      data,
    });
  };
  /**
   * on clean all
   *
   */
  const handleCleanAll = () => {
    setParams(preState => ({
      ...preState,
      productType: '',
      formStore: {
        ...preState?.formStore,
        productType: [],
      },
    }));
  };
  /**
   * on remove
   *
   * @param {*} data
   */
  const handleRemove = data => {
    const newData = params?.formStore?.productType.filter(
      (o: any) => o?.code !== data?.code,
    );
    setParams(preState => ({
      ...preState,
      productType: '',
      formStore: {
        ...preState?.formStore,
        productType: newData,
      },
    }));
  };
  /**
   * on submit popup
   *
   * @param {*} data
   */
  const handleSubmitPopup = data => {
    let newData = uniqBy(
      [...params?.formStore?.productType].concat(data),
      'code',
    );
    setParams(preState => ({
      ...preState,
      productType: '',
      formStore: {
        ...preState?.formStore,
        productType: newData,
      },
    }));
    setIsShowPopup(false);
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={params}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Product Type') },
              dataField: 'productType',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setIsShowPopup(true),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productType?.[0]?.name,
                      onClick: () =>
                        handleRemove(params?.formStore?.productType?.[0]),
                      visible: !!params?.formStore?.productType?.[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.formStore?.productType?.[1]?.name,
                      onClick: () =>
                        handleRemove(params?.formStore?.productType?.[1]),
                      visible: !!params?.formStore?.productType?.[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        params?.formStore?.productType?.length - 2
                      } ${t('more')}`,
                      visible: params?.formStore?.productType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!params?.formStore?.productType?.length,
                      onClick: () => handleCleanAll(),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setIsShowPopup(true),
                    },
                  },
                ],
                readOnly: params?.isReadOnlyProductTp,
              },
            },
            {
              label: {
                text: t('Container'),
              },
              dataField: 'containerName',
              editorOptions: {
                onEnterKey: onSubmit,
              },
            },
            {
              label: { text: t('Use') },
              dataField: 'use',
              editorType: 'dxSelectBox',
              editorOptions: {
                colSpan: 1,
                items: [
                  { label: t('All'), value: null },
                  { label: t('Use'), value: true },
                  { label: t('Not Use'), value: false },
                ],
                valueExpr: 'value',
                displayExpr: 'label',
                placeholder: '',
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {isShowPopup && (
        <PopupSelectCommonCode
          visible={isShowPopup}
          text={params.productType}
          onHiding={() => setIsShowPopup(false)}
          codeType="PT"
          mode="multiple"
          onSubmit={handleSubmitPopup}
        />
      )}
    </div>
  );
};

export default FormSearch;
