/*
 * 2082.7 Accounts Payable Inquiry By Vendor
 * @since 2022/11/27
 */
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));

// Initialize use context
const AccountPayableInquiryByVendorContext = createContext({});
AccountPayableInquiryByVendorContext.displayName =
  'AccountPayableInquiryByVendorContext';
export const useAccountPayableInquiryByVendor = () => {
  const context = useContext(AccountPayableInquiryByVendorContext);
  return context;
};

/**
 * Account Payable Inquiry By Vendor
 *
 * @export
 * @return {*}
 */
export default function AccountPayableInquiryByVendor() {
  const { t }: any = useDictionary({ programId: '2082.7' });
  const [data, setData] = useState<any>({});

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    return <List />;
  };

  // Values share with child component
  const value: any = {
    data,
    setData,
    t,
  };

  return (
    <AccountPayableInquiryByVendorContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {renderContent()}
      </React.Suspense>
    </AccountPayableInquiryByVendorContext.Provider>
  );
}
