/**
 * Update date: 07-06-2023
 * Screen 2081.8
 */
import { DataGridFull } from 'app/components/DataGrid';
import React, { forwardRef } from 'react';
import FormSearch from '../FormSearch';
import moment from 'moment';
import { useSupplyUnitPriceInquiryByMarket } from '..';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';

const ListSupplyUnitPriceInquiryByMarket = (props, ref) => {
  const { t } = useDictionary({ programId: '2081.8' });
  const context: any = useSupplyUnitPriceInquiryByMarket();
  const { columns, store } = context || {};
  /**
   * on export excel
   *
   */
  const onExportExcel = () => {
    ref?.current?.instance?.exportToExcel();
  };
  const { AmountFormat } = useFormat();

  const exportFileName = `${t(
    'SMDC_2081.8 Supply Unit Price inquiry by Market',
  )}_${moment().format('YYYYMMDD')}`;

  return (
    <div>
      <FormSearch />
      <DataGridFull
        ref={ref}
        columns={columns}
        dataSource={store}
        storageKeyInquiry="v1"
        isStorageFilter={false}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !store?._array?.length,
                  onClick: () => onExportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          selection: {
            mode: 'single',
          },
          summary: {
            totalItems: [
              {
                column: 'shippingFeeCenter Pickup_1',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'shippingFeeDirect Delivery_2',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
              {
                column: 'shippingFeeRider Delivery_3',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'shippingFeeCenter Pickup_1',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'shippingFeeDirect Delivery_2',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'shippingFeeRider Delivery_3',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default forwardRef(ListSupplyUnitPriceInquiryByMarket);
