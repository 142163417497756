/**
 * Update date: 2024-07-11
 * Screen 2024.3
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import Form from 'devextreme-react/form';
import useDictionary from 'hooks/useDictionary';
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { notification } from 'utils/notification';
interface IPopupDownloadData {
  onOk?: (data?: any) => void;
}
const PopupDownloadData = ({ onOk }: IPopupDownloadData, ref) => {
  const { t }: any = useDictionary({});
  const formRef = useRef<any>(null);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [formData, setFormData] = useState<any>({
    vendorId: null,
    seqNo: null,
  });
  const [settingFromData, setSettingFromData] = useState<any>([]);

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true },
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  useEffect(() => {
    if (!formData?.vendorId) return;
    const getSettingFromData = async () => {
      const res = await refetchData({
        url: `warehouse/consigned-delivery/setting-form/${formData?.vendorId}`,
        method: 'GET',
      });
      if (res?.data?.status === '200') {
        setSettingFromData(res?.data?.data || {});
      } else {
        notification({ res });
      }
    };
    getSettingFromData();
  }, [formData]);

  const onOpen = data => {
    if (data?.vendorId) {
      setFormData({ ...formData, ...data });
      setShowPopupConfirm(true);
    }
  };

  const onClickYes = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const printedDm = new Date();
    const res = await refetchData({
      url: `warehouse/consigned-delivery/download`,
      method: 'POST',
      data: { ...formData, printedDm: printedDm },
    });
    if (res?.data?.status === '200') {
      try {
        const binary_string = window.atob(res?.data?.data);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        const byteArray = new Uint8Array(bytes.buffer);
        const blob = new Blob([byteArray], {
          type: 'application/octet-stream',
        });
        const url = window.URL.createObjectURL(blob);
        const downloadLink = window.document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `${formData?.vendorNm}_${formData?.productTpNm}_${formData?.allocateDm}_${formData?.allocateSeq}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        onOk?.({ ID: formData?.ID, printedDm });
        setShowPopupConfirm(false);
        setFormData({ vendorId: null, seqNo: null });
      } catch (error) {
        notification({ type: 'error', message: t('Download error') });
      }
    } else {
      notification({ res });
    }
  };

  const onClickNo = () => {
    setShowPopupConfirm(false);
    setFormData({ vendorId: null, seqNo: null });
  };

  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          style={{ display: 'flex', justifyContent: 'center' }}
          items={[
            {
              label: { text: t('Setting Consignment Delivery Order Form') },
              dataField: 'seqNo',
              editorType: 'dxSelectBox',
              isRequired: true,
              editorOptions: {
                displayExpr: 'orderFormNm',
                valueExpr: 'seqNo',
                defaultValue: settingFromData?.[0]?.seqNo,
                items: settingFromData,
              },
            },
          ]}
          labelLocation="top"
          colCount={1}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
            marginTop: 40,
          }}
        >
          <Button icon="close" text={t('Cancel')} onClick={onClickNo} />
          <Button
            icon="download"
            text={t('Download')}
            stylingMode="contained"
            type="default"
            onClick={onClickYes}
          />
        </div>
      </ScrollView>
    );
  };
  const titleRender = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 35,
          fontWeight: 'bold',
        }}
      >
        <span style={{ fontSize: 20 }}>{t('Download')}</span>
      </div>
    );
  };

  return (
    <>
      <Popup
        visible={showPopupConfirm}
        titleRender={titleRender}
        contentRender={content}
        height={250}
        width={isMobile ? '96%' : '450px'}
      />
      <LoadPanel visible={loading} />
    </>
  );
};

export default memo(forwardRef(PopupDownloadData));
