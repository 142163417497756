import { CONSTANT } from 'constant';
const { passwordRegexp } = CONSTANT;

const regexPassword = value => {
  return passwordRegexp.test(value);
};

const valid: any = {
  regexPassword,
};

export { valid };
