/**
 * Update date: 29-05-2023
 * Screen 2046.3
 */
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const CenterReceivingInspectionContext = createContext({
  centerReceivingData: {},
});

CenterReceivingInspectionContext.displayName =
  'CenterReceivingInspectionContext';

export const useCenterReceivingInspection = () => {
  const context = useContext(CenterReceivingInspectionContext);
  return context;
};

export default function CenterReceivingInspection() {
  const [centerReceivingData, setCenterReceivingData] = useState({});
  const [isSubmitFired, setIsSubmitFired] = useState(false);
  const [resData, setResData] = useState<any>(null);

  // Get data
  const [
    { data: centerReceivingRes, loading: centerReceivingLoading },
    refetchData,
  ] = useAxios(
    {
      url: 'ecommerce/direct-vehicle',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  useEffect(() => {
    if (resData?.data) {
      const data = resData?.data?.list
        ?.sort((a, b) => a?.lineId - b?.lineId)
        ?.map((o, i) => ({
          ...o,
          ID: i + 1,
          status: 'normal',
        }));

      setCenterReceivingData({
        ...resData?.data,
        list: data,
      });
    } else {
      setCenterReceivingData({});
    }
  }, [resData]);
  /**
   * fetch data
   */
  useEffect(() => {
    if (centerReceivingRes?.status != 200) {
      return;
    }
    setResData(centerReceivingRes);
  }, [centerReceivingRes]);

  const loadingAll = centerReceivingLoading;

  const value: any = {
    centerReceivingData,
    setCenterReceivingData,
    refetchData,
    isSubmitFired,
    setIsSubmitFired,
    resData,
    setResData,
  };
  return (
    <CenterReceivingInspectionContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        <List />
      </React.Suspense>
    </CenterReceivingInspectionContext.Provider>
  );
}
