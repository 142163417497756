/**
 * Update date: 24-05-2023
 * Screen 2041.5
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import { useApp } from 'app';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate/index'));
/**
 * create context
 */
const ReceivingConfirmedPCContext = createContext({});

ReceivingConfirmedPCContext.displayName = 'ReceivingConfirmedPCContext';

export const useReceivingConfirmedPC = () => {
  return useContext(ReceivingConfirmedPCContext);
};

const ReceivingConfirmedPC = () => {
  const appContext: any = useApp();
  const { s } = appContext || {};
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    receiveId: null,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    receiveStatus: '',
    dataList: {
      vendor: [],
      receivingState: [],
    },
    vendor: '',
    receivingState: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ loading: loadingPdf }, executeFetchPdf] = useAxios(
    {
      url: `/warehouse/receive-confirmed/print`,
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const value: any = {
    s,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    executeFetchPdf,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
      case 'edit':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ReceivingConfirmedPCContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingPdf} />
        {renderContent()}
      </React.Suspense>
    </ReceivingConfirmedPCContext.Provider>
  );
};

export default ReceivingConfirmedPC;
