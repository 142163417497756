const PRODUCT_URL = './product/product';
const CONTAINER_URL = '/product/container';
const VENDOR_URL = './user/vendor';
const COMMON_TYPE = './core/common-code/type';
const PRODUCT_MALL_URL = '/product/product-mall';
const PRODUCT_OPTION_MALL_URL = '/product/product-option-mall';
const PRODUCT_OPTION_URL = '/product/product-option';
const PRODUCT_OPTION_SET_URL = '/product/option-set';

const WAREHOUSE_RECEIVE_URL = '/warehouse/receive';
const WAREHOUSE_FORWARD_URL = '/warehouse/forward';
const WAREHOUSE_INSTOCK_COUNT_URL = '/warehouse/instock/count';
const WAREHOUSE_RETURNABLE_AMOUNT_URL = '/warehouse/return-control/center';
const WAREHOUSE_RETURN_CONTROL_URL = 'warehouse/return-control';

const ZONE_INFO_URL = '/core/zone-info';
const ZONE_INFO_LOCATION_DEFAULT_URL = '/core/zone-info/location-default';

const USER_STORE_URL = '/user/store';
const USER_MEMBER_DUES = '/user/member-dues';
const USER_MEMBER_DETAIL_URL = '/user/member';
const USER_MEMBER_DUES_DETAIL_URL = '/user/member-dues/edit';

const CARRYOUT_INQUIRY_API_URL = '/warehouse/takeout/carry-out-inquire';
const API_URL_USER_MEMBER_LIST = 'user/member';

// 2046.5
const LOST_OTHER_ACCOUNTS_FORWARDING_URL = '/warehouse/otherForwardMst';

export {
  PRODUCT_URL,
  VENDOR_URL,
  COMMON_TYPE,
  CONTAINER_URL,
  PRODUCT_MALL_URL,
  PRODUCT_OPTION_MALL_URL,
  PRODUCT_OPTION_URL,
  PRODUCT_OPTION_SET_URL,
  WAREHOUSE_RECEIVE_URL,
  ZONE_INFO_URL,
  ZONE_INFO_LOCATION_DEFAULT_URL,
  WAREHOUSE_FORWARD_URL,
  USER_STORE_URL,
  WAREHOUSE_INSTOCK_COUNT_URL,
  CARRYOUT_INQUIRY_API_URL,
  WAREHOUSE_RETURNABLE_AMOUNT_URL,
  WAREHOUSE_RETURN_CONTROL_URL,
  USER_MEMBER_DUES,
  USER_MEMBER_DETAIL_URL,
  USER_MEMBER_DUES_DETAIL_URL,
  API_URL_USER_MEMBER_LIST,
  LOST_OTHER_ACCOUNTS_FORWARDING_URL,
};
