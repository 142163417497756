/**
 * Create date: 24-08-2023
 * Screen 2016.9
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import useLocalStorage from 'hooks/useLocalStorage';
import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const DetailProduct = React.lazy(() => import('./View'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2016_9_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2016_9 = () => {
  const [dataLocal, setDataLocal] = useLocalStorage('2016.9_dataList', {});
  const { t }: any = useDictionary({ programId: '2016.9' });
  const [data, setData] = useState([]);
  const { AmountFormat } = useFormat();
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);
  const [formSearchData, setFormSearchData] = useState<any>({
    useTf: true,
    collectionName: '',
    dataList: {
      mall: [],
      product: [],
      productGroup: [],
      largeCategoryCodes: [],
      mediumCategoryCodes: [],
      smallCategoryCodes: [],
      subCategoryCodes: [],
    },
    mall: '',
    product: '',
    productGroup: '',
    largeCategoryCodes: '',
    mediumCategoryCodes: '',
    smallCategoryCodes: '',
    subCategoryCodes: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: {},
  });

  const [listCollection, setListCollection] = useState([]);
  const [listCollectionOption, setListCollectionOption] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{}, refetchDataDisplay] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const storeCollection: any = new ArrayStore({
    data: listCollection,
    key: 'collect_id',
  });
  const storeCollectionOption: any = new ArrayStore({
    data: listCollectionOption,
    key: 'mall_collect_id',
  });

  useEffect(() => {
    if (!Object.keys(dataLocal || {})?.length) return;
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        mall: dataLocal?.mall || [],
      },
    }));
  }, [dataLocal]);

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return collapseRef?.current?.onCollappse();
    const requestData = {
      collectionName: formSearchData?.collectionName,
      useTf: formSearchData?.useTf,
      mallCode: formSearchData?.dataList?.mall?.map(o => o?.mallCode)[0] || '',
      largeCategoryCodes: formSearchData.dataList.largeCategoryCodes.map(
        (o: any) => o?.code,
      ),
      mediumCategoryCodes: formSearchData.dataList.mediumCategoryCodes.map(
        (o: any) => o?.code,
      ),
      smallCategoryCodes: formSearchData.dataList.smallCategoryCodes.map(
        (o: any) => o?.code,
      ),
      subCategoryCodes: formSearchData.dataList.subCategoryCodes.map(
        (o: any) => o?.code,
      ),
      productIds: formSearchData.dataList.product.map((o: any) => o?.productId),
      productGroupIds: formSearchData.dataList.productGroup.map(
        (o: any) => o?.productGroupId,
      ),
    };
    const res = await refetchData({
      url:
        tabIndex === 0
          ? '/warehouse/collection-option-setting/collect-display'
          : '/warehouse/collection-option-setting/option-display',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      if (tabIndex === 0) {
        setListCollection(
          (res?.data?.data || [])?.map(o => ({
            ...o,
            collect_display_tf_no: !o?.collect_display_tf,
          })),
        );
      } else {
        setListCollectionOption(
          (res?.data?.data || [])?.map(o => ({
            ...o,
            collect_option_display_tf_no: !o?.collect_option_display_tf,
          })),
        );
      }
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    setData,
    modeView,
    AmountFormat,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    storeCollection,
    storeCollectionOption,
    DateFormat,
    DateTimeFormat,
    tabIndex,
    setTabIndex,
    formRef,
    collapseRef,
    refetchDataDisplay,
    setDataLocal,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <DetailProduct />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2016_9;
