/**
 * Update date: 31-05-2023
 * Screen 2052.5
 */
import React, { useRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useInventory } from '..';
import LoadPanel from 'app/components/LoadPanel';
import useFormatTime from 'hooks/useFormatTime';
import useDictionary from 'hooks/useDictionary';

function InventoryInquiryLocationTable(props) {
  const { DateFormat } = useFormatTime();
  const { QtyFormat, DoubleFormat, IntegerFormat } = useFormat();
  const dataGridRef: any = useRef(null);
  const context: any = useInventory();
  const { inventoryData, loadingInventoryData } = context;

  const { t } = useDictionary({
    programId: '2052.5',
  });
  const columns: any = [
    {
      caption: t('Location'),
      alignment: 'center',
      items: [
        {
          dataField: 'zone_nm',
          caption: t('Zone'),
          showWhenGrouped: true,
        },
        {
          dataField: 'location_cd',
          caption: t('Location Code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'location_nm',
          caption: t('Location'),
          showWhenGrouped: true,
        },
        {
          dataField: 'location_route_order',
          caption: t('Route Order'),
          dataType: 'number',
          format: IntegerFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'location_load_tp_nm',
          caption: t('Load Type'),
          showWhenGrouped: true,
        },
        {
          dataField: 'location_distribution_period_dm',
          caption: t('Distribution Period'),
          dataType: 'date',
          format: DateFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'product_cd',
          caption: t('Product Code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'product_nm',
          caption: t('Product'),
          showWhenGrouped: true,
        },
        {
          dataField: 'product_maker_nm',
          caption: t('Maker'),
          showWhenGrouped: true,
        },
        {
          dataField: 'marketable_size',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        {
          dataField: 'vendor_nm_default',
          caption: t('Default Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'product_cost',
          caption: t('Product Cost'),
          showWhenGrouped: true,
          dataType: 'number',
          format: DoubleFormat,
        },
        {
          dataField: 'stock_ea',
          caption: t('Stock Ea'),
          showWhenGrouped: true,
          dataType: 'number',
          format: QtyFormat,
        },
        {
          dataField: 'product_origin_nm',
          caption: t('Origin'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'large_category_nm',
          caption: t('Large Category'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'medium_category_nm',
          caption: t('Medium Category'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'small_category_nm',
          caption: t('Small Category'),
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'sub_category_nm',
          caption: t('Sub Category'),
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
  ];

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  const exportFileName =
    'SMDC_2052.5 Inventory inquiry by location_' + moment().format('YYYYMMDD');

  return (
    <>
      <LoadPanel visible={loadingInventoryData} />
      <DataGridFull
        storageKeyInquiry
        columns={columns}
        ref={dataGridRef}
        dataSource={inventoryData}
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !inventoryData.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                name: 'Stock Ea',
                column: 'stock_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'stock_ea',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
              },
            ],
          },
        }}
      />
    </>
  );
}

export default InventoryInquiryLocationTable;
