/**
 * Update date: 26-05-2023
 * Screen 2043.4
 */
import React, { useState, forwardRef } from 'react';
import Form from 'devextreme-react/form';
import PureCollapse from 'app/components/PureCollapse';
import useFormatTime from 'hooks/useFormatTime';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import { useModuleContext } from '..';
import '../styles.scss';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { useApp } from 'app';
import Collapse from 'app/components/Collapse';
import { cloneDeep, uniqBy } from 'lodash';
import moment from 'moment';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const FormDetail = (props, ref) => {
  const { themePro }: any = useApp();
  const context: any = useModuleContext();
  const { t, formInfo, setFormInfo, calculateUnitPrice, setDataLocal } =
    context;
  const { DateFormat } = useFormatTime();

  const [showPopupByType, setShowPopupByType] = useState('');

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formInfo?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    let newData = {
      ...formInfo,
      dataList: dataList,
    };
    if (storedType === 'store') {
      newData = {
        ...newData,
        mallCode: 'AOS_C',
        receiver: '',
        phoneNo: '',
        mobileNo: '',
        postCode: '',
        basicAdd: '',
        detailAdd: '',
        supplyUnitPriceGroup: '',
      };
    }
    if (storedType === 'shipmethod') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
    setFormInfo(newData);
  };

  /**
   * on update
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formInfo?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    let newData = {
      ...formInfo,
      dataList: dataList,
      [storedType]: '',
    };
    if (storedType === 'store') {
      newData = {
        ...newData,
        mallCode: 'AOS_C',
        receiver: data?.representative,
        phoneNo: data?.phone,
        mobileNo: data?.mobile,
        postCode: data?.deliveryPostCd,
        basicAdd: data?.deliveryAddressBasic,
        detailAdd: data?.deliveryAddressDetail,
        supplyUnitPriceGroup: data?.unitPriceCode,
      };
    }
    if (storedType === 'shipmethod') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
    setFormInfo(newData);
    setShowPopupByType('');
    const storeId = dataList?.store[0]?.storeNo;
    const shipmentTp = dataList?.shipmethod?.[0]?.code;
    calculateUnitPrice(storeId, shipmentTp, newData?.ordDate);
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {number} [numberShowMore=2]
   * @param {IOptionItem} [option]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    numberShowMore = 2,
    option?: IOptionItem,
  ): any => {
    const dataList = formInfo?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired || false,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'store':
        return (
          <PopupSelectStore
            text={formInfo?.[popupType]}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('store', o)}
          />
        );
      case 'shipmethod':
        return (
          <PopupSelectCommonCode
            text={formInfo?.[popupType]}
            mode="single"
            codeType="FT"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('shipmethod', o)}
          />
        );
    }
  };

  /**
   * render form
   *
   * @return {*}
   */
  const FormState = () => {
    return (
      <>
        <div>
          <Form
            ref={ref}
            formData={formInfo}
            showColonAfterLabel={false}
            labelLocation="top"
            onFieldDataChanged={e => {
              if (e?.dataField === 'ordDate') {
                if (e?.value) {
                  const storeId = formInfo?.dataList?.store[0]?.storeNo;
                  const shipmentTp = formInfo?.dataList?.shipmethod?.[0]?.code;
                  calculateUnitPrice(storeId, shipmentTp, e?.value);
                }
                if (!e?.value || !formInfo?.deliveryRequestDm) return;
                if (
                  moment(e?.value).format('YYYY-MM-DD') >
                  moment(formInfo.deliveryRequestDm).format('YYYY-MM-DD')
                ) {
                  ref?.current?.instance?.updateData('deliveryRequestDm', null);
                }
              }
            }}
            colCount={3}
            items={[
              {
                dataField: 'ordDate',
                editorType: 'dxDateBox',
                colSpan: 1,
                label: {
                  text: t('Date'),
                },
                editorOptions: {
                  max: new Date(),
                  displayFormat: DateFormat,
                },
                isRequired: !formInfo?.ordDate,
              },
              generateDxTextBox('Store', 'store', 'storeNm', 1, {
                isRequired: !formInfo?.dataList?.store?.length,
              }),
              generateDxTextBox('Shipping Method', 'shipmethod', 'name', 1, {
                isRequired: !formInfo?.dataList?.shipmethod?.length,
              }),
              {
                label: { text: t('Order Number') },
                dataField: 'orderNumber',
                editorType: 'dxTextBox',
                editorOptions: {
                  showClearButton: true,
                },
              },
              {
                label: { text: t('Shipping Message') },
                dataField: 'shippingMessage',
                editorType: 'dxTextBox',
                colSpan: 2,
              },
              {
                label: { text: t('Receiver') },
                dataField: 'receiver',
                editorType: 'dxTextBox',
              },
              {
                label: { text: t('Phone No') },
                dataField: 'phoneNo',
                editorType: 'dxTextBox',
              },
              {
                label: { text: t('Mobile No') },
                dataField: 'mobileNo',
                editorType: 'dxTextBox',
              },
              {
                label: { text: t('Post Code') },
                dataField: 'postCode',
                editorType: 'dxTextBox',
              },
              {
                label: { text: t('Address') },
                dataField: 'basicAdd',
                editorType: 'dxTextBox',
                colSpan: 2,
              },
              {
                label: { text: t('Wished Delivery Date Time') },
                dataField: 'deliveryRequestDm',
                editorType: 'dxDateBox',
                editorOptions: {
                  min: moment(formInfo?.ordDate).format('YYYY-MM-DD 00:00:00'),
                  type: 'date',
                  displayFormat: DateFormat,
                },
              },
              {
                label: { text: t('Address 2') },
                dataField: 'detailAdd',
                editorType: 'dxTextBox',
                colSpan: 2,
              },
            ]}
          ></Form>
        </div>
      </>
    );
  };

  return (
    <div>
      {themePro ? (
        <Collapse hideSubmit showCollapse>
          <FormState />
        </Collapse>
      ) : (
        <PureCollapse>
          <FormState />
        </PureCollapse>
      )}

      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default forwardRef(FormDetail);
