/**
 * Update date: 2023-11-30
 * Screen 2011.B
 */
import React, {
  useState,
  useContext,
  createContext,
  memo,
  useEffect,
} from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import { useProductType } from 'hooks/useProductType';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const CreateEditView = React.lazy(() => import('./CreateEditView'));

const defaultParams = {
  complexName: '',
  tradeType: '0',
  isReadOnlyProductTp: false,
  dataList: {
    largeCategory: [],
    mediumCategory: [],
    smallCategory: [],
    subCategory: [],
    vendor: [],
    productMakers: [],
    productTypes: [],
  },
  productTypes: '',
  productMakers: '',
  vendor: '',
  largeCategory: '',
  mediumCategory: '',
  smallCategory: '',
  subCategory: '',
};

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2011_B_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const ProductRegistration = () => {
  const { t } = useDictionary({ programId: '2011.B' });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [modeView, setModeView] = useState<{ type: any; data?: any }>({
    type: 'list',
    data: null,
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'complexId',
  });

  useEffect(() => {
    const getTaxData = async () => {
      const res = await refetchData({
        url: '/core/center-info/tax',
        method: 'GET',
      });
      if (res?.data?.status == '200') {
        setTaxList(res?.data?.data || []);
      } else {
        notification({ res });
      }
    };
    getTaxData();
  }, []);
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(prev => ({
      ...prev,
      isReadOnlyProductTp: productTpPermission?.length > 0,
      dataList: {
        ...prev.dataList,
        productTypes: productTpPermission,
      },
    }));
  }, [productTpPermission]);

  const value: any = {
    t,
    store,
    setData,
    params,
    setParams,
    modeView,
    setModeView,
    taxList,
    refetchData,
  };

  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <CreateEditView />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * render content
   */
  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading || loadingProductTpPermission} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default memo(ProductRegistration);
