/**
 * Update date: 15-05-2023
 * Screen 1044.2
 */
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { useState, useRef, useEffect, memo } from 'react';
import Form from 'devextreme-react/form';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { cloneDeep, uniqBy } from 'lodash';
import { notification } from 'utils/notification';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  receivableGroup: {
    codeType: 'RC',
  },
  shippingMethod: {
    codeType: 'FT',
  },
};

interface IOptionData {
  receivableGroup?: string;
  receivableGroupName?: string;
  shippingMethod?: string;
  shippingMethodName?: string;
}

interface IPopupAddStoreShippingMethod {
  visible?: boolean;
  data?: Array<IOptionData>;
  onHiding?: () => void;
  onSubmit?: void | any;
}

function PopupAddStoreShippingMethod({
  visible,
  data = [],
  onHiding,
  onSubmit,
}: IPopupAddStoreShippingMethod) {
  const { t } = useDictionary({});
  const formRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({
    dataList: {
      shippingMethod: [],
      receivableCd: [],
    },
    shippingMethod: '',
    receivableCd: '',
  });
  const [showPopupByType, setShowPopupByType] = useState('');

  /**
   * on submit
   *
   * @return {*}
   */
  const onOk = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const receivableGroup = formData?.dataList?.receivableGroup[0];
    const shippingMethod = formData?.dataList?.shippingMethod[0];
    const dataAdd = {
      receivableGroup: receivableGroup?.code,
      receivableGroupName: receivableGroup?.name,
      shippingMethod: shippingMethod?.code,
      shippingMethodName: shippingMethod?.name,
    };

    if (data?.some(o => o.receivableGroup === dataAdd.receivableGroup)) {
      return notification({
        type: 'error',
        message: t('Receivable Group is exist. Please try again!'),
      });
    }

    onSubmit([dataAdd]);
  };

  /**
   * remote data input form search popup
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
  };
  /**
   * update data input form search popup
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
  };

  /**
   * generate type dxTextBox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render list popup
   *
   * @param {string} popupType
   * @return {*} 
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'receivableGroup':
      case 'shippingMethod':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="single"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data)}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render content
   *
   * @return {*} 
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div>
          <Form
            ref={formRef}
            formData={formData}
            showColonAfterLabel={false}
            items={[
              generateDxTextBox('Receivable Group', 'receivableGroup', 'name', {
                isRequired: true,
              }),
              generateDxTextBox('Shipping Method', 'shippingMethod', 'name', {
                isRequired: true,
              }),
            ]}
            labelLocation="top"
            colCount={1}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
              marginTop: 35,
            }}
          >
            <Button icon="save" text={t('OK')} type="default" onClick={onOk} />
            <Button icon="close" text={t('Cancel')} onClick={onHiding} />
          </div>
        </div>
        {getPopupByType(showPopupByType)}
      </ScrollView>
    );
  };
  /**
   * render title
   *
   * @return {*} 
   */
  const titleRender = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 35,
          fontSize: 20,
        }}
      >
        <h4>{t('Store Shipping Method')}</h4>
        <div>
          <i
            className="dx-icon dx-icon-close"
            style={{
              float: 'right',
              fontSize: 18,
              marginLeft: 20,
              cursor: 'pointer',
            }}
            onClick={onHiding}
          ></i>
        </div>
      </div>
    );
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      titleRender={titleRender}
      contentRender={content}
      height={300}
      width={'450px'}
    />
  );
}

export default memo(PopupAddStoreShippingMethod);
