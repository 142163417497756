import { fork, call, takeLatest, put } from '@redux-saga/core/effects';
import { mainMenuAction } from './mainMenuSlice';
import { fetchMainMenu as fetchMainMenuApi } from 'api/api';

function* fetchMainMenu(actions) {
  try {
    const result: any = yield call(fetchMainMenuApi, {});
    yield put(mainMenuAction.setMenuData(result.data.data));
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {}
}

function* watchFetchMainMenu() {
  yield takeLatest(mainMenuAction.fetchMenuData.type, fetchMainMenu);
}

export function* mainMenuSaga() {
  yield fork(watchFetchMainMenu);
}
