/**
 * Update date: 05-06-2023
 * Screen 2053.2
 */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import useAxios from 'axios-hooks';
import TableList from './TableList';
import FormSearch from './FormSearch';
import LoadPanel from 'app/components/LoadPanel';
import { notification } from 'utils/notification';
/**
 * create context
 */
const ReceiveForward = createContext({
  dataSource: {},
  dataGroup: {},
  dataGridRef: {},
  viewPop: {},
  dataDetail: {},
  refetchGroup: () => {},
  refetchData: () => {},
  refetchPG: () => {},
  setViewPop: () => {},
  refetchDetail: () => {},
  setDataDetail: () => {},
  setToShow: () => {},
});

ReceiveForward.displayName = 'ReceiveForward';

export const useReceiveForward = () => {
  const context = useContext(ReceiveForward);
  return context;
};

function ProductReceiveForwardInquiry() {
  const [dataSource, setDataSource] = useState<any>([]);
  const [dataGroup, setDataGroup] = useState<any>([]);
  const [dataDetail, setDataDetail] = useState<any>([]);
  const dataGridRef: any = useRef(null);
  const [viewPopup, setViewPopup] = useState<boolean>(false);
  const [toShow, setToShow] = useState<boolean>(false);

  const [{ data: groupData, loading: loadingGroup }, refetchGroup] = useAxios(
    {
      url: '/product/product_group',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );
  /**
   * fetch data
   */
  useEffect(() => {
    if (groupData?.data) {
      setDataGroup(groupData?.data);
    }
  }, [groupData, loadingGroup]);

  const [{ data: detailData, loading: loadingDetail }, refetchDetail] =
    useAxios(
      {
        url: '/product/product_group/detail',
        method: 'POST',
      },
      {
        manual: true,
      },
    );

  const [{ data, loading: loadingData }, refetchData] = useAxios(
    {
      url: '/product/receive_forward',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const [{ data: updatedData, loading: loadingUpdated }, refetchPG] = useAxios(
    {
      url: '/product/product_group',
      method: 'PUT',
    },
    {
      manual: true,
      useCache: false,
    },
  );

  const value: any = {
    dataSource,
    dataGroup,
    dataGridRef,
    viewPopup,
    dataDetail,
    refetchGroup,
    refetchData,
    refetchPG,
    setViewPopup,
    refetchDetail,
    setDataDetail,
    setToShow,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (detailData && !loadingDetail) {
      setDataDetail(
        detailData?.data?.listOfGroup.map((o: any) => o?.productId),
      );
    }
  }, [detailData, loadingDetail]);

  useEffect(() => {
    if (data?.data) {
      setDataSource(data?.data);
    }
  }, [data]);

  useEffect(() => {
    if (!loadingUpdated && updatedData?.status === '201') {
      if (toShow) {
        notification({
          message: updatedData?.message,
          type: 'success',
        });
        setViewPopup(false);
        setToShow(false);
        dataGridRef?.current?.instance?.deselectAll();
      }
    }
  }, [updatedData, loadingUpdated]);

  return (
    <>
      <ReceiveForward.Provider value={value}>
        <LoadPanel visible={loadingData} />
        <FormSearch />
        <TableList ref={dataGridRef} />
      </ReceiveForward.Provider>
    </>
  );
}

export default ProductReceiveForwardInquiry;
