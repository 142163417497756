/**
 * Update date: 15-05-2023
 * Screen 1044.4
 */
import React, { useRef, useState } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { uniqBy } from 'lodash';
import { useReportStore } from '..';
import useDictionary from 'hooks/useDictionary';
import PopupSelectStoreGroup from 'app/components/PopupCommon/PopupSelectStoreGroup';

const listTypeCommon = {
  storeType: {
    codeType: 'ST',
    title: 'Search Member Store Type',
  },
};

const FormSearch = () => {
  const context: any = useReportStore();
  const { refetchData } = context;
  const [formData, setFormData] = useState<any>({
    storeName: '',
    use: [],
    representative: '',
    formStore: {
      storeType: [],
      storeGroup: [],
    },
    storeType: '',
    storeGroup: '',
  });
  const formRef: any = useRef(null);
  const [showPopupByType, setShowPopupByType] = useState('');

  const { t }: any = useDictionary({
    programId: '1044.4',
  });

  // Onsubmit form search
  const onSubmit = async () => {
    if (formRef?.current && !formRef?.current?.instance?.validate()?.isValid)
      return;
    const data = {
      ...formData,
      storeType: formData?.formStore?.storeType?.map?.((o: any) => o?.code),
      storeGroupIds: formData?.formStore?.storeGroup?.map?.(
        (o: any) => o.storeGroupId,
      ),
    };
    delete data.formStore;
    refetchData({
      data,
    });
  };
  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'storeType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      case 'storeGroup':
        return (
          <PopupSelectStoreGroup
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('storeGroup', o, 'storeGroupId')}
          />
        );
      default:
        return null;
    }
  };
  /**
   * update store
   *
   * @param {*} storeType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storeType, data, key: any = false) => {
    const filterData = uniqBy(
      [...formData?.formStore?.[storeType], ...data],
      key,
    );
    const newStore = { ...formData?.formStore, [storeType]: filterData };
    setFormData(preState => ({
      ...preState,
      [storeType]: '',
      formStore: newStore,
    }));
    setShowPopupByType('');
  };
  /**
   * remove store
   *
   * @param {*} storeType
   * @param {*} index
   */
  const removeStore = (storeType, index) => {
    let newStore;
    if (index === -1) {
      setFormData(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [storeType]: [],
        },
      }));
    } else {
      const l = [...formData?.formStore[storeType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setFormData(preState => ({
        ...preState,
        formStore: {
          ...preState?.formStore,
          [storeType]: newStore,
        },
      }));
    }
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          ref={formRef}
          items={[
            {
              label: {
                text: t('Store Name'),
              },
              dataField: 'storeName',
              editorType: 'dxTextBox',
            },
            {
              dataField: 'storeType',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => {
                  setShowPopupByType('storeType');
                },
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.formStore?.storeType[0]?.name,
                      onClick: () => removeStore('storeType', 0),
                      visible: !!formData?.formStore?.storeType[0]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.formStore?.storeType[1]?.name,
                      onClick: () => removeStore('storeType', 1),
                      visible: !!formData?.formStore?.storeType[1]?.code,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        formData?.formStore?.storeType?.length - 2
                      } ${t('more')}`,
                      visible: formData?.formStore?.storeType?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.formStore?.storeType?.length,
                      onClick: () => removeStore('storeType', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('storeType'),
                    },
                  },
                ],
              },
              label: {
                text: t('Store Type'),
              },
            },
            {
              dataField: 'storeGroup',
              editorType: 'dxTextBox',
              editorOptions: {
                onEnterKey: () => setShowPopupByType('storeGroup'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.formStore?.storeGroup[0]?.storeGroupName,
                      onClick: () => removeStore('storeGroup', 0),
                      visible:
                        !!formData?.formStore?.storeGroup[0]?.storeGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'data2',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: formData?.formStore?.storeGroup[1]?.storeGroupName,
                      onClick: () => removeStore('storeGroup', 1),
                      visible:
                        !!formData?.formStore?.storeGroup[1]?.storeGroupId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'storeMore',
                    location: 'before',
                    options: {
                      text: `+ ${
                        formData?.formStore?.storeGroup?.length - 2
                      } ${t('more')}`,
                      visible: formData?.formStore?.storeGroup?.length > 2,
                    },
                  },
                  {
                    name: 'clearAll',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'close',
                      visible: !!formData?.formStore?.storeGroup?.length,
                      onClick: () => removeStore('storeGroup', -1),
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('storeGroup'),
                    },
                  },
                ],
              },
              label: { text: t('Store Group') },
            },
            {
              label: { text: t('Use') },
              dataField: 'use',
              editorType: 'dxTagBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                items: [
                  {
                    label: t('Use'),
                    value: true,
                  },
                  {
                    label: t('Not Use'),
                    value: false,
                  },
                ],
              },
            },
            {
              label: {
                text: t('Representative'),
              },
              dataField: 'representative',
              editorType: 'dxTextBox',
            },
          ]}
          labelLocation="top"
          colCount={3}
        />
      </>
    );
  };
  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
