/**
 * Create date: 2024-06-20
 * Screen 2082.C
 */

import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import React, { useContext, createContext, useState } from 'react';
import FormSearch from './FormSearch';
import List from './List';
import ArrayStore from 'devextreme/data/array_store';

// create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2082_CContext';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};
function M2082_G() {
  const [dataList, setDataList] = useState<any>();
  const store: any = new ArrayStore({
    data: dataList,
    key: 'id',
  });
  const { t }: any = useDictionary({ programId: '2082.G' });

  const value: any = { t, store, setDataList };
  /**
   * render
   */

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <FormSearch />
        <List />
      </React.Suspense>
    </ModuleContext.Provider>
  );
}

export default M2082_G;
