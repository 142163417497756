/**
 * Update date: 25-01-2024
 * Popup matched products component
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useRef, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';
import { notification } from 'utils/notification';
interface IPopupMatchedProducts {
  visible?: boolean;
  onHiding?: () => void;
  designId?: number;
  title?: string;
}

function PopupMatchedProducts({
  visible,
  onHiding,
  designId,
  title,
}: IPopupMatchedProducts) {
  const { themePro }: any = useApp();
  const dataRef: any = useRef(null);
  const { t } = useDictionary({ programId: '1042.7' });
  const [resData, setResData] = useState<any>({});

  const [{ loading }, fetchData] = useAxios(
    {},
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * fetch data
   */
  useEffect(() => {
    const getEslMatchingCode = async () => {
      const res = await fetchData({
        url: '/core/esl-design-setting/' + designId,
        method: 'GET',
      });
      if (res?.data?.status === '200') {
        setResData(res?.data);
      } else {
        notification({ res });
      }
    };
    getEslMatchingCode();
  }, [designId]);

  const loadingAll = loading;

  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{title}</span>
              <div>
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          <DataGridFull
            ref={dataRef}
            dataSource={resData?.data}
            columns={[
              {
                dataField: 'productCd',
                caption: t('Product Code'),
              },
              {
                dataField: 'productNm',
                caption: t('Product Name'),
              },
              {
                dataField: 'marketableSize',
                caption: t('Marketable Size'),
              },
              {
                dataField: 'optionNm',
                caption: t('Option'),
              },
              {
                dataField: 'quantityPerPack',
                caption: t('QPP'),
                alignment: 'left',
              },
            ]}
            options={{
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              height: themePro
                ? 'calc(100vh - 200px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={title}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupMatchedProducts;
