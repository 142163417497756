/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { DataGridFull } from 'app/components/DataGrid';
import React from 'react';
import { notification } from 'utils/notification';
import { usePopupOrder } from '..';

function Payment() {
  const { t, DateTimeCompactFormat, AmountFormat, storePayment }: any =
    usePopupOrder();
  /**
   * on receipt
   *
   * @param {*} e
   * @return {*}
   */
  const onReceipt = (e: any) => {
    const { pg_transaction_id }: any = e?.data || {};
    if (!pg_transaction_id)
      return notification({
        message: t('Payment not found, please check again!'),
        type: 'error',
        displayTime: 2000,
      });
    window.open(
      `https://cpadmin.billgate.net/billgate/common/authCardReceipt.jsp?mid=glx_api&transNm=${pg_transaction_id}&currTp=0000`,
      '_blank',
    );
  };
  return (
    <div className="body-padding-white">
      <div>
        <div className="dx-form-group-caption mb-10">
          {t('Payment Information')}
        </div>
      </div>
      <DataGridFull
        dataSource={storePayment}
        fixedRight={1}
        fixedLeft={3}
        options={{
          searchPanel: {
            visible: false,
          },
          filterPanel: {
            visible: false,
          },
          scrolling: {
            mode: 'infinite',
          },
          className: 'datagrid-relative-header datagird-payment-info',
        }}
        columns={[
          {
            dataField: 'id_no',
            caption: t('Seq'),
            alignment: 'left',
            width: 100,
          },
          {
            dataField: 'date_and_time',
            caption: t('Date and Time'),
            format: DateTimeCompactFormat,
            dataType: 'date',
            alignment: 'left',
          },
          {
            dataField: 'payment_method',
            caption: t('Payment Method'),
            alignment: 'left',
            cellRender: e => {
              return t(e?.value);
            },
          },
          {
            dataField: 'order_pay_amount',
            caption: t('Payment Amount'),
            format: AmountFormat,
            dataType: 'number',
          },
          {
            dataField: 'pg_service_id',
            caption: t('PG Service ID'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'pg_cancel_key',
            caption: t('PG Cancel Key'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'pg_auth_date',
            caption: t('PG Auth Date'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'pg_transaction_id',
            caption: t('PG Transaction ID'),
            alignment: 'left',
            visible: false,
          },
          {
            dataField: 'auth_number',
            caption: t('Approve Number'),
            alignment: 'left',
          },
          {
            dataField: 'card_company_code',
            caption: t('Card Company'),
            alignment: 'left',
          },
          {
            dataField: '',
            caption: t('Action'),
            alignment: 'left',
            width: 150,
            cellRender: e => {
              const { order_pay_cd } = e?.data || {};
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {order_pay_cd === '0900' ? (
                    <span className="cell-link" onClick={() => onReceipt(e)}>
                      {t('Receipt')}
                    </span>
                  ) : null}
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default Payment;