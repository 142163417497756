/**
 * Update date: 09-06-2023
 * Layout component
 */
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useLayoutEffect,
} from 'react';
import Header from 'app/components/Header';
import Navigation from 'app/components/Navigation';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoggedIn } from 'store/features/auth/authSlice';
import {
  mainMenuAction,
  selectMainMenu,
} from 'store/features/mainMenu/mainMenuSlice';
import { centerRegistrationActions } from 'store/features/centerRegistration/centerRegistrationSlice';
import { mainMenuFixedAction } from '../../../store/features/mainMenu/mainMenuFixed';
import { router } from 'constant/router';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import { WIDTH_SIDEBAR_COLLAPSE, WIDTH_SIDEBAR_EXPAND } from 'constant';
import LangIcons from '../LangIcons';
import useWindowSize from 'hooks/useWindowSize';
import MobileMenu from '../Navigation/MobileMenu';
import { useApp } from 'app';
import MyPagePremium from '../Header/MyPagePremium';
import useLocalStorage from 'hooks/useLocalStorage';
import useDictionary from 'hooks/useDictionary';

/**
 * create context
 */

export const LayoutContext = React.createContext({
  mainMenu: [],
  subMenu: [],
  programMenu: [],
  fullMenu: [],
  updateBookMark: () => {},
  getProgramsHistory: () => {},
  collapse: false,
  showProgramMenu: false,
  setShowProgramMenu: () => {},
});
LayoutContext.displayName = 'LayoutContext';

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) return;
  return context;
};

const Layout = props => {
  const { children } = props;
  const windowSize: any = useWindowSize();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [collapse, setCollapse] = useState(false);
  const location: any = useLocation();
  const mainMenu = useSelector(selectMainMenu);
  const { t } = useDictionary({});
  const [subMenu, setSubMenu] = useState([]);
  const [programMenu, setProgramMenu] = useState([]);
  const [showProgramMenu, setShowProgramMenu] = useState(false);
  const { themePro, setAppLogo }: any = useApp();
  const [listLastUsed, setListLastUsed, getAgainListLastUsed] = useLocalStorage(
    'last_used_menu',
    [],
  );
  /**
   * fetch data
   */
  useEffect(() => {
    isLoggedIn && getAgainListLastUsed();
  }, [isLoggedIn]);

  const [showOtherPage, setShowOtherPage] = useState<string>('');
  const fullMenu = useMemo(
    () =>
      mainMenu
        ?.map(o => o?.children?.map(e => ({ ...e, text: o?.programName })))
        ?.flat()
        ?.map(o =>
          o?.children?.map(e => ({
            ...e,
            text: `${t(o.text)} > ${t(o?.programName)} > ${e?.programName}`,
          })),
        )
        ?.flat(),
    [mainMenu, t],
  );

  const pdaMenu = useMemo(() => fullMenu?.filter(o => o?.pdaTf), [fullMenu]);
  const specialUrl = [router.search, router.history, router.book_mark].includes(
    location.pathname,
  );
  /**
   * on toggle
   *
   */
  const toggleSidebar = () => {
    setCollapse(!collapse);
  };

  /**
   * on get menu
   *
   * @return {*}
   */
  const getNormalMenu = () => {
    const { programId1, programId2 } = location?.state || {};

    if (!programId1) return;
    const menu2 = mainMenu.find(
      (o: any) => o.programId == programId1,
    )?.children;
    if (!isEqual(menu2, programMenu)) {
      setSubMenu(menu2);
    }

    if (!programId2) return;
    const menu3 = menu2?.find((o: any) => o.programId == programId2)?.children;
    if (!isEqual(menu3, subMenu)) {
      setProgramMenu(menu3);
    }
  };

  /**
   * get bookmark
   *
   */
  const getBookMark = () => {
    dispatch(mainMenuAction.fetchMenuData({ onSuccess: () => {} }));
    dispatch(
      mainMenuFixedAction.fetchProgramBookMark({
        onSuccess: setProgramMenu,
      }),
    );
  };

  /**
   * get history
   *
   */
  const getHistory = () => {
    dispatch(
      mainMenuFixedAction.fetchProgramHistory({ onSuccess: setProgramMenu }),
    );
  };

  /**
   * get search
   *
   * @param {*} searchValue
   * @return {*}
   */
  const getSearch = (searchValue: any) => {
    if (!searchValue) {
      setProgramMenu([]);
      return;
    }
    const items = fullMenu?.filter(o =>
      o?.programName?.toLowerCase()?.includes(searchValue?.toLowerCase()),
    );
    setProgramMenu(items);
  };

  /**
   * on update bookmark
   *
   * @param {*} paramsReq
   */
  const updateBookMark = (paramsReq: any) => {
    const params = {
      programId: paramsReq.programId,
      bookMark: paramsReq.bookMark,
    };
    dispatch(
      mainMenuFixedAction.bookMarkUrl({
        params,
        onSuccess: () => {
          if (paramsReq.reload) {
            getBookMark();
          } else {
            // paramsReq?.onSuccess();
            dispatch(mainMenuAction.changeBookmarkMenu(params));
          }
        },
        onError: () => {},
      }),
    );
  };

  /**
   * on remove program history
   *
   * @param {*} id
   */
  const removeProgramHistory = id => {
    const params = {
      id,
    };
    dispatch(
      mainMenuFixedAction.removeProgramHistory({
        params,
        onSuccess: getHistory,
        onError: () => {},
      }),
    );
  };

  /**
   * on update program history
   *
   * @param {*} programId
   * @return {*}
   */
  const updateProgramHistory = programId => {
    const params = {
      programId,
    };
    if (themePro && location?.state?.notUpdateHistory) return;
    dispatch(
      mainMenuFixedAction.addProgramHistory({
        params,
        onSuccess: () => {},
      }),
    );
  };

  /**
   * render bookmark
   *
   * @param {*} params
   * @param {*} onSuccess
   */
  const reOrderBookMark = (params, onSuccess) => {
    dispatch(
      mainMenuFixedAction.reOrderBookMark({
        params,
        onSuccess,
      }),
    );
  };

  const clearMenu = () => {
    setSubMenu([]);
    setProgramMenu([]);
  };

  /**
   * render menu
   *
   * @return {*}
   */
  const getMenu = () => {
    clearMenu();
    switch (location.pathname) {
      case router.search:
        return;
      case router.history:
        return getHistory();
      case router.book_mark:
        return getBookMark();
      default:
        return getNormalMenu();
    }
  };

  const fetchCenterInfoSuccess = data => {
    window.sessionStorage.setItem('centerInfo', JSON.stringify(data));
    setAppLogo(data?.centerLogoUrl || '');
  };

  useEffect(() => {
    getNormalMenu();
  }, [mainMenu]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(mainMenuAction.fetchMenuData({ onSuccess: () => getMenu }));
      dispatch(
        centerRegistrationActions.fetchCenterInfo({
          onSuccess: fetchCenterInfoSuccess,
        }),
      );
    }
  }, []);
  /**
   * fetch data
   */
  useLayoutEffect(() => {
    if (location.pathname != router.search) {
      if (!location?.state?.programId3) {
        getMenu();
      }
    } else {
      if (!location?.state?.programId3) {
        clearMenu();
      }
    }
    if (location?.state?.programId3 && location.pathname != router.history) {
      updateProgramHistory(location?.state?.programId3);
    }
    if (
      (location?.state?.isSearch ||
        location?.state?.isBookMark ||
        location?.state?.isHistory ||
        location?.state?.programId2) &&
      !location?.state.programId3
    ) {
      setShowProgramMenu(true);
    }
  }, [location]);

  useLayoutEffect(() => {
    if (location.state?.programId2) {
      getMenu();
    }
  }, [location.state?.programId2]);

  const value: any = {
    mainMenu,
    isLoggedIn,
    fullMenu,
    pdaMenu,
    subMenu,
    programMenu,
    showProgramMenu,
    setShowProgramMenu,
    updateBookMark,
    updateProgramHistory,
    collapse,
    toggleSidebar,
    getBookMark,
    getHistory,
    getSearch,
    removeProgramHistory,
    reOrderBookMark,
    getMenu,
    listLastUsed,
    setListLastUsed,
    showOtherPage,
    setShowOtherPage,
  };

  const smallSize = windowSize?.width < 768;
  if (smallSize && isLoggedIn)
    return (
      <LayoutContext.Provider value={value}>
        <MobileMenu />
        {children}
      </LayoutContext.Provider>
    );

  const renderContentPremium = () => {
    if (!themePro) return null;
    switch (showOtherPage) {
      case 'myPage':
        return <MyPagePremium />;
      default:
        return null;
    }
  };

  return (
    <LayoutContext.Provider value={value}>
      <div className="admin">
        {(isLoggedIn || !themePro) && <Navigation />}
        {themePro ? (
          <div
            className="program-main"
            style={
              !isLoggedIn
                ? {}
                : collapse
                ? {
                    width: smallSize
                      ? '100%'
                      : `calc(100% - ${WIDTH_SIDEBAR_COLLAPSE})`,
                    left: smallSize ? 0 : WIDTH_SIDEBAR_COLLAPSE,
                    margin: 0,
                  }
                : {
                    width: `calc(100% - 256px)`,
                    left: '256px',
                    margin: 0,
                  }
            }
          >
            {isLoggedIn ? <Header /> : <LangIcons />}
            {!showOtherPage && children}
            {showOtherPage && (
              <main className="main-design">{renderContentPremium()}</main>
            )}
          </div>
        ) : (
          <div
            className="program-main"
            style={
              collapse
                ? {
                    width: smallSize
                      ? '100%'
                      : `calc(100% - ${WIDTH_SIDEBAR_COLLAPSE})`,
                    left: smallSize ? 0 : WIDTH_SIDEBAR_COLLAPSE,
                    margin: 0,
                  }
                : {
                    width: `calc(100% - ${WIDTH_SIDEBAR_EXPAND})`,
                    left: WIDTH_SIDEBAR_EXPAND,
                    margin: 0,
                  }
            }
          >
            {isLoggedIn ? <Header /> : <LangIcons />}
            {children}
            {/* <Logs /> */}
          </div>
        )}
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
