/**
 * Update date: 13-06-2023
 * Popup input product quantity scan component
 */
import { Button, Popup, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import React, { useState, useRef } from 'react';
import Form from 'devextreme-react/form';
import { cloneDeep } from 'lodash';
import { isMobile } from 'react-device-detect';
import { formatNumber } from 'devextreme/localization';
import useFormat from 'hooks/useFormat';
interface IPopupInputProductQuantityScan {
  visible?: boolean;
  data: any;
  onHiding?: () => void;
  onSubmit?: void | any;
}

function PopupInputProductQuantityScan({
  visible,
  data,
  onHiding,
  onSubmit,
}: IPopupInputProductQuantityScan) {
  const { t } = useDictionary({});
  const { AmountFormat } = useFormat();
  const formRef: any = useRef(null);
  const [formData] = useState<any>(
    cloneDeep({ ...data, receiveQuantity: null }),
  );

  /**
   * on ok
   *
   * @return {*}
   */
  const onOk = async () => {
    if (!formRef?.current?.instance?.validate?.()?.isValid) return;
    onSubmit(formData);
  };

  /**
   * on content ready
   *
   */
  const onContentReady = () => {
    setTimeout(function () {
      formRef?.current?.instance?.getEditor('receiveQuantity')?.focus?.();
    }, 1000);
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div>
          <div style={{ paddingLeft: '5px', marginBottom: '5px' }}>
            <div style={{ lineHeight: '25px' }}>
              {`${t('Name')}: ${data?.productName}`}
            </div>
            <div style={{ lineHeight: '25px' }}>
              {`${t('Code')}: ${data?.productCode}`}
            </div>
            <div style={{ lineHeight: '25px' }}>
              {`${t('Option')}: ${data?.option}`}
            </div>
            <div style={{ lineHeight: '25px' }}>
              {`${t('Barcode')}: ${data?.barcodes}`}
            </div>
            <div style={{ lineHeight: '25px' }}>
              {`${t('Unit Price')}: ${formatNumber(
                data?.purchaseUnitPrice || 0,
                AmountFormat,
              )}`}
            </div>
          </div>
          <Form
            ref={formRef}
            formData={formData}
            showColonAfterLabel={false}
            style={{ display: 'flex', justifyContent: 'center' }}
            onContentReady={() => onContentReady()}
            items={[
              {
                label: { text: t('Quantity:') },
                dataField: 'receiveQuantity',
                editorType: 'dxNumberBox',
                horizontalAlignment: 'right',
                editorOptions: {
                  width: 360,
                  format: '#,###',
                  step: 0,
                  min: 1,
                  max: 1999999999,
                  onEnterKey: onOk,
                },
                validationRules: [
                  {
                    type: 'numeric',
                    message: t('Allow entering the numeric only'),
                  },
                  {
                    type: 'required',
                    message: t('Quantity is required'),
                  },
                ],
              },
            ]}
            labelLocation="top"
            colCount={1}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
              marginTop: 30,
            }}
          >
            <Button
              icon="save"
              text={t('Apply')}
              stylingMode="contained"
              type="default"
              onClick={onOk}
            />
            <Button icon="close" text={t('Cancel')} onClick={onHiding} />
          </div>
        </div>
      </ScrollView>
    );
  };
  const titleRender = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 30,
          fontWeight: 'bold',
        }}
      >
        <span style={{ fontSize: 20 }}>{t('Input Product Quantity')}</span>
      </div>
    );
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      titleRender={titleRender}
      contentRender={content}
      maxHeight={400}
      height={'auto'}
      width={isMobile ? '96%' : '410px'}
    />
  );
}

export default PopupInputProductQuantityScan;
