/**
 * Update date: 06-06-2023
 * Screen 2064.6
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import PopupSelectStore from 'app/components/PopupCommon/PopupSelectStore';
import Form from 'devextreme-react/form';
import useFormatTime from 'hooks/useFormatTime';
import React, { useRef, useState, useEffect } from 'react';
import { useStatusCarryIntoOfDirectDeliveryEmptyBottles } from '..';
import useDictionary from 'hooks/useDictionary';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { uniqBy, cloneDeep } from 'lodash';
import useLocalStorage from 'hooks/useLocalStorage';
import { dateFormatStr } from 'utils/format';
interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  receivableCd: {
    codeType: 'RC',
  },
};

const FormSearch = () => {
  const [dataLocal, setDataLocal] = useLocalStorage('2064.6_mst_dataList', {});
  const context: any = useStatusCarryIntoOfDirectDeliveryEmptyBottles();
  const { formSearchData, setFormSearchData, refetchReceive } = context;
  const formRef: any = useRef(null);
  const { t }: any = useDictionary({ programId: '2064.6' });
  const { DateFormat } = useFormatTime();
  const [showPopupByType, setShowPopupByType] = useState('');
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setFormSearchData(prev => ({
      ...prev,
      dataList: {
        ...prev.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;
    const fixFormData = {
      fromDate: dateFormatStr(formSearchData?.fromDate),
      endDate: dateFormatStr(formSearchData?.endDate),
      storeIds:
        formSearchData?.dataList?.store?.map((o: any) => o.storeNo) || [],
      receivableCds:
        formSearchData?.dataList?.receivableCd?.map(o => o?.code) || [],
    };

    refetchReceive({
      data: { ...fixFormData },
    });
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormSearchData({
      ...formSearchData,
      dataList: dataList,
    });
    if (storedType === 'receivableCd') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formSearchData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormSearchData(newData);
    setShowPopupByType('');
    if (storedType === 'receivableCd') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'store':
        return (
          <PopupSelectStore
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('store', o, 'storeNo')}
          />
        );
      case 'receivableCd':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formSearchData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formSearchData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
  };

  /**
   * on field data changed
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (
          formSearchData.endDate &&
          e?.value &&
          e?.value > formSearchData.endDate
        )
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        if (
          formSearchData.fromDate &&
          e?.value &&
          e?.value < formSearchData.fromDate
        )
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };
  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions onChangeValue={onChangeDate} />
        <Form
          ref={formRef}
          formData={formSearchData}
          showColonAfterLabel={false}
          items={[
            {
              label: {
                text: t('From Date'),
              },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formSearchData.fromDate && formSearchData.endDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('From Date is required'),
                },
              ],
            },
            {
              label: {
                text: t('End Date'),
              },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formSearchData.endDate && formSearchData.fromDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('End Date is required'),
                },
              ],
            },
            generateDxTextBox('Store', 'store', 'storeNm'),
            generateDxTextBox('Receivable Group', 'receivableCd', 'name'),
          ]}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
