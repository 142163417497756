/**
 * Update date: 19-05-2023
 * Screen 2014.2
 */
import React, { useState, useRef } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import useDictionary from 'hooks/useDictionary';
import { useStandardBarcodeContext } from '../index';

const FormSearch = () => {
  const { t }: any = useDictionary({});
  const { refetchData }: any = useStandardBarcodeContext();
  const formRef = useRef<any>(null);
  const [formData, setFormData] = useState<any>({
    productCode: '',
    excludeRegisteredMaster: false,
  });

  /**
   * render search
   *
   * @param {*} name
   * @return {*}
   */
  const Search = name => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={2}
          items={[
            {
              label: { text: t('Standard Product') },
              dataField: 'productCode',
              editorType: 'dxTextBox',
            },
            {
              label: {
                text: t('Exclude Products Registered In The Product Master'),
              },
              dataField: 'excludeRegisteredMaster',
              editorType: 'dxCheckBox',
            },
          ]}
        />
      </>
    );
  };

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) {
      return;
    }
    const data = {
      standardProductCode: formData?.productCode || '',
      excludeRegisteredMaster: formData?.excludeRegisteredMaster || false,
    };
    refetchData({
      data,
    });
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
      </Collapse>
    </div>
  );
};

export default FormSearch;
