/*
 * 2046.C Forwarding return inquiry by Bill
 * @since 2023/05/30
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';
import { dateFormatStr } from 'utils/format';
import { notification } from 'utils/notification';

const View = React.lazy(() => import('./View'));
const List = React.lazy(() => import('./List'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2046_C_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Forwarding return inquiry by Bill
 *
 * @export
 * @return {*}
 */
export default function M2046_C() {
  // get multi language
  const { t }: any = useDictionary({ programId: '2046.C' });
  // Initialize state
  const [listData, setListData] = useState([]);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    taskType: 0,
    dataList: {
      productIds: [],
      storeId: [],
      receivableCd: [],
    },
    productIds: '',
    storeId: '',
    receivableCd: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: '',
  });

  // Get data
  const [{ loading }, refetchData] = useAxios(
    {
      url: '/warehouse/m2046_c',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * On search data
   *
   */
  const onSearch = async () => {
    const searchParams = {
      fromDate: dateFormatStr(formData?.fromDate),
      endDate: dateFormatStr(formData?.endDate),
      taskType: formData?.taskType,
      productIds: formData?.dataList?.productIds?.map?.(
        (map: any) => map?.productId,
      ),
      storeIds: formData?.dataList?.storeId?.map?.((o: any) => o?.storeNo),
      receivableCds: formData?.dataList?.receivableCd?.map?.(
        (o: any) => o?.code,
      ),
    };

    const res = await refetchData({
      data: { ...searchParams },
    });
    if (res?.data?.status === '200') {
      const dataMapping = (res?.data?.data?.list || []).map(o => {
        return {
          ...o,
          task_type_name: o?.task_type === 1 ? t('Forward') : t('Return'),
        };
      });
      setListData(dataMapping);
      setShipmentTypes(res?.data?.data?.shipmentTypes || []);
    } else {
      notification({ res });
    }
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <View />;
      default:
        return <NotFoundPage />;
    }
  };

  // Values share with child component
  const value: any = {
    t,
    modeView,
    setModeView,
    formData,
    setFormData,
    listData,
    shipmentTypes,
    onSearch,
    refetchData,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
}
