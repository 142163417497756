import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import moment from 'moment';
import ArrayStore from 'devextreme/data/array_store';
import View from './View';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2086_1_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

/**
 * Issue of sales tax invoice
 *
 * @return {*}
 */
const M2086_1 = () => {
  const { t }: any = useDictionary({});
  // stored data grid after search
  const [data, setData] = useState([]);
  // stored data form search
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    enDate: new Date(),
    dataList: {
      receivableCd: [],
      store: [],
      storeGroup: [],
    },
    receivableCd: '',
    store: '',
    storeGroup: '',
    taxTp: '0',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });
  const { AmountFormat, QtyFormat } = useFormat();
  const { DateFormat } = useFormatTime();

  // list tax bill status const
  const listTaxbillStatus = [
    { type: '0', name: t('Save') },
    { type: '1', name: t('Confirmation Request') },
    { type: '2', name: t('Confirmation') },
    { type: '3', name: t('Cancel') },
    { type: '8', name: t('Delete') },
    { type: '9', name: t('Cancel - Deletion Possible') },
  ];

  // list reference type const
  const listReferenceType = [
    { type: '0', name: t('Forward') },
    { type: '1', name: t('Return') },
  ];

  // call api
  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  // call api delete
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: resTax, loading: loadingTax }] = useAxios({
    url: '/core/center-info/tax',
    method: 'GET',
  });

  const store: any = new ArrayStore({
    data: data,
    key: 'store_taxbill_id',
  });

  /**
   * On search
   */
  const onSubmitSearch = async () => {
    const requestData = {
      fromDate: formSearchData?.fromDate
        ? moment(formSearchData?.fromDate).format('YYYY-MM-DD')
        : null,
      toDate: formSearchData?.enDate
        ? moment(formSearchData?.enDate).format('YYYY-MM-DD')
        : null,
      storeGroupIds: formSearchData.dataList.storeGroup.map(
        (o: any) => o.storeGroupId,
      ),
      storeIds: formSearchData.dataList.store.map((o: any) => o.storeNo),
      receivableCodes:
        formSearchData?.dataList.receivableCd.map(o => o?.code) || [],
      taxBillTypes: formSearchData?.taxTp,
    };

    const res = await refetchData({
      url: 'user/m2086_1',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      setData(res?.data?.data || []);
    } else {
      notification({
        res,
      });
    }
  };

  // Values share with child component
  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmitSearch,
    listTax: resTax?.data || [],
    AmountFormat,
    QtyFormat,
    DateFormat,
    listTaxbillStatus,
    listReferenceType,
    store,
    refetchDelete,
  };

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <Create />;
      case 'view':
        return <View />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData || loadingTax || loadingDelete} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2086_1;
