/**
 * Update date: 16-05-2023
 * Screen 1044.6
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import ArrayStore from 'devextreme/data/array_store';
const List = React.lazy(() => import('./List'));
const CreateSpecifictVendor = React.lazy(
  () => import('./CreateSpecificVendor'),
);
const EditSpecificVendorProduct = React.lazy(
  () => import('./EditSpecificVendorProduct'),
);

/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M1044_9_Context1';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M1044_9 = () => {
  const { t }: any = useDictionary({ programId: '1044.9' });
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    status: '1',
    dataList: {
      vendor: [],
      vendorType: [],
    },
    vendor: '',
    vendorType: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'vendor_id',
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      vendorIds: formSearchData?.dataList.vendor.map((o: any) => o?.vendorId),
      vendorTypes: formSearchData?.dataList.vendorType.map((o: any) => o?.code),
      status: formSearchData?.status,
    };

    const res = await refetchData({
      url: 'user/1044_9',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const dataMap = (res?.data?.data || []).map(o => ({
        ...o,
        status_tp_nm: t(
          [
            { value: '1', label: t('Consignment Operating') },
            { value: '2', label: t('Consignment Operation Expiration') },
          ].find(s => s.value === o?.status_tp)?.label,
        ),
      }));
      setData(dataMap);
    } else {
      notification({ res });
    }
  };
  const [payList, setPayList] = useState<any>([]);
  const [formInfo, setFormInfo] = useState<any>({
    contract_start_dt: new Date(),
    contract_end_dt: new Date('2999-12-31'),
    status_tp: '1',
    default_comm_rt: 0,
    note: '',
    dataList: {
      vendor: [],
    },
    vendor: '',
  });
  const value: any = {
    t,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    store,
    payList,
    setPayList,
    formInfo,
    setFormInfo,
  };
  /**
   * render content
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <CreateSpecifictVendor />;
      case 'edit-product':
        return <EditSpecificVendorProduct />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={false} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M1044_9;
