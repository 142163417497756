/**
 * Update date: 09-06-2023
 * Lang icon component
 */
import { LANG_DEFAULT, LIST_LANGUAGE } from 'constant';
import { SelectBox, TextBox } from 'devextreme-react';
import { locale } from 'devextreme/localization';
import { useEffect, useState } from 'react';
import { useApp } from 'app';
import SwitchTheme from '../SwitchTheme';

const font = {
  en: 'arial',
  vi: 'arial',
  ja: 'HANDotum',
  zh: 'HANDotum',
  ko: 'Nanum Gothic',
};

export default function LangIcons() {
  const { setReloadLanguage }: any = useApp();
  const [defaulLang, setdefaulLang] = useState(
    JSON.parse(localStorage.getItem('myPagePreference') ?? '{}')?.language ??
      LANG_DEFAULT,
  );
  /**
   * on change language
   *
   * @param {*} e
   */
  const onChangeLanguage = e => {
    const myPagePreferenceLocal = JSON.parse(
      localStorage.getItem('myPagePreference') ?? '{}',
    );
    localStorage.setItem(
      'myPagePreference',
      JSON.stringify({
        ...myPagePreferenceLocal,
        language: e,
        fontName: font[e],
      }),
    );
    locale(e);
    setdefaulLang(e);
    document.documentElement.lang = e;
    setReloadLanguage(true);
  };

  /**
   * render item
   *
   * @param {*} e
   * @return {*}
   */
  const itemRender = e => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img style={{ height: 15, marginRight: 5 }} src={e?.icon} alt="icon" />
        <span>{e?.name}</span>
      </div>
    );
  };
  /**
   * render image
   *
   * @param {*} e
   * @return {*}
   */
  const fieldRender = e => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img style={{ height: 15, marginLeft: 10 }} src={e?.icon} alt="icon" />
        <TextBox defaultValue={e?.name} readOnly={true} />
      </div>
    );
  };
  /**
   * fetch data
   */
  useEffect(() => {
    const lang = locale();
    if (!lang) {
      locale(defaulLang || LANG_DEFAULT);
      document.documentElement.lang = defaulLang;
    }
  }, [defaulLang]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',
        gap: 4,
        margin: '10px 30px',
      }}
    >
      <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
        <SwitchTheme />
      </div>
      <SelectBox
        dataSource={LIST_LANGUAGE}
        displayExpr="name"
        valueExpr="value"
        defaultValue={defaulLang}
        itemRender={itemRender}
        fieldRender={fieldRender}
        width={180}
        onValueChanged={e => {
          if (e?.event) {
            onChangeLanguage(e?.value);
          }
        }}
      />
    </div>
  );
}
