import { createSlice } from '@reduxjs/toolkit';

export interface MenuProgramPayLoad {
  programId: string;
  programName: string;
  programType: string;
  programUrl: string;
  adminOnly: boolean;
}

export interface AuthState {
  isLoading: boolean;
  data: any;
}

const initialState: AuthState = {
  isLoading: false,
  data: [],
};

const menuProgramSlice = createSlice({
  name: 'menuProgram',
  initialState,
  reducers: {
    getMenuProgram(state, action) {
      state.isLoading = true;
    },
    getMenuProgramSuccess(state, actions) {
      state.isLoading = false;
      state.data = actions.payload;
    },
    getMenuProgramError(state) {
      state.isLoading = false;
    },
  },
});

//Action
export const menuProgramActions = menuProgramSlice.actions;

//Selectors
export const menuProgramData = (state: any) => state.menuProgram.data;
export const isLoadingFetchMenuProgram = (state: any) =>
  state.menuProgram.isLoading;

const menuProgramReducer = menuProgramSlice.reducer;

export default menuProgramReducer;
