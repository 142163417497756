/**
 * Update date: 31-05-2023
 * Screen 2052.3
 */
import LoadPanel from 'app/components/LoadPanel';
import PopupConfirmWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmWithRef';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectMall from 'app/components/PopupCommon/PopupSelectMall';
import PopupSelectUser from 'app/components/PopupCommon/PopupSelectUser';
import FileUpload from 'app/components/Templates/FileUpload';
import useAxios from 'axios-hooks';
import { Button, Form, Popup, ScrollView, Template } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import useLocalStorage from 'hooks/useLocalStorage';
import { cloneDeep, uniqBy } from 'lodash';
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { compareDate, dateFormatStr, dateFormatStrUTC } from 'utils/format';
import { notification } from 'utils/notification';
import { readerExcel } from './excel-reader';

interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

interface IPopup2088_1Create {
  onOk?: () => void;
  onHiding?: () => void;
}

function PopupCreate({ onOk, onHiding }: IPopup2088_1Create, ref) {
  const [dataLocal, setDataLocal] = useLocalStorage(
    '2088.1_dataList_create',
    {},
  );
  const { t }: any = useDictionary({ programId: '2088.1' });
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const formRefConfirm: any = useRef(null);
  const confirmCancelRef: any = useRef(null);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [formData, setFormData] = useState<any>({
    pguploadDm: new Date(),
    orderStartDt: null,
    orderEndDt: null,
    paymentStartDt: null,
    paymentEndDt: null,
    dataList: {
      pgCompany: [],
      mall: [],
      userUpload: [],
    },
    pgCompany: '',
    mall: '',
    userUpload: '',
  });
  const [file, setFile] = useState<any>(new Blob());
  const [showPopupByType, setShowPopupByType] = useState('');

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = userInfo => {
    setFormData({
      pguploadDm: new Date(),
      orderStartDt: null,
      orderEndDt: null,
      paymentStartDt: null,
      paymentEndDt: null,
      dataList: {
        pgCompany: dataLocal?.pgCompany || [],
        mall: dataLocal?.mall || [],
        userUpload: userInfo?.userId
          ? [{ userId: userInfo?.userId, name: userInfo?.name }]
          : [],
      },
      pgCompany: '',
      mall: '',
      userUpload: '',
    });
    setShowPopupConfirm(true);
  };

  const onClickNo = () => {
    onHiding?.();
    setShowPopupConfirm(false);
    setFile(new Blob());
  };

  const onSelectedFile = files => {
    if (!files) return;
    setFile(files[0]);
    readerExcel(files[0], ({ paymentStartDt, paymentEndDt }) => {
      setFormData(prev => ({
        ...prev,
        orderStartDt: paymentStartDt,
        orderEndDt: paymentEndDt,
        paymentStartDt: paymentStartDt,
        paymentEndDt: paymentEndDt,
      }));
    });
  };

  /**
   * on upload
   *
   * @return {*}
   */
  const onUpload = async () => {
    let uploadFileUrl = '';
    const formRequest = new FormData();
    formRequest.append('file', file);
    const resS3: any = await refetchData({
      url: '/user/upload/excel',
      method: 'POST',
      data: formRequest,
    });
    if (resS3.data.status === '200') {
      uploadFileUrl = resS3?.data?.data || '';
    } else {
      notification({ res: resS3 });
      return;
    }
    const dataRequest: any = {
      pgcompanyCd: formData?.dataList?.pgCompany?.[0]?.code,
      pguploadDm: dateFormatStrUTC(formData?.pguploadDm, 'YYYY-MM-DD HH:mm:ss'),
      orderStartDt: dateFormatStr(formData?.orderStartDt),
      orderEndDt: dateFormatStr(formData?.orderEndDt),
      paymentStartDt: dateFormatStr(formData?.paymentStartDt),
      paymentEndDt: dateFormatStr(formData?.paymentEndDt),
      pguploadUrl: uploadFileUrl,
      uploadUserId: formData?.dataList?.userUpload?.[0]?.userId,
      mallCode: formData?.dataList?.mall?.[0]?.mallCode,
    };
    const formDataFile = new FormData();
    formDataFile.append('file', file);
    const res = await refetchData({
      url: '/account/m2088_1/create',
      method: 'PUT',
      data: formDataFile,
      params: dataRequest,
    });
    if (res.data.status == '201') {
      const { totalRow, rowSuccess, excelEncode, fileName } =
        res?.data?.data || {};
      notification({
        message: `${t('{0} out of {1} products are uploaded successfully')}`
          .replace('{0}', rowSuccess)
          .replace('{1}', totalRow),
        type: rowSuccess === totalRow ? 'success' : 'warning',
      });
      if (excelEncode) {
        const binary_string = window.atob(excelEncode);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        const byteArray = new Uint8Array(bytes.buffer);
        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' }),
        );
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      if (rowSuccess > 0) {
        setShowPopupConfirm(false);
        onOk?.();
        setFile(new Blob());
      }
    } else {
      notification({ res });
    }
  };

  /**
   * remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
    if (storedType === 'mall' || storedType === 'pgCompany') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
    if (storedType === 'mall' || storedType === 'pgCompany') {
      setDataLocal(preState => ({
        ...preState,
        [storedType]: dataList[storedType],
      }));
    }
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      cssClass:
        option?.isRequired && dataList.length ? 'show-required-mark' : '',
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };
  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'userUpload':
        return (
          <PopupSelectUser
            text={formData?.[popupType]}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore(popupType, o)}
          />
        );
      case 'pgCompany':
        return (
          <PopupSelectCommonCode
            text={formData?.[popupType]}
            mode="single"
            codeType="PG"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore(popupType, o)}
          />
        );
      case 'mall':
        return (
          <PopupSelectMall
            text={formData?.[popupType]}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('mall', o)}
          />
        );
      default:
        return null;
    }
  };

  /**
   * On form field data chhanged
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'orderStartDt':
        if (
          formData?.orderEndDt &&
          e?.value &&
          compareDate(e?.value, formData.orderEndDt) === 1
        ) {
          setFormData(preState => ({
            ...preState,
            orderStartDt: e?.value,
            orderEndDt: null,
          }));
        } else {
          setFormData(preState => ({
            ...preState,
            [e?.dataField]: e?.value,
          }));
        }
        break;
      case 'orderEndDt':
        if (
          formData.orderStartDt &&
          e?.value &&
          compareDate(e?.value, formData.orderStartDt) === -1
        ) {
          setFormData(preState => ({
            ...preState,
            orderStartDt: null,
            orderEndDt: e?.value,
          }));
        } else {
          setFormData(preState => ({
            ...preState,
            [e?.dataField]: e?.value,
          }));
        }
        break;
      case 'paymentStartDt':
        if (
          formData?.paymentEndDt &&
          e?.value &&
          compareDate(e?.value, formData.paymentEndDt) === 1
        ) {
          setFormData(preState => ({
            ...preState,
            paymentStartDt: e?.value,
            paymentEndDt: null,
          }));
        } else {
          setFormData(preState => ({
            ...preState,
            [e?.dataField]: e?.value,
          }));
        }
        break;
      case 'paymentEndDt':
        if (
          formData.paymentStartDt &&
          e?.value &&
          compareDate(e?.value, formData.paymentStartDt) === -1
        ) {
          setFormData(preState => ({
            ...preState,
            paymentStartDt: null,
            paymentEndDt: e?.value,
          }));
        } else {
          setFormData(preState => ({
            ...preState,
            [e?.dataField]: e?.value,
          }));
        }
        break;
      default:
        break;
    }
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loading} />
          <div className="modal-popup-header">
            <span className="title-page">{t('Upload Excel')}</span>
            <div>
              <Button
                stylingMode="contained"
                type="default"
                text={t('Save')}
                icon="check"
                style={{ marginRight: 5 }}
                onClick={() => {
                  const isValid =
                    formRef?.current?.instance?.validate()?.isValid;
                  if (!isValid) return;
                  if (!file || !file?.size) {
                    return notification({
                      message: t('Please upload file'),
                      type: 'error',
                    });
                  }
                  formRefConfirm?.current?.onOpen();
                }}
              />
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={() => confirmCancelRef?.current?.onOpen()}
              />
            </div>
          </div>
          <Form
            ref={formRef}
            formData={formData}
            labelLocation="top"
            showColonAfterLabel={false}
            scrollingEnabled={true}
            focusStateEnabled={true}
            hoverStateEnabled={true}
            className="body-padding-white"
            colCount={2}
            onFieldDataChanged={onFieldDataChanged}
            items={[
              generateDxTextBox('PG Company', 'pgCompany', 'name', {
                isRequired: true,
              }),
              {
                label: { text: t('Upload  Date') },
                dataField: 'pguploadDm',
                editorType: 'dxDateBox',
                isRequired: true,
                editorOptions: {
                  displayFormat: DateFormat,
                  disabled: true,
                },
              },
              {
                itemType: 'group',
                items: [
                  generateDxTextBox('Mall ID', 'mall', 'mallName', {
                    isRequired: true,
                  }),
                  {
                    label: { text: t('Order Date') },
                    itemType: 'group',
                    colCount: 2,
                    items: [
                      {
                        label: { text: t('From Date') },
                        dataField: 'orderStartDt',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          displayFormat: DateFormat,
                          disabled: true,
                        },
                      },
                      {
                        label: { text: t('End Date') },
                        dataField: 'orderEndDt',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          displayFormat: DateFormat,
                          disabled: true,
                        },
                      },
                    ],
                  },
                  {
                    label: { text: t('Payment Date') },
                    itemType: 'group',
                    colCount: 2,
                    items: [
                      {
                        label: { text: t('From Date') },
                        dataField: 'paymentStartDt',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          displayFormat: DateFormat,
                          disabled: true,
                        },
                      },
                      {
                        label: { text: t('End Date') },
                        dataField: 'paymentEndDt',
                        editorType: 'dxDateBox',
                        isRequired: true,
                        editorOptions: {
                          displayFormat: DateFormat,
                          disabled: true,
                        },
                      },
                    ],
                  },
                  generateDxTextBox('User Upload', 'userUpload', 'name', {
                    readOnly: true,
                  }),
                ],
              },
              {
                label: { text: t('Choose file upload') },
                itemType: 'simple',
                template: 'formUpload',
                isRequired: true,
              },
            ]}
          >
            <Template
              name="formUpload"
              component={() => (
                <div>
                  <FileUpload
                    allowedFileExtensions={['.xlsx, .xls']}
                    accept={'.xlsx, .xls'}
                    mode={'single'}
                    height={235}
                    onSelectedFile={onSelectedFile}
                  />
                  <div style={{ minHeight: 50 }}>
                    {file?.name && (
                      <>
                        <Button
                          icon="close"
                          stylingMode="text"
                          type="default"
                          onClick={() => setFile(new Blob())}
                        />
                        {`${file?.name} - ${file?.size} ${t('bytes')}`}
                      </>
                    )}
                  </div>
                </div>
              )}
            />
          </Form>
          <PopupConfirmWithRef
            ref={formRefConfirm}
            content={'Are you want to save?'}
            isTranslate
            onOk={onUpload}
          />
          <PopupConfirmWithRef
            ref={confirmCancelRef}
            content={`Do you want to cancel all changed?`}
            isTranslate
            onOk={onClickNo}
          />
          {getPopupByType(showPopupByType)}
          <LoadPanel visible={loading} />
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={showPopupConfirm}
      title={''}
      contentRender={content}
      height={'100vh'}
      width={isMobile ? '96%' : '70vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}
export default memo(forwardRef(PopupCreate));
