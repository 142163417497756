import { fork, call, takeLatest, put } from '@redux-saga/core/effects';
import { mainMenuFixedAction } from './mainMenuFixed';
import {
  fetchProgramBookMark as fetchProgramBookMarkApi,
  bookMarkUrl as bookMarkUrlApi,
  fetchHistory as fetchHistoryApi,
  addProgramHistory as addProgramHistoryApi,
  removeProgramHistory as removeProgramHistoryApi,
  reOrderBookMark as reOrderBookMarkApi,
} from 'api/api';

function* fetchProgramBookMark(actions) {
  try {
    const result: any = yield call(
      fetchProgramBookMarkApi,
      actions.payload.params,
    );
    yield put(mainMenuFixedAction.setListProgramBookMark(result.data.data));
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {}
}

function* bookMarkUrl(actions) {
  try {
    const result: any = yield call(bookMarkUrlApi, actions.payload.params);
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {
    actions.payload.onError();
  }
}

function* fetchProgramHistory(actions) {
  try {
    const result: any = yield call(fetchHistoryApi, actions.payload.params);
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {}
}

function* addProgramHistory(actions) {
  try {
    const result: any = yield call(
      addProgramHistoryApi,
      actions.payload.params,
    );
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {}
}

function* removeProgramHistory(actions) {
  try {
    const result: any = yield call(
      removeProgramHistoryApi,
      actions.payload.params,
    );
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {
    actions.payload.onError();
  }
}

function* reOrderBookMark(actions) {
  try {
    const result: any = yield call(reOrderBookMarkApi, actions.payload.params);
    actions.payload.onSuccess(result.data.data);
  } catch (error: any) {}
}

function* watchFetchProgramBookMark() {
  yield takeLatest(
    mainMenuFixedAction.fetchProgramBookMark.type,
    fetchProgramBookMark,
  );
}

function* watchBookMarkUrl() {
  yield takeLatest(mainMenuFixedAction.bookMarkUrl.type, bookMarkUrl);
}

function* watchFetchProgramHistory() {
  yield takeLatest(
    mainMenuFixedAction.fetchProgramHistory.type,
    fetchProgramHistory,
  );
}

function* watchAddProgramHistory() {
  yield takeLatest(
    mainMenuFixedAction.addProgramHistory.type,
    addProgramHistory,
  );
}

function* watchRemoveProgramHistory() {
  yield takeLatest(
    mainMenuFixedAction.removeProgramHistory.type,
    removeProgramHistory,
  );
}

function* watchreOrderBookMark() {
  yield takeLatest(mainMenuFixedAction.reOrderBookMark.type, reOrderBookMark);
}

export function* mainMenuFixedSaga() {
  yield fork(watchFetchProgramBookMark);
  yield fork(watchBookMarkUrl);
  yield fork(watchFetchProgramHistory);
  yield fork(watchAddProgramHistory);
  yield fork(watchRemoveProgramHistory);
  yield fork(watchreOrderBookMark);
}
