/**
 * Update date: 31-08-2023
 * Screen 2042.6
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';
import React, { createContext, useContext, useState, useEffect } from 'react';

const List = React.lazy(() => import('./List'));
const Edit = React.lazy(() => import('./Edit'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2042_6_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

export default function M2042_6() {
  const [dataLocal, setDataLocal] = useLocalStorage('2042.6_mst_dataList', {});
  const { t }: any = useDictionary({ programId: '2042.6' });
  const [data, setData] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    dataList: {
      productIds: [],
      vendorIds: [],
      vendorType: [],
    },
    productIds: '',
    vendorIds: '',
    vendorType: '',
  });
  const [modeView, setModeView] = useState<any>({
    type: 'list',
    data: null,
    isRefresh: false,
  });

  const store: any = new ArrayStore({
    data: data,
    key: ['takeout_id', 'type'],
  });

  useEffect(() => {
    if (!dataLocal) return;
    setFormData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'edit':
        return <Edit />;
      default:
        return <NotFoundPage />;
    }
  };

  const valueContext = {
    t,
    store,
    setData,
    modeView,
    setModeView,
    formData,
    setFormData,
    dataLocal,
    setDataLocal,
  };

  return (
    <ModuleContext.Provider value={valueContext}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
}
