import React, { useEffect, useState, createContext, useContext } from 'react';
import { RouterApp } from './router';
import useAxios from 'axios-hooks';
import { GlobalStyle } from 'styles/global-styles';
import '../styles/style.scss';
// import '../styles/css/dx.light.css';
import '../styles/css/dx-customize.css';
import '../styles/css/dx-premium.css';
import enMessages from './../locales/en.json';
import viMessages from 'devextreme/localization/messages/vi.json';
import viCustom from './../locales/vi.json';
import koMessages from './../locales/ko.json';
import jaMessages from './../locales/ja.json';
import zhMessages from './../locales/zh.json';
import { locale, loadMessages } from 'devextreme/localization';
import config from 'devextreme/core/config';
import LoadPanel from 'app/components/LoadPanel';
import useBarcodeSound from 'hooks/useBarcodeSound';
import useCenterInfo from 'hooks/useCenterInfo';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'store/features/auth/authSlice';
import { Helmet } from 'react-helmet-async';
import Favicon from 'react-favicon';
import { LANG_DEFAULT } from 'constant';

const AppContext = createContext({
  myPageApp: '',
});

AppContext.displayName = 'AppContext';

export const useApp = () => {
  const context = useContext(AppContext);
  return context;
};

export function App() {
  const { s, soundTypeState, setSoundType, loadingVoice } = useBarcodeSound();

  const { centerLogoUrl, faviconUrl, centerName } = useCenterInfo();
  const [myPageApp, setMyPageApp] = useState<any>({});
  const [appLogo, setAppLogo] = useState<string>(centerLogoUrl || '');
  const [voca, setVoca] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [reloadLanguage, setReloadLanguage] = useState(true);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [themePro, setThemePro] = useState(true);
  const dispatch = useDispatch();

  const [{}, fetchMyPageData] = useAxios(
    {
      url: '/user/my-page',
      method: 'GET',
    },
    { manual: true },
  );

  const [{ loading: loadingMultiLanguage }, fetchDictionary] = useAxios(
    {
      url: '/core/multi-language',
      method: 'POST',
    },
    { manual: true },
  );

  /**
   * Loading last language is stored localStorage
   * When Init app first time
   */
  useEffect(() => {
    const myPagePreferenceLocal = JSON.parse(
      localStorage.getItem('myPagePreference') || '{}',
    );
    document.documentElement.lang =
      myPagePreferenceLocal?.language || LANG_DEFAULT;
    document.documentElement.translate = false;
    locale(myPagePreferenceLocal?.language || LANG_DEFAULT);

    let meta = document.createElement('meta');
    meta.name = 'google';
    meta.content = 'notranslate';
    document.head.appendChild(meta);
  }, []);

  const getDictionary = async (lang: string) => {
    const res = await fetchDictionary({
      data: {
        type: 'ui',
        languageCode: lang,
      },
    });
    if (res?.data?.status === '200') {
      const messages = [...res?.data?.data].filter(
        o => o?.languageCode === lang,
      );
      let l: any = {};
      messages?.forEach((o, i) => {
        if (o?.translation || o?.base) {
          l[`${o?.id}-${o?.base}`] = o?.translation || o?.autoTranslation;
        }
      });
      setVoca(l);
      loadMessages({
        [lang]: l,
      });
      setTimeout(() => setFirstRender(false));
    }
  };

  useEffect(() => {
    if (!reloadLanguage) {
      return;
    }
    loadMessages(enMessages);
    loadMessages(viMessages);
    loadMessages(viCustom);
    loadMessages(koMessages);
    loadMessages(jaMessages);
    loadMessages(zhMessages);
    config({
      forceIsoDateParsing: true,
    });
    setReloadLanguage(false);
  }, [reloadLanguage]);

  const [{}, updateMyPageData] = useAxios(
    {
      url: '/user/my-page/preferences',
      method: 'PUT',
    },
    { manual: true },
  );

  useEffect(() => {
    const myPagePreferenceLocal = JSON.parse(
      localStorage.getItem('myPagePreference') ?? '{}',
    );
    if (isLoggedIn) {
      const fetchMyPage = async () => {
        const res = await fetchMyPageData();
        const newData = { ...myPagePreferenceLocal, ...(res?.data || {}) };
        localStorage.setItem('myPagePreference', JSON.stringify(newData));
        getDictionary(newData?.language || LANG_DEFAULT);
        document.documentElement.lang = newData?.language || LANG_DEFAULT;
        locale(newData?.language || LANG_DEFAULT);
        setThemePro(newData?.themePro || true);
        setMyPageApp(newData);
      };
      fetchMyPage();
    }
  }, [dispatch, fetchMyPageData, isLoggedIn, updateMyPageData]);

  useEffect(() => {
    if (!Object.keys(myPageApp)?.length) return;
    document.body.style.fontFamily = `${
      myPageApp?.fontName || 'arial'
    }, sans-serif`;
    // Defined link css
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    const cssLink = `${window.location.origin}/themes/dx.${
      myPageApp?.theme || 'light'
    }.${myPageApp?.fontSize || 'medium'}.css`;
    link.setAttribute('href', cssLink);
    document.head.appendChild(link);
  }, [myPageApp]);

  const value: any = {
    s,
    myPageApp,
    setMyPageApp,
    voca,
    setVoca,
    firstRender,
    setFirstRender,
    appLogo,
    setAppLogo,
    setReloadLanguage,
    soundTypeState,
    setSoundType,
    themePro,
    setThemePro,
  };
  const loadingAll = loadingMultiLanguage || loadingVoice;
  return (
    <>
      <AppContext.Provider value={value}>
        <LoadPanel visible={loadingAll} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{centerName}</title>
        </Helmet>
        <Favicon url={faviconUrl || ''} />
        {themePro && (
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/dx-premium.css"
          />
        )}
        <RouterApp />
      </AppContext.Provider>
      <GlobalStyle />
    </>
  );
}
