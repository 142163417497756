/**
 * Update date: 22-05-2023
 * Screen 2016.6
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import ArrayStore from 'devextreme/data/array_store';
import { dateFormatStr } from 'utils/format';

const List = React.lazy(() => import('./List'));
  /**
   * create context
   */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2016_6_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2016_6 = () => {
  const { t }: any = useDictionary({ programId: '2016.5' });
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    setPackUnpackTp: '',
    status: '',
    dataList: {
      vendor: [],
      product: [],
      productGroup: [],
      largeCategories: [],
      mediumCategories: [],
      smallCategories: [],
      subCategories: [],
    },
    vendor: '',
    product: '',
    productGroup: '',
    largeCategories: '',
    mediumCategories: '',
    smallCategories: '',
    subCategories: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: ['setpackunpack_id', 'line_id'],
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      fromDate: dateFormatStr(formSearchData?.fromDate),
      endDate: dateFormatStr(formSearchData?.endDate),
      status: formSearchData?.status,
      setPackUnpackTp: formSearchData?.setPackUnpackTp,
      vendorIds: formSearchData?.dataList?.vendor?.map((o: any) => o?.vendorId),
      largeCategories: formSearchData?.dataList?.largeCategories?.map(
        (o: any) => o?.code,
      ),
      mediumCategories: formSearchData?.dataList?.mediumCategories?.map(
        (o: any) => o?.code,
      ),
      smallCategories: formSearchData?.dataList?.smallCategories?.map(
        (o: any) => o?.code,
      ),
      subCategories: formSearchData?.dataList?.subCategories?.map(
        (o: any) => o?.code,
      ),
      productIds: formSearchData?.dataList?.product?.map(
        (o: any) => o?.productId,
      ),
      productGroupIds: formSearchData?.dataList?.productGroup?.map(
        (o: any) => o?.productGroupId,
      ),
    };

    const res = await refetchData({
      url: '/warehouse/set-pack-unpack-inquiry',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      const dataMapping = (res?.data?.data || []).map(o => {
        o.task_type = t(o.task_type);
        o.setpackunpack_status_nm =
          o?.setpackunpack_status === '1' ? t('Fixed') : t('Pending');
        o.setpackunpack_tp_nm =
          o?.setpackunpack_tp === '1' ? t('Unpack') : t('Pack');
        return o;
      });
      setData(dataMapping);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
    store,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2016_6;
