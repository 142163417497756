/**
 * Update date: 17-05-2023
 * Screen 2011.6
 */
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import React, { useState, useRef } from 'react';
import { uniqBy } from 'lodash';
import Collapse from 'app/components/Collapse';
import useDictionary from 'hooks/useDictionary';
import { useStoreContext } from '..';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import useFormatTime from 'hooks/useFormatTime';
import DateOptions from 'app/components/DateOptions';
import moment from 'moment';

interface IFormSearchProps {
  onSearch?: any;
}

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
    title: 'Search Large Category',
  },
  mediumCategory: {
    codeType: 'MC',
    title: 'Search Medium Category',
  },
  smallCategory: {
    codeType: 'SC',
    title: 'Search Small Category',
  },
  subCategory: {
    codeType: 'UC',
    title: 'Search Sub Category',
  },
  productTypes: {
    codeType: 'PT',
    title: 'Product Type',
  },
  product: {
    codeType: 'product',
    title: 'Product',
  },
  productMakers: {
    codeType: 'MK',
    title: 'Maker',
  },
  vender: {
    codeType: 'vendor',
    title: 'Vendor',
  },
};

const FormSearch = ({ onSearch }: IFormSearchProps) => {
  const { t }: any = useDictionary({});
  const [showPopupByType, setShowPopupByType] = useState('');
  const formRef: any = useRef(null);
  const context: any = useStoreContext();
  const { formData, setFormData, formStore, setFormStore, setDataLocal } =
    context;
  const { DateFormat } = useFormatTime();

  /**
   * on submit
   *
   * @return {*}
   */
  function onSubmit() {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (!isValid) return;

    const searchParams = {
      largeCategoryCodes:
        formStore.largeCategory === ''
          ? []
          : formStore.largeCategory?.map(o => o?.code),
      mediumCategoryCodes:
        formStore.mediumCategory === ''
          ? []
          : formStore.mediumCategory?.map(o => o?.code),
      smallCategoryCodes:
        formStore.smallCategory === ''
          ? []
          : formStore.smallCategory?.map(o => o?.code),
      subCategoryCodes:
        formStore.subCategory === ''
          ? []
          : formStore.subCategory?.map(o => o?.code),
      productType:
        formStore.productTypes === ''
          ? []
          : formStore.productTypes?.map(o => o?.code),
      makerId:
        formStore.productMakers === ''
          ? []
          : formStore.productMakers?.map(o => o?.code),
      productGroupIds:
        formStore.productGroup === ''
          ? []
          : formStore.productGroup?.map(o => o?.productGroupId),
      productIds:
        formStore.product === ''
          ? []
          : formStore.product?.map(o => o?.productId),
      vendorIds:
        formStore.vendor === '' ? [] : formStore.vendor?.map(o => o?.vendorId),
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      timezone: -(new Date().getTimezoneOffset() / 60),
      tradeType: formData?.tradeType,
    };

    onSearch(searchParams);
  }

  /**
   * remove store
   *
   * @param {*} varNm
   * @param {*} index
   */
  const removeStore = (varNm, index) => {
    let newStore;
    if (index === -1) {
      setFormStore({ ...formStore, [varNm]: [] });
    } else {
      const l = [...formStore[varNm]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setFormStore({ ...formStore, [varNm]: newStore });
    }
  };

  /**
   * update store
   *
   * @param {*} varNm
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (varNm, data, key: any = false) => {
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(formStore?.[varNm] || []), ...data], key);
    }
    const newStore = { ...formStore, [varNm]: filterData };
    setFormData({ ...formData, [varNm]: '' });
    setFormStore({ ...newStore });
    setShowPopupByType('');
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {number} [numberShowMore=2]
   * @param {boolean} [readOnly=false]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    numberShowMore = 2,
    readOnly = false,
  ): any => {
    const dataList = formStore?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });

    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      editorOptions: {
        readOnly: readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
      case 'productTypes':
      case 'productMakers':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'productGroup'}
            text={formData?.productGroup}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data =>
              updateStore('productGroup', data, 'productGroupId')
            }
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={popupType === 'product'}
            text={formData?.product}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore('product', data, 'productId')}
          />
        );
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('vendor', o, 'vendorId')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChange = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (formData.endDate && e?.value && e?.value > formData.endDate)
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        if (formData.fromDate && e?.value && e?.value < formData.fromDate)
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  /**
   * on change date
   *
   * @param {*} fromDateValue
   * @param {*} endDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, endDateValue) => {
    if (!fromDateValue || !endDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', endDateValue);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, endDate) => onChangeDate(fromDate, endDate)}
        />
        <Form
          ref={formRef}
          formData={formData}
          readOnly={false}
          showColonAfterLabel={false}
          colCount={2}
          onFieldDataChanged={onFieldDataChange}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              label: { text: t('End Date') },
              dataField: 'endDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
            generateDxTextBox(
              'Product Type',
              'productTypes',
              'name',
              2,
              formData?.isReadOnlyProductTp,
            ),
            generateDxTextBox('Product', 'product', 'productNm'),
            generateDxTextBox('Maker', 'productMakers', 'name', 2),
            generateDxTextBox('Vendor', 'vendor', 'vendorName', 2),
          ]}
          labelLocation="top"
        />
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          onFieldDataChanged={e => {
            if (e.dataField === 'tradeType') {
              setDataLocal(pre => ({ ...pre, tradeType: e.value }));
            }
          }}
          items={[
            generateDxTextBox('Large Category', 'largeCategory', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
            generateDxTextBox('Small Category', 'smallCategory', 'name'),
            generateDxTextBox('Sub Category', 'subCategory', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            {
              label: { text: t('Trade Type') },
              dataField: 'tradeType',
              editorType: 'dxSelectBox',
              editorOptions: {
                items: [
                  {
                    label: t('Traded'),
                    value: '0',
                  },
                  {
                    label: t('Non Traded'),
                    value: '1',
                  },
                  {
                    label: t('Discontinued'),
                    value: '2',
                  },
                ],
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ]}
          labelLocation="top"
          colCount={2}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
