/**
 * Update date: 25-05-2023
 * Screen 2041.9
 */
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
import Form from 'devextreme-react/form';
import useFormatTime from 'hooks/useFormatTime';
import { cloneDeep, uniqBy } from 'lodash';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useProductForwardingStandardPrice } from '..';

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
    title: 'Search Large Category',
  },
  mediumCategory: {
    codeType: 'MC',
    title: 'Search Medium Category',
  },
  smallCategory: {
    codeType: 'SC',
    title: 'Search Small Category',
  },
  subCategory: {
    codeType: 'UC',
    title: 'Search Sub Category',
  },
};

const FormSearch = () => {
  const rootContext: any = useProductForwardingStandardPrice();
  const { t, formSearchData, setFormSearchData, refetchData } =
    rootContext || {};
  const [showPopupByType, setShowPopupByType] = useState('');
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);
  const { DateFormat } = useFormatTime();

  // handle search
  const onSubmit = async () => {
    collapseRef?.current?.onCollappse();
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid) {
      const searchParams = {
        fromDate: formSearchData?.fromDate
          ? moment(formSearchData?.fromDate).format('YYYY-MM-DD')
          : null,
        endDate: formSearchData?.toDate
          ? moment(formSearchData?.toDate).format('YYYY-MM-DD')
          : null,
        vendorIds: formSearchData.dataList.vendor.map((o: any) => o?.vendorId),
        registerStatus: formSearchData?.register,
        largeCategoryCodes: formSearchData.dataList.largeCategory.map(
          (o: any) => o?.code,
        ),
        mediumCategoryCodes: formSearchData.dataList.mediumCategory.map(
          (o: any) => o?.code,
        ),
        smallCategoryCodes: formSearchData.dataList.smallCategory.map(
          (o: any) => o?.code,
        ),
        subCategoryCodes: formSearchData.dataList.subCategory.map(
          (o: any) => o?.code,
        ),
        productIds: formSearchData.dataList.product.map(
          (o: any) => o?.productId,
        ),
        productGroupIds: formSearchData.dataList.productGroup.map(
          (o: any) => o?.productGroupId,
        ),
      };

      refetchData({
        data: searchParams,
      });
    } else {
      collapseRef?.current?.onCollappse();
    }
  };

  // remote data input popup
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormSearchData({
      ...formSearchData,
      dataList: dataList,
    });
  };
  // update data input popup
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formSearchData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormSearchData(newData);
    setShowPopupByType('');
  };
  // generate form input
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    numberShowMore = 2,
    colSpan = 0,
  ): any => {
    const dataList = formSearchData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: colSpan,
      editorOptions: {
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };
  // render popup select
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formSearchData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('vendor', o, 'vendorId')}
          />
        );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'productGroup'}
            text={formSearchData?.productGroup}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data =>
              updateStore('productGroup', data, 'productGroupId')
            }
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={popupType === 'product'}
            text={formSearchData?.product}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore('product', data, 'productId')}
          />
        );
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formSearchData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };
  // on field data change
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (
          formSearchData.toDate &&
          e?.value &&
          e?.value > formSearchData.toDate
        ) {
          formRef?.current?.instance.updateData('toDate', null);
        }
        break;
      case 'toDate':
        if (
          formSearchData.fromDate &&
          e?.value &&
          e?.value < formSearchData.fromDate
        )
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, toDateValue) => {
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('toDate', toDateValue);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions onChangeValue={onChangeDate} />
        <Form
          ref={formRef}
          formData={formSearchData}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            {
              label: { text: t('End Date') },
              dataField: 'toDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            generateDxTextBox('Vendor', 'vendor', 'vendorName', 2, 1),
            {
              label: { text: t('Register') },
              dataField: 'register',
              editorType: 'dxSelectBox',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                defaultValue: '',
                items: [
                  { label: t('All'), value: '' },
                  { label: t('Registered'), value: '1' },
                  { label: t('Not Registered'), value: '2' },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
        />
      </>
    );
  };

  /**
   *  render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formSearchData}
          showColonAfterLabel={false}
          items={[
            generateDxTextBox('Large Category', 'largeCategory', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
            generateDxTextBox('Small Category', 'smallCategory', 'name'),
            generateDxTextBox('Sub Category', 'subCategory', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            generateDxTextBox('Product', 'product', 'productNm'),
          ]}
          labelLocation="top"
          colCount={3}
          onFieldDataChanged={onFieldDataChanged}
        />
      </>
    );
  };

  return (
    <>
      <Collapse onSubmit={onSubmit} ref={collapseRef}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </>
  );
};

export default FormSearch;
