/**
 * Update date: 31-05-2023
 * Screen 2046.9
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import ListInventory from './ListInvetory';
import { LIMIT_ROWS, LIMIT_ROWS_MESS } from 'constant/dataConstant';
import { notification } from 'utils/notification';
import useDictionary from 'hooks/useDictionary';
/**
 * create context
 */
const InventoryReceivingContext = createContext({
  refetchData: () => {},
  loadingData: false,
  dataInventory: [],
});

InventoryReceivingContext.displayName = 'InventoryReceivingContext';

export const useInventoryReceiving = () => {
  const context = useContext(InventoryReceivingContext);
  return context;
};

function CenterForwardingReturnInquiry(props) {
  const { t }: any = useDictionary({ programId: '2046.9' });
  const [dataInventory, setDataInventory] = useState([]);
  const [{ data, loading: loadingInventory }, refetchData] = useAxios(
    {
      url: '/warehouse/return-control/forward-return-inquire',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );
  const value: any = {
    refetchData,
    dataInventory,
    loadingInventory,
  };
  useEffect(() => {
    if (data?.data) {
      if (data?.data?.length >= LIMIT_ROWS) {
        notification({
          type: 'warning',
          message: t(LIMIT_ROWS_MESS).replaceAll('{0}', LIMIT_ROWS),
          displayTime: 5000,
        });
      }
      setDataInventory(data?.data);
    }
  }, [data]);
  return (
    <InventoryReceivingContext.Provider value={value}>
      <FormSearch />
      <ListInventory />
    </InventoryReceivingContext.Provider>
  );
}

export default CenterForwardingReturnInquiry;
