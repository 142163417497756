import { fork, call, takeLatest, put } from '@redux-saga/core/effects';
import { centerZoneActions } from './centerZoneSlice';
import {
  downloadFileExampleCenterZone as downloadFileExampleCenterZoneApi,
  uploadFileCenterZone as uploadFileCenterZoneApi,
  getDetailCenterZone as getDetailCenterZoneApi,
} from '../../../api/api';

function* downloadFileExample(actions) {
  try {
    const result = yield call(downloadFileExampleCenterZoneApi, {});
    actions.payload.onSuccess(result);
  } catch (error: any) {
    actions.payload.onError();
  }
}

function* uploadFileCenterZone(actions) {
  try {
    const data = yield call(uploadFileCenterZoneApi, actions.payload.data);
    actions.payload.onSuccess(data?.data);
  } catch (error: any) {
    actions.payload.onError(error);
  }
}
function* getZoneInfo(actions) {
  try {
    if (!actions.payload) return;
    const result = yield call(getDetailCenterZoneApi, actions.payload);
    const { data } = result.data;
    const zone = {
      zoneId: data.zoneId,
      zoneNm: data.zoneNm,
      zoneOrder: parseInt(data.zoneOrder),
      zoneStockInterlockTf: data.zoneStockInterlockTf,
      zoneUseTf: data.zoneUseTf,
      zoneNt: data.zoneNt,
      zoneCd: data.zoneCd,
    };
    const listLocation = data.locationResponse.map((map: any, i: any) => ({
      ...map,
      locationCdOld: map.locationCd,
      index: i,
    }));
    yield put(centerZoneActions.updateZoneInfo(zone));
    yield put(centerZoneActions.addListLocation(listLocation));
  } catch (error: any) {}
}

//
function* watchDownloadFileExample() {
  yield takeLatest(
    centerZoneActions.downloadFileExample.type,
    downloadFileExample,
  );
}

function* watchUploadFileCenterZone() {
  yield takeLatest(centerZoneActions.uploadFile, uploadFileCenterZone);
}
function* watchGetZoneInfo() {
  yield takeLatest(
    [centerZoneActions.openViewLocation, centerZoneActions.openModalZone],
    getZoneInfo,
  );
}

export function* centerOperatorSaga() {
  yield fork(watchDownloadFileExample);
  yield fork(watchUploadFileCenterZone);
  yield fork(watchGetZoneInfo);
}
