/**
 * Update date: 22-05-2023
 * Screen 2017.3
 */
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import Form from 'devextreme-react/form';
import React, { useState, useRef, useEffect } from 'react';
import { uniqBy } from 'lodash';
import DateOptions from 'app/components/DateOptions';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import Collapse from 'app/components/Collapse';
import useFormatTime from 'hooks/useFormatTime';
import moment from 'moment';
import { useProductMaster } from '..';
import './style.scss';
import useDictionary from 'hooks/useDictionary';

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
    title: 'Search Large Category',
  },
  mediumCategory: {
    codeType: 'MC',
    title: 'Search Medium Category',
  },
  smallCategory: {
    codeType: 'SC',
    title: 'Search Small Category',
  },
  subCategory: {
    codeType: 'UC',
    title: 'Search Sub Category',
  },
  productType: {
    codeType: 'PT',
  },
};

const FormSearch = () => {
  const productMasterContext: any = useProductMaster();
  const { params, setParams, refetchData, productTpPermission } =
    productMasterContext || {};
  const [formStore, setFormStore] = useState<any>({
    largeCategory: [],
    mediumCategory: [],
    smallCategory: [],
    subCategory: [],
    product: [],
    productGroup: [],
    mallInfo: [],
    productType: [],
  });
  const [formData, setFormData] = useState<any>({
    startChangeDate: params?.startChangeDate,
    endChangeDate: params?.endChangeDate,
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    mallInfo: '',
    productType: '',
  });
  const [showPopupByType, setShowPopupByType] = useState('');
  const formRef: any = useRef(null);
  const collapseRef: any = useRef(null);
  const { DateFormat } = useFormatTime();

  const { t }: any = useDictionary({
    programId: '2017.3',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormStore(preState => ({
      ...preState,
      productType: productTpPermission,
    }));
    setFormData(preState => ({
      ...preState,
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  /**
   * on submit
   *
   */
  const onSubmit = () => {
    collapseRef?.current?.onCollappse();
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid) {
      const data = {
        ...params,
        // mallInfoId: formStore.mallInfo.map((o: any) => o?.mallInfoId)?.[0],
        mallCode: 'HUB',
        startChangeDate: formData?.startChangeDate
          ? moment(formData?.startChangeDate).format('YYYY-MM-DD')
          : null,
        endChangeDate: formData?.endChangeDate
          ? moment(formData?.endChangeDate).format('YYYY-MM-DD')
          : null,
        largeCategories: formStore.largeCategory.map((o: any) => o?.code),
        mediumCategories: formStore.mediumCategory.map((o: any) => o?.code),
        smallCategories: formStore.smallCategory.map((o: any) => o?.code),
        subCategories: formStore.subCategory.map((o: any) => o?.code),
        productIds: formStore.product.map((o: any) => o?.productId),
        productGroupIds: formStore.productGroup.map(
          (o: any) => o?.productGroupId,
        ),
        productTypes: formStore.productType.map((o: any) => o?.code),
      };
      refetchData({
        data: data,
      });
      setParams(data);
    }
  };

  /**
   * on remove store
   *
   * @param {*} storeType
   * @param {*} index
   */
  const removeStore = (storeType, index) => {
    let newStore;
    if (index === -1) {
      setFormStore({ ...formStore, [storeType]: [] });
    } else {
      const l = [...formStore[storeType]];
      newStore = [...l.slice(0, index), ...l.slice(index + 1)];
      setFormStore({ ...formStore, [storeType]: newStore });
    }
  };

  /**
   * on update store
   *
   * @param {*} storeType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storeType, data, key: any = false) => {
    const filterData = Array.isArray(data)
      ? uniqBy([...formStore?.[storeType], ...data], key)
      : uniqBy([...formStore?.[storeType], data], key);
    const newStore = { ...formStore, [storeType]: filterData };
    setFormData({ ...formData, [storeType]: '' });
    setFormStore(newStore);
    setShowPopupByType('');
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {number} [numberShowMore=2]
   * @param {boolean} [readOnly=false]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    numberShowMore = 2,
    readOnly = false,
  ): any => {
    const dataList = formStore?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });

    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      editorOptions: {
        readOnly: readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render get popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      // case 'mall':
      //   return (
      //     <PopupSelectMall
      //       visible={popupType === 'mall'}
      //       text={formData?.mallInfo}
      //       mode="single"
      //       onHiding={() => setShowPopupByType('')}
      //       onSubmit={data => updateStore('mallInfo', data, 'mallInfoId')}
      //     />
      //   );
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'productGroup'}
            text={formData?.productGroup}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data =>
              updateStore('productGroup', data, 'productGroupId')
            }
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={popupType === 'product'}
            text={formData?.product}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore('product', data, 'productId')}
          />
        );
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
      case 'productType':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'startChangeDate':
        if (
          formData.endChangeDate &&
          e?.value &&
          e?.value > formData.endChangeDate
        )
          formRef?.current?.instance.updateData('endChangeDate', null);
        break;
      case 'endChangeDate':
        if (
          formData.startChangeDate &&
          e?.value &&
          e?.value < formData.startChangeDate
        )
          formRef?.current?.instance.updateData('startChangeDate', null);
        break;
      default:
        break;
    }
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, toDateValue) => {
    formRef?.current?.instance?.updateData('startChangeDate', fromDateValue);
    formRef?.current?.instance?.updateData('endChangeDate', toDateValue);
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
          optionSelectAll={true}
        />
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          items={[
            {
              label: { text: t('Product Change Date') },
              dataField: 'startChangeDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            {
              label: { text: ' ' },
              dataField: 'endChangeDate',
              editorType: 'dxDateBox',
              cssClass: 'end-date-customize',
              editorOptions: {
                displayFormat: DateFormat,
              },
            },
            // {
            //   label: { text: t('Mall') },
            //   dataField: 'mallInfo',
            //   editorType: 'dxTextBox',
            //   colSpan: 2,
            //   isRequired: !formStore?.mallInfo?.[0]?.mallId ? true : false,
            //   editorOptions: {
            //     onEnterKey: () => setShowPopupByType('mall'),
            //     buttons: [
            //       {
            //         name: 'data1',
            //         location: 'before',
            //         options: {
            //           icon: 'close',
            //           text: formStore?.mallInfo?.[0]?.mallName,
            //           onClick: () => removeStore('mallInfo', 0),
            //           visible: !!formStore?.mallInfo?.[0]?.mallId,
            //           rtlEnabled: true,
            //         },
            //       },

            //       {
            //         name: 'clearAll',
            //         location: 'after',
            //         options: {
            //           stylingMode: 'text',
            //           icon: 'close',
            //           visible: !!formStore?.mallInfo?.[0]?.mallId,
            //           onClick: () => removeStore('mallInfo', -1),
            //         },
            //       },
            //       {
            //         name: 'search',
            //         location: 'after',
            //         options: {
            //           stylingMode: 'text',
            //           icon: 'search',
            //           onClick: () => setShowPopupByType('mall'),
            //         },
            //       },
            //     ],
            //   },
            // },
          ]}
          labelLocation="top"
          colCount={4}
          onFieldDataChanged={onFieldDataChanged}
        />
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <Form
          formData={formData}
          showColonAfterLabel={false}
          items={[
            generateDxTextBox('Large Category', 'largeCategory', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            generateDxTextBox('Small Category', 'smallCategory', 'name'),
            generateDxTextBox('Sub Category', 'subCategory', 'name'),
            generateDxTextBox('Product', 'product', 'productNm'),
            generateDxTextBox(
              'Product Type',
              'productType',
              'name',
              2,
              formData?.isReadOnlyProductTp,
            ),
          ]}
          labelLocation="top"
          colCount={3}
        />
      </>
    );
  };

  return (
    <div className="product-master-search-form">
      <Collapse onSubmit={onSubmit} ref={collapseRef}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
