/**
 * Update date: 14-06-2023
 * Switch theme component
 */
import { useApp } from 'app';
import { Switch } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useState } from 'react';

const SwitchTheme = () => {
  const myAppContext: any = useApp();
  const { themePro } = myAppContext || {};
  const [switchTheme, setSwitchTheme] = useState(themePro);
  useEffect(() => {
    setSwitchTheme(themePro);
  }, [themePro]);
  const { t } = useDictionary({});
  return (
    <>
      <div style={{ width: 106 }}/>
      {/* <span style={themePro ? { marginRight: 15 } : {}}>
        {t('Smart')}
        <Switch
          className="customize-switch"
          style={{ margin: '0 5px' }}
          defaultValue={switchTheme}
          value={switchTheme}
          onValueChange={value => {
            setSwitchTheme(value);
            const myPagePreferenceLocal = JSON.parse(
              localStorage.getItem('myPagePreference') || '{}',
            );
            localStorage.setItem(
              'myPagePreference',
              JSON.stringify({
                ...myPagePreferenceLocal,
                themePro: value,
              }),
            );
            document.location.reload();
          }}
        />
        {t('Pro')}
      </span> */}
    </>
  );
};

export default SwitchTheme;
