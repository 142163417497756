/**
 * Update date: 14-06-2023
 * Popup product detail component
 */
import { Button, Popup } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import {
  createContext,
  useContext,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import ProductAnalysis from './ProductAnalysis';
import PurchaseInformation from './PurchaseInformation';
import SalesInformation from './SalesInformation';
import EventHistory from './EventHistory';
import useAxios from 'axios-hooks';
import { dateFormatStr } from 'utils/format';
import moment from 'moment';
import LoadPanel from 'app/components/LoadPanel';

const ProductDetailContext = createContext({
  myPageApp: '',
});

ProductDetailContext.displayName = 'ProductDetailContext';

export const useProductDetail = () => {
  const context = useContext(ProductDetailContext);
  return context;
};

const PopupProductDetail = (props, ref) => {
  const [productData, setProductData] = useState<any>({});
  const [productId, setProductId] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const { t } = useDictionary({});

  useImperativeHandle(ref, () => ({
    onShowPopup,
  }));

  const [{ data, loading }, refetch] = useAxios(
    {
      url: 'product/product/popup-purchase-sale-event',
      method: 'POST',
    },
    {
      manual: true,
      autoCancel: true,
      useCache: false,
    },
  );

  useEffect(() => {
    if (productId) {
      refetch({
        data: {
          productId: productId,
          startDate: dateFormatStr(moment(new Date()).subtract(3, 'days')),
          endDate: dateFormatStr(new Date()),
          type: '',
        },
      });
    }
  }, [productId]);

  /**
   * on show popup
   *
   * @param {*} productId
   */
  const onShowPopup = productId => {
    setVisible(true);
    setProductId(productId);
  };
  /**
   * on close popup
   *
   */
  const onClosePopup = () => {
    setVisible(false);
    setProductId(null);
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return visible ? (
      <div className="popup-product-detail">
        <div
          className="modal-popup-header"
          style={{
            padding: '5px 20px',
            borderBottom: '1px dotted #ccc',
          }}
        >
          <span
            className="title-page"
            style={{
              margin: 0,
              fontSize: 18,
              lineHeight: '28px',
              maxWidth: '30%',
              width: 'auto',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {productData?.product_nm || t('Product Name')}
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              maxWidth: '70%',
              width: 'auto',
            }}
          >
            <div
              style={{
                width: 'auto',
                padding: 8,
                backgroundColor: '#F2F4F7',
                borderRadius: 6,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                marginRight: 10,
              }}
            >
              <span style={{ marginRight: 50 }}>
                {t('*Reference date ( Today - 1 )')}
              </span>
              <span>
                {t(
                  '*Inventory = Inventory the previous day, Weekly = The forward quantity from inventory/the previous day~7 days, Monthly = Forward quantity from the previous day~30 days, Yearly = Forward quantity from the previous day~365',
                )}
              </span>
            </div>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={() => {
                  onClosePopup();
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: '0 20px' }}>
          <TabPanel
            className="custom-tabpanel-border-none"
            defaultSelectedIndex={0}
            swipeEnabled={false}
          >
            <TabItem title={t('Product Analysis')}>
              <ProductAnalysis />
            </TabItem>
            {/* <TabItem title={t('향후디자인예정')}></TabItem> */}
            <TabItem title={t('Purchase Information')}>
              <PurchaseInformation />
            </TabItem>
            <TabItem title={t('Sales Information')}>
              <SalesInformation />
            </TabItem>
            <TabItem title={t('Event History')}>
              <EventHistory />
            </TabItem>
          </TabPanel>
        </div>
      </div>
    ) : null;
  };

  const value: any = {
    productData,
    setProductData,
    productId,
    visible,
    t,
    data,
    dateFormatStr,
  };

  return (
    <ProductDetailContext.Provider value={value}>
      <LoadPanel visible={loading} />
      <Popup
        className="modal-content-popupproduct"
        visible={visible}
        title={t('Product')}
        contentRender={content}
        animation={{ show: undefined, hide: undefined }}
        height={'95vh'}
        width={'80vw'}
        position={{
          boundaryOffset: { x: undefined, y: 50 },
        }}
      />
    </ProductDetailContext.Provider>
  );
};

export default forwardRef(PopupProductDetail);
