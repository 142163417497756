/**
 * Update date: 2024-07-31
 * Screen 2088.1
 */
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import useFormatTime from 'hooks/useFormatTime';
import useProfileInfo from 'hooks/useProfileInfo';
import { memo, useRef } from 'react';
import { notification } from 'utils/notification';
import PopupCreate from './PopupCreate';

const UploadHistoryTab = ({ t, dataHistory, refFormSearch }) => {
  const userInfo: any = useProfileInfo();
  const dataGridRef: any = useRef(null);
  const createRef: any = useRef(null);
  const { DateTimeFormat, DateFormat } = useFormatTime();
  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on download templete
   *
   * @return {*}
   */
  const onDownloadTemplate = async () => {
    try {
      const res = await refetchData({
        url: '/account/m2088_1/download-template-file',
        method: 'GET',
        responseType: 'blob',
      });
      if (res?.status === 200) {
        const filename = '2088.1-PG Sales Data Upload-Template.xlsx';
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        notification({ res });
      }
    } catch (error) {
      notification({
        message: t('Can not download file'),
        type: 'error',
      });
    }
  };

  /**
   * on dowload file upload
   *
   * @return {*}
   */
  const onDownloadFileUpload = (url: string, fileName: string) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const columns: any = [
    {
      dataField: 'pgcompanyNm',
      caption: t('PG Company'),
    },
    {
      dataField: 'pguploadDm',
      caption: t('Upload Date'),
      dataType: 'date',
      format: DateTimeFormat,
    },
    {
      dataField: 'orderStartDt',
      caption: t('Order Start Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'orderEndDt',
      caption: t('Order End Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'paymentStartDt',
      caption: t('Payment Start Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'paymentEndDt',
      caption: t('Payment End Date'),
      dataType: 'date',
      format: DateFormat,
    },
    {
      dataField: 'pguploadFilename',
      caption: t('File'),
      cellRender: record => {
        const { pguploadUrl, pguploadFilename } = record?.data || {};
        return (
          <div>
            <div
              className="cell-link"
              onClick={() =>
                onDownloadFileUpload(pguploadUrl, pguploadFilename)
              }
            >
              {pguploadFilename}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'uploadUserNm',
      caption: t('User'),
    },
  ];

  return (
    <div>
      <DataGridFull
        ref={dataGridRef}
        dataSource={dataHistory}
        columns={columns}
        fixedLeft={2}
        fixedRight={1}
        options={{
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'download',
                  text: t('Download Template'),
                  onClick: onDownloadTemplate,
                },
              },
              {
                locateInMenu: 'auto',
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'add',
                  text: t('Upload Excel'),
                  onClick: () => createRef?.current?.onOpen?.(userInfo),
                },
              },
            );
          },
        }}
      />
      <LoadPanel visible={loading} />
      <PopupCreate
        ref={createRef}
        onOk={() => {
          refFormSearch?.current?.onSearch?.();
        }}
      />
    </div>
  );
};

export default memo(UploadHistoryTab);
