import { call, fork, put, take, delay } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'api/api';
import { apiLogin } from 'api/auth';
import { router } from 'constant/router';
import history from 'history/history';
import tokenService from 'services/auth.service';
import { authActions, LoginPayLoad } from './authSlice';
import { locale } from 'devextreme/localization';

function* handleLogin(payload: any) {
  const { dataLogin } = payload;
  try {
    const result = yield apiLogin(
      dataLogin.username,
      dataLogin.password,
      locale(),
    );
    if (result?.data) {
      const status = result.data.status ? result.data.status : 1;
      const user = {
        name: dataLogin.username,
        type: status,
      };
      tokenService.updateLocalAccessToken(result.data.access_token);
      tokenService.updateLocalCurrentUser(user);

      yield put(authActions.loginSuccess(user));
      payload.loginSuccess();
    } else {
      yield put(authActions.loginFailed(result.messageError));
    }
  } catch (error) {
    yield put(authActions.loginFailed(''));
  }
}
export function* handleLogout() {
  try {
    yield call(logout, {});
    yield put(authActions.logoutSuccess());
    history.push(router.login);
    tokenService.removeToken();
    tokenService.removeLocalCurrentUser();
    sessionStorage.clear();
  } catch (error: any) {
    yield put(authActions.logoutSuccess());
    history.push(router.login);
    tokenService.removeToken();
    tokenService.removeLocalCurrentUser();
    sessionStorage.clear();
  }
}
function* watchLoginFlow() {
  while (true) {
    const isLoggedIn = Boolean(tokenService.getLocalAccessToken());
    if (!isLoggedIn) {
      const action: PayloadAction<LoginPayLoad> = yield take(
        authActions.login.type,
      );
      yield call(handleLogin, action.payload);
    } else {
      yield take(authActions.logout.type);
      yield call(handleLogout);
    }
  }
}

export function* authSaga() {
  yield fork(watchLoginFlow);
}
