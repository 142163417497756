/**
 * Update date: 09-06-2023
 * Input file image component
 */
import { Button } from 'devextreme-react';
import React, { useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import './styles.scss';
import { notification } from 'utils/notification';
import PopupPreviewImg from '../PopupCommon/PopupPreviewImg';
interface IInputFileImage {
  t: any;
  value?: string;
  onChangeValue?: any;
  alignItems?: string;
  maxWidth?: string | number;
  maxHeight?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  id?: string;
  accept?: string;
  allowedFile?: any[];
  isPrevew?: boolean;
  isShowOrigin?: boolean; // show Origin width height image input
  maxMb?: number; // max MB upload
}
const allowedFileExtensions = ['.jpeg', '.png', '.jpg'];

const InputFileImage = ({
  t,
  value,
  onChangeValue,
  maxWidth = '100%',
  maxHeight = '100%',
  minWidth = '100%',
  minHeight = '100%',
  id = 'dropzone-external',
  accept = '.jpeg, .png, .jpg',
  allowedFile = allowedFileExtensions,
  isPrevew = false,
  isShowOrigin = false,
  maxMb = 5,
}: IInputFileImage) => {
  const [imageSource, setImageSource] = useState(value);
  const [popupPreview, setPopupPreview] = useState<any>(false);

  const onClearImage = () => {
    setImageSource('');
    onChangeValue(null);
  };

  /**
   * on value changed
   *
   * @param {*} e
   * @return {*}
   */
  const onValueChanged = (e: any) => {
    const fileUpload = e?.value?.[0];
    const fileName = fileUpload?.name;
    if (!fileUpload || !fileName) return;
    const extentionFile = fileName?.split('.')?.pop();
    const isAllowed = allowedFileExtensions.some(
      ext => ext.toLocaleLowerCase() === `.${extentionFile || ''}`,
    );
    if (!isAllowed) {
      return notification({
        type: 'error',
        message: t('Input file is not in the correct image format.'),
        displayTime: 4000,
      });
    }
    const maxSize = maxMb * 1024 * 1024;
    if (fileUpload.size > maxSize) {
      return notification({
        type: 'error',
        message: t(`File must be less than ${maxMb}MB!.`),
      });
    }

    setImageSource(URL.createObjectURL(e.value[0]));
    onChangeValue(e.value[0]);
  };

  return (
    <div className="InputFileImage">
      <div
        id={id}
        style={{
          width: '100%',
          height: '100%',
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          minWidth: minWidth,
          minHeight: minHeight,
          backgroundColor: 'rgba(183, 183, 183, 0.1)',
          borderWidth: 2,
          borderStyle: 'dashed',
          padding: 10,
          position: 'relative',
          borderRadius: 5,
        }}
        className={`flex-box custome-view-image dx-theme-border-color`}
      >
        {imageSource ? (
          <img
            className={isShowOrigin ? '' : 'image-view'}
            src={imageSource}
            alt=""
            style={{
              position: 'absolute',
              maxWidth: '100%',
              maxHeight: '100%',
              minWidth: isShowOrigin ? 'auto' : minWidth,
              minHeight: isShowOrigin ? 'auto' : minHeight,
              borderRadius: 5,
            }}
          />
        ) : (
          <div className="no-image">
            <span>{t('No image')}</span>
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <FileUploader
          className="dropzone-external-upload"
          dialogTrigger={`#${id}`}
          dropZone={`#${id}`}
          multiple={false}
          allowedFileExtensions={allowedFile}
          accept={accept || '.jpeg, .png, .jpg'}
          uploadMode="useButtons"
          visible={true}
          onValueChanged={onValueChanged}
          labelText=""
          selectButtonText={t('File')}
          maxFileSize={maxMb * 1024 * 1024}
          width={90}
        />
        <Button text={t('Clear')} onClick={onClearImage} />
        {isPrevew && imageSource && (
          <Button
            text={t('Preview')}
            onClick={() => setPopupPreview(true)}
            className={'btn-preview'}
          />
        )}
      </div>
      {isPrevew && popupPreview && (
        <PopupPreviewImg
          visible={true}
          url={imageSource || ''}
          onHiding={() => setPopupPreview(false)}
          isOrigin={true}
        />
      )}
    </div>
  );
};

export default InputFileImage;
