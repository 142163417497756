/**
 * Update date: 15-05-2023
 * Screen 1043.3
 */
import { DataGridFull } from 'app/components/DataGrid';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useAxios from 'axios-hooks';
import { Button, Popup, ScrollView, Switch } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';
import useFormat from 'hooks/useFormat';
import React, { useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import useDictionary from 'hooks/useDictionary';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';
import { iconExcel } from 'images';

function ViewLocation(props) {
  const { themePro }: any = useApp();
  const { id, closeView } = props;
  const dataRef: any = useRef(null);
  const [fileName, setFileName] = useState('');
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [selectedSwitch, setSelectedSwitch] = useState<any>({
    cell: null,
    value: false,
  });
  const { IntegerFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '1043.3',
  });

  const [{ data, loading }, refetch] = useAxios(
    {
      url: `/core/zone-info/${id}`,
      method: 'GET',
    },
    { manual: true },
  );

  const [{ loading: loadingLocation }, refetchUpdate] = useAxios(
    {
      url: `/core/zone-info`,
      method: 'PUT',
    },
    { manual: true },
  );
  const store: any = new ArrayStore({
    data: data?.data?.locationResponse,
    key: 'locationId',
  });

  /**
   * render switch
   *
   * @param {*} cell
   * @return {*}
   */
  const switchActiveState = cell => {
    let handleActiveState = e => handleActiveStates(cell, e);
    return (
      <Switch
        defaultValue={cell.value}
        onValueChanged={handleActiveState}
        readOnly={cell.value}
      />
    );
  };
  /**
   * on active state
   *
   * @param {*} cell
   * @param {*} e
   */
  const handleActiveStates = (cell, e) => {
    setSelectedSwitch({
      cell,
      value: e?.value,
    });
    if (!e?.previousValue) {
      setShowDialogConfirm(true);
    } else {
      cell.setValue(e.value ? true : false);
    }
  };
  /**
   * on confirm dialog
   *
   */
  const handleConfirmDialog = async () => {
    store?._array?.forEach(e => {
      store.update(e?.locationId, { locationDefaultTf: false });
    });
    selectedSwitch?.cell?.setValue(true);
    const locationRequest = store?._array?.map(o => ({
      ...o,
      locationCdOld: o?.locationCd,
    }));
    const dataReq = {
      ...data?.data,
      locationRequest,
    };
    delete dataReq.locationResponse;
    const res = await refetchUpdate({
      data: dataReq,
    });
    notification({
      res,
    });
    if (res?.data?.status !== '201') {
      refetch();
    }
    setShowDialogConfirm(false);
    setSelectedSwitch({
      data: null,
      value: false,
    });
  };
  /**
   * on close confirm
   *
   */
  const handleCloseConfirm = () => {
    setShowDialogConfirm(false);
    setSelectedSwitch({
      data: null,
      value: false,
    });
  };
  /**
   * on close
   *
   */
  const handleClose = () => {
    closeView();
  };
  const columns: any = [
    {
      dataField: 'floorCd',
      caption: t('Floor'),
      alignment: 'center',
      allowEditing: false,
      width: 90,
    },
    {
      dataField: 'lineCd',
      caption: t('Line'),
      alignment: 'center',
      allowEditing: false,
      width: 90,
    },
    {
      dataField: 'rowCd',
      caption: t('Row'),
      alignment: 'center',
      allowEditing: false,
      width: 90,
    },
    {
      dataField: 'columnCd',
      caption: t('Column'),
      alignment: 'center',
      allowEditing: false,
      width: 90,
    },
    {
      dataField: 'cellCd',
      caption: t('Cell'),
      dataType: 'number',
      alignment: 'center',
      format: IntegerFormat,
      allowEditing: false,
      width: 80,
    },
    {
      dataField: 'locationCd',
      caption: t('Location'),
      allowEditing: false,
    },
    {
      dataField: 'locationNm',
      caption: t('Name'),
      allowEditing: false,
    },
    {
      dataField: 'locationRouteOrder',
      caption: t('Route Order'),
      allowEditing: false,
      dataType: 'number',
      format: IntegerFormat,
    },
    {
      dataField: 'locationLoadTpNm',
      caption: t('Load Type'),
      allowEditing: false,
    },
    {
      dataField: 'locationOperation',
      caption: t('Operation'),
      allowEditing: false,
    },
    {
      dataField: 'locationPriority',
      caption: t('Priority'),
      dataType: 'number',
      format: IntegerFormat,
      allowEditing: false,
    },
    {
      dataField: 'locationDistributionPeriodDm',
      caption: t('Distribution Period'),
      dataType: 'date',
      allowEditing: false,
    },
    {
      dataField: 'locationDefaultTf',
      caption: t('Default'),
      editorType: 'dxSwitch',
      showEditorAlways: true,
      editCellRender: switchActiveState,
    },
    {
      dataField: 'locationNt',
      caption: t('Note'),
      allowEditing: false,
    },
  ];

  const exportExcel = () => {
    dataRef?.current?.instance?.exportToExcel();
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id]);
  useEffect(() => {
    if (data?.data) {
      setFileName('SMDC_1043.3 ' + data?.data?.zoneNm + ' - Location');
    }
  }, [data, loading]);

  const loadingAll = loading || loadingLocation;
  return (
    <Popup
      visible={true}
      dragEnabled={false}
      showCloseButton={true}
      showTitle={true}
      title={t('Location')}
      maxHeight={'100vh'}
      height={themePro ? '100vh' : '85vh'}
      maxWidth={'90vw'}
      onHidden={handleClose}
      position="center"
      className="modal-content-popup"
    >
      <ScrollView width="100%" height={themePro ? '100%' : 'calc(100% - 40px)'}>
        <LoadPanel visible={loadingAll} />
        {themePro && (
          <div className="modal-popup-header">
            <span className="title-page">{data?.data?.zoneNm}</span>
            <div>
              <Button
                stylingMode="contained"
                text={t('Cancel')}
                icon="close"
                onClick={() => handleClose()}
              />
            </div>
          </div>
        )}
        <DataGridFull
          dataSource={store}
          columns={columns}
          ref={dataRef}
          options={{
            columnAutoWidth: true,
            onToolbarPreparing: (e: any) => {
              e.toolbarOptions.items.unshift(
                {
                  location: 'before',
                  template: 'totalCount',
                },
                {
                  location: 'before',
                  widget: 'dxButton',
                  options: {
                    icon: themePro ? iconExcel : 'xlsxfile',
                    text: t('Export Excel'),
                    onClick: () => exportExcel(),
                  },
                },
              );
            },
            groupPanel: {
              visible: true,
            },
            export: {
              fileName: fileName,
            },
            headerFilter: {
              visible: false,
              allowSearch: false,
            },
            // filterRow: {
            //   visible: false,
            // },
            columnChooser: {
              enabled: false,
            },
            filterPanel: {
              visible: false,
            },
            height: themePro ? 'calc(100vh - 200px)' : 'calc(100vh - 280px)',
            editing: {
              mode: 'cell',
              allowUpdating: true,
              startEditAction: 'click',
              selectTextOnEditStart: true,
              refreshMode: 'reshape',
            },
          }}
        />
      </ScrollView>
      {!themePro && (
        <div style={{ textAlign: 'center' }}>
          <Button
            text={t('Close')}
            icon="close"
            onClick={() => handleClose()}
          />
        </div>
      )}
      {showDialogConfirm && (
        <PopupConfirm
          visible={showDialogConfirm}
          content={t('Are you sure you want set default location')}
          onOk={handleConfirmDialog}
          onHidingCallback={handleCloseConfirm}
        />
      )}
    </Popup>
  );
}

export default ViewLocation;
