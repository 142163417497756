/**
 * Update date: 24-05-2023
 * Screen 2041.4
 */
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import PopupSelectZone from 'app/components/PopupCommon/PopupSelectZone';
import LoadingWait from './LoadingWait';
import LoadingComplete from './LoadingComplete';
import { useApp } from 'app';
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'ModuleContext';

export const useIncomingLoadreg = () => {
  const context = useContext(ModuleContext);
  return context;
};

function IncomingLoadReg() {
  const { themePro }: any = useApp();
  const refWait = useRef(null);
  const refComplete = useRef(null);
  const [tabPanelIndex, setTabPanelIndex] = useState<number>(0);
  const [params, setParams] = useState({
    zone: '',
    zoneData: {
      zoneName: '',
      zoneId: null,
      locationId: null,
      locationName: '',
    },
  });
  const [listWait, setListWait] = useState([]);
  const [listComplete, setListComplete] = useState([]);
  const [dataLoading, setDataLoading] = useState({});
  const [{ loading: loadingWait, data: dataWait }, fetchLoadingWait] = useAxios(
    {
      url: 'ecommerce/load-rec-pda',
      method: 'POST',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  const [showPopupByType, setShowPopupByType] = useState('');
  const storeWait: any = new ArrayStore({
    data: listWait,
    key: 'ID',
  });
  const storeComplete: any = new ArrayStore({
    data: listComplete,
    key: 'ID',
  });
  const [modeView, setModeView] = useState({
    type: 'wait',
  });
  const { t } = useDictionary({ programId: '2041.4' });

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'zone':
        return (
          <PopupSelectZone
            text={params?.zone}
            mode="single"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateZone(o)}
          />
        );
      default:
        return null;
    }
  };
  /**
   * on update zone
   *
   * @param {*} data
   */
  const updateZone = data => {
    setParams({
      zone: '',
      zoneData: {
        zoneName: data?.zoneName,
        zoneId: data?.zoneId,
        locationId: data?.locationId,
        locationName: data?.locationName,
      },
    });
    setShowPopupByType('');
  };
  /**
   * on remove zone
   *
   */
  const removeZone = () => {
    setParams({
      ...params,
      zoneData: {
        zoneName: '',
        zoneId: null,
        locationId: null,
        locationName: '',
      },
    });
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (!loadingWait && dataWait) {
      setListWait(
        dataWait?.data.map((o, i) => ({
          ...o,
          ID: i + 1,
        })),
      );
      setListComplete([]);
    }
  }, [loadingWait, dataWait]);
  useEffect(() => {
    if (modeView?.type) {
      setTabPanelIndex(modeView?.type === 'complete' ? 1 : 0);
    }
  }, [modeView?.type]);
  const value: any = {
    storeWait,
    storeComplete,
    setModeView,
    modeView,
    params,
    setParams,
    fetchLoadingWait,
    refComplete,
    refWait,
    dataLoading,
    setDataLoading,
    setListComplete,
  };
  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingWait} />
        <Form
          formData={params}
          showColonAfterLabel={false}
          className="body-padding-white"
          items={[
            {
              label: { text: t('Zone') },
              dataField: 'zone',
              editorType: 'dxTextBox',
              isRequired: true,
              editorOptions: {
                onEnterKey: () => setShowPopupByType('zone'),
                buttons: [
                  {
                    name: 'data1',
                    location: 'before',
                    options: {
                      icon: 'close',
                      text: params?.zoneData?.zoneName,
                      onClick: () => removeZone(),
                      visible: !!params?.zoneData?.zoneId,
                      rtlEnabled: true,
                    },
                  },
                  {
                    name: 'search',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'search',
                      onClick: () => setShowPopupByType('zone'),
                    },
                  },
                ],
              },
            },
          ]}
          labelLocation="top"
          colCount={3}
        ></Form>
        <br />
        <TabPanel
          swipeEnabled={false}
          defaultSelectedIndex={tabPanelIndex}
          selectedIndex={tabPanelIndex}
          onOptionChanged={(e: any) => {
            if (e?.name === 'selectedIndex') {
              setTabPanelIndex(e?.value);
            }
          }}
          className="theme-premiun-dx-multiview-wrapper-0"
        >
          <TabItem title={t('Loading Wait')}>
            <div style={themePro ? {} : { padding: '20px' }}>
              {tabPanelIndex === 0 && <LoadingWait ref={refWait} />}
            </div>
          </TabItem>
          <TabItem title={t('Loading Complete')}>
            <div style={themePro ? {} : { padding: '20px' }}>
              {tabPanelIndex === 1 && <LoadingComplete ref={refComplete} />}
            </div>
          </TabItem>
        </TabPanel>
        {getPopupByType(showPopupByType)}
      </React.Suspense>
    </ModuleContext.Provider>
  );
}

export default IncomingLoadReg;
