/**
 * Update date: 29-05-2023
 * Screen 2045.1
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';

const ViewDetail = React.lazy(() => import('./ViewDetail'));
const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ECSDataSendContext = createContext({
  emptyBottleDepositListData: [],
});

ECSDataSendContext.displayName = 'ECSDataSendContext';

export const useEcsDataSend = () => {
  const context = useContext(ECSDataSendContext);
  return context;
};

export default function ECSDataSend() {
  const { t }: any = useDictionary({ programId: '2045.1' });
  const [modeView, setModeView] = useState({
    type: 'list',
    id: '',
  });
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    ecsBill: '',
    dataList: {
      zone: [],
    },
    zone: '',
  });
  const [formCreateData, setCreateFormData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    dataList: {
      store: [],
      storeGroup: [],
      receivableManagement: [],
      shippingMethod: [],
      zone: [],
      allocation: [],
    },
    store: '',
    storeGroup: '',
    receivableManagement: '',
    shippingMethod: '',
    zone: '',
    allocation: '',
  });

  // Get data
  const [{ data: dataList, loading: loadingList }, refetchData] = useAxios(
    {
      url: 'ecommerce/ecs-send-plan',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const [{ data: dataSearch, loading: loadingSearch }, refetchCreateSearch] =
    useAxios(
      {
        url: 'ecommerce/ecs-send-plan/get-data-create',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );
  const [{ loading: loadingCreate }, refetchCreate] = useAxios(
    {
      url: 'ecommerce/ecs-send-plan',
      method: 'PUT',
    },
    { manual: true, autoCancel: true },
  );
  const [{ loading: loadingDelete }, refetchDelete] = useAxios(
    {
      url: 'ecommerce/ecs-send-plan',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );
  const [{ data: dataView, loading: loadingView }, refetchView] = useAxios(
    {
      url: 'ecommerce/ecs-send-plan',
      method: 'GET',
    },
    { manual: true, autoCancel: true },
  );

  const store: any = new ArrayStore({
    data: dataList?.data || [],
    key: 'ecs_bill',
  });

  const storeCreate: any = new ArrayStore({
    data: dataSearch?.data || [],
    key: 'way_bill',
  });
  const storeView: any = new ArrayStore({
    data: dataView?.data || [],
  });

  useEffect(() => {
    if (modeView?.type === 'create') {
      if (dataSearch) {
        dataSearch.data = [];
      }
      storeCreate._array = [];
    } else if (modeView?.type === 'view') {
      if (dataView) {
        dataView.data = [];
      }
      storeView._array = [];
    }
  }, [modeView?.type]);

  /**
   * on search
   *
   */
  const onSearch = () => {
    const searchParams = {
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
      ecsBill: formData?.ecsBill,
      zoneIds: formData?.dataList?.zone?.map((o: any) => o.zoneId) || [],
    };
    refetchData({
      data: {
        ...searchParams,
      },
    });
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
        return <Create />;
      case 'view':
        return <ViewDetail />;
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    modeView,
    setModeView,
    store,
    refetchData,
    t,
    refetchCreateSearch,
    refetchCreate,
    storeCreate,
    refetchDelete,
    storeView,
    refetchView,
    formData,
    setFormData,
    onSearch,
    formCreateData,
    setCreateFormData,
  };

  const loadingAll =
    loadingList ||
    loadingSearch ||
    loadingCreate ||
    loadingDelete ||
    loadingView;

  return (
    <ECSDataSendContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ECSDataSendContext.Provider>
  );
}
