/**
 * Update date: 2024-07-01
 * Screen 2024.5
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { notification } from 'utils/notification';

// const FormTemplate = React.lazy(() => import('./Form'));
const ListTemplate = React.lazy(() => import('./List'));
const FormTemplate = React.lazy(() => import('./Form'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2044_5';

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2044_5 = () => {
  const { t }: any = useDictionary({ programId: '2024.5' });
  const [volist, setVolist] = useState<any>([]);
  const [searchParams, setSearchParams] = useState<any>({
    orderFormName: '',
  });
  const [listTemplate, setListTemplate] = useState<any>([]);
  const [modeView, setModeView] = useState<any>({
    type: 'list',
  });

  const [{ loading }, refetchDataVO] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
      data: { codeType: 'VO' },
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: listTemplate,
    key: ['vendorId', 'seqNo'],
  });

  useEffect(() => {
    const getCommonCodeVO = async () => {
      const res = await refetchDataVO();
      if (res?.data?.status === '200') {
        setVolist(res?.data?.data || []);
      } else {
        notification({ res });
      }
    };
    getCommonCodeVO();
  }, []);

  const value: any = {
    t,
    modeView,
    setModeView,
    searchParams,
    setSearchParams,
    store,
    setListTemplate,
    volist,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListTemplate />;
      case 'create':
      case 'edit':
        return <FormTemplate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        {renderContent()}
        <LoadPanel visible={loading} />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2044_5;
