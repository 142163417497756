/**
 * Update date: 18-05-2023
 * Screen 2012.4
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { useProductType } from 'hooks/useProductType';

const Form = React.lazy(() => import('./Form'));
const List = React.lazy(() => import('./List'));

/**
 * create context
 */
export const ModuleContext = createContext({
  modeView: '',
  setModeView: any => {},
  mainSearchParams: {},
  setMainSearchParams: any => {},
  listEvents: [],
  apiSearchEvents: any => {},
});

ModuleContext.displayName = 'RegistrationRecomentdedProductsContext';

export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  return context;
};

const RegistrationRecomentdedProducts = () => {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [mainSearchParams, setMainSearchParams] = useState({
    subject: '',
    use: 1,
  });
  const [listEvents, setListEvents] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [
    { data: resApiSearchEvents, loading: loadingApiSearchEvents },
    apiSearchEvents,
  ] = useAxios(
    {
      url: 'product/recommended-products-by-event',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    productTpPermission,
    modeView,
    setModeView,
    mainSearchParams,
    setMainSearchParams,
    listEvents,
    setListEvents,
    apiSearchEvents,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'edit':
      case 'create':
        return <Form />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiSearchEvents?.data) {
      setListEvents(
        resApiSearchEvents?.data
          ?.sort((a, b) => b?.bundleAnniversaryId - a?.bundleAnniversaryId)
          ?.map((o, i) => ({
            ...o,
            ID: i + 1,
          })),
      );
    }
  }, [resApiSearchEvents]);

  const loadingAll = loadingApiSearchEvents || loadingProductTpPermission;

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default RegistrationRecomentdedProducts;
