/**
 * Create date: 2024-09-09
 * Update date: 2024-09-09
 * Screen 1041.2
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import useDictionary from 'hooks/useDictionary';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { notification } from 'utils/notification';
import FormCreate from './FormCreate';
import TreeViewList from './TreeViewList';

export const dataBookMark = {
  notGroup: 'NotInBookmarkGroup',
  isGroup: 'BookmarkGroup',
};
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M1041_2_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M1041_2 = () => {
  const { t }: any = useDictionary({ programId: '1041.2' });
  const treeViewProgramRef: any = useRef(null);
  const formRef: any = useRef(null);
  const [expanded, setExpanded] = useState<any>([
    dataBookMark.notGroup,
    dataBookMark.isGroup,
  ]);
  const [treeList, setTreeList] = useState<any>([]);
  const [selected, setSelected] = useState<any>({
    type: '',
    data: null,
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  useEffect(() => {
    const getDataBookMark = async () => {
      const res = await refetchData({
        url: '/user/m1041_2',
        method: 'GET',
      });
      if (res?.data?.status === '200') {
        mapDataToTree(res?.data?.data);
      } else {
        notification({ res });
      }
    };
    getDataBookMark();
  }, []);

  const mapDataToTree = dataTreeList => {
    const { bookmark, bookmarkMst, bookmarkDet } = dataTreeList || {};
    const dataTree = [
      {
        id: dataBookMark.notGroup,
        programId: dataBookMark.notGroup,
        programName: 'Bookmark (Programs Not In Bookmark Group)',
        parentId: null,
        level: 0,
        sortNo: 0,
        expanded: true,
      },
      {
        id: dataBookMark.isGroup,
        programId: dataBookMark.isGroup,
        programName: 'Bookmark Group',
        parentId: null,
        level: 0,
        sortNo: 1,
        expanded: true,
      },
    ];
    (bookmark || []).forEach((o, idx) =>
      dataTree.push({
        ...o,
        id: o?.programId,
        parentId: dataBookMark.notGroup,
        level: 1,
        sortNo: idx,
      }),
    );
    (bookmarkMst || []).forEach(o =>
      dataTree.push({
        ...o,
        id: o?.programId,
        parentId: dataBookMark.isGroup,
      }),
    );
    (bookmarkDet || []).forEach(o =>
      dataTree.push({
        ...o,
        id: o?.programId,
      }),
    );
    dataTree.forEach((o, idx) => {
      if (expanded.includes(o?.id)) {
        o.expanded = true;
      }
    });
    setTreeList(dataTree);
  };

  const value: any = {
    t,
    treeList,
    setTreeList,
    selected,
    setSelected,
    mapDataToTree,
    setExpanded,
  };
  return (
    <>
      <ModuleContext.Provider value={value}>
        <LoadPanel visible={loading} />
        <div style={{ display: 'flex', gap: 20 }}>
          <TreeViewList ref={treeViewProgramRef} />
          <FormCreate ref={formRef} />
        </div>
      </ModuleContext.Provider>
    </>
  );
};

export default M1041_2;
