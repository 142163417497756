/**
 * Update date: 31-05-2023
 * Screen 2051.2
 */
import React, { createContext, useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import FormSearch from './FormSearch';
import ListInventory from './ListInvetory';
import ArrayStore from 'devextreme/data/array_store';
import LoadPanel from 'app/components/LoadPanel';
import { useProductType } from 'hooks/useProductType';
const defaultParamsInstock = {
  largeCategory: '',
  subCategory: '',
  mediumCategory: '',
  smallCategory: '',
  product: '',
  productGroup: '',
  inStock: '',
  productType: '',
};
/**
 * create context
 */
const InventoryReceivingContext = createContext({
  refetchData: () => {},
});

InventoryReceivingContext.displayName = 'InventoryReceivingContext';

export const useInventoryReceiving = () => {
  const context = useContext(InventoryReceivingContext);
  return context;
};

function InventoryReceivingAndForwarding(props) {
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [params, setParams] = useState({
    ...defaultParamsInstock,
    startDuration: new Date(),
    endDuration: new Date(),
    formStore: {
      largeCategory: [],
      subCategory: [],
      mediumCategory: [],
      smallCategory: [],
      product: [],
      productGroup: [],
      productType: [],
    },
  });
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: '/warehouse/instock/transport',
      method: 'POST',
    },
    { manual: true, useCache: false },
  );
  const store: any = new ArrayStore({
    data: data?.data?.map((o, i) => ({
      ...o,
      id: i,
    })),
    key: 'id',
  });
  /**
   * fetch data
   */
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      formStore: {
        ...preState?.formStore,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  const value: any = {
    store,
    params,
    setParams,
    refetchData,
  };
  const loadingAll = loading || loadingProductTpPermission;
  return (
    <InventoryReceivingContext.Provider value={value}>
      <LoadPanel visible={loadingAll} />
      <FormSearch />
      <ListInventory />
    </InventoryReceivingContext.Provider>
  );
}

export default InventoryReceivingAndForwarding;
