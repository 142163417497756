/**
 * Update date: 23-05-2023
 * Screen 2023.2
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import React, { forwardRef, useState } from 'react';
import { useOrderInquiry } from '..';
import FormSearch from '../FormSearch';
import SendMail from '../SendMail';
import moment from 'moment';
import { notification } from 'utils/notification';
import printJS from 'print-js';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
function ListOrder(props, ref) {
  const context: any = useOrderInquiry();
  const { store } = context || {};
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const [showModalSendMail, setShowModalSendMail] = useState(false);
  const [toMail, setToMail] = useState<string>('');
  const [billId, setBillId] = useState<string>('');
  const [orderId, setOrderId] = useState<number>(0);

  const { QtyFormat, AmountFormat, IntegerFormat, DoubleFormat } = useFormat();
  const { t }: any = useDictionary({
    programId: '2023.2',
  });
  /**
   * render status
   *
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    return (
      <div>
        {e?.value === '0' ? 'Pending' : e?.value === '1' ? 'Fixed' : ''}
      </div>
    );
  };

  const [{ loading: loadingPdfOrder }, executeFetchPdfOrder] = useAxios(
    {
      url: `/warehouse/order-registration/report`,
      method: 'POST',
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on send mail
   *
   * @param {*} billId
   */
  const onSendMail = billId => {
    setBillId(billId);

    const orderId = store?._array
      ?.filter(o => o?.ctOrderBill === billId)
      ?.map(o => o?.ctOrderId)[0];
    setOrderId(orderId);

    const vendorMailChoose = store?._array
      ?.filter(o => o?.ctOrderId === orderId)
      ?.map(o => o?.vendorEmail)[0];

    setToMail(vendorMailChoose);

    setShowModalSendMail(true);
  };

  /**
   * on export excel
   *
   */
  const onExportExcel = () => {
    ref?.current?.instance?.exportToExcel();
  };

  const exportFileName = `SMDC_20232 Inquiry and outputtransmission of order details_${moment().format(
    'YYYYMMDD',
  )}`;

  /**
   * on print order
   *
   * @param {*} billId
   */
  const onPrintOrder = async billId => {
    try {
      const orderId = store?._array
        ?.filter(o => o?.ctOrderBill === billId)
        ?.map(o => o?.ctOrderId)[0];

      const res = await executeFetchPdfOrder({
        data: [orderId],
      });
      const arrayData: any = new Uint8Array(res.data);
      const blob = new Blob([arrayData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      printJS(url);
    } catch (e) {
      notification({ type: 'error', message: t('Get Order Bill Fail') });
    }
  };

  const columns: any = [
    {
      dataField: 'ctOrderBill',
      caption: t('Bill ID'),
      alignment: 'left',
      fixed: true,
    },
    {
      dataField: 'ctOrderDt',
      caption: t('Date'),
      dataType: 'date',
      format: DateFormat,
      alignment: 'left',
    },
    {
      dataField: 'vendorName',
      caption: t('Vendor'),
      alignment: 'left',
    },
    {
      dataField: 'ctOrderStateName',
      caption: t('State'),
      alignment: 'left',
    },
    {
      dataField: 'ctOrderStatus',
      caption: t('Status'),
      cellRender: renderStatus,
      alignment: 'left',
    },
    {
      dataField: 'locationNm',
      caption: t('Location'),
      alignment: 'left',
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      alignment: 'left',
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      alignment: 'left',
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
    },
    {
      dataField: 'optionCode',
      caption: t('Option Code'),
      visible: false,
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
      alignment: 'left',
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      showWhenGrouped: true,
      format: QtyFormat,
      dataType: 'number',
    },
    {
      dataField: 'purchaseUnitPrice',
      caption: t('Purchase Unit Price'),
      format: DoubleFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderQuantity',
      caption: t('Qty'),
      format: QtyFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderEa',
      caption: t('EA'),
      format: QtyFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderAmount',
      caption: t('Amount'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'supplyAmount',
      caption: t('Supply Amount'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'vatAmount',
      caption: t('VAT'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'bottleAmount',
      caption: t('Bottle'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'containerAmount',
      caption: t('Container'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'totalAmount',
      caption: t('G.Total'),
      format: AmountFormat,
      dataType: 'number',
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderMstNote',
      caption: t('Bill Note'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'ctOrderDetNote',
      caption: t('Line Note'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'bottleId',
      caption: t('Bottle ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'bottleUnitPrice',
      caption: t('Bottle Unit Price'),
      format: AmountFormat,
      dataType: 'number',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'containerId',
      caption: t('Container ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'containerUnitPrice',
      caption: t('Container Unit Price'),
      format: AmountFormat,
      dataType: 'number',
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'orderErNm',
      caption: t('Orderer'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'currency',
      caption: t('Currency'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'maker',
      caption: t('Maker'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'origin',
      caption: t('Origin'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'vendorDefault',
      caption: t('Default Vendor'),
      visible: false,
      showWhenGrouped: true,
    },

    {
      dataField: 'largeCategory',
      caption: t('Large category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'mediumCategory',
      caption: t('Medium category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'smallCategory',
      caption: t('Small category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'subCategory',
      caption: t('Sub category'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'createdDate',
      caption: t('Register Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'registerUser',
      caption: t('Register User'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'registerProgramCd',
      caption: t('Register Program ID'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifiedDate',
      caption: t('Modify Date&Time'),
      dataType: 'date',
      format: DateTimeFormat,
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifyUser',
      caption: t('Modify User'),
      visible: false,
      showWhenGrouped: true,
    },
    {
      dataField: 'modifyProgramCd',
      caption: t('Modify Program ID'),
      visible: false,
      showWhenGrouped: true,
    },
  ];

  /**
   * render content
   */
  return (
    <div>
      <LoadPanel visible={loadingPdfOrder} />
      <FormSearch />
      <DataGridFull
        ref={ref}
        dataSource={store}
        columns={columns}
        storageKey="2023.2"
        options={{
          key: 'lineId',
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          onContextMenuPreparing: (e: any) => {
            if (e.target === 'headerPanel') {
              if (!e.items) e.items = [];
              if (ref?.current?.instance?.option()?.groupPanel?.visible) {
                e.items.push(
                  {
                    text: t('Expand All'),
                    onItemClick: () => {
                      e.component.expandAll();
                    },
                  },
                  {
                    text: t('Collapse All'),
                    onItemClick: () => {
                      e.component.collapseAll();
                    },
                  },
                );
              }
            }
            if (e.target === 'content') {
              if (!e.items) e.items = [];
              if (ref?.current?.instance?.option()?.groupPanel?.visible) {
                if (e?.column?.dataField !== 'ctOrderBill') return;

                if (e?.row?.rowType !== 'group') return;

                e.items.push({
                  text: t('Send Mail'),
                  icon: 'email',
                  onItemClick: () => {
                    const billId = e?.row?.data?.key;
                    onSendMail(billId);
                  },
                });

                e.items.push({
                  text: t('Print Order Report'),
                  icon: 'print',
                  onItemClick: () => {
                    const billId = e?.row?.data?.key;
                    onPrintOrder(billId);
                  },
                });
              }
            }
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  onClick: () => onExportExcel(),
                },
              },
            );
          },
          summary: {
            totalItems: [
              {
                name: 'ctOrderQuantity',
                column: 'ctOrderQuantity',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'CtOrderEa',
                column: 'ctOrderEa',
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'CtOrderAmount',
                column: 'ctOrderAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'SupplyAmount',
                column: 'supplyAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'VatAmount',
                column: 'vatAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'Bottle',
                column: 'bottleAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'Container',
                column: 'containerAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
              {
                name: 'GTotal',
                column: 'totalAmount',
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
                summaryType: 'sum',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'ctOrderQuantity',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'ctOrderEa',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: QtyFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'ctOrderAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vatAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'bottleAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'containerAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'totalAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
          export: {
            fileName: exportFileName,
          },
          selection: {
            mode: 'single',
          },
        }}
      />
      {showModalSendMail && (
        <SendMail
          setShowModalSendMail={setShowModalSendMail}
          toMail={toMail}
          billId={billId}
          orderId={orderId}
        />
      )}
    </div>
  );
}

export default forwardRef(ListOrder);
