/**
 * Update date: 08-06-2023
 * Screen 2081.K
 */
import Collapse from 'app/components/Collapse';
import Form from 'devextreme-react/form';
import { useRef, useEffect, useState, useCallback, memo } from 'react';
import { useModuleContext } from '..';
import useFormatTime from 'hooks/useFormatTime';
import DateOptions from 'app/components/DateOptions';

const FormSearch = () => {
  const context: any = useModuleContext();
  const { t, formSearchData, onSubmitSearch, tab } = context;
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const [tabDate, setTabDate] = useState<any>({
    Order: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    Receive: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    Takeout: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    Forward: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    OtherForward: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    ZoneMove: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    Return: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    Disposal: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    EmptyBottleTakeback: {
      fromDate: new Date(),
      enDate: new Date(),
    },
    EmptyBottleTakeout: {
      fromDate: new Date(),
      enDate: new Date(),
    },
  });

  /**
   * fetch data
   */
  useEffect(() => {
    if (!tabDate[tab]) return;
    if (tabDate[tab]?.fromDate) {
      formRef?.current?.instance?.updateData(
        'fromDate',
        tabDate[tab]?.fromDate,
      );
    }
    if (tabDate[tab].enDate) {
      formRef?.current?.instance?.updateData('enDate', tabDate[tab]?.enDate);
    }
  }, [tabDate, tab]);

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (formRef?.current?.instance && !isValid) {
      return;
    }
    setTabDate(e => ({
      ...e,
      [tab]: {
        fromDate: formSearchData.fromDate,
        enDate: formSearchData.enDate,
      },
    }));
    onSubmitSearch();
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, enDateValue) => {
    if (!fromDateValue || !enDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('enDate', enDateValue);
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = useCallback(
    e => {
      switch (e?.dataField) {
        case 'fromDate':
          if (
            formSearchData.enDate &&
            e?.value &&
            e?.value > formSearchData.enDate
          )
            formRef?.current?.instance.updateData('enDate', null);
          break;
        case 'enDate':
          if (
            formSearchData.fromDate &&
            e?.value &&
            e?.value < formSearchData.fromDate
          )
            formRef?.current?.instance.updateData('fromDate', null);
          break;
        default:
          break;
      }
    },
    [formSearchData.enDate, formSearchData.fromDate],
  );

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions onChangeValue={onChangeDate} />
        <Form
          ref={formRef}
          formData={formSearchData}
          showColonAfterLabel={false}
          onFieldDataChanged={onFieldDataChanged}
          items={[
            //FromDate
            {
              label: { text: t('From Date') },
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formSearchData.fromDate && formSearchData.enDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('From Date is required'),
                },
              ],
            },
            //ToDate
            {
              label: { text: t('End Date') },
              dataField: 'enDate',
              editorType: 'dxDateBox',
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formSearchData.enDate && formSearchData.fromDate,
              validationRules: [
                {
                  type: 'required',
                  message: t('To Date is required'),
                },
              ],
            },
          ]}
          labelLocation="top"
          colCount={2}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit} swipeEnabled={false}>
        <Search name={t('Search')} />
      </Collapse>
    </div>
  );
};

export default memo(FormSearch);
