import { round, toNumber, ceil, floor } from 'lodash';

import { formatNumber } from 'devextreme/localization';

export const toNumberAmount = (amount, isDivisionType = false) => {
  const { amountPoint = 0, divisionType = '' } = JSON.parse(
    sessionStorage.getItem('centerInfo') || '{}',
  );
  const amountData = toNumber(amount);
  if (!isDivisionType) {
    return round(amountData, amountPoint);
  }
  let newValue = 0;
  switch (divisionType) {
    case '1':
      newValue = round(amountData * 0.1) * 10;
      break;
    case '2':
      newValue = ceil(amountData * 0.1) * 10;
      break;
    case '3':
      newValue = floor(amountData * 0.1) * 10;
      break;
    case '4':
      newValue = round(amountData * 0.01) * 100;
      break;
    case '5':
      newValue = ceil(amountData * 0.01) * 100;
      break;
    case '6':
      newValue = floor(amountData * 0.01) * 100;
      break;
    case '7':
      newValue = round(amountData * 0.001) * 1000;
      break;
    case '8':
      newValue = ceil(amountData * 0.001) * 1000;
      break;
    case '9':
      newValue = floor(amountData * 0.001) * 1000;
      break;
    default:
      newValue = amountData;
      break;
  }
  return round(newValue, amountPoint);
};

export const toNumberRoundUpDown = (amount, isRoundUp = false) => {
  const { amountPoint = 0 } = JSON.parse(
    sessionStorage.getItem('centerInfo') || '{}',
  );
  let multiRound = 1;
  switch (amountPoint) {
    case 1:
      multiRound = 10;
      break;
    case 2:
      multiRound = 100;
      break;
    case 3:
      multiRound = 1000;
      break;
    case 4:
      multiRound = 10000;
      break;
    case 5:
      multiRound = 100000;
      break;
    default:
      multiRound = 1;
      break;
  }
  const amountData = toNumber(amount);
  if (isRoundUp) {
    return ceil(amountData * multiRound) / multiRound;
  } else {
    return floor(amountData * multiRound) / multiRound;
  }
};

export const toNumberFixed = (amount, fixed: number | null = null) => {
  const { amountPoint = 0 } = JSON.parse(
    sessionStorage.getItem('centerInfo') || '{}',
  );
  const amountData = toNumber(amount);
  if (fixed != null && fixed >= 0) {
    return toNumber(amountData.toFixed(fixed));
  } else {
    return toNumber(amountData.toFixed(amountPoint));
  }
};

export const formatNumberToString = (amount, format: any = null) => {
  if (!format) {
    const { amountPoint = 0 } = JSON.parse(
      sessionStorage.getItem('centerInfo') || '{}',
    );
    format = amountPoint;
  }
  return formatNumber(amount, format);
};
