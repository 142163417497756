/**
 * Update date: 26-05-2023
 * Screen 2043.3
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import ArrayStore from 'devextreme/data/array_store';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const CreateRegularShippingOrdersContext = createContext({});

CreateRegularShippingOrdersContext.displayName =
  'CreateRegularShippingOrdersContext';

export const useCreateRegularShippingOrders = () => {
  return useContext(CreateRegularShippingOrdersContext);
};

const CreateRegularShippingOrders = () => {
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [shippingMethodStored, setShippingMethodStored] = useState('');
  const [formSearchData, setFormSearchData] = useState<any>({
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
    dataList: {
      shippingMethod: [],
      store: [],
    },
    shippingMethod: '',
    store: '',
  });

  const [infoData, setInfoData] = useState({
    dataList: {
      mall: ['AOS_R'],
    },
    mall: '',
    date: new Date(),
    deliveryRequestDm: new Date(),
  });

  const store: any = new ArrayStore({
    data: data,
    key: 'key',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    store,
    setData,
    refetchData,
    formSearchData,
    setFormSearchData,
    shippingMethodStored,
    setShippingMethodStored,
    infoData,
    setInfoData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <CreateRegularShippingOrdersContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </CreateRegularShippingOrdersContext.Provider>
  );
};

export default CreateRegularShippingOrders;
