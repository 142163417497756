/**
 * Update date: 07-06-2023
 * Screen 2081.7
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import React, { useState, useRef, memo } from 'react';
import { useSupplyUnitPriceGroupMarginRateManagement } from '..';
import { Button } from 'devextreme-react';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import { notification } from 'utils/notification';
import LoadPanel from 'app/components/LoadPanel';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { useApp } from 'app';

function ListSupplyUnitPriceGroupMarginRateManagement(props) {
  const { themePro }: any = useApp();

  const context: any = useSupplyUnitPriceGroupMarginRateManagement();
  const { t, store, onCancel } = context || {};
  const [showModalConfirm, setShowModalConfirm] = useState<any>(false);
  const [rowEdited, setRowEdited] = useState<any>(false);
  const { columns, productTypes, shipmentTypes } = props;
  const dataGridRef: any = useRef(null);

  const [{ loading: loadingPutMarginRate }, executePutMarginRate] = useAxios(
    {
      url: `/product/maginRate`,
      method: 'PUT',
    },
    {
      manual: true,
      useCache: false,
      autoCancel: true,
    },
  );

  /**
   * on save
   *
   */
  const onSave = async () => {
    const rowsChanges = store._array.filter(item => item.status === 'edit');
    const dataChanges: any = [];
    rowsChanges.forEach(row => {
      const { supplyUnitPriceGroupCode } = row;
      Object.keys(row).forEach(key => {
        if (!key.startsWith('col_') || key.startsWith('col_S_')) return;

        productTypes.forEach(productType => {
          if (!key.endsWith(`_${productType}`)) return;
          const keySubFull = key.substring(4, key.length);
          const keySub = keySubFull.substring(
            0,
            keySubFull.lastIndexOf(`_${productType}`),
          );
          shipmentTypes.forEach(shipmentType => {
            if (!keySub.endsWith(`_${shipmentType}`)) return;
            dataChanges.push({
              supplyUnitPriceGroup: supplyUnitPriceGroupCode,
              productType,
              shipmentType,
              marginRate: row[key] ? row[key] : 0,
              shippingRate: row?.[`col_S_${keySubFull}`] || 0,
            });
          });
        });
      });
    });
    const res = await executePutMarginRate({
      data: { maginRateList: dataChanges },
    });
    notification({ res });
    if (res?.status == 200) {
      if (res?.data?.status === '201') {
        setShowModalConfirm(false);
        setRowEdited(false);
      }
    }
  };
  /**
   * on cancel
   *
   * @return {*}
   */
  const handleCancel = () => {
    if (!rowEdited) return;
    onCancel();
    setRowEdited(false);
  };

  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowUpdating = e => {
    store.update(e?.key, { status: 'edit' });
    setRowEdited(true);
  };

  return (
    <>
      <LoadPanel visible={loadingPutMarginRate} />
      <BreadCrumbPremium
        onSubmit={() => setShowModalConfirm(!showModalConfirm)}
        onCancel={handleCancel}
      />
      <DataGridFull
        ref={dataGridRef}
        dataSource={store}
        columns={columns}
        options={{
          key: 'supplyUnitPriceGroup',
          columnAutoWidth: true,
          editing: {
            mode: 'cell',
            allowUpdating: true,
            selectTextOnEditStart: true,
          },
          onRowUpdating: onRowUpdating,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift({
              location: 'before',
              template: 'totalCount',
            });
          },
        }}
      />
      {!themePro && (
        <div
          style={{
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
          className="button-wrap-content"
        >
          <div style={{ marginRight: '50px' }}>
            <Button
              text={t('Save')}
              icon="save"
              disabled={!rowEdited}
              onClick={() => setShowModalConfirm(!showModalConfirm)}
            />
          </div>
          <Button text={t('Cancel')} icon="remove" onClick={handleCancel} />
        </div>
      )}
      <PopupConfirm
        visible={showModalConfirm}
        content={t('Are you sure you want to change data ?')}
        onOk={onSave}
        onHiding={() => {
          setShowModalConfirm(!showModalConfirm);
        }}
      />
    </>
  );
}

export default memo(ListSupplyUnitPriceGroupMarginRateManagement);
