import { ALARM_PRIORITY_COLOR } from 'constant/dataConstant';
import { dateFormatStr } from 'utils/format';
interface IItemView {
  data: any;
  t: any;
  cursor?: boolean;
  onShowDetail: () => void;
}

export const ItemView = ({ data, t, cursor, onShowDetail }: IItemView) => {
  const priorityColor =
    ALARM_PRIORITY_COLOR.find(o => o.value == data?.noticePriority)?.color ||
    '#fff';
  return (
    <div
      style={{
        display: 'flex',
      }}
      className={`${cursor && 'cursor-pointer'}`}
      onClick={() => {
        if (!cursor) return;
        onShowDetail?.();
      }}
    >
      <div>
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            background: priorityColor,
            marginRight: 10,
            marginLeft: 5,
            border: '1px solid #e4e7ec',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
          }}
        />
      </div>

      <div style={{ width: '100%' }}>
        <div
          className="item-notification-name"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <span style={{ fontWeight: data?.hasRead ? '400' : '700' }}>
              {data?.noticeSender}
            </span>{' '}
            <span
              className="item-notification-time"
              style={{ fontWeight: data?.hasRead ? '400' : '700' }}
            >
              {data?.noticeDm}
            </span>
          </div>
          <div style={{ fontWeight: '400' }}>
            <span>{data?.readDm ? `${t('Read Time')}: ` : ''}</span>
            <span className="item-notification-time">
              {dateFormatStr(data?.readDm, 'YYYY-MM-DD HH:mm:ss') || ''}
            </span>
          </div>
        </div>
        <div
          className="item-notification-name"
          style={{ fontWeight: data?.hasRead ? '400' : '700' }}
        >
          {data?.noticeTitle}
        </div>
        {data?.noticeContent && (
          <div
            style={{
              padding: '10px',
              backgroundColor: '#f2f4f7',
              borderRadius: '6px',
              marginTop: '3px',
            }}
          >
            <span
              style={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 400,
                color: '#1D2939',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                whiteSpace: 'pre-line',
              }}
            >
              {data?.noticeContent}
            </span>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 5,
          }}
        >
          {data?.noticeLink ? (
            <span
              style={
                data?.noticeLink ? { cursor: 'pointer', color: '#53B1FD' } : {}
              }
              onClick={() => window.open(data?.noticeLink, '_blank')}
            >
              {t('Open Link')}
            </span>
          ) : (
            <span></span>
          )}
          <span
            style={{ cursor: 'pointer', color: '#53B1FD' }}
            onClick={() => onShowDetail?.()}
          >
            {t('Show More')}
          </span>
        </div>
      </div>
    </div>
  );
};
