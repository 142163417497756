/**
 * Update date: 31-05-2023
 * Screen 2048.2
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const ListZoneMove = React.lazy(() => import('./ListZoneMove'));
/**
 * create context
 */
const ModuleContext = createContext({
  searchParams: {},
  listZoneMove: [] || null,
  setSearchParams: () => {},
  onUpdateListZoneMove: () => {},
});

ModuleContext.displayName = 'InquiriesZoneMove';

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const InquiriesZoneMove = () => {
  const [searchParams, setSearchParams] = useState({
    fromDate: new Date(),
    endDate: new Date(),
    zoneIds: [],
    status: '',
    productIds: [],
    largeCategories: [],
    mediumCategories: [],
    smallCategories: [],
    subCategories: [],
    productGroupIds: [],
  });
  const [listZoneMove, setListZoneMove] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [
    { data: resApiListZoneMove, loading: loadingApiListZoneMove },
    apiListZoneMove,
  ] = useAxios(
    {
      url: '/warehouse/zone-move/zone-move-inquiry',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on update list
   *
   * @param {*} record
   */
  const onUpdateListZoneMove = (record: any) => {
    const existItem = listZoneMove?.some(
      (o: any) => o?.zoneMoveId == record?.zoneMoveId,
    );
    let l: any = [];
    if (record?.type === 'delete') {
      l = [...listZoneMove]?.filter(
        (o: any) => o?.zoneMoveId !== record?.zoneMoveId,
      );
    } else {
      if (existItem) {
        l = [...listZoneMove]?.map((o: any) => {
          if (o?.zoneMoveId == record?.zoneMoveId) return record;
          return o;
        });
      } else {
        l = [...listZoneMove];
        l.unshift(record);
      }
    }
    setListZoneMove(l);
  };

  const value: any = {
    setModeView,
    modeView,
    searchParams,
    setSearchParams,
    listZoneMove,
    apiListZoneMove,
    onUpdateListZoneMove,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListZoneMove />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiListZoneMove?.data) {
      setListZoneMove(resApiListZoneMove?.data);
    }
  }, [resApiListZoneMove]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiListZoneMove} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default InquiriesZoneMove;
