import React, { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';

const Create = React.lazy(() => import('./Create'));
const List = React.lazy(() => import('./List'));

// Create context
const ShippingStaff = createContext({
  SendUserStoreData: [],
});
ShippingStaff.displayName = 'ShippingStaff';

export const useShippingStaff = () => {
  const context = useContext(ShippingStaff);
  return context;
};

/**
 * Register Shipping Staff
 *
 * @export
 * @return {*}
 */
export default function RegisterShippingStaff() {
  const [SendUserStoreData, setSendUserStoreData] = useState([]);
  const [formData, setFormData] = useState<any>({
    sendUser: [],
  });
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  // Api get data
  const [{ data: storeGroupRes, loading: productLoading }, refetchData] =
    useAxios(
      {
        url: '/ecommerce/register-shipping',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  // Api delete data
  const [{ loading: productDeleteLoading }, sendUserDelete] = useAxios(
    {
      url: '/ecommerce/register-shipping',
      method: 'DELETE',
    },
    { manual: true, autoCancel: true },
  );

  /**
   * Render Content component
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
      case 'view':
        return <Create />;
      default:
        return <NotFoundPage />;
    }
  };

  /**
   * Update record
   *
   * @param {*} record
   */
  const onUpdateData = record => {
    const existItem = SendUserStoreData?.some(
      (o: any) => o?.sendUserId === record?.sendUserId,
    );
    let l: any = [];
    if (existItem) {
      l = [...SendUserStoreData]?.map((o: any) => {
        if (o?.sendUserId === record?.sendUserId) return record;
        return o;
      });
    } else {
      l = [...SendUserStoreData];
      l.unshift(record);
    }
    setSendUserStoreData(l);
  };

  // Values share with child component
  const value: any = {
    modeView,
    setModeView,
    SendUserStoreData,
    setSendUserStoreData,
    refetchData,
    onUpdateData,
    sendUserDelete,
    formData,
    setFormData,
  };

  // set data to state
  useEffect(() => {
    if (storeGroupRes?.data) {
      setSendUserStoreData(storeGroupRes?.data);
    }
  }, [storeGroupRes]);

  const loadingAll = productLoading || productDeleteLoading;

  return (
    <ShippingStaff.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </ShippingStaff.Provider>
  );
}
