/**
 * Update date: 14-06-2023
 * Popup confirm component
 */
import React, { memo, forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { file } from 'images';

interface IPopupConfirmWithRef {
  onOk?: () => void;
  onHiding?: () => void;
  content?: any;
  isTranslate?: boolean;
}
const PopupConfirmWithRef = (
  { onOk, onHiding, content = '', isTranslate = false }: IPopupConfirmWithRef,
  ref,
) => {
  const { t }: any = useDictionary({});
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [contentShow, setContentShow] = useState(content);

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = (contentShow: any) => {
    if (contentShow) {
      setContentShow(contentShow);
    }
    setShowPopupConfirm(true);
  };

  const onClickYes = () => {
    onOk?.();
    setShowPopupConfirm(false);
  };

  const onClickNo = () => {
    onHiding?.();
    setShowPopupConfirm(false);
  };

  return (
    <Popup
      visible={showPopupConfirm}
      // onHiding={onClickNo}
      showTitle={true}
      title={''}
      width={350}
      height={'auto'}
      className="modal-confirm-popup"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          gap: 10,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <img className="icon" src={file} alt="icon warning" />
        </div>
        <div className="modal-confirm-popup-des">
          {isTranslate ? t(contentShow) : contentShow}
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <Button text={t('No')} onClick={onClickNo} width={'100%'} />
          <Button
            className="button-warning"
            text={t('Yes')}
            onClick={onClickYes}
            width={'100%'}
          />
        </div>
      </div>
    </Popup>
  );
};

export default memo(forwardRef(PopupConfirmWithRef));
