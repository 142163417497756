/**
 * Update date: 23-05-2023
 * Screen 2031.1
 */
import React, { useState, useRef, useEffect } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import useFormatTime from 'hooks/useFormatTime';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { useOrderDetail } from '..';
import moment from 'moment';
import useDictionary from 'hooks/useDictionary';
import { cloneDeep, uniqBy } from 'lodash';
import PopupSelectVendor from 'app/components/PopupCommon/PopupSelectVendor';
interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const FormSearch = () => {
  const { t }: any = useDictionary({ programId: '2031.1' });
  const context: any = useOrderDetail();
  const { refetchData, vendorId, vendorName } = context;
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({
    dateChk: false,
    dueChk: false,
    fromDate: new Date(),
    endDate: new Date(),
    fromDueDate: '',
    endDueDate: '',
    orderType: '0',
    disableVendor: false,
    dataList: {
      orderState: [],
      vendor: [],
    },
    orderState: '',
    vendor: '',
  });
  const [showPopupByType, setShowPopupByType] = useState('');
  /**
   * fetch data
   */
  useEffect(() => {
    if (!vendorId) return;
    setFormData({
      ...formData,
      disableVendor: true,
      dataList: {
        ...formData?.dataList,
        vendor: [{ vendorId: vendorId, vendorName: vendorName }],
      },
    });
  }, [vendorId]);

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;
    const data = {
      startDate: formData.fromDate
        ? moment(formData.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData.endDate
        ? moment(formData.endDate).format('YYYY-MM-DD')
        : null,
      startDueDate: formData.fromDueDate
        ? moment(formData.fromDueDate).format('YYYY-MM-DD')
        : null,
      endDueDate: formData.endDueDate
        ? moment(formData.endDueDate).format('YYYY-MM-DD')
        : null,
      orderType: formData.orderType,
      vendorStatus: '0',
      orderState: formData?.dataList?.orderState?.map(o => o?.code),
      vendorIds: formData?.dataList?.vendor?.map((o: any) => o?.vendorId),
    };
    refetchData({
      data,
    });
  };

  /**
   * on remove store
   *
   * @param {string} storedType
   * @param {number} index
   */
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormData({
      ...formData,
      dataList: dataList,
    });
  };

  /**
   * on update store
   *
   * @param {string} storedType
   * @param {*} data
   * @param {*} [key=false]
   */
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormData(newData);
    setShowPopupByType('');
  };

  /**
   * generate textbox
   *
   * @param {string} fieldLabel
   * @param {string} fieldName
   * @param {string} fieldBtnText
   * @param {IOptionItem} [option]
   * @param {number} [numberShowMore=2]
   * @return {*}  {*}
   */
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };

  /**
   * render popup by type
   *
   * @param {string} popupType
   * @return {*}
   */
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'vendor':
        return (
          <PopupSelectVendor
            text={formData?.[popupType]}
            mode="multiple"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('vendor', o, 'vendorId')}
          />
        );
      case 'orderState':
        return (
          <PopupSelectCommonCode
            text={formData?.[popupType]}
            mode="multiple"
            codeType="OT"
            visible={showPopupByType === popupType}
            onHiding={() => setShowPopupByType('')}
            onSubmit={o => updateStore('orderState', o, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * on change date
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', toDateValue);
    // formRef?.current?.instance?.updateData('fromDueDate', fromDateValue);
    // formRef?.current?.instance?.updateData('endDueDate', toDateValue);
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    const ordTyp = formData?.orderType;
    switch (e?.dataField) {
      case 'fromDate':
        setFormData(preState => ({ ...preState, fromDate: e?.value }));
        if (formData.endDate && e?.value && e?.value > formData.endDate)
          formRef?.current?.instance.updateData('endDate', null);
        break;
      case 'endDate':
        setFormData(preState => ({ ...preState, endDate: e?.value }));
        if (formData.fromDate && e?.value && e?.value < formData.fromDate)
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      case 'fromDueDate':
        setFormData(preState => ({ ...preState, fromDueDate: e?.value }));
        if (formData.endDueDate && e?.value && e?.value > formData.endDueDate)
          formRef?.current?.instance.updateData('endDueDate', null);
        break;
      case 'endDueDate':
        setFormData(preState => ({ ...preState, endDueDate: e?.value }));
        if (formData.fromDueDate && e?.value && e?.value < formData.fromDueDate)
          formRef?.current?.instance.updateData('fromDueDate', null);
        break;
      default:
        break;
        setFormData(preState => ({ ...preState, orderType: ordTyp }));
        formRef?.current?.instance.updateData('orderType', null);
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="top"
          onFieldDataChanged={onFieldDataChanged}
          colCount={3}
          items={[
            generateDxTextBox('Vendor', 'vendor', 'vendorName', {
              readOnly: formData?.disableVendor,
            }),
            generateDxTextBox('Order State', 'orderState', 'name'),
            {
              label: { text: t('Order Type') },
              dataField: 'orderType',
              editorType: 'dxRadioGroup',
              editorOptions: {
                displayExpr: 'label',
                valueExpr: 'value',
                items: [
                  { label: t('Center'), value: '0' },
                  { label: t('Mall'), value: '1' },
                ],
                value: formData?.orderType || '0',
                layout: 'horizontal',
              },
            },
          ]}
        ></Form>
      </>
    );
  };

  /**
   * render advanced search
   *
   * @param {*} { name }
   * @return {*}
   */
  const AdvancedSearch = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          colCount={4}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
            {
              dataField: 'fromDueDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Due Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.fromDate && formData.endDate ? true : false,
            },
            {
              dataField: 'endDueDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Due Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired: !formData.endDate && formData.fromDate ? true : false,
            },
          ]}
          labelLocation="top"
        ></Form>
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
