import useAxios from 'axios-hooks';
import { router } from 'constant/router';
import { Button, Popover } from 'devextreme-react';
import List from 'devextreme-react/list';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import useProfileInfo from 'hooks/useProfileInfo';
import { iconRing } from 'images';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'utils/notification';
import { ItemView } from '../ItemView';
import PopupAlarmContent from '../ItemView/PopupAlarmContent';
import '../style.scss';
import { TYPE_TOKEN } from 'constant';
import tokenService from 'services/auth.service';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { WEB_SOCKET_URL } from 'constant/dataConstant';
import { useSetRecoilState } from 'recoil';
import { hasReadAlarm } from 'store/atom-state/alarm-atom';

export const NotificationPopover = () => {
  const setHasReadAlarm = useSetRecoilState(hasReadAlarm);
  const { t }: any = useDictionary({});
  const history = useHistory();
  const { userId }: any = useProfileInfo();
  const detailAlarmRef: any = useRef(null);
  const [stompClient, setStompClient] = useState<any>(null);
  const [reconnect, setReconnect] = useState<any>(false);
  const [visible, setVisible] = useState(false);
  const [alarmNotice, setAlarmNotice] = useState<any>({});
  const [{}, refetchGetAlarm] = useAxios(
    {
      url: '/core/alarm/get-for-header',
      method: 'GET',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  const getAlarmNotice = async () => {
    const res = await refetchGetAlarm();
    if (res?.data?.status === '200') {
      setAlarmNotice(res?.data?.data || {});
    } else {
      notification({ res });
    }
  };

  useEffect(() => {
    getAlarmNotice();
  }, []);

  useEffect(() => {
    reconnect && getAlarmNotice();
  }, [reconnect]);

  useEffect(() => {
    if (!userId || stompClient) return;
    connect(false);
    return () => {
      disconnect();
    };
  }, [userId]);

  useEffect(() => {
    if (!userId || !stompClient) return;
    const subsMessages = stompClient?.subscribe?.(
      `/alarm/messages/${userId}`,
      message => {
        if (!message?.body) return;
        const dataSocket = JSON.parse(message.body);
        setAlarmNotice(prevAlarm => {
          const updatedAlarm = { ...prevAlarm };
          updatedAlarm[dataSocket?.alarmCd]?.unshift(dataSocket);
          updatedAlarm[`count_${dataSocket?.alarmCd}`] =
            (updatedAlarm[`count_${dataSocket?.alarmCd}`] || 0) + 1;
          updatedAlarm.countAllNotRead =
            (updatedAlarm.countAllNotRead || 0) + 1;

          return updatedAlarm;
        });
      },
    );
    const subsHasRead = stompClient?.subscribe(
      `/alarm/hasRead/${userId}`,
      message => {
        if (!message?.body) return;
        const dataSocket = JSON.parse(message.body);
        setHasReadAlarm(dataSocket);
        setAlarmNotice(prevAlarm => {
          const updatedAlarm = { ...prevAlarm };
          updatedAlarm[dataSocket?.alarmCd] = updatedAlarm?.[
            dataSocket?.alarmCd
          ]?.filter(o => o?.noticeId !== dataSocket?.noticeId);
          updatedAlarm[`count_${dataSocket?.alarmCd}`] =
            (updatedAlarm[`count_${dataSocket?.alarmCd}`] || 0) - 1;
          updatedAlarm.countAllNotRead =
            (updatedAlarm.countAllNotRead || 0) - 1;
          return updatedAlarm;
        });
      },
    );
    return () => {
      subsMessages?.unsubscribe?.();
      subsHasRead?.unsubscribe?.();
    };
  }, [userId, stompClient]);

  const connect = reconnect => {
    const token = `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`;
    const socket = new SockJS(WEB_SOCKET_URL);
    const stompClient = Stomp.over(socket);
    stompClient.debug = null; // disable log debug
    stompClient.connect(
      { Authorization: token },
      frame => {
        setReconnect(reconnect);
        setStompClient(stompClient);
      },
      function (error) {
        if (stompClient) {
          stompClient?.disconnect?.(function () {
            console.log('Disconnected');
          });
        }
        setTimeout(() => {
          connect(true);
        }, 60000);
        console.error('The socket is not connected!!!');
      },
    );
  };

  const disconnect = () => {
    if (stompClient) {
      stompClient?.disconnect?.(function () {
        console.log('Disconnected');
      });
    }
    setStompClient(null);
  };

  const onHiding = () => {
    setVisible(false);
  };

  return (
    <div
      className="notification-alarm"
      style={{ position: 'relative' }}
      onClick={() => setVisible(true)}
    >
      <span
        style={{
          position: 'absolute',
          right: -13,
          top: -8,
          width: 25,
          height: 20,
          backgroundColor: 'red',
          color: '#ffff',
          borderRadius: '50%',
          textAlign: 'center',
          fontSize: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 2,
        }}
      >
        {`${
          (alarmNotice?.countAllNotRead || 0) > 50
            ? '50+'
            : alarmNotice?.countAllNotRead || 0
        }`}
      </span>
      <img height={20} width={20} src={iconRing} alt="notification" />
      <Popover
        className="popover-notification"
        target="#notification-alarm"
        position="top"
        width={600}
        height="100vh"
        visible={visible}
        onHiding={onHiding}
      >
        <div
          style={{
            padding: 10,
            borderBottom: '1px solid #E4E7EC',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className="modal-popup-header"
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              lineHeight: '28px',
            }}
          >
            {t('Notification')}
          </span>
          <Button
            stylingMode="text"
            icon="close"
            onClick={() =>
              setTimeout(() => {
                setVisible(false);
              }, 100)
            }
          />
        </div>

        <TabPanel
          swipeEnabled={false}
          deferRendering={false}
          repaintChangesOnly
          showNavButtons
          scrollingEnabled
          scrollByContent
          className="theme-premiun-dx-multiview-wrapper-0"
        >
          {(alarmNotice?.alarmTypes || []).map(at => (
            <TabItem
              title={`${at?.name} (${alarmNotice?.[`count_${at?.code}`]})`}
              key={at?.code}
            >
              <div>
                <List
                  dataSource={alarmNotice?.[at?.code]}
                  keyExpr="noticeId"
                  repaintChangesOnly={true}
                  itemRender={e => (
                    <ItemView
                      data={e}
                      key={e?.noticeId}
                      // cursor
                      t={t}
                      onShowDetail={() => detailAlarmRef?.current?.onOpen(e)}
                    />
                  )}
                  showScrollbar="onScroll"
                  height={'calc(100vh - 210px)'}
                  className="alarm-notice-list"
                  showSelectionControls={false}
                  onItemClick={e => e?.event?.preventDefault()}
                />

                <div
                  className="cursor-pointer"
                  style={{
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      lineHeight: '20px',
                      color: '#6941C6',
                    }}
                    onClick={() => {
                      history.push({
                        pathname: router.home,
                        state: {
                          alarmCd: at?.code,
                          notHome: true,
                        },
                      });
                      setTimeout(() => {
                        setVisible(false);
                      }, 100);
                    }}
                  >
                    {t('See all notifications')}
                  </span>
                </div>
              </div>
            </TabItem>
          ))}
        </TabPanel>
      </Popover>
      <PopupAlarmContent ref={detailAlarmRef} />
    </div>
  );
};
