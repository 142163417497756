/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { ScrollView } from 'devextreme-react';
import BuyerContent from './BuyerContent';
function Buyer() {
  return (
    <div style={{ height: 'calc(100vh - 280px)' }}>
      <ScrollView width="100%" height="100%" useNative={true}>
        <BuyerContent />
      </ScrollView>
    </div>
  );
}

export default Buyer;
