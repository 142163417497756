/**
 * Update date: 26-05-2023
 * Screen 2043.2
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';

const List = React.lazy(() => import('./List'));
/**
 * create context
 */
const ProcessOrderUploadContext = createContext({});

ProcessOrderUploadContext.displayName = 'ProcessOrderUploadContext';

export const useProcessOrderUpload = () => {
  return useContext(ProcessOrderUploadContext);
};

const ProcessOrderUploadTab = () => {
  const [data, setData] = useState([]);
  const [dataImport, setDataImport] = useState<any>([]);

  const [modeView, setModeView] = useState({
    type: 'list',
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      mall: [],
      orderFormName: [],
    },
    mall: '',
    orderFormName: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    data,
    setData,
    dataImport,
    setDataImport,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ProcessOrderUploadContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ProcessOrderUploadContext.Provider>
  );
};

export default ProcessOrderUploadTab;
