/**
 * Update date: 08-06-2023
 * Screen 2081.H
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useContext, createContext } from 'react';
import useDictionary from 'hooks/useDictionary';

const duesTypeList = [
  { label: 'All', value: '' },
  { label: 'Deposit', value: '1' },
  { label: 'Payment', value: '2' },
];

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2081_E_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2081_E = () => {
  const { t }: any = useDictionary({});
  // stored data grid after search
  const [data, setData] = useState([]);
  // stored data form search
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    duesType: '',
    dataList: {
      duesKinds: [],
    },
    duesKinds: '',
  });
  const [modeView, setModeView] = useState({
    type: 'list',
    id: null,
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    duesTypeList,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2081_E;
