/**
 * Update date: 09-06-2023
 * Load panel image component
 */
import React from 'react';
import { LoadPanel as CustomLoadPanel } from 'devextreme-react';

export default function LoadPanel(props) {
  return (
    <CustomLoadPanel
      indicatorSrc="/assets/images/loading.gif"
      {...props}
      message={props?.message || 'Loading...'}
    />
  );
}
