import { useEffect } from 'react';
import useAxios from 'axios-hooks';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import { useLocation } from 'react-router-dom';
import { useApp } from 'app';
interface IUseDictionaryProps {
  programId?: string;
}

let newVoca: any = [];
function useDictionary({ programId }: IUseDictionaryProps) {
  const appContext: any = useApp();
  const { voca, setVoca, firstRender } = appContext || {};

  const location: any = useLocation();
  const str = location?.pathname?.split('/')?.[3];
  const id = programId || location?.state?.programId3 || str;

  const [{}, checkVoca] = useAxios(
    {
      url: '/core/multi-language/create-language-incomplete',
      method: 'POST',
    },
    { manual: true, useCache: false, autoCancel: true },
  );

  const getVoca = word => {
    const keyCommon = `common-${word}`;
    const key = id ? `${id}-${word}` : word;
    const res =
      formatMessage(key) || formatMessage(keyCommon) || formatMessage(word);
    if (firstRender) return res;

    if (!voca[keyCommon]) {
      if (!voca[key]) {
        if (!newVoca?.includes(word)) {
          newVoca?.push(word);
        }
      }
    }
    return res || word;
  };

  const collectionVoca = async () => {
    const d = newVoca?.filter(o => o);
    if (!d?.length) return;
    const res = await checkVoca({
      data: {
        languageCode: locale(),
        id: 'common',
        base: d,
      },
    });
    if (res?.data?.status === '201') {
      if (!res?.data?.data?.length) return;
      const messages = [...res?.data?.data].filter(
        o => o?.languageCode === locale(),
      );
      let l: any = {};
      messages?.forEach((o, i) => {
        if (o?.translation || o?.base) {
          l[`${o?.id}-${o?.base}`] = o?.translation || o?.autoTranslation;
        }
      });
      newVoca = d.filter(o => !messages.some(k => k?.base === o));
      const l2 = { ...voca, ...l };
      setVoca(l2);
      loadMessages({
        [locale()]: l,
      });
    }
  };

  useEffect(() => {
    if (id && id !== 'common') {
      setTimeout(collectionVoca, 4000);
    }
  }, [id]);

  return {
    t: getVoca,
  };
}

export default useDictionary;
