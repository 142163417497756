/**
 * Update date: 08-06-2023
 * Screen 2081.D
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
const ListDepositDeduction = React.lazy(() => import('./ListDepositDeduction'));
/**
 * create context
 */
const ModuleContext = createContext({
  searchParams: {},
  listDepositDeduction: [] || null,
  setSearchParams: () => {},
  onUpdatelistDepositDeduction: () => {},
});

ModuleContext.displayName = 'InquiryStoreDepositDeduction';

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const InquiryStoreDepositDeduction = () => {
  const [searchParams, setSearchParams] = useState({
    fromDate: new Date(),
    endDate: new Date(),
    storeIds: [],
    storeGroupIds: [],
    type: '0',
  });
  const [listDepositDeduction, setlistDepositDeduction] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
  });

  const [{ data: resApiList, loading: loadingApiList }, apiGetList] = useAxios(
    {
      url: '/user/store-deposit-deduction',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  /**
   * on update list deposit deduction
   *
   * @param {*} record
   */
  const onUpdatelistDepositDeduction = (record: any) => {
    const existItem = listDepositDeduction?.some(
      (o: any) =>
        o?.deductionDate == record?.deductionDate &&
        o?.lineId == record?.lineId,
    );
    let l: any = [];
    if (record?.type === 'delete') {
      l = [...listDepositDeduction]?.filter(
        (o: any) =>
          o?.deductionDate != record?.deductionDate &&
          o?.lineId != record?.lineId,
      );
    } else {
      if (existItem) {
        l = [...listDepositDeduction]?.map((o: any) => {
          if (
            o?.deductionDate == record?.deductionDate &&
            o?.lineId == record?.lineId
          )
            return record;
          return o;
        });
      } else {
        l = [...listDepositDeduction];
        l.unshift(record);
      }
    }
    setlistDepositDeduction(l);
  };

  const value: any = {
    setModeView,
    modeView,
    searchParams,
    setSearchParams,
    listDepositDeduction,
    apiGetList,
    onUpdatelistDepositDeduction,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListDepositDeduction />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (resApiList?.data) {
      setlistDepositDeduction(resApiList?.data);
    }
  }, [resApiList]);

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingApiList} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default InquiryStoreDepositDeduction;
