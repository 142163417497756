/**
 * Update date: 05-06-2023
 * Screen 2053.2
 */
import React, { useState, useRef, useEffect } from 'react';
import Form from 'devextreme-react/form';
import Collapse from 'app/components/Collapse';
import DateOptions from 'app/components/DateOptions';
import useFormatTime from 'hooks/useFormatTime';
import PopupSelectProduct from 'app/components/PopupCommon/PopupSelectProduct';
import PopupSelectProductGroup from 'app/components/PopupCommon/PopupSelectProductGroup';
import PopupSelectCommonCode from 'app/components/PopupCommon/PopupSelectCommonCode';
import { useReceiveForward } from '..';
import useDictionary from 'hooks/useDictionary';
import { notification } from 'utils/notification';
import { useProductType } from 'hooks/useProductType';
import { cloneDeep, uniqBy } from 'lodash';
import { dateFormatStr } from 'utils/format';
import { TRADE_TYPE } from 'constant';
import useLocalStorage from 'hooks/useLocalStorage';
interface IOptionItem {
  isRequired?: boolean;
  colSpan?: number;
  readOnly?: boolean;
}

const listTypeCommon = {
  largeCategory: {
    codeType: 'LC',
  },
  mediumCategory: {
    codeType: 'MC',
  },
  smallCategory: {
    codeType: 'SC',
  },
  subCategory: {
    codeType: 'UC',
  },
  productTypes: {
    codeType: 'PT',
  },
  productMaker: {
    codeType: 'MK',
  },
};

const FormSearch = () => {
  const [dataLocal, setDataLocal] = useLocalStorage('2053.2_dataList', {});
  const { productTpPermission }: any = useProductType();
  const { t }: any = useDictionary({ programId: '2053.2' });
  const context: any = useReceiveForward();
  const { refetchData } = context;
  const { DateFormat } = useFormatTime();
  const formRef: any = useRef(null);
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    tradeType: '0',
    isReadOnlyProductTp: false,
    dataList: {
      largeCategory: [],
      mediumCategory: [],
      smallCategory: [],
      subCategory: [],
      product: [],
      productGroup: [],
      productTypes: [],
      productMaker: [],
    },
    largeCategory: '',
    mediumCategory: '',
    smallCategory: '',
    subCategory: '',
    product: '',
    productGroup: '',
    productTypes: '',
    productMaker: '',
  });
  const [showPopupByType, setShowPopupByType] = useState('');
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal?.tradeType) return;
    setFormSearchData(preState => ({
      ...preState,
      tradeType: dataLocal?.tradeType || '0',
    }));
  }, [dataLocal]);

  useEffect(() => {
    if (!productTpPermission?.length) return;
    setFormSearchData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productTypes: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  /**
   * on submit
   *
   * @return {*}
   */
  const onSubmit = () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;
    if (isValid === false) return;

    const data = {
      fromDate: dateFormatStr(formSearchData.fromDate),
      toDate: dateFormatStr(formSearchData.endDate),
      tradeType: formSearchData?.tradeType,
      largeCategoryCodes: formSearchData?.dataList?.largeCategory?.map(
        (map: any) => map?.code,
      ),
      mediumCategoryCodes: formSearchData?.dataList?.mediumCategory?.map(
        (map: any) => map?.code,
      ),
      smallCategoryCodes: formSearchData?.dataList?.smallCategory?.map(
        (map: any) => map?.code,
      ),
      subCategoryCodes: formSearchData?.dataList?.subCategory?.map(
        (map: any) => map?.code,
      ),
      productIds: formSearchData?.dataList?.product?.map(
        (map: any) => map?.productId,
      ),
      productGroupIds: formSearchData?.dataList?.productGroup?.map(
        (map: any) => map?.productGroupId,
      ),
      productTypes: formSearchData?.dataList?.productTypes?.map(
        (o: any) => o?.code,
      ),
      productMakers: formSearchData?.dataList?.productMaker?.map(
        (o: any) => o?.code,
      ),
    };
    refetchData({ data });
  };

  /**
   * on change date
   *
   * @param {*} fromDateValue
   * @param {*} toDateValue
   * @return {*}
   */
  const onChangeDate = (fromDateValue, toDateValue) => {
    if (!fromDateValue || !toDateValue) return;

    formRef?.current?.instance?.updateData('endDate', toDateValue);
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
  };

  /**
   * on field data changed
   *
   * @param {*} e
   * @return {*}
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (
          formSearchData.endDate &&
          e?.value &&
          e?.value <= formSearchData.endDate
        ) {
          const newDate = new Date(formSearchData?.endDate);
          const newDate1 = new Date(e?.value);
          const danRange = Math.floor(
            (newDate.getTime() - newDate1.getTime()) / (1000 * 60 * 60 * 24),
          );
          if (
            danRange > 365 ||
            (formSearchData.endDate &&
              e?.value &&
              e?.value > formSearchData.endDate)
          ) {
            formRef?.current?.instance.updateData('endDate', new Date());
            notification({
              message:
                'Selected period should not be greater than 1 year, please reselect.',
              type: 'error',
            });
            return;
          } else {
            setFormSearchData(preState => ({
              ...preState,
              fromDate: e?.value,
            }));
          }
        }
        break;
      case 'endDate':
        if (
          formSearchData.endDate &&
          e?.value &&
          e?.value > formSearchData.fromDate
        ) {
          const newDate = new Date(formSearchData?.fromDate);
          const newDate1 = new Date(e?.value);
          const dayDiff = Math.floor(
            (newDate1.getTime() - newDate.getTime()) / (1000 * 60 * 60 * 24),
          );
          if (
            dayDiff > 365 ||
            (formSearchData.fromDate &&
              e?.value &&
              e?.value < formSearchData.fromDate)
          ) {
            formRef?.current?.instance.updateData('fromDate', new Date());
            formRef?.current?.instance.updateData('endDate', new Date());
            notification({
              message:
                'Selected period should not be greater than 1 year, please reselect.',
              type: 'error',
            });
            return;
          } else {
            setFormSearchData(preState => ({ ...preState, endDate: e?.value }));
          }
        }
        break;
      default:
        break;
    }
  };

  // remote data input popup
  const removeStore = (storedType: string, index: number) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    if (index === -1) {
      dataList[storedType] = [];
    } else {
      dataList[storedType]?.splice(index, 1);
    }
    setFormSearchData({
      ...formSearchData,
      dataList: dataList,
    });
  };
  // update data input popup
  const updateStore = (storedType: string, data, key: any = false) => {
    const dataList = cloneDeep(formSearchData?.dataList || {});
    let filterData: any = [data];
    if (key) {
      filterData = uniqBy([...(dataList?.[storedType] || []), ...data], key);
    }
    dataList[storedType] = filterData;
    const newData = {
      ...formSearchData,
      dataList: dataList,
      [storedType]: '',
    };
    setFormSearchData(newData);
    setShowPopupByType('');
  };

  // generate form input
  const generateDxTextBox = (
    fieldLabel: string,
    fieldName: string,
    fieldBtnText: string,
    option?: IOptionItem,
    numberShowMore = 2,
  ): any => {
    const dataList = formSearchData?.dataList?.[fieldName] || [];
    const buttons = dataList.slice(0, numberShowMore).map((data, index) => {
      return {
        name: `${fieldName}${index}`,
        location: 'before',
        options: {
          icon: 'close',
          text: data?.[fieldBtnText],
          onClick: () => removeStore(fieldName, index),
        },
      };
    });
    if (dataList.length > numberShowMore) {
      buttons.push({
        name: `${fieldName}More`,
        location: 'before',
        options: {
          text: `+ ${dataList.length - numberShowMore} ${t('more')}`,
        },
      });
    }
    if (dataList.length > 0) {
      buttons.push({
        name: 'clearAll',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'close',
          onClick: () => removeStore(fieldName, -1),
        },
      });
    }
    buttons.push({
      name: 'search',
      location: 'after',
      options: {
        stylingMode: 'text',
        icon: 'search',
        onClick: () => setShowPopupByType(fieldName),
      },
    });
    return {
      label: { text: t(fieldLabel) },
      dataField: fieldName,
      editorType: 'dxTextBox',
      colSpan: option?.colSpan || 0,
      isRequired: option?.isRequired && !dataList.length,
      editorOptions: {
        readOnly: option?.readOnly || false,
        onEnterKey: () => setShowPopupByType(fieldName),
        showClearButton: true,
        buttons: buttons,
      },
    };
  };
  // render popup select
  const getPopupByType = (popupType: string) => {
    switch (popupType) {
      case 'productGroup':
        return (
          <PopupSelectProductGroup
            visible={popupType === 'productGroup'}
            text={formSearchData?.[popupType]}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'productGroupId')}
          />
        );
      case 'product':
        return (
          <PopupSelectProduct
            visible={popupType === 'product'}
            text={formSearchData?.[popupType]}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'productId')}
          />
        );
      case 'largeCategory':
      case 'mediumCategory':
      case 'smallCategory':
      case 'subCategory':
      case 'productTypes':
      case 'productMaker':
        return (
          <PopupSelectCommonCode
            visible={!!listTypeCommon?.[popupType]?.codeType}
            text={formSearchData?.[popupType]}
            codeType={listTypeCommon?.[popupType]?.codeType}
            mode="multiple"
            onHiding={() => setShowPopupByType('')}
            onSubmit={data => updateStore(popupType, data, 'code')}
          />
        );
      default:
        return null;
    }
  };

  /**
   * render search
   *
   * @param {*} { name }
   * @return {*}
   */
  const Search = ({ name }) => {
    return (
      <>
        <DateOptions
          onChangeValue={(fromDate, toDate) => onChangeDate(fromDate, toDate)}
        />
        <Form
          ref={formRef}
          formData={formSearchData}
          showColonAfterLabel={false}
          labelLocation="top"
          onFieldDataChanged={onFieldDataChanged}
          colCount={7}
          items={[
            {
              dataField: 'fromDate',
              editorType: 'dxDateBox',
              label: {
                text: t('From Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired:
                !formSearchData.fromDate && formSearchData.endDate
                  ? true
                  : false,
            },
            {
              dataField: 'endDate',
              editorType: 'dxDateBox',
              label: {
                text: t('End Date'),
              },
              editorOptions: {
                displayFormat: DateFormat,
              },
              isRequired:
                !formSearchData.endDate && formSearchData.fromDate
                  ? true
                  : false,
            },
            {
              label: { text: t('Trade Type') },
              dataField: 'tradeType',
              editorType: 'dxSelectBox',
              editorOptions: {
                items: TRADE_TYPE?.map(o => ({
                  value: o?.value,
                  text: t(o?.text),
                })),
                displayExpr: 'text',
                valueExpr: 'value',
                onValueChanged: e => {
                  if (e?.event) {
                    setDataLocal(preState => ({
                      ...preState,
                      tradeType: e?.value || '0',
                    }));
                  }
                },
              },
            },
            generateDxTextBox(
              'Product Type',
              'productTypes',
              'name',
              {
                readOnly: formSearchData?.isReadOnlyProductTp,
                colSpan: 2,
              },
              3,
            ),
            generateDxTextBox('Maker', 'productMaker', 'name', { colSpan: 2 }),
          ]}
        ></Form>
      </>
    );
  };
  /**
   * render advanced search
   *
   * @param {*} name
   * @return {*}
   */
  const AdvancedSearch = name => {
    return (
      <>
        <Form
          formData={formSearchData}
          showColonAfterLabel={false}
          labelLocation="top"
          colCount={3}
          items={[
            generateDxTextBox('Large Category', 'largeCategory', 'name'),
            generateDxTextBox('Medium Category', 'mediumCategory', 'name'),
            generateDxTextBox('Small Category', 'smallCategory', 'name'),
            generateDxTextBox('Sub Category', 'subCategory', 'name'),
            generateDxTextBox(
              'Product Group',
              'productGroup',
              'productGroupNm',
            ),
            generateDxTextBox('Product', 'product', 'productNm'),
          ]}
        />
      </>
    );
  };

  return (
    <div>
      <Collapse onSubmit={onSubmit}>
        <Search name={t('Search')} />
        <AdvancedSearch name={t('Advanced Search')} />
      </Collapse>
      {getPopupByType(showPopupByType)}
    </div>
  );
};

export default FormSearch;
