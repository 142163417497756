/**
 * Create date: 2024-06-20
 * Screen 2082.E
 */
import { Form, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';

interface IChangeCommissionMargin {
  onOk: (data) => void;
}

function ChangeCommissionMargin({ onOk }: IChangeCommissionMargin, ref) {
  const { t }: any = useDictionary({});
  const formRef: any = useRef(null);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [formData, setFormData] = useState({
    currentCommission: 0,
    newCommission: 0,
  });
  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = (data: any) => {
    if (!data) return;
    setFormData(prev => ({ ...prev, ...data }));
    setShowPopupConfirm(true);
    setTimeout(function () {
      formRef?.current?.instance?.getEditor('newCommission')?.focus?.();
    }, 600);
  };

  const onClickYes = () => {
    onOk?.({ ...formData });
    setFormData({
      currentCommission: 0,
      newCommission: 0,
    });
    setShowPopupConfirm(false);
  };

  const onClickNo = () => {
    setFormData({
      currentCommission: 0,
      newCommission: 0,
    });
    setShowPopupConfirm(false);
  };

  const content = () => {
    return (
      <div>
        <div
          style={{
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: 500,
            marginBottom: 10,
          }}
        >
          {t('Change Commission (Margin)')}
        </div>
        <Form
          ref={formRef}
          labelLocation="top"
          showColonAfterLabel={false}
          formData={formData}
          items={[
            {
              dataField: 'currentCommission',
              editorType: 'dxNumberBox',
              label: {
                text: t('Current Commission (Margin)'),
              },
              editorOptions: {
                disabled: true,
                format: '##0.##',
              },
            },
            {
              dataField: 'newCommission',
              editorType: 'dxNumberBox',
              label: {
                text: t('New Commission (Margin)'),
              },
              editorOptions: {
                min: 0,
                max: 100,
                format: '##0.##',
              },
            },
            {
              itemType: 'group',
              colSpan: 2,
              colCount: 2,
              items: [
                {
                  itemType: 'button',
                  buttonOptions: {
                    text: t('Cancel'),
                    type: 'normal',
                    width: '100%',
                    icon: 'close',
                    onClick: onClickNo,
                  },
                },
                {
                  itemType: 'button',
                  buttonOptions: {
                    text: t('Apply'),
                    type: 'default',
                    width: '100%',
                    icon: 'check',
                    onClick: onClickYes,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    );
  };

  return (
    <Popup
      visible={showPopupConfirm}
      contentRender={content}
      height={'auto'}
      width={340}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}
export default memo(forwardRef(ChangeCommissionMargin));
