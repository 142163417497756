/**
 * Update date: 05-06-2023
 * Screen 2054.3
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import { useApp } from 'app';

const List = React.lazy(() => import('./List'));
const ViewEditCreate = React.lazy(() => import('./ViewEditCreate'));
/**
   * create context
   */
const DueDiligenceOnInventoryInLocationPDAContext = createContext({});
DueDiligenceOnInventoryInLocationPDAContext.displayName =
  'DueDiligenceOnInventoryInLocationPDAContext';

export const useDueDiligenceOnInventoryInLocationPDA = () => {
  return useContext(DueDiligenceOnInventoryInLocationPDAContext);
};

const dueDiligenceDefault = {
  planId: '',
  diligenceNo: '',
  subject: '',
  manager: '',
  startDate: '',
  endDate: '',
  adjustDate: '',
  area: '1',
  zone: '',
  method: '',
  product: '1',
  reason: '',
  note: '',
  significant: '',
  approved: false,
};

const DueDiligenceOnInventoryInLocationPDA = () => {
  const appContext: any = useApp();
  const { s } = appContext || {};
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });
  const [dueDiligenceInfo, setDueDiligenceInfo] = useState({
    ...dueDiligenceDefault,
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      dueDiligence: [],
    },
    dueDiligence: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    s,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    dueDiligenceInfo,
    setDueDiligenceInfo,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
      case 'edit':
      case 'create':
        return <ViewEditCreate />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <DueDiligenceOnInventoryInLocationPDAContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </DueDiligenceOnInventoryInLocationPDAContext.Provider>
  );
};

export default DueDiligenceOnInventoryInLocationPDA;
