/**
 * Update date: 08-06-2023
 * My page component
 */
import React, { useState, useRef, useEffect } from 'react';
import { Button, Template, Form } from 'devextreme-react';
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import PopupSelectPostCode from 'app/components/PopupCommon/PopupSelectPostCode';
import moment from 'moment';
import { notification } from 'utils/notification';
import { useMyPage } from '..';
import UploadImage from '../UploadImage';
import { CONSTANT } from 'constant';
import { phoneNumberSplit } from 'utils/phone-split';
import ChangeEmail from '../ChangeEmail';
import './style.scss';
import useFormatTime from 'hooks/useFormatTime';
import useDictionary from 'hooks/useDictionary';
import { restrictKeyboardPhone } from 'utils/common/restrict-keyboard-phone';
import { useApp } from 'app';
import ImageUploadPremium from 'app/components/Templates/ImageUploadPremium';

const { listPhone } = CONSTANT;

const AccountDetails = () => {
  const appContext: any = useApp();
  const { myPageApp, setMyPageApp, themePro } = appContext || {};
  const myPageContext: any = useMyPage();
  const { setModeView } = myPageContext || {};
  const [showPopupChangeEmail, setShowPopupChangeEmail] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [showPopupPostCode, setShowPopupPostCode] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const formRef: any = useRef(null);
  const { DateFormat } = useFormatTime();

  const { t }: any = useDictionary({
    programId: '1031.7',
  });

  const [{ loading: updateAccountLoading }, updateAccountDetail] = useAxios(
    {
      url: 'user/my-page',
      method: 'PUT',
    },
    {
      manual: true,
    },
  );

  /**
   * on change custom field
   *
   * @param {*} fieldName
   * @param {*} value
   * @return {*}
   */
  const onChangeCustomeField = (fieldName, value) => {
    if (!fieldName) return;
    formRef?.current?.instance?.updateData(fieldName, value);
    setFormData({ ...formData, [fieldName]: value });
  };

  /**
   * on upload image
   *
   * @param {*} name
   * @param {*} value
   */
  const onUploadImage = (name, value) => {
    formRef?.current?.instance?.updateData(name, value);
  };

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const isValid = formRef?.current?.instance?.validate()?.isValid;

    if (isValid) {
      const mobileZipCodeExist = formData.mobile.includes(
        formData.mobileZipCode,
      );
      const phoneZipCodeExist = formData.phone.includes(formData.phoneZipCode);

      const formDataBody = {
        ...formData,
        mobile: !mobileZipCodeExist
          ? `${formData.mobileZipCode}${formData.mobile}`
          : formData.mobile,
        phone: !phoneZipCodeExist
          ? `${formData.phoneZipCode}${formData.phone}`
          : formData.phone,
      };

      const res = await updateAccountDetail({ data: { ...formDataBody } });
      notification({
        res,
      });
      if (!themePro) onCancel();
      if (res?.data?.status === '200') {
        const myPagePreferenceLocal = JSON.parse(
          localStorage.getItem('myPagePreference') || '{}',
        );
        const data = {
          ...myPagePreferenceLocal,
          ...formData,
        };
        localStorage.setItem('myPagePreference', JSON.stringify(data));
        setMyPageApp(data);
      }
    }
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    setModeView(preState => ({ ...preState, type: 'list' }));
  };

  /**
   * on change email
   *
   * @param {*} value
   */
  const onChangeEmail = value => {
    formRef?.current?.instance?.updateData('email', value);
  };

  /**
   * on change post code
   *
   * @param {*} data
   */
  const onChangePostCode = data => {
    formRef?.current?.instance?.updateData('postCode', data?.zipNo);
    formRef?.current?.instance?.updateData('addressBasic', data?.roadAddrPart1);
    formRef?.current?.instance?.updateData(
      'addressDetail',
      data?.roadAddrPart2,
    );
    setShowPopupPostCode(false);
  };
  /**
   * fetch data
   */

  useEffect(() => {
    if (myPageApp) {
      var myPageMobile = phoneNumberSplit(myPageApp?.mobile);
      var myPagePhone = phoneNumberSplit(myPageApp?.phone);
      setFormData({
        ...myPageApp,
        mobileZipCode: myPageMobile?.zipCode || '',
        phoneZipCode: myPagePhone?.zipCode || '',
        mobile: myPageMobile?.phone || '',
        phone: myPagePhone?.phone || '',
      });
    }
  }, [myPageApp]);

  return (
    <>
      <LoadPanel visible={updateAccountLoading} />
      {/* <div className="form">Edit form</div> */}
      {themePro && (
        <div className="my-page-caption">
          <div>
            <div className="my-page-caption-title">{t('Account Detail')}</div>
            <div className="my-page-caption-des">
              {t('Edit contact information and address, etc.')}
            </div>
          </div>
          <Button
            icon="check"
            stylingMode="contained"
            type="default"
            text={t('Save')}
            onClick={onSubmit}
          />
        </div>
      )}
      <Form
        ref={formRef}
        formData={formData}
        colCount={1}
        labelLocation="top"
        height={themePro ? '100%' : 'calc(80vh - 150px)'}
        showColonAfterLabel={false}
        scrollingEnabled={true}
        focusStateEnabled={true}
        hoverStateEnabled={true}
        className="my-page-account-detail-form"
        items={[
          {
            caption: themePro ? '' : t('Account Detail'),
            itemType: 'group',
            colCount: 2,
            items: [
              {
                itemType: 'group',
                items: [
                  {
                    itemType: 'group',
                    colCount: 2,
                    items: [
                      {
                        label: {
                          text: t('Welcome') + ' ' + formData.name,
                        },
                        colSpan: 2,
                        cssClass: 'dx-form-group-caption',
                        visible: themePro ? false : true,
                      },
                      {
                        label: {
                          text: t('Login ID'),
                        },
                        dataField: 'loginId',
                        colSpan: 2,
                        editorOptions: { disabled: true },
                      },
                      {
                        label: {
                          text: t('ID'),
                        },
                        dataField: 'userId',
                        editorOptions: { disabled: true },
                      },
                      {
                        label: {
                          text: t('User Name'),
                        },
                        dataField: 'name',
                        editorOptions: { disabled: true },
                      },
                    ],
                  },
                  {
                    itemType: 'group',
                    colCount: 2,
                    items: [
                      {
                        label: {
                          text: t('DOB'),
                        },
                        dataField: 'dob',
                        editorType: 'dxDateBox',
                        editorOptions: {
                          displayFormat: DateFormat,
                          max: moment(),
                        },
                      },
                      {
                        label: {
                          text: t('Job Title'),
                        },
                        dataField: 'jobTitle',
                      },
                    ],
                  },
                  {
                    itemType: 'group',
                    colCount: 2,
                    items: [
                      {
                        label: {
                          text: t('Directly Under'),
                        },
                        dataField: 'directlyUnder',
                      },
                      {
                        label: {
                          text: t('Department'),
                        },
                        dataField: 'department',
                      },
                    ],
                  },
                  {
                    itemType: 'group',
                    colCount: 3,
                    items: [
                      {
                        label: {
                          text: t('Email'),
                        },
                        dataField: 'email',
                        colSpan: 2,
                        editorOptions: { disabled: true },
                      },
                      {
                        buttonOptions: {
                          text: t('Change'),
                          icon: 'refresh',
                          onClick: () => setShowPopupChangeEmail(true),
                        },
                        itemType: 'button',
                      },
                    ],
                  },
                ],
              },
              {
                itemType: 'simple',
                template: 'formUpload',
                editorOptions: {
                  value: formData?.avatar,
                  onChangeValue: e => {
                    onChangeCustomeField('avatar', e);
                  },
                },
              },
              {
                itemType: 'group',
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    label: {
                      text: t('Mobile No'),
                    },
                    dataField: 'mobileZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.mobileZipCode
                        ? formData.mobileZipCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                    validationRules: [
                      {
                        type: 'required',
                        message: t('Mobile Zip Code is required'),
                      },
                    ],
                  },
                  {
                    label: { visible: false },
                    dataField: 'mobile',
                    colSpan: 2,
                    cssClass: 'require-mark-remove',
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'required',
                        message: t('Mobile No is required'),
                      },
                    ],
                  },
                ],
              },
              {
                itemType: 'group',
                colCount: 3,
                cssClass: 'group-padding-small',
                items: [
                  {
                    label: {
                      text: t('Phone No'),
                    },
                    dataField: 'phoneZipCode',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                      items: listPhone,
                      value: formData.phoneZipCode
                        ? formData.phoneZipCode
                        : listPhone[0].value,
                      displayExpr: 'label',
                      valueExpr: 'value',
                    },
                    validationRules: [
                      {
                        type: 'required',
                        message: t('Phone Zip Code is required'),
                      },
                    ],
                  },
                  {
                    label: { visible: false },
                    dataField: 'phone',
                    colSpan: 2,
                    cssClass: 'require-mark-remove',
                    editorOptions: {
                      onKeyDown: restrictKeyboardPhone,
                    },
                    validationRules: [
                      {
                        type: 'required',
                        message: t('Phone No is required'),
                      },
                    ],
                  },
                ],
              },
              {
                itemType: 'group',
                colCount: 2,
                colSpan: 2,
                items: [
                  {
                    label: {
                      text: t('Post Code'),
                    },
                    dataField: 'postCode',
                    editorOptions: {
                      onEnterKey: () => setShowPopupPostCode(true),
                      buttons: [
                        {
                          name: 'search',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            icon: 'search',
                            onClick: () => setShowPopupPostCode(true),
                          },
                        },
                      ],
                    },
                  },
                  {
                    label: {
                      text: t('Address 1'),
                    },
                    dataField: 'addressBasic',
                    colSpan: 2,
                  },
                ],
              },
              {
                itemType: 'group',
                colCount: 2,
                colSpan: 2,
                items: [
                  {
                    label: {
                      text: '',
                    },
                  },
                  {
                    label: {
                      text: t('Address 2'),
                    },
                    dataField: 'addressDetail',
                    colSpan: 2,
                  },
                ],
              },
              {
                colSpan: 2,
                label: {
                  text: t('Note'),
                },
                dataField: 'note',
                editorType: 'dxTextArea',
              },
            ],
          },
        ]}
      >
        <Template
          name="formUpload"
          component={() =>
            themePro ? (
              <ImageUploadPremium
                value={formData?.avatar}
                onChangeValue={data => onUploadImage('avatar', data)}
                width="100%"
              />
            ) : (
              <UploadImage />
            )
          }
        />
      </Form>
      {!themePro && (
        <div className="my-page-btn-footer">
          <Button icon="save" text={t('Save')} onClick={onSubmit} />
          <Button
            icon="close"
            text={t('Cancel')}
            onClick={() => setShowPopupConfirm(true)}
          />
        </div>
      )}
      {showPopupConfirm && (
        <PopupConfirm
          title={t('Confirm Cancel')}
          visible={showPopupConfirm}
          content={t('Are you sure to cancel all the change?')}
          onOk={onCancel}
          onHiding={() => setShowPopupConfirm(false)}
        />
      )}
      {showPopupChangeEmail && (
        <ChangeEmail
          visible={showPopupChangeEmail}
          onChangeEmail={onChangeEmail}
          emailValue={formData?.email}
          onHiding={() => setShowPopupChangeEmail(false)}
        />
      )}
      {showPopupPostCode && (
        <PopupSelectPostCode
          visible={showPopupPostCode}
          text={formData?.postCode}
          onHiding={() => {
            setShowPopupPostCode(false);
          }}
          onSubmit={data => onChangePostCode(data)}
        />
      )}
    </>
  );
};

export default AccountDetails;
