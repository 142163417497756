/**
 * Update date: 14-06-2023
 * Popup product detail component
 */
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useProductDetail } from '..';

function HoverValue(props) {
  const [showFullValue, setShowFullValue] = useState(false);

  /**
   * on toggle
   *
   */
  const toggleShowFullValue = () => {
    setShowFullValue(!showFullValue);
  };
  const roundedValue =
    props.value.toString().split('.')[0] +
    '.' +
    props.value.toString().split('.')[1].slice(0, 2);
  return (
    <span
      onMouseEnter={toggleShowFullValue}
      onMouseLeave={toggleShowFullValue}
      style={{ cursor: 'default', minWidth: 60 }}
    >
      {showFullValue ? props.value : roundedValue + '...'}
    </span>
  );
}

const TableMultiple = ({ columns, dataSource, main = false }) => {
  const { visible }: any = useProductDetail();
  const tdRef: any = useRef(null);

  let columnSeconds: any = [];
  columns?.map(o => {
    o?.columns?.map(f => {
      columnSeconds.push({ ...f });
    });
  });

  const onFormat = (data, format) => {
    if (!data && data !== 0) return '';
    return parseFloat(data).toFixed(format?.precision);
  };
  const numberToCurrency = x => {
    if (!x && x !== 0) return '';
    return x.toString().replace(/\B(?!\.\d)(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    if (tdRef?.current && main && visible) {
      setTimeout(() => {
        const arrayTable: any = document.getElementsByClassName(
          'table-custom-popupproduct-tr',
        );
        for (let i = 0; i < arrayTable.length; i++) {
          arrayTable[i].style.height = `${
            tdRef?.current?.clientHeight + 2.4
          }px`;
        }
      }, 500);
    }
  }, [visible, tdRef?.current]);

  const cursorVisible = data => {
    if (!data) return '0';
    const array = data.toString().split('.');
    if (array?.length >= 2 && array[1].length > 2) {
      return <HoverValue value={data} />;
    }
    return data;
  };

  return (
    <table className="table-custom-popupproduct">
      <tbody>
        <tr>
          {columns?.map((o, i) => (
            <th
              key={i}
              colSpan={o?.columns?.length || 1}
              style={{ backgroundColor: '#E4E7EC' }}
            >
              {o?.caption || o?.label}
            </th>
          ))}
        </tr>
        <>
          <tr>
            {columnSeconds?.map((o, i) => (
              <th
                style={{
                  backgroundColor: '#E4E7EC',
                  width: o?.width || 'auto',
                }}
                key={i}
              >
                <span>{o?.label}</span>
              </th>
            ))}
          </tr>
          {dataSource?.map((o, i) => (
            <tr key={i} ref={tdRef} className="table-custom-popupproduct-tr">
              {columnSeconds?.map((f, j) => (
                <td key={j}>
                  {f?.cellRender ? (
                    f?.cellRender?.(o)
                  ) : (
                    <span>
                      {f?.format
                        ? numberToCurrency(
                            onFormat(o?.[f.dataField], f?.format),
                          )
                        : f?.type === 'date'
                        ? o?.[f.dataField]
                          ? moment(o?.[f.dataField]).format('YYYY-MM-DD')
                          : ''
                        : f?.type === 'cursor-visible'
                        ? cursorVisible(o?.[f.dataField])
                        : o?.[f.dataField]}
                    </span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
};

export default TableMultiple;
