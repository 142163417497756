/**
 * Update date: 29-05-2023
 * Screen 2044.4
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { createContext, useContext, useState } from 'react';
import useDictionary from 'hooks/useDictionary';

const List = React.lazy(() => import('./List'));
const ViewDetail = React.lazy(() => import('./View'));
/**
 * create context
 */
const OutputOfPickListCenterReceivedContext = createContext({});

OutputOfPickListCenterReceivedContext.displayName =
  'OutputOfPickListCenterReceivedContext';

export const useOutputOfPickListCenterReceived = () => {
  const context = useContext(OutputOfPickListCenterReceivedContext);
  return context;
};

const OutputOfPickListCenterReceived = () => {
  const { t }: any = useDictionary({});
  const [data, setData] = useState([]);
  const [modeView, setModeView] = useState({
    type: 'list',
    order: {
      orderId: null,
      wayBill: null,
      store: null,
      sendUser: null,
    },
  });
  const [formSearchData, setFormSearchData] = useState<any>({
    allocateDate: new Date(),
    wayBill: '',
    dataList: {
      storeGroup: [],
      store: [],
      deliveryDriver: [],
      allocation: [],
      receivableCd: [],
    },
    storeGroup: '',
    store: '',
    deliveryDriver: '',
    receiver: '',
    allocation: '',
    receivableCd: '',
  });

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const value: any = {
    t,
    data,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'view':
        return <ViewDetail />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <OutputOfPickListCenterReceivedContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        {renderContent()}
      </React.Suspense>
    </OutputOfPickListCenterReceivedContext.Provider>
  );
};

export default OutputOfPickListCenterReceived;
