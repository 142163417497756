/**
 * Update date: 26-05-2023
 * Screen 2043.0
 */
import { Button, Form, Popup, ScrollView } from 'devextreme-react';
import moment from 'moment';
import { useState, useRef, useMemo } from 'react';
import { usePopupOrder } from '..';
import PopupAddProduct from '../../PopupAddProduct';
import { toNumberAmount } from 'hooks/toNumberAmount';
import useDictionary from 'hooks/useDictionary';

interface IAddProduct {
  onOk: any;
  onClose: () => void;
}

/**
 * get amount
 *
 * @param {*} record
 * @return {*}
 */
const getAmount = (record, qty) => {
  const { supply_unit_price = 0 } = record || {};
  return toNumberAmount(supply_unit_price * qty, true);
};

/**
 * get bottle
 *
 * @param {*} record
 * @return {*}
 */
const getBottle = (record, qty) => {
  const { quantity_per_pack = 0, bottle_unit_price = 0 } = record || {};
  return toNumberAmount(bottle_unit_price * quantity_per_pack * qty);
};
/**
 * get container
 *
 * @param {*} record
 * @return {*}
 */
const getContainer = (record, qty) => {
  const { container_unit_price = 0 } = record || {};
  return toNumberAmount(container_unit_price * qty);
};

/**
 * get shipping fee
 *
 * @param {*} record
 * @return {*}
 */
const getShippingFee = (record, qty) => {
  const { shipping_rate = 0 } = record || {};
  return toNumberAmount(
    ((getAmount(record, qty) +
      getBottle(record, qty) +
      getContainer(record, qty)) *
      shipping_rate) /
      100,
  );
};

/**
 * get add shipping amount
 *
 * @param {*} record
 * @return {*}
 */
const getAddShippingAmount = (record, qty) => {
  const { add_shipping_price = 0, shipping_method } = record || {};
  if (shipping_method == '2' || shipping_method == '3') {
    return toNumberAmount(add_shipping_price * qty);
  }
  return 0;
};

/**
 * get gtotal
 *
 * @param {*} record
 * @return {*}
 */
const getGTotal = (record, qty) => {
  return toNumberAmount(
    getAmount(record, qty) +
      getBottle(record, qty) +
      getContainer(record, qty) +
      getShippingFee(record, qty) +
      getAddShippingAmount(record, qty),
  );
};

function AddProduct({ onOk, onClose }: IAddProduct) {
  const { t } = useDictionary({ programId: '2043.0' });
  const {
    formInformation,
    formData,
    DateTimeFormat,
    statusSelect,
    QtyFormat,
    AmountFormat,
    numberToCurrency,
  }: any = usePopupOrder();

  const [formProduct] = useState<any>({
    order_status: 'N10',
    statusName: statusSelect.find(o => o.code === 'N10')?.name || '',
    product: '',
    shipping_qty: 0,
  });
  const [productData, setProductData] = useState<any>({});
  const [showPopupProduct, setShowPopupProduct] = useState(false);
  const formRef: any = useRef(null);
  const scrollRef: any = useRef(null);
  // const [top, setTop] = useState(0);
  let top = 0;

  const columns: any = useMemo(
    () => [
      {
        dataField: 'product_id',
        caption: t('Id'),
      },
      {
        dataField: 'product_tp_nm',
        caption: t('Product Type'),
        allowSearch: false,
      },
      {
        dataField: 'product_cd',
        caption: t('Product Code'),
        allowSearch: false,
      },
      {
        dataField: 'product_maker_nm',
        caption: t('Maker'),
      },
      {
        dataField: 'vendor_nm',
        caption: t('Vendor'),
      },
      {
        dataField: 'product_nm',
        caption: t('Product'),
      },
      {
        dataField: 'marketable_size',
        caption: t('Marketablesize'),
      },
      {
        dataField: 'option_cd',
        caption: t('Option Code'),
      },
      {
        dataField: 'option_nm',
        caption: t('Option'),
      },
      {
        dataField: 'quantity_per_pack',
        caption: t('Quantity Per Pack'),
      },
      {
        dataField: 'regist_dm',
        caption: t('Regist Date Time'),
        dataType: 'date',
        format: DateTimeFormat,
      },
    ],
    [t, DateTimeFormat],
  );
  /**
   * on scroll
   *
   * @param {*} e
   */
  const onScroll = e => {
    top = scrollRef.current.instance.scrollTop();
  };
  const informationItem = (title, value, date?) => {
    return (
      <div
        style={{
          display: 'inline-block',
          padding: 10,
          backgroundColor: '#f2f4f7',
          borderRadius: 6,
          margin: '10px 10px 0 0',
        }}
      >
        <div style={{ color: '#667085' }}>{t(title)} </div>
        <span
          style={{
            color: '#101828',
            fontWeight: 600,
          }}
        >
          {date ? moment(value).format('MM/DD/YYYY HH:mm:ss') : value}
        </span>
      </div>
    );
  };
  const calculation = (data, qty) => {
    const amount = getAmount(data, qty);
    const bottle_amount = getBottle(data, qty);
    const container_amount = getContainer(data, qty);
    const shipping_fee = getShippingFee(data, qty);
    const add_shipping_amount = getAddShippingAmount(data, qty);
    const total = getGTotal(data, qty);
    return {
      amount,
      total,
      shipping_fee,
      add_shipping_amount,
      bottle_amount,
      container_amount,
    };
  };
  /**
   * on add product
   *
   * @param {*} data
   */
  const onAddProduct = data => {
    if (data.length) {
      const {
        total,
        shipping_fee,
        add_shipping_amount,
        bottle_amount,
        container_amount,
        amount,
      } = calculation(data[0], 1);
      setProductData({
        ...data[0],
        total,
        shipping_fee,
        add_shipping_amount,
        bottle_amount,
        container_amount,
        amount,
        shipping_fee_add: shipping_fee + add_shipping_amount,
      });

      formRef?.current?.instance?.updateData('product', '');
      formRef?.current?.instance?.updateData('shipping_qty', 1);
      formRef?.current?.instance?.updateData('product_cd', data[0]?.product_cd);
    }
    setShowPopupProduct(false);
  };

  /**
   * render content
   *
   * @return {*}
   */
  const content = () => {
    return (
      <ScrollView
        width="100%"
        height="100%"
        onScroll={onScroll}
        ref={scrollRef}
      >
        <div className="modal-popup-header">
          <span className="title-page">{t('Add Product')}</span>
          <div>
            <Button
              stylingMode="contained"
              type="default"
              text={t('Save')}
              icon="check"
              style={{ marginRight: 5 }}
              onClick={() => {
                onOk({ ...productData, ...formProduct });
              }}
            />
            <Button
              stylingMode="contained"
              text={t('Cancel')}
              icon="close"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="body-padding-white mb-10">
          <div className="dx-form-group-caption">{t('Order Information')}</div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            {informationItem('Order Number: ', formData.orderNumber)}
            {informationItem('Mall ID: ', formData.mallId)}
            {informationItem('Store: ', formData.store)}
            {informationItem('Order Date Time: ', formData.orderDateTime)}
          </div>
        </div>
        <div className="body-padding-white">
          <Form
            labelLocation="top"
            showColonAfterLabel={false}
            formData={formProduct}
            ref={formRef}
            onFieldDataChanged={e => {
              if (e.dataField === 'shipping_qty') {
                const {
                  total,
                  shipping_fee,
                  add_shipping_amount,
                  bottle_amount,
                  container_amount,
                  amount,
                } = calculation(productData, parseInt(e.value));
                setProductData(preState => ({
                  ...preState,
                  total,
                  shipping_fee,
                  add_shipping_amount,
                  bottle_amount,
                  container_amount,
                  amount,
                  shipping_fee_add: shipping_fee + add_shipping_amount,
                }));
                scrollRef.current.instance.scrollTo(top);
              }
            }}
            items={[
              {
                caption: t('Product'),
                itemType: 'group',
                cssClass: 'group-caption-second',
                items: [
                  {
                    label: {
                      text: t('Product'),
                    },
                    dataField: 'product',
                    colSpan: 2,
                    editorOptions: {
                      onEnterKey: () => setShowPopupProduct(true),
                      buttons: [
                        {
                          name: 'productData',
                          location: 'before',
                          options: {
                            rtlEnabled: true,
                            text: productData?.product_nm || '',
                            visible: !!productData?.product_id,
                            icon: 'close',
                            onClick: () => setProductData({}),
                          },
                        },
                        {
                          name: 'search',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            icon: 'search',
                            onClick: () => setShowPopupProduct(true),
                          },
                        },
                      ],
                    },
                  },
                  {
                    dataField: 'product_cd',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Product Code'),
                    },
                    editorOptions: {
                      disabled: true,
                    },
                  },
                  {
                    dataField: 'statusName',
                    editorType: 'dxTextBox',
                    label: {
                      text: t('Status'),
                    },
                    editorOptions: {
                      disabled: true,
                    },
                  },
                  {
                    itemType: 'group',
                    colCount: 8,
                    label: { text: t('Order Quantity') },
                    cssClass: 'group-center',
                    items: [
                      {
                        cssClass: 'dx-button-padding-small',
                        itemType: 'button',
                        horizontalAlignment: 'right',
                        verticalAlignment: 'top',
                        buttonOptions: {
                          icon: 'minus',
                          type: 'default',
                          onClick: () => {
                            formRef?.current?.instance?.updateData(
                              'shipping_qty',
                              formProduct.shipping_qty === 0
                                ? 0
                                : formProduct.shipping_qty - 1,
                            );
                            scrollRef.current.instance.scrollTo(top);
                          },
                        },
                      },
                      {
                        colSpan: 2,
                        label: { visible: false },
                        dataField: 'shipping_qty',
                        editorType: 'dxNumberBox',
                        cssClass: 'dx-input-center',
                        editorOptions: {
                          format: QtyFormat,
                          min: 0,
                        },
                      },
                      {
                        colSpan: 2,
                        cssClass: 'dx-button-padding-small',
                        itemType: 'button',
                        horizontalAlignment: 'left',
                        verticalAlignment: 'top',
                        buttonOptions: {
                          icon: 'plus',
                          type: 'default',
                          onClick: e => {
                            formRef?.current?.instance?.updateData(
                              'shipping_qty',
                              formProduct.shipping_qty + 1,
                            );
                            scrollRef.current.instance.scrollTo(top);
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ]}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {informationItem(
              'Supply Unit Price / Amount',
              `${numberToCurrency(
                productData?.supply_unit_price,
                AmountFormat,
              )} / ${numberToCurrency(productData?.amount, AmountFormat)}`,
            )}
            {informationItem(
              'Marketable Size',
              productData?.marketable_size || '--',
            )}
            {informationItem(
              'Option / Quantity Per Pack',
              `${productData?.option_nm || '--'} / ${numberToCurrency(
                productData?.quantity_per_pack,
                QtyFormat,
              )}`,
            )}
            {informationItem(
              'Bottle / Container',
              `${numberToCurrency(
                productData?.bottle_amount,
                AmountFormat,
              )} / ${numberToCurrency(
                productData?.container_amount,
                AmountFormat,
              )}`,
            )}
            {informationItem(
              'Shipping Rate / Shipping Fee',
              `${numberToCurrency(
                productData?.shipping_rate,
                AmountFormat,
              )} / ${numberToCurrency(
                productData?.shipping_fee_add,
                AmountFormat,
              )}`,
            )}
            {informationItem(
              'Total',
              numberToCurrency(productData?.total, AmountFormat),
            )}
          </div>
        </div>
        {showPopupProduct && (
          <PopupAddProduct
            url="/ecommerce/order-popup/product"
            columns={columns}
            text={formProduct.product}
            dataBody={{
              keySearch: formProduct.product,
              orderId: formInformation.order_id,
            }}
            keyWord="keySearch"
            mode="single"
            visible={true}
            onHiding={() => setShowPopupProduct(false)}
            onSubmit={o => onAddProduct(o)}
          />
        )}
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup popup-order-custom-claim"
      visible={true}
      //   onHiding={onHiding}
      contentRender={content}
      height={'100vh'}
      width={500}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default AddProduct;
