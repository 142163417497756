/**
 * Update date: 2023-09-20
 * Screen 1044.7
 */
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useState } from 'react';
import { notification } from 'utils/notification';

const List = React.lazy(() => import('./List'));
const CreateEditView = React.lazy(() => import('./CreateEditView'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'M1044_7_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M1044_7 = () => {
  const { t }: any = useDictionary({ programId: '1044.7' });
  const [data, setData] = useState([]);
  const [formSearchData, setFormSearchData] = useState<any>({
    deliveryCompanyName: '',
  });

  const [modeView, setModeView] = useState({
    type: 'list',
    data: null,
  });

  const [{ loading: loadingFetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: data,
    key: 'delivery_agency_id',
  });

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const requestData = {
      deliveryCompanyName: formSearchData?.deliveryCompanyName,
    };

    const res = await refetchData({
      url: '/user/m1044_7',
      method: 'POST',
      data: requestData,
    });
    if (res?.data?.status === '200') {
      setData(res?.data?.data || []);
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    store,
    setData,
    modeView,
    setModeView,
    refetchData,
    formSearchData,
    setFormSearchData,
    onSubmit,
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <List />;
      case 'create':
      case 'edit':
        return <CreateEditView />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingFetchData} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M1044_7;
